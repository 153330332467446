/* import __COLOCATED_TEMPLATE__ from './boolean-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';

const VALUE_LABEL_MAPPING = {
  true: 'is true',
  false: 'is false',
  exists: 'has any value',
  not_exists: 'is unknown',
};

const VALUE_OPERATOR_MAPPING = {
  true: 'category',
  false: 'category',
  not_exists: 'not_exists',
  exists: 'exists',
};

export default class BooleanFilter extends Component {
  @service store;
  @service intercomEventService;

  // store the selection here so that the UI doesn't update until the drop down is closed
  @tracked label = this.currentLabel;
  @tracked selected = this.startingSelectedValue;

  get startingSelectedValue() {
    let operator = this.args.selected.operator;
    let passedValue = this.args.selected.values?.[0];

    switch (operator) {
      case 'category':
        return isPresent(passedValue) ? passedValue : true;
      case 'exists':
      case 'not_exists':
        return operator;
      default:
        return true;
    }
  }

  get customField() {
    let id = this.args.type;
    return this.store.peekRecord('conversation-attributes/descriptor', id);
  }

  get name() {
    if (this.customField.archived) {
      return `${this.customField.name} (Archived)`;
    } else {
      return this.customField.name;
    }
  }

  get currentLabel() {
    return VALUE_LABEL_MAPPING[this.selected] || '';
  }

  get operator() {
    return VALUE_OPERATOR_MAPPING[this.selected];
  }

  get values() {
    return this.operator === 'category' ? [this.selected] : [];
  }

  @action
  onClose() {
    if (!this.values.length && !this.operator) {
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_custom_field',
      object: this.args.analyticsObject,
      custom_field_type: 'boolean',
    });

    this.args.setSelected(this.values, this.operator);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selected = null;
    this.args.removeFilter();
  }
}
