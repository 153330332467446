/* import __COLOCATED_TEMPLATE__ from './opportunities-table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import storage from 'embercom/vendor/intercom/storage';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ENV from 'embercom/config/environment';

export default class ArticlesOpportunitiesTable extends Component {
  @service appService;
  @service intl;
  @service notificationsService;

  @tracked deletedRows = [];
  @tracked currentRow = {};

  localStorageRegex = new RegExp(`.*-${this.appService.app.get('currentAdmin.id')}`);

  constructor() {
    super(...arguments);
    this.initDeletedRows();
  }

  initDeletedRows() {
    let matchingItemsFromStorage = storage.findItemsByRegex(
      /articles-reporting-searches-no-results/,
    );

    let itemsForCurrentAdmin = matchingItemsFromStorage.filter((x) =>
      this.localStorageRegex.test(x.key),
    );

    this.deletedRows = itemsForCurrentAdmin.map((x) => x.value.groupName);
  }

  @action
  deleteRow(row) {
    storage.set(this.storageKey(row), row);
    this.currentRow = row;
    this.deletedRows = [...this.deletedRows, row.groupName];

    this.notificationsService.notifyConfirmationWithButton(
      this.intl.t('reporting.articles.opportunities.banner-text'),
      {
        label: this.intl.t('reporting.articles.opportunities.banner-button'),
        action: this.undoDeletion,
      },
      ENV.APP._10000MS,
      'search-no-results-delete-banner',
    );
  }

  @action
  undoDeletion() {
    storage.remove(this.storageKey(this.currentRow));
    this.deletedRows = this.deletedRows.filter((title) => title !== this.currentRow.groupName);
    this.currentRow = {};
  }

  storageKey(row) {
    return `articles-reporting-searches-no-results-${row.groupName}-${this.appService.app.get(
      'currentAdmin.id',
    )}`;
  }

  get columns() {
    let basicColumns = [
      {
        label: this.intl.t('reporting.articles.opportunities.keyword'),
        isMain: true,
        valuePath: 'groupName',
        component: 'reporting/flexible/table-opportunities-keyword-cell',
        isSortable: false,
      },
      {
        label: this.intl.t('reporting.articles.opportunities.searches'),
        valuePath: 'search-frequency',
        tooltip: this.intl.t('reporting.articles.opportunities.searches-tooltip'),
      },
      {
        label: this.intl.t('reporting.articles.opportunities.action'),
        component: 'reporting/flexible/delete-action',
        valuePath: 'displayAs',
        componentData: {
          deleteRow: (row) => this.deleteRow(row),
        },
      },
    ];

    return basicColumns;
  }

  get dataConfig() {
    let baseConfig = {
      source: 'article_search_event',
      filter: {
        type: 'and',
        filters: [
          {
            type: 'exists',
            data: {
              property: 'normalized_query',
            },
          },
          {
            type: 'category',
            data: {
              property: 'profanity',
              values: [false],
            },
          },
          {
            type: 'category',
            data: {
              property: 'result_count',
              values: [0],
            },
          },
          {
            type: 'range',
            data: {
              operators: ['gt'],
              property: 'length',
              values: [3],
            },
          },
          {
            type: 'category',
            data: {
              property: 'name',
              values: ['Educate Search'],
            },
          },
        ],
      },
      time: {
        property: 'created_at',
        start: FILTER_PROPERTY_PLACEHOLDER,
        end: FILTER_PROPERTY_PLACEHOLDER,
      },
    };

    return {
      columns: [
        {
          name: 'query',
          aggregation: {
            type: 'cardinality',
            data: {
              property: 'normalized_query',
            },
          },
          ...baseConfig,
        },
        {
          name: 'search-frequency',
          aggregation: {
            type: 'count',
            data: {
              property: 'search_id',
            },
          },
          ...baseConfig,
        },
      ],
      rows: [
        {
          name: 'row-queries',
          type: 'term',
          data: {
            term_size: 10000,
            property: 'normalized_query',
          },
        },
      ],
    };
  }

  get viewConfig() {
    return {
      rowsVisible: 10,
      sortState: { valuePath: 'search-frequency', direction: 'desc' },
      isVisibleRowAfterLoading: (row) => {
        if (
          this.deletedRows.length !== 0 &&
          this.deletedRows.find((title) => title === row.groupName)
        ) {
          return false;
        }
        return true;
      },
    };
  }
}
