/* import __COLOCATED_TEMPLATE__ from './bulk-invite-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import csvParser from 'papaparse';
import { Promise as EmberPromise } from 'rsvp';
import { inject as service } from '@ember/service';

const MAXIMUM_NUMBER_OF_LINES_TO_PARSE = 1000;

export default class BulkInviteModal extends Component {
  @service router;
  @service intercomEventService;
  @service appService;
  @service intl;
  @service appService;

  @tracked file = null;
  @tracked fileIsValid = false;
  @tracked fileFailedToUpload = false;
  @tracked parsingErrorMessage = '';

  async _parseCsvFile(file) {
    // the CSV parser uses callbacks, so this is just a shim so we can use it as a Promise
    return new EmberPromise((resolve, reject) => {
      let complete = (results) => {
        if (results.errors.length) {
          // An UndetectableDelimiter happens with single column CSV files (because no line contains a ,)
          if (results.errors.any((error) => error.code !== 'UndetectableDelimiter')) {
            reject(results);
          }
        }
        resolve(results);
      };
      csvParser.parse(file, { complete, preview: MAXIMUM_NUMBER_OF_LINES_TO_PARSE, error: reject });
    });
  }

  @action
  async parseCsvFile() {
    try {
      this.parsingErrorMessage = '';
      let results = await this._parseCsvFile(this.file);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'csv-file-parsed',
        object: 'teammate-invite',
        place: 'teammate-list',
        numberOfLinesParsed: results.data.length,
      });

      if (results.data.length === 0) {
        throw new Error(this.intl.t('settings.bulk-invite-modal.no-data-error'));
      }

      this.args.onModalClose();

      let transition = this.router.transitionTo(
        'apps.app.settings.workspace.teammates.invite.csv-import',
      );
      transition.data.parsedCsv = results;
    } catch (error) {
      console.error(error);
      this.parsingErrorMessage =
        error.message || this.intl.t('settings.bulk-invite-modal.fallback-processing-error');
      this.fileFailedToUpload = true;
      this.fileIsValid = false;
    }
  }

  @action
  setFile(file) {
    this.file = file;
    if (file === null) {
      this.fileIsValid = false;
    } else {
      this.fileIsValid = true;
    }
  }

  get scimEnabled() {
    return this.appService.app.scim_enabled;
  }
}
