/* import __COLOCATED_TEMPLATE__ from './visualization.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import TopicStats from 'embercom/lib/conversational-insights/topic-stats';
import ConversationTopicModel from 'embercom/models/conversational-insights/conversation-topic';
import { inject as service } from '@ember/service';

export const MAX_TOPIC_COUNT = 30;

export default class VisualizationComponent extends Component {
  @service intercomEventService;
  @service appService;
  @service router;

  @tracked showSatisfaction = false;
  @tracked showOtherTopics = this.args.showOtherTopicsDefault;
  @tracked data;
  @tracked visualizationMode = 'volume';
  @tracked maxSatisfaction = 0;
  @tracked minSatisfaction = 100;

  margin = { top: 0, right: 0, bottom: 0, left: 0 };
  maxTopicCount = MAX_TOPIC_COUNT;

  get isLoading() {
    return this.args.primaryLoading || (this.showOtherTopics && this.args.secondaryLoading);
  }

  get isInitialLoading() {
    return !this.data && this.isLoading;
  }

  get canUseInsights() {
    return this.appService.app.hasConversationalInsightsBillingFeature;
  }

  @action
  processData() {
    if (this.isLoading) {
      return;
    }
    let bubbleData = [];
    bubbleData.push(
      ...this.args.primaryTopics
        .filter(
          (topic) =>
            this.args.primaryStats[topic.id] && this.args.primaryStats[topic.id].current_count > 0,
        )
        .map((topic) => {
          let stats = new TopicStats(this.args.primaryStats[topic.id]);
          return this.generateBubbleData(topic, stats);
        }),
    );
    if (this.showOtherTopics) {
      bubbleData.push(
        ...this.args.secondaryTopics
          .filter((suggestion) => {
            if (this.args.secondaryStats) {
              return (
                this.args.secondaryStats[suggestion.id] &&
                this.args.secondaryStats[suggestion.id].current_count > 0
              );
            } else {
              return false;
            }
          })
          .map((suggestion) => {
            let stats = new TopicStats(this.args.secondaryStats[suggestion.id]);
            return this.generateBubbleData(suggestion, stats);
          }),
      );
    }
    this.data = bubbleData
      .sort((a, b) => b.currentCount - a.currentCount)
      .slice(0, MAX_TOPIC_COUNT);

    this.data.forEach(({ currentPositiveRate }) => {
      if (currentPositiveRate === null) {
        return;
      }
      this.minSatisfaction = Math.min(this.minSatisfaction, currentPositiveRate);
      this.maxSatisfaction = Math.max(this.maxSatisfaction, currentPositiveRate);
    });
  }

  generateBubbleData(topicOrSuggestion, stats) {
    let isTopic = topicOrSuggestion instanceof ConversationTopicModel;
    let idPrefix = isTopic ? 'topic' : 'suggestion';
    return {
      onClick: () => this.trackBubbleClick(topicOrSuggestion, isTopic),
      id: `${idPrefix}-${topicOrSuggestion.id}`,
      name: topicOrSuggestion.name,
      currentCount: stats.currentCount,
      currentResponseTime: stats.currentResponseTime || 0,
      currentTimeToClose: stats.currentTimeToClose || 0,
      currentPositiveRate: stats.currentPositiveRate,
      accepted: isTopic,
      url: this.canUseInsights ? this.generateUrlFor(topicOrSuggestion, isTopic) : null,
    };
  }

  trackBubbleClick(topicOrSuggestion, isTopic) {
    let payload = { action: 'clicked', context: 'from_visualisation', place: 'topics' };

    if (isTopic) {
      payload.object = 'topic';
      payload.topic_id = topicOrSuggestion.id;
      payload.topic_keyphrases = topicOrSuggestion.keyphrasesText;
      payload.topic_name = topicOrSuggestion.name;
    } else {
      payload.object = 'suggestion';
      payload.suggestion_id = topicOrSuggestion.id;
      payload.suggestion_keyphrases = topicOrSuggestion.keyphrasesText;
      payload.suggestion_name = topicOrSuggestion.name;
    }

    this.intercomEventService.trackAnalyticsEvent(payload);
  }

  generateUrlFor(topicOrSuggestion, isTopic) {
    if (isTopic) {
      let route = 'apps.app.reports.conversational-insights.topics.topic';
      return this.router.urlFor(route, topicOrSuggestion);
    } else {
      let route = 'apps.app.reports.conversational-insights.suggested-topics.topic';
      return this.router.urlFor(route, { topic_ids: [topicOrSuggestion.id] });
    }
  }

  @action
  toggleSatisfaction() {
    this.showSatisfaction = !this.showSatisfaction;
    this.trackToggleSatisfaction();
  }

  @action
  onToggleVisualizationMode(value) {
    this.visualizationMode = value;
    this.trackToggleVisualizationMode();
  }

  @action
  onToggleShowOtherTopics() {
    this.showOtherTopics = !this.showOtherTopics;
    this.trackToggleShowOtherTopics();
    this.processData();
  }

  trackAnalyticsEvent(props = {}) {
    let showingSuggestions = this.args.primaryObjects === 'suggestions';
    let context = showingSuggestions ? 'suggestions_list' : 'topics_list';
    this.intercomEventService.trackAnalyticsEvent({
      place: 'topics',
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
      action: 'toggled',
      context,
      ...props,
    });
  }

  trackToggleVisualizationMode() {
    this.trackAnalyticsEvent({ metric: this.visualizationMode, object: 'visualization' });
  }

  trackToggleSatisfaction() {
    this.trackAnalyticsEvent({ is_on: this.showSatisfaction, object: 'satisfaction' });
  }

  trackToggleShowOtherTopics() {
    let showingSuggestions = this.args.primaryObjects === 'suggestions';
    let object = showingSuggestions ? 'show_topics' : 'show_suggestions';

    this.trackAnalyticsEvent({ is_on: this.showOtherTopics, object });
  }
}
