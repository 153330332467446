/* import __COLOCATED_TEMPLATE__ from './custom-dropdown-option-item.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Item {
  text: string;
  description?: string;
  isSelected?: boolean;
  icon: InterfaceIconName;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}
interface Args {
  item: Item;
}

export default class ReportingCustomReportCustomDropdownOptionItem extends Component<Signature> {
  get iconColor(): string {
    return this.args.item.isSelected ? 'blue' : 'black';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::CustomDropdownOptionItem': typeof ReportingCustomReportCustomDropdownOptionItem;
    'reporting/custom/report/custom-dropdown-option-item': typeof ReportingCustomReportCustomDropdownOptionItem;
  }
}
