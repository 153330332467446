/* import __COLOCATED_TEMPLATE__ from './permission-crud-row.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import { action } from '@ember/object';

interface Args {
  key: string;
  permission: any;
  permissionsObject: any;
  changed: () => void;
  isDisabled: boolean;
  isDisabledByRole: boolean;
  updatePermission: (key: string, value?: boolean) => void;
  unallowedPermissionList: any;
  seatDependentPermissionList: any;
}

export default class PermissionCrudRow extends Component<Args> {
  @service declare appService: $TSFixMe;

  get parentValue() {
    return this.args.permissionsObject[`can_${this.args.permission.parent_id}`];
  }

  get showChangeButton() {
    let permissions = this.args.permission.actions.filter((actionKey: string) => {
      return this.args.permissionsObject[`can_${actionKey}`] === undefined;
    });
    return isNone(permissions);
  }

  @action
  togglePermission(action_key: string) {
    let frontEndReadKey = `can_${this.args.permission.key}__read`;
    let frontEndKeyAction = `can_${action_key}`;
    let actionIsRead = frontEndKeyAction === frontEndReadKey;

    if (actionIsRead && this.args.permissionsObject[frontEndKeyAction]) {
      this.args.permission.actions.forEach((child: string) => {
        this.args.permissionsObject[`can_${child}`] = false;
      });
    } else if (!actionIsRead && !this.args.permissionsObject[frontEndReadKey]) {
      this.args.permissionsObject[frontEndReadKey] = true;
      this.args.permissionsObject[frontEndKeyAction] = true;
    } else {
      this.args.permissionsObject[frontEndKeyAction] =
        !this.args.permissionsObject[frontEndKeyAction];
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::Permissions::PermissionCrudRow': typeof PermissionCrudRow;
    'settings/teammates/permissions/permission-crud-row': typeof PermissionCrudRow;
  }
}
