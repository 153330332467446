/* import __COLOCATED_TEMPLATE__ from './logs-export.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import LogsSettingsModel from 'embercom/models/logs-settings';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class LogsExportModal extends Component {
  @service modalService;
  @action
  closeModal() {
    this.modalService.closeModal();
  }

  @action
  download(format) {
    let url = LogsSettingsModel.logsSettingsBetweenDatesUrl(
      this.args.model.appId,
      format,
      this.args.model.from.unix(),
      this.args.model.to.unix(),
    );

    safeWindowOpen(url, '_blank');

    this.closeModal();
  }
}
