/* import __COLOCATED_TEMPLATE__ from './line-chart-legend-row.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
export const TAG_COLOR_COUNT = 20; // this should match the count of $tagColors in reporting/_chart.scss

export default Component.extend({
  tagName: 'tr',
  classNames: ['reporting__tag-line-chart__legend-row', 'u__pointer-cursor'],
  index: 0,
  normalisedIndex: computed('index', function () {
    return this.index % TAG_COLOR_COUNT;
  }),

  mouseEnter() {
    this.setHoveredItem(this.index);
  },

  mouseLeave() {
    this.setHoveredItem();
  },
});
