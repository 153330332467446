/* import __COLOCATED_TEMPLATE__ from './app-settings-submenu.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/no-side-effects */
import { match, readOnly, and, not } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import RouteRegexes from 'embercom/lib/route-regexes';
import BaseSettingsSubmenu from 'embercom/components/base-settings-submenu';
export default BaseSettingsSubmenu.extend({
  appService: service(),
  customerService: service(),
  intercomEventService: service(),
  intercomCallService: service(),
  identityVerificationRiskStateService: service(),
  permissionsService: service(),
  showIdentityVerificationWarningIcon: computed(
    'identityVerificationRiskStateService.shouldShowLowNotification',
    function () {
      let shouldShow = this.get('identityVerificationRiskStateService.shouldShowLowNotification');
      if (shouldShow) {
        this.send('trackIdentityVerificationNotificationEvent', {
          action: 'viewed',
          object: 'sidebar',
        });
      }
      return shouldShow;
    },
  ),
  isOnAppAndTeamsRoute: match('internalCurrentRouteName', RouteRegexes.appSettings.appAndTeams),
  isOnEmailRoute: match('internalCurrentRouteName', RouteRegexes.appSettings.email),
  isOnInboxAutomationRoute: match(
    'internalCurrentRouteName',
    RouteRegexes.appSettings.inboxAutomation,
  ),
  isSwitchRoute: match('internalCurrentRouteName', RouteRegexes.appSettings.switch),
  isOnMessengerRoute: match('internalCurrentRouteName', RouteRegexes.appSettings.messenger),
  isOnDataRoute: match('internalCurrentRouteName', RouteRegexes.appSettings.data),
  isOnSpamRoute: match('internalCurrentRouteName', RouteRegexes.appSettings.spam),
  isOnInboxRoute: match('internalCurrentRouteName', RouteRegexes.appSettings.inbox),
  isOnInstallationRoute: match('internalCurrentRouteName', RouteRegexes.appSettings.installation),
  isOnDevelopersRoute: match('internalCurrentRouteName', RouteRegexes.appSettings.developers),
  isOnIntercomOnlyRoute: match('internalCurrentRouteName', RouteRegexes.appSettings.intercomOnly),
  isOnSecurityRoute: match('internalCurrentRouteName', RouteRegexes.appSettings.security),
  canManageSavedRepliesPermission: readOnly(
    'app.currentAdmin.currentAppPermissions.can_manage_saved_replies',
  ),
  noAccessProductSettingsPermission: not(
    'app.currentAdmin.currentAppPermissions.can_access_product_settings',
  ),
  shouldRedirectToTagsSection: readOnly('app.currentAdmin.canManageTags'),
  shouldRedirectToSavedRepliesSection: and(
    'canManageSavedRepliesPermission',
    'noAccessProductSettingsPermission',
  ),
  generalSectionLink: computed('permissionsService', function () {
    return (
      this.permissionsService.getFirstAccessibleGeneralSettingsRoute() ||
      'apps.app.settings.workspace.general'
    );
  }),

  actions: {
    trackIdentityVerificationNotificationEvent(data) {
      this.intercomEventService.trackEvent(`identity-verification-notification-${data.action}`, {
        action: data.action,
        object: data.object,
        place: 'settings',
        owner: 'growth',
      });
    },
    trackIdentityVerificationNotificationEventSidebar() {
      if (this.showIdentityVerificationWarningIcon) {
        this.send('trackIdentityVerificationNotificationEvent', {
          action: 'clicked',
          object: 'sidebar',
        });
      }
    },
    requestCanManageSavedRepliesPermission() {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_manage_saved_replies',
      );
    },
  },
});
