/* import __COLOCATED_TEMPLATE__ from './standard-stats-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { makeStatsParametersForSelectionSet } from 'embercom/helpers/stats-system/stats-parameters';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class StandardStatsList extends Component {
  @service appService;
  @service router;
  @tracked statsParameters;

  constructor() {
    super(...arguments);

    this.statsParameters = makeStatsParametersForSelectionSet(
      this.args.selectionSet,
      this.args.config.objectType,
      this.range,
      this.appService.app.timezone,
    );
  }

  get selectionSet() {
    return this.args.selectionSet;
  }

  get range() {
    return this.args.range;
  }

  @action changeFilter(_, filter) {
    this.router.transitionTo({ queryParams: { filter } });
  }
}
