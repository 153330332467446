/* import __COLOCATED_TEMPLATE__ from './connector-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { seriesEdgeTypes } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default class SeriesNodeConnectorWrapperComponent extends Component {
  @service seriesEditorService;

  get isVisible() {
    return (
      (this.args.isHovered && this._canBeVisible) ||
      this.args.node.outwardEdges.some(
        (edge) =>
          edge.uiProperties.startAlignment === 'right' &&
          edge.dataObject.edgeType === this.args.connector.edgeType,
      )
    );
  }

  get isSelected() {
    return this.args.node.outwardEdges.some(
      (edge) =>
        edge.uiProperties.startAlignment === 'right' &&
        edge.dataObject.edgeType === this.args.connector.edgeType &&
        edge.isSelected,
    );
  }

  get _canBeVisible() {
    if (this.seriesEditorService.isShowingShortcuts) {
      return this.args.connector.edgeType === seriesEdgeTypes.primary;
    } else {
      return true;
    }
  }
}
