/* import __COLOCATED_TEMPLATE__ from './table-csv-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { tableToCSV } from 'embercom/helpers/table-to-csv';
interface Args {
  exportFilename: string;
  tableId: string;
  trackDownloadCSV: Function;
  isMissingData: boolean;
}
export default class MessageTable extends Component<Args> {
  @tracked csvLoading = false;

  get isLoading() {
    return this.csvLoading;
  }

  get isMissingData() {
    return this.args.isMissingData;
  }

  get disabled() {
    return this.csvLoading || this.isMissingData;
  }

  trackDownloadCSV() {
    if (this.args.trackDownloadCSV) {
      this.args.trackDownloadCSV();
    }
  }

  @action export() {
    this.csvLoading = true;
    tableToCSV([
      {
        elementId: this.args.tableId,
        exportFilename: this.args.exportFilename,
        exportAnalytic: () => this.trackDownloadCSV(),
        linkElement: document.getElementById('csv-download-link'),
      },
    ]);
    this.csvLoading = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Series::Editor::OverviewReport::TableCsvButton': typeof MessageTable;
    'series/editor/overview-report/table-csv-button': typeof MessageTable;
  }
}
