/* import __COLOCATED_TEMPLATE__ from './content-shortcuts-connector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ContentShortcutsConnector extends Component {
  @service appService;
  @service attributeService;
  @service seriesEditorService;
  @tracked isHoveringLabel = false;

  get app() {
    return this.appService.app;
  }

  get hideShortcuts() {
    return (
      this.hasOutwardEdges ||
      !this.nodeHasContentAttributes ||
      this.seriesEditorService.graph.state.draggingEdge
    );
  }

  get hasOutwardEdges() {
    return (
      this.args.node.outwardEdges.filter((edge) => edge.edgeType === this.args.connector.edgeType)
        .length > 0
    );
  }

  get nodeHasContentAttributes() {
    let objectType = this.args.node.dataObject.objectTypes.firstObject;
    return this.attributeService.contentEventAttributesFor(objectType).length > 0;
  }

  @action
  onMouseEnter() {
    if (this.nodeHasContentAttributes) {
      this.seriesEditorService.isShowingShortcuts = true;
    }
  }

  @action
  onMouseLeave() {
    this.seriesEditorService.isShowingShortcuts = false;
  }
}
