/* import __COLOCATED_TEMPLATE__ from './teammates.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { getInboxSeatInfo } from 'embercom/lib/admins/inbox-seat-info';

export default Component.extend({
  classNames: ['flex-auto', 'flex', 'flex-col', 'u__ml__30', 'u__mr__30', 'u__mb__20'],
  appService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  permissionsMutatorService: service(),
  intl: service(),

  app: readOnly('appService.app'),

  teammates: null,
  adminToEdit: null,
  activeInvitedAdmins: null,
  selectedTab: 'teammates',

  adminsWithInboxAccessCount: readOnly('permissionsMutatorService.adminsWithInboxAccessCount'),
  adminsWithoutInboxAccessCount: readOnly(
    'permissionsMutatorService.adminsWithoutInboxAccessCount',
  ),

  loadInboxSeatInfo: task(function* () {
    return yield getInboxSeatInfo(1);
  }),

  actions: {
    reloadPermissions() {
      return this.reloadPermissions(...arguments);
    },
    removeAllRestrictions() {
      return this.removeAllRestrictions(...arguments);
    },
    async toggleInboxSeat(admin) {
      if (admin.get('hasInboxAccess')) {
        await this.permissionsMutatorService.removeInboxSeat(admin, this.teammates);
        this.loadInboxSeatInfo.perform();
      } else {
        await this.permissionsMutatorService.giveInboxSeat(admin);
        this.loadInboxSeatInfo.perform();
      }
    },
  },
});
