/* import __COLOCATED_TEMPLATE__ from './send-app-with-introduction-overlay.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { readOnly, or } from '@ember/object/computed';
import BaseFilterBlockPopover from 'predicates/components/filter-block/base-filter-block-popover';
import { inject } from '@ember/service';
import { findBy } from '@intercom/pulse/lib/computed-properties';

export default BaseFilterBlockPopover.extend({
  appService: inject(),
  app: readOnly('appService.app'),
  operator: findBy('app.admins', 'isOperator'),
  adminFromMessage: readOnly('componentAttrs.message.variationA.from'),
  fromAdmin: or('adminFromMessage', 'operator'),

  _onChange() {
    let onChange = this.get('componentAttrs.onChange');
    if (onChange) {
      onChange();
    }
  },

  actions: {
    update(introduction) {
      let actionData = this.get('componentAttrs.action.actionData');
      this.set(
        'componentAttrs.action.actionData',
        Object.assign({}, actionData, {
          introduction,
          reply_from_admin_id: this.get('fromAdmin.id'),
        }),
      );
      this._onChange();
    },

    selectApp(options, messengerApp) {
      let actionData = this.get('componentAttrs.action.actionData');
      this.set(
        'componentAttrs.action.actionData',
        Object.assign({}, actionData, {
          appName: messengerApp.get('name'),
          messengerAppId: messengerApp.get('id'),
          options,
        }),
      );
      this._onChange();
    },
  },
});
