/* import __COLOCATED_TEMPLATE__ from './barchart-with-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { buildDataConfigWithComparisons } from 'embercom/lib/reporting/comparison-data-config';
import { copy } from 'ember-copy';

export default class BarchartWithCounter extends Component {
  @service appService;
  @service intl;

  get counterDataConfig() {
    let comparisonCounterConfig = buildDataConfigWithComparisons(
      copy(this.args.dataConfig),
      this.args.renderableChart.dateRange,
    );
    return { ...comparisonCounterConfig, xAxis: null, yAxis: null };
  }
}
