/* import __COLOCATED_TEMPLATE__ from './clean-up-inbox-rules.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';

export default class CleanUpModal extends Component {
  @service modalService;
  @service notificationsService;
  @service intercomEventService;
  @service appService;
  @service store;
  @service intl;
  @tracked selectedWorkflows = this.args.model.workflows.map((workflow) => workflow.id);

  get workflows() {
    return this.args.model.workflows;
  }

  get app() {
    return this.appService.app;
  }

  get workflowsSelected() {
    return this.selectedWorkflows.length > 0;
  }

  @action
  handleSelect(rowId) {
    let selected;
    if (this.selectedWorkflows.includes(rowId)) {
      selected = this.selectedWorkflows.without(rowId);
    } else {
      selected = [...this.selectedWorkflows, rowId];
    }
    this.selectedWorkflows = selected;
    this.workflows.forEach((workflow, index) => {
      set(workflow, 'isSelected', this.selectedWorkflows.includes(workflow.id));
    });
  }

  @action
  onBulkSelect() {
    let allWorkflowsSelected = this.workflows.every((workflow) =>
      this.selectedWorkflows.includes(workflow.id),
    );
    if (allWorkflowsSelected) {
      this.resetBulkSelect();
    } else if (this.selectedWorkflows.length >= 0) {
      this.selectedWorkflows = this.workflows.map((workflow) => workflow.id);
      this.workflows.forEach((workflow, index) => {
        if (index < this.workflows.length) {
          set(workflow, 'isSelected', true);
        }
      });
    }
  }

  @action
  resetBulkSelect() {
    this.selectedWorkflows = [];
    this.workflows.forEach((workflow, index) => {
      if (index < this.workflows.length) {
        set(workflow, 'isSelected', false);
      }
    });
  }

  @action closeModal() {
    this.modalService.closeModal();
  }

  @dropTask
  *bulkDeleteWorkflows() {
    try {
      yield post(`/ember/workflows/bulk_delete`, {
        app_id: this.app.id,
        workflow_ids: this.selectedWorkflows.map(Number),
        source: 'inbox_rules_cleanup_wizard',
      });
      yield this.selectedWorkflows.forEach((workflow) => {
        let recordToDelete = this.store.peekRecord('workflow', workflow);
        this.store.unloadRecord(recordToDelete);
      });
      this.closeModal();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.inbox-rules.index.deprecation.cleanup-modal.delete-success', {
          count: this.selectedWorkflows.length,
        }),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'deleted',
        object: 'stale_inbox_rules',
        place: 'inbox_rules_clean_up',
      });
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('settings.inbox-rules.list.delete-popup.bulk-error'),
      );
    }
  }
}
