/* import __COLOCATED_TEMPLATE__ from './visibility.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Visibility extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get isVisibilityLimited() {
    return this.args.isVisibilityLimited;
  }

  get visibleToTeamIds() {
    return this.args.visibleToTeamIds;
  }

  get attributeVisibilityText() {
    let attributeType = this.args.isConversationTicketType ? 'conversations' : 'tickets';
    return `Visible only for ${attributeType} assigned to specific team(s)`;
  }

  @action
  toggleVisibility() {
    if (this.isVisibilityLimited) {
      this.args.onVisibilityChange(this.visibleToTeamIds, !this.isVisibilityLimited);
    } else {
      this.args.onVisibilityChange(this.visibleToTeamIds, !this.isVisibilityLimited);
    }
  }

  @action
  updateTeamIds(teamIds) {
    this.args.onVisibilityChange(teamIds, this.isVisibilityLimited);
  }
}
