/* import __COLOCATED_TEMPLATE__ from './call-type-filter.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

export default class CallTypeFilter extends Component {
  @service intercomEventService;
  @service intl;
  @tracked label = this.currentLabel;
  selectedDropdownItems = this.args.selected.values || [];

  get callTypeLabels() {
    return {
      phone: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.call-type-filter.phone',
      ),
      messenger: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.call-type-filter.messenger',
      ),
    };
  }

  get items() {
    return this.groupList;
  }

  get selectedStates() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get allItems() {
    return Object.entries(this.callTypeLabels).map(([value, text]) => ({
      text,
      value,
      isSelected: this.selectedDropdownItems.includes(value),
    }));
  }

  get groupList() {
    return [
      {
        items: this.allItems,
      },
    ];
  }

  get currentLabel() {
    if (
      this.selectedDropdownItems?.length &&
      this.selectedDropdownItems[0] !== REPORTING_FILTER_SELECT_ALL
    ) {
      return this.selectedDropdownItems.map((value) => this.callTypeLabels[value]).join(', ');
    } else {
      return this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.call-type-filter.any',
      );
    }
  }

  @action
  onClose() {
    if (this.isDestroying) {
      return;
    }

    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_call_type',
      object: this.args.analyticsObject,
      state: this.selectedStates,
    });

    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }
}
