/* import __COLOCATED_TEMPLATE__ from './custom-session-length-setting.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class CustomSessionLengthSetting extends Component {
  @service intl;
  @service intercomEventService;

  @tracked settings = this.args.settings;

  get groupList() {
    return [
      {
        items: this.settings.customSessionLengthValidExpiries.map((expiry) => {
          return {
            text: this.formatExpiry(expiry),
            value: expiry,
          };
        }),
      },
    ];
  }

  formatExpiry(expiryMinutes) {
    let hours = Math.floor(expiryMinutes / 60);
    let days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else {
      return `${expiryMinutes} minutes`;
    }
  }

  get selectedExpiryMoreThanFourDays() {
    return this.settings.customSessionLength > 60 * 24 * 4;
  }

  get selectedExpiry() {
    return this.formatExpiry(this.settings.customSessionLength);
  }

  @action
  toggleCustomSessionLengthEnabled() {
    this.settings.customSessionLengthEnabled = !this.settings.customSessionLengthEnabled;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'custom_session_length_switch',
      custom_session_length_enabled: this.settings.customSessionLengthEnabled,
    });
  }

  @action
  updateCustomSessionLength(expiry) {
    this.settings.customSessionLength = expiry;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated',
      object: 'custom_session_length',
      custom_session_length: this.settings.customSessionLength,
    });
  }
}
