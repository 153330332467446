/* import __COLOCATED_TEMPLATE__ from './keyphrase-input.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import EmberObject, { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class KeyphraseInputComponent extends Component {
  @tracked inputHasFocus = false;
  @tracked newKeyphrase = '';

  @tracked selectedKeyphrases =
    this.args.keyphrases?.map((keyphrase) => {
      return EmberObject.create({ name: keyphrase });
    }) || [];

  @action
  removeKeyphrase(_, index) {
    let keyphrases = [...this.selectedKeyphrases];
    keyphrases.splice(index, 1);
    this.selectedKeyphrases = keyphrases;
    this.updateKeyphrases();
  }

  @action
  onInputEnter() {
    if (this.newKeyphrase.length > 0) {
      this.selectedKeyphrases = [
        ...this.selectedKeyphrases,
        EmberObject.create({ name: this.newKeyphrase }),
      ];
      this.newKeyphrase = '';
      this.updateKeyphrases();
    }
  }

  @action
  onFocus() {
    this.inputHasFocus = true;
  }

  @action
  onBlur() {
    this.onInputEnter();
    this.inputHasFocus = false;
  }

  updateKeyphrases() {
    this.args.onUpdateKeyphrases(this.selectedKeyphrases.map((k) => k.name));
  }
}
