/* import __COLOCATED_TEMPLATE__ from './reassign.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class extends Component {
  @service intl;
  conversationReassignmentsAreValid = false;

  get teammates() {
    return this.args.teammates || [this.args.teammate];
  }

  get thingsToReassign() {
    return this.intl.t('settings.teammates.remove-teammate.reassign.things-to-reassign-question');
  }

  get possibleSendersForMessages() {
    let teammateIds = this.teammates.map((teammate) => teammate.id);
    return this.args.app.assignableCreators.filter(
      (assignableAdmin) => !teammateIds.includes(assignableAdmin.id),
    );
  }

  get possibleAuthorsForArticles() {
    return this.possibleSendersForMessages;
  }

  get possibleOwnersForReports() {
    let teammateIds = this.teammates.map((teammate) => teammate.id);
    return this.args.app.assignableCreators.filter((teammate) => {
      return (
        teammate.currentAppPermissions &&
        teammate.currentAppPermissions.can_reporting__reports__update &&
        !teammateIds.includes(teammate.id)
      );
    });
  }

  get possibleAssigneesForOwnedUsersLeadsAndAccounts() {
    return this.possibleSendersForMessages;
  }

  get selectionIsValid() {
    let reassignments = this.args.reassignments;
    return (
      this.conversationReassignmentsAreValid &&
      isPresent(reassignments.messages) &&
      isPresent(reassignments.articles) &&
      (isPresent(reassignments.reportOwner) || !this.args.app.canShareReportsInternally) &&
      isPresent(reassignments.ownedUsersLeadsAndAccounts)
    );
  }

  @action
  updateSelection(key, teammateId) {
    this.args.reassignments[key] = teammateId;
    this.args.updateSelection(this.selectionIsValid);
  }

  @action
  updateConversationsSelection(conversationReassignmentsAreValid) {
    this.conversationReassignmentsAreValid = conversationReassignmentsAreValid;
    this.args.updateSelection(this.selectionIsValid);
  }
}
