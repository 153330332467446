/* import __COLOCATED_TEMPLATE__ from './base-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

export default class BaseFilter extends Component {
  @service intercomEventService;
  @service intl;
  // store the selection here so that the UI doesn't update until the drop down is closed
  selectedDropdownItems = this.args.selected;

  get items() {
    return this.groupList;
  }

  get selectedItems() {
    return this.args.selected?.filter((item) => item !== REPORTING_FILTER_SELECT_ALL) || [];
  }

  get groupList() {
    return [
      {
        items: Object.entries(this.args.availableValues).map(([value, text]) => ({
          text,
          value,
          isSelected: this.selectedItems.includes(value),
        })),
      },
    ];
  }

  get label() {
    if (this.selectedItems?.length && this.selectedItems[0] !== 'any') {
      let mapped = this.selectedItems.map((value) => this.args.availableValues[value]);
      return this.intl.formatList(mapped, { type: 'conjunction' });
    } else {
      return (
        this.args.defaultLabel ||
        this.intl.formatList(Object.values(this.args.availableValues), { type: 'conjunction' })
      );
    }
  }

  @action
  onClose() {
    if (
      !this.selectedDropdownItems.length ||
      this.selectedDropdownItems.length === this.args.availableValues.length
    ) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered',
      object: this.args.analyticsObject,
      place: this.args.analyticsPlace,
      filter_value: this.selectedDropdownItems,
    });

    this.args.setSelected(this.selectedDropdownItems);
  }
}
