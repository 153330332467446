/* import __COLOCATED_TEMPLATE__ from './teammate-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import {
  MEMBER_OF_TEAM,
  NOT_MEMBER_OF_TEAM,
  TEAM_OPTIONS,
} from 'embercom/lib/reporting/custom/data-config-builder-helpers';
import { first } from 'underscore';

const UNASSIGNED_TEAMMATE_ID = '0';
const UNASSIGNED_TEAM_ID = '0';

export default class TeammateFilter extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;
  @service filterPatternService;

  @tracked availableTeammates = this.availableTeammatesForFilter;
  @tracked label = this.currentLabel;
  @tracked operator = this.startingOperator;
  @tracked visibleTeammates = [];
  @tracked availableTeams = this.teams;
  @tracked selectedTeamDropDownItems = this.startingTeamDropDownItems;
  @tracked selectedTeammateDropDownItems = this.startingTeammateDropDownItems;

  get startingTeamDropDownItems() {
    if (TEAM_OPTIONS.includes(this.startingOperator)) {
      let firstTeam = first(this.teamsId);
      let result = this.args.selected.values?.length
        ? this.args.selected.values
        : [firstTeam].compact();
      return result;
    }
    return [];
  }

  get startingTeammateDropDownItems() {
    if (!TEAM_OPTIONS.includes(this.startingOperator)) {
      return this.selectedValues;
    }
    return [];
  }

  get teamFilterOptions() {
    return [
      { option: MEMBER_OF_TEAM, label: this.filterPatternService.mappings[MEMBER_OF_TEAM] },
      { option: NOT_MEMBER_OF_TEAM, label: this.filterPatternService.mappings[NOT_MEMBER_OF_TEAM] },
    ];
  }

  get isTeammateCurrentlyAssignedFilter() {
    return this.args.type === 'conversation.admin_assignee_id';
  }

  get availableTeammatesForFilter() {
    if (this.isTeammateCurrentlyAssignedFilter) {
      return [this.appService.app.unassignedAdmin].concat(this.appService.app.humanAdmins);
    }

    return this.appService.app.humanAdmins;
  }

  get startingOperator() {
    let operator = this.args.selected.operator;
    // https://github.com/intercom/intercom/issues/330737
    if (this.isTeammateCurrentlyAssignedFilter) {
      if (operator === 'exists') {
        return 'category';
      } else if (operator === 'not_exists') {
        return 'not_in_category';
      }
    }

    return operator || 'category';
  }

  get selectedValues() {
    let values = this.args.selected.values || [];
    return values[0] === REPORTING_FILTER_SELECT_ALL ? [] : values;
  }
  get items() {
    if (this.appService.app.canUseOperatorTeammateFilter && this.args.type === 'teammate_closed') {
      let operator = this.appService.app.operatorBot;
      return [
        {
          heading: this.intl.t('reporting.custom-reports.operator-header'),
          items: [this._dropdownEntry(operator), ...this.allItems],
        },
      ];
    } else {
      return [
        {
          heading: this.intl.t('reporting.custom-reports.teammate-header'),
          items: this.allItems,
        },
      ];
    }
  }

  get allItems() {
    return this.visibleTeammates.map((teammate) => this._dropdownEntry(teammate));
  }

  teammateItemLabel(teammate) {
    if (teammate.id === UNASSIGNED_TEAMMATE_ID) {
      return this.intl.t('reporting.filter-bar.unassigned-teammate');
    }

    return teammate.display_as_assignee;
  }

  _dropdownEntry(teammate) {
    return {
      model: teammate,
      text: this.teammateItemLabel(teammate),
      value: teammate.id.toString(),
      component: 'reporting/flexible/filter-bar/teammate-item',
      isSelected: this.selectedTeammateDropDownItems.includes(teammate.id.toString()),
    };
  }

  get selectedTeammateIds() {
    return this.selectedTeammateDropDownItems.filter(
      (item) => item !== REPORTING_FILTER_SELECT_ALL,
    );
  }

  get selectedTeammateNames() {
    if (this.selectedTeammateIds.length === 0) {
      return this.intl.t('reporting.custom-reports.any');
    }

    let operatorAndTeammates = [this.appService.app.operatorBot, ...this.availableTeammates];
    let teammateNames = this.selectedTeammateIds.map((teammateId) => {
      return (
        operatorAndTeammates.find((teammate) => teammate.id.toString() === teammateId)
          ?.display_as_assignee || this.intl.t('reporting.custom-reports.former-teammate')
      );
    });
    return teammateNames.join(', ');
  }

  get name() {
    return this.args.labelPrefix || this.intl.t('reporting.custom-reports.teammate-is');
  }

  get currentLabel() {
    switch (this.operator) {
      case 'category':
      case 'not_in_category': {
        return `${this.filterPatternService.mappings[this.operator]} ${this.selectedTeammateNames}`;
      }
      case MEMBER_OF_TEAM:
      case NOT_MEMBER_OF_TEAM:
        return `${this.filterPatternService.mappings[this.operator]} ${this.selectedTeamNames}`;
      case 'exists':
      case 'not_exists':
        return this.filterPatternService.mappings[this.operator];
    }
  }

  get valuesForFilter() {
    if (TEAM_OPTIONS.includes(this.operator)) {
      return this.selectedTeamDropDownItems.length
        ? this.selectedTeamDropDownItems
        : [first(this.teamsId)].compact();
    } else {
      let valuesOrPlaceholder = this.selectedTeammateDropDownItems.length
        ? this.selectedTeammateDropDownItems
        : [REPORTING_FILTER_SELECT_ALL];

      return ['category', 'not_in_category'].includes(this.operator) ? valuesOrPlaceholder : [];
    }
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    let baseEvent = {
      action: `filtered_${this.args.type}`,
      object: this.args.analyticsObject,
      operator: this.operator,
    };
    if (TEAM_OPTIONS.includes(this.operator)) {
      this.intercomEventService.trackAnalyticsEvent({
        ...baseEvent,
        team_count: this.selectedTeamDropDownItems.length,
      });
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        ...baseEvent,
        teammate_count: this.selectedTeammateIds.length,
      });
    }

    this.args.setSelected(this.valuesForFilter, this.operator);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedTeammateDropDownItems = [];
    this.selectedTeamDropDownItems = [];
    this.operator = null;
    this.args.removeFilter();
  }

  @action
  filterValues(filter) {
    this.visibleTeammates = this.availableTeammates.filter((teammate) =>
      teammate.name.toLowerCase().includes(filter.toLowerCase()),
    );
  }

  get teamsId() {
    return this.teams.map((team) => team.id);
  }

  get teams() {
    return this.appService.app.teams;
  }

  get hasTeams() {
    return this.teams.length > 0;
  }

  get selectedTeamNames() {
    let selectedTeamIds = this.selectedTeamDropDownItems.filter(
      (item) => item !== REPORTING_FILTER_SELECT_ALL,
    );

    if (selectedTeamIds.length === 0) {
      let firstTeam = first(this.availableTeams);
      return firstTeam?.display_as_assignee || '';
    }

    let teamNames = selectedTeamIds.map((teamId) => {
      return (
        this.availableTeams.find((team) => team.id.toString() === teamId)?.display_as_assignee ||
        this.intl.t(
          'components.reporting.custom.chart-builder.filter-bar.team-assigned-filter.former-team',
        )
      );
    });
    return teamNames.join(', ');
  }

  teamItemLabel(team) {
    if (team.id === UNASSIGNED_TEAM_ID) {
      return this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.team-assigned-filter.unassigned-team',
      );
    } else {
      return team.display_as_assignee;
    }
  }

  _teamDropDownItem(team) {
    return {
      model: team,
      text: this.teamItemLabel(team),
      value: team.id.toString(),
      component: 'reporting/flexible/filter-bar/teammate-item',
      isSelected: this.selectedTeamDropDownItems.includes(team.id.toString()),
    };
  }

  get allTeams() {
    return [
      {
        items: this.availableTeams.map((team) => this._teamDropDownItem(team)),
      },
    ];
  }

  @action
  onTeamSearch(filterValue) {
    this.availableTeams = this.teams.filter((team) =>
      team.name.toLowerCase().includes(filterValue.toLowerCase()),
    );
  }
}
