/* import __COLOCATED_TEMPLATE__ from './permissions-crud-section-panel.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    permissionsObject: any;
    changed: () => void;
    isDisabled: boolean;
    isDisabledByRole: boolean;
    updatePermission: (key: string, value?: boolean) => void;
    unallowedPermissionList: any;
    seatDependentPermissionList: any;
  };
  Blocks: {
    default: [{ crud: any }];
  };
}

const PermissionsCrudSectionPanel = templateOnlyComponent<Signature>();
export default PermissionsCrudSectionPanel;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'settings/teammates/permissions/permissions-crud-section-panel': typeof PermissionsCrudSectionPanel;
  }
}
