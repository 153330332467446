/* import __COLOCATED_TEMPLATE__ from './metric-with-comparison.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';

export default class MetricWithComparisonComponent extends Component {
  get hasChange() {
    return (
      this.args.current !== this.args.previous &&
      this.args.previous !== 0 &&
      this.args.previous !== null
    );
  }

  get changePercentage() {
    return Math.abs(((this.args.current - this.args.previous) / this.args.previous) * 100);
  }

  get arrowIcon() {
    if (this.args.current > this.args.previous) {
      return 'small-up-arrow';
    } else {
      return 'small-down-arrow';
    }
  }

  get improving() {
    return (
      (this.args.improvementDirection === 'increasing' && this.args.current > this.args.previous) ||
      (this.args.improvementDirection === 'decreasing' && this.args.current < this.args.previous)
    );
  }

  get comparisonRangeFormatted() {
    let { isSingleDay, comparisonStartMoment, comparisonEndMoment, endMoment } = this.args.range;

    if (isSingleDay) {
      return comparisonStartMoment.format('MMM D');
    }

    let compareAcrossYears = comparisonStartMoment.year() !== endMoment.year();
    let format = compareAcrossYears ? 'MMM D, YYYY' : 'MMM D';

    return `${comparisonStartMoment.format(format)} - ${comparisonEndMoment.format(format)}`;
  }
}
