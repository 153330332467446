/* import __COLOCATED_TEMPLATE__ from './inbox-redirect-banner.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type RouterService from '@ember/routing/router-service';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import type InboxState from 'embercom/services/inbox-state';

interface Args {
  conversationId: number;
  partId: number | undefined;
}

export default class ReportingCustomReportConversationStreamInboxRedirectBanner extends Component<Args> {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare inboxState: InboxState;

  get app() {
    return this.appService.app;
  }

  get showLinkToConversationPart() {
    return isPresent(this.args.partId);
  }

  get partURL() {
    let conversationUrl = this.router.urlFor(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      this.appService.app.id,
      InboxCategory.Shared,
      InboxType.All,
      this.args.conversationId,
    );

    return `${conversationUrl}#part_id=comment-${this.args.conversationId}-${this.args.partId}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ConversationStream::InboxRedirectBanner': typeof ReportingCustomReportConversationStreamInboxRedirectBanner;
    'reporting/custom/report/conversation-stream/inbox-redirect-banner': typeof ReportingCustomReportConversationStreamInboxRedirectBanner;
  }
}
