/* import __COLOCATED_TEMPLATE__ from './new-conversations-columnchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  COUNT,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  CONTINENT,
  COUNTRY,
  CHANNEL,
} from 'embercom/lib/reporting/flexible/constants';
import { inject as service } from '@ember/service';

export default class NewConversationsColumnchart extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    let groupings = [
      {
        ...GROUPINGS[CONTINENT],
        text: this.intl.t(GROUPINGS[CONTINENT].textKey),
        createTitleTooltipText: () =>
          this.intl.t('reporting.conversations-report.new-conversations.tooltip', {
            grouping: this.intl.t(GROUPINGS[CONTINENT].textKey),
          }),
      },
      {
        ...GROUPINGS[COUNTRY],
        text: this.intl.t(GROUPINGS[COUNTRY].textKey),
        createTitleTooltipText: () =>
          this.intl.t('reporting.conversations-report.new-conversations.tooltip', {
            grouping: this.intl.t(GROUPINGS[COUNTRY].textKey),
          }),
      },
    ];
    groupings.push({
      ...GROUPINGS[CHANNEL],
      text: this.intl.t(GROUPINGS[CHANNEL].textKey),
      createTitleTooltipText: () =>
        this.intl.t('reporting.conversations-report.new-conversations.tooltip', {
          grouping: this.intl.t(GROUPINGS[CHANNEL].textKey),
        }),
    });

    viewConfig.formatUnit = { unit: units.value, displayUnit: 'conversation' };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
        text: this.intl.t(GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT].textKey),
        isComparison: true,
        createTitleTooltipText: () =>
          this.intl.t(
            'reporting.conversations-report.new-conversations.first-user-conversation-part-created-tooltip',
          ),
      },
      rest: groupings,
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      xAxis: {
        type: 'temporal',
        data: {
          interval: FILTER_PROPERTY_PLACEHOLDER,
          property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
        },
      },
      series: [
        {
          name: 'comparison_columnchart',
          source: this.app.canUseFeature('reporting-ember-conversation-index-merging')
            ? 'conversation'
            : 'conversation_with_message_thread',
          type: COUNT,
          data: { property: FIRST_USER_CONVERSATION_PART_CREATED_AT },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
      ],
    };
  }
}
