/* import __COLOCATED_TEMPLATE__ from './conversation-history-settings.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import moment from 'moment-timezone';

const SETTING_DEFAULT_BACKFILL_DATE = moment('2022-10-20T02:10:00.000Z');

export default class ConversationHistorySettings extends Component {
  @service store;
  @service notificationsService;
  @service intl;
  @service appService;
  @service intercomEventService;

  constructor() {
    super(...arguments);
    this.trackOpenAnalytics();
  }

  @task({ enqueue: true })
  *updateSetting() {
    try {
      yield this.args.settings.save();

      this.trackUpdateAnalytics();

      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (error) {
      this.args.settings.toggleProperty('removeConversationHistory');
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  toggleRemoveConversationHistory() {
    this.args.settings.toggleProperty('removeConversationHistory');
    this.updateSetting.perform();
  }

  trackOpenAnalytics() {
    let appDefault = moment(this.appService.app.created_at).isAfter(SETTING_DEFAULT_BACKFILL_DATE);

    this.trackAnalytics('opened', appDefault);
  }

  trackUpdateAnalytics() {
    let action = this.args.settings.removeConversationHistory ? 'turned_on' : 'turned_off';

    let appDefault = moment(this.appService.app.created_at).isAfter(SETTING_DEFAULT_BACKFILL_DATE);

    this.trackAnalytics(action, appDefault);
  }

  trackAnalytics(action, appDefault) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'email_remove_conversation_history_settings',
      app_default: appDefault,
    });
  }
}
