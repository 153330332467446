/* import __COLOCATED_TEMPLATE__ from './replies-sent-comparison-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import {
  COUNT,
  COMMENT_CREATED_AT,
  TEAMMATE_REPLIES_FILTERS,
} from 'embercom/lib/reporting/flexible/constants';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import { inject as service } from '@ember/service';

const SERIES_NAME = 'replies_sent_comparison_counter';

export default class RepliesSentComparisonCounter extends Component {
  @service intl;

  get modifiedFilters() {
    let newFilters = { ...this.args.filters };

    if (newFilters.admin_participant_ids) {
      newFilters.teammate_id = newFilters.admin_participant_ids;
      delete newFilters.admin_participant_ids;
    }

    return newFilters;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[COMMENT_CREATED_AT],
        isComparison: true,
        createTitleTooltipText: () =>
          this.intl.t('reporting.conversations-report.replies-sent-comparison-counter.tooltip'),
      },
    };
    viewConfig.counter = {
      [SERIES_NAME]: {
        improvementDirection: improvementDirections.INCREASING,
      },
    };

    return viewConfig;
  }
  get dataConfig() {
    return {
      series: [
        {
          name: SERIES_NAME,
          source: 'consolidated_conversation_part',
          type: COUNT,
          data: { property: COMMENT_CREATED_AT },
          time: {
            property: COMMENT_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'and',
            filters: TEAMMATE_REPLIES_FILTERS,
          },
        },
      ],
    };
  }
}
