/* import __COLOCATED_TEMPLATE__ from './remove-tag-from-user.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Tag from 'embercom/objects/rules/actions/tag';
import type TagModel from 'embercom/models/tag';

interface Signature {
  Element: HTMLElement;
  Args: {
    action: $TSFixMe;
    allRuleActions: $TSFixMe[];
    actionEditorContext: $TSFixMe;
    onChange?: () => void;
    isDisabled?: boolean;
    onDelete?: () => void;
    rule: $TSFixMe;
  };
  Blocks: {
    default: [{ opener: unknown; actionTagName: string }];
  };
}

export default class RemoveTagFromUser extends Component<Signature> {
  @service appService: $TSFixMe;

  tag = new Tag('remove-tag-from-user');

  noTags = [
    {
      value: null,
      isDisabled: true,
      component: 'rules/actions/tag-empty-item',
    },
  ];

  get actionTagName(): string {
    let tagId = this.args.action.actionData.tag_id;
    return this.tag.getActionTagName(tagId);
  }

  get tagItems() {
    return this.tag.getTagItems(this.selectableTags);
  }

  @action updateTag(tag: Tag) {
    this.args.action.actionData.tag_id = tag;
    this.args.action.actionData = { tag_id: tag };
    this.args?.onChange?.();
  }

  get selectableTags(): TagModel[] {
    return this.tag.getSelectableTags(this.args.allRuleActions, this.args.action);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Rules::Actions::RemoveTagFromUser': typeof RemoveTagFromUser;
    'rules/actions/remove-tag-from-user': typeof RemoveTagFromUser;
  }
}
