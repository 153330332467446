/* import __COLOCATED_TEMPLATE__ from './edit-nav-create-folder.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type ReportingFolderManagerService from 'embercom/services/reporting-folder-manager-service';

interface Arguments {
  item: {
    onCreateFolder: () => void;
  };
}

export default class EditNavCreateFolder extends Component<Arguments> {
  @service declare reportingFolderManagerService: ReportingFolderManagerService;
  @service declare permissionsService: any;

  get canAdminManageFlexibleFolders() {
    return this.reportingFolderManagerService.canAdminManageFlexibleFolders;
  }

  @action
  openPermissionsModal() {
    this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
      'can_reporting__navigation_folders__manage',
    );
  }

  @action
  handleClick() {
    if (this.canAdminManageFlexibleFolders) {
      this.args.item.onCreateFolder();
    } else {
      this.openPermissionsModal();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::NavSubmenuComponents::EditNavCreateFolder': typeof EditNavCreateFolder;
    'reporting/nav-submenu-components/edit-nav-create-folder': typeof EditNavCreateFolder;
  }
}
