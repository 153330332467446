/* import __COLOCATED_TEMPLATE__ from './metrics-playground.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Chart from 'embercom/models/reporting/custom/chart';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import type Report from 'embercom/models/reporting/custom/report';
// @ts-ignore
import { copy } from 'ember-copy';
import { tracked } from '@glimmer/tracking';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import { type MetricDefinition } from 'embercom/objects/reporting/unified/metrics/types';

const DEFAULT_CHART_DEFINITION = {
  id: 'test',
  title: 'Test metric chart',
  tooltip: 'Some tooltip',
  visualization_type: VISUALIZATION_TYPES.COUNTER,
  visualization_options: {
    show_time_comparison: true,
  },
  width_type: WIDTH_TYPES.FULL,
  height_type: HEIGHT_TYPES.X_SMALL,
  chart_series: [
    {
      metric_id: 'test.metric',
    },
  ],
  date_range: {
    property: 'conversation_created_at',
    selection: 'past_12_weeks',
    start: null,
    end: null,
  },
  stacked: false,
  view_by: null,
  view_by_time_interval: null,
  view_by_display_limit: 10000,
  view_by_display_other: false,
};

const DEFAULT_METRIC_DEFINITION: MetricDefinition = {
  id: 'test.metric',
  type: 'field',
  name: null,
  description: null,
  property: 'conversation_created_at',
  supportedAggregations: ['count'],
  unit: 'value',
  timeProperty: 'conversation_created_at',
  improvementDirection: 'decreasing',
  source: 'conversation',
  datasetId: 'conversation',
  filter: {
    type: 'category',
    data: {
      property: 'conversation_state',
      values: ['opened', 'closed'],
    },
  },
};

export default class MetricsPlayground extends Component {
  @service declare reportingMetrics: ReportingMetrics;
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare notificationsService: any;

  reportId = 'metric-playground';

  @tracked report: Report | null = null;
  @tracked chartDefinitionsJSON = '';
  @tracked metricDefinitionsJSON = '';
  @tracked openSectionId: string | null = null;
  @tracked errorMessage: string | null = null;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.reportingMetrics.loadMetricsAndProperties();
    this.reset();
  }

  willDestroy() {
    super.willDestroy();
    this.cleanUp();
  }

  get reportState() {
    return {
      settings: {},
      dateRange: null,
      filters: {},
      isStatic: true,
      colorChartsByPoint: true,
    };
  }

  get reportDefinition() {
    return {
      id: this.reportId,
      title: null,
      description: null,
      charts: this.chartDefinitions,
      date_range: null,
      filters: {},
    };
  }

  get sanitizedReportDefinition() {
    let sanitizedReportDefinition = copy(this.reportDefinition, true);
    // Sanitize chart ids so we don't accidentely overwrite other custom charts in the store
    sanitizedReportDefinition['charts'].forEach((chart: any, index: number) => {
      chart['id'] = `chart-${this.reportId}-${index}`;
    });
    return sanitizedReportDefinition;
  }

  get chartDefinitions() {
    return JSON.parse(this.chartDefinitionsJSON);
  }

  cleanUp() {
    this.errorMessage = null;
    if (this.report) {
      let _report = this.report;
      this.report = null;
      // Remove all chart records
      _report.charts.toArray().forEach((chart: Chart) => {
        chart.unloadRecord();
      });
      // Remove the report record
      _report.unloadRecord();
    }
    this.reportingMetrics.deleteTemporaryMetricDefinitions();
  }

  generateReportAndCharts() {
    this.store.pushPayload({
      'reporting/custom/report': this.sanitizedReportDefinition,
    });
    this.report = this.store.peekRecord('reporting/custom/report', this.reportId);
  }

  generateMetrics() {
    let metricDefinitions = JSON.parse(this.metricDefinitionsJSON);
    this.reportingMetrics.addTemporaryMetricDefinitions(metricDefinitions);
  }

  storeValues() {
    let item = {
      metricDefinitionsJSON: this.metricDefinitionsJSON,
      chartDefinitionsJSON: this.chartDefinitionsJSON,
    };
    localStorage.setItem('metricsPlayground', JSON.stringify(item));
  }

  @action
  loadPreviousState() {
    this.loadValues();
    this.execute();
  }

  loadValues() {
    let item = localStorage.getItem('metricsPlayground');
    if (item) {
      let value = JSON.parse(item);
      this.metricDefinitionsJSON = value.metricDefinitionsJSON;
      this.chartDefinitionsJSON = value.chartDefinitionsJSON;
    }
  }

  @action
  execute() {
    this.storeValues();
    this.cleanUp();
    try {
      this.generateMetrics();
    } catch (e) {
      console.error('Metric definition error!', e);
      this.errorMessage = `Metric definition error: ${e.toString()}`;
      return;
    }
    try {
      this.generateReportAndCharts();
    } catch (e) {
      console.error('Chart definition error!', e);
      this.errorMessage = `Chart definition error: ${e.toString()}`;
    }
  }

  @action
  reset() {
    this.cleanUp();
    this.chartDefinitionsJSON = JSON.stringify([DEFAULT_CHART_DEFINITION], null, 2);
    this.metricDefinitionsJSON = JSON.stringify([DEFAULT_METRIC_DEFINITION], null, 2);
    this.generateMetrics();
    this.generateReportAndCharts();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::MetricsPlayground': typeof MetricsPlayground;
    'reporting/metrics-playground': typeof MetricsPlayground;
  }
}
