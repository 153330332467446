/* import __COLOCATED_TEMPLATE__ from './provisioning-reassign-admins.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ProvisioningReassignAdmins extends Component {
  @action
  updateDefaultAdmin(selection, teammateId) {
    switch (selection) {
      case 'messages':
        this.args.scimSettings.newMessageOwnerId = teammateId;
        break;
      case 'articles':
        this.args.scimSettings.newArticleAuthorId = teammateId;
        break;
      case 'owner':
        this.args.scimSettings.newOwnerId = teammateId;
        break;
      case 'reportOwner':
        this.args.scimSettings.newReportOwnerId = teammateId;
        break;
      case 'conversation':
        this.args.scimSettings.newAssigneeId = teammateId;
    }
  }
}
