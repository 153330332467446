/* import __COLOCATED_TEMPLATE__ from './identify-existing-users.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';

export default class IdentifyExistingUsers extends Component {
  @service notificationsService;
  @service intl;

  @task({ restartable: true })
  *togglePhoneBasedUserAssociation(newValue) {
    this.args.settings.phoneBasedUserAssociation = newValue;
    yield this.args.settings.save();
    this.notificationsService.notifyConfirmation(
      this.intl.t('components.settings.switch.setup.settings-saved'),
    );
  }
}
