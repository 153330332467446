/* import __COLOCATED_TEMPLATE__ from './routes-tabs.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class RoutesTabs extends Component {
  @service router;

  get currentRoute() {
    return this.router.currentRouteName;
  }

  @action
  changeRoute(route) {
    this.router.transitionTo(route);
  }
}
