/* import __COLOCATED_TEMPLATE__ from './bar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { fmtStyle, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import { equal } from '@ember/object/computed';

export default Component.extend({
  tagName: '',
  isVertical: equal('orientation', 'vertical'),
  horizontalGroupStyle: fmtStyle(
    'transform: translate3d(%@%,0,0) scale3d(%@,1,1);',
    'start',
    'scale',
  ),
  verticalGroupStyle: fmtStyle(
    'transform: translate3d(0,%@px,0) scale3d(1,%@,1);',
    'start',
    'scale',
  ),
  containerStyle: ternaryToProperty('isVertical', 'verticalGroupStyle', 'horizontalGroupStyle'),
});
