/* import __COLOCATED_TEMPLATE__ from './user-info.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';

export default class SeriesUserInfoComponent extends Component {
  constructor() {
    super(...arguments);
    this.args.user.loadSeries.perform();
  }
}
