/* import __COLOCATED_TEMPLATE__ from './enforce-sso-warning.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class EnforceSSOWarning extends Component {
  @service modalService;

  @action handleEnforceSSO() {
    this.args.options.enforceSSO();
    this.closeModal();
  }

  @action closeModal() {
    this.modalService.closeModal();
  }
}
