/* import __COLOCATED_TEMPLATE__ from './delete-action.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class DeleteAction extends Component {
  @action
  deleteRow() {
    return this.args.data.deleteRow(this.args.row);
  }
}
