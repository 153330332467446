/* import __COLOCATED_TEMPLATE__ from './column-selection.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ColumnSelection extends Component {
  @service appService;

  get checked() {
    return this.args.allColumnNames.reduce(
      (acc, columnName) => (
        (acc[columnName] = this.args.selectedColumns.includes(columnName)), acc
      ),
      {},
    );
  }

  get selectAllChecked() {
    return this.args.allColumns.reduce((acc, section) => {
      let shouldBeChecked = section.columns.every((column) =>
        this.args.selectedColumns.includes(column.name),
      );
      acc[section.sectionName] = shouldBeChecked;
      return acc;
    }, {});
  }

  _updateColumns(columnNames, newValue) {
    let selectedColumnSet = new Set(this.args.selectedColumns);
    columnNames.forEach((column) => {
      if (newValue) {
        selectedColumnSet.add(column);
      } else {
        selectedColumnSet.delete(column);
      }
    });
    let selectedColumns = this.args.allColumnNames.filter((column) =>
      selectedColumnSet.has(column),
    );
    this.args.updateSelectedColumns(selectedColumns);
  }

  @action
  updateColumn(columnName, event) {
    let checkedValue = event?.target?.checked;
    this._updateColumns([columnName], checkedValue);
  }

  @action
  toggleSelectAll(sectionName, event) {
    let section = this.args.allColumns.find((section) => section.sectionName === sectionName);
    let checkedValue = event?.target?.checked;
    let columnNames = section.columns.map((column) => column.name);
    this._updateColumns(columnNames, checkedValue);
  }
}
