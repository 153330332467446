/* import __COLOCATED_TEMPLATE__ from './number-of-teammate-replies-to-close-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  CLUSTERED_TEAMMATE_REPLIES_COUNT,
  CONVERSATION_STATE,
  MIN,
  MAX,
  MEAN,
  MEDIAN,
} from 'embercom/lib/reporting/flexible/constants';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';

export default class numberOfTeammateRepliesToCloseCounter extends Component {
  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value };
    viewConfig.aggregations = { defaultOption: MEDIAN, rest: [MIN, MAX, MEAN] };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
        isComparison: true,
        createTitleTooltipText: (aggregation) => {
          return `The ${aggregation} number of replies for teammates to close conversations. If teammates send multiple replies before an end-user responds, they’ll only count as one reply.`;
        },
      },
    };
    viewConfig.counter = {
      number_of_teammate_replies_to_close_counter: {
        improvementDirection: improvementDirections.DECREASING,
      },
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      series: [
        {
          name: 'number_of_teammate_replies_to_close_counter',
          source: 'conversation_with_message_thread',
          type: MEDIAN,
          data: {
            property: CLUSTERED_TEAMMATE_REPLIES_COUNT,
          },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'and',
            filters: [
              {
                type: 'category',
                data: {
                  property: CONVERSATION_STATE,
                  values: ['closed'],
                },
              },
              {
                type: 'range',
                data: {
                  operators: ['gte'],
                  property: CLUSTERED_TEAMMATE_REPLIES_COUNT,
                  values: [1],
                },
              },
            ],
          },
        },
      ],
    };
  }
}
