/* import __COLOCATED_TEMPLATE__ from './table-search-result-keyword-cell.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class TableComponent extends Component {
  @tracked keyword = null;

  constructor() {
    super(...arguments);
    this.keyword = this.args.data.getKeyword(this.args.row);
  }

  @action
  toggleSearchDrawer() {
    this.args.data.toggleSearchDrawer(this.args.row);
  }
}
