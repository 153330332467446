/* import __COLOCATED_TEMPLATE__ from './legacy-chart-tooltips.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

//@pat - borrowed/stolen from jQuery $(el).data() https://github.com/jquery/jquery/blob/0b676ae12d20721e2df6f6f32f37f7302f8805bf/src/data.js#L20-L43
let rbrace = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/;
function castData(data) {
  if (data === 'true') {
    return true;
  }

  if (data === 'false') {
    return false;
  }

  if (data === 'null') {
    return null;
  }

  // Only convert to a number if it doesn't change the string
  if (data === `${+data}`) {
    return +data;
  }

  if (rbrace.test(data)) {
    return JSON.parse(data);
  }

  return data;
}

export default class ReportingChartTooltipsComponent extends Component {
  @tracked tooltipOpenerElement = null;
  @tracked tooltipData = null;

  @action
  handleMouseOver(event) {
    let element = event.target.closest('.js__legacy-reporting-tooltip');
    if (element) {
      this.tooltipData = this.extractData(element);
      this.tooltipOpenerElement = element;
    }
  }

  @action
  handleMouseOut() {
    this.tooltipOpenerElement = null;
    this.tooltipData = null;
  }

  extractData(element) {
    let data = {};
    let dataset = element.dataset;
    Object.keys(dataset).forEach((key) => {
      let datum = dataset[key];
      data[key] = castData(datum);
    });
    return data;
  }
}
