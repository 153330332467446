/* import __COLOCATED_TEMPLATE__ from './checking-dependents.hbs'; */
/* eslint-disable @intercom/intercom/require-empty-tagname */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import { computed, action } from '@ember/object';
import { alias, equal, not, notEmpty, or, reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { ternary, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';
import { groupBy } from 'embercom/helpers/group-by';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { OUTBOUND_EDITOR_ROUTES } from 'embercom/models/outbound/content-wrapper';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';

export default Component.extend({
  init() {
    this._super(...arguments);
    if (isPresent(this.objectId)) {
      this.set('isChecking', true);
      this.checkForDependents();
    }
  },
  intl: service(),
  intercomEventService: service(),
  notificationsService: service(),
  outboundHomeService: service(),
  appService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  modelDataCacheService: service(),

  app: alias('appService.app'),
  hasChecked: false,
  isChecking: true,
  cannotDeleteOrArchive: or(
    'isChecking',
    'hasDependentSegments',
    'hasDependentCustomBots',
    'hasDependentAnswers',
    'hasDependentBehaviors',
    'hasDependentQualificationAttribute',
    'hasDependentTaskBots',
    'hasDependentRules',
    'hasDependentTeamIntros',
    'hasDependentRulesets',
    'hasDependentSeries',
    'hasDependentContents',
    'hasDependentEmailTemplates',
    'hasDependentWorkflowConnectoActions',
    'hasDependentCustomReports',
  ),
  canDeleteOrArchive: not('cannotDeleteOrArchive'),
  dependentSegments: [],
  type: reads('options.dependentObjectService.type'),
  isAttribute: equal('type', 'attribute'),
  isEvent: equal('type', 'event'),
  shouldArchive: or('isAttribute', 'isEvent'),
  objectName: reads('model.name'),
  objectId: computed('isAttribute', 'model.cda_id', 'model.event_id', 'model.id', function () {
    if (this.isAttribute) {
      return this.get('model.cda_id');
    } else if (this.isEvent) {
      return this.get('model.event_id');
    } else {
      return this.get('model.id');
    }
  }),
  routeForRuleset(ruleset) {
    return OUTBOUND_EDITOR_ROUTES[objectNames[ruleset.object_type]];
  },
  objectNameForType(objectType) {
    return objectNames[objectType];
  },

  get inboxRulesLink() {
    return 'apps.app.inbox.automation.workflows.edit';
  },

  get titleForChecking() {
    return this.intl.t('settings.modals.checking-dependents.title-for-checking');
  },

  get titleWithObjectName() {
    switch (this.type) {
      case 'attribute': {
        return this.intl.t(`settings.modals.checking-dependents.title-with-object-name.attribute`, {
          objectName: this.objectName,
        });
      }
      case 'segment': {
        return this.intl.t(`settings.modals.checking-dependents.title-with-object-name.segment`, {
          objectName: this.objectName,
        });
      }
      case 'event': {
        return this.intl.t(`settings.modals.checking-dependents.title-with-object-name.event`, {
          objectName: this.objectName,
        });
      }
      default: {
        return this.intl.t(
          `settings.modals.checking-dependents.title-with-object-name.${this.type}`,
          {
            objectName: this.objectName,
          },
        );
      }
    }
  },
  showCancelButton: or('isChecking', 'canDeleteOrArchive'),
  modalTitle: ternaryToProperty('isChecking', 'titleForChecking', 'titleWithObjectName'),
  hasDependentSegments: notEmpty('dependentSegments'),
  hasDependentCustomBots: notEmpty('dependentCustomBots'),
  hasDependentBehaviors: notEmpty('dependentBehaviors'),
  hasDependentAnswers: notEmpty('dependentAnswers'),
  hasDependentQualificationAttribute: notEmpty('dependentQualificationAttribute'),
  hasDependentTaskBots: notEmpty('dependentTaskBots'),
  hasDependentRules: notEmpty('dependentRules'),
  hasDependentTeamIntros: notEmpty('dependentTeamIntros'),
  hasDependentRulesets: notEmpty('dependentRulesets'),
  hasDependentSeries: notEmpty('dependentSeries'),
  hasDependentContents: notEmpty('dependentContents'),
  hasDependentEmailTemplates: notEmpty('dependentEmailTemplates'),
  hasDependentWorkflowConnectoActions: notEmpty('dependentWorkflowConnectoActions'),
  hasDependentCustomReports: notEmpty('dependentCustomReports'),
  archiveOrDelete: ternary('shouldArchive', 'archive', 'delete'),
  promptMessage: alias('options.promptMessage'),
  rulesetsByContentType: computed('dependentRulesets', function () {
    if (this.hasDependentRulesets) {
      return groupBy(this.dependentRulesets, (item) => item.object_type);
    }
  }),

  checkForDependents() {
    this.get('options.dependentObjectService')
      .getDependentObjects()
      .then((dependentObjects) =>
        this.setProperties({
          dependentSegments: dependentObjects.dependent_segments,
          dependentCustomBots: dependentObjects.dependent_custom_bots,
          dependentBehaviors: dependentObjects.dependent_behaviors,
          dependentAnswers: dependentObjects.dependent_answers,
          dependentQualificationAttribute: dependentObjects.dependent_qualification_attribute,
          dependentTaskBots: dependentObjects.dependent_task_bots,
          dependentRules: dependentObjects.dependent_rules,
          dependentTeamIntros: dependentObjects.dependent_team_intros,
          dependentContents: dependentObjects.dependent_contents,
          dependentEmailTemplates: dependentObjects.dependent_email_templates,
          dependentRulesets: dependentObjects.dependent_rulesets,
          dependentSeries: dependentObjects.dependent_series,
          dependentWorkflowConnectoActions: dependentObjects.dependent_workflow_connector_actions,
          dependentCustomReports: dependentObjects.dependent_custom_reports,
          isChecking: false,
        }),
      );
  },

  archivedConfirmation() {
    switch (this.type) {
      case 'attribute': {
        return this.intl.t(`settings.modals.checking-dependents.archived-attribute`, {
          objectName: this.objectName,
        });
      }
      case 'event': {
        return this.intl.t(`settings.modals.checking-dependents.archived-event`, {
          objectName: this.objectName,
        });
      }
      default: {
        return this.intl.t(`settings.modals.checking-dependents.archived-${this.type}`, {
          objectName: this.objectName,
        });
      }
    }
  },

  closeModal: action(function () {
    this.modalService.closeModal();
  }),

  actions: {
    confirmArchive() {
      this.set('isSaving', true);
      this.get('options.dependentObjectService')
        .archive()
        .then(
          function () {
            this.set('isSaving', false);
            this.notificationsService.notifyConfirmation(this.archivedConfirmation());
            this.closeModal();
          }.bind(this),
        );
    },
    confirmDelete() {
      let itemToDelete = this.model;
      let itemToDeleteName = itemToDelete.get('name');
      let itemType = this.type;
      this.set('isSaving', true);

      if (itemType === 'segment' || itemType === 'tag') {
        this.deleteItemTask.perform(itemToDelete);
        return;
      }

      itemToDelete.deleteRecord().then(
        function () {
          this.set('isSaving', false);
          if (itemType === 'tag') {
            this.app.get('tags').removeObject(itemToDelete);
          } else if (itemType === 'segment') {
            this.app.get('segments').removeObject(itemToDelete);
          }
          this.notificationsService.notifyConfirmation(
            this.intl.t('settings.modals.checking-dependents.delete-confirmation-2', {
              itemToDeleteName,
            }),
          );
          this.closeModal();
        }.bind(this),
      );
    },
    transitionToTaggedMessages() {
      this.outboundHomeService.resetFilters();
      this.set('outboundHomeService.selectedTagValues', [this.model.id]);
      this.transitionToRoute('apps.app.outbound.all');
      this.closeModal();
    },
  },

  deleteItemTask: task(function* (itemToDelete) {
    try {
      yield itemToDelete.destroyRecord();
      this.set('isSaving', false);
      if (this.type === 'segment') {
        this.app.segments.removeObject(itemToDelete);
      } else if (this.type === 'tag') {
        this.app.tags.removeObject(itemToDelete);

        this.intercomEventService.trackAnalyticsEvent({
          action: 'deleted',
          object: itemToDelete,
          place: 'tag_settings',
          location: 'tags',
          owner: 'inbox',
        });
      }

      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.modals.checking-dependents.delete-confirmation', {
          itemToDelete: itemToDelete.get('name'),
        }),
      );
      this.closeModal();
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyError(
        this.intl.t('settings.modals.checking-dependents.request-failed'),
      );
    }
  }).drop(),
});
