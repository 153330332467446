/* import __COLOCATED_TEMPLATE__ from './attribute-descriptor-format.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { CARDINALITY_ICON_LABEL_MAP } from 'embercom/models/custom-objects/constants/relationship-cardinalities';

export default class AttributeDescriptorFormat extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get cardinalityIcon() {
    return CARDINALITY_ICON_LABEL_MAP[this.args.attributeDescriptor.cardinality];
  }
}
