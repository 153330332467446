/* import __COLOCATED_TEMPLATE__ from './category-info-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Args {
  category: TicketCategory;
  onClose: () => void;
}

interface ModalContent {
  title: string;
  steps: string[];
  stepImages: string[];
  bannerContent: string[];
}

export default class CategoryInfoModal extends Component<Args> {
  @service declare intl: IntlService;

  @tracked category = this.args.category;
  @tracked stepImage = this.modalContent.stepImages[0];
  @tracked activeStep = 0;
  @tracked stepBannerInfo = this.modalContent.bannerContent[0];

  private modalContentMap = {
    [TicketCategory.Task]: this.buildBackOfficeContent(),
    [TicketCategory.Request]: this.buildCustomerTicketContent(),
    [TicketCategory.Tracker]: this.buildTrackerTicketContent(),
  };

  @action
  showStep(step: number) {
    this.stepImage = this.modalContent.stepImages[step];
    this.activeStep = step;
    this.stepBannerInfo = this.modalContent.bannerContent[step];
  }

  get modalContent() {
    return this.modalContentMap[this.category];
  }

  private buildBackOfficeContent(): ModalContent {
    return {
      title: this.intl.t('settings.ticket-data.tickets-onboarding.modal.back-office-ticket.title'),
      steps: [
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.back-office-ticket.steps.1'),
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.back-office-ticket.steps.2'),
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.back-office-ticket.steps.3'),
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.back-office-ticket.steps.4'),
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.back-office-ticket.steps.5'),
      ],
      stepImages: [
        assetUrl('/assets/images/tickets-onboarding/back_office_1.png'),
        assetUrl('/assets/images/tickets-onboarding/back_office_2.png'),
        assetUrl('/assets/images/tickets-onboarding/back_office_3.png'),
        assetUrl('/assets/images/tickets-onboarding/back_office_4.png'),
        assetUrl('/assets/images/tickets-onboarding/back_office_5.png'),
      ],
      bannerContent: [
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.back-office-ticket.banner-content.1',
        ),
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.back-office-ticket.banner-content.2',
        ),
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.back-office-ticket.banner-content.3',
        ),
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.back-office-ticket.banner-content.4',
        ),
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.back-office-ticket.banner-content.5',
        ),
      ],
    };
  }

  private buildCustomerTicketContent(): ModalContent {
    return {
      title: this.intl.t('settings.ticket-data.tickets-onboarding.modal.customer-ticket.title'),
      steps: [
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.customer-ticket.steps.1'),
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.customer-ticket.steps.2'),
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.customer-ticket.steps.3'),
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.customer-ticket.steps.4'),
      ],
      stepImages: [
        assetUrl('/assets/images/tickets-onboarding/customer_ticket_1.png'),
        assetUrl('/assets/images/tickets-onboarding/customer_ticket_2.png'),
        assetUrl('/assets/images/tickets-onboarding/customer_ticket_3.png'),
        assetUrl('/assets/images/tickets-onboarding/customer_ticket_4.png'),
      ],
      bannerContent: [
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.customer-ticket.banner-content.1',
        ),
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.customer-ticket.banner-content.2',
        ),
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.customer-ticket.banner-content.3',
        ),
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.customer-ticket.banner-content.4',
        ),
      ],
    };
  }

  private buildTrackerTicketContent(): ModalContent {
    return {
      title: this.intl.t('settings.ticket-data.tickets-onboarding.modal.tracker-ticket.title'),
      steps: [
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.tracker-ticket.steps.1'),
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.tracker-ticket.steps.2'),
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.tracker-ticket.steps.3'),
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.tracker-ticket.steps.4'),
        this.intl.t('settings.ticket-data.tickets-onboarding.modal.tracker-ticket.steps.5'),
      ],
      stepImages: [
        assetUrl('/assets/images/tickets-onboarding/tracker_ticket_1.png'),
        assetUrl('/assets/images/tickets-onboarding/tracker_ticket_2.png'),
        assetUrl('/assets/images/tickets-onboarding/tracker_ticket_3.png'),
        assetUrl('/assets/images/tickets-onboarding/tracker_ticket_4.png'),
        assetUrl('/assets/images/tickets-onboarding/tracker_ticket_5.png'),
      ],
      bannerContent: [
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.tracker-ticket.banner-content.1',
        ),
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.tracker-ticket.banner-content.2',
        ),
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.tracker-ticket.banner-content.3',
        ),
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.tracker-ticket.banner-content.4',
        ),
        this.intl.t(
          'settings.ticket-data.tickets-onboarding.modal.tracker-ticket.banner-content.5',
        ),
      ],
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketData::TicketsOnboarding::CategoryInfoModal': typeof CategoryInfoModal;
    'settings/ticket-data/tickets-onboarding/category-info-modal': typeof CategoryInfoModal;
  }
}
