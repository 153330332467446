/* import __COLOCATED_TEMPLATE__ from './survey-question-table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import ajax from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { isEmpty } from '@ember/utils';
import abbreviatedRelativeTimeAgo from 'embercom/lib/abbreviated-relative-time-ago';
import { buildFiltersForDataConfig } from '../../../lib/reporting/custom/data-config-builder-helpers';
import { useTask } from 'ember-resources';
import EmberObject from '@ember/object';
import UserAvatarData from 'embercom/models/mixins/user-avatar-data';

let SenderWithAvatar = EmberObject.extend(UserAvatarData);

const QUESTION_SOURCE_FIELD = 'stats';
const QUESTION_NESTED_FIELD = 'question_id';

const VISITOR_ROLE = 'visitor_role';

export default class SurveyQuestionTable extends Component {
  surveyTableData = useTask(this, this.fetchSurveyTableRows, () => [this.args.renderableChart]);

  @service appService;
  @tracked rows = [];
  @service store;
  @service intl;

  columns = [
    {
      label: this.intl.t('reporting.surveys.sender-name'),
      valuePath: 'senderName',
      minWidth: '180px',
    },
    {
      label: this.intl.t('reporting.surveys.answer'),
      valuePath: 'answer',
    },
    {
      label: this.intl.t('reporting.surveys.date'),
      valuePath: 'date',
    },
  ];

  @task
  *fetchSurveyTableRows() {
    let tableData = yield this.fetchData.perform();
    this.rows = tableData.map((data) => {
      let answer = isEmpty(data.stats) ? {} : data.stats[0];
      return {
        sender: this._senderWithAvatar(data.user),
        userId: data.user?.id,
        isVisitorOrDeleted: this._isVisitorOrDeleted(data.user),
        senderName: this._senderName(data.user),
        answer: answer?.value_keyword,
        date: answer?.created_at,
        relativeDate: abbreviatedRelativeTimeAgo(answer?.created_at),
      };
    });
    return this.rows;
  }

  @task
  *fetchData() {
    return yield ajax({
      url: '/ember/reporting/documents/by_custom_chart_filters',
      type: 'POST',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        source: 'survey_receipt',
        time: this.args.renderableChart.time,
        filters: buildFiltersForDataConfig(
          this.args.renderableChart,
          this.args.renderableChart.firstMetric,
          undefined,
          this.args.renderableChart.chartSeries.firstObject.filters,
        ),
        include_fields: ['user', 'stats'],
        select_field_by_value: this._selectFieldByValue,
      }),
    });
  }

  get _selectFieldByValue() {
    return {
      array_field: QUESTION_SOURCE_FIELD,
      nested_field: QUESTION_NESTED_FIELD,
      value: this.questionId,
    };
  }

  get questionId() {
    // searching the chart-series filters - we expect exactly one question filter
    let filters = this.args.renderableChart.chartSeries.firstObject.filters;
    let questionFilter = filters.filters.find((filter) => {
      return filter.data.property.startsWith('stats.question#');
    });

    if (isEmpty(questionFilter)) {
      throw new Error('expected question filter is missing');
    }

    return questionFilter.data.property.split('#')[1];
  }

  get hasData() {
    return this.loading || this.rows.length > 0;
  }

  _senderWithAvatar(userData) {
    return SenderWithAvatar.create({
      nameOrEmail: this._senderName(userData),
      avatar: userData?.avatar,
      isActive: false,
    });
  }

  _senderName(userData) {
    return userData?.display_as || this.intl.t('reporting.user.deleted_username');
  }

  _isVisitorOrDeleted(userData) {
    return userData?.role === VISITOR_ROLE || userData?.deleted;
  }
}
