/* import __COLOCATED_TEMPLATE__ from './select-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { isEmpty } from '@ember/utils';
import { computed } from '@ember/object';
import Component from '@ember/component';
// eslint-disable-next-line @intercom/intercom/no-legacy-dropdown
import ComponentDropdownToggler from 'embercom/components/mixins/component-dropdown-toggler';

export default Component.extend(ComponentDropdownToggler, {
  tagName: 'span',
  selectedValue: null,
  options: null,
  fit: false,

  optionsHash: computed('options', 'selectedValue', function () {
    let properties = this.getProperties('options', 'selectedValue');
    let optionsHash = properties.options.map(function (item) {
      let hash = {};
      hash.label = item.label;
      hash.value = item.value;
      hash.selected = item.value === properties.selectedValue;
      return hash;
    });
    if (isEmpty(optionsHash.filterBy('selected', true))) {
      optionsHash[0].selected = true;
    }
    return optionsHash;
  }),

  selectedOption: computed('optionsHash', 'selectedValue', function () {
    let selectedOption = this.optionsHash.filterBy('selected', true);
    return selectedOption[0];
  }),

  actions: {
    optionSelected(value) {
      this.set('selectedValue', value);
    },
  },
});
