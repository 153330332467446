/* import __COLOCATED_TEMPLATE__ from './people-overview-timeline.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { PERSON_IDENTIFIED_EVENT_TYPE, GROUP_BY_USER_ROLE } from 'embercom/lib/reporting/events';
import { NEW_PEOPLE_BY_USER } from 'embercom/lib/reporting/queries';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';

const PEOPLE_CREATED = {
  signalConfig: {
    ...NEW_PEOPLE_BY_USER,
    nested_keys_are_dates: false,
  },
  filters: PERSON_IDENTIFIED_EVENT_TYPE,
};

export default Component.extend({
  reportingService: service(),

  didReceiveAttrs() {
    this._super(...arguments);
    this.fetchSignals.perform();
  },

  signal: readOnly('fetchSignals.lastSuccessful.value'),

  fetchSignals: task(function* () {
    return yield this.reportingService.fetchStackedSignal({
      debugName: 'new_people_users_timeline',
      ...PEOPLE_CREATED,
      aggregations: [...this.range.baseAggregations, GROUP_BY_USER_ROLE],
      range: this.range,
    });
  }).restartable(),
});
