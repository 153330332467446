/* import __COLOCATED_TEMPLATE__ from './default-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import {
  dateRangeBucketFormatter,
  isBucketGreaterThanDay,
} from 'embercom/lib/inbox/date-range-bucket-formatter';

const CURRENCIES = {
  dollars: '$',
};

export function dateFormatter({
  intl,
  currentDate,
  aggregationInterval,
  rangeStart,
  rangeEnd,
  timezone,
  datePattern,
}) {
  let duration = moment.duration(moment(rangeEnd).diff(moment(rangeStart))).asDays();

  let isOneDayPickedRange = duration <= 1;
  let defaultDatePattern = isOneDayPickedRange ? 'dayWithMonthAndTime' : 'dayWithMonth';

  if (isBucketGreaterThanDay(aggregationInterval)) {
    return dateRangeBucketFormatter(
      intl,
      timezone,
      currentDate,
      aggregationInterval,
      rangeStart,
      rangeEnd,
    );
  } else {
    return intl.formatDate(currentDate, {
      format: datePattern || defaultDatePattern,
      timeZone: timezone,
    });
  }
}

export default class DefaultTooltip extends Component {
  @service intl;

  get formattedDate() {
    return dateFormatter({
      intl: this.intl,
      currentDate: this.args.currentDate,
      aggregationInterval: this.args.aggregationInterval,
      rangeStart: this.args.rangeStart,
      rangeEnd: this.args.rangeEnd,
      timezone: this.args.timezone,
      datePattern: this.datePattern,
    });
  }

  get isCurrency() {
    return this.args.valueUnit in CURRENCIES;
  }

  get currencySymbol() {
    if (this.isCurrency) {
      return CURRENCIES[this.args.valueUnit];
    }
  }
}
