/* import __COLOCATED_TEMPLATE__ from './x-axis.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { makeDateFormatter } from 'embercom/lib/reporting/date-formatter';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

export default Component.extend({
  intl: service(),
  tagName: '',
  scale: notImplemented(
    '`reporting/element/x-axis`: you must pass a D3 scale as "scale". Consider wrapping this component with `reporting/element/x-scale`.',
  ),
  rangeInDays: computed('from', 'to', function () {
    let rangeStart = moment(this.from);
    let rangeEnd = moment(this.to);
    let duration = moment.duration(rangeEnd.diff(rangeStart)).asDays();

    return duration < 1 ? duration : Math.round(duration);
  }),
  axisItems: computed('scale', 'ticks', 'stride', 'rangeInDays', function () {
    let dateFormat = makeDateFormatter({
      stride: this.stride,
      isRangeHours: this.rangeInDays < 1,
      isRangeDays: this.rangeInDays <= 91,
    });

    return this.ticks.map((item, i) => {
      let format = dateFormat(item, i);
      return {
        x: this.scale(moment.tz(item, this.timezone)),
        label: isEmpty(format)
          ? ''
          : this.intl.formatDate(item, { format, timeZone: this.timezone }),
      };
    });
  }),
});
