/* import __COLOCATED_TEMPLATE__ from './number-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { get } from 'embercom/lib/ajax';
import { all } from 'ember-concurrency';
import { mapValueToLabel } from 'embercom/lib/reporting/custom/view-config-builder-helpers';

const RANGE_FILTER_OPERATORS = ['gt', 'lt'];

export default class NumberFilter extends Component {
  @service appService;
  @service store;
  @service intercomEventService;
  @service intl;
  @tracked label = this.currentLabel;
  // store the selection here so that the UI doesn't update until the drop down is closed
  @tracked stagedValues = this.selectedValues;
  @tracked selectedValueForRangeFilter = this.selectedValues[0] || 0;
  @tracked operator = this.startingOperator;
  @tracked knownValues = null;
  @tracked visibleValues = [];

  previousOperator = this.operator;

  constructor() {
    super(...arguments);
    this.initializeValues.perform();
  }

  get validValuesFromSelection() {
    if (['gt', 'lt'].includes(this.operator)) {
      return this.selectedValues;
    }

    return this.selectedValues.filter((string) => this.knownValues.includes(string));
  }

  @task({ restartable: true }) *initializeValues() {
    yield this.loadKnownValues.perform();
    let before = this.selectedValues;
    let after = this.validValuesFromSelection;

    if (before.length !== after.length) {
      if (after.length === 0) {
        this.removeFilter();
        return;
      }
      this.args.setSelected(after, this.operator);
    }
    this.visibleValues = this.knownValues;
  }

  @task({ restartable: true }) *loadKnownValues() {
    let knownValues = yield this.fetchKnownValues.perform();
    this.knownValues = Array.from(knownValues);
  }

  @task({ restartable: true }) *fetchKnownValues() {
    let sources = this.args.loadKnownValuesSources || ['conversation'];
    let childTasks = sources.map((source) => this.fetchData.perform(source));
    let responses = yield all(childTasks);
    return new Set(responses.flat());
  }

  @task({ enqueue: true, maxConcurrency: 12 }) *fetchData(source) {
    let options = {
      app_id: this.appService.app.id,
      source,
      property: this.args.type,
    };
    return yield get('/ember/reporting/known_values', options);
  }

  get operatorLabelMappings() {
    return {
      gt: this.intl.t('reporting.flexible-filters.range.more-than'),
      lt: this.intl.t('reporting.flexible-filters.range.less-than'),
      category: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.is',
      ),
      not_in_category: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.is-not',
      ),
      exists: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.exists',
      ),
      not_exists: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.not-exists',
      ),
    };
  }

  get selectedValues() {
    let values = this.args.selected.values || [];
    return values[0] === REPORTING_FILTER_SELECT_ALL ? [] : values;
  }

  get startingOperator() {
    return this.args.selected.operator || 'gt';
  }

  get currentLabel() {
    switch (this.operator) {
      case 'gt':
      case 'lt': {
        return `${this.operatorLabelMappings[this.operator]} ${this.selectedValueForRangeFilter}`;
      }
      case 'category':
      case 'not_in_category': {
        let label =
          this.formatted(this.valuesForFilter).join(', ') ||
          this.intl.t(
            'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.any',
          );
        return `${this.operatorLabelMappings[this.operator]} ${label}`;
      }
      case 'exists':
      case 'not_exists':
        return this.operatorLabelMappings[this.operator];
    }
  }

  formatted(values) {
    return values.map((value) => mapValueToLabel(this.args.type, value) || '(blank)');
  }

  get valuesForFilter() {
    if (['gt', 'lt'].includes(this.operator)) {
      return [this.selectedValueForRangeFilter || 0];
    }

    let valuesOrPlaceholder = this.stagedValues.length
      ? this.stagedValues
      : [REPORTING_FILTER_SELECT_ALL];
    return ['category', 'not_in_category'].includes(this.operator) ? valuesOrPlaceholder : [];
  }

  get items() {
    return [
      {
        items: this.allItems,
      },
    ];
  }

  get allItems() {
    if (this.knownValues === null) {
      return [];
    }
    return this.visibleValues.map((knownValue) => ({
      text: mapValueToLabel(this.args.type, knownValue) || '(blank)',
      value: knownValue,
      isSelected: this.stagedValues.includes(knownValue),
    }));
  }

  @action
  onClose() {
    if (!this.valuesForFilter.length && !this.operator) {
      return;
    }

    let analyticsObject = {
      action: 'filtered_custom_field',
      object: this.args.analyticsObject,
      filter_name: this.args.type,
      custom_field_type: this.args.attributeType || 'number',
      operator: this.operator,
    };
    this.intercomEventService.trackAnalyticsEvent(analyticsObject);
    this.args.setSelected(this.valuesForFilter, this.operator);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.stagedValues = [];
    this.selectedValueForRangeFilter = null;
    this.operator = null;
    this.args.removeFilter();
  }

  @action
  filterValues(filter) {
    this.visibleValues = this.knownValues.filter((value) =>
      value.toString().includes(filter.toLowerCase()),
    );
  }

  @action
  onOperatorChange() {
    if (
      !RANGE_FILTER_OPERATORS.includes(this.operator) &&
      RANGE_FILTER_OPERATORS.includes(this.previousOperator)
    ) {
      this.stagedValues = this.validValuesFromSelection;
    }

    this.previousOperator = this.operator;
  }
}
