/* import __COLOCATED_TEMPLATE__ from './survey-multi-select.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { inject as service } from '@ember/service';
import { copy } from 'ember-copy';
import { isEqual } from 'underscore';

export default class SurveyMultiSelect extends Component {
  @service store;
  @service intl;

  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.dataConfig,
  }));

  get dataConfig() {
    let dataConfig = copy(this.args.dataConfig);
    if (this.hasOtherAnswers) {
      dataConfig.xAxis.data = {
        ...dataConfig.xAxis.data,
        showOther: true,
        include: this.questionOptions,
      };
    }
    return dataConfig;
  }

  get viewConfig() {
    let viewConfig = this.args.viewConfig;
    viewConfig.grouping = {
      dataTransformation: (dataResponses) => this._dataTransformation(dataResponses),
    };
    return viewConfig;
  }

  get hasOtherAnswers() {
    return this.question.data.includeOther;
  }

  get questionOptions() {
    return this.question.data.options;
  }

  get question() {
    return this.store.peekRecord('surveys/question', this.questionId);
  }

  get questionId() {
    let property = this.args.renderableChart.viewBy;
    return property.split('#')[1];
  }

  _dataTransformation(dataResponses) {
    // find the group with the "Other" value and remove it
    let otherGroupIndex = dataResponses.groups.findIndex((group) =>
      isEqual(group.values, ['Other']),
    );
    if (otherGroupIndex === -1) {
      return dataResponses;
    }

    let [otherGroup] = dataResponses.groups.splice(otherGroupIndex, 1);
    let otherGroupValues = otherGroup.aggregations['0'].values;
    if (otherGroupValues.length > 0) {
      // Add the 'Other' group value
      dataResponses.groups[0].values.push(this.intl.t('reporting.surveys.other-column-label'));

      // merge the data
      dataResponses.groups[0].aggregations['0'].values.push(...otherGroupValues);
    }
    return dataResponses;
  }
}
