/* import __COLOCATED_TEMPLATE__ from './table-teammate-cell.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';

export default class TableComponent extends Component {
  col = this.args.col;

  constructor() {
    super(...arguments);

    this.groupName = this.args.row.groupName;
  }

  get isTeammate() {
    return !isNaN(this.groupName);
  }

  get teammate() {
    return this.args.data[this.groupName];
  }

  get name() {
    if (this.isTeammate && this.teammate) {
      return this.teammate.name;
    } else if (this.col.aliases) {
      return this.col.aliases[this.groupName] || 'unknown';
    } else {
      return this.groupName;
    }
  }
}
