/* import __COLOCATED_TEMPLATE__ from './ticket-type-attributes.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type TicketType from 'embercom/models/inbox/ticket-type';
import type { TicketConfigurationFields } from 'embercom/components/settings/ticket-types/ticket-type-settings';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

interface Args {
  convertInboundEmailsToTicketsSettings: $TSFixMe;
  ticketType: TicketType;
  configurations: TicketConfigurationFields;
}

export default class TicketTypeAttributes extends Component<Args> {
  @tracked showDescriptorDetailsSideDrawer = false;
  @service declare intl: IntlService;
  @service session!: Session;

  get canEditConditions() {
    return this.session.workspace.canUseTicketsConditionalAttributes;
  }

  get isCurrentTicketTypeInUse() {
    return (
      this.args.convertInboundEmailsToTicketsSettings?.ticketTypeId ===
      parseInt(this.args.ticketType?.id, 10)
    );
  }

  get emptyState() {
    return {
      icon: 'ticket',
      title: this.intl.t(
        'settings.ticket-data.ticket-type-configuration.ticket-attributes-empty-state',
      ),
    };
  }

  @action attributeCreated() {
    this.args.ticketType.reload();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketTypes::TicketTypeAttributes': typeof TicketTypeAttributes;
    'settings/ticket-types/ticket-type-attributes': typeof TicketTypeAttributes;
  }
}
