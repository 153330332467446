/* import __COLOCATED_TEMPLATE__ from './select-ticket-required-attribute-dropdown-option.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  item: { text: string; value: string };
  componentAttrs: { type: string };
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const SelectTicketRequiredAttributeDropdownOption = templateOnlyComponent<Signature>();
export default SelectTicketRequiredAttributeDropdownOption;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::SelectTicketRequiredAttributeDropdownOption': typeof SelectTicketRequiredAttributeDropdownOption;
    'settings/data-import/modal/select-ticket-required-attribute-dropdown-option': typeof SelectTicketRequiredAttributeDropdownOption;
  }
}
