/* import __COLOCATED_TEMPLATE__ from './table-article-author-cell.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

export default class TableComponent extends Component {
  @tracked author = null;

  constructor() {
    super(...arguments);
    this.loadAuthor.perform();
  }

  @task
  *loadAuthor() {
    try {
      let article = yield this.args.data.articleCollection.get(this.args.row.groupName);
      this.author = this.args.data.getCellValue(article);
    } catch (_e) {
      this.author = null;
    }
  }
}
