/* import __COLOCATED_TEMPLATE__ from './data-modal.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  title: string;
  onModalClose: () => void;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
}

const ReportingCustomReportDataModal = templateOnlyComponent<Signature>();
export default ReportingCustomReportDataModal;
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::DataModal': typeof ReportingCustomReportDataModal;
    'reporting/custom/report/data-modal': typeof ReportingCustomReportDataModal;
  }
}
