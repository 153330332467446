/* import __COLOCATED_TEMPLATE__ from './descriptor-archive-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import Admin from 'embercom/models/admin';

export default class extends Component {
  @service store;
  @service appService;
  @service notificationsService;

  @tracked dependentObjects = null;
  @tracked hasDependentObjects = null;

  get inboxRulesLink() {
    return 'apps.app.settings.inbox-rules.edit';
  }

  get modalTitle() {
    return this.isChecking
      ? 'Checking for dependencies of this attribute'
      : `Archive “${this.args.attributeToArchive.name}”`;
  }

  get isChecking() {
    return this.fetchDependentObjects.isRunning;
  }

  get canArchive() {
    return !this.isChecking && this.hasDependentObjects === false;
  }

  get hasDependentTaskBots() {
    return this.dependentObjects && this.dependentObjects.taskBots.length > 0;
  }

  get hasDependentBehaviors() {
    return this.dependentObjects && this.dependentObjects.behaviors.length > 0;
  }

  get hasDependentCustomBots() {
    return this.dependentObjects && this.dependentObjects.customBots.length > 0;
  }

  get hasDependentAnswers() {
    return this.dependentObjects && this.dependentObjects.answers.length > 0;
  }

  get hasDependentWorkflows() {
    return this.dependentObjects && this.dependentObjects.workflows.length > 0;
  }

  get hasDependentMacros() {
    return this.dependentObjects && this.dependentObjects.macros.length > 0;
  }

  get hasDependentWorkflowConnectorActions() {
    return this.dependentObjects?.workflowConnectorActions.length > 0;
  }

  get hasDependentCustomReports() {
    return this.dependentObjects?.customReports.length > 0;
  }

  get hasDependentAttributes() {
    return this.dependentObjects?.attributes.length > 0;
  }

  get hasDependentControllingAttributes() {
    return this.dependentObjects?.controllingAttributes.length > 0;
  }

  get dependentMacros() {
    return this.dependentObjects.macros.map((macro) => {
      let displayName = macro.name;
      if (macro.visibility === 'owner') {
        let admin = Admin.peekAndMaybeLoad(this.store, macro.admin_id);
        displayName = `(${admin.nameOrEmail}) ${macro.name}`;
      }
      return {
        ...macro,
        displayName,
      };
    });
  }

  get archiveText() {
    if (this.args.attributeToArchive.relationship) {
      return 'Archiving this attribute prevents your team from using it. Its data will still be stored.';
    }

    let attributeType = this.args.isConversationTicketType ? 'conversations' : 'tickets';
    return `This attribute will no longer be available to set, but ${attributeType} that already have it will stay as is. You'll still be able to filter your reports by this attribute.`;
  }

  @task({ drop: true })
  *fetchDependentObjects() {
    let attribute = this.args.attributeToArchive;

    try {
      let response = yield ajax({
        url: `/ember/conversation_attributes/descriptors/${this.args.attributeToArchive.id}/dependent_objects`,
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
        },
      });
      this.dependentObjects = {
        customBots: response.dependent_custom_bots,
        answers: response.dependent_answers,
        behaviors: response.dependent_behaviors,
        taskBots: response.dependent_task_bots,
        workflows: response.dependent_workflows,
        macros: response.dependent_macros,
        workflowConnectorActions: response.dependent_workflow_connector_actions,
        customReports: response.dependent_custom_reports,
        attributes: response.dependent_attributes,
        controllingAttributes: response.dependent_controlling_attributes,
      };
      this.hasDependentObjects = response.has_dependents;
    } catch (error) {
      this.args.onModalClose();
      this.notificationsService.notifyError(
        `Failed to archive ${attribute.name}, please try again!`,
      );
    }
  }

  @task({ drop: true })
  *archiveAttribute() {
    let attribute = this.args.attributeToArchive;

    try {
      attribute.archived = true;
      attribute.order = 0;
      yield attribute.save();
      this.notificationsService.notifyConfirmation(`${attribute.name} was archived`);
      this.args.onModalClose();
    } catch (error) {
      console.error(error);
      attribute.rollbackAttributes();
      this.notificationsService.notifyError(
        `Failed to archive ${attribute.name}, please try again!`,
      );
    }
  }
}
