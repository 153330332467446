/* import __COLOCATED_TEMPLATE__ from './seats-editor.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import {
  seatsByTeammates,
  initialSeatCountForSeatsWithDifferentSelection,
} from 'embercom/lib/teammates/calculate-common-seats';
import { action } from '@ember/object';
import {
  getWorkspaceSeatUsageAndPrices,
  createSeatUsedLabel,
  seatOverageInfo,
  calculateSeatOveragesCountForMultipleSeatTypes,
} from 'embercom/lib/admins/multiple-seats-info';
import {
  availableUiSeatTypes,
  ENGAGE_SEAT_TYPE,
  INVITE_USAGE,
  CONVERT_SEAT_TYPE,
  PROACTIVE_SUPPORT_SEAT_TYPE,
  COLLABORATOR_SEAT_TYPE,
  PRICING_5_X_FULL_SEAT_TYPE,
  PRICING_5_X_LITE_SEAT_TYPE,
  SUPPORT_SEAT_TYPE,
  TEAMMATE_USAGE,
  MONTHLY_BILLING_PERIOD,
} from 'embercom/lib/settings/seats/constants';
import { object, reduce, isFunction } from 'underscore';

export default class SeatsEditor extends Component {
  @service appService;
  @service customerService;
  @service quoteService;
  @tracked workspaceSeatUsagesAndPrices;
  @tracked collaboratorSeatsWouldGoIntoOverages;
  @tracked hasCollaboratorSeatsAvailable;
  @tracked initialSeatTypes = [];
  @tracked selectedRadioButton = PRICING_5_X_FULL_SEAT_TYPE;
  @tracked selectedSeatBillingPeriod = MONTHLY_BILLING_PERIOD;

  initialSeatCountForSeatsWithDifferentSelection = {};
  isOnPricing5 = this.appService.app.onPricing5;
  pricing5CoreSeatsUsageLabelMemoized;

  constructor() {
    super(...arguments);
    if (!this.args.isSeatsEditorDisabled) {
      this.loadData.perform();
    }
    if (this.args.teammates) {
      this.initialSeatCountForSeatsWithDifferentSelection =
        initialSeatCountForSeatsWithDifferentSelection(
          seatsByTeammates(this.args.teammates, this.args.seats, this.args.seatsSelections),
          this.args.seatsSelections,
        );
    }
    this.initialSeatTypes = [...this.args.seats];
    this.workspaceSeatUsagesAndPrices = this.getBaseSeatUsageObject();
    if (this.args.setInitialUsage) {
      this.args.setInitialUsage(this.initialSeatUsagesForSelectedTeammate);
    }
    if (this.args.seats.length > 0) {
      this.selectedRadioButton = this.args.seats[0];
    }
    this.seatBillingPeriodChanged(this.selectedSeatBillingPeriod);
  }

  get initialSeatUsagesForSelectedTeammate() {
    let initialUsage = this.getBaseSeatUsageObject();

    // In the case of invites we don't want to
    // update the initial usage. This is a problem
    // for pricing5 where we default the chosen seat
    // to core which populates the initialSeatTypes array.
    // All other pricing models don't have a default seat set.
    if (this.isOnPricing5 && this.args.isInvite && !this.args.isEditInvite) {
      return initialUsage;
    }

    this.initialSeatTypes.forEach((seatType) => {
      if (this.args.teammates) {
        initialUsage[seatType][this.args.usageType] =
          this.initialSeatCountForSeatsWithDifferentSelection[seatType];
      } else {
        initialUsage[seatType][this.args.usageType] = this.args.numberOfTeammates;
      }
    });
    return initialUsage;
  }

  get isSalesforceContracted() {
    return this.appService.app.isSalesforceContracted;
  }

  _initiallyChangeButtonPresentFor(seatType) {
    return this.initialSeatCountForSeatsWithDifferentSelection[seatType];
  }

  _changeButtonPresentFor(seatType) {
    return this.args.seatsSelections[seatType] === undefined;
  }

  get updatedSeatUsages() {
    let additionalUsage = this.getBaseSeatUsageObject();
    Object.keys(additionalUsage).forEach((seatType) => {
      if (this.args.teammates && this._initiallyChangeButtonPresentFor(seatType)) {
        additionalUsage[seatType][this.args.usageType] =
          this._calculateUpdateSeatsForUndefinedSeats(seatType);
      } else {
        additionalUsage[seatType][this.args.usageType] = this.args.numberOfTeammates;
      }
    });

    return additionalUsage;
  }

  _calculateUpdateSeatsForUndefinedSeats(seatType) {
    if (this._changeButtonPresentFor(seatType)) {
      return this.initialSeatCountForSeatsWithDifferentSelection[seatType];
    } else if (this.args.seats.includes(seatType)) {
      return this.args.numberOfTeammates;
    } else {
      return 0;
    }
  }

  get supportSeatsUsageLabel() {
    return this.generateUsageLabel(SUPPORT_SEAT_TYPE);
  }

  get proactiveSupportSeatsUsageLabel() {
    return this.generateUsageLabel(PROACTIVE_SUPPORT_SEAT_TYPE);
  }

  get collaboratorSeatsUsageLabel() {
    return this.generateUsageLabel(COLLABORATOR_SEAT_TYPE);
  }

  get engageSeatsUsageLabel() {
    return this.generateUsageLabel(ENGAGE_SEAT_TYPE);
  }

  get marketingSeatsUsageLabel() {
    return this.generateUsageLabel(CONVERT_SEAT_TYPE);
  }

  get liteSeatsUsageLabel() {
    return this.generateUsageLabel(PRICING_5_X_LITE_SEAT_TYPE);
  }

  get disablePricing5Seats() {
    return this.args.isSeatsEditorDisabled || this.isBulkEditingWithoutFeatureFlag;
  }

  get pricing5CoreSeatsUsageLabel() {
    if (this.pricing5CoreSeatsUsageLabelMemoized) {
      return this.pricing5CoreSeatsUsageLabelMemoized;
    }
    // we need this as we the function should only run once and we can't put it in the constructor
    // eslint-disable-next-line ember/no-side-effects
    this.pricing5CoreSeatsUsageLabelMemoized = this.generateUsageLabel(PRICING_5_X_FULL_SEAT_TYPE);
    return this.pricing5CoreSeatsUsageLabelMemoized;
  }

  get isTeammateBulkEdit() {
    return this.args.usageType === TEAMMATE_USAGE && this.args.numberOfTeammates > 1;
  }

  get isBulkEditingWithFeatureFlag() {
    return this.appService.app.canUseBulkEditSeats && this.isTeammateBulkEdit;
  }

  get isBulkEditingWithoutFeatureFlag() {
    return !this.appService.app.canUseBulkEditSeats && this.isTeammateBulkEdit;
  }

  async _seatOverageInfo(seatType) {
    return await seatOverageInfo(
      this.workspaceSeatUsagesAndPrices[seatType],
      this.initialSeatUsagesForSelectedTeammate[seatType],
      this.args.seats.includes(seatType) ? this.updatedSeatUsages[seatType] : undefined,
      this.args.numberOfTeammates,
      seatType,
      this.args.seats.includes(seatType),
    );
  }

  overageSeatCount(seatType) {
    return calculateSeatOveragesCountForMultipleSeatTypes(
      this.workspaceSeatUsagesAndPrices[seatType],
      this.initialSeatUsagesForSelectedTeammate[seatType],
      this.args.seats.includes(seatType) ? this.updatedSeatUsages[seatType] : undefined,
    );
  }

  get shouldSelectBillingPeriod() {
    return (
      this.customerService.isP5SelfServeAnnualCustomer &&
      this.overageSeatCount(PRICING_5_X_FULL_SEAT_TYPE) > 0 &&
      this.args.isInvite
    );
  }

  get fullSeatAnnualPrice() {
    let coreSeatPlanId = Number(this.customerService.activePricing5CorePlanId);
    return (
      this.quoteService.getQuoteById([coreSeatPlanId])?.discountedAnnualSeatPrice(coreSeatPlanId) ||
      0
    );
  }

  get fullSeatPriceForBillingPeriod() {
    let fullSeatPriceInCents =
      this.workspaceSeatUsagesAndPrices[PRICING_5_X_FULL_SEAT_TYPE]?.price || 0;
    return {
      monthly: fullSeatPriceInCents / 100,
      annual: this.fullSeatAnnualPrice || fullSeatPriceInCents / 100,
    };
  }

  @action
  seatBillingPeriodChanged(selectedBillingPeriod) {
    this.selectedSeatBillingPeriod = selectedBillingPeriod;
    if (isFunction(this.args.setCoreSeatBillingPeriod)) {
      this.args.setCoreSeatBillingPeriod(
        this.selectedSeatBillingPeriod,
        this.fullSeatPriceForBillingPeriod,
      );
    }
  }

  generateUsageLabel(seatType) {
    return createSeatUsedLabel(
      this.workspaceSeatUsagesAndPrices[seatType],
      this.initialSeatUsagesForSelectedTeammate[seatType],
      this.args.seats.includes(seatType) ? this.updatedSeatUsages[seatType] : undefined,
      this.appService.app,
      seatType,
      this.args.isInvite,
    );
  }

  getBaseSeatUsageObject() {
    let seatTypes = availableUiSeatTypes(this.isOnPricing5);
    return reduce(
      seatTypes,
      function (returnObj, seat) {
        returnObj[seat] = object([TEAMMATE_USAGE, INVITE_USAGE], [0, 0]);
        return returnObj;
      },
      {},
    );
  }

  @task *loadData() {
    yield this.loadWorkspaceSeatUsageAndPrices.perform();

    if (!this.isOnPricing5) {
      yield this.loadSeatOverageInfo.perform();
    }

    if (this.customerService.isP5SelfServeAnnualCustomer) {
      let coreSeatPlanId = Number(this.customerService.activePricing5CorePlanId);
      // NOTE: even after this.quoteService.getQuotes is finished, the prices may not be fetched yet.
      // this is due to the way getQuotes works: it returns as soon as the API request is fired, but not finished.
      // we have to rely on this.quoteService.loading to know if the prices are actaully fetched.
      yield this.quoteService.getQuotes.perform([
        {
          planIds: [coreSeatPlanId],
          includePricingByBillingPeriod: true,
        },
      ]);
    }
  }

  @task *loadSeatOverageInfo() {
    let seatOverageInfoResult = yield this._seatOverageInfo(COLLABORATOR_SEAT_TYPE);
    this.collaboratorSeatsWouldGoIntoOverages =
      !seatOverageInfoResult.canAddSeatWithoutGoingIntoOverages;
    this.hasCollaboratorSeatsAvailable = seatOverageInfoResult.hasSeatsAvailable;
  }

  @task *loadWorkspaceSeatUsageAndPrices() {
    this.workspaceSeatUsagesAndPrices = yield getWorkspaceSeatUsageAndPrices();
  }

  @action
  updateSeatUsages() {
    if (this.args.setUpdatedUsage) {
      this.args.setUpdatedUsage(this.updatedSeatUsages);
    }
  }

  get hasCollaboratorSeat() {
    return this.args.seats?.includes(COLLABORATOR_SEAT_TYPE);
  }

  get supportToggleDisabled() {
    return this.args.seats?.includes(COLLABORATOR_SEAT_TYPE);
  }

  get proactiveSupportToggleDisabled() {
    return (
      !this.args.seats?.includes(SUPPORT_SEAT_TYPE) ||
      this.args.seats?.includes(ENGAGE_SEAT_TYPE) ||
      this.args.seats?.includes(COLLABORATOR_SEAT_TYPE)
    );
  }

  get isLoading() {
    if (!this.customerService.isP5SelfServeAnnualCustomer) {
      return false;
    }
    return this.quoteService.loading;
  }
}
