/* import __COLOCATED_TEMPLATE__ from './banner-component.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Chart from 'embercom/models/reporting/custom/chart';

interface Signature {
  Args: {
    chart: Chart;
    deleteChart: () => void;
    editMode: boolean;
  };
}

export default class BannerComponent extends Component<Signature> {
  @tracked isHovered = false;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Bespoke::BannerComponent': typeof BannerComponent;
    'reporting/bespoke/banner-component': typeof BannerComponent;
  }
}
