/* import __COLOCATED_TEMPLATE__ from './article-tabs-component.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ArticleTabsComponent extends Component {
  get activeView() {
    return this.args.displaying || 'article';
  }

  @action
  changeView(displaying) {
    this.args.changeView(displaying);
  }

  get tabComponentConfigs() {
    return [
      {
        label: 'Views',
        view: 'views',
        component: 'articles/show/tabs/stat-tab',
        statistic: {
          value: this.args.stats?.viewCount,
        },
      },
      {
        label: 'Conversations',
        view: 'newchat',
        component: 'articles/show/tabs/stat-tab',
        statistic: {
          value: this.args.stats?.replyCount,
        },
      },
      {
        label: 'Reacted',
        view: 'reacted',
        component: 'articles/show/tabs/reactions-tab',
        statistic: {
          subaggregations: [
            { value: '\uD83D\uDE03', count: this.args.stats?.reaction0Percentage },
            { value: '\uD83D\uDE10', count: this.args.stats?.reaction1Percentage },
            { value: '\uD83D\uDE1E', count: this.args.stats?.reaction2Percentage },
          ],
        },
      },
    ];
  }
}
