/* import __COLOCATED_TEMPLATE__ from './buttons.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';

export default class SuggestedTopicButtonsComponent extends Component {
  @service router;
  @service notificationsService;
  @service appService;
  @service intercomEventService;
  @service intl;

  suggestionKeyphrases = this.args.topic.keyphrases;

  @task
  *saveTopic() {
    let { keyphrasesAdded, keyphrasesDeleted } = this.getKeyphraseDifference();
    if (!this.args.topic.name?.length) {
      this.args.topic.name = this.args.topic.keyphrasesText;
    }
    try {
      yield this.args.topic.save();
      yield this.destroySuggestedTopics.perform();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: 'suggestion',
        place: 'topics',
        is_new: true,
        is_merged: this.args.suggestedTopicIds.length >= 2,
        from_suggestion_ids: this.args.suggestedTopicIds,
        topic_id: this.args.topic.id,
        topic_name: this.args.topic.name,
        keyphrases_added: keyphrasesAdded,
        keyphrases_deleted: keyphrasesDeleted,
      });
      this.intercomEventService.trackEvent('added-suggestion-as-topic');
      if (this.args.removedConversationIds?.length) {
        yield this.removeConversationsFromTopic.perform();
      }
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'reporting.conversational-insights.suggested-topics.buttons.notifications.save-topic.confirmation',
        ),
      );
      this.router.transitionTo(
        'apps.app.reports.conversational-insights.topics.topic.show',
        this.args.topic.id,
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t(
          'reporting.conversational-insights.suggested-topics.buttons.notifications.save-topic.error',
        ),
      );
    }
  }

  @task
  *removeConversationsFromTopic() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'conversation_removed',
      object: 'suggestions',
      place: 'topics',
      topic_id: this.args.topic.id,
      conversation_ids: this.args.removedConversationIds,
    });
    yield ajax({
      url: `/ember/conversational_insights/conversation_topics/${this.args.topic.id}/exclude_conversations`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        conversation_ids: this.args.removedConversationIds,
      }),
    });
    this.notificationsService.notifyWarning(
      this.intl.t(
        'reporting.conversational-insights.suggested-topics.buttons.notifications.remove-conversation-from-topic.warning',
      ),
    );
  }

  @task
  *destroySuggestedTopics() {
    yield ajax({
      url: '/ember/conversational_insights/suggested_conversation_topics/destroy_by_ids',
      type: 'DELETE',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        topic_ids: this.args.suggestedTopicIds,
      }),
    });
  }

  @action
  cancelEdit() {
    this.args.topic.deleteRecord();
    this.router.transitionTo('apps.app.reports.conversational-insights.suggested-topics');
  }

  getKeyphraseDifference() {
    let keyphrasesBefore = this.suggestionKeyphrases;
    let keyphrasesAfter = this.args.topic.keyphrases;
    return {
      keyphrasesAdded: keyphrasesAfter?.reject((k) => keyphrasesBefore?.includes(k)),
      keyphrasesDeleted: keyphrasesBefore?.reject((k) => keyphrasesAfter?.includes(k)),
    };
  }
}
