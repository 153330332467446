/* import __COLOCATED_TEMPLATE__ from './conversation-ratings.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { CONVERSATION_RATINGS } from 'embercom/lib/reporting/queries';
import {
  createBucketsForKeys,
  stackBucketsToPercents,
} from 'embercom/lib/reporting/signal-transformations';
import { CONVERSATION_RATING_IDS } from 'predicates/models/data/conversation-ratings';

const RATING_COLOR_MAP = {
  [CONVERSATION_RATING_IDS.AMAZING]: 'vis-green-40',
  [CONVERSATION_RATING_IDS.GREAT]: 'vis-green-60',
  [CONVERSATION_RATING_IDS.OK]: 'vis-yellow-70',
  [CONVERSATION_RATING_IDS.BAD]: 'vis-yellow-50',
  [CONVERSATION_RATING_IDS.TERRIBLE]: 'vis-salmon-40',
};

export default Component.extend({
  query: CONVERSATION_RATINGS,
  groupings: [{ grouping: 'rating_index', interval: 1 }],
  transforms: [createBucketsForKeys([5, 4, 3, 2, 1]), stackBucketsToPercents],
  ratingColorMap: RATING_COLOR_MAP,
});
