/* import __COLOCATED_TEMPLATE__ from './manage-folder.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import type NavigationFolder from 'embercom/models/reporting/navigation-folder';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type ReportingFolderManagerService from 'embercom/services/reporting-folder-manager-service';

interface Args {
  folder: NavigationFolder;
  onCreateFolder: () => void;
  mainPopover: any;
  setEditingFolder: (folder: NavigationFolder | undefined) => void;
}

export default class ReportingNavSubmenuComponentsSubMenuManageFolder extends Component<Args> {
  @service declare intercomConfirmService: any;
  @service declare intl: any;
  @service declare intercomEventService: any;
  @service declare reportingFolderManagerService: ReportingFolderManagerService;
  @action
  unpinFolder() {
    this.args.folder.unpin();
    this.trackAnalytics(this.args.folder, 'unpinned');
    this.reportingFolderManagerService.loadPinnedFlexibleFolders();
  }

  @action
  renameFolder() {
    if (this.args.setEditingFolder) {
      this.args.setEditingFolder(this.args.folder);
    }
    this.args.onCreateFolder();
  }

  @action
  async deleteFolder(popover: any) {
    let confirm = await this.intercomConfirmService.confirm({
      title: this.intl.t('reporting.folders.sub-folders.delete-confirmation-modal.title'),
      body: this.intl.t('reporting.folders.sub-folders.delete-confirmation-modal.body'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t(
        'reporting.folders.sub-folders.delete-confirmation-modal.confirm-button-label',
      ),
    });
    if (!confirm) {
      popover.hide();
      return;
    }
    await this.args.folder.destroyRecord();
    this.trackAnalytics(this.args.folder, 'deleted');
    this.reportingFolderManagerService.loadPinnedFlexibleFolders();
  }

  trackAnalytics(folder: NavigationFolder, action: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'reporting_navigation_folder',
      folder_id: folder.id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::NavSubmenuComponents::SubMenu::ManageFolder': typeof ReportingNavSubmenuComponentsSubMenuManageFolder;
    'reporting/nav-submenu-components/sub-menu/manage-folder': typeof ReportingNavSubmenuComponentsSubMenuManageFolder;
  }
}
