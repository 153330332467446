/* import __COLOCATED_TEMPLATE__ from './view-mode.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import StandardGoalSummaryConfiguration from 'embercom/objects/series/summaries/standard-goal-summary-configuration';
import StandardExitRulesSummaryConfiguration from 'embercom/objects/series/summaries/standard-exit-rules-summary-configuration';
import StandardCompanyPrioritySummaryConfiguration from 'embercom/objects/series/summaries/standard-company-priority-summary-configuration';

export default class SettingsViewMode extends Component {
  @service seriesEditorService;

  get goalConfig() {
    let container = getOwner(this);
    return StandardGoalSummaryConfiguration.create({ container });
  }

  get exitRulesConfig() {
    let container = getOwner(this);
    return StandardExitRulesSummaryConfiguration.create({ container });
  }

  get companyPriorityConfig() {
    let container = getOwner(this);
    return StandardCompanyPrioritySummaryConfiguration.create({ container });
  }
}
