/* import __COLOCATED_TEMPLATE__ from './report-template.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { filterableProperties as defaultFilterableProperties } from 'embercom/lib/reporting/custom/filter-helpers';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import ReportTemplateBuilder from 'embercom/lib/reporting/custom/report-template-builder';

export default class ReportTemplate extends Component {
  @service store;
  @service intl;
  @service reportingMetrics;

  @tracked report;
  @tracked reportState;

  constructor() {
    super(...arguments);
    let templateBuilder = new ReportTemplateBuilder(
      getOwner(this),
      this.args.templateKey,
      this.args.report,
    );
    let { report, reportState } = templateBuilder.buildTemplate();

    this.report = report;
    this.reportState = reportState;
  }

  get filterableProperties() {
    return defaultFilterableProperties().concat('teammate');
  }

  get filterableMetricProperties() {
    return this.reportingMetrics.getPropertiesByIds(this.filterableProperties);
  }

  get timezone() {
    return this.reportState.dateRange.timezone;
  }
}
