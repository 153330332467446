/* import __COLOCATED_TEMPLATE__ from './views-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import ViewConfig from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { COUNT, CARDINALITY } from 'embercom/lib/reporting/flexible/constants';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { articleViewsDataConfig } from 'embercom/lib/reporting/flexible/articles';
import moment from 'moment-timezone';

export default class ArticlesViewChart extends Component {
  @service appService;
  @service intl;

  get chartTitle() {
    return this.intl.t('articles.reports.view-chart.title');
  }

  get isTooltipVisible() {
    let r = this.args.range;
    if (!r) {
      return false;
    }

    let cutoffTime = moment.tz('2022-10-07T00:00:00', r.timezone);
    return r.endMoment.isAfter(cutoffTime);
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();
    viewConfig.formatUnit = {
      unit: units.value,
    };

    let rest = [
      {
        value: 'user.role',
        text: this.intl.t('articles.reports.view-chart.filter.audience'),
        type: 'nominal',
        labelMappings: {
          visitor: this.intl.t('articles.reports.filters.audience.visitor'),
          lead: this.intl.t('articles.reports.filters.audience.lead'),
          user: this.intl.t('articles.reports.filters.audience.user'),
        },
        excludeMissingGroup: true,
      },
      {
        value: 'article_stat_metadata.article_channel',
        text: this.intl.t('articles.reports.view-chart.filter.article-channel'),
        type: 'nominal',
        labelMappings: {
          help_center: this.intl.t('articles.reports.filters.channel.help-center'),
          messenger: this.intl.t('articles.reports.filters.channel.messenger'),
        },
        excludeMissingGroup: true,
      },
      {
        value: 'article_stat_metadata.article_source',
        text: this.intl.t('articles.reports.view-chart.filter.article-source'),
        type: 'nominal',
        labelMappings: {
          search_browse: this.intl.t('articles.reports.filters.messenger-source.search-browse'),
          conversation: this.intl.t('articles.reports.filters.messenger-source.conversation'),
          home_screen: this.intl.t('articles.reports.filters.messenger-source.home-screen'),
          suggested_article: this.intl.t(
            'articles.reports.filters.messenger-source.suggested-article',
          ),
        },
        excludeMissingGroup: true,
      },
    ];

    viewConfig.groupings = {
      defaultOption: {
        value: 'event.created_at',
        text: this.intl.t('articles.reports.view-chart.filter.time'),
        type: 'temporal',
      },
      rest,
    };

    viewConfig.legend = {
      article_unique_views: () => this.intl.t('articles.reports.unique-view.title'),
      article_total_views: () => this.intl.t('articles.reports.total-view.title'),
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      xAxis: {
        type: 'temporal',
        data: {
          interval: FILTER_PROPERTY_PLACEHOLDER,
          property: 'event.created_at',
        },
      },
      series: [
        {
          name: 'article_unique_views',
          type: CARDINALITY,
          data: { property: 'user.id' },
          ...articleViewsDataConfig(),
        },
        {
          name: 'article_total_views',
          type: COUNT,
          data: { property: 'user.id' },
          ...articleViewsDataConfig(),
        },
      ],
    };
  }
}
