/* import __COLOCATED_TEMPLATE__ from './team-inbox-performance-report.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { filterableProperties as defaultFilterableProperties } from 'embercom/lib/reporting/custom/filter-helpers';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import PALETTE from '@intercom/pulse/lib/palette';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

const DEFAULT_CHART_DEFINITION = {
  id: null,
  title: null,
  visualization_type: null,
  metric_property: null,
  metric_function: null,
  date_range: {
    property: 'comment_at',
    selection: 'past_12_weeks',
    start: null,
    end: null,
  },
  stacked: false,
  view_by: null,
  view_by_time_interval: null,
  view_by_display_limit: 10000,
  view_by_display_other: false,
  width_type: null,
  height_type: null,
};

export default class TeamInboxPerformanceReport extends Component {
  @service store;
  @service intl;
  @service appService;
  @service reportingMetrics;
  @service router;

  get reportDefinition() {
    return {
      id: `team_inbox_performance_report`,
      title: null,
      description: null,
      charts: this.charts,
      date_range: null,
      filters: {
        type: 'and',
        filters: [
          {
            type: 'category',
            data: {
              attribute: 'team',
              property: 'team',
              values: [REPORTING_FILTER_SELECT_ALL],
            },
          },
        ],
      },
    };
  }

  get filterableProperties() {
    return defaultFilterableProperties().concat('team');
  }

  get filterableMetricProperties() {
    return this.reportingMetrics.getPropertiesByIds(this.filterableProperties);
  }

  getMetricIdVariant(metricId) {
    let { excludeOooHours } = this.args.settings;
    if (excludeOooHours) {
      return metricId;
    }
    let metric = this.reportingMetrics.getMetricById(metricId);
    return metric.metricFamilyId;
  }

  get charts() {
    return [
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'median_team_first_response_time',
        tooltip: this.intl.t(
          'reporting.team-inbox-performance.team-first-response-time-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        should_render_chrome: true,
        chart_series: [
          {
            metric_id: this.getMetricIdVariant('team.first_response_time.in_office_hours'),
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'median_team_subsequent_response_time',
        tooltip: this.intl.t(
          'reporting.team-inbox-performance.team-subsequent-response-time-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        should_render_chrome: true,
        chart_series: [
          {
            metric_id: this.getMetricIdVariant('team.subsequent_response_time.in_office_hours'),
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'median_team_time_to_close',
        tooltip: this.intl.t(
          'reporting.team-inbox-performance.team-time-to-close-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        should_render_chrome: true,
        chart_series: [
          {
            metric_id: this.getMetricIdVariant('team.time_to_close.in_office_hours'),
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'conversations_assigned_to_teams',
        title: this.intl.t(
          'reporting.team-inbox-performance.conversations-assigned-to-teams-chart-title',
        ),
        tooltip: this.intl.t(
          'reporting.team-inbox-performance.conversations-assigned-to-teams-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'conversations.assigned.count',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'conversations_replied_to_by_teams',
        title: this.intl.t(
          'reporting.team-inbox-performance.conversations-replied-to-by-teams-chart-title',
        ),
        tooltip: this.intl.t(
          'reporting.team-inbox-performance.conversations-replied-to-by-teams-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'conversations.participated.count',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'closed_conversations_by_teams',
        title: this.intl.t(
          'reporting.team-inbox-performance.closed-conversations-by-teams-chart-title',
        ),
        tooltip: this.intl.t(
          'reporting.team-inbox-performance.closed-conversations-by-teams-chart-description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'conversations.closed.count',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'conversations_assigned_participated_closed_by_week',
        title: this.intl.t(
          'reporting.team-inbox-performance.conversations-assigned-participated-and-closed-by-week-chart-title',
        ),
        visualization_type: VISUALIZATION_TYPES.BAR,
        view_by: 'time',
        width_type: WIDTH_TYPES.FULL,
        chart_series: [
          {
            metric_id: 'conversations.assigned.count',
            color: PALETTE['vis-blue-50'],
          },
          {
            metric_id: 'conversations.participated.count',
            color: PALETTE['vis-yellow-50'],
          },
          {
            metric_id: 'conversations.closed.count',
            color: PALETTE['vis-mint-50'],
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'team_performance_overview',
        title: this.intl.t(
          'reporting.team-inbox-performance.team-performance-overview-chart-title',
        ),
        tooltip: this.intl.t(
          'reporting.team-inbox-performance.team-performance-overview-chart-tooltip',
          {
            url: this.router.urlFor('apps.app.messenger.settings', this.appService.app.id, {
              queryParams: { tab: 'general', section: 'office-hours' },
            }),
            htmlSafe: true,
          },
        ),
        visualization_type: VISUALIZATION_TYPES.TABLE,
        view_by: 'team',
        width_type: WIDTH_TYPES.FULL,
        number_rows_visible: 10,
        show_default_chart_title: false,
        should_render_chrome: true,
        visualization_options: {
          show_summary_row: true,
        },
        chart_series: [
          {
            metric_id: 'conversations.assigned.count',
          },
          {
            metric_id: 'conversations.participated.count',
          },
          {
            metric_id: 'conversations.closed.count',
          },
          {
            metric_id: this.getMetricIdVariant('team.first_response_time.in_office_hours'),
            aggregation: 'median',
          },
          {
            metric_id: this.getMetricIdVariant('team.subsequent_response_time.in_office_hours'),
            aggregation: 'median',
          },
          {
            metric_id: this.getMetricIdVariant('team.time_to_close.in_office_hours'),
            aggregation: 'median',
          },
          {
            metric_id: 'v1.replies_sent',
          },
          {
            metric_id: 'notes.created.count',
          },
          {
            metric_id: 'mentions.count',
          },
        ],
      },
    ];
  }
}
