/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import trustedStyle from '@intercom/pulse/lib/trusted-style';

export default class SeriesSidebar extends Component {
  @service seriesEditorService;
  @tracked isOpen = true;
  @tracked scrollbarWidth = 0;
  wasPreviouslyOpen = true;

  @action onInsertionStart() {
    this.wasPreviouslyOpen = this.isOpen;
    this.isOpen = false;
  }

  @action onInsertionEnd() {
    if (this.wasPreviouslyOpen) {
      this.isOpen = true;
    }
  }

  @action setScrollbarOffset() {
    let element = this.seriesEditorService.graph.wrapper;
    this.scrollbarWidth = element.offsetWidth - element.clientWidth;
  }

  get style() {
    return trustedStyle`right: ${this.scrollbarWidth}px; bottom: ${this.scrollbarWidth}px;`;
  }
}
