/* import __COLOCATED_TEMPLATE__ from './csv-column.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';

export default class CsvColumn extends Component {
  previewRowCount = 6;

  get bodyRows() {
    let previewRows = this.args.rows.slice(0, this.previewRowCount);
    return this.args.hasHeader ? previewRows.slice(1) : previewRows;
  }

  get rowCount() {
    return this.args.rows.length;
  }

  get rowsLeftCount() {
    return Math.max(0, this.rowCount - this.previewRowCount);
  }

  get headerRow() {
    return this.args.rows[0];
  }

  get isSelected() {
    return this.args.index === this.args.selectedIndex;
  }
}
