/* import __COLOCATED_TEMPLATE__ from './team-assigned-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

const UNASSIGNED_TEAM_TEXT = 'None';
const UNASSIGNED_TEAM_ID = '0';

export default class TeamAssignedFilter extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;
  @tracked availableTeams = this.teamsWithUnassigned;
  @tracked label = this.currentLabel;
  selectedDropdownItems = this.args.selected;

  get items() {
    return [
      {
        heading: 'Teams',
        items: this.allItems,
      },
    ];
  }

  get teamsWithUnassigned() {
    //Unassigned team uses the same icon and value as unassigned admin
    return [this.appService.app.unassignedAdmin].concat(this.appService.app.teams);
  }

  teamItemLabel(teammate) {
    if (!teammate) {
      return 'Former Team';
    }
    if (teammate.id === UNASSIGNED_TEAM_ID) {
      return UNASSIGNED_TEAM_TEXT;
    } else {
      return teammate.display_as_assignee;
    }
  }

  get allItems() {
    return this.availableTeams.map((team) => ({
      model: team,
      text: this.teamItemLabel(team),
      value: team.id.toString(),
      component: 'reporting/flexible/filter-bar/teammate-item',
      isSelected: this.args.selected.includes(team.id.toString()),
    }));
  }

  get selectedTeamIds() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get name() {
    return this.intl.t(
      'components.reporting.custom.chart-builder.filter-bar.team-assigned-filter.team-assigned-is',
    );
  }

  get selectAllLabel() {
    return this.intl.t(
      'components.reporting.custom.chart-builder.filter-bar.team-assigned-filter.any-team-currently-assigned',
    );
  }

  get currentLabel() {
    if (this.selectedTeamIds.length === 0) {
      return 'Any';
    }

    let teamNames = this.selectedTeamIds.map((teamId) => {
      let team = this.availableTeams.find((team) => team.id.toString() === teamId);
      return this.teamItemLabel(team);
    });
    return teamNames.join(', ');
  }

  @action
  onClose() {
    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_assigned_team',
      object: this.args.analyticsObject,
      team_count: this.selectedTeamIds.length,
    });

    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }
}
