/* import __COLOCATED_TEMPLATE__ from './summarize-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLElement;
  Args: {
    action: any;
    onChange?: () => void;
    isDisabled?: boolean;
    onDelete?: () => void;
  };
}

const SummarizeConversation = templateOnlyComponent<Signature>();
export default SummarizeConversation;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Rules::Actions::SummarizeConversation': typeof SummarizeConversation;
  }
}
