/* import __COLOCATED_TEMPLATE__ from './table-article-cell.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class TableComponent extends Component {
  @service store;
  @tracked cellValue = null;

  constructor() {
    super(...arguments);

    this.groupName = this.args.row.groupName;
    this.args.data.articleCollection
      .get(this.groupName)
      .then((article) => (this.cellValue = this.args.data.getCellValue(article)))
      .catch(() => (this.cellValue = 'Unknown'));
  }
}
