/* import __COLOCATED_TEMPLATE__ from './funnel-percentage.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { computed } from '@ember/object';
import Component from '@ember/component';
import percent, {
  toPercentString,
  REPORTS_PRECISION_DEFAULT,
} from 'embercom/lib/percentage-formatter';

export default Component.extend({
  tagName: '',
  percentage: computed('from', 'to', function () {
    return toPercentString(percent(this.from, this.to), REPORTS_PRECISION_DEFAULT);
  }),
});
