/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Editor extends Component {
  @tracked openSectionId = 'attributes';
  @tracked showObjectTypeArchiveModal = false;
  @tracked showAttributeDescriptorModal = false;
  @tracked showAttributeDescriptorArchiveModal = false;
  @tracked modalAttributeDescriptor = undefined;
  @service appService;

  @action
  openAttributeDescriptorModal(attributeDescriptor) {
    this.showAttributeDescriptorModal = true;
    this.modalAttributeDescriptor = attributeDescriptor;
  }
}
