/* import __COLOCATED_TEMPLATE__ from './subscription.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import EmberObject, { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import moment from 'moment-timezone';
import { task } from 'ember-concurrency-decorators';
import { isPresent, isEmpty } from '@ember/utils';
import formatTimezoneToUTC from 'embercom/lib/format-timezone-to-utc';

export default class Subscription extends Component {
  @tracked submitted = false;
  @tracked showSubscriptionModal = false;
  @tracked showDeleteSubscriptionConfirmModal = false;
  @tracked showUnsubscribeModal = false;
  @tracked fetchingSubscription = false;
  @tracked frequency;
  @service intercomEventService;
  @service notificationsService;
  @service store;
  @service appService;
  @service reportSubscriptionService;
  @service intl;
  @service permissionsService;

  bannerTimeToLive = ENV.APP.default_notification_timeout;
  frequencies = [
    { text: this.intl.t('reporting.subscription.daily'), value: 'daily' },
    { text: this.intl.t('reporting.subscription.weekly'), value: 'weekly' },
    { text: this.intl.t('reporting.subscription.monthly'), value: 'monthly' },
  ];
  days = [
    { text: this.intl.t('reporting.subscription.monday'), value: 'monday' },
    { text: this.intl.t('reporting.subscription.tuesday'), value: 'tuesday' },
    { text: this.intl.t('reporting.subscription.wednesday'), value: 'wednesday' },
    { text: this.intl.t('reporting.subscription.thursday'), value: 'thursday' },
    { text: this.intl.t('reporting.subscription.friday'), value: 'friday' },
    { text: this.intl.t('reporting.subscription.saturday'), value: 'saturday' },
    { text: this.intl.t('reporting.subscription.sunday'), value: 'sunday' },
  ];

  constructor() {
    super(...arguments);
    // Settings the items
    this.loadHours();
    this.monthlyDays = Array.from(Array(28), (d, i) => ({
      text: this.intl.t('reporting.subscription.on-month-days', { number: i + 1 }),
      value: i + 1,
    }));
    this.monthlyDays.push({ text: this.intl.t('reporting.subscription.last-day'), value: 32 });
    // Settings the icSelect groups
    this.hoursGroup = [{ items: this.hours, isFilterable: false }];
    this.monthlyDaysGroup = [{ items: this.monthlyDays, isFilterable: false }];
    this.readOnlyDayGroup = [
      {
        text: this.intl.t('reporting.subscription.every-day'),
        value: null,
      },
    ];

    this.readOnlyDay = null;
    this.loadSubscription.perform();
  }

  @task *loadSubscription() {
    this.fetchingSubscription = true;
    let status = yield this.reportSubscriptionService.retrieveSubscription.perform(
      this.args.subscriptionId,
    );
    this.handleSubscriptionNotFound(status);
    this.autoOpenModal();
    this.fetchingSubscription = false;
  }

  get showModal() {
    if (this.args.showModalOnly) {
      return this.args.showSubscriptionModal;
    }
    return this.showSubscriptionModal;
  }

  handleSubscriptionNotFound(status) {
    if (status === 404) {
      this.notificationsService.notifyHelp(
        this.intl.t('reporting.subscription.no-longer-subscribed-notification'),
        this.bannerTimeToLive,
        'SubscriptionNotFound',
      );
    }
  }

  autoOpenModal() {
    if (this.editMode) {
      this.currentAdminIsOwner ? this.openSubscriptionModal() : this.openUnsubscribeModal();
    }
  }

  openUnsubscribeModal() {
    this.showUnsubscribeModal = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'report_schedule_unsubscribe_modal',
      ...this.eventDetails(),
      is_owner: false,
    });
  }

  get currentAdminIsOwner() {
    return this.currentAdmin.id === this.subscription.adminId;
  }

  loadHours() {
    let timezoneString = formatTimezoneToUTC(this.appService.app.timezone);
    this.hours = Array.from(Array(24), (d, i) => ({
      text: `${moment({ hour: i }).format('h:mmA')} ${timezoneString}`,
      value: i,
    }));
  }

  get subscription() {
    return this.reportSubscriptionService.subscription;
  }

  get editMode() {
    return isPresent(this.subscription) ? !this.subscription.isNew : false;
  }

  get scheduleButtonTooltipDisabled() {
    return !this.customDateRange;
  }

  get scheduleButtonDisabled() {
    return this.fetchingSubscription || this.customDateRange || this.args.dashboard.isPaywalled;
  }

  get customDateRange() {
    return this.args.dashboard.range.selectedRange === 'custom';
  }

  get dashboardId() {
    return this.args.dashboard?.dashboardId;
  }

  get reportId() {
    return this.args.report?.id;
  }

  get hourToString() {
    return this.hours.find((f) => f.value === this.subscription.cadenceTimeOfDay).text;
  }

  get bannerMessage() {
    // Note: day is already translated
    let day = this.days.find((f) => f.value === this.subscription.cadenceDayOfWeek).text;
    let dayOfMonth = this.subscription.cadenceDateOfMonth;

    switch (this.subscription.cadence) {
      case 'daily':
        return this.intl.t('reporting.subscription.daily-report-confirmation', {
          time: this.hourToString,
        });
      case 'weekly':
        return this.intl.t('reporting.subscription.weekly-report-confirmation', {
          day,
          time: this.hourToString,
        });
      case 'monthly':
        if (dayOfMonth === 32) {
          return this.intl.t('reporting.subscription.monthly-last-day-report-confirmation', {
            time: this.hourToString,
          });
        } else {
          return this.intl.t('reporting.subscription.monthly-report-confirmation', {
            dayOfMonth,
            time: this.hourToString,
          });
        }
    }
  }

  get isMonthlyFrequency() {
    return this.subscription.cadence === this.frequencies[2].value;
  }

  get isWeeklyFrequency() {
    return this.subscription.cadence === this.frequencies[1].value;
  }

  get admins() {
    return this.appService.app.humanAdmins;
  }

  get currentAdmin() {
    return this.appService.app.currentAdmin;
  }

  get adminsOptions() {
    return this.admins.map((admin) => {
      let isCurrentAdmin = this.currentAdmin.id === admin.id;
      let isSelected =
        isCurrentAdmin || this.subscription.teammateIds.includes(parseInt(admin.id, 10));
      return EmberObject.create({
        value: admin.id,
        text: isCurrentAdmin ? this.intl.t('reporting.subscription.you') : admin.name,
        avatar: admin.avatar,
        avatarData: admin.avatarData,
        readonly: isCurrentAdmin,
        isSelected,
      });
    });
  }

  get deleteSubscriptionButton() {
    if (this.editMode) {
      return {
        label: this.intl.t('reporting.subscription.stop-sending-button'),
        icon: 'unfollow',
        destructive: 'true',
        action: this.openDeleteSubscriptionConfirmModal,
      };
    } else {
      return null;
    }
  }

  get noExistingSubscription() {
    return (
      isEmpty(this.args.subscriptionId) || isEmpty(this.subscription) || this.subscription.isDeleted
    );
  }

  notifySubscriptionUpdated() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('reporting.subscription.schedule-updated-notification'),
      this.bannerTimeToLive,
      'SubscriptionUpdated',
    );
  }

  notifySubscriptionSaved() {
    this.notificationsService.notifyConfirmationWithButton(
      this.bannerMessage,
      {
        label: this.intl.t('reporting.subscription.edit-button'),
        action: this.openSubscriptionModal,
      },
      this.bannerTimeToLive,
      'SubscriptionSaved',
    );
  }

  getSendDayForFrequency(frequency) {
    let frequencyToDateMap = {
      daily: 'daily',
      weekly: this.subscription.cadenceDayOfWeek,
      monthly: this.subscription.cadenceDateOfMonth,
    };

    return frequencyToDateMap[frequency];
  }

  @action
  updateTeammates(teammateIds) {
    if (teammateIds.length > this.subscription.teammateIds.length) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'participant_added',
        object: 'report_schedule',
        ...this.eventDetails(),
      });
    }
    this.subscription.teammateIds = teammateIds;
  }

  @action
  updateFrequency(frequency) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'frequency_updated',
      object: 'report_schedule',
      ...this.eventDetails(),
      send_time: this.subscription.cadenceTimeOfDay,
      send_day: this.getSendDayForFrequency(frequency),
      frequency,
    });
    this.subscription.cadence = frequency;
  }

  @action
  openSubscriptionModal() {
    if (this.editMode) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        object: 'report_schedule_edit_modal',
        is_owner: this.currentAdminIsOwner,
        ...this.eventDetails(),
      });
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        object: 'report_schedule_create_modal',
        ...this.eventDetails(),
      });
    }
    this.openModal();
  }

  @action
  async submit() {
    this.closeModal();
    await this.permissionsService.checkHierarchyPermissionWithFeatureFlag(
      'can_reporting__reports__share',
    );
    this.submitted = true;
    let updatingSubscription = this.editMode;
    if (!updatingSubscription) {
      this.updateSubscription();
    }
    await this.subscription.save();

    if (updatingSubscription) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'edited',
        object: 'report_schedule',
        ...this.eventDetails(),
        recipients_length: this.subscription.teammateIds.length,
      });
      this.notifySubscriptionUpdated();
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'created',
        object: 'report_schedule',
        ...this.eventDetails(),
        recipients_length: this.subscription.teammateIds.length,
      });
      this.notifySubscriptionSaved();
    }
  }

  @action
  async unsubscribe() {
    this.showUnsubscribeModal = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'unsubscribed',
      object: 'report_schedule',
      ...this.eventDetails(),
    });
    await this.subscription.unsubscribe();
    this.notificationsService.notifyConfirmation(
      this.intl.t('reporting.subscription.unsubscribed-notification'),
      this.bannerTimeToLive,
      'UnsubscribedFromSubscription',
    );
    this.reportSubscriptionService.resetSubscription();
  }

  @action
  cancel() {
    this.closeModal();
    this.reportSubscriptionService.rollbackSubscriptionChanges();
  }

  @action
  cancelUnsubscribe() {
    this.showUnsubscribeModal = false;
    this.reportSubscriptionService.resetSubscription();
  }

  @action
  openDeleteSubscriptionConfirmModal() {
    this.showDeleteSubscriptionConfirmModal = true;
    this.closeModal();
  }

  @action
  closeDeleteSubscriptionConfirmModal() {
    this.openModal();
    this.showDeleteSubscriptionConfirmModal = false;
  }

  @action
  async deleteSubscription() {
    this.showDeleteSubscriptionConfirmModal = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'deleted',
      object: 'report_schedule',
      ...this.eventDetails(),
    });
    await this.subscription.destroyRecord();
    this.notificationsService.notifyConfirmation(
      this.intl.t('reporting.subscription.stopped-notification'),
      this.bannerTimeToLive,
      'SubscriptionDeleted',
    );
    this.reportSubscriptionService.resetSubscription();
  }

  closeModal() {
    if (this.args.closeSubscriptionModal) {
      this.args.closeSubscriptionModal();
    } else {
      this.showSubscriptionModal = false;
    }
  }

  openModal() {
    if (this.args.openSubscriptionModal) {
      this.args.openSubscriptionModal();
    } else {
      this.showSubscriptionModal = true;
    }
  }

  updateSubscription() {
    if (this.args.report) {
      this.subscription.reportType = 'custom_report';
      this.subscription.jsonBlob = {
        customReportId: this.args.report.id,
        dashboardName: this.intl.t('reporting.subscription.custom-report-dashboard-name', {
          reportTitle: this.args.report.title,
        }),
      };
      return;
    }
    this.subscription.jsonBlob = this.args.dashboard.toJson();
  }

  // will return either dashboard_id or custom_report_id prop depending on the schedule type
  eventDetails() {
    return {
      ...(!isEmpty(this.dashboardId) && {
        dashboard_id: this.dashboardId,
      }),
      ...(!isEmpty(this.reportId) && {
        custom_report_id: this.reportId,
      }),
    };
  }
}
