/* import __COLOCATED_TEMPLATE__ from './pick-conversation-data-attribute-value.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class PickConversationDataAttributeValue extends Component {
  @tracked action = this.args.action;
  @tracked actionEditorContext = this.args.actionEditorContext;
  @service attributeService;
  @service intl;

  get conversationDataAttribute() {
    return this.attributeService.conversationCustomAttributes.find(
      (attribute) => attribute.identifier === this.identifier,
    );
  }

  get conversationDataAttributeType() {
    return this.conversationDataAttribute.type;
  }

  get identifier() {
    return this.action.get('actionData.attribute_identifier');
  }

  get label() {
    return this.intl.t('settings.inbox-rules.index.actions.conversation-data-attribute.label', {
      name: this.conversationDataAttribute.name,
    });
  }

  get details() {
    if (this.formatValue) {
      return this.intl.t('settings.inbox-rules.index.actions.conversation-data-attribute.details', {
        value: this.formatValue,
      });
    }
    return '';
  }

  get value() {
    return this.action.get('actionData.value');
  }

  get overlayComponent() {
    return 'rules/pick-conversation-data-attribute-value-overlay';
  }

  get overlayComponentHash() {
    return {
      onUpdate: this.updateValue,
      selectedValue: this.value,
      conversationDataAttribute: this.conversationDataAttribute,
    };
  }

  get formatValue() {
    switch (this.conversationDataAttribute.type) {
      case 'conversation_attribute_list':
        return this.conversationDataAttribute.options.find(
          (option) => option.identifier === this.value,
        )?.value;
      case 'boolean':
        return this.value ? 'True' : 'False';
      default:
        return this.value;
    }
  }

  @action
  updateValue(value) {
    let normalizedValue = this.normalizeValue(value);
    this.action.set('actionData.value', normalizedValue);
    let onChange = this.onChange;
    if (onChange) {
      onChange();
    }
  }

  normalizeValue(value) {
    let type = this.conversationDataAttribute.type;
    if (type === 'integer' || type === 'decimal') {
      let casted = Number(value);
      if (value === '' || isNaN(casted)) {
        value = null;
      } else {
        value = type === 'integer' ? Math.round(casted) : casted;
      }
    }
    if (type === 'string') {
      value = value.trim();
      if (value === '') {
        value = null;
      }
    }
    return value;
  }
}
