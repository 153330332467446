/* import __COLOCATED_TEMPLATE__ from './notify-private-slack-channel.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class NotifyPrivateSlackChannel extends Component {
  @action updateValues(slack_channel_id) {
    this.args.action.actionData = { slack_channel_id };
  }

  @action close() {
    this.args.action.justAdded = false;
  }
}
