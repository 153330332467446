/* import __COLOCATED_TEMPLATE__ from './breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isWithinSafeLimit } from 'embercom/lib/reporting/custom/safe-limits';
import { TEAMMATE_ALIAS } from 'embercom/lib/reporting/custom/filter-helpers';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { groupDropDownItems } from './chart-builder-helpers';

export default class Breakdown extends Component {
  @service store;
  @service appService;
  @service attributeService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get suggestedProperties() {
    if (this.args.suggestedProperties !== undefined) {
      return this.args.suggestedProperties;
    }
    return [];
  }

  get groupedAttributes() {
    let dropDownItems = this.args.supportedBreakdowns.map((attribute) =>
      this._standardAttributeForDisplay(attribute),
    );

    let groupedProperties = groupDropDownItems(
      dropDownItems,
      this.args.supportedBreakdowns,
      this.intl,
    );

    let suggestedProperties = this.args.supportedBreakdowns.filter((p) =>
      this.suggestedProperties.includes(p.field),
    );

    let items = [
      {
        heading: this.intl.t('app.lib.reporting.custom.filter-headings.suggested-data'),
        items: suggestedProperties.map((p) => this._standardAttributeForDisplay(p)),
      },
      ...groupedProperties,
    ];

    if (this.addNoneOption) {
      items = [
        { text: this.intl.t('reporting.custom-reports.chart.breakdown.none'), value: null },
        ...items,
      ];
    }
    if (this.args.property) {
      let allItems = items.flatMap((item) => item.items || [item]);
      let currentlySelectedItem = allItems.find((item) => item.value === this.args.property);
      if (!currentlySelectedItem) {
        console.error(`Selected item ${this.args.property} is not in the list of available items`);
        items = [
          {
            text: this.intl.t('reporting.custom-reports.chart.breakdown.archived-attribute'),
            value: this.args.property,
          },
          ...items,
        ];
      }
    }
    return items;
  }

  get attributeObject() {
    if (this.args.property) {
      let attributes = this.groupedAttributes.flatMap((item) => item.items || [item]);
      let fullAttribute = attributes.find((p) => p.value === this.args.property);
      if (TEAMMATE_ALIAS[this.args.property]) {
        let teammateAttribute = attributes.find(
          (p) => p.value === TEAMMATE_ALIAS[this.args.property],
        );
        if (teammateAttribute) {
          fullAttribute = teammateAttribute;
        }
      }

      return {
        label: fullAttribute?.text,
        icon: fullAttribute?.icon,
      };
    } else {
      return {
        label: this.intl.t('reporting.custom-reports.chart.breakdown.none'),
      };
    }
  }

  _standardAttributeForDisplay(attribute) {
    let tooltipText = this.intl.exists(attribute.groupTooltip)
      ? this.intl.t(attribute.groupTooltip)
      : attribute.groupTooltip;
    return {
      text: this.intl.exists(attribute.name) ? this.intl.t(attribute.name) : attribute.name,
      value: attribute.field,
      group: attribute.group,
      tooltipText: tooltipText ? sanitizeHtml(tooltipText) : null,
      icon: attribute.icon,
      isDisabled: false,
      isSelected: this.args.property === attribute.field,
    };
  }

  get addNoneOption() {
    return this.args.allowNone;
  }

  get isHourlyIntervalDisabled() {
    return !isWithinSafeLimit('hour', this.args.range.inDays, this.args.chartType);
  }

  get isDailyIntervalDisabled() {
    return !isWithinSafeLimit('day', this.args.range.inDays, this.args.chartType);
  }

  get tooltip() {
    if (this.args.isDisabled) {
      return undefined;
    }
    return this.args.tooltip;
  }
}
