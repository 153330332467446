/* import __COLOCATED_TEMPLATE__ from './message-type.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { objectTypes, objectNames } from 'embercom/models/data/matching-system/matching-constants';

export default class MessageType extends Component {
  get messageTypes() {
    return {
      email: {
        value: objectTypes.email,
        label: objectNames[objectTypes.email],
      },
      sms: {
        value: objectTypes.sms,
        label: objectNames[objectTypes.sms],
      },
    };
  }

  @action
  updateMessageType(value) {
    this.args.subscriptionType.permittedContentTypes = [value];
  }
}
