/* import __COLOCATED_TEMPLATE__ from './background-select-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';

export default class BackgroundSelectComponent extends Component {
  get backgroundClass() {
    return `o__m4__${this.args.background}`;
  }

  get selected() {
    return this.args.background === this.args.selectedBackground;
  }

  get selectedClass() {
    return this.selected ? 'o__selected' : '';
  }
}
