/* import __COLOCATED_TEMPLATE__ from './source-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class SourceFilter extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get filterValues() {
    return {
      search_browse: this.intl.t('articles.reports.filters.messenger-source.search-browse'),
      suggested_article: this.intl.t('articles.reports.filters.messenger-source.suggested-article'),
      home_screen: this.intl.t('articles.reports.filters.messenger-source.home-screen'),
      conversation: this.intl.t('articles.reports.filters.messenger-source.conversation'),
    };
  }
}
