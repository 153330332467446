/* import __COLOCATED_TEMPLATE__ from './set-ticket-custom-state.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type Session from 'embercom/services/session';

export interface SetTicketCustomStateArgs {
  action: any;
  onChange?: () => void;
  isDisabled?: boolean;
  onDelete?: () => void;
}

interface Signature {
  Element: HTMLElement;
  Args: SetTicketCustomStateArgs;
  Blocks: {
    default: [args: { opener: unknown; ticketCustomState: string }];
  };
}

export default class SetTicketCustomState extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare ticketStateService: TicketStateService;
  @service declare session: Session;

  get ticketStates() {
    let liveTicketCustomStates = this.ticketStateService.liveTicketCustomStates;

    return this.ticketStateService.getGroupedCustomStatesForDropdown(liveTicketCustomStates, {
      showUserLabel: true,
      showRelatedTicketTypes: true,
      additionalItemClasses: 'max-w-48',
    });
  }

  get ticketCustomStateId() {
    return this.args.action.actionData?.ticket_custom_state_id;
  }

  get ticketCustomState() {
    if (this.ticketCustomStateId === undefined) {
      return '';
    }

    let matchedTicketState = this.ticketStateService.getTicketCustomStateById(
      this.ticketCustomStateId,
    );
    return matchedTicketState?.adminLabel || '';
  }

  @action
  setTicketCustomState(ticketCustomStateId: number) {
    this.args.action.actionData = { ticket_custom_state_id: ticketCustomStateId };

    if (this.args.onChange) {
      this.args.onChange();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Rules::Actions::SetTicketCustomState': typeof SetTicketCustomState;
  }
}
