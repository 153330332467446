/* import __COLOCATED_TEMPLATE__ from './custom-address-row.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import resendSenderEmailVerificationLink from 'embercom/lib/settings/resend-sender-email-verification-link';

export default class CustomAddressRow extends Component {
  @service appService;
  @service intercomEventService;
  @service notificationsService;
  @service intl;
  @service intercomConfirmService;

  @tracked tagName = 'tr';
  @tracked isDeleting = false;
  @tracked isEditing = false;
  @tracked editableName;

  get adminReplyDefaultAddress() {
    return this.appService.app?.admin_reply_default_address_id;
  }

  get isUnverifiedDomain() {
    return !this.args.domainVerificationMap[this.args.row.data.domain];
  }

  @action
  toggleEditMode() {
    if (!this.isEditing) {
      this.editableName = this.args.row.data.name;
    }
    this.isEditing = !this.isEditing;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'edit_sender_email_address',
      place: 'settings_sender_email_addresses',
    });
  }

  @action
  async deleteSender() {
    let senderEmail = this.args.row.data;
    if (senderEmail.id === this.adminReplyDefaultAddress) {
      return;
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'delete_sender_email_address',
      place: 'settings_sender_email_addresses',
    });
    let isConfirmed = await this.intercomConfirmService.confirm({
      title: this.intl.t('components.settings.senders.manage-custom-addresses.delete-modal.title'),
      body: this.intl.t('components.settings.senders.manage-custom-addresses.delete-modal.body', {
        senderEmail: senderEmail.email,
      }),
      confirmButtonText: this.intl.t(
        'components.settings.senders.manage-custom-addresses.delete-modal.button-text',
      ),
      primaryButtonType: 'primary-destructive',
    });
    if (!isConfirmed) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'cancelled',
        object: 'delete_sender_email_address',
        place: 'settings_sender_email_addresses',
      });
      return;
    }
    this.isDeleting = true;
    try {
      await senderEmail.destroyRecord();
      this.isDeleting = false;
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'components.settings.senders.manage-custom-addresses.delete-modal.success-confirmation',
          {
            senderEmail: senderEmail.displayAs,
          },
        ),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'success',
        object: 'delete_sender_email_address',
        place: 'settings_sender_email_addresses',
      });
    } catch (e) {
      this.isDeleting = false;
      this.notificationsService.notifyResponseError(e);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'error',
        object: 'delete_sender_email_address',
        place: 'settings_sender_email_addresses',
      });
    }
  }

  @action
  async updateName() {
    if (!this.editableName) {
      return;
    }
    let senderEmail = this.args.row.data;
    senderEmail.name = this.editableName;

    try {
      await senderEmail.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('components.settings.senders.manage-custom-addresses.success-confirmation'),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'update_sender_email_address',
        place: 'settings_sender_email_addresses',
      });
      this.toggleEditMode();
    } catch (e) {
      this.notificationsService.notifyResponseError(e);
    }
  }

  @action
  resendVerificationLink() {
    resendSenderEmailVerificationLink(this.args.row.data.id);
  }
}
