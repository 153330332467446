/* import __COLOCATED_TEMPLATE__ from './filter-manager.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Component.extend({
  appService: service(),
  tagName: '',

  init() {
    this._super(...arguments);
    this.set('filters', []);
    this.set('populatedFilters', []);
  },

  didReceiveAttrs() {
    this._super(...arguments);
    this.set('filters', this.savedFilters || []);
    this.set('populatedFilters', this.savedFilters);
  },

  allFiltersHaveValues: computed('filters', function () {
    return this.filters.every((filter) => filter.value);
  }),

  availableFilters: computed('trustedList', 'blocksByKey', function () {
    return (
      this.trustedList && this.trustedList.length ? this.trustedList : Object.keys(this.blocksByKey)
    ).reduce(
      (availableFilterBlocks, key) => ({
        ...availableFilterBlocks,
        [key]: this.blocksByKey[key],
      }),
      {},
    );
  }),

  query: computed('blocksByKey', 'populatedFilters', function () {
    return this.filters
      .reduce(
        (filters, filter) => [
          ...filters,
          filter.value && { [this.blocksByKey[filter.key].field]: filter.value },
        ],
        [],
      )
      .filter((filter) => filter);
  }),

  actions: {
    addFilterFieldByKey(key) {
      this.set('filters', [...this.filters, { key }]);
    },

    removeFilterByIndex(index) {
      let toRemove = this.filters[index];
      let filters = this.filters.filter((filter, i) => i !== index);

      this.set('filters', filters);
      if (toRemove.value) {
        this.set('populatedFilters', filters);
      }

      this.updateView({ filters });
    },

    updateFilterValueByIndex(index, name, valueOptions, value) {
      let filters = this.filters.reduce((filters, filter, i) => {
        let updatedValue = filter.value === value ? undefined : value;
        let valueLabel = valueOptions.find((option) => option.value === value).name;

        return [
          ...filters,
          i === index ? { key: filter.key, value: updatedValue, name, valueLabel } : filter,
        ];
      }, []);
      this.set('filters', filters);
      this.set('populatedFilters', filters);
      this.updateView({ filters });
    },
  },
});
