/* import __COLOCATED_TEMPLATE__ from './tagging-modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class TaggingModal extends Component {
  @service notificationsService;
  @service seriesEditorService;

  get series() {
    return this.seriesEditorService.series;
  }

  @action
  afterTagSave() {
    this.notificationsService.notifyConfirmation('Tags applied');
    this.series.reload();
    this.args.onClose();
  }
}
