/* import __COLOCATED_TEMPLATE__ from './stats.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import TopicStats from 'embercom/lib/conversational-insights/topic-stats';

export default class TopicStatsComponent extends Component {
  @service appService;
  @service router;
  @service store;
  @service topicStatsService;
  @service intl;

  @tracked stats;

  reportingSettings = this.loadReportingSettings.perform();
  metrics = this.metrics;

  get metrics() {
    return [
      {
        title: this.intl.t('reporting.conversational-insights.topics.table.columns.volume'),
        value: 'conversation_count',
        currentStatsPath: 'currentCount',
        previousStatsPath: 'previousCount',
        improvementDirection: 'decreasing',
      },
      {
        title: this.intl.t(
          'reporting.conversational-insights.topics.table.columns.first-response-time',
        ),
        value: 'response_time',
        dashboardRoute: 'apps.app.reports.customer-support.responsiveness',
        currentStatsPath: 'currentResponseTime',
        previousStatsPath: 'previousResponseTime',
        improvementDirection: 'decreasing',
        format: 'seconds',
      },
      {
        title: this.intl.t('reporting.conversational-insights.topics.table.columns.time-to-close'),
        value: 'time_to_close',
        dashboardRoute: 'apps.app.reports.customer-support.responsiveness',
        currentStatsPath: 'currentTimeToClose',
        previousStatsPath: 'previousTimeToClose',
        improvementDirection: 'decreasing',
        format: 'seconds',
      },
      {
        title: this.intl.t(
          'reporting.conversational-insights.topics.table.columns.customer-satisfaction',
        ),
        value: 'csat',
        dashboardRoute: 'apps.app.reports.customer-support.customer-satisfaction',
        currentStatsPath: 'currentPositiveRate',
        previousStatsPath: 'previousPositiveRate',
        improvementDirection: 'increasing',
        format: 'percentage_with_csat_emojis',
      },
      {
        title: this.intl.t(
          'reporting.conversational-insights.topics.table.columns.most-conversations-assigned',
        ),
        value: 'team_assignee_counts',
        dashboardRoute: 'apps.app.reports.customer-support.team-performance',
        currentStatsPath: 'currentMostAssignedTeam',
        previousStatsPath: 'previousMostAssignedTeam',
      },
    ];
  }

  @task
  *loadReportingSettings() {
    return yield this.store.queryRecord('reporting/settings', {});
  }

  @task
  *loadStats() {
    if (!this.args.topic.keyphrases?.length) {
      this.stats = {};
      return;
    }

    let range = this.args.range;
    let { excludeOooHours } = yield this.reportingSettings;

    let {
      stats: [rawStats],
    } = yield this.topicStatsService.loadRawKeyphraseStats.perform({
      rangeStart: range?.start,
      rangeEnd: range?.end,
      excludeOooHours,
      excludeBotInboxTime: this.args.botInboxTimeExcluded || false,
      keyphrases: this.args.topic.keyphrases,
      excludedKeyphrases: this.args.topic.excludedKeyphrases,
      authorTypes: this.args.topic.authorTypes,
      conversationCustomData: this.args.topic.conversationCustomData,
      topicId: this.args.topic.id,
    });

    this.stats = new TopicStats(rawStats);
  }
}
