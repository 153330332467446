/* import __COLOCATED_TEMPLATE__ from './download.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class DownloadReport extends Component {
  @service intercomEventService;
  @service notificationsService;
  @service reportSubscriptionService;
  @service appService;
  @service permissionsService;
  @tracked downloading;
  @service intl;

  constructor() {
    super(...arguments);
  }

  get dashboardId() {
    return this.args.dashboard.dashboardId;
  }

  get adminEmail() {
    return this.appService.app.currentAdmin.email;
  }

  get bannerMessage() {
    return this.intl.t('reporting.download.pdf-download', { email: this.adminEmail });
  }

  @task
  *download() {
    yield this.permissionsService.checkHierarchyPermissionWithFeatureFlag(
      'can_reporting__reports__share',
    );
    this.downloading = true;
    this.subscription = this.reportSubscriptionService.createNewDefaultSubscription();
    this.subscription.jsonBlob = this.args.dashboard.toJson();
    this.subscription.cadence = 'one_time';
    try {
      yield this.subscription.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'download',
        object: 'report_schedule',
        dashboard_id: this.dashboardId,
      });
      this.notificationsService.notifyConfirmation(this.bannerMessage);
    } catch (e) {
      console.error(e.jqXHR?.responseJSON);
      this.notificationsService.notifyError(this.intl.t('reporting.download.error'));
    }
    this.downloading = false;
  }
}
