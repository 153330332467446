/* import __COLOCATED_TEMPLATE__ from './copilot-access-editor.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  COPILOT_SECTION_ID,
  SUPPORT_SEAT_TYPE as SUPPORT,
  VBP2_SEAT_LABELS_KEYS,
} from 'embercom/lib/settings/seats/constants';
import { FIN_AI_COPILOT_BASE_ID } from 'embercom/lib/billing';
import {
  NO_ACCESS,
  LIMITED_USAGE,
  UNLIMITED_USAGE,
} from 'embercom/lib/settings/copilot-access-editor/constants';
import { tracked } from '@glimmer/tracking';
import { keepLatestTask } from 'ember-concurrency-decorators';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import type Price from 'embercom/models/price';
import { trackedReset } from 'tracked-toolbox';
import { action } from '@ember/object';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  accessType: string;
  toggleCopilotAccess: (value: string) => void;
  openSections: { [key: string]: boolean };
  onOpenSectionChange: (key: string) => void;
}

export default class CopilotAccessEditor extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare customerService: $TSFixMe;

  @tracked copilotSeatPrice = '';
  @tracked priceLoading = true;

  @tracked accordionState: string | null = COPILOT_SECTION_ID;
  @trackedReset('args.openSections') copilotAccessEditorId = this.sectionState;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadCopilotSeatPrice).perform();
  }

  get app() {
    return this.appService.app;
  }

  get accessOptionItems() {
    let componentAttrs = {
      selectedValue: this.args.accessType,
      buttonDisabled: false,
    };

    let optionItems = [
      {
        text: this.intl.t('components.settings.teammates.copilot-access-editor.no-access'),
        value: NO_ACCESS,
        componentAttrs,
      },
      {
        text: this.intl.t('components.settings.teammates.copilot-access-editor.free-limited-usage'),
        value: LIMITED_USAGE,
        componentAttrs,
      },
      {
        text: this.intl.t('components.settings.teammates.copilot-access-editor.unlimited-usage'),
        value: UNLIMITED_USAGE,
        requiresPaidSeat: true,
        price: this.copilotSeatPrice,
        disabled: true,
        componentAttrs,
      },
    ];

    return optionItems;
  }

  get seatTagTranslationKey(): string {
    if (this.app.hasMultipleSeatTypes && !this.app.onPricing5) {
      return VBP2_SEAT_LABELS_KEYS[SUPPORT] as string;
    }

    if (this.app.canUsePerProductPricingFlow) {
      return 'settings.seats.inbox';
    }

    return 'settings.seats.full';
  }

  @keepLatestTask
  *loadCopilotSeatPrice() {
    yield this.customerService.bulkLoadPrices([
      {
        planIds: [parseInt(FIN_AI_COPILOT_BASE_ID, 10)],
      },
    ]);

    let price = this.customerService.prices.find((price: Price) => {
      return price.hasSamePlans([parseInt(FIN_AI_COPILOT_BASE_ID, 10)]);
    })?.perUnitPrice;

    if (price) {
      this.copilotSeatPrice = this._formatPriceFromCents(price);
    }

    this.priceLoading = false;
  }

  _formatPriceFromCents(price: number, minimumFractionDigits = 0) {
    return this.intl.formatNumber(price / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits,
    });
  }

  get sectionState() {
    return this.args.openSections
      ? this.args.openSections[COPILOT_SECTION_ID] && COPILOT_SECTION_ID
      : this.accordionState;
  }

  get icon(): InterfaceIconName {
    return 'fin';
  }

  get sectionKey() {
    return COPILOT_SECTION_ID;
  }

  get getStampText() {
    switch (this.args.accessType) {
      case UNLIMITED_USAGE:
        return this.intl.t('components.settings.teammates.copilot-access-editor.unlimited-usage');
      case LIMITED_USAGE:
        return this.intl.t(
          'components.settings.teammates.copilot-access-editor.free-limited-usage',
        );
      default:
        return this.intl.t('components.settings.teammates.copilot-access-editor.no-access');
    }
  }

  get getStampColor() {
    switch (this.args.accessType) {
      case NO_ACCESS:
        return 'gray';
      case LIMITED_USAGE:
        return 'sky';
      default:
        return 'blue';
    }
  }

  @action
  onOpenSectionChange() {
    this.accordionState = this.accordionState ? null : COPILOT_SECTION_ID;
    if (this.args.onOpenSectionChange) {
      this.args.onOpenSectionChange(COPILOT_SECTION_ID);
    } else {
      this.copilotAccessEditorId = this.sectionState;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::Roles::CopilotAccessEditor': typeof CopilotAccessEditor;
    'settings/teammates/roles/copilot-access-editor': typeof CopilotAccessEditor;
  }
}
