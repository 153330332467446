/* import __COLOCATED_TEMPLATE__ from './ip-allowlist-settings.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { run } from '@ember/runloop';

export default class IpAllowlistSettings extends Component {
  @service intl;
  @service intercomEventService;

  @tracked settings = this.args.settings;

  @action
  toggleAllowlistEnabled() {
    this.settings.ipAllowlistEnabled = !this.settings.ipAllowlistEnabled;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'ip_allowlist_switch',
      ip_allowlist_enabled: this.settings.ipAllowlistEnabled,
    });
  }

  @action
  updateIpAllowlist() {
    // We need to run this in a runloop because the the on modifier does not schedule the event listener onto the run loop
    // https://github.com/emberjs/ember.js/issues/19222#issuecomment-1049773929
    run(() => {
      this.settings.updateIpAllowlist();
    });
  }
}
