/* import __COLOCATED_TEMPLATE__ from './response-time-range-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { isEmpty } from '@ember/utils';

const SECONDS_IN = {
  minute: 60,
  hour: 3600,
  day: 3600 * 24,
};

export default class ResponseTimeRangeFilter extends Component {
  @service appService;
  @service store;
  @service intercomEventService;
  @service intl;

  @tracked label = this.currentLabel;
  // store the selection here so that the UI doesn't update until the drop down is closed
  @tracked selectedValue = this.startingValue;
  @tracked selectedUnit = this.startingUnit;
  @tracked operator = this.startingOperator;
  @tracked includeBotInboxTime = this.startingIncludeBotInboxTime;

  @tracked operatorSettings;
  @tracked reportingSettings;

  unitsHash = {
    1: {
      text: this.intl.t('reporting.flexible-filters.range.short-form.seconds'),
      fullTextKey: 'reporting.flexible-filters.range.long-form.seconds',
    },
    [SECONDS_IN.minute]: {
      text: this.intl.t('reporting.flexible-filters.range.short-form.minutes'),
      fullTextKey: 'reporting.flexible-filters.range.long-form.minutes',
    },
    [SECONDS_IN.hour]: {
      text: this.intl.t('reporting.flexible-filters.range.short-form.hours'),
      fullTextKey: 'reporting.flexible-filters.range.long-form.hours',
    },
    [SECONDS_IN.day]: {
      text: this.intl.t('reporting.flexible-filters.range.short-form.days'),
      fullTextKey: 'reporting.flexible-filters.range.long-form.days',
    },
  };

  units = Object.keys(this.unitsHash).map((unit) => {
    return {
      text: this.unitsHash[unit].text,
      value: parseInt(unit, 10),
    };
  });

  constructor() {
    super(...arguments);
    this.loadOperatorBotInboxSettings.perform();
    this.reportingSettings = this.loadReportingSettings;
  }

  @task({ restartable: true }) *loadOperatorBotInboxSettings() {
    this.operatorSettings = yield this.store.findRecord(
      'operator-bot-inbox-settings',
      this.appService.app.id,
    );
  }

  get loadReportingSettings() {
    return (
      this.store.peekAll('reporting/settings')?.firstObject ||
      this.store.queryRecord('reporting/settings', {})
    );
  }

  get excludeOooHours() {
    if (this.reportingSettings === undefined) {
      return false;
    }
    return this.reportingSettings?.excludeOooHours;
  }

  get startingExcludeOooHours() {
    return Boolean(this.args.selected.field?.includes('in_office_hours'));
  }

  get startingIncludeBotInboxTime() {
    return !this.args.selected.field?.includes('excluding_bot_inbox');
  }

  get operatorLabelMappings() {
    return {
      gt: this.intl.t('reporting.flexible-filters.range.more-than'),
      lt: this.intl.t('reporting.flexible-filters.range.less-than'),
    };
  }

  get startingValue() {
    if (isEmpty(this.args.selected.values)) {
      return null;
    }

    let value = this.args.selected.values[0] / this.startingUnit;
    return Math.round(value * 100) / 100;
  }

  get startingUnit() {
    if (isEmpty(this.args.selected.values)) {
      return 1;
    }
    let val = this.args.selected.values[0];
    if (val >= SECONDS_IN.day) {
      return SECONDS_IN.day;
    } else if (val >= SECONDS_IN.hour) {
      return SECONDS_IN.hour;
    } else if (val >= SECONDS_IN.minute) {
      return SECONDS_IN.minute;
    } else {
      return 1;
    }
  }

  get labelForCurrentUnit() {
    return (
      this.fullText(this.unitsHash[this.selectedUnit]?.fullTextKey) || REPORTING_FILTER_SELECT_ALL
    );
  }

  fullText(fullTextKey) {
    return this.intl.t(fullTextKey, { count: this.selectedValue });
  }

  get startingOperator() {
    return this.args.selected.operator || 'gt';
  }

  get currentLabel() {
    switch (this.operator) {
      case 'gt':
      case 'lt': {
        return `${this.operatorLabelMappings[this.operator]} ${this.labelForCurrentUnit}`;
      }
    }
  }

  get shouldDisplayBotInboxToggle() {
    return this.operatorSettings?.assignConversationsToBots;
  }

  get openerLabel() {
    return this.currentLabel || 'Add';
  }

  get valuesForFilter() {
    let valuesOrPlaceholder = this.selectedValue ? [this.selectedValue * this.selectedUnit] : [0];
    return valuesOrPlaceholder;
  }

  get newFilterAttributeId() {
    if (this.includeBotInboxTime) {
      return this.excludeOooHours
        ? 'conversation.first_response_time_in_office_hours'
        : 'conversation.first_response_time';
    } else {
      return this.excludeOooHours
        ? 'conversation.first_response_time_excluding_bot_inbox_in_office_hours'
        : 'conversation.first_response_time_excluding_bot_inbox';
    }
  }

  get minWidthOfOverlay() {
    return this.shouldDisplayBotInboxToggle ? 250 : 232;
  }

  @action
  updateSelectedUnit(id) {
    this.selectedUnit = id;
  }

  @action
  toggleIncludeBotInboxTime() {
    this.includeBotInboxTime = !this.includeBotInboxTime;
  }

  @action
  onClose() {
    if (!this.valuesForFilter.length && !this.operator) {
      return;
    }

    let analyticsObject;

    analyticsObject = {
      action: 'filtered_first_response_time',
      object: this.args.analyticsObject,
      filter_name: this.args.type,
      custom_field_type: this.args.attributeType || 'string',
    };
    this.intercomEventService.trackAnalyticsEvent(analyticsObject);
    // need to clear the old type and add the new type
    this.args.replaceFilter(this.newFilterAttributeId, this.valuesForFilter, this.operator);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedValue = null;
    this.operator = null;
    this.args.removeFilter();
  }
}
