/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  translationType,
  stateOptions,
  consentType,
} from 'embercom/models/outbound-subscriptions/subscription-type';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class Editor extends Component {
  @service intercomEventService;
  @service notificationsService;
  @service store;
  @service router;
  @service appService;

  @tracked subscriptionType;

  @action
  createNewSubscriptionType() {
    if (this.args.subscriptionType) {
      this.subscriptionType = this.args.subscriptionType;
      return;
    }
    let locales = [];
    this.args.translationSettings.translationLocaleIds.forEach((locale) =>
      locales.push({
        locale,
        name: '',
        description: '',
      }),
    );

    this.subscriptionType = this.store.createRecord('outbound-subscriptions/subscription-type', {
      translationType: translationType.custom,
      customTranslations: locales,
      state: stateOptions.draft,
      consentType: consentType.optIn,
      permittedContentTypes: [objectTypes.email],
    });
  }

  @action
  redirectToIndex() {
    let route = 'apps.app.settings.proactive-support.subscriptions.index';
    this.router.transitionTo(route, {
      queryParams: {
        expandLocalize: true,
      },
    });
  }
}
