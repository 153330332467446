/* import __COLOCATED_TEMPLATE__ from './compliance-documents.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class ComplianceDocuments extends Component {
  @service router;
  @service appService;
  @tracked showModal = false;
  @tracked actionLink = '';

  constructor() {
    super(...arguments);
  }

  get appId() {
    return this.appService.app.id;
  }

  @action
  confirmationModal(rowData) {
    this.actionLink = rowData.actionLink;
    if (this.actionLink === 'goToCsa') {
      safeWindowOpen(
        'https://cloudsecurityalliance.org/star/registry/intercom-inc/services/intercom-inc/',
        '_blank',
      );
    } else {
      this.showModal = true;
    }
  }

  @action
  linkDocs(actionLink) {
    this.showModal = false;
    switch (actionLink) {
      case 'downloadSoc':
        safeWindowOpen(`/ember/compliance_documents/download?document=soc&app_id=${this.appId}`);
        break;
      case 'downloadIso':
        safeWindowOpen(`/ember/compliance_documents/download?document=iso&app_id=${this.appId}`);
        break;
      case 'downloadHipaa':
        safeWindowOpen(`/ember/compliance_documents/download?document=hipaa&app_id=${this.appId}`);
        break;
      case 'downloadHdsEnglish':
        safeWindowOpen(
          `/ember/compliance_documents/download?document=hds_english&app_id=${this.appId}`,
        );
        break;
      case 'downloadHdsFrench':
        safeWindowOpen(
          `/ember/compliance_documents/download?document=hds_french&app_id=${this.appId}`,
        );
        break;
      case 'downloadPenTest':
        safeWindowOpen(
          `/ember/compliance_documents/download?document=pen_test&app_id=${this.appId}`,
        );
        break;
    }
  }
}
