/* import __COLOCATED_TEMPLATE__ from './new-conversations-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { useTask } from 'ember-resources';
import { inject as service } from '@ember/service';
import { NEW_CONVERSATIONS_BREAKDOWN } from 'embercom/lib/reporting/queries';
import {
  asPercentages,
  filterContextKeys,
  reverse,
  sortContext,
} from 'embercom/lib/reporting/signal-transformations';

const BREAKDOWN_FETCH_SIGNAL_PARAMS = {
  valueUnit: 'new conversations',
  signalConfig: {
    ...NEW_CONVERSATIONS_BREAKDOWN,
    keys_are_dates: false,
  },
  aggregations: [...NEW_CONVERSATIONS_BREAKDOWN.aggregations],
};

export default class NewConversationsBreakdown extends Component {
  @service reportingService;

  data = useTask(this, this.fetchData, () => [this.args.range]);

  @task
  *fetchData() {
    let signal = yield this.reportingService.fetchStackedSignal(
      {
        debugName: 'new_conversations_breakdown',
        ...BREAKDOWN_FETCH_SIGNAL_PARAMS,
        range: this.args.range,
      },
      (signal) => signal.transform(asPercentages, sortContext, reverse),
    );

    return {
      inbound: signal.transform(filterContextKeys('true')),
      outbound: signal.transform(filterContextKeys('false')),
    };
  }
}
