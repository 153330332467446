/* import __COLOCATED_TEMPLATE__ from './invite.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isValidEmail } from 'embercom/lib/email';
import { isEmpty } from '@ember/utils';
import { schedule } from '@ember/runloop';
import { getSeatInfo } from 'embercom/lib/admins/seat-info';
import { task } from 'ember-concurrency-decorators';
import checkEmailIsPersonal from 'embercom/utils/check-email-domain';

const EMAIL_SEPARATORS = [',', '|', ';', ' ', '\n', '\t'];
const EMAIL_SPLIT_REGEX = new RegExp(`[${EMAIL_SEPARATORS.join('\\')}]+`);
const ACTIVE_INVITES_LIMIT = 50;

export default class Invite extends Component {
  @service appService;
  @service customerService;
  @service store;
  @service intl;

  @tracked inviteEmails = [];
  @tracked newEmail = '';
  @tracked seatInfo;
  @tracked emailInputMessage;
  @tracked isWorkEmail;

  constructor() {
    super(...arguments);
    this.loadInvitedAdmins();
    this.addEmails(this.args.emails.join(','));
    this.customerService.ensureDataIsLoaded.perform();
    this.setIsWorkEmail();
  }

  async setIsWorkEmail() {
    let personalEmailDomain = await checkEmailIsPersonal(this.app.currentAdmin.email.split('@')[1]);
    this.isWorkEmail = !personalEmailDomain;
  }

  get app() {
    return this.appService.app;
  }

  get customer() {
    return this.customerService.customer;
  }

  async loadInvitedAdmins() {
    await this.store.findAll('invited-admin');
  }

  addEmails(value) {
    this.emailInputMessage = undefined;
    let existingEmails = new Set(this.inviteEmails.map((obj) => obj.value));
    let adminEmails = new Set(this.app.admins.map((admin) => admin.email));
    let alreadyInvitedTeammateEmails = new Set(
      this.store
        .peekAll('invited-admin')
        .filter((invite) => invite.active)
        .map((invite) => invite.email),
    );

    this.extractEmails(value).forEach((email) => {
      let invalidReason = this.invalidTooltipMessage({
        isExistingEmail: existingEmails.has(email),
        isAlreadyInvitedEmail: alreadyInvitedTeammateEmails.has(email),
        isAdminEmail: adminEmails.has(email),
        isInvalidEmail: !isValidEmail(email),
      });

      this.inviteEmails.pushObject({
        value: email,
        isInvalid: invalidReason !== null,
        invalidTooltipContent: invalidReason,
      });
      existingEmails.add(email);
    });
    this.truncateEmails();
  }

  extractEmails(value) {
    return value
      .trim()
      .split(EMAIL_SPLIT_REGEX)
      .map((email) => email.trim())
      .map((email) => {
        if (this.surroundedWithBrackets(email)) {
          return email.slice(1, email.length - 1);
        } else {
          return email;
        }
      })
      .filter((email) => email !== '');
  }

  surroundedWithBrackets(email) {
    if (email[0] === '<' && email[email.length - 1] === '>') {
      return true;
    }
    return false;
  }

  truncateEmails() {
    if (this.customer?.inCardlessTrial) {
      let alreadyInvitedTeammateEmails = new Set(
        this.store.peekAll('invited-admin').filter((invite) => invite.active),
      ).size;
      let totalInvites = this.inviteEmails.length + alreadyInvitedTeammateEmails;
      let invitesLeft = ACTIVE_INVITES_LIMIT - alreadyInvitedTeammateEmails;
      if (totalInvites > ACTIVE_INVITES_LIMIT) {
        this.emailInputMessage = `You can't invite more than the allowed limit ${ACTIVE_INVITES_LIMIT}, contact support if you need to invite more.`;
        this.inviteEmails = this.inviteEmails.slice(0, invitesLeft);
        return;
      }
    }

    if (!this.seatInfo) {
      return;
    }
    if (this.seatInfo.maximumNumberOfSeats === null) {
      this.emailInputMessage = undefined;
      return;
    }

    let maximumNumberOfAvailableSeats = Math.max(
      0,
      this.seatInfo.maximumNumberOfSeats - this.seatInfo.numberOfSeatsInUse,
    );
    if (this.inviteEmails.length < maximumNumberOfAvailableSeats) {
      this.emailInputMessage = undefined;
      return;
    }

    if (
      this.inviteEmails.length === maximumNumberOfAvailableSeats &&
      isEmpty(this.emailInputMessage)
    ) {
      this.emailInputMessage = this.intl.t(
        'settings.teammates.invites.invite.upgrade-to-add-more-teammates',
      );
      return;
    }

    if (this.inviteEmails.length > maximumNumberOfAvailableSeats) {
      this.emailInputMessage = `${maximumNumberOfAvailableSeats} out of ${this.inviteEmails.length} emails added, upgrade to add more`;
      this.inviteEmails = this.inviteEmails.splice(0, maximumNumberOfAvailableSeats);
      return;
    }
  }

  get canContinue() {
    return (
      (this.inviteEmails.length > 0 || this.newEmail.length > 0) &&
      !this.inviteEmails.any((obj) => obj.isInvalid)
    );
  }

  addNewlyEnteredEmail() {
    this.addEmails(this.newEmail);
    this.newEmail = '';
  }

  invalidTooltipMessage({ isExistingEmail, isAlreadyInvitedEmail, isAdminEmail, isInvalidEmail }) {
    if (isInvalidEmail) {
      return this.intl.t('settings.teammates.invites.invite.invalid-email-format');
    } else if (isExistingEmail) {
      return this.intl.t('settings.teammates.invites.invite.existing-email');
    } else if (isAdminEmail) {
      return this.intl.t('settings.teammates.invites.invite.existing-teammate-email');
    } else if (isAlreadyInvitedEmail) {
      return this.intl.t('settings.teammates.invites.invite.existing-invited-email');
    }
    return null;
  }

  @action onContinue() {
    this.addNewlyEnteredEmail();
    if (this.canContinue) {
      this.args.onEmailsChange(this.inviteEmails.map((obj) => obj.value));
      this.args.onContinue();
    }
  }

  scrollIntoViewAfterRender(element) {
    schedule('afterRender', () => {
      element.scrollIntoView();
    });
  }

  @action handleInput() {
    if (this.inviteEmails.length > 0 || this.newEmail.length > 0) {
      this.args.onUnsavedChanges();
    }
  }

  @action handlePaste(event) {
    let value = (event.clipboardData || window.clipboardData).getData('text');
    this.addEmails(value);
    event.preventDefault();
    this.scrollIntoViewAfterRender(event.target);
  }

  @action handleKeydown(event) {
    if (event.key === 'Enter' || EMAIL_SEPARATORS.includes(event.key)) {
      this.addNewlyEnteredEmail();
      event.preventDefault();
      return;
    }
    if (event.key === 'Backspace' && this.inviteEmails.length > 0 && isEmpty(this.newEmail)) {
      this.inviteEmails.popObject();
      event.preventDefault();
    }
    this.scrollIntoViewAfterRender(event.target);
  }

  @action removeEmail(obj) {
    this.emailInputMessage = undefined;
    this.inviteEmails.removeObject(obj);
  }

  @action focusNewEmailInput({ target, currentTarget }) {
    if (currentTarget === target) {
      let inputElement = currentTarget.getElementsByTagName('input')[0];
      inputElement.focus();
    }
  }

  get scimEnabled() {
    return this.app.scim_enabled;
  }

  get numberOfExtraSeatsRequired() {
    return this.inviteEmails.length + this.extractEmails(this.newEmail).length;
  }

  @task({ restartable: true })
  *loadSeatInfo() {
    this.seatInfo = yield getSeatInfo({ numberOfSeatsRequired: this.numberOfExtraSeatsRequired });
    this.truncateEmails();
    return this.seatInfo;
  }

  get annuallyContractedWithOveragesEnabled() {
    return this.app.canUseSeatOverages && this.app.isSalesforceContracted;
  }
}
