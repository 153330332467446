/* import __COLOCATED_TEMPLATE__ from './chart-settings-modal.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isEmpty, isPresent } from '@ember/utils';
import { aggregationConfig } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import storage from 'embercom/vendor/intercom/storage';
import { MEDIAN } from 'embercom/lib/reporting/flexible/constants';
import { seriesNameForMetric } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import type Chart from 'embercom/models/reporting/custom/chart';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import type { Aggregation, FieldMetric } from 'embercom/objects/reporting/unified/metrics/types';

type Args = {
  renderableChart: RenderableChart;
  chartState: Chart;
  onModalClose: () => void;

  isOpen: boolean;

  title: string;
};
export default class ChartSettingsModal extends Component<Args> {
  @tracked selectedAggregation: Aggregation = MEDIAN;
  constructor(owner: unknown, args: any) {
    super(owner, args);
    if (this.canUpdateAggregation) {
      this.selectedAggregation = storage.get(this.aggregationStorageKey) || MEDIAN;
      this.updateSelectedAggregation();
    }
  }
  get aggregationStorageKey() {
    return `${this.args.renderableChart.reportState?.id}_${this.args.renderableChart.id}_aggregation_method`;
  }

  get canUpdateAggregation() {
    if (isEmpty(this.args.renderableChart?.chartSeries)) {
      return false;
    }
    let series = this.args.renderableChart.chartSeries.toArray();
    return (
      this.args.renderableChart.shouldRenderChrome &&
      series.some((series: ChartSeries) => series.aggregation !== undefined)
    );
  }

  get aggregationItems() {
    let series = this.args.renderableChart.chartSeries.find((series: ChartSeries) =>
      isPresent(series.aggregation),
    );
    let supportedAggregations = series.metric.supportedAggregations;
    if (isEmpty(supportedAggregations)) {
      return [];
    }
    return supportedAggregations
      .reject((aggregation: string) => ['sum', 'percentile'].includes(aggregation))
      .filter((agg: string) => agg in aggregationConfig())
      .map((aggregation: string) => {
        return {
          // @ts-ignore
          text: aggregationConfig()[aggregation].text,
          value: aggregation,
        };
      });
  }

  updateSelectedAggregation() {
    if (!this.canUpdateAggregation || isEmpty(this.args.renderableChart.chartSeries)) {
      return;
    }
    let chartSeries = this.args.renderableChart.chartSeries;
    chartSeries.forEach((series: ChartSeries) => {
      let metric = series.metric as FieldMetric;
      let aggregations = metric.supportedAggregations;
      if (
        isPresent(series.aggregation) &&
        !isEmpty(aggregations) &&
        aggregations.includes(this.selectedAggregation)
      ) {
        series.aggregation = this.selectedAggregation;
      }
    });
    if (this.args.chartState && chartSeries.length === 1) {
      this.args.chartState.title = seriesNameForMetric(
        chartSeries.firstObject.metric.name,
        chartSeries.firstObject.aggregation,
        this.args.renderableChart.shouldRenderChrome,
      );
    }
  }

  @action
  saveSelectedAggregation() {
    this.args.onModalClose();
    if (!this.canUpdateAggregation) {
      return;
    }
    this.storeSelectedAggregation();
    this.updateSelectedAggregation();
  }

  storeSelectedAggregation() {
    storage.set(this.aggregationStorageKey, this.selectedAggregation);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartSettingsModal': typeof ChartSettingsModal;
    'reporting/custom/chart-settings-modal': typeof ChartSettingsModal;
  }
}
