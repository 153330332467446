/* import __COLOCATED_TEMPLATE__ from './records-table.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class RecordsTable extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get data() {
    let rows = [
      {
        name: this.args.dkimRecord?.domainForCustomerDnsRecord,
        type: this.intl.t('settings.domains.manage.cname'),
        value: `${this.args.dkimRecord?.domainForIntercomDnsRecord}.`,
        status: this.args.dkimRecord?.validRecordExists,
      },
      {
        name: this.args.customBounceSettings?.host,
        type: this.intl.t('settings.domains.manage.cname'),
        value: `${this.args.customBounceSettings?.value}.`,
        status: this.args.customBounceSettings?.validRecordExists,
      },
      {
        name: `_dmarc.${this.args.dkimRecord?.domain}`,
        type: this.intl.t('settings.domains.manage.txt'),
        value: 'v=DMARC1; p=none',
        status: this.args.dkimRecord?.validDmarcExists,
      },
    ];
    return rows;
  }

  @action
  trackAnalyticsEvent(eventName) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'copy_records',
      object: eventName,
      context: 'copy_to_clipboard',
      place: 'settings_email_domains',
    });
  }
}
