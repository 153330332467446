/* import __COLOCATED_TEMPLATE__ from './visualization.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import type Chart from 'embercom/models/reporting/custom/chart';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { VIZ_TYPE_TO_ICON_MAPPING } from 'embercom/models/reporting/custom/visualization-options';

interface Arguments {
  chart: Chart;
  disableHeatmaps: boolean;
}
interface Signature {
  Args: Arguments;
}
export default class Visualization extends Component<Signature> {
  @service declare intercomEventService: any;

  @action
  updateVisualization(value: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'edited_vizualization',
      visualization: value,
      object: 'custom_chart',
      custom_chart_id: this.args.chart.id,
    });
    this.args.chart.updateMetricVisualization(value);
  }

  get icons() {
    return VIZ_TYPE_TO_ICON_MAPPING;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::Visualization': typeof Visualization;
    'reporting/custom/chart-builder/visualization': typeof Visualization;
  }
}
