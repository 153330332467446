/* import __COLOCATED_TEMPLATE__ from './date-range-filter.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import Range from 'embercom/models/reporting/range';

export function getDefaultRange() {
  let timezone = moment.tz.guess();
  return Range.createFromPreset('past_4_weeks', timezone);
}

export default class SwitchMonitorFilterDateRange extends Component {
  @tracked range;

  constructor() {
    super(...arguments);
    this.range = getDefaultRange();
  }

  @action
  updateRange(range) {
    this.range = range;

    if (this.args.onChange) {
      this.args.onChange(range);
    }
  }
}
