/* import __COLOCATED_TEMPLATE__ from './dashboard.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { copy } from 'ember-copy';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class Dashboard extends Component {
  @service router;
  @service intl;
  @tracked paywall;

  dashboardId = this.args.dashboardId;
  dashboardName = this.args.dashboardName;
  reverseFilterMappings = this.args.reverseFilterMappings;
  charts = [];

  registerChart(chart) {
    this.charts.push(chart);
  }

  get url() {
    let routePrefix = 'reports';
    let currentUrl = this.router.urlFor(this.router.currentRouteName);
    return `/${currentUrl.substring(currentUrl.indexOf(routePrefix))}`;
  }

  get chartConfigurations() {
    return this.charts.map((chart) => ({
      id: chart.args.id,
      name: chart.args.title,
      type: chart.chartCanonicalName,
      dataConfig: chart.args.dataConfig,
      viewConfig: chart.currentViewConfig,
    }));
  }

  get range() {
    let { start, end, timezone, selectedRange } = this.args.range;
    return {
      start,
      end,
      timezone,
      selectedRange,
    };
  }

  get isPaywalled() {
    return this.paywall?.isActive;
  }

  get paywallBannerContent() {
    return this.intl.t('reporting.dashboard.paywall-banner', {
      dashboardName: this.args.dashboardName,
    });
  }

  @action
  toJson() {
    let filters = { ...this.args.filters, ...this.args.customFilters };

    let chartConfigurations = copy(this.chartConfigurations, true);

    chartConfigurations.forEach((x) => {
      let legend = x.viewConfig.legend;
      if (legend && Object.keys(legend).length > 0) {
        let legendKeys = Object.keys(legend);
        legendKeys.forEach((key) => {
          legend[key] = legend[key]();
        });
      }
    });

    return {
      dashboardId: this.dashboardId,
      dashboardName: this.dashboardName,
      url: this.url,
      charts: chartConfigurations,
      range: this.range,
      filters,
      filterModifiers: this.reverseFilterMappings,
    };
  }
}
