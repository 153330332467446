/* import __COLOCATED_TEMPLATE__ from './permissions-custom-row.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  component: any;
  permissionObject: any;
  changed: () => void;
  isDisabled: boolean;
  withSeats: boolean;
  isDisabledByRole: boolean;
  updatePermission: (key: string, value?: boolean) => void;
}

const PermissionsCustomRow = templateOnlyComponent<Args>();
export default PermissionsCustomRow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'settings/teammates/permissions/permissions-custom-row': typeof PermissionsCustomRow;
  }
}
