/* import __COLOCATED_TEMPLATE__ from './team-participated-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

export default class TeamParticipatedFilter extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;
  @tracked availableTeams = this.appService.app.teams;
  @tracked label = this.currentLabel;
  selectedDropdownItems = this.args.selected;

  get items() {
    return [
      {
        heading: 'Teams',
        items: this.allItems,
      },
    ];
  }

  get allItems() {
    return this.availableTeams.map((team) => ({
      model: team,
      text: team.display_as_assignee,
      value: team.id.toString(),
      component: 'reporting/flexible/filter-bar/teammate-item',
      isSelected: this.args.selected.includes(team.id.toString()),
    }));
  }

  get selectedTeamIds() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get currentLabel() {
    if (this.selectedTeamIds.length === 0) {
      return 'Any';
    }

    let teamNames = this.selectedTeamIds.map((teamId) => {
      return this.availableTeams.find((team) => team.id.toString() === teamId).display_as_assignee;
    });
    return teamNames.join(', ');
  }

  get name() {
    return this.intl.t(
      'components.reporting.custom.chart-builder.filter-bar.team-participated-filter.team-replied-is',
    );
  }

  get selectAllLabel() {
    return this.intl.t(
      'components.reporting.custom.chart-builder.filter-bar.team-participated-filter.any-team-replied',
    );
  }

  @action
  onClose() {
    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_participated_team',
      object: this.args.analyticsObject,
      team_count: this.selectedTeamIds.length,
    });

    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }
}
