/* import __COLOCATED_TEMPLATE__ from './manage-proactive-messages-modal.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class ManageProactiveMessagesModal extends Component {
  @tracked proactiveSupportSelectionType =
    Object.keys(this.args.filterHash).length === 0 ? 'all' : 'filtered';
  @service appService;

  get app() {
    return this.appService.app;
  }
}
