/* import __COLOCATED_TEMPLATE__ from './y-scale.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import AxesMixin from 'embercom/components/reporting/axes-mixin';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { scaleLinear } from 'd3-scale';

const Y_AXIS_ITEM_HEIGHT = 20;

export default Component.extend(AxesMixin, {
  tagName: '',
  data: readOnly('signal.context'),
  chartHeight: 250,
  isStacked: true,
  hasAxis: true,
  renderComparisonData: readOnly('comparison'),
  chartMaxValue: readOnly('yAxisTopInUnits'),
  yAxesTop: readOnly('yAxisTopInLargestUnit'),
  scaleUnit: readOnly('yAxisGranularity'),
  scale: computed('chartHeight', 'minChartValue', 'chartMaxValue', 'hasAxis', function () {
    let rangeMax = this.hasAxis ? this.chartHeight - Y_AXIS_ITEM_HEIGHT : this.chartHeight;
    return scaleLinear().domain([this.minChartValue, this.chartMaxValue]).range([0, rangeMax]);
  }),
  axisScale: computed('chartHeight', 'minChartValue', 'yAxesTop', function () {
    return scaleLinear()
      .domain([this.minChartValue, this.yAxesTop])
      .range([0, this.chartHeight - Y_AXIS_ITEM_HEIGHT]);
  }),
});
