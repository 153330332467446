/* import __COLOCATED_TEMPLATE__ from './ticket-type-states-picker.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type IntlService from 'ember-intl/services/intl';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type SystemStateIconDetails } from 'embercom/services/ticket-state-service';
import { type Router } from '@ember/routing';
import type Session from 'embercom/services/session';

interface TicketTypeStatesPickerArgs {
  ticketCustomStateIds: number[];
  onAdd: (systemState: number) => void;
  onRemove: (systemState: number) => void;
  editMode: boolean;
  showUserLabel: boolean;
}

export interface TicketCustomStateHash {
  [TicketSystemState.Submitted]?: TicketCustomState[];
  [TicketSystemState.InProgress]?: TicketCustomState[];
  [TicketSystemState.WaitingOnCustomer]?: TicketCustomState[];
  [TicketSystemState.Resolved]?: TicketCustomState[];
}
interface TicketCustomStateOptions {
  items: TicketCustomStateOption[];
}
interface TicketCustomStateOption {
  component: string;
  isCreateNew?: boolean;
  state?: TicketCustomState;
  onSelectItem?: () => void;
  isDisabled?: boolean;
  icon?: InterfaceIconName;
  iconColor?: string;
  isEmptyState?: boolean;
  componentShouldReplaceItem?: boolean;
  systemStatesIcons?: SystemStateIconDetails[];
}

const allStatesSelectedDropdownOption: TicketCustomStateOption = {
  component: 'settings/ticket-types/custom-state-dropdown-option',
  isEmptyState: true,
  componentShouldReplaceItem: true,
};

export default class TicketTypeStatesPicker extends Component<TicketTypeStatesPickerArgs> {
  @service declare ticketStateService: TicketStateService;
  @service intercomEventService: $TSFixMe;

  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare session: Session;

  @tracked finishedSetup = false;
  @tracked ticketSystemStates;
  @tracked ticketCustomStateIds = this.args.ticketCustomStateIds;
  @tracked isEditorOpen = false;
  @tracked editorTicketState: TicketSystemState = TicketSystemState.Submitted;

  constructor(owner: unknown, args: TicketTypeStatesPickerArgs) {
    super(owner, args);
    this.ticketSystemStates = this.ticketStateOptions;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'ticket_states_accordion',
      place: 'ticket_states_settings',
    });
  }

  get customerLabelTooltip() {
    return this.intl.t('settings.ticket-data.ticket-type-configuration.customer-label-tooltip');
  }

  get ticketStateArticleUrl() {
    return 'https://www.intercom.com/help/en/articles/8687982-ticket-states-explained';
  }

  get ticketStateOptions() {
    return [
      {
        title: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.system-states.submitted',
        ),
        systemState: TicketSystemState.Submitted,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.Submitted),
      },
      {
        title: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.system-states.in_progress',
        ),
        systemState: TicketSystemState.InProgress,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.InProgress),
      },
      {
        title: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.system-states.waiting_on_customer',
        ),
        systemState: TicketSystemState.WaitingOnCustomer,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.WaitingOnCustomer),
      },
      {
        title: this.intl.t('settings.ticket-data.ticket-type-configuration.system-states.resolved'),
        systemState: TicketSystemState.Resolved,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.Resolved),
      },
    ];
  }

  @action
  ticketStateDescription(systemState: TicketSystemState) {
    if (systemState === TicketSystemState.Submitted) {
      return this.intl.t(
        `settings.ticket-states.ticket-state-editor.description.multiple_submitted`,
      );
    }

    return this.intl.t(`settings.ticket-states.ticket-state-editor.description.${systemState}`);
  }

  get customStatesHash() {
    let ticketCustomStateIds = this.args.ticketCustomStateIds;
    let hash = {
      [TicketSystemState.Submitted]: [],
      [TicketSystemState.InProgress]: [],
      [TicketSystemState.WaitingOnCustomer]: [],
      [TicketSystemState.Resolved]: [],
    };
    if (ticketCustomStateIds) {
      return ticketCustomStateIds
        .map((elem) => this.ticketStateService.getTicketCustomStateById(elem))
        .reduce((acc, cur) => {
          if (!cur) {
            return acc;
          }
          acc[cur.systemState] = acc[cur.systemState] || [];
          acc[cur.systemState]?.push(cur);
          return acc;
        }, hash as TicketCustomStateHash);
    }
    return hash;
  }

  @action
  trackAddCustomState() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'add_state_button',
      place: 'ticket_states_settings',
    });
  }

  @action
  removeCustomState(customStateId: number) {
    this.args.onRemove(customStateId);
  }

  @action
  getSelectedCustomStatesForSystemState(
    systemState: TicketSystemState,
  ): TicketCustomState[] | undefined {
    return this.customStatesHash[systemState];
  }

  @action
  getAllCustomStatesForSystemState(systemState: TicketSystemState): TicketCustomStateOptions[] {
    let items = this.ticketStateService
      .getLiveTicketCustomStatesForSystemState(systemState)
      .filter((customState) => !this.args.ticketCustomStateIds.includes(customState.id))
      .map((customState): TicketCustomStateOption => {
        let systemStateConfig = this.ticketStateOptions.find(
          (option) => option.systemState === customState.systemState,
        );
        return {
          state: { ...customState },
          component: 'settings/ticket-types/custom-state-dropdown-option',
          onSelectItem: () => this.onClickAdd(customState.id),
          icon: (systemStateConfig?.icon || '') as InterfaceIconName,
          iconColor: systemStateConfig?.iconColor || '',
        };
      });
    let buttons = [
      {
        component: 'settings/ticket-types/custom-state-dropdown-option',
        isCreateNew: true,
        componentShouldReplaceItem: true,
        onSelectItem: () => this.onClickCreateNew(systemState),
      },
    ];
    let result = [];
    if (items.length > 0) {
      result.push({ items });
    } else {
      result.push({
        items: [
          {
            systemStatesIcons: this.ticketStateService.getAllSystemStateIcons(),
            ...allStatesSelectedDropdownOption,
          },
        ],
      });
    }
    result.push({ items: buttons });
    return result;
  }

  @action
  onClickCreateNew(systemState: TicketSystemState) {
    this.editorTicketState = systemState;
    this.closeEditor();
    this.isEditorOpen = true;
  }

  @action
  closeEditor() {
    this.isEditorOpen = false;
  }

  @action
  onClickAdd(selectedStateId: number) {
    this.closeEditor();
    this.args.onAdd(selectedStateId);
  }

  @action
  clientLabel(customState: TicketCustomState) {
    return `${this.intl.t('settings.ticket-data.ticket-type-configuration.customer-sees')} "${
      customState?.userLabel
    }"`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketTypes::TicketTypeStatesPicker': typeof TicketTypeStatesPicker;
    'settings/ticket-types/ticket-type-states-picker': typeof TicketTypeStatesPicker;
  }
}
