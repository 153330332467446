/* import __COLOCATED_TEMPLATE__ from './fin-standalone-report.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { filterableAttributesForStandalone } from 'embercom/lib/reporting/custom/filter-helpers';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import { HEIGHT_TYPES, WIDTH_TYPES } from 'embercom/models/reporting/custom/chart';
import PALETTE from '@intercom/pulse/lib/palette';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type ReportingSettings from 'embercom/models/reporting/settings';

interface Args {
  conversationAttributeDescriptors: ConversationAttributeDescriptor[];
  settings: typeof ReportingSettings;
}

export const DEFAULT_FIN_CHART_DEFINITION = {
  id: null,
  title: null,
  visualization_type: null,
  metric_property: null,
  metric_function: null,
  date_range: {
    property: 'first_user_conversation_part_created_at',
    selection: 'past_12_weeks',
    start: null,
    end: null,
  },
  stacked: false,
  view_by: null,
  view_by_time_interval: null,
  view_by_display_limit: 10000,
  view_by_display_other: false,
  width_type: null,
  height_type: null,
};

export default class FinStandaloneReport extends Component<Args> {
  @service declare intl: IntlService;

  filterableProperties = filterableAttributesForStandalone();

  get reportDefinition() {
    return {
      id: `fin_standalone`,
      title: this.intl.t('reporting.fin-standalone.report-title'),
      description: null,
      charts: this.charts,
      date_range: null,
      filters: {},
    };
  }

  get charts(): any[] {
    return [
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_standalone_involvement',
        title: this.intl.t('reporting.fin-standalone.involvement.title'),
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.FULL,
        height_type: HEIGHT_TYPES.TALL,
        chart_series: [
          {
            metric_id: 'fin.conversations_participated.count',
          },
        ],
        view_by: 'time',
        visualization_options: {
          show_time_comparison: true,
        },
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_standalone_deflection_rate',
        title: this.intl.t('reporting.fin-standalone.deflection_rate.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.X_SMALL,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        chart_series: [
          {
            metric_id: 'fin.deflection_rate',
          },
        ],
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_standalone_answer_rate',
        title: this.intl.t('reporting.fin-standalone.answer_rate.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.X_SMALL,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        chart_series: [
          {
            metric_id: 'fin.answer_rate',
          },
        ],
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_standalone_resolution_rate',
        title: this.intl.t('reporting.fin-standalone.resolution_rate.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.X_SMALL,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        chart_series: [
          {
            metric_id: 'fin.resolution_rate',
          },
        ],
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_standalone_impact_over_time',
        title: this.intl.t('reporting.fin-standalone.impact-over-time.title'),
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.FULL,
        height_type: HEIGHT_TYPES.TALL,
        chart_series: [
          {
            metric_id: 'fin.answer_rate',
            color: PALETTE['vis-slateblue-40'],
          },
          {
            metric_id: 'fin.deflection_rate',
            color: PALETTE['vis-salmon-40'],
          },
          {
            metric_id: 'fin.resolution_rate',
            color: PALETTE['vis-mint-40'],
          },
        ],
        view_by: 'time',
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_standalone_resolution_state',
        title: this.intl.t('reporting.fin-standalone.resolution_state.title'),
        visualization_type: VISUALIZATION_TYPES.DONUT,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.TALL,
        chart_series: [
          {
            metric_id: 'fin.conversations_participated.count',
          },
        ],
        view_by: 'conversation_custom_fields#ai_chatbot_resolution_state',
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_standalone_ai_answer_resolution_rate',
        title: this.intl.t('reporting.fin-standalone.ai_answer_resolution_rate.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.X_SMALL,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        chart_series: [
          {
            metric_id: 'fin.ai_answer.resolution_rate',
          },
        ],
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_standalone_custom_answer_resolution_rate',
        title: this.intl.t('reporting.fin-standalone.custom_answer_resolution_rate.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.X_SMALL,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        chart_series: [
          {
            metric_id: 'fin.custom_answer.resolution_rate',
          },
        ],
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_standalone_abandonment_rate',
        title: this.intl.t('reporting.fin-standalone.abandonment_rate.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.SMALL,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        chart_series: [
          {
            metric_id: 'fin.abandonment_rate',
          },
        ],
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_standalone_routed_to_team_rate',
        title: this.intl.t('reporting.fin-standalone.routed_to_team_rate.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.SMALL,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        chart_series: [
          {
            metric_id: 'fin.routed_to_team_rate',
          },
        ],
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Reports::Fin::FinStandaloneReport': typeof FinStandaloneReport;
    'reporting/reports/fin/fin-standalone-report': typeof FinStandaloneReport;
  }
}
