/* import __COLOCATED_TEMPLATE__ from './connectors.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class SeriesNodeConnectorsComponent extends Component {
  @service seriesEditorService;
  @tracked showConnectors = false;
  @tracked isShifted = false;
  connectorsAreHovered = false;

  lastLayoutForGraphUpdateTime = undefined;
  numberOfCalculationsForCurrentLayout = 0;

  get isHovered() {
    return this.showConnectors && this.seriesEditorService.isEditMode;
  }

  get rightwardEdges() {
    return this.args.node.outwardEdges.filter(
      (edge) => edge.uiProperties.startAlignment === 'right',
    );
  }

  get isVisible() {
    return isPresent(this.rightwardEdges);
  }

  get isInLoopedShift() {
    return this.numberOfCalculationsForCurrentLayout >= 2;
  }

  @action onEdgesChanged() {
    if (this.lastLayoutForGraphUpdateTime !== this.seriesEditorService.lastGraphUpdateTime) {
      this.numberOfCalculationsForCurrentLayout = 0;
    }

    if (this.isInLoopedShift) {
      return;
    }

    if (
      this.seriesEditorService.graph.state.draggingEdge ||
      this.seriesEditorService.graph.state.draggingNode
    ) {
      return;
    }

    if (this.isVisible && this.rightwardEdges.every((edge) => edge.dataObject.isAlternative)) {
      this._setShiftedValue(true);
    } else {
      this._setShiftedValue(false);
    }
  }

  _setShiftedValue(value) {
    if (this.isShifted !== value) {
      this.lastLayoutForGraphUpdateTime = this.seriesEditorService.lastGraphUpdateTime;
      this.numberOfCalculationsForCurrentLayout += 1;
      this.isShifted = value;
    }
  }

  @action onMouseEnter() {
    this.connectorsAreHovered = true;
    this.toggleVisibility();
  }

  @action onMouseLeave() {
    this.connectorsAreHovered = false;
    this.toggleVisibility();
  }

  @action toggleVisibility() {
    if (this.args.node.isHovered || this.connectorsAreHovered) {
      this.showConnectors = true;
      this.unsetHoveredState.cancelAll();
    } else {
      this.unsetHoveredState.perform();
    }
  }

  @task({ restartable: true })
  *unsetHoveredState() {
    yield timeout(150);
    this.showConnectors = false;
  }
}
