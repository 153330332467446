/* import __COLOCATED_TEMPLATE__ from './tag-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { map } from '@ember/object/computed';
import { isNone } from '@ember/utils';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  intercomEventService: service(),
  noFilterDescriptor: 'All tags',
  tagName: 'span',
  groupList: computed('rawTags', 'selected', function () {
    let selectedTag = this.selected;
    return [
      {
        items: [
          {
            text: this.noFilterDescriptor,
            value: null,
            isSelected: isNone(selectedTag),
          },
        ],
      },
      {
        heading: 'Filter by tag',
        items: this.rawTags.map((tag) => ({
          text: tag.get('name'),
          value: tag,
          isSelected: tag === selectedTag,
          icon: 'tag',
        })),
      },
    ];
  }),
  tags: map('rawTags', function (tag) {
    return this.buildItem(tag);
  }),
  rawTags: notImplemented('reporting/tag-filter: You must override the `tags` property'),
  selected: notImplemented('reporting/tag-filter: You must override the `selected` property'),
  setSelected: notImplemented('reporting/tag-filter: You must override the `setSelected` property'),

  actions: {
    setSelectedItem(tag) {
      this.intercomEventService.trackEvent('filtered-report-by-tag', {
        action: 'filtered',
        object: this.analyticsObject,
        place: 'reporting',
        context: 'by_tag',
        owner: 'respond',
      });

      this.setSelected(tag);
    },
  },
});
