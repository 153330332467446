/* import __COLOCATED_TEMPLATE__ from './conversation-attachments-messenger-preview.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import type AttachmentSettings from 'embercom/models/attachment-settings';
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  attachmentSettings: AttachmentSettings;
}

interface Signature {
  Args: Args;
}

const ConversationAttachmentsMessengerPreview = templateOnlyComponent<Signature>();
export default ConversationAttachmentsMessengerPreview;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Security::ConversationAttachmentsMessengerPreview': typeof ConversationAttachmentsMessengerPreview;
    'settings/security/conversation-attachments-messenger-preview': typeof ConversationAttachmentsMessengerPreview;
  }
}
