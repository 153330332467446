/* import __COLOCATED_TEMPLATE__ from './tag-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class TagFilter extends Component {
  @tracked selectedTagIds = this.args.filterHash.tagIds || [];
  @service appService;
  @service tagService;
  @service notificationsService;
  @service intercomEventService;
  @service intl;

  get hasMessageTags() {
    return this.args.messageTags.length > 0;
  }

  get tags() {
    if (!this.hasMessageTags) {
      return [
        {
          text: this.intl.t(
            'components.reporting.conversational-support-funnel.proactive-support-usage.tag-filter.no-tags',
          ),
          value: 'name',
          component: 'outbound/filters/empty-tags-component',
          isDisabled: true,
        },
      ];
    }

    return this.args.messageTags
      .map((messageTag) => {
        let isSelected = this.selectedTagIds.includes(messageTag.id);
        return { text: messageTag.name, value: messageTag.id, isSelected };
      })
      .sortBy('text')
      .reverse()
      .sortBy('isSelected')
      .reverse();
  }

  get filterName() {
    if (this.selectedTagIds.length === 0) {
      return this.intl.t(
        'components.reporting.conversational-support-funnel.proactive-support-usage.tag-filter.any-tag',
      );
    }

    let selectedTags = this.args.messageTags.filter((messageTag) => {
      return this.selectedTagIds.includes(messageTag.id);
    });

    let firstName = selectedTags.firstObject.name;
    if (this.selectedTagIds.length === 1) {
      return `${firstName}`;
    } else {
      return this.intl.t(
        'components.reporting.conversational-support-funnel.proactive-support-usage.tag-filter.selected-count',
        { count: this.selectedTagIds.length - 1, firstName },
      );
    }
  }

  @action
  updateMessagesFilter(selectedValues) {
    this.selectedTagIds = selectedValues;
    this.args.updateFilterHash('tagIds', selectedValues);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved',
      object: 'filter',
      context: 'any_tag',
    });
  }
}
