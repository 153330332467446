/* import __COLOCATED_TEMPLATE__ from './color-select-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class ColorSelectComponent extends Component {
  @action
  handleClick() {
    this.args.updateValue?.(this.args.color.slice(1));
  }

  get style() {
    return sanitizeHtml(`background-color: ${this.args.color};`);
  }
}
