/* import __COLOCATED_TEMPLATE__ from './notify-private-slack-channel-overlay.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
// It feels better to disable this lint rule and stay consistent with all the other popovers in rules
/* eslint-disable @intercom/intercom/no-component-inheritance */
import BaseFilterBlockPopover from 'predicates/components/filter-block/base-filter-block-popover';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class PickConversationDataAttributeValueOverlay extends BaseFilterBlockPopover {
  @tracked slackChannel = this.componentAttrs.action.actionData.slack_channel_id;

  @action
  updateAndClose() {
    this.componentAttrs.onUpdate(this.slackChannel);
    this.hideDropdownOverlay();
  }
}
