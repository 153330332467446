/* RESPONSIBLE TEAM: team-help-desk-experience */
import { generateSafeEmoji } from 'embercom/lib/emoji';
import { VISIBILITY_FILTER_TYPE } from './filter-types';
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';

// eslint-disable-next-line @intercom/intercom/no-component-inheritance
export class TeamVisibilityFilter {
  @service intl;
  #allTeammatesValue = 'everyone';

  constructor(appService, owner) {
    setOwner(this, owner);
    this.visibilityFilterType = VISIBILITY_FILTER_TYPE.TEAM;
    this.appService = appService;
  }

  get app() {
    return this.appService.app;
  }

  get sortedTeams() {
    return this.app.teams.sortBy('name');
  }

  filterSavedReplies(savedReplies, visibility) {
    if (visibility === this.#allTeammatesValue) {
      return savedReplies.filterBy('visibility', this.#allTeammatesValue);
    } else {
      return savedReplies.filter(({ visibleToTeamIds }) => {
        if (visibleToTeamIds) {
          visibleToTeamIds = visibleToTeamIds.map((id) => {
            return parseInt(id, 10);
          });
          return visibleToTeamIds.includes(parseInt(visibility, 10));
        }

        return false;
      });
    }
  }

  filterLabel(visibility) {
    if (visibility === this.#allTeammatesValue) {
      return this.intl.t('inbox.manage-macros.filter.teammates.all');
    } else {
      let team = this.sortedTeams.findBy('id', visibility);
      return this.intl.t('inbox.manage-macros.filter.teammates.specific', {
        emoji: team.avatar_emoji,
        teamName: team.name,
      });
    }
  }

  items() {
    return [
      {
        heading: this.intl.t('inbox.manage-macros.filter.teammates.heading'),
        items: [
          {
            text: this.intl.t('inbox.manage-macros.filter.teammates.all-teammates'),
            value: { type: this.visibilityFilterType, value: this.#allTeammatesValue },
            icon: 'multiple-people',
          },
          ...this.sortedTeams.map(({ name, id, avatar_emoji }) => ({
            text: name,
            value: { type: this.visibilityFilterType, value: id },
            component: 'settings/saved-replies/team-select-item',
            componentAttrs: { teamAvatar: generateSafeEmoji(avatar_emoji) },
          })),
        ],
      },
    ];
  }
}
