/* import __COLOCATED_TEMPLATE__ from './duplicate-ruleset-into-series-modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, taskGroup } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import {
  humanReadableObjectNames,
  objectInterfaceIcons,
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { post } from 'embercom/lib/ajax';
import { PAGE_SIZE } from 'embercom/models/data/outbound/constants';
import ENV from 'embercom/config/environment';
import { timeout } from 'ember-concurrency';

export default class DuplicateRulesetIntoSeriesModal extends Component {
  @service store;
  @service appService;
  @service outboundHomeService;
  @service seriesEditorService;
  @service notificationsService;

  @tracked contentObjectItems = [];
  @tracked selectedContentObject;
  @tracked searchString;
  @tracked currentPage;
  @tracked totalPages;
  @tracked lastPageHit;

  @taskGroup({ restartable: true }) contentSearchTasks;

  get app() {
    return this.appService.app;
  }

  get series() {
    return this.seriesEditorService.series;
  }

  get oneLetterSearch() {
    return this.searchString?.length === 1;
  }

  @task({ group: 'contentSearchTasks' })
  *fetchContentObjects() {
    yield timeout(ENV.APP._250MS);

    let contentWrappers = yield this.contentSearch({ title: this.searchString });
    this.contentObjectItems = contentWrappers.map((contentWrapper) => {
      return this._createContentObjectItem(contentWrapper);
    });
  }

  @task({ group: 'contentSearchTasks' })
  *fetchMoreContentObjects() {
    if (this.lastPageHit) {
      return;
    }

    let contentWrappers = yield this.contentSearch({
      title: this.searchString,
      pageFrom: this.currentPage + 1,
    });

    contentWrappers.map((contentWrapper) => {
      this.contentObjectItems.pushObject(this._createContentObjectItem(contentWrapper));
    });
  }

  async contentSearch(options) {
    let response = await this.outboundHomeService.contentSearch({
      title: options.title || null,
      object_types: [
        objectTypes.banner,
        objectTypes.carousel,
        objectTypes.chat,
        objectTypes.checklist,
        objectTypes.customBot,
        objectTypes.email,
        objectTypes.legacyMessageEmail,
        objectTypes.post,
        objectTypes.push,
        objectTypes.sms,
        objectTypes.survey,
        objectTypes.tour,
      ],
      match_behaviors: [matchBehaviors.static, matchBehaviors.single, matchBehaviors.multi],
      app_id: this.app.id,
      per_page: PAGE_SIZE,
      page_from: options.pageFrom || 0,
      statistics: [],
    });

    this.currentPage = response.pageFrom;
    this.totalPages = response.totalPages;
    this.lastPageHit = response.lastPageHit;

    return response.contentWrappers.filter((contentWrapper) => {
      return Number(contentWrapper.relations?.series?.series_id) !== Number(this.series.id);
    });
  }

  @action
  handleScrollEvent(event) {
    if (event.target.scrollTop >= event.target.scrollHeight - event.target.offsetHeight) {
      this.fetchMoreContentObjects.perform();
    }
  }

  @action
  async copy() {
    let bounds = this.seriesEditorService.graph.state.viewportBounds;

    let params = {
      app_id: this.app.id,
      series_id: this.series.id,
      node_x_position: bounds.left + 100,
      node_y_position: bounds.top + 100,
    };

    try {
      let response;
      if (this.selectedContentObject.contentType === objectTypes.legacyMessageEmail) {
        response = await this._duplicateLegacyEmail({
          ...params,
          message_id: this.selectedContentObject.value,
        });
      } else {
        response = await this._duplicateRuleset({
          ...params,
          ruleset_id: this.selectedContentObject.value,
        });
      }

      this.seriesEditorService.graph.trackActions(() => {
        let graphNodes = this._createGraphNodes(response.nodes);
        let conditionNode = this._findConditionNode(graphNodes);
        if (conditionNode) {
          this._createGraphEdge(conditionNode, this._findContentNode(graphNodes));
        }
        if (this.args.onClose) {
          this.args.onClose();
        }
      });
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyResponseError(error, {
        default: 'We encoutered an error while copying this message',
      });
    }
  }

  _duplicateRuleset(params) {
    return post('/ember/series/series/import_ruleset', params);
  }

  _duplicateLegacyEmail(params) {
    return post('/ember/series/series/import_legacy_email', params);
  }

  _createGraphNodes(nodes) {
    return nodes.map((node) => {
      let newNode = this.store.push(this.store.normalize('series/node', node));
      this.series.nodes.pushObject(newNode);
      return this.seriesEditorService.addNode(newNode);
    });
  }

  _createGraphEdge(predecessor, successor) {
    this.seriesEditorService.createGraphEdge(predecessor, successor);
  }

  _findConditionNode(nodes) {
    return nodes.filter((node) => node.dataObject.objectTypes.firstObject === objectTypes.condition)
      .firstObject;
  }

  _findContentNode(nodes) {
    return nodes.filter((node) => node.dataObject.objectTypes.firstObject !== objectTypes.condition)
      .firstObject;
  }

  _createContentObjectItem(contentWrapper) {
    let contentType = contentWrapper.contents.firstObject.contentType;

    return {
      text: contentWrapper.title || `Untitled ${humanReadableObjectNames[contentType]}`,
      value: contentWrapper.contentWrapperId,
      contentType,
      icon: objectInterfaceIcons[contentType],
    };
  }
}
