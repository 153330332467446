/* import __COLOCATED_TEMPLATE__ from './translation-settings.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { isPresent } from '@ember/utils';

export default class TranslationSettings extends Component {
  @service store;
  @service intercomEventService;
  @service notificationsService;
  @service router;
  @tracked openSectionId = '';

  @tracked translationSettings;

  constructor() {
    super(...arguments);
    this.loadTranslationSettings.perform();

    this.expandBasedOnQueryParams();
  }

  expandBasedOnQueryParams() {
    if (this.router.currentRoute && this.router.currentRoute.queryParams['expandLocalize']) {
      this.openSectionId = 'Locale';
    }
  }

  get addLocaleDropdownItems() {
    return this.translationSettings?.possibleLocales
      ?.filter((locale) => {
        return !this.translationSettings.translationLocaleIds.includes(locale.localeId);
      })
      .map((locale) => {
        return {
          value: locale.localeId,
          text: locale.name,
        };
      });
  }

  get tableItems() {
    return this.translationSettings?.translationLocaleIds?.map((localeId) => {
      return {
        id: localeId,
        name: this.translationSettings.possibleLocales.findBy('localeId', localeId).name,
        isDefault: localeId === this.translationSettings.defaultTranslationLocaleId,
      };
    });
  }

  _isValidDefaultLocale(localeId) {
    let subscriptionTypes = this.store.peekAll('outbound-subscriptions/subscription-type');
    return subscriptionTypes
      .filter((t) => t.isLive)
      .every((type) => {
        let translation = type.customTranslations.find((t) => t.locale === localeId);
        return isPresent(translation?.name) && isPresent(translation?.description);
      });
  }

  @action
  async addLocale(localeId) {
    this.translationSettings.translationLocaleIds.pushObject(localeId);
    await this.translationSettings.save();
    this.store.findAll('outbound-subscriptions/subscription-type');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'locale',
      locale_id: localeId,
    });
  }

  @action
  makeDefaultLocale(localeId) {
    if (this._isValidDefaultLocale(localeId)) {
      this.translationSettings.defaultTranslationLocaleId = localeId;
      this.translationSettings.save();
    } else {
      this.notificationsService.notifyError(
        'You must have a valid translation for each live subscription type for the language you wish to make the default.',
      );
    }
  }

  @action
  async deleteLocale(localeId) {
    this.translationSettings.translationLocaleIds =
      this.translationSettings.translationLocaleIds.filter(
        (translationLocaleId) => translationLocaleId !== localeId,
      );
    await this.translationSettings.save();
    this.store.findAll('outbound-subscriptions/subscription-type');
  }

  @task *loadTranslationSettings() {
    let settings = yield this.store.findAll('outbound-subscriptions/translation-settings');
    this.translationSettings = settings.firstObject;
  }
}
