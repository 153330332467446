/* import __COLOCATED_TEMPLATE__ from './suggested-metric-card.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';

const SHARE_FEEDBACK_SURVEY_ID = 29754530;

export default class SuggesetedMetricCard extends Component {
  @action
  launchSurvey(event) {
    event.preventDefault();
    startSurvey(SHARE_FEEDBACK_SURVEY_ID);
  }
}
