/* import __COLOCATED_TEMPLATE__ from './add-conversation-topic.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';

import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type ConversationTopicModel from 'embercom/models/conversational-insights/conversation-topic';

interface Args {
  action: {
    isInvalid?: boolean;
    justAdded?: boolean;
    actionData?: Record<string, any>;
  };
  forceInvalid?: boolean;
  topics: ConversationTopicModel[];
  onPopoverHide: () => void;
  onDelete: () => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [{ label: string | undefined }];
  };
}

export default class AddConversationTopic extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @tracked topics: ConversationTopicModel[] = [];

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.loadAllTopics).perform();
  }

  @task
  *loadAllTopics() {
    this.topics = yield this.store.findAll('conversational-insights/conversation-topic');
  }

  get label() {
    let topicId = this.args.action.actionData?.topic_id;
    if (!topicId) {
      return this.intl.t('inbox.composer.macro-actions.add-conversation-topic.missing-topic');
    }

    return this.topics.findBy('id', topicId.toString())?.name;
  }

  get isInvalid() {
    if (this.args.forceInvalid) {
      return true;
    }

    return this.args.action.isInvalid;
  }

  @action onSelectTopic(data: Record<string, any>) {
    this.args.action.actionData = { topic_id: data.id };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Rules::Actions::AddConversationTopic': typeof AddConversationTopic;
    'inbox2/rules/actions/add-conversation-topic': typeof AddConversationTopic;
  }
}
