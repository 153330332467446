/* import __COLOCATED_TEMPLATE__ from './changelog.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { get } from 'embercom/lib/ajax';

const PER_PAGE = 25;

export default class SeriesChangelogComponent extends Component {
  @service appService;
  @service seriesEditorService;
  @service store;
  @tracked changelogs = A([]);
  @tracked page = 1;
  @tracked lastPageHit = true;

  get series() {
    return this.seriesEditorService.series;
  }

  @task
  *loadChangelogs() {
    let response = yield get('/ember/series/changelogs', {
      app_id: this.appService.app.id,
      series_id: this.series.id,
      page_from: this.page,
      per_page: PER_PAGE,
    });

    this.store.pushPayload('series/changelog', { 'series/changelog': response.changelogs });
    response.changelogs.map((changelog) => {
      this.changelogs.addObject(this.store.peekRecord('series/changelog', changelog.id));
    });
    this.lastPageHit = response.last_page_hit;
  }

  @task
  *loadNextPage() {
    this.page += 1;
    yield this.loadChangelogs.perform();
  }

  @action
  onClose() {
    this.changelogs = [];
    this.page = 1;
    this.seriesEditorService.isShowingChangelog = false;
  }
}
