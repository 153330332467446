/* import __COLOCATED_TEMPLATE__ from './survey-rating-star-bar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResource from 'embercom/lib/reporting/chart-data-resource';
import { SORTING_LABEL_NUMERIC } from 'embercom/lib/reporting/flexible/constants';
import { inject as service } from '@ember/service';
import { populateEmptyRatingsWithZero } from 'embercom/lib/reporting/survey-helpers';

const AVAILABLE_STAR_RATINGS = ['1', '2', '3', '4', '5'];

export default class SurveyRatingStarBar extends Component {
  @service store;
  @service intl;

  dataResource = useResource(this, ChartDataResource, () => ({
    renderableChart: this.args.renderableChart,
  }));

  get viewConfig() {
    return {
      ...this.dataResource.viewConfig,
      grouping: {
        tooltipTransformation: (data) => this._formatTooltip(data),
        dataTransformation: (dataResponses) =>
          populateEmptyRatingsWithZero(AVAILABLE_STAR_RATINGS, dataResponses),
      },
    };
  }

  get dataConfig() {
    let config = this.dataResource.dataConfig;
    config.xAxis.data.sorting = SORTING_LABEL_NUMERIC;
    return config;
  }

  _formatTooltip(data) {
    let numberOfStars = parseInt(data.name, 10);
    return this.intl.t('reporting.surveys.stars', { numberOfStars });
  }
}
