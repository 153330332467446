/* import __COLOCATED_TEMPLATE__ from './ticket-state-details-editor.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { isPresent } from '@ember/utils';
import { taskFor } from 'ember-concurrency-ts';
import type InboxApi from 'embercom/services/inbox-api';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { type TaskInstance } from 'ember-resources/util/ember-concurrency';
import { type ResponseError } from 'embercom/lib/inbox/requests';
import type Session from 'embercom/services/session';

interface TicketCustomStatesEditorArgs {
  isOpen: boolean;
  systemState: TicketSystemState;
  customState?: TicketCustomState;
  onClose: () => void;
  onCreate?: (id: number) => void;
  onUpdate?: (id: number) => void;
  onArchiveTicketCustomStateModal?: () => void;
}

export default class TicketCustomStatesEditor extends Component<TicketCustomStatesEditorArgs> {
  @service declare intl: IntlService;
  @service declare inboxApi: InboxApi;
  @service declare ticketStateService: TicketStateService;
  @service declare notificationsService: any;
  @service declare session: Session;

  @trackedReset('args.systemState') adminLabel: string = this.args.customState?.adminLabel ?? '';
  @trackedReset('args.systemState') userLabel: string = this.args.customState?.userLabel ?? '';
  @trackedReset('args.customState') editMode = isPresent(this.args.customState);
  @trackedReset('args.customState') confirmationDialogOpen = false;
  @tracked isArchiveModalOpen = false;

  get systemState() {
    return isPresent(this.args.systemState)
      ? this.args.systemState
      : this.args.customState?.systemState;
  }

  get iconDetails() {
    return this.ticketStateService.getIconForSystemState(
      this.systemState || TicketSystemState.Submitted,
    );
  }

  get canArchive() {
    return this.editMode && !this.args.customState?.archived;
  }

  get isSaveActive() {
    return this.isAdminLabelUnique && this.areLabelsChanged;
  }

  get isAdminLabelChanged() {
    if (!this.editMode) {
      return this.adminLabel.length > 0;
    }

    return this.adminLabel.length > 0 && this.adminLabel !== this.args.customState?.adminLabel;
  }

  get showEmptyState() {
    return this.editMode && !this.hasDependentTicketTypes;
  }

  get ticketStateDescription() {
    if (this.args.systemState === TicketSystemState.Submitted) {
      return this.intl.t(
        `settings.ticket-states.ticket-state-editor.description.multiple_submitted`,
      );
    }

    return this.intl.t(
      `settings.ticket-states.ticket-state-editor.description.${this.args.systemState}`,
    );
  }

  get areLabelsChanged() {
    if (!this.editMode) {
      return this.adminLabel.length > 0 && this.userLabel.length > 0;
    }

    return (
      this.adminLabel.length > 0 &&
      this.userLabel.length > 0 &&
      (this.adminLabel !== this.args.customState?.adminLabel ||
        this.userLabel !== this.args.customState?.userLabel)
    );
  }

  get isAdminLabelUnique() {
    if (!this.isAdminLabelChanged) {
      return true;
    }

    if (this.editMode) {
      return !this.ticketStateService.customStates.some(
        (state) =>
          state.adminLabel.localeCompare(this.adminLabel, undefined, { sensitivity: 'accent' }) ===
            0 && state.id !== this.args.customState?.id,
      );
    } else {
      return !this.ticketStateService.customStates.some(
        (state) =>
          state.adminLabel.localeCompare(this.adminLabel, undefined, { sensitivity: 'accent' }) ===
          0,
      );
    }
  }

  get dependentTicketTypes() {
    if (typeof this.args.customState !== 'undefined') {
      return this.ticketStateService
        .getTicketTypesForCustomState(this.args.customState)
        .filter((type) => !type.archived);
    } else {
      return [];
    }
  }

  get hasDependentTicketTypes() {
    return this.dependentTicketTypes.length !== 0 && this.editMode;
  }

  initialState() {
    this.adminLabel = '';
    this.userLabel = '';
  }

  humanReadableSystemState(systemState: TicketSystemState) {
    return this.intl.t(
      `settings.ticket-data.ticket-type-configuration.system-states.${systemState}`,
    );
  }

  @action
  closeEditor() {
    this.initialState();
    this.args.onClose();
  }

  @action
  hideConfirmationModal() {
    this.confirmationDialogOpen = false;
  }

  @action
  save(_event: any, confirmed = false): TaskInstance<void> | undefined {
    if (this.editMode) {
      if (confirmed || this.dependentTicketTypes.length === 0) {
        return taskFor(this.updateState).perform();
      } else {
        this.confirmationDialogOpen = true;
        return;
      }
    } else {
      return taskFor(this.createState).perform();
    }
  }

  @task({ drop: true })
  *createState() {
    if (!this.isSaveActive) {
      return;
    }

    try {
      let savedTicketState: TicketCustomState =
        yield this.ticketStateService.createTicketCustomState(
          this.systemState as TicketSystemState,
          this.adminLabel,
          this.userLabel,
        );
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.ticket-states.ticket-state-editor.successfully-created', {
          ticketStateName: this.humanReadableSystemState(savedTicketState.systemState),
        }),
      );
      this.args.onCreate?.(savedTicketState.id);
      this.closeEditor();
    } catch (error) {
      yield this.handleErrorResponse(error);
    }
  }

  @task({ drop: true })
  *updateState() {
    if (!this.isSaveActive) {
      return;
    }

    try {
      if (this.args.customState) {
        let savedTicketState: TicketCustomState =
          yield this.ticketStateService.updateTicketCustomState(
            this.args.customState.id,
            this.adminLabel.trim(),
            this.userLabel.trim(),
          );
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.ticket-states.ticket-state-editor.successfully-updated', {
            ticketStateName: this.humanReadableSystemState(savedTicketState.systemState),
          }),
        );
        this.args.onUpdate?.(savedTicketState.id);
        this.closeEditor();
      }
    } catch (error) {
      yield this.handleErrorResponse(error);
    }
  }

  async handleErrorResponse(error: ResponseError) {
    if (error?.response && error.response.status === 400) {
      let response = await error.response.json();
      this.notificationsService.notifyError(response.errors);
    } else {
      this.notificationsService.notifyResponseError(error);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketStates::TicketStateDetailsEditor': typeof TicketCustomStatesEditor;
    'settings/ticket-states/ticket-state-details-editor': typeof TicketCustomStatesEditor;
  }
}
