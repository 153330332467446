/* import __COLOCATED_TEMPLATE__ from './convert-inbound-emails-to-tickets.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

export default class ConvertEmailsToTickets extends Component {
  @service store;
  @service notificationsService;
  @service intl;
  @tracked ticketTypes;
  @service appService;

  constructor() {
    super(...arguments);
    this.fetchTicketTypes.perform();
  }
  get selectedTicketType() {
    if (!this.ticketTypes) {
      return null;
    }

    let selectedTicketTypeById = this.ticketTypes.find(
      (item) => item.id === `${this.args.settings.ticketTypeId}`,
    );

    if (selectedTicketTypeById) {
      return selectedTicketTypeById;
    }

    let defaultTicketType = this.ticketTypes.find((ticketType) =>
      ticketType.userVisibleDescriptors.every(
        ({ requiredToCreate, requiredToCreateForUsers }) =>
          !requiredToCreate && !requiredToCreateForUsers,
      ),
    );

    return defaultTicketType || null;
  }

  @task({ drop: true })
  *fetchTicketTypes() {
    let fetchedTicketTypes = yield this.store.findAll('inbox/ticket-type');
    fetchedTicketTypes = fetchedTicketTypes.filter(
      ({ archived, category }) => !archived && category === TicketCategory.Request,
    );
    this.ticketTypes = fetchedTicketTypes;
  }

  get selectListItems() {
    if (!this.ticketTypes) {
      return [];
    }

    return this.ticketTypes.map((type) => {
      let isDisabled = type.userVisibleDescriptors.any(
        (obj) => obj.requiredToCreate || obj.requiredToCreateForUsers,
      );

      return {
        text: `${type.emoji} ${type.name}`,
        value: type.id,
        isDisabled,
        component: 'workflows/graph-editor/node-items/steps/convert-to-ticket-item',
      };
    });
  }

  get hasError() {
    return this.fetchTicketTypes.isRunning && (!this.ticketTypes || this.ticketTypes.length === 0);
  }

  @task({ drop: true })
  *updateSetting() {
    try {
      yield this.args.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (error) {
      this.args.settings.toggleProperty('allowAutoConversion');
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  toggleAutoConversion() {
    let allowAutoConversation = this.args.settings.toggleProperty('allowAutoConversion');
    if (allowAutoConversation && this.selectedTicketType) {
      this.args.settings.ticketTypeId = this.selectedTicketType.id;
    } else {
      this.args.settings.ticketTypeId = '';
    }
    this.updateSetting.perform();
  }

  @action
  onSelectedTicketType(selectedTicketTypeId) {
    this.args.settings.ticketTypeId = selectedTicketTypeId;
    if (this.args.settings.allowAutoConversion) {
      this.updateSetting.perform();
    }
  }
}
