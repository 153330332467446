/* import __COLOCATED_TEMPLATE__ from './permanent-user-deletion.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/no-private-routing-service */
import { none, empty } from '@ember/object/computed';
import EmberObject, { get } from '@ember/object';
import { inject as service } from '@ember/service';
import BaseContentComponent from 'embercom/components/settings/content-components/base';
import { isValidEmail } from 'embercom/lib/email';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';
import fullRelativeTimeAgo from 'embercom/lib/full-relative-time-ago';
import { getOwner } from '@ember/application';

export default BaseContentComponent.extend({
  notificationsService: service(),
  router: service(),
  intl: service(),

  _sendAjaxRequest(endpoint, params, type) {
    return ajax({
      url: `/ember/user_deletion/${endpoint}`,
      data: JSON.stringify({
        app_id: this.get('app.id'),
        ...params,
      }),
      type,
    });
  },

  _formatCompanies: (companies) => ({
    displayCompany: companies[0],
    additionalCompanies: companies.slice(1, companies.length).map((company) => company),
  }),

  _createUserRows(users) {
    return users.map((user) => ({
      avatarUser: EmberObject.create({
        name: user.display_as,
        archived: user.archived,
        companies: user.companies.length ? this._formatCompanies(user.companies) : null,
        id: user.id,
        avatarData: {
          color: get(user, 'avatar_data.color'),
          url: get(user, 'avatar_data.image_urls.normal'),
          initials: get(user, 'avatar_data.initials'),
          isCustomer: true,
        },
      }),
      type:
        user.type === 'user_role'
          ? this.intl.t('settings.permanent-user-deletion.user')
          : this.intl.t('settings.permanent-user-deletion.lead'),
      conversationCount: this.intl.t('settings.permanent-user-deletion.conversation-count', {
        conversationCount: user.conversation_count,
      }),
      lastRequestAt: user.last_request_at ? fullRelativeTimeAgo(user.last_request_at) : '-',
      status: user.archived
        ? this.intl.t('settings.permanent-user-deletion.archived')
        : this.intl.t('settings.permanent-user-deletion.active'),
      id: user.id,
    }));
  },

  tableRows: null,
  tableColumns: [],
  showModal: false,

  noSearchResults: none('tableRows'),
  emptySearchResults: empty('tableRows'),

  searchUsers: task(function* () {
    if (!this.searchValue.length) {
      return;
    }
    let params = { [isValidEmail(this.searchValue) ? 'email' : 'user_id']: this.searchValue };
    try {
      let { users } = yield this._sendAjaxRequest('show', params, 'POST');
      this.set('tableRows', this._createUserRows(users));
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('settings.permanent-user-deletion.could-not-find'),
      );
    }
  }).drop(),

  requestDeletion: task(function* () {
    let params = { id: this.selectedUserId };
    try {
      yield this._sendAjaxRequest('delete', params, 'POST');
      this.setProperties({
        tableRows: null,
        showModal: false,
        searchValue: '',
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.permanent-user-deletion.deleted'),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('settings.permanent-user-deletion.could-not-delete'),
      );
    }
  }).drop(),

  actions: {
    showDeleteModal(userId) {
      this.set('selectedUserId', userId);
      this.set('showModal', true);
    },
  },

  init() {
    this._super(...arguments);
    let router = getOwner(this).lookup('router:main');
    let searchValue = router.currentState?.routerJsState?.fullQueryParams?.searchValue || '';
    this.set('searchValue', searchValue);
    this.searchUsers.perform();
    this.set('tableColumns', [
      {
        label: this.intl.t('settings.permanent-user-deletion.name'),
        canExpandToFit: true,
        valueComponent: 'table-cells/gdpr-settings/avatar-name-company',
        propertyName: 'avatarUser',
      },
      {
        label: this.intl.t('settings.permanent-user-deletion.type'),
        canExpandToFit: true,
        propertyName: 'type',
      },
      {
        label: this.intl.t('settings.permanent-user-deletion.conversation-history'),
        canExpandToFit: true,
        propertyName: 'conversationCount',
      },
      {
        label: this.intl.t('settings.permanent-user-deletion.last-seen'),
        canExpandToFit: true,
        propertyName: 'lastRequestAt',
      },
      {
        label: this.intl.t('settings.permanent-user-deletion.status'),
        canExpandToFit: true,
        propertyName: 'status',
      },
      {
        canExpandToFit: true,
        component: 'ic-legacy-table-cell-button',
        buttonProperties: {
          type: 'secondary-destructive',
          label: this.intl.t('settings.permanent-user-deletion.label-permanent-delete'),
        },
        tableActionName: 'showDeleteModal',
        propertyName: 'id',
      },
    ]);
  },
});
