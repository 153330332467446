/* import __COLOCATED_TEMPLATE__ from './installation-guide.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { post } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';

const THANK_YOU_MESSAGE_MAX_FILE_SIZE = 5000000;
const THANK_YOU_MESSAGE_ACCEPTED_MIME_TYPE = 'audio/mpeg';
export default class SwitchInstallationGuide extends Component {
  @service store;
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @service regionService;
  @service intl;

  @tracked settings;
  @tracked installationGuideSectionId = 'installation-guide-call-forwarding';
  @tracked callForwardingSectionId = 'request-phone-number';
  @tracked apiSectionId = 'api-request';

  constructor() {
    super(...arguments);
    this.settings = this.args.settings;
  }

  get paywallActiveOverride() {
    return !this.appService.app?.canUseFeature('switch');
  }

  get apiEndpoint() {
    return this.regionService.publicApiBaseURL.split('//').pop();
  }

  get storedFileName() {
    if (this.settings.thankYouMessageUrl) {
      return this.settings.thankYouMessageUrl.split('/').pop();
    }
    return 'say_success.mp3 (Default)';
  }

  get hasCustomThankYouMessage() {
    return !!this.settings.thankYouMessageUrl;
  }

  get thankYouMessageMaxFileSize() {
    return THANK_YOU_MESSAGE_MAX_FILE_SIZE;
  }

  get thankYouMessageAcceptedMimeType() {
    return THANK_YOU_MESSAGE_ACCEPTED_MIME_TYPE;
  }

  @task({ restartable: true })
  *requestNumberTask() {
    try {
      let data = {
        app_id: this.appService.app.id,
      };
      let response = yield post('/ember/switch/settings/request_phone_number', data);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'requested',
        object: 'phone_number',
        place: 'switch',
      });
      this.store.pushPayload({ 'switch/settings': response });
      yield this.settings.reload();
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('components.settings.switch.setup.error-phone-number'),
      });
    }
  }

  @task({ restartable: true })
  *requestApiTokenTask() {
    try {
      let data = {
        app_id: this.appService.app.id,
      };
      let response = yield post('/ember/switch/settings/request_api_token', data);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'requested',
        object: 'api_token',
        place: 'switch',
      });
      this.store.pushPayload({ 'switch/settings': response });
      yield this.settings.reload();
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('components.settings.switch.setup.error-api-token'),
      });
    }
  }

  @task({ restartable: true })
  *updateThankYouMessageTask(file) {
    let uploader = new EmbercomFileUploader(file, {
      policyUrl: `/apps/${this.appService.app.id}/uploads`,
    });

    try {
      if (file.type !== this.thankYouMessageAcceptedMimeType) {
        throw new Error(
          this.intl.t(
            'channels.switch.settings.installing_guide.call_forwarding.set_switch_voice_messages.upload_invalid_file_type',
          ),
        );
      }
      let uploadResponse = yield uploader.upload();

      if (uploadResponse.type !== 'success') {
        throw new Error(
          this.intl.t(
            'channels.switch.settings.installing_guide.call_forwarding.set_switch_voice_messages.upload_server_error',
          ),
        );
      }

      let data = {
        app_id: this.appService.app.id,
        thank_you_message_url: uploadResponse.uploadUrl,
      };
      let updateResponse = yield post('/ember/switch/settings/update_thank_you_message', data);
      this.store.pushPayload({ 'switch/settings': updateResponse });
      yield this.settings.reload();

      this.notificationsService.notifyConfirmation(
        this.intl.t('components.settings.switch.setup.changes-saved'),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: e.message,
      });
    }
  }

  @action
  requestPhoneNumber() {
    return this.requestNumberTask.perform();
  }

  @action
  requestApiToken() {
    return this.requestApiTokenTask.perform();
  }

  @action
  updateThankYouMessage(file) {
    return this.updateThankYouMessageTask.perform(file);
  }
}
