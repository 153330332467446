/* import __COLOCATED_TEMPLATE__ from './people-overview-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import {
  PERSON_IDENTIFIED_EVENT_TYPE,
  GROUP_BY_USER_FIRST_CONVERSATION_MESSAGE_TYPE,
} from 'embercom/lib/reporting/events';
import {
  sumBucketsToCategories,
  stackBucketsToPercents,
} from 'embercom/lib/reporting/signal-transformations';
import { NEW_PEOPLE_BY_USER } from 'embercom/lib/reporting/queries';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Component.extend({
  intl: service(),
  breakdownCategories: computed('intl.locale', function () {
    return [
      {
        key: 'started_new_conversation',
        label: this.intl.t('components.reporting.reports.people-overview-breakdown.started-label'),
        color: 'vis-green-40',
        sumKeys: ['UserMessage', 'BotIntroMessage'],
      },
      {
        key: 'replied_to_message_or_bot',
        label: this.intl.t('components.reporting.reports.people-overview-breakdown.replied-label'),
        color: 'vis-green-60',
        sumKeys: [
          'BotAutoMessage',
          'VisitorAutoMessage',
          'AutoMessage',
          'TriggeredMessage',
          'ManualMessage',
          'CampaignMessage',
        ],
      },
      {
        key: 'reacted_to_article',
        label: this.intl.t(
          'components.reporting.reports.people-overview-breakdown.help-center-label',
        ),
        tooltip: this.intl.t(
          'components.reporting.reports.people-overview-breakdown.help-center-tooltip',
        ),
        color: 'vis-green-70',
        sumKeys: ['DisplayableContent'],
      },
      {
        key: 'other',
        label: this.intl.t(
          'components.reporting.reports.people-overview-breakdown.no-interaction-label',
        ),
        tooltip: this.intl.t(
          'components.reporting.reports.people-overview-breakdown.no-interaction-tooltip',
        ),
        color: 'vis-green-80',
        sumKeys: ['other'],
      },
    ];
  }),
  get categoriesColors() {
    return this.breakdownCategories.reduce((hash, cat) => ({ ...hash, [cat.key]: cat.color }), {});
  },
  categories: alias('breakdownCategories'),

  query: {
    ...NEW_PEOPLE_BY_USER,
    keys_are_dates: false,
  },
  filters: { ...PERSON_IDENTIFIED_EVENT_TYPE },
  groupings: [GROUP_BY_USER_FIRST_CONVERSATION_MESSAGE_TYPE],
  get transforms() {
    return [sumBucketsToCategories(this.breakdownCategories), stackBucketsToPercents];
  },
});
