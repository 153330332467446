/* import __COLOCATED_TEMPLATE__ from './data-loader.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class DataLoader extends Component {
  previousValue;

  @action
  loadDataWhenChanged() {
    if (JSON.stringify(this.previousValue) !== JSON.stringify(this.args.dependentValue)) {
      this.previousValue = this.args.dependentValue;
      this.args.loadDataTask.perform();
    }
  }
}
