/* import __COLOCATED_TEMPLATE__ from './participant-type-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
  REPORTING_FILTER_SELECT_ALL,
  PARTICIPANT_TYPES,
  PARTICIPANT_TYPE_VALUES,
} from 'embercom/lib/reporting/flexible/constants';

export default class ParticipantTypeFilter extends Component {
  @service intercomEventService;
  @tracked label = this.currentLabel;
  // store the selection here so that the UI doesn't update until the drop down is closed
  selectedDropdownItems = this.args.selected;

  get selectedParticipantTypes() {
    if (
      this.selectedDropdownItems.includes(REPORTING_FILTER_SELECT_ALL) ||
      this.selectedDropdownItems.length === PARTICIPANT_TYPE_VALUES.length
    ) {
      return [];
    } else {
      return this.selectedDropdownItems;
    }
  }

  get items() {
    return this.groupList;
  }

  get allItems() {
    return PARTICIPANT_TYPES.map(({ value, text }) => ({
      text,
      value,
      isSelected: this.args.selected.includes(value),
    }));
  }

  get groupList() {
    return [
      {
        heading: this.args.skipHeader ? '' : 'Participant types',
        items: this.allItems,
      },
    ];
  }

  get currentLabel() {
    if (this.selectedParticipantTypes.length === 0) {
      return this.args.expandAllOptions ? 'is User, Teammate' : 'is Any';
    }

    let participantTypeNames = this.selectedParticipantTypes.map((participantTypeValue) => {
      return PARTICIPANT_TYPES.find((type) => type.value === participantTypeValue).text;
    });
    return `is ${participantTypeNames.join(', ')}`;
  }

  @action
  onClose() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_participant_type',
      object: this.args.analyticsObject,
      participantTypes: this.selectedParticipantTypes,
    });

    this.args.setSelected(this.selectedParticipantTypes);
    this.label = this.currentLabel;
  }
}
