/* import __COLOCATED_TEMPLATE__ from './share-button.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type ReportAccessService from 'embercom/services/report-access-service';
import type Report from 'embercom/models/reporting/custom/report';
import { isPresent } from '@ember/utils';
import type CustomReportsService from 'embercom/services/custom-reports-service';

interface Args {
  disabled: boolean;
  report: Report;
}

export default class ReportingCustomReportShareButton extends Component<Args> {
  @service declare modalService: $TSFixMe;
  @service declare reportAccessService: ReportAccessService;
  @service declare customReportsService: CustomReportsService;

  get hasWorkspaceAccess(): boolean {
    let accessControlList = this.reportAccessService.localAccessControls;
    return isPresent(accessControlList.find((control) => control.isWorkspaceAccessControl));
  }

  get shareIcon() {
    return this.hasWorkspaceAccess ? 'multiple-people' : 'locked';
  }

  get isLoading() {
    return this.reportAccessService.fetchReportAccess.isRunning;
  }

  set isLoading(_value) {
    // noop
    // without this, clicking button does not open modal
  }

  @action
  handleModalOpen() {
    if (this.customReportsService.canChangeCustomReports) {
      this.customReportsService.openSharingModal(this.args.report, false);
    } else {
      this.customReportsService.loadChangePermissionModal();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ShareButton': typeof ReportingCustomReportShareButton;
    'reporting/custom/report/share-button': typeof ReportingCustomReportShareButton;
  }
}
