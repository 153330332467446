/* import __COLOCATED_TEMPLATE__ from './answer-to-question-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { isEmpty } from '@ember/utils';
import { responseTypes, scaleTypes } from 'embercom/models/data/survey/constants';
import { emojiScale } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import {
  DETRACTORS_VALUES,
  PASSIVES_VALUES,
  PROMOTERS_VALUES,
} from 'embercom/lib/reporting/survey-helpers';
import { isDefaultValue } from 'embercom/lib/reporting/custom/filter-helpers';

const FILTERABLE_QUESTION_TYPES = new Set([
  responseTypes.dropdown,
  responseTypes.ratingScale,
  responseTypes.multiSelect,
]);

const PROMOTERS = 'promoters';
const PASSIVES = 'passives';
const DETRACTORS = 'detractors';

const NPS_VALUE_MAP = {
  [PROMOTERS]: PROMOTERS_VALUES,
  [PASSIVES]: PASSIVES_VALUES,
  [DETRACTORS]: DETRACTORS_VALUES,
};

export default class AnswerToQuestionFilter extends Component {
  @service appService;
  @service store;
  @service intercomEventService;
  @service intl;
  @tracked availableTicketTypes = [];
  @tracked selectedQuestion = this.args.type?.split('#')[1] || REPORTING_FILTER_SELECT_ALL;
  @tracked operator = this.startingOperator;
  @tracked selectedAnswers = this.startingAnswers;
  @tracked answerLabel = this.currentAnswerLabel;

  get startingOperator() {
    return this.args.selected?.operator || 'category';
  }

  get startingAnswers() {
    if (
      this.answerFilterDisabled ||
      isEmpty(this.args.selected?.values) ||
      this.args.selected.values[0] === REPORTING_FILTER_SELECT_ALL
    ) {
      return [];
    }

    if (this._npsQuestionSelected) {
      return this._mapValuesToNPSLabels(this.args.selected?.values);
    } else {
      return this.args.selected?.values || [];
    }
  }

  get answerName() {
    return this.intl.t('reporting.filter-bar.answer-to-question-filter.name', {
      category: this._operatorText,
    });
  }

  get _operatorText() {
    return this.operator === 'category' ? ' is' : ' is not';
  }

  get filterableQuestions() {
    let questions = this._survey.questions
      .map((question, index) => this._questionDropdownEntry(question, index))
      .filter((question) => this._isFilterableQuestion(question));
    return [this._selectAllQuestion, ...questions];
  }

  get _selectAllQuestion() {
    return {
      value: REPORTING_FILTER_SELECT_ALL,
      text: this.intl.t('reporting.filter-bar.select-all-label'),
      type: REPORTING_FILTER_SELECT_ALL,
    };
  }

  get _npsQuestionSelected() {
    return this._currentQuestion.data.type === scaleTypes.nps;
  }

  get _survey() {
    let survey = this.args.customFilterArgs?.survey;
    if (isEmpty(survey)) {
      throw new Error('expected customFilterArg "survey" is missing');
    }
    return survey;
  }

  get _currentQuestion() {
    return this.filterableQuestions.find((question) => question.value === this.selectedQuestion);
  }

  get questionLabel() {
    return this._currentQuestion.text;
  }

  get currentAnswerLabel() {
    if (this.selectedAnswers?.length && !isDefaultValue(this.selectedAnswers[0])) {
      return this._formattedAnswerLabel;
    } else {
      return this.intl.t('reporting.filter-bar.select-all-label');
    }
  }

  get answerFilterDisabled() {
    return isEmpty(this.selectedQuestion) || this.selectedQuestion === REPORTING_FILTER_SELECT_ALL;
  }

  get answers() {
    let question = this._currentQuestion;
    if (isEmpty(question) || question.value === REPORTING_FILTER_SELECT_ALL) {
      return [REPORTING_FILTER_SELECT_ALL];
    }

    switch (question.type) {
      case responseTypes.dropdown:
      case responseTypes.multiSelect:
        return this._generateSelectionDropdown(question.data.options);
      case responseTypes.ratingScale:
        return this._generateScaleDropdown(question);
    }
  }

  get _formattedAnswerLabel() {
    let answerMap = this._answersMap;
    let values = this.selectedAnswers;
    return values
      .map((value) => {
        return answerMap[value];
      })
      .join(', ');
  }

  get _answersMap() {
    let mapping = {};
    this.answers.map((answer) => {
      let key = answer.value;
      mapping[key] = answer.text;
    });
    return mapping;
  }

  get _generateNPSDropdown() {
    return [
      this._generateDropdownEntry(PROMOTERS, this.intl.t('reporting.surveys.promoters')),
      this._generateDropdownEntry(PASSIVES, this.intl.t('reporting.surveys.passives')),
      this._generateDropdownEntry(DETRACTORS, this.intl.t('reporting.surveys.detractors')),
    ];
  }

  get _answersAsFilterableValues() {
    if (this.selectedAnswers[0] === REPORTING_FILTER_SELECT_ALL) {
      return this.selectedAnswers;
    }

    let values = this._npsQuestionSelected
      ? this._mapNPSLabelsToValues(this.selectedAnswers)
      : this.selectedAnswers;
    return values;
  }

  _isFilterableQuestion(question) {
    return (
      FILTERABLE_QUESTION_TYPES.has(question?.type) ||
      question?.value === REPORTING_FILTER_SELECT_ALL
    );
  }

  _questionDropdownEntry(question, index) {
    return {
      value: question.id,
      text: this.intl.t('reporting.filter-bar.question-filter.label', {
        questionIndex: index + 1,
        question: question.questionTitle || this.intl.t('reporting.surveys.empty-question-title'),
      }),
      type: question.questionType,
      data: question.data,
      component: 'reporting/custom/chart-builder/filter-bar/answer-to-question/dropdown-item',
    };
  }

  _generateSelectionDropdown(options) {
    return options.map((option) => {
      return this._generateDropdownEntry(option, option);
    });
  }

  _generateScaleDropdown(question) {
    switch (question.data.type) {
      case scaleTypes.nps:
        return this._generateNPSDropdown;
      case scaleTypes.emoji:
        return this._generateEmojiDropdown(question.data.scaleEnd);
      case scaleTypes.numeric:
      case scaleTypes.stars:
        return this._generateNumericDropdown(question.data.scaleEnd);
    }
  }

  _generateEmojiDropdown(scaleEnd) {
    let scaleNumbers = [...Array(scaleEnd).keys()];
    return scaleNumbers.map((number) => {
      let emoji = emojiScale(scaleEnd, number + 1); // Array starts at 0 but scale starts at 1
      return this._generateDropdownEntry(number + 1, emoji);
    });
  }

  _generateNumericDropdown(scaleEnd) {
    let scaleNumbers = [...Array(scaleEnd).keys()];
    return scaleNumbers.map((number) => {
      return this._generateDropdownEntry(number + 1, number + 1); // Array starts at 0 but scale starts at 1
    });
  }

  _generateDropdownEntry(value, text) {
    return {
      value,
      text,
      isSelected: this.selectedAnswers.includes(value),
      component: 'reporting/custom/chart-builder/filter-bar/answer-to-question/dropdown-item',
    };
  }

  _mapValuesToNPSLabels(values) {
    let returnValues = [];

    if (isEmpty(values)) {
      return returnValues;
    }

    let valueSet = new Set(values);

    // e.g. if all PASSIVES_VALUES (7 and 8) are present in valueSet, push PASSIVES to returnValues
    Object.keys(NPS_VALUE_MAP)
      .filter((key) => NPS_VALUE_MAP[key].every((val) => valueSet.has(val)))
      .map((key) => returnValues.push(key));

    return returnValues;
  }

  _mapNPSLabelsToValues(values) {
    return Object.keys(NPS_VALUE_MAP)
      .filter((key) => values.includes(key))
      .map((key) => NPS_VALUE_MAP[key])
      .flat();
  }

  @action
  removeFilter() {
    this.selectedAnswers = [];
    this.selectedQuestion = REPORTING_FILTER_SELECT_ALL;
    this.args.removeFilter();
  }

  @action
  updateSelectedQuestion(value) {
    if (value === this.selectedQuestion) {
      // User selected same question as was already selected so we don't change anything
      return;
    }

    this.selectedAnswers = [REPORTING_FILTER_SELECT_ALL];
    this.operator = 'category';
    this.selectedQuestion = value;
    if (value === REPORTING_FILTER_SELECT_ALL) {
      this.args.removeFilter();
    } else {
      /* Since selecting a new question is equivalent to selecting a new filter (changing the filterType),
    we replace the previous filter with a new filter of that question type */
      this.args.replaceFilter(`stats.question#${value}`, this.selectedAnswers, this.operator);
    }
  }

  @action updateSelectedAnswers(selectedAnswers) {
    this.selectedAnswers = selectedAnswers;
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    if (isEmpty(this.selectedAnswers)) {
      this.selectedAnswers = [REPORTING_FILTER_SELECT_ALL];
    }

    let filterableValues = this._answersAsFilterableValues;
    this.args.setSelected(filterableValues, this.operator);
    this.answerLabel = this.currentAnswerLabel;
  }
}
