/* RESPONSIBLE TEAM: team-help-desk-experience */
import { VISIBILITY_FILTER_TYPE } from './filter-types';
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';

// eslint-disable-next-line @intercom/intercom/no-component-inheritance
export class SavedReplyTypeVisibilityFilter {
  @service intl;

  constructor(appService, owner) {
    setOwner(this, owner);
    this.visibilityFilterType = VISIBILITY_FILTER_TYPE.SAVED_REPLY_TYPE;
    this.appService = appService;
  }

  get app() {
    return this.appService.app;
  }

  get savedReplyTypes() {
    return (this.app.savedReplyTypes || []).sortBy('name');
  }

  filterSavedReplies(savedReplies, visibility) {
    let savedReplyType = this.savedReplyTypes.findBy('id', visibility);
    return savedReplies.filter(({ types }) => {
      // when a macro has no types, it defaults to visible everywhere
      // https://github.com/intercom/intercom/issues/237987
      if (types.length > 0) {
        return types.includes(savedReplyType.name);
      } else {
        return true;
      }
    });
  }

  filterLabel(visibility) {
    let savedReplyType = this.savedReplyTypes.findBy('id', visibility);
    return this.intl.t('inbox.manage-macros.filter.available.when-label', {
      displayName: savedReplyType.displayName.toLowerCase(),
    });
  }

  items() {
    if (this.savedReplyTypes.length === 0) {
      return [];
    }

    return [
      {
        heading: this.intl.t('inbox.manage-macros.filter.available.when'),
        items: this.savedReplyTypes.map(({ id, displayName }) => ({
          // this should be removed once the backend strings are translated
          text: this.intl.t(
            `inbox.manage-macros.saved-reply.types.${displayName.split(' ').join('-')}`,
          ),
          value: { type: this.visibilityFilterType, value: id },
        })),
      },
    ];
  }
}
