/* import __COLOCATED_TEMPLATE__ from './y-axis.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';
import { unitFormatter } from 'embercom/helpers/unit-formatter';

const MAX_CHARACTERS = 6;
const CHARACTER_WIDTH = 7;

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;

let convertScaleUnitToMinutes = (value, scaleUnit) => {
  if (scaleUnit === 'days') {
    return MINUTES_IN_DAY * value;
  } else if (scaleUnit === 'hours') {
    return MINUTES_IN_HOUR * value;
  } else {
    return value;
  }
};

export default Component.extend({
  tagName: '',
  scale: notImplemented(
    '`reporting/element/y-axis`: you must pass a D3 scale as "scale". Consider wrapping this component with `reporting/element/y-scale`.',
  ),
  axisItems: computed('scale', 'scaleUnit', function () {
    return this.scale.ticks(5).map((item) => {
      let value = this.unit === 'minute' ? convertScaleUnitToMinutes(item, this.scaleUnit) : item;

      return {
        y: -this.scale(item),
        label: unitFormatter({ value, unit: this.unit, abbreviate: true, hideDefaultUnit: true }),
      };
    });
  }),
  labelOffset: computed('axisItems', function () {
    let localMaxCharacters = this.axisItems
      .map(({ label }) => label.split(''))
      .reduce((max, item) => Math.max(max, item.length), 0);

    return (MAX_CHARACTERS - localMaxCharacters) * CHARACTER_WIDTH - 1; // 1px offset to adjust for the eye
  }),
});
