/* import __COLOCATED_TEMPLATE__ from './main-content.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomConfirmService: service(),
  store: service(),
  intl: service(),

  initialOpenSection: undefined,

  clearChangedData: false,

  isTeammateListUpdated: false,

  sectionCanClose: task(function* (sectionName) {
    let team = this.sortedTeams.findBy('id', sectionName);
    let teamSettings = this.teamSettings.findBy('teamId', sectionName);
    if (
      team === undefined ||
      (!team.hasDirtyAttributes && !teamSettings.hasDirtyAttributes && !this.isTeammateListUpdated)
    ) {
      return true;
    }
    let options = {
      title: this.intl.t('components.settings.teams.main-content.unsaved-changes-modal.title'),
      body: this.intl.t('components.settings.teams.main-content.unsaved-changes-modal.message'),
      confirmButtonText: this.intl.t(
        'components.settings.teams.main-content.unsaved-changes-modal.confirm-button',
      ),
    };
    let confirmed = yield this.intercomConfirmService.confirm(options);
    if (confirmed) {
      this.set('isTeammateListUpdated', false);
      this.setTeammateUpdateStatus(false);
      team.rollbackAttributes();
      teamSettings.rollbackAttributes();
      return true;
    } else {
      return false;
    }
  }),
  actions: {
    setInitialOpenSection(team) {
      this.set('initialOpenSection', team);
    },
    sectionDidClose() {
      this.set('section', null);
      this.set('openSectionName', undefined);
      this.set('clearChangedData', true);
    },
    sectionDidOpen(sectionName) {
      this.set('section', sectionName);
      this.set('openSectionName', sectionName);
      this.set('clearChangedData', false);
    },
    setTeammateUpdateStatusForSection(isUpdated) {
      this.set('isTeammateListUpdated', isUpdated);
      this.setTeammateUpdateStatus(isUpdated);
    },
  },
});
