/* import __COLOCATED_TEMPLATE__ from './search-empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { isEmpty } from '@ember/utils';
import Component from '@ember/component';
import { selectFromObject } from '@intercom/pulse/lib/computed-properties';

const LABELS = {
  platform: {
    title: 'Search people and companies',
    content: 'You can search for users, leads and companies by name and tags',
  },
  inbox: {
    title: 'Search conversations',
    content: 'You can search for conversations by keyword or tag',
  },
  messages: {
    title: 'Search messages',
    content: 'You can search for messages by title or sender',
  },
  outbound: {
    title: 'Search messages',
    content: 'You can search messages by title',
  },
};

export default Component.extend({
  classNames: ['flex-auto flex flex-col items-center justify-center'],
  context: null,
  allLabels: LABELS,
  labels: selectFromObject('allLabels', 'context'),

  didReceiveAttrs() {
    this._super(...arguments);
    if (isEmpty(this.context)) {
      throw new Error('`context` must be defined when invoking `search-empty-state`.');
    }
  },

  click() {
    let exitSearch = this.exitSearch;
    if (typeof exitSearch === 'function') {
      exitSearch();
    }
  },
});
