/* import __COLOCATED_TEMPLATE__ from './sms-message-customization-editor.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { getCharCount } from 'embercom/helpers/count-sms-chars';

class ComposerConfig extends BaseConfig {
  // eslint-disable-next-line @intercom/intercom/no-bare-strings
  placeholder = 'Enter your SMS message. Max 100 chars.';
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  allowedInline = [];
  allowTextAlignment = false;
  singleBlockMode = true;
  tools = [];
  experimental = {
    hideInsertersOnMouseOut: true,
  };

  constructor(app, intl) {
    super(app);
    this.placeholder = intl.t(
      'components.settings.switch.setup.sms-message-customization-editor.placeholder-sms',
    );
  }
}

export default class SmsMessageCustomizationEditorComponent extends Component {
  @service router;
  @service attributeService;
  @service notificationsService;
  @service appService;
  @service intercomEventService;
  @service intl;

  @tracked blocksDocument;
  @tracked selectedLanguage;
  @tracked charactersRemaining;

  constructor() {
    super(...arguments);

    this.selectedLanguage = this.defaultLanguage;

    let smsContent = this.current.smsContent || this.current.defaultSmsContent;
    let blocks = this.createBlocksFromSmsContent(smsContent);
    this.blocksDocument = new BlocksDocument(blocks);
    this.charactersRemaining = this.calculateCharactersRemaining(smsContent);
  }

  get composerConfig() {
    return new ComposerConfig(this.appService.app, this.intl);
  }

  get current() {
    return this.args.settings.smsLanguages.find(
      (smsLanguage) => smsLanguage.language === this.selectedLanguage,
    );
  }

  // MARK: Characters remaining

  calculateCharactersRemaining(text) {
    let total_length = 153;
    let reserved_length = 53;
    return total_length - reserved_length - getCharCount(text || '');
  }

  // MARK: Locales / languages

  get defaultLanguage() {
    let match = this.args.settings.smsLanguages.find((smsLanguage) => smsLanguage.default === true);
    return match ? match.language : 'en';
  }

  @action
  updateSelectedLanguage(language) {
    if (language === 'messenger-settings') {
      let messengerRoute = this.appService.app.canSeeNewIASettingsHub
        ? 'apps.app.settings.channels.messenger'
        : 'apps.app.messenger';
      this.router.transitionTo(messengerRoute);
      return;
    }

    this.current.rollbackAttributes();
    this.selectedLanguage = language;

    let smsContent = this.current.smsContent || this.current.defaultSmsContent;
    let blocks = this.createBlocksFromSmsContent(smsContent);
    this.blocksDocument = new BlocksDocument(blocks);
    this.charactersRemaining = this.calculateCharactersRemaining(smsContent);
  }

  // MARK: Updating the 'switch/sms-language' Ember model

  @task({ restartable: true })
  *saveSwitchSettings() {
    try {
      yield this.args.settings.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'customized',
        object: 'switch_settings',
        place: 'switch',
        section: 'settings',
      });

      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'components.settings.switch.setup.sms-message-customization-editor.confirmation',
          { languageName: this.current.languageName },
        ),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t(
          'components.settings.switch.setup.sms-message-customization-editor.default-error',
        ),
      });
    }
  }

  @action
  resetToDefault() {
    let smsContent = this.current.defaultSmsContent;
    let blocks = this.createBlocksFromSmsContent(smsContent);
    this.blocksDocument = new BlocksDocument(blocks);
    this.charactersRemaining = this.calculateCharactersRemaining(smsContent);
    this.current.smsContent = smsContent;
  }

  @action
  save() {
    this.saveSwitchSettings.perform();
  }

  @action
  updateCharacterCount(blocksDoc) {
    let smsContent = this.getSmsContentFromBlocks(blocksDoc);
    this.charactersRemaining = this.calculateCharactersRemaining(smsContent);
    this.current.smsContent = smsContent;
  }

  // MARK: Formatting

  createBlocksFromSmsContent(smsContent) {
    return [
      {
        type: 'paragraph',
        text: smsContent || '',
        modelKey: 'common/blocks/paragraph',
      },
    ];
  }

  getSmsContentFromBlocks(blocksDoc) {
    return blocksDoc.blocks[0].text;
  }
}
