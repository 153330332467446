/* import __COLOCATED_TEMPLATE__ from './started-by-user-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default class StartedByUserFilter extends Component {
  @service intercomEventService;
  @service intl;
  @service appService;

  selectionItems = [
    { text: this.inboundText, value: true }, // started by Customer
    { text: this.outboundText, value: false }, // replied to Bot/Message
  ];

  get inboundText() {
    return this.intl.t(
      'components.reporting.custom.chart-builder.filter-bar.started-by-user-filter.inbound-text',
    );
  }

  get outboundText() {
    return this.intl.t(
      'components.reporting.custom.chart-builder.filter-bar.started-by-user-filter.outbound-text',
    );
  }

  get selectedValue() {
    if (isEmpty(this.args.selected.values)) {
      return true; // started by Customer is default selection
    }
    return this._retrieveRealValue(this.args.selected.values);
  }

  get label() {
    return this.selectedValue ? this.inboundText : this.outboundText;
  }

  @action
  changeSelection(selectedValue) {
    if (this.args.setSelected) {
      this.args.setSelected([this._retrieveRealValue(selectedValue)]);
    }
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    if (this.args.setSelected) {
      this.args.setSelected([this._retrieveRealValue(this.selectedValue)]);
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_started_by_user',
      object: this.args.analyticsObject,
      type: this.selectedValue,
    });
  }

  _retrieveRealValue(value) {
    return Array.isArray(value) ? value[0] : value;
  }
}
