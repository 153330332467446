/* import __COLOCATED_TEMPLATE__ from './flexible-submenu.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type ReportingViews from 'embercom/services/reporting-views';
import ENV from 'embercom/config/environment';
import type Store from '@ember-data/store';
import type NavigationFolder from 'embercom/models/reporting/navigation-folder';
import type ReportingFolderManagerService from 'embercom/services/reporting-folder-manager-service';
import type Router from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type CustomReportsService from 'embercom/services/custom-reports-service';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';

const REPORTS_3DOTMENU = 'reports-3dotmenu';
export default class FlexibleSubmenuComponent extends Component {
  @service declare appService: any;
  @service declare outboundHomeService: any;
  @service declare reportingViews: ReportingViews;
  @service declare store: Store;
  @service declare reportingFolderManagerService: ReportingFolderManagerService;
  @service declare intercomEventService: any;
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare customReportsService: CustomReportsService;
  @service declare permissionsService: any;
  @service declare greatGuidanceService: GreatGuidanceService;

  @tracked declare selectedFolder?: string;
  @tracked declare showCreateFolderModal: boolean;
  @tracked declare editingFolder: undefined | NavigationFolder;
  @tracked declare showTemplateModal: boolean;

  get navCount() {
    return {
      totalReportsCount: this.reportingViews.allPersistedReportsWithoutTopics.length,
      myReportsCount: this.reportingViews.myReports.length,
    };
  }

  get foldersData(): NavigationFolder[] {
    let folderValues = this.reportingFolderManagerService.pinnedFlexibleFolders ?? [];
    return folderValues
      .reject((folder: NavigationFolder) => folder.position < 0)
      .sortBy('position');
  }

  get isSyntheticDataVisible() {
    return ENV.environment === 'development' && this.appService.app.canUseSyntheticReportingData;
  }

  get newReportActions() {
    return [
      {
        items: [
          {
            text: this.intl.t('reporting.custom-reports.report.use-template'),
            stamp: { text: this.intl.t('reporting.stamps.beta'), color: 'sky' },
            onSelectItem: () => {
              this.openReportTemplateModal();
            },
          },
          {
            text: this.intl.t('reporting.custom-reports.report.from-scratch'),
            onSelectItem: () => {
              this.trackReportCreationEvent();
              this.customReportsService.handleCreateReport();
            },
          },
        ],
      },
    ];
  }

  @action openReportTemplateModal() {
    if (!this.customReportsService.canChangeCustomReports) {
      this.customReportsService.loadChangePermissionModal();
    } else {
      this.openTemplateModal();
    }
  }

  @action async openTemplateModal() {
    this.showTemplateModal = true;
  }

  @action async openCreateFolderModal() {
    this.showCreateFolderModal = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'create_folder_modal',
    });
  }

  @action async closeCreateFolderModal() {
    this.showCreateFolderModal = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      object: 'create_folder_modal',
    });
    this.editingFolder = undefined;
  }

  @action
  trackReportCreationEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      place: 'new_report_button',
      object: 'custom_report',
      context: 'create_report_from_nav',
      section: 'reports',
    });
  }

  @action setEditingFolder(folder: NavigationFolder | undefined) {
    this.editingFolder = folder;
  }

  @action
  reorderFolders(folders: NavigationFolder[], movedFolder: NavigationFolder) {
    let newIndex = folders.indexOf(movedFolder);
    folders.forEach((folder, index) => {
      folder.set('position', index);
    });
    movedFolder.reorder(newIndex);
  }

  @action
  resetActiveFolder() {
    this.reportingFolderManagerService.resetActiveFolder();
  }

  @action
  toggleWidgetVisibility(visibility: boolean) {
    this.greatGuidanceService.setWidgetVisibility(REPORTS_3DOTMENU, visibility);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Layout::FlexibleSubmenu': typeof FlexibleSubmenuComponent;
    'reporting/layout/flexible-submenu': typeof FlexibleSubmenuComponent;
  }
}
