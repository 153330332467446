/* import __COLOCATED_TEMPLATE__ from './standard-checkpoint-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { checkpointStatuses as statuses } from 'embercom/models/data/matching-system/matching-constants';

export default class StandardCheckpointList extends Component {
  @service seriesEditorService;
  @tracked checkpoints = [];
  @tracked checkpointStatuses = 'All';
  @tracked page = 1;
  @tracked pageSize = 25;

  statusGroups = [
    { text: 'All', value: 'All', array: [] },
    { text: 'Active', value: 'Active', array: [statuses.active] },
    {
      text: 'Completed',
      value: 'Completed',
      array: [
        statuses.completedAndTraversedPrimaryEdges,
        statuses.completedAndFinishedPath,
        statuses.completedAndTraversedSplitEdges,
      ],
    },
    {
      text: 'Did not match',
      value: 'Did not match',
      array: [
        statuses.expired,
        statuses.completedAndTraversedAlternativeEdges,
        statuses.completedAndEjectedFromPath,
        statuses.rejectedDueToCompanyPrioritization,
      ],
    },
    { text: 'Exited', value: 'Exited', array: [statuses.exited] },
  ];

  get seriesHasNodeWithEventTriggerAndMetadata() {
    return this.args.node.series.nodes.any((node) => node.hasRulesetWithEventTriggerAndMetadata);
  }

  @action
  onNodeChanged() {
    this.checkpoints = [];
    this.page = 1;
    this.loadCheckpoints.perform();
  }

  @action loadNextPage() {
    this.page += 1;
    this.loadCheckpoints.perform();
  }

  @task
  *loadCheckpoints() {
    yield timeout(50);
    let filter = this.statusGroups.find((group) => group.value === this.checkpointStatuses).array;
    let checkpoints = yield this.seriesEditorService.activeStatsNode.checkpoints(
      filter,
      this.page,
      this.pageSize,
    );
    this.checkpoints.pushObjects(checkpoints);
  }
}
