/* import __COLOCATED_TEMPLATE__ from './team-assigned-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

const UNASSIGNED_TEAM_ID = '0';

export default class TeamAssignedFilter extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;
  @tracked availableTeams = this.teamsWithUnassigned;
  @tracked label = this.currentLabel;
  @tracked selectedDropdownItems = this.args.selected.values || [];
  @tracked operator = this.args.selected.operator || 'category';

  get items() {
    return [
      {
        heading: this.intl.t(
          'components.reporting.custom.chart-builder.filter-bar.team-assigned-filter.heading',
        ),
        items: this.allItems,
      },
    ];
  }

  get unassignedTeamText() {
    return this.intl.t(
      'components.reporting.custom.chart-builder.filter-bar.team-assigned-filter.unassigned-team',
    );
  }

  get teamsWithUnassigned() {
    //Unassigned team uses the same icon and value as unassigned admin
    return [this.appService.app.unassignedAdmin].concat(this.appService.app.teams);
  }

  teamItemLabel(teammate) {
    if (teammate.id === UNASSIGNED_TEAM_ID) {
      return this.unassignedTeamText;
    } else {
      return teammate.display_as_assignee;
    }
  }

  get allItems() {
    return this.availableTeams.map((team) => ({
      model: team,
      text: this.teamItemLabel(team),
      value: team.id.toString(),
      component: 'reporting/flexible/filter-bar/teammate-item',
      isSelected: this.selectedDropdownItems.includes(team.id.toString()),
    }));
  }

  get name() {
    return (
      this.args.labelPrefix ||
      this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.team-assigned-filter.team-assigned-is',
      )
    );
  }

  get selectedTeamIds() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get currentLabel() {
    if (this.selectedTeamIds.length === 0) {
      return this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.team-assigned-filter.any',
      );
    }

    let teamNames = this.selectedTeamIds.map((teammateId) => {
      return (
        this.availableTeams.find((teammate) => teammate.id.toString() === teammateId)
          ?.display_as_assignee ||
        this.intl.t(
          'components.reporting.custom.chart-builder.filter-bar.team-assigned-filter.former-team',
        )
      );
    });
    return teamNames.join(', ');
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_assigned_team',
      object: this.args.analyticsObject,
      team_count: this.selectedTeamIds.length,
      operator: this.operator,
    });
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.trackAnalytics();
    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }

  @action
  onSearch(filterValue) {
    this.availableTeams = this.teamsWithUnassigned.filter((team) =>
      team.name.toLowerCase().includes(filterValue.toLowerCase()),
    );
  }
}
