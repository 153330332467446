/* import __COLOCATED_TEMPLATE__ from './set-language-override.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';

interface Args {
  action: $TSFixMe;
  actionEditorContext: $TSFixMe;
  onChange?: () => void;
  isDisabled?: boolean;
  onDelete?: () => void;
  rule: $TSFixMe;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [{ opener: unknown; languageName?: string }];
  };
}

type Locale = {
  localeId: string;
  name: string;
};

export default class SetLanguageOverride extends Component<Signature> {
  @service appService: $TSFixMe;
  @service declare store: Store;

  @tracked permittedLocales: Locale[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.fetchPermittedLocales).perform();
  }

  get languageName() {
    return this.selectedLanguage?.text;
  }

  get languageCode() {
    return this.selectedLanguage?.value;
  }

  get selectedLanguage() {
    return this.languageItems?.find((l) => l.value === this.args.action.actionData.language_code);
  }

  get languageItems() {
    return this.permittedLocales.map((l) => {
      return {
        text: l.name,
        value: l.localeId,
      };
    });
  }

  @action
  updateLanguage(languageCode: string) {
    this.args.action.actionData = { language_code: languageCode };

    let onChange = this.args.onChange;
    if (onChange) {
      onChange();
    }
  }

  @task({ drop: true })
  *fetchPermittedLocales(): TaskGenerator<void> {
    let languageSettings;

    let peekedLanguageSettings = this.store.peekRecord(
      'messenger-settings/languages',
      this.appService.app.id,
    );
    if (!peekedLanguageSettings) {
      let peekedMessengerSettings = this.store.peekRecord(
        'messenger-settings/all',
        this.appService.app.id,
      );
      peekedLanguageSettings = peekedMessengerSettings?.languages;
    }

    if (peekedLanguageSettings) {
      languageSettings = peekedLanguageSettings;
    } else {
      let fetchedLanguageSettings = yield this.store.findRecord(
        'messenger-settings/languages',
        this.appService.app.id,
      );
      languageSettings = fetchedLanguageSettings;
    }

    this.permittedLocales = languageSettings.permittedLocales;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Rules::Actions::SetLanguageOverride': typeof SetLanguageOverride;
  }
}
