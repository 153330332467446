/* import __COLOCATED_TEMPLATE__ from './static-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { copy } from 'ember-copy';
import { inject as service } from '@ember/service';
import generateUUID from 'embercom/lib/uuid-generator';
import RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';

export default class StaticChart extends Component {
  @service store;

  lastRange = this.args.range;
  @tracked trackedLastRange = this.args.range;

  chartId = generateUUID();

  constructor() {
    super(...arguments);
    if (!this.args.chartDefinition) {
      throw Error('Missing value for @chartDefinition');
    }
    this.store.pushPayload({
      'reporting/custom/chart': this.sanitizedChartDefinition,
    });
  }

  get sanitizedChartDefinition() {
    let sanitizedChartDefinition = copy(this.args.chartDefinition, true);
    // Sanitize chart ids so we don't accidentely overwrite other custom charts in the store
    sanitizedChartDefinition.id = this.chartId;
    return sanitizedChartDefinition;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.chart?.unloadRecord();
  }

  get chart() {
    return this.store.peekRecord('reporting/custom/chart', this.chartId);
  }

  rangeChange = modifier(
    (_element, [range]) => {
      // We want to only trigger a new renderableChart to be created if the range has actually changed
      // We can't check AND update trackedLastRange here, hence the need for lastRange
      if (this.lastRange?.start !== range?.start || this.lastRange?.end !== range?.end) {
        this.lastRange = this.args.range;
        this.trackedLastRange = this.args.range;
      }
    },
    { eager: false },
  );

  get renderableChart() {
    return new RenderableChart(
      this.chart,
      { dateRange: this.trackedLastRange, filters: this.args.filters },
      getOwner(this),
    );
  }
}
