/* import __COLOCATED_TEMPLATE__ from './list-ticket-custom-states.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';

interface Signature {
  Element: HTMLSpanElement;
  Args: {};
}
type TabView = 'live' | 'archived';

export default class ListTicketCustomStates extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare ticketStateService: TicketStateService;

  @tracked ticketSystemState = [];
  @tracked currentTab: TabView = 'live';

  @action switchTab(tab: TabView) {
    this.currentTab = tab;
  }

  @action startSurvey() {
    let surveyId = 40938061;
    window.Intercom('startSurvey', surveyId);
  }

  get currentTabLabel() {
    return this.currentTab === 'live'
      ? this.intl.t('settings.ticket-data.list-ticket-live-tab')
      : this.intl.t('settings.ticket-data.list-ticket-archived-tab');
  }

  get archivedCustomStatesCount() {
    return this.ticketStateService.archivedTicketCustomStates.length;
  }

  @action onRestoreTicketCustomState(ticketCustomState: TicketCustomState) {
    taskFor(this.unarchiveTicketCustomState).perform(ticketCustomState);
  }

  @task({ drop: true })
  *unarchiveTicketCustomState(ticketCustomState: TicketCustomState) {
    try {
      yield this.ticketStateService.unarchiveTicketCustomState(ticketCustomState.id);
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.ticket-states.unarchive.successfully-unarchived', {
          ticketCustomState: ticketCustomState.adminLabel,
        }),
      );
      if (this.archivedCustomStatesCount === 1) {
        this.switchTab('live');
      }

      this.ticketStateService.reloadTicketCustomStates();
    } catch (error) {
      if (error.jqXHR?.responseJSON?.message) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-states.unarchive.unknown-error', {
            ticketCustomState: ticketCustomState.adminLabel,
          }),
        );
      }
    }
  }

  get systemStates() {
    return [
      {
        title: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.system-states.submitted',
        ),
        systemState: TicketSystemState.Submitted,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.Submitted),
      },
      {
        title: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.system-states.in_progress',
        ),
        systemState: TicketSystemState.InProgress,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.InProgress),
      },
      {
        title: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.system-states.waiting_on_customer',
        ),
        systemState: TicketSystemState.WaitingOnCustomer,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.WaitingOnCustomer),
      },
      {
        title: this.intl.t('settings.ticket-data.ticket-type-configuration.system-states.resolved'),
        systemState: TicketSystemState.Resolved,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.Resolved),
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketStates::ListTicketCustomStates': typeof ListTicketCustomStates;
    'settings/ticket-states/list-ticket-custom-states': typeof ListTicketCustomStates;
  }
}
