/* import __COLOCATED_TEMPLATE__ from './attribute-table.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly, gte, not, notEmpty, filterBy, union } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
const ALLOWED_ATTRIBUTE_IDENTIFIERS = [
  'name',
  'email',
  'phone',
  'company.name',
  'company.size',
  'company.website',
  'company.industry',
];
const ATTRIBUTE_LIMIT = 40;

export default Component.extend({
  notificationsService: service(),
  classNames: ['settings__qualification__table'],
  attributeService: service(),
  appService: service(),
  intercomConfirmService: service(),
  app: readOnly('appService.app'),
  intl: service(),
  store: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  dropdownIsDisabled: gte('qualificationAttributes.length', ATTRIBUTE_LIMIT),
  dropdownIsEnabled: not('dropdownIsDisabled'),
  hasQualificationAttributes: notEmpty('qualificationAttributes'),
  allowedStandardAttributes: computed('attributeService.nonArchivedAttributes.[]', function () {
    return ALLOWED_ATTRIBUTE_IDENTIFIERS.map((identifier) => {
      return this.get('attributeService.nonArchivedAttributes').findBy('identifier', identifier);
    });
  }),
  customAttributes: filterBy('attributeService.nonArchivedAttributes', 'isCustomData', true),
  attributes: union('allowedStandardAttributes', 'customAttributes'),
  qualificationAttributeIdentifiers: computed('qualificationAttributes.[]', function () {
    return new Set(this.qualificationAttributes.map((attribute) => attribute.get('identifier')));
  }),
  userAttributesForDropdown: filterBy('attributes', 'canBeUsedForQualification', true),
  companyAttributesForDropdown: filterBy('attributes', 'isUser', false),
  attributeGroupList: computed(
    'intl.locale',
    'userAttributesForDropdown.[]',
    'companyAttributesForDropdown.[]',
    function () {
      return [
        {
          heading: this.intl.t('components.settings.qualification.attribute-table.user-data'),
          attributes: this.userAttributesForDropdown,
        },
        {
          heading: this.intl.t('components.settings.qualification.attribute-table.company-data'),
          attributes: this.companyAttributesForDropdown,
        },
      ];
    },
  ),
  addQualificationAttributeTask: task(function* (identifier) {
    let qualificationAttribute = this.store.createRecord('people/qualification-attribute', {
      identifier,
    });
    try {
      yield qualificationAttribute.save();
      this.app.qualification_attributes.pushObject(qualificationAttribute);
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t(
          'components.settings.qualification.attribute-table.something-went-wrong',
        ),
      });
    }
  }),
  removeQualificationAttributeTask: task(function* (qualificationAttribute) {
    try {
      let name = qualificationAttribute.get('name');
      yield qualificationAttribute.destroyRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t('components.settings.qualification.attribute-table.removed-attribute', {
          name,
        }),
      );
    } catch (e) {
      if (e.jqXHR.responseJSON.errors[0].message === 'Attribute is in use') {
        let name = qualificationAttribute.get('name');
        this._confirmRemove(name).then(() => {
          this.removeQualificationAttributeAndDependants.perform(qualificationAttribute);
        });
      } else {
        this.notificationsService.notifyResponseError(e, {
          default: this.intl.t('components.settings.qualification.attribute-table.error-occured'),
        });
      }
    }
  }),
  removeQualificationAttributeAndDependants: task(function* (qualificationAttribute) {
    try {
      let name = qualificationAttribute.get('name');
      let operatorSettings = yield this.store.findRecord(
        'operator-settings/task-bots',
        this.get('app.id'),
      );
      operatorSettings.removeQualificationAttribute(qualificationAttribute.get('identifier'));
      yield operatorSettings.save();
      yield qualificationAttribute.destroyRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'components.settings.qualification.attribute-table.customer-profile-attribute-remove',
          { name },
        ),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t(
          'components.settings.qualification.attribute-table.error-while-removing',
        ),
      });
    }
  }),
  _confirmRemove(attributeName) {
    let options = {
      title: this.intl.t('components.settings.qualification.attribute-table.title'),
      confirmButtonText: this.intl.t(
        'components.settings.qualification.attribute-table.confirm-button-text',
      ),
      cancleButtonText: this.intl.t(
        'components.settings.qualification.attribute-table.cancle-button-text',
      ),
      body: this.intl.t('components.settings.qualification.attribute-table.body', {
        attributeName,
      }),
      primaryButtonType: 'primary-destructive',
    };
    return this.intercomConfirmService.confirm(options);
  },
  actions: {
    addQualificationAttribute(attribute) {
      this.setIsSaving?.(true);
      this.addQualificationAttributeTask.perform(attribute.get('identifier')).finally(() => {
        this.setIsSaving?.(false);
      });
    },
    removeQualificationAttribute(attribute) {
      this.setIsSaving?.(true);
      this.removeQualificationAttributeTask.perform(attribute).finally(() => {
        this.setIsSaving?.(false);
      });
    },
    showNewAttributeModal() {
      this.modalService.openModal('settings/modals/attribute-details', {
        allowArchival: false,
        dataContext: 'qualification',
      });
    },
  },
});
