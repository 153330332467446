/* import __COLOCATED_TEMPLATE__ from './edit-filters.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { PARTICIPANT_TYPE_VALUES } from 'embercom/lib/reporting/flexible/constants';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { buildNestedFieldFilters } from 'embercom/lib/reporting/nested-field-filters';
import CustomAttributes from 'embercom/lib/reporting/flexible/custom-attributes';
import { isPresent } from '@ember/utils';

const FILTERABLE_CUSTOM_ATTRIBUTE_TYPES = ['list', 'boolean'];

const OPERATORS = {
  eq: 'category',
  nin: 'not_in_category',
  known: 'exists',
  unknown: 'not_exists',
};

export default class EditFiltersComponent extends Component {
  @service appService;
  @service intl;

  filterIdExpanded = null;

  @tracked conversationAttributeDescriptors = [];
  @tracked enabledFilterIds = this.buildInitiallyEnabledFilterIds();
  customAttributes = new CustomAttributes();

  get app() {
    return this.appService.app;
  }

  buildInitiallyEnabledFilterIds() {
    if (!isPresent(this.args.topic.conversationCustomData)) {
      return [];
    }

    let initiallyEnabledFilterIds = [];

    this.args.topic.conversationCustomData.forEach((attribute) => {
      let filter = this.customAttributeFilters[attribute.field_id];
      if (filter) {
        initiallyEnabledFilterIds.push(attribute.field_id);
      }
    });

    return initiallyEnabledFilterIds;
  }

  get filters() {
    let filterIdsAvailable = Object.keys(this.customAttributeFilters)
      .filter((id) => !this.enabledFilterIds.includes(id))
      .filter((id) => this.customAttributeFiltersConfig[id]);

    let filterConfigs = filterIdsAvailable
      .map((id) => this.customAttributeFiltersConfig[id])
      .sort((configA, configB) => {
        if (configA.isArchived && !configB.isArchived) {
          return 1;
        } else if (!configA.isArchived && configB.isArchived) {
          return -1;
        } else {
          return configA.selectorLabel.localeCompare(configB.selectorLabel);
        }
      });

    let filters = filterConfigs.map((config) => ({
      text: config.selectorLabel,
      value: config.selectorValue,
      icon: config.icon,
      count: config.isArchived
        ? this.intl.t('reporting.conversational-insights.edit-filters.filter-is-archived')
        : '',
      isPaywalled: config.isPaywalled,
    }));

    return filters;
  }

  get enabledFilters() {
    let filters = [];

    this.enabledFilterIds.map((id) => {
      let filterConfig = this.customAttributeFiltersConfig[id];
      filters.pushObject({
        component: filterConfig.component,
        type: id,
        isOpen: id === this.filterIdExpanded,
        selected: this.customAttributeFilters[id],
      });
    });

    return filters;
  }

  get customAttributeFiltersConfig() {
    let filters = {};

    this.conversationAttributeDescriptors.forEach((descriptor) => {
      filters[descriptor.id] = {
        selectorLabel: descriptor.name,
        selectorValue: descriptor.id,
        component: `reporting/flexible/filter-bar/custom-attributes/${descriptor.dataType}-filter`,
        isArchived: descriptor.archived,
        icon: descriptor.icon || 'transfer',
        category: descriptor.category,
      };
    });

    return filters;
  }

  get displayedParticipantTypes() {
    return this.args.topic.authorTypes.length === PARTICIPANT_TYPE_VALUES.length
      ? []
      : this.args.topic.authorTypes;
  }

  @action
  addFilter(id) {
    this.filterIdExpanded = id;
    this.enabledFilterIds.pushObject(id);
  }

  @action
  onFiltersChanged(id, values, operator) {
    this.filterIdExpanded = null;
    this.updateAttributesInTopic(id, values, operator);
  }

  updateAttributesInTopic(id, values, operator) {
    let data = [...this.args.topic.conversationCustomData];
    let filter = data.find((filter) => filter.field_id === id);

    if (!filter) {
      filter = {};
      data.push(filter);
    }

    let descriptor = this.conversationAttributeDescriptors.find(
      (descriptor) => descriptor.id === id,
    );
    let nestedFilter = buildNestedFieldFilters({
      [id]: { field: 'conversation_custom_field', type: descriptor.dataType, operator, values },
    })[0];

    filter.field_id = nestedFilter.field_id;
    filter.operator = nestedFilter.operator;
    filter.values = nestedFilter.values;

    this.args.topic.conversationCustomData = data;
    let operatorToUse = OPERATORS[operator] || operator;
    this.customAttributes.updateValues(id, values, operatorToUse);
  }

  @action
  removeFilter(index) {
    let newFilterIds = [];
    let filterIdToRemove;

    this.enabledFilterIds.forEach((type, i) => {
      if (index === i) {
        filterIdToRemove = type;
      } else {
        newFilterIds.push(type);
      }
    });

    this.enabledFilterIds = newFilterIds;

    let data = this.args.topic.conversationCustomData.filter(
      (filter) => filter.field_id !== filterIdToRemove,
    );
    this.args.topic.conversationCustomData = data;
    this.filterIdExpanded = null;
    this.customAttributes.updateValues(filterIdToRemove);
  }

  @action
  clearFilters() {
    this.enabledFilterIds = [];
    this.args.topic.conversationCustomData = [];
    this.filterIdExpanded = null;
  }

  get customAttributeFilters() {
    let attributeTypeIsNotSupported = (attribute) => {
      return !FILTERABLE_CUSTOM_ATTRIBUTE_TYPES.includes(attribute.dataType);
    };

    let filters = {};

    this.conversationAttributeDescriptors.forEach((descriptor) => {
      if (
        attributeTypeIsNotSupported(descriptor) ||
        descriptor.isTicketDescriptor ||
        descriptor.hideFromReporting
      ) {
        return;
      }

      filters[descriptor.id] = this.customAttributes.getAttributes(descriptor.id) || {};
    });

    return filters;
  }

  @action
  updateAuthorTypes(participantTypes) {
    this.args.topic.authorTypes =
      participantTypes.length === 0 ? PARTICIPANT_TYPE_VALUES : participantTypes;
  }

  @task
  *fetchConversationAttributes() {
    if (this.app.canUseFeature('conversation_attributes')) {
      this.conversationAttributeDescriptors =
        yield ConversationAttributeDescriptor.peekAllAndMaybeLoad();
      this._buildCustomAttributes();
      this.enabledFilterIds = this.buildInitiallyEnabledFilterIds();
    }
  }

  _buildCustomAttributes() {
    if (!isPresent(this.args.topic.conversationCustomData)) {
      return [];
    }

    this.args.topic.conversationCustomData.forEach((filter) => {
      let operator = OPERATORS[filter.operator];
      this.customAttributes.updateValues(filter.field_id, filter.values, operator);
    });
  }
}
