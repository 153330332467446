/* import __COLOCATED_TEMPLATE__ from './favorite-button.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FavoritesService from 'embercom/services/favorites-service';

interface FavoriteButtonArgs {
  favoriteId: string;
}

export default class FavoriteButton extends Component<FavoriteButtonArgs> {
  @service declare favoritesService: FavoritesService;

  get isFavorite() {
    return this.favoritesService.isFavoriteReport(this.args.favoriteId);
  }

  get hasMigrationReplacement() {
    return this.favoritesService.hasMigrationReplacement(this.args.favoriteId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::FavoriteButton': typeof FavoriteButton;
  }
}
