/* import __COLOCATED_TEMPLATE__ from './regex-string-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import templateOnlyComponent from '@ember/component/template-only';
import { type Filter } from 'embercom/services/chart-builder-filter-service';
import type Range from 'embercom/models/reporting/range';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    icon: string;
    type: string;
    name: string;
    selected: Range | Filter | string[];
    setSelected: () => void;
    removeFilter: () => void;
    regexFiltersEnabled: boolean | undefined;
  };
}

const RegexStringFilter = templateOnlyComponent<Signature>();
export default RegexStringFilter;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::CustomAttributes::RegexStringFilter': typeof RegexStringFilter;
  }
}
