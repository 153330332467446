/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
export default class List extends Component {
  @service intl;
  @service notificationsService;
  @service intercomEventService;
  @service appService;
  @service modelDataCacheService;
  @tracked showObjectTypeModal = false;
  @tracked currentTab = 'live';

  get app() {
    return this.appService.app;
  }

  get customObjects() {
    return this.args.customObjects.rejectBy('isSystemDefined');
  }

  get liveCustomObjects() {
    return this.customObjects.rejectBy('archived');
  }

  get archivedCustomObjects() {
    return this.customObjects.filterBy('archived');
  }

  get selectedCustomObjects() {
    if (this.currentTab === 'live') {
      return this.liveCustomObjects;
    } else {
      return this.archivedCustomObjects;
    }
  }

  get columns() {
    let columns = [
      {
        label: this.intl.t('settings.custom-object-data.list.object-types.column-object'),
        valuePath: 'name',
      },
      {
        label: this.intl.t('settings.custom-object-data.list.object-types.column-updated-at'),
        valuePath: 'updatedAt',
      },
      {
        label: this.intl.t('settings.custom-object-data.list.object-types.column-created-at'),
        valuePath: 'createdAt',
      },
    ];

    if (this.currentTab === 'live') {
      columns.push({
        label: '',
        valuePath: 'editAttribute',
        width: 0,
      });
    } else {
      columns.push({
        label: this.intl.t('settings.custom-object-data.list.object-types.label-archived'),
        valuePath: 'archivedAt',
      });
    }

    return columns;
  }

  @task({ drop: true })
  *unarchiveCustomObjectType(customObject) {
    try {
      customObject.archived = false;
      yield customObject.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.custom-object-data.modals.unarchive.notification-success', {
          type: customObject.name,
        }),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'unarchive',
        object: 'archive',
        place: 'custom_object',
        archive_object_type: 'object',
        archive_object_id: customObject.id,
      });
      if (this.archivedCustomObjects.length === 0) {
        this.currentTab = 'live';
      }
      this.app.updateLocalCache(this.modelDataCacheService);
    } catch (error) {
      customObject.rollbackAttributes();
      this.notificationsService.notifyError(
        this.intl.t('settings.custom-object-data.modals.unarchive.notification-failure', {
          type: customObject.name,
        }),
      );
    }
  }
}
