/* import __COLOCATED_TEMPLATE__ from './first-assignment-to-close-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig from 'embercom/lib/reporting/flexible/view-config';
import {
  COUNT,
  MEDIAN,
  MEAN,
  MAX,
  MIN,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  LAST_ASSIGNMENT_TO_CLOSE_TIME,
} from 'embercom/lib/reporting/flexible/constants';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { botInboxAndOfficeHoursField } from 'embercom/lib/reporting/office-hours-field';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { inject as service } from '@ember/service';

export default class FirstAssignmentToCloseBreakdown extends Component {
  @service intl;
  get dataConfig() {
    return {
      series: [
        {
          name: 'first-assignment-to-close-breakdown',
          source: 'conversation_with_message_thread',
          type: 'range',
          data: {
            property: botInboxAndOfficeHoursField(
              LAST_ASSIGNMENT_TO_CLOSE_TIME,
              this.args.settings.reportingExcludeOooHours,
            ),
            ranges: [
              { to: 5 * 60 },
              { from: 5 * 60, to: 15 * 60 },
              { from: 15 * 60, to: 30 * 60 },
              { from: 30 * 60, to: 60 * 60 },
              { from: 60 * 60, to: 3 * 60 * 60 },
              { from: 3 * 60 * 60, to: 8 * 60 * 60 },
              { from: 8 * 60 * 60 },
            ],
          },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
      ],
    };
  }

  get viewConfig() {
    return this.buildViewConfig({
      unit: units.percent,
      aggregations: { defaultOption: COUNT },
      groupings: {
        defaultOption: false,
      },
      tableChart: {
        columns: [
          {
            label: this.intl.t(
              'components.reporting.reports.effectiveness.first-assignment-to-close-breakdown.first-time-to-close',
            ),
            valuePath: 'bucketRange',
          },
          {
            label: this.intl.t(
              'components.reporting.reports.effectiveness.first-assignment-to-close-breakdown.conversations',
            ),
            valuePath: 'percentage',
          },
        ],
      },
    });
  }

  buildViewConfig({
    unit,
    displayUnit = '',
    aggregations = { defaultOption: MEDIAN, rest: [MIN, MAX, MEAN] },
    groupings,
    tableChart = {},
    counter = {},
  }) {
    let viewConfig = new ViewConfig();

    viewConfig.aggregations = aggregations;
    if (Object.keys(groupings).length) {
      viewConfig.groupings = groupings;
    }
    viewConfig.formatUnit = { unit, displayUnit };
    viewConfig.counter = counter;
    viewConfig.tableChart = tableChart;

    return viewConfig;
  }
}
