/* import __COLOCATED_TEMPLATE__ from './reassignments-columnchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  COUNT,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  REASSIGNMENTS_COUNT_AFTER_ADMIN_REPLY,
} from 'embercom/lib/reporting/flexible/constants';
import { inject as service } from '@ember/service';

export default class ReassignmentsColumnchart extends Component {
  @service intl;
  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value, displayUnit: 'conversation' };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
        createTitleTooltipText: () => {
          return this.intl.t(
            'components.reporting.reports.effectiveness.reassignments-columnchart.columnchart-tooltip',
          );
        },
      },
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      xAxis: {
        type: 'temporal',
        data: {
          interval: FILTER_PROPERTY_PLACEHOLDER,
          property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
        },
      },
      series: [
        {
          name: 'reassignments_columnchart',
          source: 'conversation_with_message_thread',
          type: COUNT,
          data: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
          },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'range',
            data: {
              operators: ['gte'],
              property: REASSIGNMENTS_COUNT_AFTER_ADMIN_REPLY,
              values: [1],
            },
          },
        },
      ],
    };
  }
}
