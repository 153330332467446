/* import __COLOCATED_TEMPLATE__ from './y-axis-line.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { fmtStyle } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  tagName: '',
  y: 0,
  style: fmtStyle('transform: translate3d(0,%@px,0);', 'y'),
  labelOffsetStyle: fmtStyle('transform:translate3d(-%@px,0,0)', 'labelOffset'),
});
