/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { computed } from '@ember/object';
import { and } from '@ember/object/computed';
import BaseChart from 'embercom/components/reporting/base-chart';
import AxesMixin from 'embercom/components/reporting/axes-mixin';
import { color as d3Color } from 'd3-color';
import { select } from 'd3-selection';
import { buildPalette } from 'embercom/helpers/palette';
import defaultTo from '@intercom/pulse/lib/default-to';

export default BaseChart.extend(AxesMixin, {
  'data-test-reporting-bar-chart': true,
  classNames: ['reporting__chart__bar-chart'],
  showComparison: defaultTo(true),
  renderComparisonData: and('showComparison', 'signal.comparison'),

  bucketWidth: computed('chartWidth', 'data.length', function () {
    let maxBucketWidth = 30;
    let minBucketWidth = 2;
    let minGapWidth = 5;

    let availableBarSpace = this.chartWidth / this.get('data.length');
    let cappedBucketWidth = Math.min(availableBarSpace - minGapWidth, maxBucketWidth);
    return Math.max(minBucketWidth, cappedBucketWidth);
  }),
  gapWidth: computed('data.length', 'chartWidth', 'bucketWidth', function () {
    let bucketCount = this.get('data.length');
    let chartWidth = this.chartWidth;
    let bucketWidth = this.bucketWidth;
    return Math.max(2, (chartWidth - bucketCount * bucketWidth) / bucketCount);
  }),

  redrawChart() {
    let data = this.data;
    let transitionDuration = this.transitionDuration;
    let xScale = this.xScale;
    let yScale = this.yScale;
    let chartHeight = this.chartHeight;
    let barWidth = this.bucketWidth / 2;
    let gapWidth = this.gapWidth;
    let color = buildPalette([this.color]);
    let renderComparisonData = this.renderComparisonData;

    let valueUnit = this.hoverTextUnit || this.get('signal.valueUnit');

    let bars = this.chart()
      .selectAll('.reporting__chart__bar-container')
      .data(data, (d) => d.key);

    bars
      .exit()
      .transition()
      .duration(transitionDuration)
      .remove()
      .selectAll('rect')
      .attr('y', chartHeight)
      .attr('height', 0);

    let enterJoinBars = bars.enter().append('g').classed('reporting__chart__bar-container', true);

    function xTransform(d) {
      let x_val = xScale(new Date(d.key)) + gapWidth / 2;
      return `translate(${x_val}, 0)`;
    }

    enterJoinBars
      .append('rect')
      .classed('reporting__chart__bar', true)
      .classed('js__legacy-reporting-tooltip', true)
      .classed('reporting__chart__transition', true)
      .attr('data-component', 'tooltips/reporting-chart-datum')
      .attr('data-value-unit', valueUnit)
      .attr('data-value-unit-is-minute', this.get('signal.valueUnitIsMinute'))
      .attr('transform', (d) => xTransform(d))
      .attr('x', (d) => (renderComparisonData ? barWidth : barWidth / 2)) // if there's no comparison bar we center this single bar.
      .attr('y', chartHeight)
      .attr('height', 0)
      .style('opacity', 0)
      .attr('fill', color)
      .on('mouseover', function () {
        select(this).attr('fill', d3Color(color).darker(0.2));
      })
      .on('mouseout', function () {
        select(this).attr('fill', color);
      });

    enterJoinBars
      .merge(bars)
      .select('rect.reporting__chart__bar')
      .attr('data-current-date', (d) => d.key)
      .attr('data-timezone', this.timezone)
      .attr('data-comparison-date', (d) => d.previousKey)
      .attr('data-current-value', (d) => d.value)
      .attr('data-range-start', this.get('range.start'))
      .attr('data-range-end', this.get('range.end'))
      .attr('data-aggregation-interval', this.get('range.interval'))
      .transition()
      .duration(transitionDuration)
      .attr('transform', (d) => xTransform(d))
      .attr('width', barWidth)
      .attr('y', (d) => yScale(d.value))
      .style('opacity', 1)
      .attr('height', (d) => chartHeight - yScale(d.value));

    if (renderComparisonData) {
      enterJoinBars
        .append('rect')
        .classed('reporting__chart__bar-comparison', true)
        .classed('js__legacy-reporting-tooltip', true)
        .attr('data-component', 'tooltips/reporting-chart-datum')
        .attr('data-value-unit', valueUnit)
        .attr('data-value-unit-is-minute', this.get('signal.valueUnitIsMinute'))
        .attr('transform', (d) => xTransform(d))
        .attr('y', chartHeight)
        .attr('height', 0)
        .style('opacity', 0);

      enterJoinBars
        .merge(bars)
        .select('rect.reporting__chart__bar-comparison')
        .attr('data-current-date', (d) => d.previousKey)
        .attr('data-timezone', this.timezone)
        .attr('data-current-value', (d) => d.previousValue)
        .attr('data-range-start', this.get('range.comparisonStart'))
        .attr('data-range-end', this.get('range.comparisonEnd'))
        .attr('data-previous-period', true)
        .attr('data-comparison-date', (d) => d.key)
        .attr('data-aggregation-interval', this.get('range.interval'))
        .transition()
        .duration(transitionDuration)
        .attr('transform', (d) => xTransform(d))
        .attr('width', barWidth)
        .attr('x', -2)
        .attr('y', (d) => yScale(d.previousValue))
        .style('opacity', 1)
        .attr('height', (d) => chartHeight - yScale(d.previousValue));
    }

    this.redrawAxes();
  },
});
