/* import __COLOCATED_TEMPLATE__ from './conversations-assigned-team-columnchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import PALETTE from '@intercom/pulse/lib/palette';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  CARDINALITY,
  COMMENT_TEAM_ASSIGNED,
  COMMENT_CREATED_AT,
  CONVERSATION_ID,
  USER_PART_EXISTS_FILTER,
} from 'embercom/lib/reporting/flexible/constants';
import { inject as service } from '@ember/service';

export default class ConversationsAssignedTeamColumnchart extends Component {
  @service appService;
  @service intl;
  teams = this.appService.app.teams;

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value, displayUnit: 'conversation' };
    viewConfig.aggregations = { defaultOption: CARDINALITY };
    viewConfig.seriesColors = [
      PALETTE['vis-azure-50'],
      PALETTE['vis-yellow-50'],
      PALETTE['vis-mint-50'],
      PALETTE['vis-salmon-50'],
      PALETTE['vis-slateblue-50'],
      PALETTE['vis-olive-50'],
      PALETTE['vis-pink-50'],
      PALETTE['vis-turquoise-50'],
      PALETTE['vis-orange-50'],
      PALETTE['vis-blue-50'],
    ];
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[COMMENT_TEAM_ASSIGNED],
        labelMappings: this.labelMappings,
        defaultLabel: this.intl.t(
          'apps.app.reports.customer-support.team-performance.conversation-assigned-team-columnchart.default-label',
        ),
        isComparison: false,
        createTitleTooltipText: () => {
          return this.intl.t(
            'apps.app.reports.customer-support.team-performance.conversation-assigned-team-columnchart.tooltip',
          );
        },
      },
      rest: [],
    };

    return viewConfig;
  }

  get labelMappings() {
    let teamMapping = {};
    this.teams.forEach((team) => (teamMapping[team.id] = team.display_as_assignee));
    return teamMapping;
  }

  get dataConfig() {
    return {
      xAxis: {
        type: 'nominal',
        data: {
          property: COMMENT_TEAM_ASSIGNED,
        },
      },
      series: [
        {
          name: 'conversations_assigned_team_columnchart',
          source: 'consolidated_conversation_part',
          type: CARDINALITY,
          data: { property: CONVERSATION_ID },
          time: {
            property: COMMENT_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'and',
            filters: [
              USER_PART_EXISTS_FILTER,
              {
                type: 'range',
                data: {
                  property: COMMENT_TEAM_ASSIGNED,
                  operators: ['gt'],
                  values: ['0'], //filter out unassigned teams
                },
              },
            ],
          },
        },
      ],
    };
  }
}
