/* import __COLOCATED_TEMPLATE__ from './attribute-details.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import { bind } from '@ember/runloop';
import { computed, action } from '@ember/object';
import { alias, readOnly, empty, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line @intercom/intercom/no-legacy-modal
import DataAttributeArchiver from 'embercom/lib/data-attributes/data-attribute-archiver';
import NewCdaSuccess from 'embercom/components/notifications/new-cda-success';

export default Component.extend({
  tagName: '',
  notificationsService: service(),
  appService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  modelDataCacheService: service(),
  router: service(),
  showCheckingDependentsModal: false,
  attribute: alias('model.attribute'),
  allowArchival: alias('model.allowArchival'),
  showPeopleAndCompanyToggleTab: computed('model.showPeopleAndCompanyToggleTab', {
    get() {
      return this.get('model.showPeopleAndCompanyToggleTab') !== false;
    },
  }),
  typeToCreate: computed('model.typeToCreate', {
    get() {
      return this.get('model.typeToCreate') === undefined
        ? 'people'
        : this.get('model.typeToCreate');
    },
  }),
  dataContext: readOnly('model.dataContext'),
  availableTypes: readOnly('model.availableTypes'),
  isCreateMode: empty('attribute'),
  isEditMode: not('isCreateMode'),
  canCloseModal: true,

  init() {
    this._super(...arguments);
    this.router.on('routeWillChange', this.closeModal);
  },

  markAttributeAsArchived() {
    this.attribute.set('archived', true);
    this.get('appService.app').updateLocalCache(this.modelDataCacheService);
  },

  markAttributeAsUnarchived() {
    this.attribute.set('archived', false);
    this.get('appService.app').updateLocalCache(this.modelDataCacheService);
    this.notificationsService.notifyConfirmation(
      `${this.get('attribute.name')} has been unarchived`,
    );
    this.modalService.closeModal();
  },

  dataAttributeArchiver: computed('attribute', function () {
    return DataAttributeArchiver.create({
      appId: this.get('appService.app.id'),
      attribute: this.attribute,
      onArchive: bind(this, this.markAttributeAsArchived),
      onUnarchive: bind(this, this.markAttributeAsUnarchived),
    });
  }),

  afterUpdate: () => {},

  afterCreate(attribute) {
    if (this.dataContext !== 'qualification') {
      this.notificationsService.notifyConfirmationWithModelAndComponent(attribute, NewCdaSuccess);
    }
  },
  hooks: computed(function () {
    return {
      afterUpdate: bind(this, this.afterUpdate),
      afterCreate: bind(this, this.afterCreate),
    };
  }),

  closeModal: action(function () {
    this.modalService.closeModal();
  }),

  actions: {
    performTask(task) {
      return task.perform().then((res) => {
        if (this.model.onSave && typeof this.model.onSave === 'function') {
          this.model.onSave(res);
        }
        this.modalService.closeModal();
      });
    },
    archiveAttribute() {
      if (this.allowArchival) {
        this.set('showCheckingDependentsModal', true);
      }
    },
    unarchiveAttribute() {
      if (this.allowArchival) {
        return this.get('dataAttributeArchiver.unarchiveTask').perform();
      }
    },
  },
});
