/* import __COLOCATED_TEMPLATE__ from './sender-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { senderTypes } from 'embercom/models/data/outbound/constants';
import pluralize from 'embercom/lib/inflector';

export default class SenderFilter extends Component {
  @tracked selectedSenderIds = this.args.filterHash.senderIds || [];
  @service appService;
  @service intercomEventService;

  get app() {
    return this.appService.app;
  }

  get senders() {
    let allSenders = [];

    let emails = this.app.get('verifiedCustomEmailAddresses');
    allSenders.push(emails);

    let teams = this.app.get('teams');
    allSenders.push(teams);

    let admins = this.app.get('humanAdmins').sortBy('name').reverse();
    allSenders.push(admins);

    let operator = this.app.get('operatorBot');
    allSenders.push(operator);

    let owner = { name: 'Owner', id: -1 };
    allSenders.push(owner);

    return this._mapSendersToDropdownItems(allSenders.flat()).sortBy('isSelected').reverse();
  }

  get filterName() {
    if (this.selectedSenderIds.length === 0) {
      return 'Any sender';
    }

    let senderNames = this.senders
      .filter((sender) => {
        return this.selectedSenderIds.includes(sender.value);
      })
      .map((sender) => {
        return sender.text;
      });

    if (senderNames.length === 1) {
      return senderNames.firstObject;
    } else {
      return `${senderNames.firstObject} or ${this.selectedSenderIds.length - 1} ${pluralize(
        'other',
        this.selectedSenderIds.length - 1,
        'others',
      )}`;
    }
  }

  _senderId(sender) {
    if (sender.id === -1) {
      return `${senderTypes.owner}_`;
    }

    if (sender.type === 'admin') {
      let senderType = sender.isTeam ? senderTypes.team : senderTypes.admin;
      return `${senderType}_${sender.id}`;
    }

    return `${senderTypes.customEmailAddress}_${sender.id}`;
  }

  _mapSendersToDropdownItems(senders) {
    return senders.map((sender) => {
      let senderId = this._senderId(sender);
      let isSelected = this.selectedSenderIds.includes(senderId);

      if (sender.type === 'admin') {
        return {
          text: sender.name,
          value: senderId,
          avatarData: sender.avatarData,
          component: 'common/avatar-and-name-dropdown-item',
          componentAttrs: {
            model: sender,
            modelDisplayName: sender.name,
            avatarSize: 'xs',
          },
          isSelected,
        };
      } else if (sender.id === -1) {
        return {
          text: sender.name,
          value: senderId,
          icon: 'owner',
          isSelected,
        };
      } else {
        return {
          text: `${sender.name} <${sender.email}>`,
          value: senderId,
          icon: 'email',
          isSelected,
        };
      }
    });
  }

  @action
  updateMessagesFilter(selectedValues) {
    this.selectedSenderIds = selectedValues;
    this.args.updateFilterHash('senderIds', selectedValues);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved',
      object: 'filter',
      context: 'any_sender',
    });
  }
}
