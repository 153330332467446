/* import __COLOCATED_TEMPLATE__ from './manage-custom-addresses.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { getDomainName } from 'embercom/lib/email';
import { sortBy } from 'underscore';
import { inject as service } from '@ember/service';

export default class ManageCustomAddresses extends Component {
  @service appService;
  @service intl;

  get tableColumns() {
    let columns = [
      {
        label: this.intl.t('settings.senders.outbound-sender-settings.custom-email-table.name'),
        valuePath: 'name',
        width: '40%',
      },
      {
        label: this.intl.t('settings.senders.outbound-sender-settings.custom-email-table.email'),
        valuePath: 'email',
      },
    ];

    if (this.appService.app.canUseMultiBrandEmailOpenBeta) {
      columns.push({
        label: this.intl.t('settings.senders.outbound-sender-settings.custom-email-table.brand'),
        valuePath: 'brandName',
      });
    }

    columns.push(
      {
        label: this.intl.t('settings.senders.outbound-sender-settings.custom-email-table.status'),
        valuePath: 'status',
      },
      {
        label: this.intl.t('settings.senders.outbound-sender-settings.custom-email-table.domain'),
        valuePath: 'domain',
      },
    );

    return columns;
  }

  get customEmailAddresses() {
    let addresses = this.args.customEmailAddresses.map((address) => {
      address.domain = getDomainName(address.email);
      return address;
    });

    addresses = sortBy(addresses, (address) => address.domain);

    return addresses;
  }

  get domainVerificationMap() {
    let verificationMap = {};

    this.args.dkimSettings.forEach((record) => {
      let customBounceSetting = this.args.customBounceSettings.find((bounceSetting) => {
        return bounceSetting.rootDomain === record.domain;
      });
      verificationMap[record.domain] =
        record.validRecordExists && customBounceSetting.validRecordExists;
    });

    return verificationMap;
  }
}
