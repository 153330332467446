/* import __COLOCATED_TEMPLATE__ from './agent-type-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import type IntlService from 'ember-intl/services/intl';
import type { SelectedFilter } from 'embercom/services/filter-pattern-service';

interface Args {
  selected: SelectedFilter;
  loadKnownValuesSources: string[];
  analyticsObject: string;
  setSelected: (filterValues: string[], operator?: string) => void;
  removeFilter: () => void;
  initiallyOpened: boolean;
  icon: string;
  type: string;
  name: string;
  isDisabled: boolean;
}

interface Item {
  text: string;
  value: string;
  isSelected: boolean;
}

export default class AgentTypeFilter extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;
  @tracked selectedDropdownItems = this.args.selected.values || [];
  @tracked operator = this.args.selected.operator || 'category';

  constructor(owner: unknown, args: $TSFixMe) {
    super(owner, args);
  }

  get agentLabels() {
    return {
      'ai-agent': this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.agent-type-filter.ai-agent',
      ),
      human: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.agent-type-filter.human',
      ),
      workflow: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.agent-type-filter.workflow',
      ),
    } as const;
  }

  get allItems(): Item[] {
    return Object.entries(this.agentLabels).map(([value, text]) => ({
      text,
      value,
      isSelected: this.selectedDropdownItems.includes(value),
    }));
  }

  get selectedAgents() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get currentLabel() {
    if (this.selectedAgents.length === 0) {
      return this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.agent-type-filter.any',
      );
    }

    let agents = this.selectedAgents.map((agent) => {
      return this.agentLabels[agent as keyof typeof this.agentLabels];
    });
    return `${agents.join(', ')}`;
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_agent_rated',
      object: this.args.analyticsObject,
      agents: this.selectedAgents,
      operator: this.operator,
    });
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::AgentTypeFilter': typeof AgentTypeFilter;
    'reporting/custom/chart-builder/filter-bar/agent-type-filter': typeof AgentTypeFilter;
  }
}
