/* import __COLOCATED_TEMPLATE__ from './chart-builder.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import type Chart from 'embercom/models/reporting/custom/chart';
import type Report from 'embercom/models/reporting/custom/report';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { restartableTask, task } from 'ember-concurrency-decorators';
import { getOwner } from '@ember/application';
import RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { isEmpty, isPresent } from '@ember/utils';
import { modifier } from 'ember-modifier';
import { tracked } from '@glimmer/tracking';
import {
  SHOW_TIME_COMPARISON,
  STACKED,
  SHOW_DATA_LABELS,
  TARGET,
  SHOW_RELATIVE_VALUES,
  SHOW_SUMMARY_ROW,
} from 'embercom/models/reporting/custom/visualization-options';
import type Target from 'embercom/models/reporting/custom/target';
import { setDefaultSizesOnChart } from 'embercom/lib/reporting/custom/visualization-type-grid-sizes';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type ReportingUnderlyingDataService from 'embercom/services/reporting-underlying-data-service';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type Router from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type ReportingChartService from 'embercom/services/reporting-chart-service';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import type CustomReportsService from 'embercom/services/custom-reports-service';
import Run from 'embercom/lib/run';

interface Arguments {
  chart: Chart;
  settings: any;
  report: Report;
  route: string;
  editChartRoute?: string;
  isStandalone?: boolean;
}
interface Signature {
  Args: Arguments;
}
export default class ChartBuilder extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare permissionsService: any;
  @service declare reportingChartService: ReportingChartService;
  @service declare intl: IntlService;
  @service declare store: any;
  @service declare router: Router;
  @service declare notificationsService: any;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare reportingUnderlyingDataService: ReportingUnderlyingDataService;
  @service declare customReportsService: CustomReportsService;

  @tracked activeVisualizationTab = 'chart';
  @tracked visualizationOptions = this.startingVisualizationOptions;
  @tracked showSideDrawer = false;
  @tracked showUnderlyingData = false;
  @tracked drillInChartSeries: ChartSeries = this.initializeDrillInChartSeries;
  @tracked selection: string[] = [];
  @tracked previousSelection: string[] = [];
  startingVisualizationType: string = this.args.chart.visualizationType;

  get initializeDrillInChartSeries() {
    return this.args.chart.chartSeries.firstObject;
  }

  get canChangeCustomReports() {
    return this.customReportsService.canChangeCustomReports;
  }

  get renderableChart() {
    let report = {
      settings: this.args.settings,
      timezone: this.timezone,
    };
    return new RenderableChart(this.args.chart, report, getOwner(this));
  }

  get metric() {
    if (this.args.chart.isMultimetric) {
      return this.args.chart.chartSeries.firstObject.metric;
    }
    return this.args.chart.chartSeries.firstObject.metric;
  }

  get chartIsNotNew() {
    return !this.args.chart.isNew;
  }

  get reportTimezone() {
    if (isPresent(this.args.report.timezone)) {
      return this.args.report.timezone;
    }
    return undefined;
  }

  get saveTextForBeta() {
    if (this.args.report?.availableCharts.find((chart: Chart) => chart.id === this.args.chart.id)) {
      return this.intl.t('reporting.custom-reports.chart.update-chart');
    } else {
      return this.intl.t('reporting.custom-reports.chart.add-chart');
    }
  }

  get saveText() {
    if (this.appService.app.canSeeR2Beta) {
      return this.saveTextForBeta;
    } else {
      return this.intl.t('reporting.custom-reports.chart.save-close');
    }
  }
  get timezone() {
    return this.reportTimezone || this.appService.app.timezone;
  }

  resetColors = modifier((_: any) => {
    this.reportingChartService.resetColors();
  });

  get startingVisualizationOptions() {
    return isPresent(this.args.chart.visualizationOptions)
      ? this.args.chart.visualizationOptions
      : this.store.createFragment('reporting/custom/visualization-options');
  }

  get visualizationOptionValues() {
    return {
      [SHOW_TIME_COMPARISON]: this.visualizationOptions.showTimeComparison,
      [STACKED]: this.args.chart.stacked,
      [SHOW_DATA_LABELS]: this.showDataLabelsValue,
      [TARGET]: this.visualizationOptions.target,
      [SHOW_RELATIVE_VALUES]: this.visualizationOptions.showRelativeValues,
      [SHOW_SUMMARY_ROW]: this.visualizationOptions.showSummaryRow,
    };
  }

  get disableTimeComparison() {
    if (this.renderableChart.isCounter) {
      return false;
    }
    return this.args.chart.viewBy !== 'time' || isPresent(this.args.chart.segmentBy);
  }

  get showDataLabelsValue() {
    // dataLabels will apply to all metrics and the value will be the same for all chartSeries.
    // this value is the same for all, no need to iterate through it
    return this.args.chart.chartSeries.firstObject.showDataLabels;
  }

  get sharedAnalyticsData() {
    return {
      object: 'custom_chart',
      custom_chart_id: this.args.chart.id,
      custom_chart_name: this.args.chart.title || 'untitled',
      custom_report_id: this.args.report.id,
      custom_report_name: this.args.report.title || 'untitled',
      currentMetricId: this.args.chart.chartSeries.firstObject.metric.id,
      is_on_reports_1: !this.appService.app.canSeeR2Beta,
    };
  }

  get columns() {
    return this.selection;
  }

  defaultColumns(chartSeries: ChartSeries) {
    return this.reportingUnderlyingDataService.getDefaultColumns(
      chartSeries,
      this.renderableChart,
      this.args.isStandalone,
    );
  }

  get knownValuesEndpointSources() {
    return this.args.chart.chartSeries.map((series: ChartSeries) => series.metric.firstSource);
  }

  get conversationAttributeDescriptors() {
    return this.store.peekAll('conversation-attributes/descriptor');
  }

  @action
  setTarget(target: Target) {
    if (!this.args.chart.isMultimetric) {
      this.trackAnalyticsEvent({
        action: 'edited_target',
      });
      this.visualizationOptions.target = target;
      this.args.chart.visualizationOptions = this.visualizationOptions;
    }
  }

  @action
  updateStacked() {
    this.trackAnalyticsEvent({ action: 'edited_stacked' });
    this.args.chart.toggleStacked();
  }

  @action
  toggleShowDataLabels() {
    this.trackAnalyticsEvent({
      action: 'edited_show_data_labels',
    });
    this.args.chart.toggleShowDataLabels();
  }

  @action
  toggleTimeComparison() {
    this.trackAnalyticsEvent({
      action: 'edited_time_comparison',
    });
    this.visualizationOptions.showTimeComparison = !this.visualizationOptions.showTimeComparison;
    this.args.chart.visualizationOptions = this.visualizationOptions;
  }

  @action
  toggleRelativeValues() {
    this.trackAnalyticsEvent({
      action: 'edited_show_relative_values',
    });
    this.visualizationOptions.showRelativeValues = !this.visualizationOptions.showRelativeValues;
    this.args.chart.visualizationOptions = this.visualizationOptions;
    if (!this.args.chart.stacked) {
      this.args.chart.toggleStacked();
    }
  }

  @action
  toggleSummaryRow() {
    this.trackAnalyticsEvent({ action: 'edited_show_summary_row' });
    this.visualizationOptions.showSummaryRow = !this.visualizationOptions.showSummaryRow;
    this.args.chart.visualizationOptions = this.visualizationOptions;
  }

  @action
  toggleVisualizationOption(option: string) {
    if (option === STACKED) {
      this.updateStacked();
    } else if (option === SHOW_DATA_LABELS) {
      this.toggleShowDataLabels();
    } else if (option === SHOW_TIME_COMPARISON) {
      this.toggleTimeComparison();
    } else if (option === SHOW_RELATIVE_VALUES) {
      this.toggleRelativeValues();
    } else if (option === SHOW_SUMMARY_ROW) {
      this.toggleSummaryRow();
    } else {
      throw new Error(`Unknown visualization option passed to toggle: ${option}`);
    }
  }

  @restartableTask
  *confirmModal(content: any): TaskGenerator<void> {
    return yield this.intercomConfirmService.confirm(content);
  }

  @task({ drop: true }) *postDuplicateChart(): TaskGenerator<void> {
    let result;
    try {
      result = yield this.args.chart.duplicateChart({
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
        id: this.args.chart.id,
        ...(isEmpty(this.args.chart.title) && {
          default_title: this.renderableChart.description,
        }),
      });
      let duplicatedChart = yield this.store.find('reporting/custom/chart', result.id);
      yield this.args.report.saveChart(duplicatedChart);
    } catch (_e) {
      this.notificationsService.notifyError(
        this.intl.t('reporting.custom-reports.chart.more-button.chart-error'),
      );
    }
    return result;
  }

  @task({ drop: true }) *duplicateChart(): TaskGenerator<void> {
    // @ts-ignore
    if (this.args.chart.hasDirtyAttributes) {
      let confirmed = yield this.customReportsService.confirmSave(false);
      if (confirmed === true) {
        yield taskFor(this.performSaveChart).perform();
      } else {
        this.args.chart.rollbackAttributes();
      }
    }
    let duplicatedChart = yield taskFor(this.postDuplicateChart).perform();
    this.trackAnalyticsEvent({
      action: 'duplicated',
    });
    this.notificationsService.notifyConfirmation(
      this.intl.t('reporting.custom-reports.chart.more-button.chart-duplicated'),
    );
    if (this.args.isStandalone) {
      this.router.transitionTo(this.args.editChartRoute!, duplicatedChart.id, {
        queryParams: {
          isStandalone: this.args.isStandalone,
        },
      });
    } else {
      this.router.transitionTo(
        'apps.app.reports.custom-reports.report.show.chart.edit',
        duplicatedChart.id,
      );
    }
  }

  @task({ drop: true }) *deleteChart(): TaskGenerator<void> {
    let confirmationModelContent = {
      title: this.intl.t('reporting.custom-reports.chart.more-button.confirmation-model.title'),
      body: this.intl.t('reporting.custom-reports.chart.more-button.confirmation-model.body'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t(
        'reporting.custom-reports.chart.more-button.confirmation-model.confirm-button-text',
      ),
    };
    let confirm = yield taskFor(this.confirmModal).perform(confirmationModelContent);
    if (!confirm) {
      return;
    }
    this.trackAnalyticsEvent({
      action: 'deleted',
    });

    if (this.appService.app.canSeeR2Beta) {
      yield this.args.report.removeChart(this.args.chart, { save: false });
      this.notificationsService.notifyConfirmation(
        this.intl.t('reporting.custom-reports.chart.more-button.chart-deleted'),
      );
      this.router.transitionTo(this.args.route, this.args.report.id, {
        queryParams: { cr2AddingToChart: true },
      });
    } else {
      yield this.args.report.removeChart(this.args.chart, { save: true });
      this.notificationsService.notifyConfirmation(
        this.intl.t('reporting.custom-reports.chart.more-button.chart-deleted'),
      );
      this.router.transitionTo(this.args.route, this.args.report.id);
    }
  }

  @action
  cancel() {
    // @ts-ignore
    if (this.args.report.isNew) {
      this.router.transitionTo(this.args.route, { queryParams: { cancelling: true } });
    } else {
      this.router.transitionTo(this.args.route, this.args.report.id, {
        queryParams: { cancelling: true },
      });
    }
  }

  addChartToReport() {
    if (this.chartIsNotNew) {
      this.trackAnalyticsEvent({
        action: 'edited_chart_on_report',
        object: 'custom_chart',
      });

      this.args.report.saveChart(this.args.chart);
    } else {
      this.trackAnalyticsEvent({
        action: 'added_chart_to_report',
        object: 'custom_chart',
      });
      this.args.report.saveChart(this.args.chart);
      if (
        // we only want to set the default sizes if no sizes have been set yet
        // we can't use changedAttributes here because the chart is new, so visualizationType will always show as changed
        isEmpty(this.args.chart.gridWidth) ||
        isEmpty(this.args.chart.gridHeight)
      ) {
        setDefaultSizesOnChart(this.args.chart);
      }
    }
  }

  @task({ drop: true }) *saveChartOnChartBuilder() {
    let isFromTemplateReport = this.args.report.isIntercomOwnedReport;
    if (this.chartIsNotNew) {
      this.trackAnalyticsEvent({
        action: 'edited',
        from_template: isFromTemplateReport,
        new_metrics: true,
      });
      yield this.args.report.saveChart(this.args.chart);
    } else {
      yield this.args.report.save();
      yield this.args.report.saveChart(this.args.chart);
      this.trackAnalyticsEvent({
        action: 'saved',
        from_template: isFromTemplateReport,
        new_metrics: true,
      });
    }
  }

  @task({ drop: true }) *performSaveChart() {
    if (this.appService.app.canSeeR2Beta) {
      this.addChartToReport();
    } else {
      yield taskFor(this.saveChartOnChartBuilder).perform();
    }
  }

  @task({ drop: true }) *saveChart() {
    let reportIsNew = this.args.report.get('isNew');
    yield taskFor(this.performSaveChart).perform();
    this.intercomEventService.trackEvent('saved-custom-report', {
      new_metrics: true,
    });
    // standalone does not support new routes yet, the show route is the only one being sent
    let transitionTo = this.args.isStandalone
      ? this.args.route
      : 'apps.app.reports.custom-reports.report.show';
    if (this.appService.app.canSeeR2Beta) {
      this.notificationsService.notifyConfirmation(
        this.intl.t('reporting.custom-reports.chart.chart-added-to-report'),
      );
      this.router.transitionTo(transitionTo, this.args.report.id, {
        queryParams: { cr2AddingToChart: true },
      });
    } else {
      this.notificationsService.notifyConfirmation(
        this.intl.t('reporting.custom-reports.chart.chart-saved'),
      );
      if (reportIsNew) {
        Run.later(
          this,
          () => {
            this.customReportsService.openSharingModal(this.args.report, true);
          },
          1600,
        );
      }
      this.router.transitionTo(transitionTo, this.args.report.id);
    }
  }

  @action
  updateVisualization(value: string) {
    this.trackAnalyticsEvent({
      action: 'edited_vizualization',
      visualization: value,
    });
    this.args.chart.updateMetricVisualization(value);
  }

  @action
  trackAnalyticsEvent(data: any) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      ...data,
    });
  }

  @action
  openSideDrawer() {
    // TODO update this to work per-metric, and pass the correct filters
    this.trackAnalyticsEvent({
      action: 'opened',
      object: 'conversations_list',
      currentFilters: this.args.chart.chartSeries.firstObject.filters,
    });
    this.showSideDrawer = true;
  }

  @action
  openUnderlyingDataModal(selectedChartSeries?: ChartSeries) {
    //TODO: when cleaning up reporting-r2-multimetrics-new-ui make chartSeries required
    let chartSeries: ChartSeries = selectedChartSeries ?? this.args.chart.chartSeries.firstObject;
    if (this.permissionsService.currentAdminCan('can_reporting__drillin__access')) {
      this.trackAnalyticsEvent({
        action: 'opened',
        object: 'underlying_data_modal',
        place: 'chart_builder',
        chart_metric_id: chartSeries.metricId,
      });
      this.setDefaultColumns(chartSeries);
      this.drillInChartSeries = chartSeries;
      this.showUnderlyingData = true;
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_reporting__drillin__access',
      );
    }
  }

  setDefaultColumns(chartSeries: ChartSeries) {
    let defaultSelection = [];
    let cachedSelection: string[] = [];

    if (this.args.chart.id) {
      cachedSelection = this.reportingUnderlyingDataService.loadSelectedColumnFromCache(
        this.args.chart.id,
        chartSeries,
      );
    }

    if (isPresent(cachedSelection)) {
      defaultSelection = cachedSelection;
    } else {
      defaultSelection = this.defaultColumns(chartSeries);
    }
    this.selection = defaultSelection.uniq().compact();
    this.previousSelection = this.selection;
  }

  @action
  onUnderlyingDataCloseEvent() {
    this.showUnderlyingData = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      object: 'underlying_data_modal',
      place: 'chart_builder',
    });
  }

  @action
  resetColumns(chartSeries: ChartSeries) {
    this.selection = this.defaultColumns(chartSeries);
    if (this.args.chart.id) {
      this.reportingUnderlyingDataService.setColumnSelectionInCache(
        this.args.chart.id,
        chartSeries,
        this.selection,
      );
    }
    this.previousSelection = this.selection;
  }

  @action
  setColumns(chartSeries: ChartSeries, columns: string[]) {
    this.selection = this.reportingUnderlyingDataService.getOrderedAttributeIds(
      chartSeries.metric,
      columns,
      this.previousSelection,
    );

    if (this.args.chart.id) {
      this.reportingUnderlyingDataService.setColumnSelectionInCache(
        this.args.chart.id,
        chartSeries,
        this.selection,
      );
    }

    this.previousSelection = this.selection;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder': typeof ChartBuilder;
    'reporting/custom/chart-builder': typeof ChartBuilder;
  }
}
