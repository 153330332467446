/* import __COLOCATED_TEMPLATE__ from './workspace-share.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import EmberObject from '@ember/object';
import type ReportAccessControlList from 'embercom/models/reporting/report-access-control-list';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type Admin from 'embercom/models/admin';

interface Args {
  workspaceAccessControl: ReportAccessControlList | undefined;
  workspaceOptions: DropdownOption[];
}

interface DropdownOption {
  text: string;
  onSelect: () => void;
  description?: string;
  isSelected?: boolean;
  icon: InterfaceIconName;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class WorkspaceShare extends Component<Signature> {
  @service declare appService: $TSFixMe;

  get companyModel() {
    return EmberObject.create({
      id: '0',
      name: this.appService.app.name,
      isNotHuman: true,
      avatarData: {
        color: this.appService.app.base_color,
        isCustomer: false,
        isCompany: true,
        isTeam: false,
      },
    }) as Admin;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::WorkspaceShare': typeof WorkspaceShare;
    'reporting/custom/report/workspace-share': typeof WorkspaceShare;
  }
}
