/* import __COLOCATED_TEMPLATE__ from './ticket-type-configuration.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type TicketType from 'embercom/models/inbox/ticket-type';
import { isPresent } from '@ember/utils';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { type TicketConfigurationFields } from './ticket-type-settings';

interface Args {
  ticketType: TicketType;
  ticketCustomStates?: TicketCustomState[];
  configurations: TicketConfigurationFields;
  updateConfigurations: (value: Partial<TicketConfigurationFields>) => void;
  saveTicketType: () => void;
}

export default class TicketTypeConfiguration extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare notificationsService: any;

  @tracked showDescriptorDetailsModal = false;
  @tracked showDuplicateTicketTypeModal = false;
  @tracked editMode = isPresent(this.args.ticketType);

  @trackedReset('args.configurations.name') name = this.args.configurations.name;
  @trackedReset('args.configurations.description') description =
    this.args.configurations.description;
  @trackedReset('args.configurations.emoji') emoji = this.args.configurations.emoji;
  @trackedReset('args.configurations.internal') internal = this.args.configurations.internal;
  @trackedReset('args.configurations.disableAiAssist') disableAiAssist =
    this.args.configurations.disableAiAssist;
  @trackedReset('args.configurations.category') category = this.args.configurations.category;

  get categoryIcon() {
    switch (this.category) {
      case TicketCategory.Request:
        return 'ticket';
      case TicketCategory.Task:
        return 'back-office';
      case TicketCategory.Tracker:
        return 'tracker';
    }
  }

  get ticketCategories() {
    return [
      {
        items: [
          {
            text: this.intl.t('settings.ticket-data.ticket-type-configuration.category.request'),
            icon: 'ticket',
            value: TicketCategory.Request,
            description: this.intl.t('settings.ticket-data.ticket-type-configuration.info.request'),
          },
          {
            text: this.intl.t('settings.ticket-data.ticket-type-configuration.category.task'),
            icon: 'back-office',
            value: TicketCategory.Task,
            description: this.intl.t('settings.ticket-data.ticket-type-configuration.info.task'),
          },
          {
            text: this.intl.t('settings.ticket-data.ticket-type-configuration.category.tracker'),
            icon: 'tracker',
            value: TicketCategory.Tracker,
            description: this.intl.t('settings.ticket-data.ticket-type-configuration.info.tracker'),
          },
        ],
      },
    ];
  }

  get ticketType() {
    return this.args.ticketType;
  }

  get hasVisibilitySettings() {
    return this.category === TicketCategory.Task;
  }

  get visibilitySettingsDisabled() {
    return this.category === TicketCategory.Request || this.category === TicketCategory.Tracker;
  }

  @action updateDescription(description: string) {
    this.args.updateConfigurations({
      description,
    });
  }

  @action updateInternal(internal: boolean) {
    this.args.updateConfigurations({
      internal,
    });
  }

  @action updateDisableAiAssist() {
    this.args.updateConfigurations({
      disableAiAssist: !this.disableAiAssist,
    });
  }

  @action onCategorySelectionChange(category: TicketCategory) {
    this.category = category;
    let internal =
      this.category === TicketCategory.Tracker ||
      (!this.editMode && this.category === TicketCategory.Task);

    this.args.updateConfigurations({
      category,
      internal,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketTypes::TicketTypeConfiguration': typeof TicketTypeConfiguration;
    'settings/ticket-types/ticket-type-configuration': typeof TicketTypeConfiguration;
  }
}
