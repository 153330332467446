/* import __COLOCATED_TEMPLATE__ from './permissions-editor-with-role-selector.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import currencyFormatter from 'embercom/lib/currency-formatter';
import { getNumberOfSeatsInUse } from 'embercom/lib/admins/seat-info';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import permissionLabelTranslationHelper from 'embercom/helpers/permission-label-translation-helper';
import { COPILOT_PERMISSION } from 'embercom/lib/settings/copilot-access-editor/constants';
import {
  COPILOT_SEAT_TYPE as COPILOT,
  CONVERSATION_SECTION_ID,
  COPILOT_SECTION_ID,
} from 'embercom/lib/settings/seats/constants';

export default class PermissionsEditor extends Component {
  @service appService;
  @service customerService;
  @service intl;
  @tracked numberOfSeatsInUse = 0;
  @service permissionsHierarchyService;
  @tracked openSections = {
    [CONVERSATION_SECTION_ID]: false,
    [COPILOT_SECTION_ID]: true,
  };
  @tracked lastAllAccordionOpenedState = false;

  modifiedAdminInitialPermissions = { ...this.args.permissionsObject.permissions() };
  unassignableRoles;

  constructor() {
    super(...arguments);
    this.customerService.ensureDataIsLoaded.perform();
    this.requestNumberOfSeatsInUse.perform();

    if (this.appService.app.cannotAssignWiderAccess) {
      this.unassignableRoles = this._calculateUnownedPermissions();
    }
    if (
      this.args.isInvite &&
      !this.args.isEditInvite &&
      !this.appService.app.canUsePerProductPricingFlow
    ) {
      this.args.permissionsObject[COPILOT_PERMISSION] = true;
    }
  }

  get shouldShowInboxSeatToggle() {
    let app = this.args.app;
    let permissionsObject = this.args.permissionsObject;
    return (
      !this.args.hideInboxToggle &&
      permissionsObject.id !== undefined &&
      app.inboxIsActive &&
      app.requiresInboxSeatAccess
    );
  }

  get shouldShowRoleSelector() {
    return !!this.args.roles;
  }

  get usingRole() {
    return this.shouldShowRoleSelector && this.args.permissionsObject.role !== null;
  }

  get numberOfTeammates() {
    return this.args.numberOfTeammates || 1;
  }

  @task
  *requestNumberOfSeatsInUse() {
    try {
      let numberOfSeatsInUseInfo = yield getNumberOfSeatsInUse();
      this.numberOfSeatsInUse = numberOfSeatsInUseInfo.totalNumberOfSeatsInUse;
    } catch (err) {
      this.numberOfSeatsInUse = 0;
    }
  }

  get numberOfAdditionalSeatsRequired() {
    if (this.numberOfSeatsInUse < this.customerService.customer.freeSeatCount) {
      return (
        this.numberOfSeatsInUse -
        this.customerService.customer.freeSeatCount +
        this.numberOfTeammates
      );
    } else {
      return this.numberOfTeammates;
    }
  }

  get formattedPricePerSeat() {
    return currencyFormatter(this.customerService.customer.pricePerSeat);
  }

  get shouldShowAdditionalSeatsPriceBadge() {
    return (
      this.appService.app.canUseSeatOverages &&
      !this.appService.app.canUsePerProductPricingFlow &&
      this.appService.app.isSalesforceContracted &&
      this.args.shouldShowAdditionalSeatsCostChange &&
      this.numberOfAdditionalSeatsRequired > 0
    );
  }

  get roles() {
    return (this.args.roles || []).sortBy('name');
  }

  get rolesGroupList() {
    let roleItems = this.roles.map((role) => {
      return {
        text: role.name,
        value: role,
        isDisabled: this.unassignableRoles && this.unassignableRoles[role.id]?.isDisabled,
        tooltipText: this.unassignableRoles && this.unassignableRoles[role.id]?.tooltipText,
      };
    });
    return [
      {
        items: [{ text: this.intl.t('settings.teammates.role.no-role'), value: null }],
      },
      {
        heading: this.intl.t('settings.teammates.role.roles'),
        items: roleItems,
      },
    ];
  }

  get shouldShowCopilotAccessEditor() {
    return this.appService.app.canUseFinAiCopilotAddon;
  }

  @action
  onChangeRole(role) {
    let conversationAccess;

    if (role === null && !this.args.permissionsObject.isNew) {
      this.args.permissionsObject.rollbackAttributes();
      conversationAccess = {};
    } else {
      conversationAccess = {
        accessType: role?.accessType,
        includeUnassigned: role?.includeUnassigned,
        assigneeBlockedList: role?.assigneeBlockedList,
      };

      if (!role?.can_inbox__access_copilot) {
        this._removeCopilotSeatAndPermission();
      }
    }

    Object.assign(this.args.permissionsObject, role?.permissions(), conversationAccess, {
      role_id: role?.id || null,
    });
  }

  _cannotAssignRolePermission(rolePermission, adminPermission) {
    return rolePermission !== adminPermission && adminPermission === false;
  }

  _cannotAssignPermissionOnAdmin(perm, adminPermission) {
    return this.modifiedAdminInitialPermissions[perm] === true && adminPermission === false;
  }

  _getUnownedPermissionsForRole(selectedRoleId, role, permissionKeys, adminPermissions) {
    let unownedPermissions = [];

    if (selectedRoleId && selectedRoleId === role.id) {
      return unownedPermissions;
    }
    permissionKeys.forEach((perm) => {
      if (
        this._cannotAssignRolePermission(role[perm], adminPermissions[perm], unownedPermissions) ||
        this._cannotAssignPermissionOnAdmin(perm, adminPermissions[perm], unownedPermissions)
      ) {
        unownedPermissions.push(perm);
      }
    });

    return unownedPermissions;
  }

  _translatePermissions(permissions) {
    if (permissions.length > 3) {
      return this.intl.t('settings.teammates.role.permissions-needed-long', {
        permissionsCount: permissions.length - 3,
        permissionsList: permissions
          .slice(0, 3)
          .map((perm) => permissionLabelTranslationHelper(perm))
          .join(', '),
      });
    }

    return `${this.intl.t('settings.teammates.role.permissions-needed', {
      permissionsCount: permissions.length,
    })} ${permissions.map((perm) => permissionLabelTranslationHelper(perm)).join(', ')}`;
  }

  _calculateUnownedPermissions() {
    let unassignableRolesList = {};
    let permissionKeys = this.args.roles.length
      ? Object.keys(this.args.roles.firstObject.permissions())
      : [];
    let adminPermissions = this.appService.app.currentAdmin.currentAppPermissions;
    let selectedRoleId = this.args.permissionsObject.role
      ? this.args.permissionsObject.role.id
      : false;

    this.args.roles?.forEach((role) => {
      let unownedPermissions = this._getUnownedPermissionsForRole(
        selectedRoleId,
        role,
        permissionKeys,
        adminPermissions,
      );
      if (unownedPermissions.length && role.id !== selectedRoleId) {
        unassignableRolesList[role.id] = {
          isDisabled: true,
          tooltipText: this._translatePermissions(unownedPermissions),
        };
      }
    });
    return unassignableRolesList;
  }

  _assignSeat(seatType) {
    this.args.seatTypes.pushObject(seatType);
  }

  _removeSeat(seatType) {
    this.args.seatTypes.removeObject(seatType);
  }

  @action
  toggleSeat(seatType) {
    this.args.seatTypes.includes(seatType)
      ? this._removeSeat(seatType)
      : this._assignSeat(seatType);
  }

  @action
  onToggleInboxSeat() {
    if (!this.args.permissionsObject.has_inbox_access) {
      this._removeCopilotSeatAndPermission();
    }
  }

  _removeCopilotSeatAndPermission() {
    if (this.args.seatTypes.includes(COPILOT)) {
      this._removeSeat(COPILOT);
    }
    if (this.args.permissionsObject[COPILOT_PERMISSION]) {
      this.args.permissionsObject[COPILOT_PERMISSION] = false;
    }
  }

  @action
  onClickOpenSection(sectionId) {
    this.openSections = {
      ...this.openSections,
      [sectionId]: !this.openSections[sectionId],
    };
  }

  get isSelectAllState() {
    return this._doesNotHaveAllPermissions();
  }

  get disableToggleAllPermissionsButton() {
    return this.args.permissionsObject.role !== null;
  }

  get showToggleAllPermissionsButton() {
    return !this.usingRole;
  }

  _doesNotHaveAllPermissions() {
    let permissions = { ...this.args.permissionsObject.permissions() };
    let permissionsKeys = Object.keys(permissions);
    return permissionsKeys.some((perm) => permissions[perm] === false);
  }

  @action toggleSelectAll() {
    let permissions = { ...this.args.permissionsObject.permissions() };

    let selectAllPermissionsState = !!this.isSelectAllState;
    Object.keys(permissions).forEach((key) => {
      permissions[key] = selectAllPermissionsState;
    });

    Object.assign(this.args.permissionsObject, permissions);
  }

  @action setAllAccordions(state) {
    this.lastAllAccordionOpenedState = state;
    let sections = Object.values(this.permissionsHierarchyService.sections);
    let clonedOpenSections = {
      ...this.openSections,
      [CONVERSATION_SECTION_ID]: state,
      [COPILOT_SECTION_ID]: state,
    };
    sections.forEach((section) => {
      clonedOpenSections[section.key] = state;
    });
    this.openSections = clonedOpenSections;
  }

  get disabledTooltipContent() {
    if (!this.args.seatTypes?.length) {
      if (this.appService?.app.onPricing5) {
        return this.intl.t(
          'settings.teammates.permissions.permissions-editor-with-seats-selector.assign-one-pricing5-seat',
          { htmlSafe: true },
        );
      } else {
        return this.intl.t(
          'settings.teammates.permissions.permissions-editor-with-seats-selector.assign-one-seat',
          { htmlSafe: true },
        );
      }
    }

    return this.intl.t(
      'components.settings.teammates.conversation-access-editor.change-conversation-access',
      { htmlSafe: true },
    );
  }
}
