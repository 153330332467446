/* import __COLOCATED_TEMPLATE__ from './tickets-onboarding.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

interface Args {}

export default class TicketsOnboarding extends Component<Args> {
  @service declare router: RouterService;
  @service declare appService: any;

  @tracked showModal = false;
  @tracked modalCategory: TicketCategory = TicketCategory.Request;

  @action routeToTicketsSettings() {
    this.router.transitionTo('apps.app.settings.helpdesk.tickets', {
      queryParams: { tab: 'ticket-types' },
    });
  }

  @action seeExample(category: TicketCategory) {
    this.modalCategory = category;
    this.showModal = true;
  }

  @action closeModal() {
    this.showModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketData::TicketsOnboarding::TicketsOnboarding': typeof TicketsOnboarding;
    'settings/ticket-data/tickets-onboarding/tickets-onboarding': typeof TicketsOnboarding;
  }
}
