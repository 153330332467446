/* import __COLOCATED_TEMPLATE__ from './legacy-heatmap.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import HeatmapBuilder from 'embercom/lib/reporting/flexible/heatmap-builder';
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { cached } from 'tracked-toolbox';

export default class LegacyHeatmap extends Component {
  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.args.dataConfig,
    viewConfig: this.args.viewConfig,
  }));

  @cached
  get chartData() {
    return this.dataResource.rawChartData.map((response) => {
      let days = response.groups[0].values;
      let data = days.reduce((acc, curr, dayIndex) => {
        let hourValues = curr.groups[0].aggregations[0].values;
        let formattedValues = hourValues.map((value, hourIndex) => ({
          x: hourIndex,
          y: dayIndex,
          value: value || 0,
        }));
        return acc.concat(formattedValues);
      }, []);
      return {
        name: response.name,
        data,
        showInLegend: false,
      };
    });
  }

  get chartOptions() {
    let builder = new HeatmapBuilder({
      range: this.args.range,
      chartData: this.chartData,
      width: this.args.width,
      viewConfig: this.args.viewConfig,
    });
    return builder.buildTheme();
  }

  get hasData() {
    return this.chartData.some((singleSeries) => {
      return singleSeries.data.some(({ value }) => value);
    });
  }
}
