/* import __COLOCATED_TEMPLATE__ from './permissions-editor-using-hierarchy.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { PERMISSIONS_EXCLUDED_FROM_TOGGLED_PERMISSIONS } from 'embercom/models/model-with-permissions';

export default class PermissionsEditorUsingHierarchy extends Component {
  @service appService;
  @service permissionsHierarchyService;

  constructor() {
    super(...arguments);
    if (this.args.defaultToFullAccess) {
      this.toggleAllPermissions(undefined, true);
    }
  }

  get app() {
    return this.appService.app;
  }

  get sections() {
    let sections = Object.values(this.permissionsHierarchyService.sections);

    return sections.reduce((updatedSections, section) => {
      if (section.key === 'inbox') {
        section.title = 'help-desk-section-title';
      }
      if (
        (section.children_ids && section.children_ids.length > 0) ||
        this.showDefaultPredicatesEditor(section, this.args.permissionsObject)
      ) {
        updatedSections.push(section);
      }
      return updatedSections;
    }, []);
  }

  showDefaultPredicatesEditor(section, permissionsObject) {
    return (
      section.key === 'outbound' &&
      (permissionsObject.role_id || permissionsObject.defaultPredicatesGroup)
    );
  }

  get isSelectAllState() {
    if (this.args.noSeatAssigned && !this.app.hasMultipleSeatTypesPaywalled) {
      return true;
    }

    return this._doesNotHaveAllPermissions();
  }

  get showToggleAllPermissionsButton() {
    return (
      this.args.isInvite === true ||
      this.args.isNewRole === true ||
      this.args.disableToggles === false
    );
  }

  get disableToggleAllPermissionsButton() {
    if (this.args.isNewRole) {
      return false;
    }

    if (this.args.noSeatAssigned) {
      return !this.app.hasMultipleSeatTypesPaywalled;
    }
    return this.args.permissionsObject.role !== null;
  }

  _doesNotHaveAllPermissions() {
    let permissions = { ...this.args.permissionsObject.permissions() };
    let permissionsKeys = Object.keys(permissions);

    let permissionDisabled = (perm) =>
      permissions[perm] === false && !PERMISSIONS_EXCLUDED_FROM_TOGGLED_PERMISSIONS.includes(perm);

    if (this.args.isNewRole) {
      return permissionsKeys.some(permissionDisabled);
    }

    if (this.args.unallowedPermissionList) {
      if (this.args.unallowedPermissionList.length >= permissionsKeys.length) {
        return true;
      }
      return permissionsKeys.some(
        (perm) => permissionDisabled(perm) && !this.args.unallowedPermissionList.includes(perm),
      );
    }

    return permissionsKeys.some(permissionDisabled);
  }

  @action toggleAllPermissions(_, state) {
    let permissions = { ...this.args.permissionsObject.permissions() };

    let selectAllPermissionsState = !!this.isSelectAllState;
    if (state !== undefined) {
      selectAllPermissionsState = !!state;
    }
    Object.keys(permissions).forEach((key) => {
      if (!PERMISSIONS_EXCLUDED_FROM_TOGGLED_PERMISSIONS.includes(key)) {
        permissions[key] = selectAllPermissionsState;
      }
    });

    Object.assign(this.args.permissionsObject, permissions);
    if (this.args.toggleOffDisallowedPermissions) {
      this.args.toggleOffDisallowedPermissions();
    }
  }

  @action
  updatePermission(key, value) {
    this.args.permissionsObject[`can_${key}`] = value;
  }

  // sectionCurrentState: This function calculates the state of a section.
  // This is done by counting the number of permissions that are in the section,
  // their children and their state ( disabled, ON, OFF ) and comparing with the total.
  @action
  sectionCurrentState(permissionSection, countDisabled = false) {
    let permissions = this.args.permissionsObject.permissions();
    let totalNumberOfPermissions = permissionSection.children_ids.reduce((acc, c) => {
      let counter = 1;
      let permission = this.permissionsHierarchyService.permissionsHierarchy[c];
      permission?.children_ids?.forEach((child) => {
        if (this.permissionsHierarchyService.permissionsHierarchy[child]['type'] === 'toggle') {
          counter++;
        }
        if (this.permissionsHierarchyService.permissionsHierarchy[child]['type'] === 'crud') {
          counter +=
            this.permissionsHierarchyService.permissionsHierarchy[child]['actions']?.length || 0;
        }
      });
      return acc + counter;
    }, 0);

    let totalAllowed = permissionSection.children_ids.reduce((acc, c) => {
      let counter = 1;
      let permission = this.permissionsHierarchyService.permissionsHierarchy[c];
      permission?.children_ids?.forEach((child) => {
        if (this.permissionsHierarchyService.permissionsHierarchy[child]['type'] === 'toggle') {
          counter += permissions[`can_${child}`] ? 1 : 0;
        }
        this.permissionsHierarchyService.permissionsHierarchy[child]['actions']?.forEach(
          (possibleAction) => {
            if (permissions[`can_${possibleAction}`]) {
              counter++;
            }
          },
        );
      });
      return permissions[`can_${c}`] ? acc + counter : acc;
    }, 0);

    // Permissions conflict when bulk editing multiple teammates and its permission differs between them
    // In the UI we show the "Change" button to show that permissions differ
    // In the code, the permission object has the key but the value is undefined
    let totalConflicts = permissionSection.children_ids.reduce((acc, c) => {
      let foundConflict = permissions[`can_${c}`] === undefined;
      let counter = foundConflict ? 1 : 0;

      let permission = this.permissionsHierarchyService.permissionsHierarchy[c];
      permission?.children_ids?.forEach((child) => {
        let childPermission = this.permissionsHierarchyService.permissionsHierarchy[child];
        let foundChildConflict =
          childPermission['type'] === 'toggle' && permissions[`can_${child}`] === undefined;
        counter += foundChildConflict ? 1 : 0;
      });

      return acc + counter;
    }, 0);
    // Whenever there's at least one conflict, the section should use incomplete state (aka 'Custom')
    if (totalConflicts > 0) {
      return 'incomplete';
    }
    let totalDisabled = countDisabled
      ? permissionSection.children_ids.reduce((acc, c) => {
          let counter = this.args.unallowedPermissionList?.includes(`can_${c}`) ? 1 : 0;
          let permission = this.permissionsHierarchyService.permissionsHierarchy[c];
          permission?.children_ids?.forEach((child) => {
            if (this.args.unallowedPermissionList?.includes(`can_${child}`)) {
              counter += 1;
            }
          });
          return acc + counter;
        }, 0)
      : 0;

    let atLeastOnePermissionEnabled = !!totalAllowed;
    let allEnabledAreOn = totalNumberOfPermissions === totalAllowed + totalDisabled;
    if (atLeastOnePermissionEnabled && allEnabledAreOn) {
      return 'complete';
    }
    if (totalAllowed === 0) {
      return 'none';
    }
    return 'incomplete';
  }

  @action _toggleSectionPermissions(permissionSection) {
    let permissions = { ...this.args.permissionsObject.permissions() };
    let currentState = this.sectionCurrentState(permissionSection, true) === 'complete';
    permissionSection.children_ids.forEach((c) => {
      let permission = this.permissionsHierarchyService.permissionsHierarchy[c];
      permissions[`can_${c}`] = !currentState;
      permission?.children_ids?.forEach((child) => {
        if (this.permissionsHierarchyService.permissionsHierarchy[child]['type'] === 'toggle') {
          permissions[`can_${child}`] = !currentState;
        }
      });
    });

    Object.assign(this.args.permissionsObject, permissions);
    if (this.args.toggleOffDisallowedPermissions) {
      this.args.toggleOffDisallowedPermissions();
    }
  }

  @action toggleSectionPermissions(permissionSection) {
    return () => {
      this._toggleSectionPermissions(permissionSection);
    };
  }
}
