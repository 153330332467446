/* import __COLOCATED_TEMPLATE__ from './table-csat-cell.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import percent, { toPercentString } from 'embercom/lib/percentage-formatter';

export default class CSATCellComponent extends Component {
  complexValue = this.args.row['col-group-csat'];

  constructor() {
    super(...arguments);
  }

  get hasValue() {
    return this.complexValue && this.complexValue.total;
  }

  get percentage() {
    let rawPercentage = percent(this.complexValue.total, this.complexValue.value || 0);
    return toPercentString(rawPercentage, 2);
  }

  get total() {
    return this.complexValue.total;
  }
}
