/* import __COLOCATED_TEMPLATE__ from './custom-report-header.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Report from 'embercom/models/reporting/custom/report';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import type ReportSubscriptionService from 'embercom/services/report-subscription-service';
import { task } from 'ember-concurrency-decorators';
import type FavoritesService from 'embercom/services/favorites-service';
import { taskFor } from 'ember-concurrency-ts';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { type Router } from '@ember/routing';
import { type TaskGenerator } from 'ember-concurrency';
import type CustomReportsService from 'embercom/services/custom-reports-service';
import type ReportAccessService from 'embercom/services/report-access-service';

interface Args {
  report: Report;
  openSubscriptionModal: () => void;
  closeSubscriptionModal: () => void;
  sharedAnalyticsData: Record<string, string>;
  saveReport: () => TaskGenerator<void>;
  duplicateReport: () => TaskGenerator<void>;
  deleteReport: () => TaskGenerator<void>;
  addChart: (buttonPosition: string) => Promise<void>;
  openChartTemplateSidePanel: () => void;
  canEditReportOrReportIsNew: boolean;
}

export default class ReportingCustomReportLegacyComponentCustomReportHeader extends Component<Args> {
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare reportSubscriptionService: ReportSubscriptionService;
  @service declare favoritesService: FavoritesService;
  @service permissionsService: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service notificationsService: $TSFixMe;
  @service appService: $TSFixMe;
  @service declare router: Router;
  @service declare customReportsService: CustomReportsService;
  @service declare reportAccessService: ReportAccessService;

  @task({ drop: true })
  *saveReport() {
    yield taskFor(this.args.saveReport).perform();
  }

  @task({ drop: true })
  *deleteReport() {
    yield taskFor(this.args.deleteReport).perform();
  }

  @task({ drop: true })
  *duplicateReport() {
    yield taskFor(this.args.duplicateReport).perform();
  }

  @action
  trackAnalyticsEvent(data: Record<string, string | boolean>) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.sharedAnalyticsData,
      ...data,
    });
  }

  @action
  async addChart() {
    await this.args.addChart('header');
  }

  async transitionToNewChartPage() {
    if (this.isNewReport(this.args.report)) {
      this.router.transitionTo('apps.app.reports.custom-reports.report.new.chart.new', {
        queryParams: { source: 'custom_report' },
      });
    } else {
      this.router.transitionTo(
        'apps.app.reports.custom-reports.report.show.chart.new',
        this.args.report.id,
        {
          queryParams: { source: 'custom_report' },
        },
      );
    }
  }

  isNewReport(report: Report) {
    return report.isNew;
  }

  get saveEnabled() {
    if (this.isNewReport(this.args.report)) {
      return this.args.report.title !== undefined || this.args.report.description !== undefined;
    } else {
      return this.args.report.hasDirtyAttributes;
    }
  }

  get saveEnabledAndCanMakeEditableSaves() {
    return this.saveEnabled && this.args.canEditReportOrReportIsNew;
  }

  get canChangeCustomReports() {
    return this.customReportsService.canChangeCustomReports;
  }

  get canDeleteCustomReports() {
    return this.customReportsService.canDeleteReport;
  }

  @action
  loadAccessReportModal() {
    this.reportAccessService.loadReportAccessModal(this.args.report.id, () => {});
  }

  get canEditReportOrReportIsNew() {
    return this.reportAccessService.canEditReportOrReportIsNew(this.args.report);
  }

  get customDateRange() {
    return this.args.report.range?.selectedRange === 'custom';
  }

  get scheduleButtonTooltipDisabled() {
    return !this.customDateRange;
  }

  get currentAdminIsOwner() {
    //TODO: maybe move this into subscription service this.reportSubscriptionService.isOwner(currentAdmin)
    return (
      this.appService.app.currentAdmin.id === this.reportSubscriptionService.subscription?.adminId
    );
  }

  @action
  scheduleReport() {
    if (this.isReportSubscriptionInEditMode) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        object: 'report_schedule_edit_modal',
        is_owner: this.currentAdminIsOwner,
        custom_report_id: this.args.report.id,
      });
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        object: 'report_schedule_create_modal',
        custom_report_id: this.args.report.id,
      });
    }
    this.args.openSubscriptionModal();
  }

  get isReportSubscriptionInEditMode() {
    let subscription = this.reportSubscriptionService.subscription;
    return isPresent(subscription) ? !subscription?.isNew : false;
  }

  get isFavorite() {
    return this.favoritesService.isFavoriteReport(this.args.report.id);
  }

  @task({ drop: true })
  *addOrRemoveFavorite() {
    yield taskFor(this.favoritesService.addOrRemoveFavorite).perform(this.args.report.id);
  }

  @action
  loadAccessModal() {
    this.reportAccessService.loadReportAccessModal(this.args.report.id, () => {});
  }

  get lockLabel() {
    // update this as part of https://github.com/intercom/intercom/issues/356630
    if (this.reportAccessService.adminIsRestricted(this.args.report)) {
      return 'reporting.custom-reports.report.share-modal.view-only';
    } else {
      return 'reporting.custom-reports.report.share-modal.explore-only';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::LegacyComponent::CustomReportHeader': typeof ReportingCustomReportLegacyComponentCustomReportHeader;
    'reporting/custom/report/legacy-component/custom-report-header': typeof ReportingCustomReportLegacyComponentCustomReportHeader;
  }
}
