/* import __COLOCATED_TEMPLATE__ from './standard.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

const SHOW_TRUNCATION_TOOLTIP_CHAR_COUNT = 50;
export default class Standard extends Component {
  @service seriesEditorService;
  @service appService;
  errorIcon = 'series/content/error';

  get hasShortTitle() {
    return this.args.seriesNode.title.length < SHOW_TRUNCATION_TOOLTIP_CHAR_COUNT;
  }

  get graph() {
    return this.seriesEditorService?.graph;
  }

  get isActive() {
    return this.args.seriesNode === this.seriesEditorService.activeSeriesNode;
  }

  get isConnectableNode() {
    if (!this.graph) {
      return;
    }

    if (!this.graph.state.draggingEdge) {
      return false;
    }

    if (this.graph.state.hoveredNodes.firstObject !== this.args.graphNode) {
      return false;
    }

    return this.graph.hooks.canInsertEdge(
      this.graph.state.draggingEdge.predecessor,
      this.args.graphNode,
    );
  }

  get hasAccessToRequiredFeature() {
    return this.args.config.hasAccessToRequiredFeature(this.appService.app);
  }

  @action
  onNodeClick(event) {
    if (event.shiftKey || event.metaKey) {
      let graph = this.seriesEditorService.graph;
      if (graph.state.selectedNodes.includes(this.args.graphNode)) {
        graph.state.removeSelectedNode(this.args.graphNode);
      } else {
        graph.state.addSelectedNode(this.args.graphNode);
      }
    } else {
      this.seriesEditorService.registerActiveSeriesNode(this.args.seriesNode);
    }
  }

  get showError() {
    return (
      !!this.args.config.customErrorMessage ||
      (this.seriesEditorService.canShowErrorOnNode(this.args.seriesNode) &&
        this.args.seriesNode.hasErrors)
    );
  }

  get showEntryRuleTooltip() {
    if (this.args.seriesNode.isCondition) {
      return this.args.seriesNode.isStarting;
    }

    return false;
  }

  get isNewlyAddedNode() {
    return this.seriesEditorService.series.latestChangelog?.trackedChanges.newNodeIds?.includes(
      Number(this.args.seriesNode.id),
    );
  }
}
