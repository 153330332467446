/* RESPONSIBLE TEAM: team-help-desk-experience */
import { VISIBILITY_FILTER_TYPE } from './filter-types';
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';

// eslint-disable-next-line @intercom/intercom/no-component-inheritance
export class AnyVisibilityFilter {
  @service intl;

  constructor(owner) {
    setOwner(this, owner);
    this.visibilityFilterType = VISIBILITY_FILTER_TYPE.ANY;
    this.anyVisibilityFilterValue = { type: this.visibilityFilterType, value: 'any' };
  }

  filterSavedReplies(savedReplies) {
    return savedReplies;
  }

  filterLabel() {
    return this.intl.t('inbox.manage-macros.filter.label');
  }

  items() {
    return [
      {
        items: [
          {
            text: this.intl.t('inbox.manage-macros.filter.no-filter'),
            value: this.anyVisibilityFilterValue,
          },
        ],
      },
    ];
  }
}
