/* import __COLOCATED_TEMPLATE__ from './closed-reopened-conversations-columnchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  CARDINALITY,
  STATE_START,
  COMMENT_CREATED_AT,
  CLOSES_WITH_USER_PART_FILTERS,
  CONVERSATION_ID,
  CONVERSATION_STATE,
  PREVIOUS_CONVERSATION_STATE,
  TIME,
} from 'embercom/lib/reporting/flexible/constants';
import PALETTE from '@intercom/pulse/lib/palette';
import { inject as service } from '@ember/service';

export default class ClosedReopendConversationsColumnchart extends Component {
  @service intl;

  get modifiedFilters() {
    let newFilters = { ...this.args.filters };

    if (newFilters.admin_participant_ids) {
      newFilters.teammate_id = newFilters.admin_participant_ids;
      delete newFilters.admin_participant_ids;
    }

    return newFilters;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.aggregations = { defaultOption: CARDINALITY };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[TIME],
        createTitleTooltipText: () =>
          this.intl.t('reporting.conversations-report.closed-vs-reopened-conversations.tooltip'),
      },
    };
    viewConfig.formatUnit = {
      unit: units.value,
      displayUnit: 'conversation',
    };
    viewConfig.legend = {
      closed_conversations_columnchart: () =>
        this.intl.t(
          'reporting.conversations-report.closed-vs-reopened-conversations.legend.closed',
        ),
      reopened_conversations_columnchart: () =>
        this.intl.t(
          'reporting.conversations-report.closed-vs-reopened-conversations.legend.reopened',
        ),
    };
    viewConfig.seriesColors = [PALETTE['vis-slateblue-50'], PALETTE['vis-turquoise-50']];

    return viewConfig;
  }
  get dataConfig() {
    return {
      xAxis: {
        type: 'temporal',
        data: {
          interval: FILTER_PROPERTY_PLACEHOLDER,
          property: TIME,
        },
      },
      series: [
        {
          name: 'closed_conversations_columnchart',
          source: 'consolidated_conversation_part',
          type: CARDINALITY,
          data: { property: CONVERSATION_ID },
          time: {
            property: COMMENT_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'and',
            filters: CLOSES_WITH_USER_PART_FILTERS,
          },
        },
        {
          name: 'reopened_conversations_columnchart',
          source: 'conversation_state',
          type: CARDINALITY,
          data: { property: CONVERSATION_ID },
          time: {
            property: STATE_START,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'and',
            filters: [
              {
                type: 'category',
                data: {
                  property: CONVERSATION_STATE,
                  values: ['opened'],
                },
              },
              {
                type: 'category',
                data: {
                  property: PREVIOUS_CONVERSATION_STATE,
                  values: ['closed'],
                },
              },
            ],
          },
        },
      ],
    };
  }
}
