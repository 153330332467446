/* import __COLOCATED_TEMPLATE__ from './auto-reply-customization.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import {
  BaseConfig,
  BlocksDocument,
  EMOJI_TYPEAHEAD,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_MARKDOWN_ANCHOR,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
} from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import {
  INLINE_CONTROL_ALIGNMENT,
  INLINE_CONTROL_BLOCK_TYPES,
} from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import { task } from 'ember-concurrency-decorators';

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  customStylesConfig = {
    allowCustomStyles: true,
    formattingSidebarClass: 'email-composer-formatting-side-bar',
  };
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = true;
  allowedBlocks = [
    'paragraph',
    'image',
    'orderedList',
    'unorderedList',
    'heading',
    'subheading',
    'codeBlock',
    'button',
    'html',
  ];
  emojiInserter = 'composer/emoji-inserter';
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  hideFromBlockFormatter = [INLINE_CONTROL_ALIGNMENT];
  hideFromTextFormatter = [INLINE_CONTROL_BLOCK_TYPES, INLINE_CONTROL_ALIGNMENT];
  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_EMOJI,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];
  placeholder = '';
  textDirection = 'ltr';
  tools = [
    { name: 'text-formatter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'button-editor', options: { showHelpLinkHeader: true } },
    { name: 'media-inserter' },
    { name: 'html/edit-button' },
    { name: 'image-editor', options: { supportAltAttributeEditing: true } },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];
  updateImages = true;
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    allowedAttachmentTypes: ['*'],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
  };

  constructor(app) {
    super();
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
  }
}

export default class AutoReplyCustomizationComponent extends Component {
  @service notificationsService;
  @service appService;
  @service intercomEventService;
  @service store;
  @service router;
  @service intl;

  @tracked blocksDocument;
  @tracked selectedLanguage;

  constructor() {
    super(...arguments);
    this.selectedLanguage = this.defaultLanguage;
    this.blocksDocument = new BlocksDocument(this.current.blocks);
  }

  get composerConfig() {
    return new ComposerConfig(this.appService.app);
  }

  get current() {
    return this.args.settings.emailLanguages.find(
      (emailLanguage) => emailLanguage.language === this.selectedLanguage,
    );
  }

  get defaultLanguage() {
    let match = this.args.settings.emailLanguages.find(
      (emailLanguage) => emailLanguage.default === true,
    );
    return match ? match.language : 'en';
  }

  @action
  updateSelectedLanguage(language) {
    if (language === 'messenger-settings') {
      this.router.transitionTo('apps.app.messenger');
      return;
    }

    this.current.rollbackAttributes();
    this.selectedLanguage = language;

    this.blocksDocument = new BlocksDocument(this.current.blocks);
  }

  @task({ restartable: true })
  *saveLocalizedContents() {
    try {
      let response = yield this.args.settings.save();
      this.store.pushPayload({ 'email-auto-reply-settings': response });
      this.intercomEventService.trackAnalyticsEvent({
        action: 'customized',
        object: 'email_auto_reply_settings',
        place: 'email_auto_reply',
        section: 'settings',
      });

      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.auto_reply.email-updated', {
          languageName: this.current.languageName,
        }),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  save() {
    this.saveLocalizedContents.perform();
  }

  // MARK: Formatting

  createBlocksFromSmsContent(smsContent) {
    return [
      {
        type: 'paragraph',
        text: smsContent || '',
        modelKey: 'common/blocks/paragraph',
      },
    ];
  }

  getSmsContentFromBlocks(blocksDoc) {
    return blocksDoc.blocks[0].text;
  }

  @action
  updateBlocks(blocksDoc) {
    this.current.blocks = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
  }

  @action
  resetContent() {
    this.current.rollbackAttributes();
    this.blocksDocument = new BlocksDocument(this.current.blocks);
  }
}
