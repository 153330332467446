/* import __COLOCATED_TEMPLATE__ from './archive-ticket-custom-state-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { task } from 'ember-concurrency-decorators';
import type Router from '@ember/routing/router-service';
import type InboxApi from 'embercom/services/inbox-api';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type Session from 'embercom/services/session';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type TicketStateService from 'embercom/services/ticket-state-service';

interface Signature {
  Element: HTMLSpanElement;
  Args: {
    ticketCustomStateToArchive: TicketCustomState | undefined;
    onModalClose: () => void;
    onArchivedState: () => void;
  };
}

export default class ArchiveTicketCustomStateModal extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare router: Router;
  @service declare inboxApi: InboxApi;
  @service declare session: Session;
  @service declare ticketStateService: TicketStateService;

  @tracked dependentObjects: {
    ticketTypes: any[];
    workflows: any[];
  } = {
    ticketTypes: [],
    workflows: [],
  };

  @tracked hasDependentObjects = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.fetchDependentObjects).perform();
  }

  get modalTitle() {
    return this.canArchive
      ? this.intl.t(
          'settings.ticket-states.archive-ticket-state-modal.archive-confirmation.title',
          {
            ticketCustomState: this.args.ticketCustomStateToArchive?.adminLabel,
          },
        )
      : this.intl.t('settings.ticket-states.archive-ticket-state-modal.title');
  }

  get isChecking() {
    return (this.fetchDependentObjects as any).isRunning;
  }

  get canArchive() {
    return !this.isChecking && !this.hasDependentObjects;
  }

  get hasDependentTicketTypes() {
    return this.dependentObjects && this.dependentObjects.ticketTypes.length > 0;
  }

  get hasDependentWorkflows() {
    return this.dependentObjects && this.dependentObjects.workflows.length > 0;
  }

  get workflowsDependencyText() {
    return this.intl.t(
      'settings.ticket-states.archive-ticket-state-modal.workflows-dependency-text',
      {
        workflowsCount: this.dependentObjects.workflows?.length,
      },
    );
  }

  get ticketTypesDependencyText() {
    return this.intl.t(
      'settings.ticket-states.archive-ticket-state-modal.ticket-types-dependency-text',
      {
        ticketTypesCount: this.dependentObjects.ticketTypes.length,
      },
    );
  }

  @task({ drop: true })
  *fetchDependentObjects(): TaskGenerator<any> {
    if (!this.args.ticketCustomStateToArchive) {
      return;
    }
    let ticketCustomStateId = Number(this.args.ticketCustomStateToArchive.id);

    try {
      let response =
        yield this.ticketStateService.getTicketCustomStateDependentObjects(ticketCustomStateId);

      this.dependentObjects = {
        ticketTypes: response.dependent_ticket_types,
        workflows: response.dependent_workflows,
      };

      this.hasDependentObjects = Object.values(this.dependentObjects).some(
        (dependentObjects) => dependentObjects.length > 0,
      );
    } catch (error) {
      this.args.onModalClose();
      this.notificationsService.notifyError(
        this.intl.t('settings.ticket-states.archive-ticket-state-modal.error-on-archive', {
          ticketCustomState: this.args.ticketCustomStateToArchive.adminLabel,
        }),
      );
    }
  }

  @task({ drop: true })
  *archiveTicketCustomState() {
    if (!this.args.ticketCustomStateToArchive) {
      return;
    }
    try {
      yield this.ticketStateService.archiveTicketCustomState(
        this.args.ticketCustomStateToArchive.id,
      );
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.ticket-states.archive-ticket-state-modal.successfully-archived', {
          ticketCustomState: this.args.ticketCustomStateToArchive.adminLabel,
        }),
      );
      this.args.onArchivedState();
      this.ticketStateService.reloadTicketCustomStates();
    } catch (error) {
      if (error.jqXHR?.responseJSON?.message) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-states.archive-ticket-state-modal.unknown-error', {
            ticketCustomState: this.args.ticketCustomStateToArchive.adminLabel,
          }),
        );
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketStates::ArchiveTicketCustomStateModal': typeof ArchiveTicketCustomStateModal;
    'settings/ticket-states/archive-ticket-custom-state-modal': typeof ArchiveTicketCustomStateModal;
  }
}
