/* import __COLOCATED_TEMPLATE__ from './chart-comparison-bar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { computed } from '@ember/object';
import Component from '@ember/component';
import moment from 'moment-timezone';

const ITEM_WIDTH = 46; //corresponds to max-width set in reporting/_axes.scss
const BAR_WIDTH = 40 + 2; //corresponds to width set in reporting/_chart.scss

export default Component.extend({
  tagName: '',
  offset: (ITEM_WIDTH - BAR_WIDTH) / 2,
  barScale: computed('width', 'signal.context.length', function () {
    return Math.min(this.width / (this.signal.context.length * (BAR_WIDTH + 10)), 1); //prevent bars from scaling larger than 100% their set width
  }),
  series: computed('signal.{context,previousContext,timezone}', 'xScale', function () {
    if (!this.signal.context || !this.signal.previousContext || !this.xScale) {
      return;
    }

    return this.signal.context.map((current, i) => ({
      key: current.key,
      current,
      x: this.xScale(moment.tz(current.key, this.signal.timezone)),
      previous: this.signal.previousContext[i],
    }));
  }),
});
