/* import __COLOCATED_TEMPLATE__ from './bubble-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { BubbleChartRenderEngine } from 'embercom/lib/reporting/bubble-chart/render-engine';

const SVG_WIDTH = 930;
const SVG_HEIGHT = 415;

export default class BubbleChartComponent extends Component {
  @service appService;

  @tracked tooltipOpenerElement;
  @tracked tooltipBubbleData;

  @tracked width = SVG_WIDTH;
  height = SVG_HEIGHT;
  svgElement = null;

  renderEngine = new BubbleChartRenderEngine();

  _updateWidth() {
    this.width = Math.max(this.svgElement.parentElement.clientWidth, SVG_WIDTH);
  }

  // Tooltip

  @action
  updateTooltip(bubbleData, target) {
    this.tooltipOpenerElement = target;
    this.tooltipBubbleData = bubbleData;
  }

  @action
  resetTooltip() {
    this.tooltipOpenerElement = undefined;
  }

  // Toggles

  @action
  onToggleLayout() {
    this.renderEngine.toggleLayout(this.args.showAxes);
  }

  @action
  onToggleSatisfaction() {
    this.renderEngine.toggleSatisfaction(this.args.showSatisfaction);
  }

  @action
  onToggleVolume() {
    this.renderEngine.toggleVolume(this.args.showVolume);
  }

  @action
  onWindowResize() {
    if (!this.svgElement) {
      return;
    }

    this._updateWidth();
    this.updateChart();
  }

  // Initialization

  @action
  drawChart(element) {
    this.svgElement = element;
    this._updateWidth();
    this.renderEngine.init(
      this.args.data,
      element,
      this.updateTooltip,
      this.resetTooltip,
      this.appService.app.id,
    );
    this.updateChart();
  }

  @action
  updateChart() {
    this.renderEngine.render(this.width, this.height, this.args.data);
  }
}
