/* import __COLOCATED_TEMPLATE__ from './teammate-table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import Formatters, { units } from 'embercom/lib/reporting/flexible/formatters';
import percent, { toPercentString } from 'embercom/lib/percentage-formatter';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { bind } from '@ember/runloop';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import {
  botInboxAndOfficeHoursField,
  timeTimeToCloseField,
  officeHoursField,
} from 'embercom/lib/reporting/office-hours-field';
import { MEDIAN, MIN, MAX, MEAN } from 'embercom/lib/reporting/flexible/constants';
import { isEmpty, pick, isObject, isArray } from 'underscore';
import storage from 'embercom/vendor/intercom/storage';
import {
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  CLOSES_WITH_USER_PART_FILTERS,
  USER_PART_EXISTS_FILTER,
} from 'embercom/lib/reporting/flexible/constants';
import {
  REPORTING_FILTER_SELECT_ALL,
  CONVERSATION_PART_REPLIES_FILTERS,
  TEAMMATE_REPLIES_FILTERS,
  EXCLUDE_BOT_ADMIN,
} from 'embercom/lib/reporting/flexible/constants';

const TEAMMATE_PARTICIPATED_FILTER = 'admin_participant_ids';
const TEAMMATE_ATTRIBUTED_FILTER = 'attributed_to_admin_id';
const COMBINED_TEAM_TEAMMATES_FILTER = 'combined-same-filter-field';
const AGGREGATION_STORAGE_KEY = 'reporting-teammate-performance-aggregation-method';

const SLA_COLUMN_VALUE_KEY = 'col-group-sla_[value]';
const SLA_COLUMN_TOTAL_KEY = 'col-group-sla_[total]';

export default class TeammateTable extends Component {
  @service appService;
  @service intl;

  @tracked columns;
  @tracked showAggregationSelector = false;
  @tracked selectedAggregation = MEDIAN;

  constructor() {
    super(...arguments);
    this.timeFormatter = new Formatters[units.seconds]();
    // to prevent rebuilding of a potentially expensive map
    this.precalculatedAllTeammatesMap = this.getTeammates();
    this.selectedAggregation = storage.get(AGGREGATION_STORAGE_KEY) || MEDIAN;
    this.columns = this.generateColumnConfig();
  }

  get filteredTeammates() {
    let teamFilterValues = this.args.filters[TEAMMATE_PARTICIPATED_FILTER];

    if (isArray(teamFilterValues)) {
      if (teamFilterValues[0] === REPORTING_FILTER_SELECT_ALL) {
        return [];
      }
      // if it's an array, we assume it's an array of teammate ids
      return teamFilterValues.map((v) => parseInt(v, 10));
    } else if (
      isObject(teamFilterValues) &&
      teamFilterValues.type === COMBINED_TEAM_TEAMMATES_FILTER
    ) {
      let values = teamFilterValues.values.flat();
      if (values[0] === REPORTING_FILTER_SELECT_ALL) {
        return [];
      }
      // support a special case of colliding team and teammate ids when they use the same filter name
      return values.map((v) => parseInt(v, 10));
    }

    return [];
  }

  createdAtFieldName() {
    return FIRST_USER_CONVERSATION_PART_CREATED_AT;
  }

  generateColumnConfig() {
    return [
      {
        label: this.intl.t('reporting.reports.team-performance.teammate-table.name'),
        isMain: true,
        valuePath: 'groupName',
        component: 'reporting/flexible/table-teammate-cell',
        componentData: this.teammatesMap,
        // this is required to support sorting not on response values but on derive data instead
        // e.g. we can't simply sort by teammate names because we have only teammate ids returned
        // in the response. This function is used to map teammate ids (stored in `groupName`)
        // to teammate names
        getRowValue: (row) => this.teammateName(row.groupName),
        // this is required to provide a display name for columns with cell component or formatter rendering when
        // they need to render rows that they do not support.
        // e.g. we need to render 'All teammates' row in a cell that shows teammate userpic and name
        aliases: {
          'row-summary': this.intl.t('reporting.reports.team-performance.teammate-table.summary'),
        },
        isSortable: true,
        sortingHelper: (row) => this.teammateName(row.groupName),
      },
      {
        label: this.intl.t('reporting.reports.team-performance.teammate-table.assigned'),
        valuePath: 'col-assigned',
        type: 'number',
        isSortable: true,
        tooltip: this.intl.t('reporting.reports.team-performance.teammate-table.assigned-tooltip'),
      },
      {
        label: this.intl.t('reporting.reports.team-performance.teammate-table.replied-to'),
        valuePath: 'col-participated',
        type: 'number',
        isSortable: true,
        tooltip: this.intl.t(
          'reporting.reports.team-performance.teammate-table.participated-tooltip',
        ),
      },
      {
        label: this.intl.t('reporting.reports.team-performance.teammate-table.replies-sent'),
        valuePath: 'col-replies',
        isSortable: true,
        type: 'number',
        tooltip: this.intl.t(
          'reporting.reports.team-performance.teammate-table.replies-sent-tooltip',
        ),
      },
      {
        label: this.intl.t('reporting.reports.team-performance.teammate-table.closed-by-teammate'),
        valuePath: 'col-closed',
        type: 'number',
        isSortable: true,
        tooltip: this.intl.t(
          'reporting.reports.team-performance.teammate-table.closed-by-teammate-tooltip',
        ),
      },
      {
        label: this.intl.t('reporting.reports.team-performance.teammate-table.ratings'),
        csvLabel: this.intl.t('reporting.reports.team-performance.teammate-table.csv-label'),
        valuePath: 'col-group-csat',
        type: 'number',
        isSortable: true,
        component: 'reporting/flexible/table-csat-cell',
        sortingHelper: (row) => this.getPercentageForSorting(row['col-group-csat']),
        getRowValue: (row) => this.formatPercentage(row['col-group-csat']),
        minWidth: '145px',
        tooltip: this.intl.t('reporting.reports.team-performance.teammate-table.ratings-tooltip'),
      },
      this.columnConfigForSLAMissRate,
      {
        label: this.intl.t('reporting.reports.team-performance.teammate-table.response-time', {
          selectedAggregation: this.selectedAggregation,
        }),
        labelCanWrap: true,
        valuePath: 'col-mrt',
        isSortable: true,
        type: 'number',
        formatter: bind(this, this.formatTime),
        minWidth: '160px',
        tooltip: this.intl.t(
          'reporting.reports.team-performance.teammate-table.response-time-tooltip',
          {
            selectedAggregation: this.selectedAggregation,
          },
        ),
      },
      {
        label: this.intl.t(
          'reporting.reports.team-performance.teammate-table.first-response-time',
          {
            selectedAggregation: this.selectedAggregation,
          },
        ),
        labelCanWrap: true,
        valuePath: 'col-mfrt',
        isSortable: true,
        type: 'number',
        formatter: bind(this, this.formatTime),
        minWidth: '190px',
        tooltip: this.intl.t(
          'reporting.reports.team-performance.teammate-table.first-response-time-tooltip',
          {
            selectedAggregation: this.selectedAggregation,
          },
        ),
      },
      {
        label: this.intl.t('reporting.reports.team-performance.teammate-table.time-to-close', {
          selectedAggregation: this.selectedAggregation,
        }),
        labelCanWrap: true,
        valuePath: 'col-mtc',
        isSortable: true,
        type: 'number',
        formatter: bind(this, this.formatTime),
        minWidth: '140px',
        tooltip: this.intl.t(
          'reporting.reports.team-performance.teammate-table.time-to-close-tooltip',
          {
            selectedAggregation: this.selectedAggregation,
          },
        ),
      },
      this.columnConfigForHandlingTime,
      {
        label: this.intl.t('reporting.reports.team-performance.teammate-table.notes'),
        valuePath: 'col-notes',
        isSortable: true,
        type: 'number',
        tooltip: this.intl.t('reporting.reports.team-performance.teammate-table.notes-tooltip'),
      },
      {
        label: this.intl.t('reporting.reports.team-performance.teammate-table.mentions'),
        valuePath: 'col-mentions',
        isSortable: true,
        type: 'number',
        tooltip: this.intl.t('reporting.reports.team-performance.teammate-table.mentions-tooltip'),
      },
    ].compact();
  }

  get dataConfig() {
    let dataConfigHash = {
      columns: [
        {
          name: 'col-assigned',
          groupAlias: {
            teammate_id: 'comment_admin_assignee_id',
          },
          filter: {
            type: 'and',
            filters: [
              {
                type: 'range',
                data: {
                  property: 'comment_admin_assignee_id',
                  operators: ['gt'],
                  values: ['0'],
                },
              },
              USER_PART_EXISTS_FILTER,
            ],
          },
          filterAlias: {
            admin_participant_ids: 'comment_admin_assignee_id',
          },
          source: 'consolidated_conversation_part',
          aggregation: {
            type: 'cardinality',
            data: {
              property: 'conversation_id',
            },
          },
          time: {
            property: 'comment_created_at',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
        {
          name: 'col-participated',
          filter: {
            type: 'and',
            filters: [
              {
                type: 'exists',
                data: {
                  property: 'teammate_id',
                },
              },
              ...TEAMMATE_REPLIES_FILTERS,
              ...this.excludeBotFilter,
            ],
          },
          filterAlias: {
            admin_participant_ids: 'teammate_id',
          },
          source: 'consolidated_conversation_part',
          aggregation: {
            type: 'cardinality',
            data: {
              property: 'conversation_id',
            },
          },
          time: {
            property: 'comment_created_at',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
        {
          name: 'col-closed',
          filter: {
            type: 'and',
            filters: [
              ...CLOSES_WITH_USER_PART_FILTERS,
              {
                type: 'exists',
                data: {
                  property: 'teammate_id',
                },
              },
              ...this.excludeBotFilter,
            ],
          },
          filterAlias: {
            admin_participant_ids: 'teammate_id',
          },
          source: 'consolidated_conversation_part',
          aggregation: {
            type: 'cardinality',
            data: {
              property: 'conversation_id',
            },
          },
          time: {
            property: 'comment_created_at',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
        {
          name: 'col-group-csat_[value]',
          filter: {
            type: 'and',
            filters: [
              {
                type: 'category',
                data: {
                  property: 'rating_index',
                  values: [4, 5],
                },
              },
              {
                type: 'exists',
                data: {
                  property: 'teammate_id',
                },
              },
            ],
          },
          filterAlias: {
            admin_participant_ids: 'teammate_id',
          },
          source: 'conversation_rating',
          aggregation: {
            type: 'count',
            data: {
              property: 'rating_updated_at',
            },
          },
          time: {
            property: 'rating_updated_at',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
        {
          name: 'col-group-csat_[total]',
          filter: {
            type: 'and',
            filters: [
              {
                type: 'exists',
                data: {
                  property: 'teammate_id',
                },
              },
              {
                type: 'exists',
                data: {
                  property: 'rating_index',
                },
              },
            ],
          },
          filterAlias: {
            admin_participant_ids: 'teammate_id',
          },
          source: 'conversation_rating',
          aggregation: {
            type: 'count',
            data: {
              property: 'rating_updated_at',
            },
          },
          time: {
            property: 'rating_updated_at',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
        ...this.dataConfigForSLAMissRate,
        {
          name: 'col-mrt',
          filter: {
            type: 'and',
            filters: [
              {
                type: 'exists',
                data: {
                  property: 'teammate_id',
                },
              },
              ...CONVERSATION_PART_REPLIES_FILTERS,
            ],
          },
          filterAlias: {
            admin_participant_ids: 'teammate_id',
          },
          source: 'consolidated_conversation_part',
          aggregation: {
            type: this.selectedAggregation,
            data: {
              property: botInboxAndOfficeHoursField(
                'response_time',
                this.args.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
                true,
              ),
            },
          },
          time: {
            property: this.createdAtFieldName(),
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
        this.dataConfigForFirstResponseTime,
        this.dataConfigForTimeToClose,
        this.dataConfigForHandlingTime,
        {
          name: 'col-notes',
          filter: {
            type: 'and',
            filters: [
              {
                type: 'exists',
                data: {
                  property: 'teammate_id',
                },
              },
              {
                type: 'category',
                data: {
                  property: 'is_note',
                  values: [true],
                },
              },
              USER_PART_EXISTS_FILTER,
              ...this.excludeBotFilter,
            ],
          },
          filterAlias: {
            admin_participant_ids: 'teammate_id',
          },
          source: 'consolidated_conversation_part',
          aggregation: {
            type: 'count',
            data: {
              property: 'comment_created_at',
            },
          },
          time: {
            property: 'comment_created_at',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
        {
          name: 'col-replies',
          filter: {
            type: 'and',
            filters: [
              {
                type: 'exists',
                data: {
                  property: 'teammate_id',
                },
              },
              ...TEAMMATE_REPLIES_FILTERS,
              ...this.excludeBotFilter,
            ],
          },
          filterAlias: {
            admin_participant_ids: 'teammate_id',
          },
          source: 'consolidated_conversation_part',
          aggregation: {
            type: 'count',
            data: {
              property: 'comment_created_at',
            },
          },
          time: {
            property: 'comment_created_at',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
        {
          name: 'col-mentions',
          groupAlias: {
            teammate_id: 'mentions',
          },
          filter: {
            type: 'and',
            filters: [
              USER_PART_EXISTS_FILTER,
              {
                type: 'exists',
                data: {
                  property: 'mentions',
                },
              },
            ],
          },
          filterAlias: {
            admin_participant_ids: 'mentions',
          },
          source: 'consolidated_conversation_part',
          aggregation: {
            type: 'count',
            data: {
              property: 'comment_created_at',
            },
          },
          time: {
            property: 'comment_created_at',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
      ].compact(),
      rows: [
        {
          name: 'row-teammates',
          type: 'term',
          ignoreFilters: {
            // we're skipping participation filters for all teammate rows
            columns: [
              'col-assigned',
              'col-participated',
              'col-closed',
              SLA_COLUMN_VALUE_KEY,
              SLA_COLUMN_TOTAL_KEY,
              'col-mrt',
              'col-mfrt',
              'col-mtc',
              'col-group-csat_[value]',
              'col-group-csat_[total]',
              'col-mht',
              'col-notes',
              'col-replies',
              'col-mentions',
            ].compact(),
            filters: [TEAMMATE_PARTICIPATED_FILTER],
          },
          data: {
            term_size: 10000,
            property: 'teammate_id',
          },
        },
        {
          name: 'row-summary',
          type: 'allRecords',
        },
      ],
    };
    return dataConfigHash;
  }

  get viewConfig() {
    return {
      rowsVisible: 10,
      sortState: { valuePath: 'col-assigned', direction: 'desc' },
      rowOrder: [{ position: 0, valuePath: 'groupName', value: 'row-summary' }],
      // allows controlling row visibility based on the data returned from the server
      isVisibleRow: (row) =>
        !isEmpty(this.teammatesMap[row.groupName]) || row.groupName === 'row-summary',
      // allows merging front-end defined rows with rows fetched from the server
      mergeableRows: {
        // property of a row based on which merging happens
        valuePath: 'groupName',
        // front-end defined rows as map to speed up merging
        getRowsMap: () =>
          Object.values(this.teammatesMap).reduce((acc, t) => {
            acc[t.id] = { groupName: t.id };
            return acc;
          }, {}),
      },
    };
  }

  formatTime(value) {
    let result = value ? this.timeFormatter.formatCounter(value) : '-';
    return result;
  }

  teammateName(teammateId) {
    let teammate = this.teammatesMap[teammateId];
    return teammate ? teammate.name : 'unknown';
  }

  getPercentageForSorting(complexValue) {
    if (!complexValue || !complexValue.total) {
      return -1;
    }

    return percent(complexValue.total, complexValue.value || 0);
  }

  formatPercentage(complexValue) {
    if (!complexValue || !complexValue.total) {
      return '-';
    }

    let rawPercentage = percent(complexValue.total, complexValue.value || 0);
    let percentageValue = toPercentString(rawPercentage, 2);
    return `${percentageValue} (${complexValue.total})`;
  }

  formatSLAPercentage(complexValue) {
    if (!complexValue || !complexValue.total) {
      return '-';
    }

    let value = complexValue.value || 0;
    let rawPercentage = percent(complexValue.total, value);
    let percentageValue = toPercentString(rawPercentage, 2);
    return `${percentageValue} (${value}/${complexValue.total})`;
  }

  sumRowValues(columnName) {
    return (rows) => rows.reduce((sum, r) => sum + (r[columnName] || 0), 0);
  }

  addFilterToExcludeOperators(columns) {
    let newColumns = [];

    columns.forEach((column) => {
      let newColumn = Object.assign({}, column);
      let groupByFieldName = 'teammate_id';

      if ('groupAlias' in column) {
        groupByFieldName = column.groupAlias.teammate_id; // The name of the teammate ID property can change depending on the metric
      }

      let operatorFilter = {
        type: 'not_in_category',
        data: {
          property: groupByFieldName,
          values: [this.appService.app.operatorBot.id],
        },
      };

      if ('filters' in newColumn.filter) {
        newColumn.filter.filters.push({ ...operatorFilter });
      } else {
        newColumn.filter = this.makeFiltersArray([newColumn.filter, { ...operatorFilter }]);
      }
      newColumns.push(newColumn);
    });
    return newColumns;
  }

  makeFiltersArray(filters) {
    return {
      type: 'and',
      filters: [...filters],
    };
  }

  getTeammates() {
    let admins = this.appService.app.humanAdmins;
    return admins.reduce((acc, a) => {
      acc[a.id] = {
        id: a.id,
        name: a.name,
        avatar: a.avatar,
      };

      return acc;
    }, {});
  }

  // conversation_closed migration to conversation index
  get dataConfigForTimeToClose() {
    return {
      name: 'col-mtc',
      filter: {
        type: 'and',
        filters: [
          {
            type: 'exists',
            data: {
              property: 'last_human_authored_closer_teammate_id',
            },
          },
        ],
      },
      filterAlias: {
        admin_participant_ids: 'last_human_authored_closer_teammate_id',
      },
      groupAlias: {
        teammate_id: 'last_human_authored_closer_teammate_id',
      },
      excludeMissingGroup: true,
      source: 'conversation',
      aggregation: {
        type: this.selectedAggregation,
        data: {
          property: botInboxAndOfficeHoursField(
            timeTimeToCloseField(this.botInboxTimeExcluded),
            this.args.settings.reportingExcludeOooHours,
            this.botInboxTimeExcluded,
            true,
          ),
        },
      },
      time: {
        property: 'first_user_conversation_part_created_at',
        start: FILTER_PROPERTY_PLACEHOLDER,
        end: FILTER_PROPERTY_PLACEHOLDER,
      },
    };
  }

  // initial_admin_reply migration to conversation
  get dataConfigForFirstResponseTime() {
    return {
      name: 'col-mfrt',
      filter: {
        type: 'exists',
        data: {
          property: 'first_reply_teammate_id',
        },
      },
      filterAlias: {
        admin_participant_ids: 'first_reply_teammate_id',
      },
      groupAlias: {
        teammate_id: 'first_reply_teammate_id',
      },
      source: 'conversation',
      aggregation: {
        type: this.selectedAggregation,
        data: {
          property: botInboxAndOfficeHoursField(
            'first_response_time',
            this.args.settings.reportingExcludeOooHours,
            this.botInboxTimeExcluded,
            true,
          ),
        },
      },
      time: {
        property: this.createdAtFieldName(),
        start: FILTER_PROPERTY_PLACEHOLDER,
        end: FILTER_PROPERTY_PLACEHOLDER,
      },
    };
  }

  get dataConfigForSLAMissRate() {
    return [
      {
        name: SLA_COLUMN_VALUE_KEY,
        groupAlias: {
          teammate_id: TEAMMATE_ATTRIBUTED_FILTER,
        },
        filter: {
          type: 'and',
          filters: [
            {
              type: 'exists',
              data: {
                property: TEAMMATE_ATTRIBUTED_FILTER,
              },
            },
            {
              type: 'category',
              data: {
                property: 'sla_status',
                values: ['missed', 'fixed'],
              },
            },
          ],
        },
        filterAlias: {
          admin_participant_ids: TEAMMATE_ATTRIBUTED_FILTER,
        },
        source: 'conversation_sla_status_log',
        aggregation: {
          type: 'cardinality',
          data: {
            property: 'conversation_id',
          },
        },
        time: {
          property: 'started_at',
          start: FILTER_PROPERTY_PLACEHOLDER,
          end: FILTER_PROPERTY_PLACEHOLDER,
        },
      },
      {
        name: SLA_COLUMN_TOTAL_KEY,
        groupAlias: {
          teammate_id: TEAMMATE_ATTRIBUTED_FILTER,
        },
        filter: {
          type: 'and',
          filters: [
            {
              type: 'exists',
              data: {
                property: TEAMMATE_ATTRIBUTED_FILTER,
              },
            },
          ],
        },
        filterAlias: {
          admin_participant_ids: TEAMMATE_ATTRIBUTED_FILTER,
        },
        source: 'conversation_sla_status_log',
        aggregation: {
          type: 'cardinality',
          data: {
            property: 'conversation_id',
          },
        },
        time: {
          property: 'started_at',
          start: FILTER_PROPERTY_PLACEHOLDER,
          end: FILTER_PROPERTY_PLACEHOLDER,
        },
      },
    ];
  }

  get dataConfigForHandlingTime() {
    return {
      name: 'col-mht',
      filter: {
        type: 'exists',
        data: {
          property: 'teammate_id',
        },
      },
      source: 'teammate_handling_conversation',
      aggregation: {
        type: this.selectedAggregation,
        data: {
          property: officeHoursField('handling_time', this.args.settings.reportingExcludeOooHours),
        },
      },
      filterAlias: {
        admin_participant_ids: 'teammate_id',
      },
      time: {
        property: this.createdAtFieldName(),
        start: FILTER_PROPERTY_PLACEHOLDER,
        end: FILTER_PROPERTY_PLACEHOLDER,
      },
    };
  }

  get columnConfigForHandlingTime() {
    return {
      label: this.intl.t('reporting.reports.team-performance.teammate-table.handling-time', {
        selectedAggregation: this.selectedAggregation,
      }),
      labelCanWrap: true,
      valuePath: 'col-mht',
      isSortable: true,
      type: 'number',
      formatter: bind(this, this.formatTime),
      minWidth: '160px',
      tooltip: this.intl.t(
        'reporting.reports.team-performance.teammate-table.handling-time-tooltip',
        {
          selectedAggregation: this.selectedAggregation,
        },
      ),
    };
  }

  get columnConfigForSLAMissRate() {
    return {
      label: this.intl.t('reporting.reports.team-performance.columns.sla.label'),
      valuePath: 'col-group-sla',
      isSortable: true,
      type: 'number',
      component: 'reporting/flexible/table-sla-miss-cell',
      sortingHelper: (row) => this.getPercentageForSorting(row['col-group-sla']),
      getRowValue: (row) => this.formatSLAPercentage(row['col-group-sla']),
      tooltip: this.intl.t('reporting.reports.team-performance.columns.sla.tooltip'),
    };
  }

  get teamsData() {
    let admins = this.appService.app.assignableAdmins;
    return admins.filter((admin) => admin.isTeam);
  }

  get teammatesMap() {
    if (isEmpty(this.filteredTeammates)) {
      return this.precalculatedAllTeammatesMap;
    }

    let result = pick(this.precalculatedAllTeammatesMap, ...this.filteredTeammates);
    return result;
  }

  get botInboxTimeExcluded() {
    return this.args.rawFilters.botInboxTime?.[0] === 'excluded';
  }

  get aggregationItems() {
    return [
      {
        value: MEDIAN,
        text: this.intl.t('reporting.reports.team-performance.teammate-table.median'),
      },
      {
        value: MEAN,
        text: this.intl.t('reporting.reports.team-performance.teammate-table.average'),
      },
      {
        value: MAX,
        text: this.intl.t('reporting.reports.team-performance.teammate-table.maximum'),
      },
      {
        value: MIN,
        text: this.intl.t('reporting.reports.team-performance.teammate-table.minimum'),
      },
    ];
  }

  get selectedAggregationText() {
    return this.aggregationItems.find((item) => item.value === this.selectedAggregation).text;
  }

  @action
  openAggregationSelector() {
    this.showAggregationSelector = true;
  }

  @action
  closeAggregationSelector() {
    this.showAggregationSelector = false;
  }

  @action
  changeSelectedAggregation(aggregation) {
    this.selectedAggregation = aggregation;
  }

  @action
  saveSelectedAggregation() {
    this.showAggregationSelector = false;
    this.storeSelectedAggregation();
    this.columns = this.generateColumnConfig();
  }

  storeSelectedAggregation() {
    storage.set(AGGREGATION_STORAGE_KEY, this.selectedAggregation);
  }

  get excludeBotFilter() {
    return [EXCLUDE_BOT_ADMIN];
  }
}
