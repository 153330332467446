/* import __COLOCATED_TEMPLATE__ from './attribute-descriptor-format-link-to.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP } from 'embercom/models/custom-objects/constants/attribute-descriptor-types';

export default class AttributeDescriptorFormatLinkTo extends Component {
  @action
  onItemSelect(objectTypeIdentifier) {
    this.args.item.componentAttrs.onSelectItem(objectTypeIdentifier);
    this.args.selectItem(ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP);
  }
}
