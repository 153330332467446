/* import __COLOCATED_TEMPLATE__ from './column-line-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { getOwner } from '@ember/application';
import type Range from 'embercom/models/reporting/range';
import type ReportingChartService from 'embercom/services/reporting-chart-service';
import { type ViewConfig } from 'embercom/services/reporting-chart-service';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { cached } from 'tracked-toolbox';
import { mapXAxisLabel } from 'embercom/lib/reporting/flexible/label-formatter';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import {
  getFirstSeriesBucketKeys,
  shouldAllowZeroValues,
  shouldConvertNullsToZeros,
} from 'embercom/lib/reporting/flexible/data-response-helpers';
import {
  getChartSeriesName,
  mapHumanReadableLabelsToRawKey,
} from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import ColumnLineChartBuilder from 'embercom/lib/reporting/flexible/column-line-chart-builder';
import moment from 'moment-timezone';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import type Metric from 'embercom/objects/reporting/unified/metrics/types';
import { type Aggregation } from 'embercom/objects/reporting/unified/metrics/types';

interface Signature {
  Args: Args;
  Blocks: {
    default: [{ Empty: Function }];
  };
}

interface Args {
  dataConfig: any;
  range: Range;
  width: string;
  isBeingRenderedOnAReport: boolean;
  viewConfig: ViewConfig;
  renderableChart: RenderableChart;
  disabled: boolean;
  dashboard: any;
  isPaywalled: boolean;
}

const COLUMN_CHART_TYPE = 'column';

const LINE_CHART_TYPE = 'line';

const Y_AXIS_MAPPING: any = {
  [COLUMN_CHART_TYPE]: 0,
  [LINE_CHART_TYPE]: 1,
};

export default class ColumnLineChart extends Component<Signature> {
  @service declare reportingChartService: ReportingChartService;
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare intl: any;

  @use dataLoader = AsyncData<ChartDataResourceCompatible>(async () => {
    return new ChartDataResourceCompatible(getOwner(this), {
      dataConfig: this.args.dataConfig,
      viewConfig: this.args.viewConfig,
      renderableChart: this.args.renderableChart,
    });
  });

  get dataResource(): ChartDataResourceCompatible | undefined {
    return this.dataLoader.value;
  }

  get rawChartData() {
    return this.dataResource?.rawChartData || [];
  }

  get app() {
    return this.appService.app;
  }

  get legend() {
    if (this.args.viewConfig.legend && !this.args.renderableChart.showTimeComparison) {
      return this.args.viewConfig.legend;
    }

    return {
      comparison_columnchart: () => {
        return `${moment(this.args.range.startMoment).format(
          dateAndTimeFormats.dateWithoutYear,
        )} - ${moment(this.args.range.endMoment).format(dateAndTimeFormats.dateWithoutYear)}`;
      },
      comparison_columnchart_previous: () => {
        return this.intl.t('reporting.flexible-chart.previous-time-period-in-days', {
          dayCount: this.args.range.inDays,
        });
      },
    };
  }

  get isTimeComparison() {
    return this.args.renderableChart.showTimeComparison;
  }

  shouldAllowZeroValues(metric: Metric, index: number) {
    return shouldAllowZeroValues(metric, this.getAggregationFunction(index));
  }

  mapToSameAxis(dataResponses: any) {
    if (!dataResponses) {
      return dataResponses;
    }
    if (dataResponses.length === 1) {
      dataResponses[0].groups[0].names = [...dataResponses[0].groups[0].values];
      return dataResponses;
    }

    let currentPeriodResponses = this.isTimeComparison
      ? dataResponses.slice(dataResponses.length / 2)
      : dataResponses;
    let previousPeriodResponses = this.isTimeComparison
      ? dataResponses.slice(0, dataResponses.length / 2)
      : [];
    currentPeriodResponses.forEach((response: any) => {
      response.groups[0].names = [...response.groups[0].values];
    });

    // we care only about a single nesting level of groups
    // we also rely on the presence of the group. Add validation in the future
    previousPeriodResponses.forEach((response: any, index: number) => {
      response.groups[0].names = response.groups[0].values;
      response.groups[0].values = [...currentPeriodResponses[index].groups[0].values];
    });

    return [...previousPeriodResponses, ...currentPeriodResponses];
  }

  getChartType(index: number) {
    return index % 2 === 0 ? COLUMN_CHART_TYPE : LINE_CHART_TYPE;
  }

  @cached
  get chartData() {
    // Nolaneo – this linter error seems genuine: why are we mutating state in this getter?
    // eslint-disable-next-line
    let dataModified = {};
    try {
      let mappedResponse = this.mapToSameAxis(this.rawChartData);
      let firstSeriesBucketKeys = getFirstSeriesBucketKeys(
        this.args.renderableChart,
        this.rawChartData,
      );
      let type: string;
      return mappedResponse.map((response: any, index: number) => {
        let seriesIsPreviousPeriod = response.name.includes('previous');

        let numberOfCurrentSeries = this.args.renderableChart.chartSeries.length;
        let seriesIndex =
          this.args.renderableChart.showTimeComparison && !seriesIsPreviousPeriod
            ? index - numberOfCurrentSeries
            : index;
        type = this.getChartType(seriesIndex);
        let metric = this.args.viewConfig.metrics[seriesIndex];
        let shouldAllowZeroValues = this.shouldAllowZeroValues(metric, seriesIndex);
        let data = this.buildVisualisationData(
          response,
          firstSeriesBucketKeys,
          shouldAllowZeroValues,
        );
        if (type === LINE_CHART_TYPE) {
          data = this.convertNullsToZeros(data, this.getAggregationFunction(seriesIndex), metric);
        }

        // For multi-metric charts with percentage or ratio metrics, we need to
        // use the main metric id, and not the metric id in the response
        let seriesName = metric.id || response.name;

        let legendLabel;

        if (this.args.renderableChart.showTimeComparison) {
          let metricNameLabel = getChartSeriesName(seriesIndex, this.args.renderableChart);
          let previousLabel =
            this.legend['comparison_columnchart_previous'] &&
            this.legend['comparison_columnchart_previous'].call(seriesName);
          let comparisonLabel =
            this.legend['comparison_columnchart'] &&
            this.legend['comparison_columnchart'].call(seriesName);

          if (seriesIsPreviousPeriod) {
            legendLabel = metricNameLabel ? `${metricNameLabel} - ${previousLabel}` : previousLabel;
          } else {
            legendLabel = metricNameLabel
              ? `${metricNameLabel} - ${comparisonLabel}`
              : comparisonLabel;
          }
        } else {
          legendLabel = getChartSeriesName(index, this.args.renderableChart);
        }

        let options = {
          name: seriesName,
          data,
          legendLabel,
          showInLegend: mappedResponse.length > 1,
          dataModified,
          isComparison: this.isTimeComparison,
          rawToLabelMapping: mapHumanReadableLabelsToRawKey(
            response,
            this.args.dataConfig,
            this.args.viewConfig,
          ),
          isPreviousPeriod: seriesIsPreviousPeriod,
          yAxis: Y_AXIS_MAPPING[type],
          type,
          shouldAllowZeroValues,
        };

        return options;
      });
    } catch (e) {
      console.error(e);
      this.dataResource?.notifyError();
      return [];
    }
  }

  buildVisualisationData(dataResponse: any, keys: any[] = [], shouldAllowZeroValues: boolean) {
    // we assume there's only one group for a simple barchart
    let group = dataResponse.groups[0];
    let aggregation = group.aggregations[0];
    let xAxisType = this.args.dataConfig.xAxis.type;
    let keysToProcess =
      isPresent(keys) && !this.args.renderableChart.isBrokenDownByTime ? keys : group.values;
    return keysToProcess
      .map((key: any) => {
        if (!group.values.includes(key)) {
          return undefined;
        }
        let index = group.values.indexOf(key);
        let x = key;
        let y =
          aggregation.values[index] !== 0 || shouldAllowZeroValues
            ? aggregation.values[index]
            : null;

        if (xAxisType === 'nominal') {
          let mappedX = mapXAxisLabel(this.args.dataConfig, this.args.viewConfig, x);
          return [mappedX.toString(), y, mappedX];
        } else {
          let name = group?.names?.get(index);
          return [x, y, name];
        }
      })
      .compact();
  }

  convertNullsToZeros(responseWithNulls: any, aggregation: Aggregation, metric: any) {
    if (!shouldConvertNullsToZeros(aggregation, metric)) {
      return responseWithNulls;
    }

    return responseWithNulls.map((point: any[]) => [point[0], point[1] || 0, point[2]]);
  }

  getAggregationFunction(index: number) {
    if (isPresent(this.args.dataConfig?.series)) {
      return this.args.dataConfig?.series[index]?.type;
    }
    return undefined;
  }

  get chartOptions() {
    let builder = new ColumnLineChartBuilder(
      this.args.range,
      this.args.viewConfig,
      this.args.dataConfig,
      this.app,
      this.isTimeComparison,
      this.chartData,
      this.getSeriesColor(),
      this.args.width,
    );
    return builder.buildTheme();
  }

  private getSeriesColor() {
    if (this.args.viewConfig.seriesColors) {
      return this.args.viewConfig.seriesColors;
    }
    return undefined;
  }

  get hasData() {
    return this.doesSingleResponseHaveData;
  }

  get doesSingleResponseHaveData() {
    if (this.args.disabled) {
      return false;
    }

    return this.chartData.some((singleSeries: any) => {
      if (singleSeries.shouldAllowZeroValues) {
        return singleSeries.data.some(
          (dataArray: any) => dataArray[1] !== null && dataArray[1] !== undefined,
        );
      }
      return this.chartData.some((singleSeries: any) => {
        return singleSeries.data.some((dataArray: any) => dataArray[1]);
      });
    });
  }

  get showEmptyState() {
    return this.args.isPaywalled || !this.hasData;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Flexible::ColumnLineChart': typeof ColumnLineChart;
    'reporting/flexible/column-line-chart': typeof ColumnLineChart;
  }
}
