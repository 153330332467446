/* import __COLOCATED_TEMPLATE__ from './verify-user-identity-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { A } from '@ember/array';
import { action, computed } from '@ember/object';
import { readOnly, not, notEmpty, equal, gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import {
  itemAtPropertyIndex,
  ternaryToProperty,
  valueOrDefault,
} from '@intercom/pulse/lib/computed-properties';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import STRICTNESS from 'embercom/lib/identity-verification-strictness';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  intl: service(),
  store: service(),
  intercomConfirmService: service(),
  classNames: ['flex-auto', 'flex', 'flex-col'],

  identityVerificationRiskStateService: service(),

  appService: service(),
  app: readOnly('appService.app'),

  showSecret: false,
  maskedSecret: '****************************************',
  showUserHashCheckerModal: false,

  sdkApp: computed('app.iosSdkApps.[]', 'app.androidSdkApps.[]', 'userSource', function () {
    let sdkApps = {
      ios: this.get('app.iosSdkApps'),
      android: this.get('app.androidSdkApps'),
    }[this.userSource];

    if (sdkApps) {
      return sdkApps.get('firstObject');
    }
  }),
  pingDomains: A(),
  userSource: null,
  identityVerified: valueOrDefault('sdkApp.identityVerified', false),
  identityUnverified: not('identityVerified'),

  installationComponentPath: computed('installMethod', function () {
    return `guide/start-screen/tasks/identity-verification/${this.installMethod}-component`;
  }),

  platformNames: {
    web: 'web',
    ios: 'iOS',
    android: 'Android',
  },
  installationHelpArticles: {
    web: 'https://www.intercom.com/help/install-on-your-product-or-site/quick-install/install-intercom-on-your-web-app-for-logged-in-users',
    ios: 'https://www.intercom.com/help/install-on-your-product-or-site/quick-install/install-and-configure-intercom-on-your-mobile-app',
    android:
      'https://www.intercom.com/help/install-on-your-product-or-site/quick-install/install-and-configure-intercom-on-your-mobile-app',
  },
  platformName: itemAtPropertyIndex('platformNames', 'userSource'),
  installationHelpArticle: itemAtPropertyIndex('installationHelpArticles', 'userSource'),
  installMethodSelected: notEmpty('installMethod'),

  helpArticles: {
    web: 'https://www.intercom.com/help/configure-intercom-for-your-product-or-site/staying-secure/enable-identity-verification-on-your-web-product',
    ios: 'https://developers.intercom.com/installing-intercom/docs/ios-identity-verification',
    android: 'https://developers.intercom.com/installing-intercom/android/identity-verification',
  },
  helpArticle: itemAtPropertyIndex('helpArticles', 'userSource'),

  isWeb: equal('userSource', 'web'),
  isNotWeb: not('isWeb'),

  isInstalled: ternaryToProperty('isWeb', 'hasPingDomains', 'sdkApp.is_activated'),
  isNotInstalled: not('isInstalled'),

  hasPingDomains: gt('pingDomains.length', 0),
  noDomainsHaveErrors: computed('pingDomains.@each.errorCode', function () {
    return this.pingDomains.isEvery('errorCode', null);
  }),
  domainsHaveErrors: not('noDomainsHaveErrors'),
  noDomainHasValidUserHash: computed('pingDomains.@each.validUserHash', function () {
    return this.pingDomains.isEvery('validUserHash', false);
  }),

  identityVerificationSettings: null,
  defaultStrictnessLabel: computed('identityVerificationSettings.defaultStrictness', function () {
    return STRICTNESS[this.identityVerificationSettings.defaultStrictness].label;
  }),

  confirmAndToggleIdentityVerification: task(function* () {
    let confirmed = yield this._confirmIdentityVerificationTask.perform();

    this._toggleIdentityVerificationTask.perform(this.identityVerified, confirmed);
  }),

  _confirmIdentityVerificationTask: task(function* () {
    if (this.identityVerified) {
      return true;
    } else if (!this.isWeb) {
      return yield this.intercomConfirmService.confirm(
        this._identityVerificationMobileConfirmationOptions(),
      );
    } else if (this.domainsHaveErrors) {
      return yield this.intercomConfirmService.confirm(
        this._identityVerificationWebConfirmationOptions(),
      );
    } else {
      return true;
    }
  }),

  _toggleIdentityVerificationTask: task(function* (originalState, confirmed) {
    if (confirmed) {
      let newState = this.toggleProperty('identityVerified');

      this._trackIdentityVerificationToggleEvent(newState);

      try {
        yield this._updateIdentityVerification(newState);
        yield this._refreshIdentityVerificationRiskState(newState);
      } catch (e) {
        this.set('identityVerified', originalState);
        this.notificationsService.notifyError(
          this.intl.t(
            'components.settings.identity-verification.verify-user-identity-component.problem-updating',
          ),
        );
      }
    }
  }).drop(),

  _createUserSource: task(function* () {
    try {
      let userSource = yield this.store
        .createRecord('user-source', { name: this.userSource })
        .save();
      let sdkApp = yield this._createSdkAppForMobile(userSource.get('formattedName'));
      if (sdkApp) {
        this.get('app.sdkApps').pushObject(sdkApp);
      }

      this.userSources.pushObject(userSource);
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t(
          'components.settings.identity-verification.verify-user-identity-component.problem-adding',
        ),
      );
    }
  }),

  _identityVerificationWebConfirmationOptions() {
    return {
      title: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.domain-with-error',
      ),
      body: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.verification-errors-description',
      ),
      confirmButtonText: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.ignore-and-enforce',
      ),
      cancelButtonText: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.go-back-review',
      ),
      secondaryButtonType: 'primary-destructive',
      invertButtonTypes: true,
    };
  },

  _identityVerificationMobileConfirmationOptions() {
    return {
      title: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.before-enforce',
      ),
      body: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.after-enforce',
      ),
      confirmButtonText: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.enforce-and-disable',
      ),
      cancelButtonText: this.intl.t(
        'components.settings.identity-verification.verify-user-identity-component.go-back',
      ),
      secondaryButtonType: 'primary-destructive',
      invertButtonTypes: true,
    };
  },

  _trackIdentityVerificationToggleEvent(enabled) {
    let stateString = enabled ? 'enabled' : 'disabled';
    this.intercomEventService.trackEvent(`secure-mode-${stateString}`, {
      action: stateString,
      object: 'secure_mode',
      place: `${this.userSource}_settings`,
      owner: 'growth',
    });
  },

  _updateIdentityVerification(enabled) {
    if (this.isWeb) {
      this._updateWebIdentityVerification(enabled);
    } else {
      this._updateMobileIdentityVerification(enabled);
    }
  },

  _updateWebIdentityVerification(enabled) {
    return ajax({
      url: `/ember/apps/${this.get('app.id')}/update_identity_verification`,
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify({
        identity_verified: enabled,
      }),
    });
  },

  _createSdkAppForMobile(userSourceFormattedName) {
    if (['ios', 'android'].includes(this.userSource)) {
      this.store
        .createRecord('sdk-app', {
          admin_id: this.get('admin.id'),
          platform: userSourceFormattedName,
        })
        .save();
    }
  },

  _updateMobileIdentityVerification(enabled) {
    if (!this.sdkApp) {
      this._createUserSource.perform().then(() => {
        this.set('sdkApp.identityVerified', enabled);
        this.sdkApp.save();
      });
    } else {
      this.set('sdkApp.identityVerified', enabled);
      this.sdkApp.save();
    }
  },

  _refreshIdentityVerificationRiskState() {
    return this.identityVerificationRiskStateService.start(this.app);
  },

  closeUserHashChecker: action(function () {
    this.set('showUserHashCheckerModal', false);
  }),

  actions: {
    openMessenger() {
      showNewMessageInIntercomWidget(
        this.intl.t(
          'components.settings.identity-verification.verify-user-identity-component.enable-identity-verification',
          { platformName: this.platformName },
        ),
      );
    },

    chooseInstallationMethod(installMethod) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'install_method',
        place: 'identity_verification',
        context: installMethod,
      });
      this.set('installMethod', installMethod);
    },
  },
});
