/* import __COLOCATED_TEMPLATE__ from './area-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import type Range from 'embercom/models/reporting/range';

import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';
import { cached } from 'tracked-toolbox';
import { zip } from 'underscore';
import { mapXAxisLabel } from 'embercom/lib/reporting/flexible/label-formatter';
import { isPresent, isEmpty } from '@ember/utils';
import { getOwner } from '@ember/application';
import SerieschartBuilder from 'embercom/lib/reporting/flexible/serieschart-builder';
import { inject as service } from '@ember/service';
import HighchartsDataBuilder from 'embercom/lib/reporting/flexible/highcharts-data-builder';
import {
  getChartSeriesName,
  mapHumanReadableLabelsToRawKey,
} from 'embercom/lib/reporting/custom/view-config-builder-helpers';

// @ts-ignore
import PALETTE from '@intercom/pulse/lib/palette';
import type ReportingChartService from 'embercom/services/reporting-chart-service';
import type { Aggregation, Group } from 'embercom/services/reporting-chart-service';
import {
  type ViewConfig,
  type RawChartData,
  type SeriesAreaData,
  type Point,
  type Value,
  type OptionalNumber,
} from 'embercom/services/reporting-chart-service';
import {
  shouldConvertNullsToZeros,
  shouldAllowZeroValues,
  getFirstSeriesBucketKeys,
} from 'embercom/lib/reporting/flexible/data-response-helpers';

interface Signature {
  Args: Args;
  Blocks: {
    default: [{ Empty: Function }];
  };
}

interface Args {
  dataConfig: any;
  range: Range;
  width: string;
  viewConfig: ViewConfig;
  renderableChart: RenderableChart;
  isPaywalled: boolean;
}

export default class AreaChart extends Component<Signature> {
  @service declare reportingChartService: ReportingChartService;
  @service declare intercomEventService: any;
  @service declare appService: any;

  @use dataLoader = AsyncData<ChartDataResourceCompatible>(async () => {
    return new ChartDataResourceCompatible(getOwner(this), {
      dataConfig: this.args.dataConfig,
      viewConfig: this.args.viewConfig,
      renderableChart: this.args.renderableChart,
    });
  });

  get dataResource(): ChartDataResourceCompatible | undefined {
    return this.dataLoader.value;
  }

  get app() {
    return this.appService.app;
  }

  get aggregationFunction() {
    return this.args.dataConfig?.series?.firstObject?.type;
  }

  get shouldAllowZeroValues() {
    return shouldAllowZeroValues(this.args.viewConfig.metrics?.[0], this.aggregationFunction);
  }

  private buildSeriesAreaData(data: RawChartData, keys: any[] = []): SeriesAreaData[] {
    // we assume there's only one group for a simple case
    let group = data.groups[0];
    let aggregation = group.aggregations[0];

    return this.args.renderableChart?.isMultimetric
      ? this.buildMultiMetricSeriesAreaData(group, aggregation, keys)
      : this.buildVisualizationDataFromResponseGroups(group, aggregation, keys);
  }

  private buildVisualizationDataFromResponseGroups(
    group: Group,
    aggregation: Aggregation,
    keys: any[],
  ): SeriesAreaData[] {
    let points: Point[] = zip(group.values, aggregation.values).map(([x, y]) => {
      return { x, y };
    });
    // An array of data points for the series. For the area series type, points will be given as:
    // [x,y,name] e.g
    // data: [ [0, 9, 0], [1, 7, 1] ] or  data: [ [Chat, 9, Chat], [Email, 7, Email] ]
    // reference https://api.highcharts.com/highcharts/series.area.data
    return points
      .map((point): SeriesAreaData | undefined => {
        if (isPresent(keys) && !keys.includes(point.x)) {
          return undefined;
        }
        return this.getTransformedPoint(point);
      })
      .compact();
  }

  private buildMultiMetricSeriesAreaData(
    group: Group,
    aggregation: Aggregation,
    keys: any[],
  ): SeriesAreaData[] {
    if (this.args.renderableChart.isBrokenDownByTime || !isPresent(keys)) {
      // the buckets are correctly ordered by timeline and all responses have the same buckets keys when the chart is viewing by Time
      // there is no need to process the responses using the keys from the first response.
      return this.buildVisualizationDataFromResponseGroups(group, aggregation, keys);
    }
    // An array of data points for the series. For the area series type, points will be given as:
    // [x,y,name] e.g
    // data: [ [0, 9, 0], [1, 7, 1] ] or  data: [ [Chat, 9, Chat], [Email, 7, Email] ]
    // reference https://api.highcharts.com/highcharts/series.area.data
    return keys
      .map((key): SeriesAreaData | undefined => {
        if (!group.values.includes(key)) {
          return undefined;
        }
        let index = group.values.indexOf(key);
        let point = {
          x: key,
          y: aggregation.values[index],
        };
        return this.getTransformedPoint(point);
      })
      .compact();
  }

  getTransformedPoint(point: Point) {
    let y: number | null = point.y !== 0 || this.shouldAllowZeroValues ? point.y : null;
    if (this.args.dataConfig.xAxis.type === 'nominal') {
      let xAxisLabel: string = mapXAxisLabel(this.args.dataConfig, this.args.viewConfig, point.x);
      return { x: xAxisLabel, y, name: xAxisLabel };
    } else {
      return { x: point.x, y, name: point.x };
    }
  }

  get rawChartData() {
    return this.dataResource?.rawChartData || [];
  }

  @cached
  get seriesData(): SeriesAreaData[][] {
    try {
      let firstSeriesBucketKeys = getFirstSeriesBucketKeys(
        this.args.renderableChart,
        this.rawChartData,
      );
      return this.rawChartData.map((data) => this.buildSeriesAreaData(data, firstSeriesBucketKeys));
    } catch (e) {
      this.dataResource?.notifyError();
      return [];
    }
  }

  get isSegmented() {
    // @ts-ignore
    let response = !isEmpty(this.rawChartData) ? this.rawChartData.firstObject : undefined;
    return response && !isEmpty(response.groups) && response.groups[0]?.values[0]?.groups;
  }

  @cached
  get chartData() {
    if (this.isSegmented) {
      return this.chartDataForSegmentedResponse;
    }
    return this.chartDataForSingleResponse;
  }

  get chartDataForSingleResponse() {
    let viewConfig = this.args.viewConfig;
    return zip(viewConfig.metrics, this.seriesData, this.rawChartData).map(
      ([metric, data, _], index) => {
        let seriesName = metric.id;
        let legendLabelFunc = this.getLabelFunction(seriesName, index);
        let aggregation = this.aggregationFunction;

        let cleanedData: SeriesAreaData[] = shouldConvertNullsToZeros(aggregation, metric)
          ? this.reportingChartService.convertNullsToZeros(data)
          : data;

        return {
          name: seriesName,
          data: cleanedData.map((data) => [data.x, data.y, data.name]),
          legendLabel: legendLabelFunc(),
          showInLegend: this.seriesData.length > 1,
          marker: {
            symbol: 'circle',
          },
          rawToLabelMapping: mapHumanReadableLabelsToRawKey(
            this.dataResource?.rawChartData[0],
            this.args.dataConfig,
            this.args.viewConfig,
          ),
        };
      },
    );
  }

  get chartDataForSegmentedResponse() {
    try {
      return new HighchartsDataBuilder(
        getOwner(this),
        this.args.dataConfig,
        this.args.viewConfig,
      ).forStackedChartResponse(this.rawChartData);
    } catch (e) {
      this.dataResource?.notifyError();
      return [];
    }
  }

  get chartOptions() {
    let options = {
      range: this.args.range,
      chartData: this.chartData,
      width: this.args.width,
      viewConfig: this.isSegmented
        ? { ...this.args.viewConfig, mergeTooltipsForCoincidentPoints: true }
        : this.args.viewConfig,
      dataConfig: this.args.dataConfig,
      chartType: 'area',
      seriesColors: this.isSegmented ? undefined : this.getSeriesColor(),
      app: this.app,
      isMultimetric: this.args.renderableChart?.isMultimetric || false,
    };

    // @ts-ignore
    let builder = new SerieschartBuilder(options);
    return builder.buildTheme();
  }

  private getSeriesColor() {
    if (this.args.viewConfig.seriesColors) {
      return this.args.viewConfig.seriesColors;
    } else if (this.chartData.length > 1 && !this.args.renderableChart?.isMultimetric) {
      return [PALETTE['vis-azure-80'], PALETTE['vis-azure-50']];
    }
    return undefined;
  }

  private getLabelFunction(seriesName: string, index: number): () => string | undefined {
    if (this.args.renderableChart?.isMultimetric) {
      return () => getChartSeriesName(index, this.args.renderableChart);
    }
    let legendHash = this.args.viewConfig.legend || {};
    let func = legendHash[seriesName];
    if (func) {
      return func;
    }
    return function () {
      return undefined;
    };
  }

  get hasData() {
    if (this.isSegmented) {
      return this.doesSegmentedResponseHaveData;
    }
    return this.doesSingleResponseHaveData;
  }
  get doesSegmentedResponseHaveData() {
    return this.chartData.some((data: { data: [Value, OptionalNumber, Value][] }) => {
      return data.data.some(([, y]) => y);
    });
  }

  get doesSingleResponseHaveData() {
    if (this.shouldAllowZeroValues) {
      return this.seriesData.flat().some((data) => isPresent(data.y));
    }
    return this.seriesData.flat().some((data) => data.y);
  }

  get showEmptyState() {
    return this.args.isPaywalled || !this.hasData;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Flexible::AreaChart': typeof AreaChart;
  }
}
