/* import __COLOCATED_TEMPLATE__ from './channel-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import {
  AVAILABLE_CHANNEL_MAPPINGS,
  REPORTING_FILTER_SELECT_ALL,
} from 'embercom/lib/reporting/flexible/constants';
import { all } from 'ember-concurrency';

export default class ChannelFilter extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;
  @tracked label = this.formattedLabel;
  @tracked availableChannels = [];
  @tracked selectedDropdownItems = this.args.selected.values || [];
  @tracked operator = this.args.selected.operator || 'category';

  constructor() {
    super(...arguments);
    this.initializeValues.perform();
  }

  get allItems() {
    return this.availableChannels
      .map((channelName) => ({
        text: AVAILABLE_CHANNEL_MAPPINGS[channelName] || channelName,
        value: channelName,
        isSelected: this.selectedDropdownItems.includes(channelName),
      }))
      .sort((channel1, channel2) => channel1.text.localeCompare(channel2.text));
  }

  get selectedChannels() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get items() {
    return this.groupList;
  }

  get groupList() {
    return [
      {
        items: this.allItems,
      },
    ];
  }

  get disabled() {
    return this.availableChannels && this.availableChannels.length === 0;
  }

  get currentLabel() {
    if (this.selectedChannels.length === 0) {
      return this.intl.t('components.reporting.custom.chart-builder.filter-bar.channel.any');
    }

    let channels = this.selectedChannels.map((channel) => {
      return AVAILABLE_CHANNEL_MAPPINGS[channel] || channel;
    });
    return `${channels.join(', ')}`;
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_channel',
      object: this.args.analyticsObject,
      channels: this.selectedChannels,
      operator: this.operator,
    });
  }

  get formattedLabel() {
    if (this.selectedChannels.length === 0) {
      return this.intl.t('components.reporting.custom.chart-builder.filter-bar.channel.any');
    }

    let channels = this.selectedChannels.map((channel) => {
      return AVAILABLE_CHANNEL_MAPPINGS[channel] || channel;
    });
    return channels.join(', ');
  }

  removeInvalidValuesFromSelection() {
    let before = this.selectedChannels;
    let after = before.filter((channel) => this.availableChannels.includes(channel));
    if (after.length === before.length) {
      return;
    }
    if (after.length === 0) {
      this.removeFilter();
      return;
    }
    this.args.setSelected(after);
  }

  @task({ restartable: true }) *initializeValues() {
    let knownValues = yield this.fetchKnownValues.perform();
    this.availableChannels = Array.from(knownValues);
    this.removeInvalidValuesFromSelection();
  }

  @task({ restartable: true }) *fetchKnownValues() {
    let sources = this.args.loadKnownValuesSources || ['conversation'];
    let childTasks = sources.map((source) => this.fetchData.perform(source));
    let responses = yield all(childTasks);
    return new Set(responses.flat());
  }

  @task({ enqueue: true, maxConcurrency: 12 }) *fetchData(source) {
    return yield get('/ember/reporting/known_values', {
      app_id: this.appService.app.id,
      source,
      property: 'channel_type',
    });
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_channel',
      object: this.args.analyticsObject,
      channels: this.selectedChannels,
    });

    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.formattedLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }
}
