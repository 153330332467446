/* import __COLOCATED_TEMPLATE__ from './custom-attributes-table.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */

import templateOnlyComponent from '@ember/component/template-only';
import { type MappingVisibility } from 'embercom/models/crm/attribute-mapping';

export interface Args {
  mappingVisibility: MappingVisibility;
  integrationCode: string;
  sectionName: string;
  section: any;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const CustomAttributesTable = templateOnlyComponent<Signature>();
export default CustomAttributesTable;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::CustomAttributesTable': typeof CustomAttributesTable;
    'settings/data-import/modal/custom-attributes-table': typeof CustomAttributesTable;
  }
}
