/* import __COLOCATED_TEMPLATE__ from './new-conversations-channels-columnchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  AVAILABLE_CHANNEL_MAPPINGS,
  CHANNEL,
  CHANNEL_GROUPING_TERM_SIZE,
  CONTINENT,
  COUNT,
  COUNTRY,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
} from 'embercom/lib/reporting/flexible/constants';
import { inject as service } from '@ember/service';

export default class NewConversationsChannelsColumnchart extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    let groupings = [
      {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
        text: this.intl.t(GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT].textKey),
        isComparison: true,
        createTitleTooltipText: () => {
          return this.intl.t(
            'reporting.reports.conversations-flexible.new-conversations-channels-columnchart.grouped-by-date',
          );
        },
      },
      {
        ...GROUPINGS[CONTINENT],
        text: this.intl.t(GROUPINGS[CONTINENT].textKey),
        createTitleTooltipText: () => {
          return this.intl.t(
            'reporting.reports.conversations-flexible.new-conversations-channels-columnchart.grouped-by-continent',
          );
        },
      },
      {
        ...GROUPINGS[COUNTRY],
        text: this.intl.t(GROUPINGS[COUNTRY].textKey),
        createTitleTooltipText: () => {
          return this.intl.t(
            'reporting.reports.conversations-flexible.new-conversations-channels-columnchart.grouped-by-country',
          );
        },
      },
    ];

    viewConfig.formatUnit = { unit: units.value, displayUnit: 'conversation' };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[CHANNEL],
        text: this.intl.t(GROUPINGS[CHANNEL].textKey),
        createTitleTooltipText: () => {
          return this.intl.t(
            'reporting.reports.conversations-flexible.new-conversations-channels-columnchart.grouped-by-channels',
          );
        },
      },
      rest: groupings,
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      xAxis: {
        type: 'nominal',
        data: {
          property: CHANNEL,
          term_size: CHANNEL_GROUPING_TERM_SIZE,
          labelMappings: AVAILABLE_CHANNEL_MAPPINGS,
        },
      },
      series: [
        {
          name: 'comparison_columnchart',
          source: this.app.canUseFeature('reporting-ember-conversation-index-merging')
            ? 'conversation'
            : 'conversation_with_message_thread',
          type: COUNT,
          data: { property: FIRST_USER_CONVERSATION_PART_CREATED_AT },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
      ],
    };
  }
}
