/* import __COLOCATED_TEMPLATE__ from './table-engagement-report-title-cell.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import ENV from 'embercom/config/environment';

const DELETED_ARTICLE_TITLE = '(Article deleted)';

export default class TableComponent extends Component {
  @service educateEventService;
  @tracked article;
  @tracked title;

  constructor() {
    super(...arguments);

    this.groupName = this.args.row.groupName;
    this.loadArticle.perform();
  }

  get articleId() {
    return this.article?.id;
  }

  @task
  *loadArticle() {
    try {
      this.article = yield this.args.data.articleCollection.get(this.args.row.groupName);
      this.title = this.args.data.getCellValue(this.article);
    } catch (_e) {
      this.article = null;
      this.title = DELETED_ARTICLE_TITLE;
    }
  }

  @action
  openDrawer() {
    this.args.data.toggleEngagementDrawer(this.article);

    if (this.article === null || this.article === undefined) {
      return;
    }

    later(
      this,
      () => {
        // TODO: correct values for tracking
        this.educateEventService.trackEducateArticleEvent(this.article, {
          action: 'visited',
          place: 'insights',
        });
      },
      ENV.APP._2000MS,
    );
  }
}
