/* RESPONSIBLE TEAM: team-reporting */
import Highcharts, {
  Color,
  type ColorString,
  type Options,
  type PlotSeriesOptions,
} from 'highcharts';
import Heatmap from 'highcharts/modules/heatmap';
import Sankey from 'highcharts/modules/sankey';
import PALETTE from '@intercom/pulse/lib/palette';

function reinstateOriginalContrastCalculationFormula() {
  Highcharts.SVGRenderer.prototype.getContrast = function (rgba: ColorString): ColorString {
    let [r, g, b] = (Color.parse(rgba) as any).rgba as number[];
    r *= 1;
    g *= 1.2;
    b *= 0.5;

    return r + g + b > 1.8 * 255 ? '#000000' : '#FFFFFF';
  };
}

interface ImprovedPlotSeriesOptions extends PlotSeriesOptions {
  borderRadius: number;
}

const theme: Options = {
  title: undefined,
  credits: undefined,
  chart: {
    style: {
      fontFamily: 'inherit',
      fontSize: '16px',
    },
  },
  plotOptions: {
    area: {
      lineWidth: 1.7,
    },
    line: {
      lineWidth: 1.7,
    },
    series: {
      borderRadius: 0,
      dataLabels: {
        style: {
          fontSize: '11px',
        },
      },
    } as ImprovedPlotSeriesOptions,
  },
  tooltip: {
    borderWidth: 1,
  },
  legend: {
    itemMarginTop: 0,
    itemMarginBottom: 0,
    navigation: {
      style: {
        fontSize: '12px',
        color: PALETTE.gray,
      },
    },
    itemStyle: {
      fontSize: '12px',
      fontWeight: 'normal',
      color: PALETTE.gray,
    },
  },
  xAxis: {
    labels: {
      autoRotation: undefined,
      rotation: undefined,
      padding: 1,
      distance: 8,
      style: {
        color: PALETTE.gray,
        fontSize: '12px',
        textOverflow: undefined,
        whiteSpace: undefined,
      },
      overflow: undefined,
    },
    lineColor: '#ccd6eb',
  },
  yAxis: {
    labels: {
      distance: 8,
      style: {
        color: PALETTE.gray,
        fontSize: '12px',
      },
    },
  },
  colorAxis: {
    labels: {
      distance: 8,
      style: {
        color: PALETTE.gray,
        fontSize: '12px',
      },
    },
  },
  lang: {
    // the default is a space
    thousandsSep: ',',
  },
};

export function initializeHighcharts() {
  Heatmap(Highcharts);
  Sankey(Highcharts);
  reinstateOriginalContrastCalculationFormula();
  Highcharts.setOptions(theme);
}
