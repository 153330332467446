/* import __COLOCATED_TEMPLATE__ from './new-inbound-conversations-comparison-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import {
  COUNT,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
} from 'embercom/lib/reporting/flexible/constants';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import { inject as service } from '@ember/service';

const SERIES_NAME = 'new_inbound_conversations_comparison_counter';

export default class NewInboundConversationsComparisonCounter extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
        isComparison: true,
        createTitleTooltipText: () =>
          this.intl.t(
            'reporting.conversations-report.new-inbound-conversations-comparison-counter.tooltip',
          ),
      },
    };
    viewConfig.counter = {
      [SERIES_NAME]: {
        improvementDirection: improvementDirections.INCREASING,
      },
    };

    return viewConfig;
  }
  get dataConfig() {
    return {
      series: [
        {
          name: SERIES_NAME,
          source: this.app.canUseFeature('reporting-ember-conversation-index-merging')
            ? 'conversation'
            : 'conversation_with_message_thread',
          type: COUNT,
          data: { property: FIRST_USER_CONVERSATION_PART_CREATED_AT },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'category',
            data: {
              property: 'conversation_started_by_user',
              values: [true],
            },
          },
        },
      ],
    };
  }
}
