/* import __COLOCATED_TEMPLATE__ from './chart-horizontal-bar-with-rule.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { stack } from 'd3-shape';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  attributeBindings: ['data-test-reporting-horizontal-bar-chart-with-rule'],
  'data-test-reporting-horizontal-bar-chart-with-rule': true,
  series: computed('keys', 'report.percentagesByKey', function () {
    let layers = stack()
      .keys(this.keys)
      .value((d, key) => d[key] || 0)([this.report.percentagesByKey]);

    return layers.map((layer) => {
      let [start, end] = layer[0];

      return {
        start,
        end,
        scale: (end - start) / 100,
        key: layer.key,
      };
    });
  }),
});
