/* import __COLOCATED_TEMPLATE__ from './ticket-state-category-filter.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import type IntlService from 'ember-intl/services/intl';
import type { SelectedFilter } from 'embercom/services/filter-pattern-service';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { SystemState, systemStateIdToString } from 'embercom/services/ticket-state-service';

interface Args {
  icon: string;
  name: string;
  initiallyOpened: boolean;
  removeFilter: () => void;
  setSelected: (filterValues: string[], operator: string) => void;
  selected: SelectedFilter;
  type: string;
  analyticsObject: any;
  isDisabled: boolean;
}

export default class TicketStateCategoryFilter extends Component<Args> {
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare ticketStateService: TicketStateService;

  @tracked selectedDropdownItems = this.args.selected.values || [];
  @tracked operator = this.args.selected.operator || 'category';

  @action
  ticketStateLabel(value: string) {
    return this.ticketStateService.getTranslatedSystemState(value);
  }

  get selectedStates() {
    return this.selectedDropdownItems.filter(
      (item: string) => item !== REPORTING_FILTER_SELECT_ALL,
    );
  }

  get allItems() {
    return Object.values(SystemState).map((value: string) => ({
      text: this.ticketStateLabel(value),
      value,
      systemState: systemStateIdToString(value),
      isSelected: this.selectedStates.includes(value),
      component: 'reporting/flexible/filter-bar/ticket-state-category-item',
    }));
  }

  get currentLabel() {
    if (
      this.selectedDropdownItems?.length &&
      this.selectedDropdownItems[0] !== REPORTING_FILTER_SELECT_ALL
    ) {
      return this.selectedDropdownItems
        .map((systemState: string) => this.ticketStateLabel(systemState))
        .join(', ');
    } else {
      return this.intl.t('reporting.custom-reports.any');
    }
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: `filtered_${this.args.type}`,
      object: this.args.analyticsObject,
      state: this.selectedStates,
      operator: this.operator,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::TicketStateCategoryFilter': typeof TicketStateCategoryFilter;
  }
}
