/* import __COLOCATED_TEMPLATE__ from './fin-ai-agent-content-table.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { FinAIType } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  renderableChart?: any;
}

export default class FinAIAgentContentTable extends Component<Args> {
  get aiType() {
    return FinAIType.AGENT;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Bespoke::FinAiAgentContentTable': typeof FinAIAgentContentTable;
    'reporting/bespoke/fin-ai-agent-content-table': typeof FinAIAgentContentTable;
  }
}
