/* import __COLOCATED_TEMPLATE__ from './role-editor.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { CONVERSATION_SECTION_ID, COPILOT_SECTION_ID } from 'embercom/lib/settings/seats/constants';
import { NO_ACCESS, LIMITED_USAGE } from 'embercom/lib/settings/copilot-access-editor/constants';

export default class RoleEditor extends Component {
  @service permissionsService;
  @service permissionsHierarchyService;
  @tracked openSections = {
    [CONVERSATION_SECTION_ID]: false,
    [COPILOT_SECTION_ID]: true,
  };
  @tracked lastSwitchState = false;

  get disableAssignmentLimit() {
    return !this.permissionsService.currentAdminCan('can_manage_workload_management');
  }

  get accessType() {
    return this.args.role.can_inbox__access_copilot ? LIMITED_USAGE : NO_ACCESS;
  }

  get isSelectAllState() {
    let permissions = { ...this.args.role.permissions() };
    let permissionsKeys = Object.keys(permissions);

    return permissionsKeys.some((perm) => permissions[perm] === false);
  }

  get disableToggleAllPermissionsButton() {
    if (this.args.isNewRole) {
      return false;
    }

    return this.args.role !== null;
  }

  get showToggleAllPermissionsButton() {
    return this.args.isNewRole;
  }

  @action toggleSelectAll() {
    let permissions = { ...this.args.role.permissions() };

    let selectAllPermissionsState = !!this.isSelectAllState;
    Object.keys(permissions).forEach((key) => {
      permissions[key] = selectAllPermissionsState;
    });

    Object.assign(this.args.role, permissions);
  }

  @action
  updateCopilotPermission(value) {
    this.args.role.can_inbox__access_copilot = value === LIMITED_USAGE;
  }

  @action
  setAllAccordions(state) {
    this.lastSwitchState = state;
    let sections = Object.values(this.permissionsHierarchyService.sections);
    let clonedOpenSections = {
      ...this.openSections,
      [CONVERSATION_SECTION_ID]: state,
      [COPILOT_SECTION_ID]: state,
    };
    sections.forEach((section) => {
      clonedOpenSections[section.key] = state;
    });
    this.openSections = clonedOpenSections;
  }

  @action
  onClickOpenSection(sectionId) {
    this.openSections = {
      ...this.openSections,
      [sectionId]: !this.openSections[sectionId],
    };
  }
}
