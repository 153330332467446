/* import __COLOCATED_TEMPLATE__ from './customer-satisfaction-report.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ReportingSettings from 'embercom/models/reporting/settings';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { filterableProperties } from 'embercom/lib/reporting/custom/filter-helpers';

const DEFAULT_CHART_DEFINITION = {
  id: null,
  title: null,
  visualization_type: null,
  metric_property: null,
  metric_function: null,
  stacked: false,
  view_by: null,
  view_by_time_interval: null,
  view_by_display_limit: 10,
  view_by_display_other: false,
  width_type: null,
  height_type: null,
};

interface Args {
  settings: typeof ReportingSettings;
  conversationAttributeDescriptors: ConversationAttributeDescriptor[];
}

export default class CustomerSatisfactionReport extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare reportingMetrics: ReportingMetrics;

  get reportDefinition() {
    return {
      id: `csat_v2_report`,
      title: this.intl.t('reporting.customer-satisfaction.title'),
      description: null,
      charts: [],
      date_range: {
        selection: 'past_week',
      },
      filters: {},
    };
  }

  filterableProperties = filterableProperties();

  metricDescription(metricId: string) {
    let metric = this.reportingMetrics.getMetricById(metricId);
    return metric.description;
  }

  get upperCharts() {
    return [
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'overall_csat',
        title: this.intl.t('reporting.reports.customer-satisfaction.overall-csat.title'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'conversation_rating.any_agent.csat',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'teammate_csat',
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'conversation_rating.human.csat',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'fin_csat',
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'conversation_rating.ai_agent.csat',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'csat_over_time',
        title: this.intl.t('reporting.reports.customer-satisfaction.csat-over-time.title'),
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.FULL,
        view_by: 'time',
        chart_series: [
          {
            metric_id: 'conversation_rating.any_agent.csat',
            show_data_labels: true,
            label: this.intl.t('reporting.reports.customer-satisfaction.csat-over-time.any-agent'),
          },
          {
            metric_id: 'conversation_rating.ai_agent.csat',
            show_data_labels: true,
          },
          {
            metric_id: 'conversation_rating.human.csat',
            show_data_labels: true,
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'conversation_ratings',
        title: this.intl.t('reporting.reports.customer-satisfaction.conversation-ratings.title'),
        visualization_type: VISUALIZATION_TYPES.BAR,
        visualization_options: {
          show_relative_values: true,
        },
        stacked: true,
        view_by: 'time',
        segment_by: 'rating_index',
        width_type: WIDTH_TYPES.FULL,
        chart_series: [
          {
            metric_id: 'conversation_rating.any_agent.count',
          },
        ],
      },
    ];
  }

  get lowerCharts() {
    return [
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'sent_rate',
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'conversation_rating.sent_rate',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'response_rate',
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'conversation_rating.response_rate',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'conversations_with_remarks',
        title: this.intl.t(
          'reporting.reports.customer-satisfaction.conversations-with-remarks.title',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id:
              'conversation_rating.conversations_with_a_conversation_rating_response.count',
            filters: {
              type: 'and',
              filters: [
                {
                  type: 'category',
                  data: {
                    property: 'rating_index',
                    attribute: 'conversation_rating_sent.rating_index',
                    values: ['any'],
                  },
                },
                {
                  type: 'category',
                  data: {
                    property: 'remark',
                    attribute: 'conversation_rating_sent.remark',
                    values: ['any'],
                  },
                },
              ],
            },
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'csat_survey_request_response_rates',
        title: this.intl.t(
          'reporting.reports.customer-satisfaction.csat-survey-request-response-rates.title',
        ),
        visualization_type: VISUALIZATION_TYPES.AREA,
        width_type: WIDTH_TYPES.FULL,
        view_by: 'time',
        chart_series: [
          {
            metric_id: 'conversation_rating.sent_rate',
            show_data_labels: true,
          },
          {
            metric_id: 'conversation_rating.response_rate',
            show_data_labels: true,
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'topics_with_negative_ratings',
        title: this.intl.t(
          'reporting.reports.customer-satisfaction.topics-with-negative-ratings.title',
        ),
        visualization_type: VISUALIZATION_TYPES.HORIZONTAL_BAR,
        width_type: WIDTH_TYPES.FULL,
        view_by: 'topic',
        view_by_display_limit: 10,
        chart_series: [
          {
            metric_id: 'conversation_rating.any_agent.count',
            filters: {
              type: 'and',
              filters: [
                {
                  type: 'category',
                  data: {
                    property: 'rating_index',
                    attribute: 'conversation_rating_sent.rating_index',
                    values: ['1', '2', '3'],
                  },
                },
              ],
            },
          },
        ],
        footer_text: 'reporting.overview.charts.most-common-topics.footer-text',
        footer_cta_component: 'reporting/overview/most-common-topics-footer-cta',
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'teammate_csat_table',
        title: this.intl.t('reporting.reports.customer-satisfaction.teammate-csat-table.title'),
        visualization_type: VISUALIZATION_TYPES.TABLE,
        width_type: WIDTH_TYPES.FULL,
        height_type: HEIGHT_TYPES.X_TALL,
        view_by: 'teammate',
        view_by_display_limit: 10000,
        grid_height: 7, // chart-card.ts determines the height of the table based off the chart height, setting this here to make sure the table fits in the card
        chart_series: [
          {
            metric_id: 'conversation_rating.human.csat',
          },
          {
            metric_id: 'conversation_rating.human.dsat',
          },
          {
            metric_id:
              'conversation_rating.conversations_with_a_conversation_rating_response.count',
            filters: {
              type: 'and',
              filters: [
                {
                  type: 'category',
                  data: {
                    property: 'rating_index',
                    attribute: 'conversation_rating_sent.rating_index',
                    values: ['any'],
                  },
                },
                {
                  type: 'category',
                  data: {
                    property: 'remark',
                    attribute: 'conversation_rating_sent.remark',
                    values: ['any'],
                  },
                },
              ],
            },
            label: this.intl.t(
              'reporting.reports.customer-satisfaction.teammate-csat-table.remarks-header',
            ),
          },
        ],
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Reports::CustomerSatisfaction::CustomerSatisfactionReport': typeof CustomerSatisfactionReport;
  }
}
