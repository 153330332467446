/* import __COLOCATED_TEMPLATE__ from './pick-assignee.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

const OWNER_ID = '-1';
const ASSIGN_TO = 'assign-conversation';
const ASSIGN_TO_OWNER = 'assign-conversation-to-owner';
const REASSIGN_TO = 'reassign-conversation';

export default Component.extend({
  appService: service(),
  intl: service(),
  router: service(),
  app: readOnly('appService.app'),
  allowTeams: true,
  allowTeammates: computed('actionEditorContext.allowTeammates', function () {
    let value = this.get('actionEditorContext.allowTeammates');
    return value === undefined ? true : value;
  }),
  allowOwnerAssignee: computed('actionEditorContext.allowOwnerAssignee', function () {
    let value = this.get('actionEditorContext.allowOwnerAssignee');
    return value === undefined ? true : value;
  }),
  allowUnassignedAdmin: computed(
    'actionEditorContext.allowUnassignedAdminForAssign',
    'requireUnassigned',
    function () {
      return (
        this.get('actionEditorContext.allowUnassignedAdminForAssign') && !this.requireUnassigned
      );
    },
  ),
  enableRoundRobin: true,
  name: computed('intl.locale', 'requireUnassigned', 'app.canSeeReassignAction', function () {
    if (this.requireUnassigned || !this.get('app.canSeeReassignAction')) {
      return this.intl.t('settings.inbox-rules.pick-assignee.assign-to');
    } else {
      return this.intl.t('settings.inbox-rules.pick-assignee.reassign-to');
    }
  }),
  groupedItems: computed(
    'intl.locale',
    'action.type',
    'items.[]',
    'app.assignableHumanAdmins.[]',
    'app.assignableTeams.[]',
    'app.unassignedAdmin',
    'allowUnassignedAdmin',
    'allowTeams',
    'allowTeammates',
    'allowOwnerAssignee',
    function () {
      let items = this.items;

      if (items) {
        return items;
      }

      let assignableHumanAdmins = this.allowTeammates ? this.get('app.assignableHumanAdmins') : [];
      let teams = this.allowTeams && this.get('app.assignableTeams');
      let unassigned = this.allowUnassignedAdmin && this.get('app.unassignedAdmin');
      let assignees = this._mapAssigneesToDropdownItems(assignableHumanAdmins);

      if (this.allowOwnerAssignee) {
        assignees.unshift({
          text: this.get('app.owner.name'),
          value: this.get('app.owner.id'),
          component: 'common/avatar-and-name-dropdown-item',
          componentAttrs: {
            model: this.get('app.owner'),
            modelDisplayName: this.get('app.owner.name'),
            avatarSize: 'xs',
          },
        });
      }

      let groupTitles = {
        teams: this.intl.t('settings.inbox-rules.pick-assignee.teams'),
        teammates: this.intl.t('settings.inbox-rules.pick-assignee.teammates'),
      };

      if (this.router.currentRouteName?.includes('apps.app.automation.workflows.edit')) {
        groupTitles.teams = this.intl.t('settings.inbox-rules.pick-assignee.teams_wd', {
          url: this.router.urlFor('apps.app.settings.helpdesk.teams'),
          htmlSafe: true,
        });
        groupTitles.teammates = this.intl.t('settings.inbox-rules.pick-assignee.teammates_wd', {
          url: this.router.urlFor('apps.app.settings.workspace.teammates'),
          htmlSafe: true,
        });
      }

      return [
        teams && {
          heading: groupTitles.teams,
          hasItemsMarkableAsSelected: true,
          items: this._mapAssigneesToDropdownItems(teams),
        },
        assignees && {
          heading: groupTitles.teammates,
          hasItemsMarkableAsSelected: true,
          items: assignees,
        },
        unassigned && {
          heading: this.intl.t('settings.inbox-rules.pick-assignee.other'),
          hasItemsMarkableAsSelected: true,
          items: this._mapAssigneesToDropdownItems([unassigned]),
        },
      ].filter(Boolean);
    },
  ),

  assignee: computed('action.type', 'action.actionData.assignee_id', function () {
    if (
      this.get('action.actionData.assignee_id') === undefined &&
      this.get('action.type') === ASSIGN_TO_OWNER
    ) {
      return this.get('app.owner');
    }

    let assigneeId = this.get('action.actionData.assignee_id');
    if (assigneeId) {
      return this._getAssigneeById(`${assigneeId}`);
    }
  }),

  requireUnassigned: computed(
    'action.type',
    'action.actionData.skip_assigned',
    'action.actionData.assignee_id',
    'app.canSeeReassignAction',
    function () {
      if (!this.get('app.canSeeReassignAction')) {
        return false;
      }

      // Reassign action was explicitly chosen, so we always assign
      if (this.get('action.type') === REASSIGN_TO) {
        return false;
      }

      // New assignment action created, so default to not reasssigning
      if (!this.get('action.actionData.assignee_id')) {
        return true;
      }

      // Consider already existing ones to reassign, maintaining the way it worked before it was configurable
      return this.get('action.actionData.skip_assigned') || false;
    },
  ),

  actions: {
    updateAssignee(assigneeId) {
      if (assigneeId === OWNER_ID) {
        this._setActionDataForOwnerAssignment();
        return;
      }
      let assignee = this._getAssigneeById(assigneeId);
      let actionData = { assignee_id: assigneeId };
      if (!assignee.get('isTeam')) {
        actionData.round_robin = false;
      }
      this._setActionData(actionData);
    },
  },

  _mapAssigneesToDropdownItems(assignees) {
    return assignees.map((assignee) => {
      return {
        text: assignee.get('name'),
        value: assignee.get('id'),
        component: 'common/avatar-and-name-dropdown-item',
        componentAttrs: {
          model: assignee,
          modelDisplayName: assignee.get('name'),
          avatarSize: 'xs',
        },
      };
    });
  },

  _getAssigneeById(assigneeId) {
    let assignee = this.get('app.teams').findBy('id', assigneeId);

    if (assignee === undefined) {
      assignee = this.get('app.admins').findBy('id', assigneeId);
    }

    return assignee;
  },

  _setActionDataForOwnerAssignment() {
    this.setProperties({
      'action.type': ASSIGN_TO_OWNER,
      'action.actionData': { skip_assigned: this.requireUnassigned },
    });

    this._updateOnChange();
  },

  _setActionData(data) {
    data.skip_assigned = this.requireUnassigned;

    this.setProperties({
      'action.type': ASSIGN_TO,
      'action.actionData': data,
    });

    this._updateOnChange();
  },

  _updateOnChange() {
    let onChange = this.onChange;
    if (onChange) {
      onChange();
    }
  },
});
