/* import __COLOCATED_TEMPLATE__ from './bucket-range-table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';

export default class BucketRangeTable extends Component {
  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.args.dataConfig,
    viewConfig: this.args.viewConfig,
  }));

  get hasData() {
    return this.dataResource.rawChartData.some((singleSeries) => {
      return singleSeries.data.some((dataArray) => dataArray[1]);
    });
  }

  get columns() {
    let columnWidth = (100 / this.args.viewConfig.tableChart.columns.length).toFixed(1);
    return this.args.viewConfig.tableChart.columns.map(({ label, valuePath }) => {
      return {
        label,
        valuePath,
        width: `${columnWidth}%`,
      };
    });
  }

  get rawTableData() {
    return this.dataResource.rawChartData[0].aggregations[0].values[0];
  }

  get sumOfAllDocuments() {
    return this.rawTableData.map((x) => x.doc_count).reduce((acc, v) => (acc += v));
  }

  get data() {
    let rawTableData = this.rawTableData;
    let sumOfAllDocuments = this.sumOfAllDocuments;

    return rawTableData.map((value) => {
      let percentageOfValues;
      let key;

      if (value.from === undefined) {
        key = `*-${value.to}`;
      } else if (value.to === undefined) {
        key = `${value.from}-*`;
      } else {
        key = `${value.from}-${value.to}`;
      }

      if (sumOfAllDocuments === 0) {
        percentageOfValues = 0;
      } else {
        percentageOfValues = (value.doc_count / sumOfAllDocuments) * 100;
      }

      return {
        key,
        count: value.doc_count,
        value: percentageOfValues,
      };
    });
  }
}
