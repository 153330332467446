/* import __COLOCATED_TEMPLATE__ from './edit-mode.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class SettingsEditMode extends Component {
  @service seriesEditorService;

  seriesEntityType = objectTypes.series;
  defaultGoalTimeConstraint = 2160;
}
