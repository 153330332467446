/* import __COLOCATED_TEMPLATE__ from './time-period-row-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly, gt, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import TimePeriod from 'embercom/models/settings/time-period';
import TimesByHalfHour from 'embercom/models/data/times-by-half-hour';
import TimePeriods from 'embercom/models/data/time-periods';
import { ternary } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  tagName: '',
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  intl: service(),
  availablePeriods: computed(
    'row.period',
    'intl.locale',
    'row.timeIntervals.@each.{startMinute,endMinute}',
    function () {
      let translatedTimePeriods = TimePeriods.map((period) => ({
        text: this.intl.t(period.textTranslationKey),
        value: period.value,
      }));
      return translatedTimePeriods;
    },
  ),
  availableStartTimes: TimesByHalfHour,
  availableEndTimes: computed('row.startTime', function () {
    let startTime = this.get('row.startTime');
    return TimesByHalfHour.filter((time) => time.value > startTime);
  }),
  endTime: computed('row.startTime', 'row.endTime', function () {
    let startTime = this.get('row.startTime');
    let endTime = this.get('row.endTime');
    if (endTime <= startTime) {
      return undefined;
    }
    return endTime;
  }),
  hasTimes: gt('availableTimes.length', 0),
  hasSingleRow: equal('availableTimes.length', 1),
  rowRemovalAction: ternary('hasSingleRow', 'removeLastRow', 'removeRow'),

  actions: {
    timeSelectionChanged(key, value) {
      let row = this.row;
      row.set(key, value);
    },

    periodSelectionChanged(value) {
      let row = this.row;
      let periodValues = TimePeriod.periodValueMapping[value];
      row.setProperties(periodValues);
    },
  },
});
