/* import __COLOCATED_TEMPLATE__ from './rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';

export default Component.extend({
  tagName: '',
  addRule: action(function () {
    this.rules.createFragment();

    let onChange = this.onChange;
    if (onChange) {
      onChange();
    }
  }),

  removeRule: action(function (rule) {
    this.rules.removeFragment(rule);

    let onChange = this.onChange;
    if (onChange) {
      onChange();
    }
  }),
});
