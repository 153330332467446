/* import __COLOCATED_TEMPLATE__ from './edit-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';

export default class TopicShowButtonsComponent extends Component {
  @service router;
  @service notificationsService;
  @service intercomEventService;
  @service appService;
  @service intl;

  @task
  *saveTopic() {
    let isNew = this.args.topic.isNew;
    let { keyphrasesAdded, keyphrasesDeleted } = this.getKeyphraseDifference();
    if (!this.args.topic.name?.length) {
      this.args.topic.name = this.args.topic.keyphrasesText;
    }
    try {
      yield this.args.topic.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: 'topic',
        place: 'topics',
        topic_id: this.args.topic.id,
        topic_name: this.args.topic.name,
        is_new: isNew,
        keyphrases_added: keyphrasesAdded,
        keyphrases_deleted: keyphrasesDeleted,
      });
      if (isNew) {
        this.intercomEventService.trackEvent('created-conversation-topic');
      } else {
        this.intercomEventService.trackEvent('saved-conversation-topic');
      }
      if (this.args.removedConversationIds?.length) {
        yield this.removeConversationsFromTopic.perform();
      }
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'reporting.conversational-insights.topic-edit-buttons.notifications.save-topic.confirmation',
        ),
      );
      this.router.transitionTo(
        'apps.app.reports.conversational-insights.topics.topic.show',
        this.args.topic.id,
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t(
          'reporting.conversational-insights.topic-edit-buttons.notifications.save-topic.error',
        ),
      );
    }
  }

  @task
  *removeConversationsFromTopic() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'conversation_removed',
      object: 'topic',
      place: 'topics',
      topic_id: this.args.topic.id,
      conversation_ids: this.args.removedConversationIds,
    });
    yield ajax({
      url: `/ember/conversational_insights/conversation_topics/${this.args.topic.id}/exclude_conversations`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        conversation_ids: this.args.removedConversationIds,
      }),
    });
    this.notificationsService.notifyWarning(
      this.intl.t(
        'reporting.conversational-insights.topic-edit-buttons.notifications.remove-conversations-from-topic.warning',
      ),
    );
  }

  @action
  cancelEdit() {
    let isNew = this.args.topic.isNew;
    this.args.topic.rollbackAttributes();
    if (isNew) {
      this.router.transitionTo('apps.app.reports.conversational-insights.topics');
    } else {
      this.router.transitionTo('apps.app.reports.conversational-insights.topics.topic.show');
    }
  }

  @task
  *deleteTopic() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'deleted',
      object: 'topic',
      place: 'topics',
      topic_id: this.args.topic.id,
      topic_name: this.args.topic.name,
    });

    try {
      yield this.args.topic.destroyRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'reporting.conversational-insights.topic-edit-buttons.notifications.delete-topic.confirmation',
        ),
      );
      this.router.transitionTo('apps.app.reports.conversational-insights.topics');
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t(
          'reporting.conversational-insights.topic-edit-buttons.notifications.delete-topic.error',
        ),
      );
    }
  }

  getKeyphraseDifference() {
    let changedAttributes = this.args.topic.changedAttributes();
    if (!changedAttributes.keyphrases) {
      return {};
    }
    let [keyphrasesBefore, keyphrasesAfter] = changedAttributes.keyphrases;
    return {
      keyphrasesAdded: keyphrasesAfter?.reject((k) => keyphrasesBefore?.includes(k)),
      keyphrasesDeleted: keyphrasesBefore?.reject((k) => keyphrasesAfter?.includes(k)),
    };
  }
}
