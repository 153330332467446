/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { compareBlocks } from 'embercom/lib/developer-hub/diff-calculator';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AnnotationsComposerConfig from 'embercom/lib/series/annotations/composer-config';

export default class Editor extends Component {
  @service store;
  @service seriesEditorService;
  @tracked blocksDocument = [];
  composer;

  constructor() {
    super(...arguments);

    this.blocksDocument = new BlocksDocument(this.annotation.blocks.serialize());
  }

  get annotation() {
    return this.args.annotation;
  }

  get serializedBlocks() {
    return this.annotation.blocks.serialize();
  }

  get composerConfig() {
    return new AnnotationsComposerConfig();
  }

  @action
  onEditModeChange() {
    this.blocksDocument = new BlocksDocument(this.serializedBlocks);
  }

  @action
  onComposerReady(composer) {
    this.composer = composer;
  }

  @action
  onComposerChange(newBlocksDoc) {
    let blocks = newBlocksDoc.blocks;
    if (compareBlocks(blocks, this.annotation.blocks.serialize()).hasDifference) {
      let blockFragments = blocks.map((block) => createFragmentFromBlock(this.store, block));
      this.annotation.set('blocks', blockFragments);
    }
  }
}
