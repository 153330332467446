/* import __COLOCATED_TEMPLATE__ from './closed-conversations-comparison-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  CARDINALITY,
  CONVERSATION_ID,
  COMMENT_CREATED_AT,
  CLOSES_WITH_USER_PART_FILTERS,
} from 'embercom/lib/reporting/flexible/constants';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import { inject as service } from '@ember/service';

const seriesName = 'closed_conversations_counter';

export default class ClosedConversationsComparisonCounter extends Component {
  @service intl;

  get modifiedFilters() {
    let newFilters = { ...this.args.filters };

    if (newFilters.admin_participant_ids) {
      newFilters.teammate_id = newFilters.admin_participant_ids;
      delete newFilters.admin_participant_ids;
    }

    return newFilters;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value };
    viewConfig.aggregations = { defaultOption: CARDINALITY };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[COMMENT_CREATED_AT],
        isComparison: true,
        createTitleTooltipText: () =>
          this.intl.t('reporting.conversations-report.closed-conversations.tooltip'),
      },
    };
    viewConfig.counter = {
      [seriesName]: {
        improvementDirection: improvementDirections.INCREASING,
      },
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      series: [
        {
          name: seriesName,
          source: 'consolidated_conversation_part',
          type: CARDINALITY,
          data: { property: CONVERSATION_ID },
          time: {
            property: COMMENT_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'and',
            filters: CLOSES_WITH_USER_PART_FILTERS,
          },
        },
      ],
    };
  }
}
