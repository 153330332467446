/* import __COLOCATED_TEMPLATE__ from './country-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { get } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import {
  COUNTRY_ALIASES,
  COUNTRY_NAME_TO_VALUE_LOOKUP,
} from 'embercom/lib/reporting/flexible/constants';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

export default class CountryFilter extends Component {
  @service appService;
  @service intercomEventService;
  @tracked availableCountries = [];
  @tracked label = this.currentLabel;
  // store the selection here so that the UI doesn't update until the drop down is closed
  selectedDropdownItems = this.args.selected;
  // We transform the values back and forth from a list of country to a list of names of country
  // We do this to link a label (Russia) to its multiple values (Russia, Russian Federation) in the backend

  constructor() {
    super(...arguments);
    this.loadKnownValues.perform();
  }

  get groupList() {
    return [
      {
        heading: 'Countries',
        items: this.allItems,
      },
    ];
  }

  get allItems() {
    return this.availableCountries.map((countryName) => ({
      text: countryName,
      value: COUNTRY_NAME_TO_VALUE_LOOKUP[countryName] || [countryName],
      icon: 'globe',
      isSelected: this.args.selected.includes(countryName),
    }));
  }

  get selectedCountries() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get items() {
    return this.groupList;
  }

  get disabled() {
    return this.availableCountries && this.availableCountries.length === 0;
  }

  removeEmptyCountry(countries) {
    return countries.filter((row) => row.trim().length > 0);
  }

  getCanonicalCountryNames(countries) {
    return Array.from(
      countries.reduce((acc, country, index) => {
        let _country = COUNTRY_ALIASES[country] || country;
        acc.add(_country);

        return acc;
      }, new Set()),
    );
  }

  cleanData(data) {
    let filteredCountries = this.removeEmptyCountry(data);
    return this.getCanonicalCountryNames(filteredCountries);
  }

  @task
  *loadKnownValues() {
    let countries = yield get('/ember/reporting/known_values', {
      app_id: this.appService.app.id,
      source: 'conversation',
      property: 'user_location.country_name',
    });
    this.availableCountries = this.cleanData(countries);
    this.availableCountries.sort();
  }

  get currentLabel() {
    let numSelected = this.getCanonicalCountryNames(this.selectedCountries).length;
    if (numSelected === 0) {
      return 'is Any';
    }

    return `is ${this.selectedCountries.join(', ')}`;
  }

  @action
  onClose() {
    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_country',
      object: this.args.analyticsObject,
      countries: this.selectedCountries,
    });

    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }
}
