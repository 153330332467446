/* import __COLOCATED_TEMPLATE__ from './multiple-seats-overage.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import currencyFormatter from 'embercom/lib/currency-formatter';
import { calculateSeatOveragesCountForMultipleSeatTypes } from 'embercom/lib/admins/multiple-seats-info';
import { inject as service } from '@ember/service';
import { COLLABORATOR_SEAT_TYPE } from 'embercom/lib/settings/seats/constants';
import { PLAN_DATA } from 'embercom/lib/billing';

export default class MultipleSeatsOverage extends Component {
  @service appService;
  @service customerService;
  @service intl;

  get formattedSeatCost() {
    return currencyFormatter(this.price, this._currencyPrecision);
  }

  get seatOverageCount() {
    return calculateSeatOveragesCountForMultipleSeatTypes(
      this.args.seatUsageAndPrice,
      this.args.initialSeatUsagesForSelectedTeammate,
      this.args.updatedSeatUsages,
    );
  }

  get price() {
    return this.args.seatUsageAndPrice?.price || 0;
  }

  get _currencyPrecision() {
    return this.price % 100 === 0 ? 0 : 2;
  }

  get shouldRenderOverageBanner() {
    let { seatType } = this.args;
    let { onPricing5 } = this.appService.app;

    return onPricing5 ? false : seatType !== COLLABORATOR_SEAT_TYPE;
  }

  get hasCompoundSeatPrice() {
    return Object.keys(this._chargeableSeatPriceByPlan(this.args.seatType)).length > 1;
  }

  get seatPriceBreakdownTooltip() {
    let seatType = this.args.seatType;
    let seatPriceByPlan = this._chargeableSeatPriceByPlan(seatType);
    let sorted = [];
    Object.keys(seatPriceByPlan).forEach((planId) => {
      let rawPrice = seatPriceByPlan[planId];
      let precision = rawPrice % 100 === 0 ? 0 : 2;
      let price = currencyFormatter(rawPrice, precision);
      if (PLAN_DATA[planId].addon) {
        sorted.push(`${price} ${this.intl.t(PLAN_DATA[planId].nameWithProductTranslationKey)}`);
      } else {
        sorted.unshift(`${price} ${this.intl.t(PLAN_DATA[planId].nameWithProductTranslationKey)}`);
      }
    });

    return sorted.join(' + ');
  }

  _chargeableSeatPriceByPlan(seatType) {
    let priceByPlan = this.customerService.customer.seatTypePriceByPlan[seatType] || {};

    return Object.keys(priceByPlan)
      .filter((key) => priceByPlan[key] !== 0)
      .reduce((newObj, key) => {
        newObj[key] = priceByPlan[key];
        return newObj;
      }, {});
  }
}
