/* import __COLOCATED_TEMPLATE__ from './snoozed-conversations-comparison-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  COUNT,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
} from 'embercom/lib/reporting/flexible/constants';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import { inject as service } from '@ember/service';

const seriesName = 'snoozed_conversations_counter';

export default class SnoozedConversationsColumnchart extends Component {
  @service intl;

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
        isComparison: true,
        createTitleTooltipText: () =>
          this.intl.t('reporting.conversations-report.snoozed-conversations.tooltip'),
      },
    };
    viewConfig.counter = {
      [seriesName]: {
        improvementDirection: improvementDirections.INCREASING,
      },
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      series: [
        {
          name: seriesName,
          source: 'conversation_state',
          type: COUNT,
          data: { property: FIRST_USER_CONVERSATION_PART_CREATED_AT },
          time: {
            property: 'state',
            data_model: 'interval',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'category',
            data: {
              property: 'conversation_state',
              values: ['snoozed'],
            },
          },
        },
      ],
    };
  }
}
