/* import __COLOCATED_TEMPLATE__ from './busiest-period-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import {
  COUNT,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  MAX_FIRST_USER_CONVERSATION_PART_CREATED_AT_DAY_OF_WEEK,
} from 'embercom/lib/reporting/flexible/constants';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { inject as service } from '@ember/service';

export default class BusiestPeriodCounter extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[MAX_FIRST_USER_CONVERSATION_PART_CREATED_AT_DAY_OF_WEEK],
        createTitleTooltipText: () => '',
      },
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      yAxis: {
        name: 'hour_of_day',
        type: 'temporal',
        data: {
          interval: 'hour_of_day',
          property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
        },
      },
      xAxis: {
        name: 'day_of_week',
        type: 'temporal',
        data: {
          interval: 'day_of_week',
          property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
        },
      },
      series: [
        {
          name: 'busiest_period_counter',
          source: this.app.canUseFeature('reporting-ember-conversation-index-merging')
            ? 'conversation'
            : 'conversation_with_message_thread',
          type: COUNT,
          data: { property: FIRST_USER_CONVERSATION_PART_CREATED_AT },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
      ],
    };
  }
}
