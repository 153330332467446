/* import __COLOCATED_TEMPLATE__ from './active-view.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';

export default class ActiveView extends Component {
  @service seriesEditorService;

  get activeConfig() {
    let config = this.args.config[this.seriesEditorService.activeView];
    if (isNone(config)) {
      throw new Error(
        `No configuration found to render a view for ${this.seriesEditorService.activeView}`,
      );
    }
    return config;
  }
}
