/* import __COLOCATED_TEMPLATE__ from './heatmap-column.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',
  intl: service(),
  get hourIntervalTimes() {
    return [
      this.intl.t('reporting.chart-heatmap.hours.12am'),
      this.intl.t('reporting.chart-heatmap.hours.1am'),
      this.intl.t('reporting.chart-heatmap.hours.2am'),
      this.intl.t('reporting.chart-heatmap.hours.3am'),
      this.intl.t('reporting.chart-heatmap.hours.4am'),
      this.intl.t('reporting.chart-heatmap.hours.5am'),
      this.intl.t('reporting.chart-heatmap.hours.6am'),
      this.intl.t('reporting.chart-heatmap.hours.7am'),
      this.intl.t('reporting.chart-heatmap.hours.8am'),
      this.intl.t('reporting.chart-heatmap.hours.9am'),
      this.intl.t('reporting.chart-heatmap.hours.10am'),
      this.intl.t('reporting.chart-heatmap.hours.11am'),
      this.intl.t('reporting.chart-heatmap.hours.12pm'),
      this.intl.t('reporting.chart-heatmap.hours.1pm'),
      this.intl.t('reporting.chart-heatmap.hours.2pm'),
      this.intl.t('reporting.chart-heatmap.hours.3pm'),
      this.intl.t('reporting.chart-heatmap.hours.4pm'),
      this.intl.t('reporting.chart-heatmap.hours.5pm'),
      this.intl.t('reporting.chart-heatmap.hours.6pm'),
      this.intl.t('reporting.chart-heatmap.hours.7pm'),
      this.intl.t('reporting.chart-heatmap.hours.8pm'),
      this.intl.t('reporting.chart-heatmap.hours.9pm'),
      this.intl.t('reporting.chart-heatmap.hours.10pm'),
      this.intl.t('reporting.chart-heatmap.hours.11pm'),
    ];
  },
  series: computed('intl.locale', 'data', 'colorScale', function () {
    if (!this.data || !this.colorScale) {
      return [];
    }

    return this.data.map((item) => ({
      ...item,
      hour: this.hourIntervalTimes[item.key],
      isMaxCell: item.value === this.maxValue,
      color: this.colorScale(item.value),
    }));
  }),
});
