/* import __COLOCATED_TEMPLATE__ from './condition-delay-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class SeriesNodeConditionDelayEditorComponent extends Component {
  @tracked popoverOpen = false;

  @action onPopoverChange(openState) {
    this.popoverOpen = openState;
  }
}
