/* import __COLOCATED_TEMPLATE__ from './blocked-user-list-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { readOnly, alias, and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import UserOrCompanyListComponentBase from 'embercom/components/base/user-or-company-list-base';
import { task } from 'ember-concurrency';

export default UserOrCompanyListComponentBase.extend({
  classNames: ['flex-auto', 'flex', 'flex-col'],
  appService: service(),
  app: readOnly('appService.app'),
  store: service(),
  responseArrayName: 'blockedUsers',
  items: alias('blockedUsers'),
  displayEmptyState: and('isNotLoading', 'hasEmptyItemList'),
  hasReachedLastPage: false,
  isLoading: true,

  // Called in the nextPage action defined in UserOrCompanyListComponentBase
  searchFunction(_app, _predicates, options) {
    return this._searchFunction.perform(options);
  },

  _searchFunction: task(function* (options) {
    let blockedUsers = yield this.store.query('blocked-user', {
      page: options.page || 1,
      per_page: options.per_page || 30,
    });

    let { page: currentPage, total_pages: lastPage } = blockedUsers.meta;
    if (currentPage === lastPage) {
      this.set('hasReachedLastPage', true);
    }

    return {
      blockedUsers,
      totalCount: (blockedUsers.meta && blockedUsers.meta.total_count) || 0,
    };
  }).drop(),
});
