/* import __COLOCATED_TEMPLATE__ from './suggestions.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { type Entity } from 'embercom/components/reporting/custom/report/share';
import { cached } from 'tracked-toolbox';
import Role from 'embercom/models/role';
import { action } from '@ember/object';

interface Args {
  title: string;
  entities: Entity[];
  onClick: (entity: Entity) => void;
  isAdmin: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

export default class ReportingCustomReportShareSuggestions extends Component<Signature> {
  @cached
  get perRoleSize() {
    return Role.adminPerRole();
  }

  @action
  getSize(entity: Entity) {
    if (entity instanceof Role) {
      return this.perRoleSize[entity.id] ?? 0;
    }
    return 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::Share::Suggestions': typeof ReportingCustomReportShareSuggestions;
    'reporting/custom/report/share/suggestions': typeof ReportingCustomReportShareSuggestions;
  }
}
