/* import __COLOCATED_TEMPLATE__ from './reference-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { REFERENCES_MANY } from 'embercom/models/objects/constants/reference-types';

export default class ReferenceAttribute extends Component {
  @service customObjectsService;

  get canContainMultiple() {
    return this.args.referenceType === REFERENCES_MANY;
  }

  get referencedObjectType() {
    return this.customObjectsService.findObjectTypeByIdentifier(
      this.args.referencedObjectTypeIdentifier,
    );
  }
}
