/* import __COLOCATED_TEMPLATE__ from './deliverability-table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { REPORTING_RANGE_UPDATED } from 'embercom/services/reporting-service';
import { action } from '@ember/object';
import { failureListCategories } from 'embercom/models/data/stats-system/stats-constants';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import { registerDestructor } from '@ember/destroyable';

const PER_PAGE = 10;

export default class DeliverabilityTable extends Component {
  @service outboundHomeService;
  @service reportingService;
  @service intl;

  @tracked pageFrom = 0;
  @tracked data = [];
  @tracked lastPageHit = true;
  @tracked isLoadingRulesets = true;

  statisticKeys = statisticKeys;
  failureCategories = failureListCategories;

  constructor() {
    super(...arguments);

    this._fetch.perform();
    let fetchData = () => {
      this.isLoadingRulesets = true;
      this.pageFrom = 0;
      this.data = [];
      this._fetch.perform();
    };
    this.reportingService.on(REPORTING_RANGE_UPDATED, fetchData);

    registerDestructor(this, () => {
      this._fetch.cancelAll();
      this.reportingService.off(REPORTING_RANGE_UPDATED, fetchData);
    });
  }

  get isLoading() {
    return this.data.length === 0 && this._fetch.isRunning;
  }

  get statistics() {
    return {
      [statisticKeys.receipts]: 'Sent',
      [statisticKeys.opens]: 'Opened',
      [statisticKeys.clicks]: 'Clicked',
      [statisticKeys.replies]: 'Replied',
      [statisticKeys.emailFailures]: 'Failed',
    };
  }

  get columns() {
    return [
      {
        valuePath: 'title',
        label: this.intl.t(
          'components.reporting.reports.deliverability.deliverability-table.message-label',
        ),
        component: 'series/editor/overview-report/title-cell',
      },
    ];
  }

  get statisticColumns() {
    return Object.keys(this.statistics).map((id) => {
      return { valuePath: id, key: parseInt(id, 10) };
    });
  }

  @task({ drop: true })
  *_fetch() {
    this.outboundHomeService.isDeliverabilityReport = true;
    this.outboundHomeService.safelySet('selectedStateValue', states.live);
    yield this.outboundHomeService
      .contentSearchWithFilters({
        app_id: this.outboundHomeService.app.id,
        object_types: [objectTypes.email],
        page_from: this.pageFrom,
        per_page: PER_PAGE,
        statistics: Object.keys(this.statistics),
      })
      .then((result) => {
        this.data.pushObjects(result.contentWrappers);
        this.isLoadingRulesets = false;
        this.lastPageHit = result.lastPageHit;
        this.pageFrom = result.pageFrom;
        this.outboundHomeService.isDeliverabilityReport = false;
      });
  }

  @action
  async loadMore() {
    this.pageFrom += 1;
    await this._fetch.perform();
  }
}
