/* import __COLOCATED_TEMPLATE__ from './setup.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class Setup extends Component {
  @service store;
  @service appService;
  @service intercomEventService;

  @tracked openSectionId = 'installation-guide';
  @tracked settings;

  constructor() {
    super(...arguments);
    this.loadSwitchSettings.perform();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'switch_settings',
      place: 'switch',
      section: 'settings',
    });
  }

  @task({ restartable: true })
  *loadSwitchSettings() {
    this.settings = yield this.store.findRecord('switch/settings', this.appService.app.id);
    if (this.hasPhoneNumberOrApiToken) {
      this.openSectionId = null;
    }
  }

  get hasPhoneNumberOrApiToken() {
    return this.settings.phoneNumber || this.settings.apiToken;
  }
}
