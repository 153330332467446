/* import __COLOCATED_TEMPLATE__ from './color-picker.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-observers */
import Component from '@ember/component';
import { computed, observer, action } from '@ember/object';

export default Component.extend({
  tagName: '',

  init() {
    this._super(...arguments);
    this.set('_value', this.value);
  },

  didUpdateAttrs() {
    this._super(...arguments);
    this.set('_value', this.value);
  },

  colorWithHashtag: computed('_value', function () {
    if (!this._value) {
      return '#FFFFFF';
    } else if (this._value.startsWith('#')) {
      return this._value;
    } else {
      return `#${this._value}`;
    }
  }),

  handleValueChange: observer({
    dependentKeys: ['_value'],

    fn() {
      this.send('setColor', this._value);
    },

    sync: true,
  }),

  updateValue: action(function (color) {
    this.set('_value', color);
  }),

  actions: {
    setColor(color) {
      if (color !== this.value) {
        this.setColor?.(`#${color}`);
      }
    },
  },
});
