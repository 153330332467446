/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { on } from '@ember/object/evented';
import $ from 'jquery';
import { reads } from '@ember/object/computed';
import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';
import { notEqual } from '@intercom/pulse/lib/computed-properties';

const KEY_CODE_HASH = 51;
const KEY_CODE_BACKSPACE = 8;
const KEY_CODE_C = 67;
const KEY_CODE_V = 86;
const KEY_CODE_X = 88;
const KEY_CODE_LOWER_LIMIT = 48;
const KEY_CODE_UPPER_LIMIT = 70;
const KEY_CODES_CMD = [
  91, // Left command (Chrome & Safari)
  93, // Right command (Chrome & Safari)
  224, // Left & right command (Firefox)
];

export default Component.extend({
  tagName: 'input',
  type: 'input',
  maxlength: 7,
  colorHexCodeRegex: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  attributeBindings: ['type', 'maxlength', 'value', 'disabled', 'data-test-color-edit-component'],
  'data-test-color-edit-component': true,
  classNameBindings: ['isColorSelected:color-select__hex-input'],
  isColorSelected: notEqual('lastUsedColor', 'Default'),
  lastUsedColor: reads('value'),
  invalidColor(color) {
    return this.colorHexCodeRegex.exec(color) === null;
  },
  throttleColorChange: task(function* () {
    this.changeColor();
    yield timeout(100);
  }).restartable(),
  changeColor() {
    let color = $(this.element).val();

    if (!this.invalidColor(color)) {
      this.setColor?.(color);
      this.set('lastUsedColor', color);
    }
  },
  isInvalidKeyCodePressed(keyCode, altKeyPressed, ctrlKeyPressed) {
    // Handling # key
    if (altKeyPressed && keyCode !== KEY_CODE_HASH) {
      return true;
    }

    // Handling copy, paste, cut
    if (
      (ctrlKeyPressed || this.cmdKeyPressed) &&
      (keyCode === KEY_CODE_C || keyCode === KEY_CODE_V || keyCode === KEY_CODE_X)
    ) {
      return false;
    }

    return (
      (keyCode < KEY_CODE_LOWER_LIMIT || keyCode > KEY_CODE_UPPER_LIMIT) &&
      keyCode !== KEY_CODE_BACKSPACE &&
      !altKeyPressed
    );
  },
  handleCommandKeyDown: on('keyDown', function (e) {
    if (KEY_CODES_CMD.indexOf(e.which) !== -1) {
      this.set('cmdKeyPressed', true);
    }
    return true;
  }),
  handleCommandKeyUp: on('keyUp', function (e) {
    if (KEY_CODES_CMD.indexOf(e.which) !== -1) {
      this.set('cmdKeyPressed', false);
    }
    return true;
  }),
  handleKeyUp: on('keyUp', function () {
    this.throttleColorChange.perform();
  }),
  handleKeyDown: on('keyDown', function (e) {
    if (this.isInvalidKeyCodePressed(e.which, e.altKey, e.ctrlKey)) {
      e.preventDefault();
      return false;
    }
  }),
  handleFocusOut: on('focusOut', function () {
    if (this.invalidColor($(this.element).val())) {
      $(this.element).val(this.lastUsedColor);
    }
  }),
});
