/* import __COLOCATED_TEMPLATE__ from './step2-request-seat.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import {
  NON_SPECIFIC_SEAT_TYPE_REQUEST as NON_SPECIFIC_SEAT,
  VBP2_SEAT_LABELS,
} from 'embercom/lib/settings/seats/constants';
import { isEmpty } from 'underscore';

export default class StepTwo extends Component {
  @service notificationsService;
  @service router;
  @service intl;

  @tracked requestClicked = false;
  @tracked requestBodyText;
  requestBodyTextMaxLength = 255;

  constructor() {
    super(...arguments);
    if (NON_SPECIFIC_SEAT === this.args.model.seat || !this.seatLabel) {
      this.requestBodyText = this.intl.t(
        'components.seatless-experience.step2-request-seat.request-body-text-without-seat',
        {
          appName: this.args.model.app.name,
        },
      );
    } else {
      this.requestBodyText = this.intl.t(
        'components.seatless-experience.step2-request-seat.request-body-text-with-seat',
        {
          isEngage: this.args.model.seat === 'engage',
          seatLabel: this.seatLabel,
          appName: this.args.model.app.name,
        },
      );
    }
  }

  get seatLabel() {
    return VBP2_SEAT_LABELS[this.args.model.seat];
  }

  get requestBodyCharactersRemaining() {
    return this.requestBodyTextMaxLength - (this.requestBodyText.length || 0);
  }

  @task
  *sendRequest() {
    this.requestClicked = true;
    if (isEmpty(this.args.model.requestTo)) {
      return false;
    }
    try {
      yield this.saveTeammateSeatRequest();
      this.router.transitionTo('apps.app.seatless-experience.request-confirmed');
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('components.seatless-experience.step2-request-seat.request-error'),
      );
    }
  }

  saveTeammateSeatRequest() {
    let params = {
      app_id: this.args.model.app.id,
      seat_type: this.args.model.seat,
      to_admin_id: this.args.model.requestTo.id,
      message_text: this.requestBodyText,
    };
    return post('/ember/teammate_seat_request', params);
  }
}
