/* import __COLOCATED_TEMPLATE__ from './node-stats-sheet.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import { addEventListener, removeEventListener, runDisposables } from 'ember-lifeline';

export default class NodeStatsSheet extends Component {
  @service seriesEditorService;

  @action
  addClickListener() {
    next(this, () => {
      addEventListener(this, document, 'click', this.handleDocumentClick);
    });
  }

  @action
  removeClickListener() {
    removeEventListener(this, document, 'click', this.handleDocumentClick);
    runDisposables(this);
  }

  @action
  closeEditor() {
    this.seriesEditorService.unregisterActiveStatsNode();
  }

  handleDocumentClick(event) {
    let blanket = document.querySelector('.side-drawer__blanket');
    if (blanket?.contains(event.target)) {
      this.closeEditor();
    }
  }
}
