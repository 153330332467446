/* import __COLOCATED_TEMPLATE__ from './self-serve-resolution-rate.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { SEEKING_SUPPORT, SELF_SERVE_RESOLVED } from 'embercom/lib/reporting/csf-queries';
import { getTimeConfig } from 'embercom/lib/reporting/csf-query-helper';
import { buildDataConfigWithComparisons } from '../../../../lib/reporting/comparison-data-config';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';

const SELF_SERVE_RESOLUTION_RATE_NUMERATOR = 'self-serve-resolution-rate-numerator';
const SELF_SERVE_RESOLUTION_RATE_DENOMINATOR = 'self-serve-resolution-rate-denominator';

export default class SelfServeResolutionRate extends Component {
  @service intercomEventService;

  get viewConfig() {
    return {
      formatUnit: { unit: units.percent },
      counter: {
        [SELF_SERVE_RESOLUTION_RATE_NUMERATOR]: {
          improvementDirection: improvementDirections.INCREASING,
        },
      },
    };
  }

  get time() {
    return getTimeConfig({ range: this.args.range });
  }

  get dataConfig() {
    return buildDataConfigWithComparisons(
      {
        series: [
          {
            name: SELF_SERVE_RESOLUTION_RATE_NUMERATOR,
            ...SELF_SERVE_RESOLVED,
            time: this.time,
          },
          {
            name: SELF_SERVE_RESOLUTION_RATE_DENOMINATOR,
            ...SEEKING_SUPPORT,
            time: this.time,
          },
        ],
      },
      this.args.range,
    );
  }

  @action
  trackAnalyticsEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'hovered',
      object: 'tooltip',
      context: 'self_serve_resolution_rate',
    });
  }
}
