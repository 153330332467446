/* import __COLOCATED_TEMPLATE__ from './identity-verification-settings-modal.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import STRICTNESS from 'embercom/lib/identity-verification-strictness';
import { capitalize } from '@ember/string';

export default class IdentityVerificationSettingsModal extends Component {
  @service store;

  @tracked isEnabled = this.args.showDefaultSettings
    ? this.settings.enabledByDefault
    : this.settings.get('identityVerificationEnabled');
  @tracked selectedStrictness = this.args.showDefaultSettings
    ? this.settings.defaultStrictness
    : this.settings.get('identityVerificationStrictness');

  get settings() {
    return this.args.showDefaultSettings
      ? this.args.identityVerificationSettings
      : this.args.pingDomain.settings;
  }

  get strictnessButtons() {
    return [
      { value: STRICTNESS.any.value, label: capitalize(STRICTNESS.any.label) },
      { value: STRICTNESS.user_id.value, label: capitalize(STRICTNESS.user_id.label) },
      { value: STRICTNESS.email.value, label: capitalize(STRICTNESS.email.label) },
      {
        value: STRICTNESS.user_id_and_email.value,
        label: capitalize(STRICTNESS.user_id_and_email.label),
      },
    ];
  }

  updateDefaultSettings() {
    this.settings.set('enabledByDefault', this.isEnabled);
    this.settings.set('defaultStrictness', this.selectedStrictness);
    this.args.identityVerificationSettings.save();
  }

  updatePingDomainSettings() {
    this.settings.set('identityVerificationEnabled', this.isEnabled);
    this.settings.set('identityVerificationStrictness', this.selectedStrictness);
    this.args.pingDomain.save();
  }

  @action
  onSave() {
    if (this.args.showDefaultSettings) {
      this.updateDefaultSettings();
    } else {
      this.updatePingDomainSettings();
    }

    this.args.toggleIsModalShown();
  }
}
