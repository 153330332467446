/* import __COLOCATED_TEMPLATE__ from './flexible-folder.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import type ReportingFolderManagerService from 'embercom/services/reporting-folder-manager-service';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type StickQueryParamsService from 'embercom/services/stick-query-params-service';
import type IntlService from 'ember-intl/services/intl';
import type ReportingViews from 'embercom/services/reporting-views';
import { tracked } from '@glimmer/tracking';
import type NavigationFolder from 'embercom/models/reporting/navigation-folder';
import { type ReportItem } from 'embercom/services/reporting-views';
import { cached } from 'tracked-toolbox';

interface Args {
  folder: NavigationFolder;
  sections: any;
  onCreateFolder: () => void;
  setEditingFolder: (folder: NavigationFolder | undefined) => void;
}

export default class FlexibleReportingNavSubmenuFolder extends Component<Args> {
  @service declare router: Router;
  @service declare reportingFolderManagerService: ReportingFolderManagerService;
  @service declare intercomEventService: any;
  @service declare stickQueryParamsService: StickQueryParamsService;
  @service declare intl: IntlService;
  @service declare reportingViews: ReportingViews;

  @tracked isHovered = false;
  @tracked hasExpandOptions = false;
  @tracked searchString?: string;

  get folder() {
    return this.args.folder;
  }

  get isOpen() {
    return this.folder.expanded;
  }

  get folderManager() {
    return this.reportingFolderManagerService;
  }

  get trustedListFilterQueryParams() {
    return this.stickQueryParamsService.safeCarryOverQueryParams;
  }

  @cached
  get reportsInFolder(): ReportItem[] {
    let allReportsById = this.reportingViews.allReportsById;
    return this.folder.reports.map((id: string) => {
      if (isPresent(allReportsById[id])) {
        return allReportsById[id];
      }
      return { name: id, id };
    });
  }

  @action
  reorderReports(reports: ReportItem[], _draggedModel: any) {
    taskFor(this.performReorderReports).perform(reports);
  }

  @task({ drop: true })
  *performReorderReports(reports: ReportItem[]) {
    yield this.args.folder.updateReportOrder(reports.map((report: ReportItem) => report.id));
  }

  @task({ drop: true })
  *trackAnalyticsEventFor(item: ReportItem) {
    yield this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: item.route,
      place: this.folder.name,
      section: 'reports',
    });
  }

  @action
  async handleOnClickFor(item: ReportItem) {
    this.folder.updateLastOpened(item);
    this.folderManager.setActiveFolderReport('', ''); // reset active
    this.reportingFolderManagerService.allFlexibleFolders
      .filter((folder) => isPresent(folder.lastOpened))
      .reject((folder) => this.folder.id === folder.id)
      .forEach((folder) => folder.resetLastOpened());
    taskFor(this.trackAnalyticsEventFor).perform(item);
  }

  @action
  updateFolderState() {
    this.folder.toggledExpanded();
  }

  get showMore() {
    return (
      this.reportingFolderManagerService.canAdminManageFlexibleFolders &&
      (this.isHovered || this.hasExpandOptions)
    );
  }

  @action
  toggleExpandOptions() {
    this.hasExpandOptions = !this.hasExpandOptions;
  }

  @action
  reset() {
    this.hasExpandOptions = false;
    this.isHovered = false;
    this.searchString = undefined;
  }

  @action
  onSearchInput(value: string) {
    this.searchString = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::NavSubmenuComponents::FlexibleFolder': typeof FlexibleReportingNavSubmenuFolder;
  }
}
