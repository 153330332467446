/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';

export default class Base extends Component {
  @tracked openSectionId = null;
  @service appService;

  @action
  async downloadTemplateFile(s3BucketName) {
    await post(`/ember/bulk_export/cloud_formation_template`, {
      app_id: this.appService.app.id,
      s3_bucket_name: s3BucketName,
    }).then((response) => {
      let dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(response))}`;
      let dlAnchorElem = document.createElement('a');
      dlAnchorElem.setAttribute('href', dataStr);
      dlAnchorElem.setAttribute('download', 'intercom-data-export-cloudformation-template.json');
      dlAnchorElem.click();
    });
  }
}
