/* import __COLOCATED_TEMPLATE__ from './date-range-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class DateRangeFilter extends Component {
  @service notificationsService;

  constructor() {
    super(...arguments);
    if (!this.args.timezone) {
      console.warn('Timezone should be provided to date-range-filter');
    }
  }

  @action
  setDefaultDateRange(selectedRange) {
    let range = this.args.range;
    range.setDefaultRange(selectedRange);
    this.args.updateRange(range);
  }

  @action
  setRange(start, end) {
    let range = this.args.range;
    range.updateRange(start, end);
    this.args.updateRange(range);
  }

  @action
  setCustomRange(hideCustomDateRangePopover, start, end) {
    if (this.args.maxSelectableRangeInDays && this.args.maxSelectableRangeInDays > 0) {
      let rangeInDays = end.endOf('day').diff(start.startOf('day'), 'days') + 1;
      if (rangeInDays > this.args.maxSelectableRangeInDays) {
        this.notificationsService.notifyError(this.args.maxSelectableRangeInDaysErrorMessage);
        return;
      }
    }

    this.setRange(start, end);
    hideCustomDateRangePopover();
  }
}
