/* import __COLOCATED_TEMPLATE__ from './send-to-salesforce.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class SendToSalesforce extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  @action
  updateLeadSource(event) {
    this.args.action.actionData = {
      lead_source: event.currentTarget.value,
    };
  }
}
