/* import __COLOCATED_TEMPLATE__ from './with-analytics.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { trackAnalyticsEventForChart } from 'embercom/lib/reporting/analytics';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { ref } from 'ember-ref-bucket';

export default class TooltipWithAnalytics extends Component {
  @ref('element') elementWithinChart;

  @action
  trackShowEvent() {
    trackAnalyticsEventForChart(this.elementWithinChart, { action: 'hovered' });
  }

  @action
  storeElement(element) {
    this.elementWithinChart = element;
  }
}
