/* import __COLOCATED_TEMPLATE__ from './invite-list-multiple-seat-types.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { get } from 'embercom/lib/ajax';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class InviteListMultipleSeatTypes extends Component {
  @service appService;
  @service intl;
  @service intercomConfirmService;
  @service notificationsService;
  @tracked seatsUsed = {};

  constructor() {
    super(...arguments);
    this.loadSeatUsage.perform();
  }

  get getColumns() {
    let cols = [
      {
        label: this.intl.t('settings.teammate-list.invite-list-multiple-seat-types.email-label'),
        valuePath: 'email',
        width: '30%',
      },
      {
        label: this.intl.t('settings.teammate-list.invite-list-multiple-seat-types.expiry-label'),
        valuePath: 'expires',
        hasTooltipBlock: true,
      },
      {
        label: this.appService.app.onPricing5
          ? this.intl.t('settings.teammate-list.invite-list-multiple-seat-types.seat-label')
          : this.intl.t('settings.teammate-list.invite-list-multiple-seat-types.seats-label'),
        valuePath: 'seats',
        hasTooltipBlock: true,
      },
      {
        label: this.intl.t('settings.teammate-list.invite-list-multiple-seat-types.role-label'),
        valuePath: 'role',
        width: '24%',
      },
    ];

    cols.push(
      { label: '', valuePath: 'copy-link' },
      { label: '', valuePath: 'resend', minWidth: '220px' },
      { label: '', valuePath: 'edit' },
    );
    return cols;
  }

  @action
  async revokeInvite(invite) {
    let inviteEmail = invite.email;
    let options = {
      title: this.intl.t('settings.teammates.invite.invite-list.delete-invite'),
      body: this.intl.t('settings.teammates.invite.invite-list.revoke-alert', {
        email: inviteEmail,
      }),
      confirmButtonText: this.intl.t('settings.teammates.invite.invite-list.delete-invite'),
      primaryButtonType: 'primary-destructive',
    };
    let confirmed = await this.intercomConfirmService.confirm(options);

    if (confirmed) {
      try {
        await invite.destroyRecord();
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.teammates.invite.invite-list.invite-revoked', {
            email: inviteEmail,
          }),
        );
      } catch (err) {
        this.notificationsService.notifyError(
          this.intl.t('settings.teammates.invite.invite-list.revoke-error'),
        );
      }
    }
  }

  @task
  *loadSeatUsage() {
    this.seatsUsed = yield get('/ember/settings/seats/used_and_pending_seat_count', {
      app_id: this.appService.app.id,
    });
  }
}
