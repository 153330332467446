/* import __COLOCATED_TEMPLATE__ from './slas-page.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { A } from '@ember/array';
import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';
import { notEmpty, sort, readOnly } from '@ember/object/computed';
import { findBy } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  currentSortColumn: findBy('columns', 'isSortColumn', true),
  sortDefinition: computed('currentSortColumn', 'currentSortColumn.sortedAscending', function () {
    let sortColumn = this.currentSortColumn;
    return sortColumn.get('sortedAscending')
      ? [sortColumn.get('propertyName')]
      : [`${sortColumn.get('propertyName')}:desc`];
  }),

  slasWithOfficeHours: computed('slas', 'officeHoursSchedules', function () {
    return this.slas.map((sla) => {
      sla.officeHoursSchedule = this.officeHoursSchedules.findBy('id', sla.officeHoursScheduleId);
      return sla;
    });
  }),
  hasSlas: notEmpty('slasWithOfficeHours'),
  rows: sort('slasWithOfficeHours', 'sortDefinition'),
  inboxRulesLink: 'apps.app.settings.inbox-rules.list',

  classNames: [
    'flex-auto',
    'flex',
    'flex-col',
    'u__padt__20',
    'u__padb__20',
    'u__padl__30',
    'u__padr__30',
  ],

  columns: A([
    EmberObject.create({
      label: 'Name',
      canExpandToFit: true,
      component: 'table-cells/slas/name-cell',
      sortable: true,
      isSortColumn: false,
      sortedAscending: true,
      propertyName: 'name',
    }),
    EmberObject.create({
      label: 'Created',
      component: 'table-cells/slas/created-at-cell',
      sortable: true,
      isSortColumn: true,
      sortedAscending: false,
      propertyName: 'createdAt',
    }),
  ]),

  actions: {
    toggleSort(column) {
      let columns = this.columns;
      let currentSortColumn = this.currentSortColumn;

      if (currentSortColumn.get('propertyName') === column.propertyName) {
        currentSortColumn.toggleProperty('sortedAscending');
      } else {
        currentSortColumn.set('isSortColumn', false);

        let newSortColumn = columns.findBy('propertyName', column.propertyName);
        newSortColumn.set('isSortColumn', true);
      }
    },
  },
});
