/* import __COLOCATED_TEMPLATE__ from './chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { copy } from 'ember-copy';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { buildFilters } from 'embercom/lib/reporting/flexible/filter-helpers';
import { buildDataConfigWithComparisons } from 'embercom/lib/reporting/comparison-data-config';

export const FILTER_PROPERTY_PLACEHOLDER = '_placeholder_';

export default class ChartComponent extends Component {
  @service intercomEventService;
  @service appService;
  @service intl;

  @tracked selectedGrouping = this.grouping;
  @tracked selectedAggregation = this.persistentAggregation;
  @tracked showEditor = false;
  @tracked paywall = null;

  persistentDataConfig = this.args.persistentDataConfig;

  get chartHeadingText() {
    return capitalize(this._chartHeadingTextInLowerCase);
  }

  get _chartHeadingTextInLowerCase() {
    if (this.savedAggregationDisplay && this.savedGroupingDisplay) {
      return this.intl.t('reporting.flexible-chart.title_with_aggregation_and_grouping', {
        rawTitle: this.args.title,
        aggregation: this.savedAggregationDisplay,
        grouping: this._groupingDisplayText,
      });
    } else if (this.savedAggregationDisplay) {
      return this.intl.t('reporting.flexible-chart.title_with_aggregation', {
        rawTitle: this.args.title,
        aggregation: this.savedAggregationDisplay,
      });
    } else if (this.savedGroupingDisplay) {
      return this.intl.t('reporting.flexible-chart.title_with_grouping', {
        rawTitle: this.args.title,
        grouping: this._groupingDisplayText,
      });
    } else {
      return this.args.title;
    }
  }

  get _groupingDisplayText() {
    if (this.savedGroupingDisplay) {
      let lowerGroupingDisplay = this.savedGroupingDisplay.toLowerCase();

      return lowerGroupingDisplay === 'time' ? this.args.range.interval : lowerGroupingDisplay;
    }
  }

  get titleTooltipText() {
    let selectedGroupingObj = this.findSelectedGroupingObj;

    if (selectedGroupingObj.createTitleTooltipText && this.findSelectedAggregationObj) {
      return selectedGroupingObj.createTitleTooltipText(
        this.findSelectedAggregationObj.text.toLowerCase(),
      );
    }
  }

  get currentViewConfig() {
    return {
      ...this.args.viewConfig.toHash(),
      grouping: this.findSelectedGroupingObj,
      aggregation: this.findSelectedAggregationObj,
    };
  }

  get savedAggregationDisplay() {
    return this.findSelectedAggregationObj?.text;
  }

  get savedGroupingDisplay() {
    if (this.args.viewConfig.groupings.length > 1) {
      return this.findSelectedGroupingObj.text;
    } else {
      return;
    }
  }

  get canEditChart() {
    return (
      this.args.viewConfig.aggregations.length > 1 || this.args.viewConfig.groupings.length > 1
    );
  }

  @action
  addToDashboard() {
    if (this.args.dashboard) {
      this.args.dashboard.registerChart(this);
    }
  }

  @action
  isPaywalled(paywall) {
    if (paywall?.isActive) {
      this.paywall = paywall;
      this.selectedAggregation = this.persistentAggregation;
      this.selectedGrouping = this.grouping;
    }
  }

  get aggregationItems() {
    if (this.paywall?.isActive) {
      return this.args.viewConfig.aggregationsWithPaywall({
        paywall: this.paywall,
      });
    } else {
      return this.args.viewConfig.aggregations;
    }
  }

  get groupingItems() {
    if (this.paywall?.isActive) {
      return this.args.viewConfig.groupingsWithPaywall({
        paywall: this.paywall,
      });
    } else {
      return this.args.viewConfig.groupings;
    }
  }

  @action
  openEditor() {
    this.showEditor = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'edit_chart',
      chart_id: this.persistentDataConfig.chart_identifier,
      dashboard_id: this.persistentDataConfig.dashboard_identifier,
    });
  }

  @action
  closeEditor() {
    this.showEditor = false;
    this.selectedAggregation = this.persistentAggregation;
    this.selectedGrouping = this.grouping;
  }

  @task
  *saveSelectedAggregation() {
    this.showEditor = false;
    this.persistentDataConfig.aggregation_method = this.selectedAggregation;
    this.persistentDataConfig.grouping_method = this.selectedGrouping;

    this.persistentDataConfig = yield this.persistentDataConfig.save();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved',
      object: 'edit_chart',
      aggregation: this.selectedAggregation,
      grouping: this.selectedGrouping,
      chart_id: this.persistentDataConfig.chart_identifier,
      dashboard_id: this.persistentDataConfig.dashboard_identifier,
    });
  }

  get defaultAggregationOptionValue() {
    return this.args.viewConfig.aggregations?.find((agg) => agg.defaultOption)?.value;
  }

  get persistentAggregation() {
    if (this.paywall?.isActive) {
      return this.defaultAggregationOptionValue;
    }

    if (this.persistentDataConfig?.aggregation_method) {
      let persistedAggregationMethod = this.args.viewConfig.aggregations.find(
        (agg) => agg.value === this.persistentDataConfig.aggregation_method,
      );

      if (persistedAggregationMethod) {
        return persistedAggregationMethod.value;
      }
    }

    return this.defaultAggregationOptionValue;
  }

  get grouping() {
    let { groupings } = this.args.viewConfig;
    let defaultGrouping = groupings.find(({ defaultOption }) => defaultOption);

    if (this.paywall?.isActive) {
      return defaultGrouping.value;
    }

    if (this.persistentDataConfig?.grouping_method) {
      let persistedGrouping = groupings.find(
        ({ value }) => value === this.persistentDataConfig.grouping_method,
      );

      if (persistedGrouping) {
        return persistedGrouping.value;
      }
    }

    return defaultGrouping.value;
  }

  get filters() {
    return this.args.filters || {};
  }

  get dataConfig() {
    let mergedDataConfig = copy(this.args.dataConfig, true);

    if (mergedDataConfig.xAxis) {
      mergedDataConfig.xAxis = this.buildXAxis(mergedDataConfig.xAxis);
    }

    mergedDataConfig = this.transformSeriesFilters(mergedDataConfig);

    let filterList = [...this.getFilterList(mergedDataConfig.filter), ...this.mainFilterList()];
    mergedDataConfig.filter = this.transformFilterList(filterList);

    if (this.args.viewConfig.aggregations.length > 1) {
      mergedDataConfig = this.setAggregation(mergedDataConfig);
    }

    return mergedDataConfig;
  }

  transformFilterList(list) {
    if (list.length === 0) {
      return;
    } else if (list.length === 1) {
      return list[0];
    } else {
      return { type: 'and', filters: list };
    }
  }

  buildXAxis(xAxis) {
    let selectedGroupingObj = this.findSelectedGroupingObj;
    let newXAxis = {};

    newXAxis['type'] = selectedGroupingObj.type;
    newXAxis['data'] = { property: selectedGroupingObj.value };

    if (selectedGroupingObj.type === 'temporal') {
      newXAxis['data']['interval'] = selectedGroupingObj.interval || this.args.range.interval;
    }

    if (selectedGroupingObj.type === 'nominal') {
      if (selectedGroupingObj.limit) {
        newXAxis['data']['limit'] = selectedGroupingObj.limit;
        newXAxis['data']['showOtherColumn'] = true;
      }

      if (selectedGroupingObj.sorting) {
        newXAxis['data']['sorting'] = selectedGroupingObj.sorting;
      }

      if (selectedGroupingObj.termSize) {
        newXAxis['data']['term_size'] = selectedGroupingObj.termSize;
      }

      if (selectedGroupingObj.excludeMissingGroup) {
        newXAxis['data']['exclude_missing_group'] = selectedGroupingObj.excludeMissingGroup;
      }

      if (selectedGroupingObj.labelMappings) {
        newXAxis['data']['labelMappings'] = selectedGroupingObj.labelMappings;
      }

      if (selectedGroupingObj.defaultLabel) {
        newXAxis['data']['defaultLabel'] = selectedGroupingObj.defaultLabel;
      }
    }

    return newXAxis;
  }

  getFilterList(filter) {
    if (!filter) {
      return [];
    }

    return filter.filters || [filter];
  }

  mainFilterList() {
    let filtersList = [];

    for (let [key, value] of Object.entries(this.filters)) {
      if (value !== undefined) {
        let filters = buildFilters(key, value);
        filtersList.push(...filters);
      }
    }
    return filtersList;
  }

  setAggregation(mergedDataConfig) {
    for (let seriesData of mergedDataConfig.series) {
      seriesData.type = this.persistentAggregation;
    }
    return mergedDataConfig;
  }

  get chartCanonicalName() {
    if (this.isComparisonCounter) {
      return 'comparison-counter';
    } else if (this.isCounter) {
      return 'counter';
    }

    let componentName = this.args.chartComponent.split('/').lastObject;
    return componentName === 'legacy-heatmap' ? 'heatmap' : componentName;
  }

  fixFilters(series) {
    if (series.filter) {
      series.filter = this.transformFilterList(this.getFilterList(series.filter));
    }
  }

  setTimeRange(series) {
    series.time.start = this.args.range.startMoment.valueOf();
    series.time.end = this.args.range.endMoment.valueOf();
  }

  transformSeriesFilters(defaultConfig) {
    let allSeries = defaultConfig.series;
    for (let singleSeries of allSeries) {
      this.fixFilters(singleSeries);
      this.setTimeRange(singleSeries);
    }

    if (this.findSelectedGroupingObj.isComparison) {
      return buildDataConfigWithComparisons(defaultConfig, this.args.range);
    }
    return defaultConfig;
  }

  get findSelectedGroupingObj() {
    return this.args.viewConfig.groupings.find((grouping) => grouping.value === this.grouping);
  }

  get findSelectedAggregationObj() {
    return this.args.viewConfig.aggregations?.find((agg) => agg.value === this.selectedAggregation);
  }

  get isComparisonCounter() {
    return this.isCounter && this.args.showTimeComparison;
  }

  get isCounter() {
    let component = this.args.chartComponent.split('/').lastObject;
    return component === 'counter' || component === 'legacy-counter';
  }

  get cardCssClasses() {
    if (this.args.useEmptyComponent) {
      return 'reporting__flexible__chart__empty-state';
    } else if (this.isComparisonCounter) {
      return 'reporting__flexible__chart__comparison-counter-card';
    } else {
      return '';
    }
  }

  get emptyComponent() {
    return this.args.forceEmptyComponent || 'reporting/flexible/default-empty-chart';
  }
}
