/* import __COLOCATED_TEMPLATE__ from './edit-nav-submenu.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type NavigationFolder from 'embercom/models/reporting/navigation-folder';
import type ReportingFolderManagerService from 'embercom/services/reporting-folder-manager-service';

interface Arguments {
  onCreateFolder: () => void;
}
interface Signature {
  Args: Arguments;
}
export default class EditNavSubmenu extends Component<Signature> {
  @service declare reportingFolderManagerService: ReportingFolderManagerService;
  @service declare appService: any;
  @service declare intl: any;
  @service declare store: any;
  @service declare intercomEventService: any;
  @service declare permissionsService: any;

  constructor(owner: unknown, args: any) {
    super(owner, args);
  }

  get foldersData(): any {
    return this.reportingFolderManagerService.allFlexibleFolders.map((folder: NavigationFolder) => {
      return {
        name: folder.name,
        pinned: folder.pinned,
        updateFolder: () => this.updateFolder(folder),
        component: 'reporting/nav-submenu-components/edit-nav-folder',
        componentShouldReplaceItem: true,
      };
    });
  }

  get groupList() {
    let groups = [];
    if (this.foldersData?.length > 0) {
      let folders = [
        {
          heading: this.intl.t('reporting.submenu-component.edit-sidebar.modal-header'),
          items: this.foldersData,
        },
      ];
      groups.push(...folders);
    }

    let createFolderItem = {
      items: [
        {
          component: 'reporting/nav-submenu-components/edit-nav-create-folder',
          componentShouldReplaceItem: true,
          onCreateFolder: this.args.onCreateFolder,
        },
      ],
    };
    groups.push(createFolderItem);
    return groups;
  }

  get canAdminManageFlexibleFolders() {
    return this.reportingFolderManagerService.canAdminManageFlexibleFolders;
  }

  @action
  openPermissionsModal() {
    this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
      'can_reporting__navigation_folders__manage',
    );
  }

  @action
  async updateFolder(folder: NavigationFolder) {
    if (this.canAdminManageFlexibleFolders) {
      await folder.togglePin();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'reporting_navigation_folder',
        folder_id: folder.id,
      });
      this.reportingFolderManagerService.loadPinnedFlexibleFolders();
    } else {
      this.openPermissionsModal();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::NavSubmenuComponents::EditNavSubmenu': typeof EditNavSubmenu;
    'reporting/nav-submenu-components/edit-nav-submenu': typeof EditNavSubmenu;
  }
}
