/* import __COLOCATED_TEMPLATE__ from './shortcuts.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  objectTypes,
  nodeDelayBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { isNone } from '@ember/utils';
import { CONNECTION_POINTS } from 'embercom/services/series-editor-service';
import { allNodeConfigurations } from 'embercom/objects/series/configuration-list';
import { tracked } from '@glimmer/tracking';
import { contentEvents } from 'embercom/models/data/stats-system/stats-constants';
import moment from 'moment-timezone';

const DEFAULT_MAX_EVALUATION_TIME_IN_SECONDS = moment.duration(3, 'days').asSeconds();

export default class SeriesNodeShortcuts extends Component {
  @service attributeService;
  @tracked showMore = false;
  connectionPoints = CONNECTION_POINTS;
  successorObjectType = objectTypes.condition;
  expandedLimit = 3;

  get expandShortcuts() {
    return this.shortcuts.length <= this.expandedLimit || this.showMore;
  }

  get node() {
    return this.args.node.dataObject;
  }

  get shortcuts() {
    let seriesNode = this.node;

    return this.filteredContentAttributes.map((attribute) => {
      let predicate = {
        attribute: attribute.identifier,
        type: 'content_event',
        comparison: 'in',
        value: seriesNode.rulesetId,
      };
      let placeholderDataObject = {
        ...this.successorInserterConfig.placeholderGraphNodeDataObject,
        hasTemporaryEdge: true,
        defaultParams: {
          predicate_group: { predicates: [predicate] },
          max_evaluation_time: DEFAULT_MAX_EVALUATION_TIME_IN_SECONDS,
          delay_behavior: nodeDelayBehaviors.delayable,
        },
      };
      let label = `${this.eventDescription(attribute.identifier)}`;
      return { label, placeholderDataObject };
    });
  }

  eventDescription(identifier) {
    let customDescriptions = new Map();
    customDescriptions.set('clicked', 'clicked link');
    customDescriptions.set('replied', 'replied to');
    customDescriptions.set('goal_hit', 'hit goal');
    customDescriptions.set('collected_email', 'collected email');
    customDescriptions.set('dismissal', 'dismissed');

    let verb = contentEvents[identifier].statistic;
    return customDescriptions.get(verb) || verb;
  }

  get contentEventAttributes() {
    let seriesNode = this.node;
    return this.attributeService.contentEventAttributesFor(seriesNode.objectTypes.firstObject);
  }

  get filteredContentAttributes() {
    // For shortcuts, remove any 'received' content events. These are implied
    // by connecting two nodes with paths so are irrelevant for the shortcuts.
    let contentAttributes = this.contentEventAttributes.filter(
      (attribute) =>
        !attribute.identifier.endsWith('_received') && !attribute.identifier.endsWith('_viewed'),
    );

    if (!this.rulesetHasGoal) {
      contentAttributes = contentAttributes.filter(
        (attribute) => !attribute.identifier.includes('_goal_hit'),
      );
    }
    return contentAttributes;
  }

  get successorInserterConfig() {
    let conditionNodeConfig = allNodeConfigurations.filter(
      (config) => config.inserterConfiguration.objectType === this.successorObjectType,
    ).firstObject;
    return conditionNodeConfig.inserterConfiguration;
  }

  get successorIcon() {
    return this.successorInserterConfig.componentConfig.icon;
  }

  get rulesetHasGoal() {
    if (this.node.hasFetchedRuleset) {
      return !isNone(this.node.ruleset.goal);
    } else {
      return this.node.rulesetHasGoal;
    }
  }
}
