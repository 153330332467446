/* import __COLOCATED_TEMPLATE__ from './configure-distribution-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Args {
  shouldDisableBalancedAssignment: boolean;
  shouldDisableRoundRobinAssignment: boolean;
  teamSettings: any;
}

interface Signature {
  Args: Args;
  Blocks: {};
}

export default class ConfigureDistributionComponent extends Component<Signature> {
  @service appService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  get manualAssignmentDisabled() {
    return this.cannotChangeCurrentAssignmentMethod;
  }

  get roundRobinAssignmentDisabled() {
    return this.cannotChangeCurrentAssignmentMethod || this.args.shouldDisableRoundRobinAssignment;
  }

  get balancedAssignmentDisabled() {
    return this.cannotChangeCurrentAssignmentMethod || this.args.shouldDisableBalancedAssignment;
  }

  get cannotChangeCurrentAssignmentMethod() {
    // You can change assignment method only when you are disabling an assignment
    // method you have permission to enable/disable
    let currentAssignmentMethod = this.args.teamSettings.distributionMethod;
    switch (currentAssignmentMethod) {
      case 'load_balanced':
        return this.args.shouldDisableBalancedAssignment;
      case 'round_robin':
        return this.args.shouldDisableRoundRobinAssignment;
      default:
        return false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teams::ConfigureDistributionComponent': typeof ConfigureDistributionComponent;
  }
}
