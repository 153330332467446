/* import __COLOCATED_TEMPLATE__ from './is-isnot-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  initiallyOpened: boolean;
  icon: string;
  name: string;
  type: string;
  currentLabel: string;
  selectedValues: string[];
  allItems: Item[];
  operator: string;
  enableSearch?: boolean;
  removeFilter: () => void;
  filterKnownValuesLoader: ((filter: string | undefined) => TaskGenerator<void>) | Function;
  onSelectionChange: (values: string[]) => void;
  trackAnalytics: () => void;
  updateFilter: (filterValues: string[], operator: string) => void;
  isDisabled: boolean;
}

interface Item {
  text: string;
  value: string;
  isSelected: boolean;
}
export default class IsIsNotFilter extends Component<Args> {
  @service declare appService: any;
  @service intl!: IntlService;

  @tracked knownValues: string[] = [];
  // store the selection here so that the UI doesn't update until the drop-down is closed
  @tracked label = this.currentLabel;
  @tracked operator = this.args.operator;
  @tracked selectedDropdownItems = this.args.selectedValues;

  constructor(owner: unknown, args: any) {
    super(owner, args);
  }
  get items() {
    let { allItems, selectedValues } = this.args;
    let newAllItems = allItems.map((item) => {
      return { ...item, isSelected: selectedValues.includes(item.value) };
    });
    return [{ items: newAllItems }];
  }

  get operatorLabelMappings(): Record<string, string> {
    return {
      category: this.intl.t('reporting.custom-reports.is'),
      not_in_category: this.intl.t('reporting.custom-reports.is-not'),
    };
  }

  get valuesForFilter() {
    return this.selectedDropdownItems.length
      ? this.selectedDropdownItems
      : [REPORTING_FILTER_SELECT_ALL];
  }
  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.operator = '';
    this.args.removeFilter();
  }

  get currentLabel() {
    return `${this.operatorLabelMappings[this.operator]} ${this.args.currentLabel}`;
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    this.args.trackAnalytics();
    this.args.updateFilter(this.valuesForFilter, this.operator);
    this.label = this.currentLabel;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::IsIsnotFilter': typeof IsIsNotFilter;
  }
}
