/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import AxesMixin from 'embercom/components/reporting/axes-mixin';
import BaseChart from 'embercom/components/reporting/base-chart';
import { line } from 'd3-shape';
import { select } from 'd3-selection';
import { interpolatePath } from 'd3-interpolate-path';
import { TAG_COLOR_COUNT } from 'embercom/components/reporting/line-chart-legend-row';
import { MAX_CHART_LINES } from 'embercom/components/reporting/signal-card';
import setupLineChartSignal from 'embercom/lib/inbox/reporting/setup-line-chart-signal';
import defaultTo from '@intercom/pulse/lib/default-to';

let chartLineClasses = (index, selectedIndex) =>
  `reporting__chart__line o__${index % TAG_COLOR_COUNT} ${
    index === selectedIndex ? 'o__selected' : ''
  }`;

let chartColorId = (index, greenBlueScheme) => {
  if (greenBlueScheme) {
    return index === 0 ? 7 : 0;
  }
  return index;
};

function setMinYAxis(data) {
  let [first, ...rest] = data.map(({ value = [] }) => value.map(({ value }) => value));
  let flattened = first.concat(...rest);
  let max = Math.max(...flattened);
  let min = Math.min(...flattened);

  // we want to always show a scale that is an increment of 10 if possible,
  // but not lower than 0
  let adjustedMin = max === min ? Math.max(min - 10, 0) : min;

  return flattened.length > 0 ? adjustedMin : 0;
}

export default BaseChart.extend(AxesMixin, {
  'data-test-reporting-line-chart': true,
  classNames: ['reporting__chart__line-chart'],

  displayedItemCount: defaultTo(MAX_CHART_LINES),

  sortKey: ['value:desc'],
  sortedSignalComparisons: sort('signal.comparisons', 'sortKey'),
  signal: computed('raw', function () {
    if (this.raw.length > 0) {
      return setupLineChartSignal([...this.raw]);
    } else {
      return this.raw;
    }
  }),

  sortedData: computed('data', 'sortedSignalComparisons', function () {
    let sortedKeys = this.sortedSignalComparisons.mapBy('key');
    let indexedData = this.data.reduce((acc, value) => ({ ...acc, [value.key]: value }), {});
    return sortedKeys.map((key) => indexedData[key]);
  }),

  tagData: computed('sortedData', 'displayedItemCount', function () {
    return this.sortedData.slice(0, this.displayedItemCount);
  }),

  bucketWidth: 15,
  gapWidth: computed('dates.length', 'chartWidth', 'bucketWidth', function () {
    let numberOfDates = this.get('dates.length');
    let chartWidth = this.chartWidth;
    let bucketWidth = this.bucketWidth;
    return (chartWidth - numberOfDates * bucketWidth) / numberOfDates;
  }),
  interpolateZeroCounts: defaultTo(false),

  redrawChart() {
    let bucketWidth = this.bucketWidth;
    let gapWidth = this.gapWidth;
    let xScale = this.xScale;
    let selectedIndex = this.selectedIndex;
    let interpolateZeroCounts = this.interpolateZeroCounts;
    let compressYAxis = this.compressYAxis;
    let signalNameColorIndex = this.signalNameColorIndex;
    let data = this.tagData.map((series) => ({
      ...series,
      value: series.value.filter((point) => (interpolateZeroCounts ? point.count > 0 : true)),
    }));

    if (compressYAxis && data.length > 0) {
      this.set('minChartValue', setMinYAxis(data));
    }

    let baseLine = line()
      .x((d) => xScale(new Date(d.key)) + (gapWidth + bucketWidth) / 2)
      .y(this.chartHeight);

    let tags = this.chart()
      .selectAll('.reporting__chart__icon')
      .data(data, (d) => d.key);

    let enterTags = tags
      .enter()
      .append('g')
      .attr('class', (d) => `reporting__chart__icon _${d.key}`);

    enterTags
      .append('path')
      .attr('class', (d, i) => `reporting__chart__line o__hovers`)
      .attr('data-test-reporting-chart-line', true)
      .attr('d', (d) => baseLine(d.value));

    let transitionDuration = this.transitionDuration;

    tags.exit().remove();

    let yScale = this.yScale;

    let l = line()
      .x((d) => xScale(new Date(d.key)) + (gapWidth + bucketWidth) / 2)
      .y((d) => yScale(d.value));

    let shouldUseGB = this.greenBlueScheme;

    enterTags
      .merge(tags)
      .select('.reporting__chart__line')
      .attr('class', (d, i) =>
        chartLineClasses(
          signalNameColorIndex ? signalNameColorIndex[d.name] : chartColorId(i, shouldUseGB),
          selectedIndex,
        ),
      )
      .transition()
      .duration(transitionDuration)
      .attrTween('d', function (d) {
        let previous = select(this).attr('d');
        let current = l(d.value);
        return interpolatePath(previous, current);
      });

    this._renderDots({ data, interpolateZeroCounts, signalNameColorIndex });
    this.redrawAxes();
  },

  _renderDots({ data, interpolateZeroCounts, signalNameColorIndex }) {
    let transitionDuration = this.transitionDuration;
    let yScale = this.yScale;
    let xScale = this.xScale;
    let bucketWidth = this.bucketWidth;
    let gapWidth = this.gapWidth;
    let valueUnit = this.hoverTextUnit || this.get('signal.valueUnit');
    let shouldUseGB = this.greenBlueScheme;
    let tooltipComponent = this.tooltipComponent || 'tooltips/reporting-chart-datum';

    data.forEach((lineData, i) => {
      let circles = this.chart()
        .select(`.reporting__chart__icon._${lineData.key}`)
        .selectAll('.reporting__chart__point')
        .data(
          lineData.value.filter((d) => (interpolateZeroCounts ? d.count : true)),
          (d, i) => `${d.key} - ${i}`,
        );

      circles.exit().remove();

      let colorId = signalNameColorIndex
        ? signalNameColorIndex[lineData.name]
        : chartColorId(i, shouldUseGB);

      let enterCircles = circles
        .enter()
        .append('circle')
        .attr(
          'class',
          `js__legacy-reporting-tooltip reporting__chart__point o__${colorId % TAG_COLOR_COUNT} _${
            lineData.key
          }`,
        )
        .attr('cx', (d) => xScale(new Date(d.key)) + (gapWidth + bucketWidth) / 2)
        .attr('cy', (d) => yScale(d.value))
        .attr('r', 0)
        .attr('data-component', tooltipComponent)
        .attr('data-icon', lineData.icon)
        .attr('data-icon-label', lineData.label)
        .attr('data-value-unit', valueUnit)
        .attr('data-series', lineData.name)
        .attr('data-test-reporting-chart-point', true)
        .attr('data-range-start', this.get('range.start'))
        .attr('data-range-end', this.get('range.end'))
        .attr('data-timezone', this.timezone)
        .attr('data-aggregation-interval', this.get('range.interval'))
        .attr('data-value-unit-is-minute', this.get('signal.valueUnitIsMinute'));

      enterCircles.transition().delay(transitionDuration).duration(transitionDuration).attr('r', 4);

      enterCircles
        .on('mouseover', function () {
          select(this).attr('r', 6);
        })
        .on('mouseout', function () {
          select(this).attr('r', 4);
        });

      enterCircles
        .merge(circles)
        .attr(
          'class',
          `js__legacy-reporting-tooltip reporting__chart__point o__${colorId % TAG_COLOR_COUNT} _${
            lineData.key
          }`,
        )
        .attr('cx', (d) => xScale(new Date(d.key)) + (gapWidth + bucketWidth) / 2)
        .attr('cy', (d) => yScale(d.value))
        .attr('data-current-date', (d) => d.key)
        .attr('data-current-value', (d) => d.value);
    });
  },
});
