/* import __COLOCATED_TEMPLATE__ from './invites-blocked-banner-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

const InvitesBlockedBannerComponent = templateOnlyComponent();
export default InvitesBlockedBannerComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::InvitesBlockedBannerComponent': typeof InvitesBlockedBannerComponent;
  }
}
