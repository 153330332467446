/* import __COLOCATED_TEMPLATE__ from './survey-rating-emoji-bar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResource from 'embercom/lib/reporting/chart-data-resource';
import { emojiScaleOptions } from 'embercom/models/data/survey/constants';
import { emojiScale } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { populateEmptyRatingsWithZero } from 'embercom/lib/reporting/survey-helpers';
import { inject as service } from '@ember/service';

const SMALL_EMOJI_SCALE_RATING_SET = ['1', '2', '3'];
const EMOJI_SCALE_RATING_SET = ['1', '2', '3', '4', '5'];
const EMOJI_LABEL_FONT_SIZE = '24px';

export default class SurveyRatingEmojiBar extends Component {
  @service store;

  constructor() {
    super(...arguments);

    let questionId = this.args.renderableChart.viewBy.split('#')[1];
    let question = this.store.peekRecord('surveys/question', questionId);
    // surveys emoji scale question has 2 options
    // one with 3 emojis, another with 5
    this._numberOfEmojiOptions = question.data.scaleEnd;
  }

  dataResource = useResource(this, ChartDataResource, () => ({
    renderableChart: this.args.renderableChart,
  }));

  get viewConfig() {
    let viewConfig = this.dataResource.viewConfig;
    viewConfig.labelMappingFunction = (value) => emojiScale(this._numberOfEmojiOptions, value);
    viewConfig.labelStyleOverrides = { fontSize: EMOJI_LABEL_FONT_SIZE };
    viewConfig.grouping = {
      dataTransformation: (dataResponses) =>
        populateEmptyRatingsWithZero(this._availableRatings, dataResponses),
    };
    return viewConfig;
  }

  get dataConfig() {
    let config = this.dataResource.dataConfig;
    config.xAxis.data.sorting = (pointA, pointB) => this._sortEmojis(pointA, pointB);
    return config;
  }

  _sortEmojis(pointA, pointB) {
    return this._convertEmojiLabelToIndex(pointA[2]) - this._convertEmojiLabelToIndex(pointB[2]);
  }

  _convertEmojiLabelToIndex(point) {
    return emojiScaleOptions[this._numberOfEmojiOptions].findIndex(
      (element) => element.unicodeEmoticon === point,
    );
  }

  get _availableRatings() {
    switch (this._numberOfEmojiOptions) {
      case 3:
        return SMALL_EMOJI_SCALE_RATING_SET;
      case 5:
        return EMOJI_SCALE_RATING_SET;
    }
  }
}
