/* import __COLOCATED_TEMPLATE__ from './date-range-text.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';

export default class DateRangeText extends Component {
  get startDateFormat() {
    let format = 'MMM D';
    if (this.isStartAndEndYearDifferent || this.args.range.isSingleDay) {
      format += ', YYYY';
    }
    return format;
  }

  get isStartAndEndYearDifferent() {
    return this.args.range.startMoment.year() !== this.args.range.endMoment.year();
  }
}
