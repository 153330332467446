/* import __COLOCATED_TEMPLATE__ from './new-conversations-comparison-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  COUNT,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
} from 'embercom/lib/reporting/flexible/constants';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import { inject as service } from '@ember/service';

const seriesName = 'new_conversations_counter';

export default class NewConversationsComparisonCounter extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
        isComparison: true,
        createTitleTooltipText: () =>
          this.intl.t(
            'reporting.conversations-report.new-conversations-comparison-counter.tooltip',
          ),
      },
    };
    viewConfig.counter = {
      [seriesName]: {
        improvementDirection: improvementDirections.INCREASING,
      },
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      series: [
        {
          name: seriesName,
          source: this.app.canUseFeature('reporting-ember-conversation-index-merging')
            ? 'conversation'
            : 'conversation_with_message_thread',
          type: COUNT,
          data: { property: FIRST_USER_CONVERSATION_PART_CREATED_AT },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
      ],
    };
  }
}
