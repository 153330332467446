/* import __COLOCATED_TEMPLATE__ from './report-actions.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import type Report from 'embercom/models/reporting/custom/report';
import { action } from '@ember/object';
import type FavoritesService from 'embercom/services/favorites-service';
import { type TaskGenerator } from 'ember-concurrency';
import type CustomReportsService from 'embercom/services/custom-reports-service';
import type ReportAccessService from 'embercom/services/report-access-service';

interface Args {
  report: Report;
  duplicateReport: () => TaskGenerator<void>;
  deleteReport: () => TaskGenerator<void>;
  editReportDisabled?: boolean;
}

export default class ReportingCustomReportReportActions extends Component<Args> {
  @service declare favoritesService: FavoritesService;
  @service permissionsService: $TSFixMe;
  @service declare customReportsService: CustomReportsService;
  @service declare appService: any;
  @service declare reportAccessService: ReportAccessService;

  @task({ drop: true })
  *duplicateReport() {
    yield taskFor(this.args.duplicateReport).perform();
  }

  get customDateRange() {
    return this.args.report.range?.selectedRange === 'custom';
  }

  get scheduleButtonTooltipDisabled() {
    return !this.customDateRange;
  }

  get isNewReport() {
    return this.args.report.isNew;
  }

  get canDeleteCustomReports() {
    if (this.appService.app.canShareReportsInternally) {
      return this.customReportsService.canDeleteReport && !this.args.editReportDisabled;
    } else {
      return this.customReportsService.canDeleteReport;
    }
  }

  get canChangeCustomReports() {
    return this.customReportsService.canChangeCustomReports;
  }

  @action
  scheduleReport() {}

  get isFavorite() {
    return this.favoritesService.isFavoriteReport(this.args.report.id);
  }

  @task({ drop: true })
  *addOrRemoveFavorite() {
    yield taskFor(this.favoritesService.addOrRemoveFavorite).perform(this.args.report.id);
  }

  @action
  loadPermissionModalForChange() {
    return this.customReportsService.loadChangePermissionModal();
  }

  @action
  loadPermissionModalForDelete() {
    if (this.appService.app.canShareReportsInternally && this.args.editReportDisabled) {
      this.reportAccessService.loadReportAccessModal(this.args.report.id, () => {});
    } else {
      return this.customReportsService.loadDeletePermissionsModal();
    }
  }

  @task({ drop: true })
  *deleteReport() {
    yield taskFor(this.args.deleteReport).perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ReportActions': typeof ReportingCustomReportReportActions;
    'reporting/custom/report/report-actions': typeof ReportingCustomReportReportActions;
  }
}
