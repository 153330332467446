/* import __COLOCATED_TEMPLATE__ from './filter-bar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

export default class FilterBar extends Component {
  @action
  onFiltersChanged(filterType, filterValues) {
    let isOnlyMessengerChannel =
      filterValues?.length === 1 && filterValues?.firstObject !== 'messenger';

    if (this.args.source?.length && filterType === 'channel' && !isOnlyMessengerChannel) {
      this.args.onFiltersChanged('source', [REPORTING_FILTER_SELECT_ALL]);
    }

    this.args.onFiltersChanged(filterType, filterValues);
  }

  get channelIsMessenger() {
    return this.args.channel?.length === 1 && this.args.channel?.firstObject === 'messenger';
  }
}
