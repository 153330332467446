/* import __COLOCATED_TEMPLATE__ from './send-app.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
export default Component.extend({
  appService: service(),
  init() {
    this._super(...arguments);
    this.set('messengerAppName', this.get('action.actionData.appName'));
  },
  actions: {
    selectApp(options, messengerApp) {
      let appName = messengerApp.get('name');
      this.set('messengerAppName', appName);
      this.set('action.actionData', {
        appName,
        messengerAppId: messengerApp.get('id'),
        options,
      });

      let onChange = this.onChange;
      if (onChange) {
        onChange();
      }
    },
  },
});
