/* import __COLOCATED_TEMPLATE__ from './team-select.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { generateSafeEmoji } from 'embercom/lib/emoji';

export default class TeamSelect extends Component {
  @service appService;
  @service intl;

  get sortedTeams() {
    return this.appService.app.teams.sortBy('name');
  }

  get teamSelectItems() {
    if (this.sortedTeams.length === 0) {
      return [
        {
          value: null,
          isDisabled: true,
          component: 'settings/saved-replies/team-select-empty-item',
        },
      ];
    }
    return this.sortedTeams.map(({ name, id, avatar_emoji }) => ({
      text: name,
      value: id,
      isSelected: (this.args.selectedTeams || []).includes(id),
      component: 'settings/saved-replies/team-select-item',
      componentAttrs: { teamAvatar: generateSafeEmoji(avatar_emoji) },
    }));
  }

  get openerLabel() {
    if (isEmpty(this.args.selectedTeams)) {
      return this.intl.t('inbox.manage-macros.team-select.opener-label');
    } else {
      return this.args.selectedTeams
        .map((id) => {
          let team = this.sortedTeams.find((team) => parseInt(team.id, 10) === parseInt(id, 10));
          if (team) {
            return `${team.avatar_emoji} ${team.name}`;
          } else {
            return this.intl.t('new-settings.helpdesk.macros.deleted-team');
          }
        })
        .join(', ');
    }
  }

  @action selectionChanged(newSelection) {
    this.args.onSelectionChanged(newSelection);
  }
}
