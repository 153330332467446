/* import __COLOCATED_TEMPLATE__ from './chart-drill-in.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import type Chart from 'embercom/models/reporting/custom/chart';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
interface Args {
  isOpen: boolean;
  onClose: () => void;
  shouldExpandNavOnClose: boolean;
  chart: Chart;
  chartSeries: ChartSeries;
  renderableChart: RenderableChart;
  knownValuesEndpointSources: string[];
  conversationAttributeDescriptors: Array<ConversationAttributeDescriptor>;
  columns: string[];
  setColumns: (chartSeries: ChartSeries, columns: string[]) => void;
  isReportLevel: boolean;
  eventPlace: string;
  isStandalone?: boolean;
}

export default class ReportingCustomReportChartDrillIn extends Component<Args> {
  @service navbarCollapsingService: any;

  @action
  onClose() {
    if (this.navbarCollapsingService.collapsed && this.args.shouldExpandNavOnClose) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
    this.args.onClose();
  }

  get headerName() {
    return this.args.renderableChart.metricDescription(this.args.chartSeries);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ChartDrillIn': typeof ReportingCustomReportChartDrillIn;
    'reporting/custom/report/chart-drill-in': typeof ReportingCustomReportChartDrillIn;
  }
}
