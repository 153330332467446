/* import __COLOCATED_TEMPLATE__ from './standard.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class SeriesNodeInserterStandard extends Component {
  @service appService;

  get hasAccessToRequiredFeature() {
    return this.args.config.hasAccessToRequiredFeature(this.appService.app);
  }

  get isTooltipDisabled() {
    return this.args.inserterConfig.isTooltipDisabled(this.appService.app);
  }

  get disabledTooltipContent() {
    return this.args.inserterConfig.disabledTooltipContent();
  }
}
