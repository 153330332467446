/* import __COLOCATED_TEMPLATE__ from './team-filter.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { difference, isEqual, without } from 'underscore';
import { inject as service } from '@ember/service';
import {
  NO_FILTER,
  WITHOUT_FILTER_VALUE,
  TEAM_FILTER_TYPE,
} from 'embercom/lib/settings/filters/constants';

export default class TeamFilter extends Component {
  @service appService;
  @service intl;

  @tracked selectedFilter = this.args.filterValues;
  @tracked label = this.currentLabel;

  get filterItems() {
    return [
      {
        items: [
          {
            text: this.intl.t('settings.filters.team-filter.any'),
            value: NO_FILTER,
            isSelected: this.selectedFilter.includes(NO_FILTER),
          },
          {
            text: this.intl.t(
              this.appService.app.canUseHelpdeskSetup
                ? 'settings.filters.team-filter.no-team-inbox'
                : 'settings.filters.team-filter.no-team',
            ),
            value: WITHOUT_FILTER_VALUE,
            isSelected: this.selectedFilter.includes(WITHOUT_FILTER_VALUE),
          },
        ],
      },
      {
        heading: this.intl.t(
          this.appService.app.canUseHelpdeskSetup
            ? 'settings.filters.team-filter.team-inboxes'
            : 'settings.filters.team-filter.teams',
        ),
        items: this.appService.app.teams.map((team) => {
          return {
            text: team.name,
            value: team.id,
            isSelected: this.selectedFilter.includes(team.id),
          };
        }),
      },
    ];
  }

  get currentLabel() {
    if (isEqual(this.selectedFilter, [NO_FILTER])) {
      return this.intl.t('settings.filters.team-filter.any');
    } else if (isEqual(this.selectedFilter, [WITHOUT_FILTER_VALUE])) {
      return this.intl.t(
        this.appService.app.canUseHelpdeskSetup
          ? 'settings.filters.team-filter.no-team-inbox'
          : 'settings.filters.team-filter.no-team',
      );
    } else {
      let selectedTeamNames = this.selectedFilter.map((filterValue) => {
        return this.appService.app.teams.find((team) => team.id === filterValue).name;
      });
      return selectedTeamNames.join(` ${this.intl.t('settings.filters.team-filter.and')} `);
    }
  }

  @action
  onChangeFilterValue(value) {
    let addedFilterValue = difference(value, this.selectedFilter);

    if (isEqual(addedFilterValue, [NO_FILTER]) || isEqual(value, [])) {
      this.selectedFilter = [NO_FILTER];
    } else if (isEqual(addedFilterValue, [WITHOUT_FILTER_VALUE])) {
      this.selectedFilter = [WITHOUT_FILTER_VALUE];
    } else {
      this.selectedFilter = without(value, NO_FILTER, WITHOUT_FILTER_VALUE);
    }

    this.label = this.currentLabel;
  }

  @action
  clearFilters() {
    if (isEqual(this.args.filterValues, [NO_FILTER])) {
      this.selectedFilter = this.args.filterValues;
      this.label = this.currentLabel;
    }
  }

  @action
  updateFilter() {
    this.args.updateFilterValues(TEAM_FILTER_TYPE, this.selectedFilter);
  }
}
