/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { scheduleOnce } from '@ember/runloop';
import { readOnly } from '@ember/object/computed';
import { capitalize } from '@ember/string';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';
import { select } from 'd3-selection';
import 'd3-transition'; // We need this for transitions
import PALETTE from '@intercom/pulse/lib/palette';
import defaultTo from '@intercom/pulse/lib/default-to';

export const DEFAULT_CHART_MARGIN = { top: 20, right: 0, bottom: 20, left: 70 };

export default Component.extend({
  tagName: 'svg',

  width: defaultTo(500),
  height: defaultTo(250),
  attributeBindings: [
    'width',
    'height',
    'data-test-base-chart',
    'data-test-reporting-line-chart',
    'data-test-reporting-stacked-bar-chart',
    'data-test-reporting-bar-chart',
  ],
  margin: DEFAULT_CHART_MARGIN,
  transitionDuration: 1000,
  color: defaultTo(PALETTE['vis-blue-40']),
  'data-test-base-chart': true,
  timezone: readOnly('signal.timezone'),
  signal: notImplemented(),

  signalName: computed('signal.name', function () {
    return capitalize(this.get('signal.name').replace('_', ' '));
  }),

  data: readOnly('signal.context'),

  chart() {
    return select(this.element).select('.chart');
  },

  didInsertElement() {
    this._super(...arguments);
    scheduleOnce('render', this, this._drawChart);
  },

  didReceiveAttrs() {
    this._super(...arguments);
    scheduleOnce('render', this, this._redrawChart); // TODO Make this a task
  },

  drawChart() {},
  drawAxes() {},
  redrawChart() {},

  _drawChart() {
    let margin = this.margin;

    select(this.element)
      .append('g')
      .classed('chart', true)
      .attr('transform', `translate(${margin.left},${margin.top})`);

    this.drawChart();
    this.drawAxes();
    this._redrawChart();
  },

  _redrawChart() {
    this.redrawChart();
  },

  chartHeight: computed('height', 'margin', function () {
    let margin = this.margin;
    return Math.max(10, this.height - margin.top - margin.bottom);
  }),

  chartWidth: computed('width', 'margin', function () {
    let margin = this.margin;
    return Math.max(10, this.width - margin.left - margin.right);
  }),
});
