/* import __COLOCATED_TEMPLATE__ from './conversation-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import { buildFiltersForDataConfig } from 'embercom/lib/reporting/custom/data-config-builder-helpers';
import type IntlService from 'embercom/services/intl';
import type Chart from 'embercom/models/reporting/custom/chart';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { type FieldMetric } from 'embercom/objects/reporting/unified/metrics/types';

interface Args {
  chart: Chart;
  renderableChart: RenderableChart;
  onClose: () => void;
  showSideDrawer: boolean;
}

export default class ConversationSideDrawer extends Component<Args> {
  @service declare appService: any;
  @service intercomEventService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  get title() {
    let reportTitle = `${this.args.chart.title || this.args.renderableChart.description}`;
    return this.metricModel === 'ticket'
      ? this.intl.t('reporting.custom-reports.side-drawer.title-tickets', { reportTitle })
      : this.intl.t('reporting.custom-reports.side-drawer.title-conversations', { reportTitle });
  }

  get emptyStateDescription() {
    return this.metricModel === 'ticket'
      ? this.intl.t('reporting.custom-reports.side-drawer.empty-state-tickets')
      : this.intl.t('reporting.custom-reports.side-drawer.empty-state-conversations');
  }

  get includedObjectViews() {
    if (this.metricModel === 'ticket') {
      return ['ticket'];
    }
    return [];
  }

  get metric(): FieldMetric {
    return this.args.chart.chartSeries.firstObject.metric;
  }

  get metricModel(): string {
    return this.metric.model || 'conversation';
  }

  get metricProperty(): string {
    return this.metric.property;
  }

  get tooltipText() {
    return this.metricModel === 'ticket'
      ? this.intl.t('reporting.custom-reports.side-drawer.tooltip-tickets')
      : this.intl.t('reporting.custom-reports.side-drawer.tooltip-conversations');
  }

  @task({ restartable: true }) *fetchConversationIds(): any {
    let params = {
      app_id: this.app.id,
      source: this.args.renderableChart.source,
      filters: buildFiltersForDataConfig(
        this.args.renderableChart,
        this.metric,
        undefined,
        this.args.chart.chartSeries.firstObject.filters,
      ),
      time: this.args.renderableChart.time,
      metric_property: this.metricProperty,
    };

    return yield post('/ember/reporting/conversations/ids_by_custom_chart_filters', params);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ConversationSideDrawer': typeof ConversationSideDrawer;
    'reporting/custom/report/conversation-side-drawer': typeof ConversationSideDrawer;
  }
}
