/* import __COLOCATED_TEMPLATE__ from './ticket-state-detail.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import {
  ticketSystemStatesList,
  groupCustomStatesBySystemStates,
} from 'embercom/objects/inbox/ticket-custom-state';
import { tracked } from '@glimmer/tracking';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { action } from '@ember/object';

interface Args {}

export default class TicketStateDetail extends Component<Args> {
  @service store: any;
  @service declare appService: any;
  @service declare ticketStateService: TicketStateService;
  @tracked selectedTab = 'live';
  @tracked createStateEditorOpen = false;
  @tracked editorStateType = TicketSystemState.Submitted;

  get systemStatesOptions() {
    return [
      {
        items: [
          {
            component: 'settings/ticket-states/custom-state-dropdown-option',
            systemState: TicketSystemState.Submitted,
            onSelectItem: () => this.openCreateStateDropdown(TicketSystemState.Submitted),
          },
          {
            component: 'settings/ticket-states/custom-state-dropdown-option',
            systemState: TicketSystemState.InProgress,
            onSelectItem: () => this.openCreateStateDropdown(TicketSystemState.InProgress),
          },
          {
            component: 'settings/ticket-states/custom-state-dropdown-option',
            systemState: TicketSystemState.WaitingOnCustomer,
            onSelectItem: () => this.openCreateStateDropdown(TicketSystemState.WaitingOnCustomer),
          },
          {
            component: 'settings/ticket-states/custom-state-dropdown-option',
            systemState: TicketSystemState.Resolved,
            onSelectItem: () => this.openCreateStateDropdown(TicketSystemState.Resolved),
          },
        ],
      },
    ];
  }

  get app() {
    return this.appService.app;
  }

  get ticketSystemStates(): TicketSystemState[] {
    return ticketSystemStatesList();
  }

  get customStatesGroupedBySystemStates(): Record<`${TicketSystemState}`, TicketCustomState[]> {
    return groupCustomStatesBySystemStates(this.ticketStateService.customStates);
  }

  @action
  openCreateStateDropdown(systemState: TicketSystemState) {
    this.editorStateType = systemState;
    this.createStateEditorOpen = true;
  }

  @action
  onEditorClose() {
    this.createStateEditorOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketStates::TicketStateDetail': typeof TicketStateDetail;
    'settings/ticket-states/ticket-state-detail': typeof TicketStateDetail;
  }
}
