/* import __COLOCATED_TEMPLATE__ from './tag-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { isEmpty } from '@ember/utils';

export default class TagFilter extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;
  @tracked tags = this.appService.app.tags;
  @tracked label = this.currentLabel;
  @tracked operator = this.startingOperator;

  // store the selection here so that the UI doesn't update until the drop down is closed
  @tracked selectedDropdownItems = this.selectedValues;
  @tracked visibleTags = this.tags;

  get startingOperator() {
    return this.args.selected.operator || 'category';
  }

  get selectedValues() {
    let values = this.args.selected.values || [];
    return values[0] === REPORTING_FILTER_SELECT_ALL ? [] : values;
  }

  get items() {
    return [
      {
        heading: this.intl.t(
          'components.reporting.custom.chart-builder.filter-bar.tag-filter.tags',
        ),
        items: this.allItems,
      },
    ];
  }

  get selectedTags() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get allItems() {
    return this.visibleTags.map((tag) => ({
      text: tag.name,
      value: tag.id,
      isSelected: this.selectedDropdownItems.includes(tag.id),
    }));
  }

  get currentLabel() {
    if (this.selectedTags.length === 0) {
      return this.intl.t('components.reporting.custom.chart-builder.filter-bar.tag-filter.any');
    }

    let tagNames = this.selectedTags.map((tagId) => {
      return this.tags.find((tag) => tag.id === tagId)?.name;
    });
    let returnedNames = tagNames.filter((name) => !isEmpty(name)).join(', ');
    return (
      returnedNames ||
      this.intl.t('components.reporting.custom.chart-builder.filter-bar.tag-filter.any')
    );
  }

  get name() {
    let label =
      this.args.name ||
      this.intl.t('components.reporting.custom.chart-builder.filter-bar.tag-filter.tags');
    return label + this.labelText;
  }

  get labelText() {
    return this.operator === 'category'
      ? ` ${this.intl.t('components.reporting.custom.chart-builder.filter-bar.tag-filter.is')}`
      : ` ${this.intl.t('components.reporting.custom.chart-builder.filter-bar.tag-filter.is-not')}`;
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_tag',
      object: this.args.analyticsObject,
      operator: this.operator,
    });
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.trackAnalytics();
    this.args.setSelected(this.selectedDropdownItems, this.operator);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.operator = null;
    this.args.removeFilter();
  }

  @action
  filterValues(filter) {
    this.visibleTags = this.tags.filter((tag) =>
      tag.name.toLowerCase().includes(filter.toLowerCase()),
    );
  }
}
