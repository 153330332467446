/* import __COLOCATED_TEMPLATE__ from './visualization-options-tab.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import {
  type VisualizationTraits,
  type ToggleableVisualizationTraitNames,
  visualizationSupportOptions,
} from 'embercom/models/reporting/custom/visualization-options';
import type Target from 'embercom/models/reporting/custom/target';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { type Metric } from 'embercom/objects/reporting/unified/metrics/types';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import type Chart from 'embercom/models/reporting/custom/chart';
import { isEmpty } from '@ember/utils';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  visualizationType: string;
  values: VisualizationTraits;
  metric: Metric;
  toggleValue: (key: ToggleableVisualizationTraitNames) => void;
  setTarget: (target: Target | null) => void;
  disableTimeComparison: boolean;
  chart: Chart;
}

const SECONDS_IN = {
  minute: 60,
  hour: 3600,
  day: 3600 * 24,
};

export default class VisualizationOptionsTab extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: any;

  @tracked targetEnabled = isPresent(this.startingTargetValue);
  @tracked currentTargetValue = this.startingTargetValue;
  @tracked selectedUnit = this.startingUnit;

  get app() {
    return this.appService.app;
  }

  get startingTargetValue() {
    if (this.isDurationMetric && this.args.values.target?.value) {
      return this.args.values.target.value / this.selectedUnit;
    }

    return this.args.values.target?.value;
  }

  get supportedOptions() {
    return visualizationSupportOptions(this.args.visualizationType, this.args.chart.isMultimetric);
  }

  get targetValue() {
    return parseFloat(this.currentTargetValue.toFixed(2));
  }

  set targetValue(value: number) {
    value = Math.max(value, 0);
    if (this.isPercentageMetric) {
      value = Math.min(100, value);
    }
    this.currentTargetValue = value;

    debounce(this, this.setTargetValueOnChart, value, ENV.APP._500MS);
  }

  setTargetValueOnChart(value: number) {
    if (this.isDurationMetric) {
      value *= this.selectedUnit;
    }
    let target = this.store.createFragment('reporting/custom/target', {
      value,
      timeUnit: this.selectedUnit,
    });
    this.args.setTarget(target);
  }

  get isPercentageMetric() {
    return this.args.metric.unit === 'percent';
  }

  get isDurationMetric() {
    return this.args.metric.unit === 'seconds';
  }

  get suffix() {
    if (this.isPercentageMetric || this.args.values.showRelativeValues) {
      return '%';
    }
    return undefined;
  }

  get unitsHash() {
    return {
      1: {
        text: this.intl.t('reporting.custom-reports.chart.target-units.seconds'),
      },
      [SECONDS_IN.minute]: {
        text: this.intl.t('reporting.custom-reports.chart.target-units.minutes'),
      },
      [SECONDS_IN.hour]: {
        text: this.intl.t('reporting.custom-reports.chart.target-units.hours'),
      },
      [SECONDS_IN.day]: {
        text: this.intl.t('reporting.custom-reports.chart.target-units.days'),
      },
    };
  }

  get units() {
    return Object.keys(this.unitsHash).map((unit: any) => {
      return {
        text: this.unitsHash[unit].text,
        value: parseInt(unit, 10),
      };
    });
  }

  get startingUnit(): any {
    let val = this.args.values.target?.value;
    let unit = 1;
    if (isEmpty(val)) {
      return 1;
    }
    if (val >= SECONDS_IN.day) {
      unit = SECONDS_IN.day;
    } else if (val >= SECONDS_IN.hour) {
      unit = SECONDS_IN.hour;
    } else if (val >= SECONDS_IN.minute) {
      unit = SECONDS_IN.minute;
    } else {
      unit = 1;
    }

    let valueInUnit = val / unit;

    if (valueInUnit.toString().split('.')[1]?.length > 1 && unit > 1) {
      unit = unit === SECONDS_IN.day ? unit / 24 : unit / 60;
    }
    return unit;
  }

  @action
  toggleTargetEnabled() {
    this.targetEnabled = !this.targetEnabled;
    if (!this.targetEnabled) {
      this.args.setTarget(null);
    } else {
      this.targetValue = this.startingTargetValue;
    }
  }

  @action
  updateSelectedUnit(id: any) {
    this.selectedUnit = id;
    this.targetValue = this.targetValue;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::VisualizationOptionsTab': typeof VisualizationOptionsTab;
    'reporting/custom/chart-builder/visualization-options-tab': typeof VisualizationOptionsTab;
  }
}
