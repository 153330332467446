/* import __COLOCATED_TEMPLATE__ from './report-list-table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type ViewItem } from 'embercom/services/reporting-views';
import Admin from 'embercom/models/admin';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import { modifier } from 'ember-modifier';
import type Store from '@ember-data/store';

interface Args {
  reports: ViewItem[];
  shouldShowSearch: boolean;
}
type SortDirection = 'asc' | 'desc';

interface Signature {
  Args: Args;
  Element: HTMLElement;
}
const PAGE_SIZE = 50;
const SIZE_INCREASE = 20;

export default class ReportListTable extends Component<Signature> {
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked searchTerm: string | undefined;
  lastURL: string | undefined;

  @tracked sortState: any = {
    valuePath: null,
    direction: 'desc',
  };
  @tracked scrollMoreCount = 0;

  @action
  async findTeammate(teammateId: string) {
    return Admin.peekAndMaybeLoad(this.store, teammateId);
  }

  resetOnUrlChange = modifier(
    (_element: Element, [currentURL]: [string]) => {
      if (this.lastURL !== currentURL) {
        this.resetSearch();
        this.scrollMoreCount = 0;
        this.lastURL = currentURL;
      }
    },
    { eager: false },
  );

  get showLastUpdatedColumn() {
    return this.args.reports.some((report) => report.updatedAt);
  }

  get data() {
    let { direction, valuePath } = this.sortState;
    if (valuePath) {
      return direction === 'asc'
        ? this.filteredAndPaginatedReports.sortBy(valuePath)
        : this.filteredAndPaginatedReports.sortBy(valuePath).reverse();
    } else {
      return this.filteredAndPaginatedReports;
    }
  }

  get columns() {
    let columns = [
      {
        valuePath: 'name',
        label: this.intl.t('reporting.custom-reports.report.table.title'),
        isSortable: true,
      },
      {
        valuePath: 'author.name',
        label: this.intl.t('reporting.custom-reports.report.table.created-by'),
        isSortable: true,
      },
    ];

    if (this.showLastUpdatedColumn) {
      columns.push({
        valuePath: 'updatedAt',
        label: this.intl.t('reporting.custom-reports.report.table.updated-at'),
        isSortable: true,
      });
    }
    return columns;
  }

  private get filteredAndPaginatedReports(): ViewItem[] {
    if (this.searchTerm && this.searchTerm.length > 0) {
      let untitledReportName = this.intl.t('reporting.custom-reports.report.untitled');
      let filteredReports = this.args.reports.filter((report: any) => {
        let reportName = report.name || untitledReportName;
        return reportName.toLocaleLowerCase().includes(this.searchTerm);
      });
      return filteredReports.slice(0, this.currentPageLength);
    } else {
      return this.args.reports.slice(0, this.currentPageLength);
    }
  }

  get currentPageLength() {
    return Math.min(PAGE_SIZE + this.scrollMoreCount * SIZE_INCREASE, this.args.reports.length);
  }

  @action
  onLoadMore() {
    this.scrollMoreCount += 1;
  }

  @action
  onSort(clickedValuePath: string) {
    let oldSortDirection = this.sortState.direction;
    let newSortDirection: SortDirection = oldSortDirection === 'asc' ? 'desc' : 'asc';
    this.sortState = {
      valuePath: clickedValuePath,
      direction: newSortDirection,
    };
    this.scrollMoreCount = 0;
  }

  @action
  doSearch(item: any) {
    this.searchTerm = item.target.value.toLocaleLowerCase();
    this.scrollMoreCount = 0;
  }

  @action
  resetSearch() {
    this.searchTerm = '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Views::ReportListTable': typeof ReportListTable;
  }
}
