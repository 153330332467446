/* import __COLOCATED_TEMPLATE__ from './content-type-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { pluralHumanReadableObjectName } from 'embercom/helpers/matching-system/plural-human-readable-object-name';
import { objectFeatures } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';

export default class ContentTypeFilter extends Component {
  @service outboundHomeService;
  @service paywallService;
  @service intercomEventService;
  @service intl;

  @tracked selectedContentTypeIds = this.args.contentTypeIds || [];

  get contentTypes() {
    let allContentTypeIds = this.outboundHomeService.rulesetBasedObjectTypes;

    return allContentTypeIds
      .map((id) => this.buildItem(id))
      .sortBy('text')
      .reverse()
      .sortBy('isSelected')
      .reverse();
  }

  buildItem(contentTypeId) {
    let isSelected = this.selectedContentTypeIds.includes(contentTypeId);
    let featureKey = objectFeatures[contentTypeId];
    return {
      text: capitalize(pluralHumanReadableObjectName(contentTypeId, this.intl)),
      value: contentTypeId,
      isSelected,
      component: 'reporting/content-type-filter/content-type-dropdown-item',
      componentAttrs: {
        featureKey,
      },
      componentClasses: 'csf-content-type-filter-item opacity-100',
      isDisabled: this.paywallService.isPaywallActive({ featureName: featureKey }),
    };
  }

  get filterName() {
    if (this.selectedContentTypeIds.length === 0) {
      return this.intl.t('reporting.content-type-filter.all-content-types');
    }

    let firstContentType = capitalize(
      pluralHumanReadableObjectName(this.selectedContentTypeIds.firstObject, this.intl),
    );

    if (this.selectedContentTypeIds.length === 1) {
      return firstContentType;
    } else {
      return this.intl.t('reporting.human-readable-object-names.filter-name', {
        firstContentType,
        length: this.selectedContentTypeIds.length - 1,
      });
    }
  }

  @action
  updateMessagesFilter(selectedValues) {
    this.selectedContentTypeIds = selectedValues;
    this.args.onUpdate('contentTypeIds', selectedValues);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved',
      object: 'filter',
      context: 'any_content_type',
    });
  }
}
