/* import __COLOCATED_TEMPLATE__ from './switch-workspace-and-logout-button.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';

export default class SwitchWorkspaceAndLogoutButtonComponent extends Component {
  get groupedApps() {
    return this._sortedProductionApps.map((app) => ({ app, testApp: this._findTestApp(app) }));
  }

  get hasMoreThanOneApp() {
    return this.groupedApps.length > 1 || isPresent(this.groupedApps[0].testApp);
  }

  get _sortedProductionApps() {
    return this._productionApps.sortBy('name');
  }

  get _productionApps() {
    return this.args.admin.apps.filterBy('is_test_app', false) || [];
  }

  get _testApps() {
    return this.args.admin.apps.filterBy('is_test_app') || [];
  }

  _findTestApp(app) {
    return this._testApps.findBy('id', app.test_app_id);
  }
}
