/* import __COLOCATED_TEMPLATE__ from './permission-toggle-with-seats-row.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';

interface Args {
  permissionsObject?: any;
  permission?: any;
  updatePermission?: any;
  changed?: any;
  isDisabled?: boolean;
  isDisabledByRole?: boolean;
  unallowedPermissionList?: any;
  seatDependentPermissionList?: any;
}

export default class PermissionToggleWithSeatsRow extends Component<Args> {
  @service appService: $TSFixMe;
  @service permissionsHierarchyService: $TSFixMe;

  get parentValue() {
    return this.args.permissionsObject[`can_${this.args.permission.parent_id}`];
  }

  get showRequireSeatTag() {
    let noParent = !this.args.permission?.parent_id;
    let parentIsDisabled = this.args.unallowedPermissionList?.includes(
      `can_${this.args.permission?.parent_id}`,
    );
    let noParentOrParentIsDisabled = noParent || parentIsDisabled;
    return (
      this.isDisabled &&
      this.args.seatDependentPermissionList?.includes(`can_${this.args.permission?.key}`) &&
      noParentOrParentIsDisabled
    );
  }

  @action
  togglePermission() {
    let frontEndKey = `can_${this.args.permission.key}`;
    if (this.args.permissionsObject[frontEndKey] && isPresent(this.args.permission.children_ids)) {
      this.args.permission.children_ids.forEach((child: string) => {
        if (this.permissionsHierarchyService.permissionsHierarchy[child]['type'] === 'crud') {
          this.permissionsHierarchyService.permissionsHierarchy[child]['actions'].forEach(
            (action: string) => {
              this.args.permissionsObject[`can_${action}`] = false;
            },
          );
        } else if (this.args.permissionsObject[`can_${child}`] !== undefined) {
          // Don't change children permissions that are set as undefined since it means a conflict on bulk permission edit
          this.args.permissionsObject[`can_${child}`] = false;
        }
      });
    }
    this.args.permissionsObject[frontEndKey] = !this.args.permissionsObject[frontEndKey];
  }

  get isDisabled() {
    let hasParent = !!this.args.permission?.parent_id;
    let noParentValue = !this.parentValue;
    return (
      this.args.isDisabled ||
      this.args.isDisabledByRole ||
      this.args.unallowedPermissionList?.includes(`can_${this.args.permission.key}`) ||
      (hasParent && noParentValue)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::Permissions::PermissionToggleWithSeatsRow': typeof PermissionToggleWithSeatsRow;
    'settings/teammates/permissions/permission-toggle-with-seats-row': typeof PermissionToggleWithSeatsRow;
  }
}
