/* import __COLOCATED_TEMPLATE__ from './heatmap.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import HeatmapBuilder from 'embercom/lib/reporting/flexible/heatmap-builder';
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { cached } from 'tracked-toolbox';
import type ViewConfig from 'embercom/services/reporting-chart-service';
import type RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { inject as service } from '@ember/service';
import { shouldConvertNullsToZeros } from 'embercom/lib/reporting/flexible/data-response-helpers';
import { type SeriesHeatmapOptions } from 'highcharts';

interface Signature {
  Args: Args;
  Blocks: {
    default: [{ Empty: Function }];
  };
}

interface Args {
  dataConfig: any;
  range: Range;
  width: string;
  viewConfig: ViewConfig;
  renderableChart: RenderableChart;
  isPaywalled: boolean;
}

export default class Heatmap extends Component<Signature> {
  @service declare appService: any;
  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.args.dataConfig,
    viewConfig: this.args.viewConfig,
    renderableChart: this.args.renderableChart,
  }));

  @cached
  get chartData(): SeriesHeatmapOptions[] {
    return this.dataResource.rawChartData.map((response) => {
      let yAxis = response.groups[0].values;
      let data = yAxis.reduce((acc: any, curr: any, yAxisIndex: any) => {
        let xAxisValues = curr.groups[0].aggregations[0].values;
        let formattedValues = xAxisValues.map((value: any, xAxisValues: any) => ({
          x: xAxisValues,
          y: yAxisIndex,
          value: this.convertNullsToZeros(value, response),
        }));
        return acc.concat(formattedValues);
      }, []);
      return {
        type: 'heatmap',
        name: response.name,
        data,
        showInLegend: false,
      };
    });
  }

  get chartOptions() {
    let builder = new HeatmapBuilder({
      range: this.args.range,
      width: this.args.width,
      viewConfig: this.args.viewConfig,
      app: this.appService.app,
    });
    return builder.buildTheme();
  }

  get hasData() {
    return this.chartData.some((singleSeries) => {
      return singleSeries.data!.some(({ value }: { value: any }) => value);
    });
  }

  convertNullsToZeros(value: any, response: any) {
    let aggregation = response.aggregations[0];
    let metric = response.name;
    if (value === null && shouldConvertNullsToZeros(aggregation, metric)) {
      return 0;
    }
    value = value === undefined ? null : value;

    return value;
  }

  get showEmptyState() {
    return this.args.isPaywalled || !this.hasData;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Flexible::Heatmap': typeof Heatmap;
    'reporting/flexible/heatmap': typeof Heatmap;
  }
}
