/* import __COLOCATED_TEMPLATE__ from './surrounding-connectors.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { seriesEdgeTypes } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

const NODE_WIDTH = 192;
const DEFAULT_OFFSET = 8;
const HORIZONTAL_CONNECTOR_SPACING = 32;
export default class SeriesNodeSurroundingConnectorsComponent extends Component {
  @service seriesEditorService;

  get connectionPoints() {
    return [
      {
        x: NODE_WIDTH / 2 - HORIZONTAL_CONNECTOR_SPACING,
        y: -DEFAULT_OFFSET,
        alignment: 'top',
        direction: 'inwards',
        dataObject: { edgeTypes: [seriesEdgeTypes.alternative] },
      },
      {
        x: NODE_WIDTH / 2,
        y: -DEFAULT_OFFSET,
        alignment: 'top',
        direction: 'inwards',
        dataObject: { edgeTypes: [seriesEdgeTypes.primary, seriesEdgeTypes.alternative] },
      },
      {
        x: NODE_WIDTH / 2 + HORIZONTAL_CONNECTOR_SPACING,
        y: -DEFAULT_OFFSET,
        alignment: 'top',
        direction: 'inwards',
        dataObject: { edgeTypes: [seriesEdgeTypes.primary] },
      },

      {
        x: NODE_WIDTH / 2 - HORIZONTAL_CONNECTOR_SPACING,
        y: -DEFAULT_OFFSET,
        alignment: 'top',
        direction: 'outwards',
        dataObject: { edgeTypes: [seriesEdgeTypes.primary] },
      },
      {
        x: NODE_WIDTH / 2,
        y: -DEFAULT_OFFSET,
        alignment: 'top',
        direction: 'outwards',
        dataObject: { edgeTypes: [seriesEdgeTypes.primary, seriesEdgeTypes.alternative] },
      },
      {
        x: NODE_WIDTH / 2 + HORIZONTAL_CONNECTOR_SPACING,
        y: -DEFAULT_OFFSET,
        alignment: 'top',
        direction: 'outwards',
        dataObject: { edgeTypes: [seriesEdgeTypes.alternative] },
      },

      {
        x: NODE_WIDTH / 2 - HORIZONTAL_CONNECTOR_SPACING,
        y: DEFAULT_OFFSET,
        alignment: 'bottom',
        direction: 'inwards',
        dataObject: { edgeTypes: [seriesEdgeTypes.alternative] },
      },
      {
        x: NODE_WIDTH / 2,
        y: DEFAULT_OFFSET,
        alignment: 'bottom',
        direction: 'inwards',
        dataObject: { edgeTypes: [seriesEdgeTypes.primary, seriesEdgeTypes.alternative] },
      },
      {
        x: NODE_WIDTH / 2 + HORIZONTAL_CONNECTOR_SPACING,
        y: DEFAULT_OFFSET,
        alignment: 'bottom',
        direction: 'inwards',
        dataObject: { edgeTypes: [seriesEdgeTypes.primary] },
      },

      {
        x: NODE_WIDTH / 2 - HORIZONTAL_CONNECTOR_SPACING,
        y: DEFAULT_OFFSET,
        alignment: 'bottom',
        direction: 'outwards',
        dataObject: { edgeTypes: [seriesEdgeTypes.primary] },
      },
      {
        x: NODE_WIDTH / 2,
        y: DEFAULT_OFFSET,
        alignment: 'bottom',
        direction: 'outwards',
        dataObject: { edgeTypes: [seriesEdgeTypes.primary, seriesEdgeTypes.alternative] },
      },
      {
        x: NODE_WIDTH / 2 + HORIZONTAL_CONNECTOR_SPACING,
        y: DEFAULT_OFFSET,
        alignment: 'bottom',
        direction: 'outwards',
        dataObject: { edgeTypes: [seriesEdgeTypes.alternative] },
      },
    ];
  }

  @action addConnectionPoints() {
    this.connectionPoints.forEach((connectionPoint) => {
      this.args.node.addConnectionPoint(connectionPoint);
    });
  }

  @action updateAvailableConnectors() {
    ['outwards', 'inwards'].forEach((direction) => {
      ['bottom', 'top'].forEach((alignment) => {
        let middleConnectionPoint = this.args.node.connectionPoints.find(
          (cp) =>
            cp.alignment === alignment && cp.direction === direction && cp.x === NODE_WIDTH / 2,
        );

        if (this._edgeTypesForDirectionAndAlignment(direction, alignment).length > 1) {
          this.args.node.connectionPoints.removeObject(middleConnectionPoint);
        } else if (!middleConnectionPoint) {
          this.args.node.addConnectionPoint({
            x: NODE_WIDTH / 2,
            y: alignment === 'top' ? -DEFAULT_OFFSET : DEFAULT_OFFSET,
            alignment,
            direction,
            dataObject: { edgeTypes: [seriesEdgeTypes.primary, seriesEdgeTypes.alternative] },
          });
        }
      });
    });
  }

  _edgeTypesForDirectionAndAlignment(direction, alignment) {
    let directionalEdgesWithAlignment = this.args.node[
      direction === 'outwards' ? 'outwardEdges' : 'inwardEdges'
    ].filter((edge) => {
      let pointClosestToNode = direction === 'outwards' ? 'startAlignment' : 'endAlignment';
      return edge.uiProperties[pointClosestToNode] === alignment;
    });
    return directionalEdgesWithAlignment.map((edge) => edge.dataObject.edgeType).uniq();
  }
}
