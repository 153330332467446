/* import __COLOCATED_TEMPLATE__ from './seats-cost-change-card.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getSeatInfo } from 'embercom/lib/admins/seat-info';
import { task } from 'ember-concurrency-decorators';
import { captureException } from 'embercom/lib/sentry';
import { tracked } from '@glimmer/tracking';

export default class SeatsCostChangeCard extends Component {
  @service appService;
  @service store;
  @tracked customer;

  get activePlanIds() {
    return this.appService.app.activeProducts
      .flatMap((p) => p.plans.toArray())
      .filter((p) => p.active)
      .map((p) => p.id);
  }

  get shouldShowAnything() {
    return this.appService.app.canSeeCostChangeCard;
  }

  @task({ restartable: true })
  *loadSeatInfo() {
    try {
      this.customer = yield this.store.findRecord(
        'billing/customer',
        this.appService.app.customer_id,
        {
          reload: false,
          backgroundReload: false,
        },
      );
      if (this.args.loadSeatInfo) {
        return yield this.args.loadSeatInfo.perform();
      }
      return yield getSeatInfo({ numberOfSeatsRequired: this.args.changeInSeats });
    } catch (e) {
      captureException(e);
      console.error(e);
    }
  }
}
