/* import __COLOCATED_TEMPLATE__ from './email-continued-conversations-settings.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';

const DEFAULT_DURATION_DAYS = 5;

export default class EmailContinuedConversationsSettings extends Component {
  @service store;
  @service notificationsService;
  @service intl;

  @tracked durationInDays = this.getDurationInDays();

  @task({ enqueue: true })
  *updateSetting() {
    try {
      this.args.settings.set('duration', this.getDurationInSeconds());
      yield this.args.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  toggleSettings() {
    if (this.args.settings.duration || this.args.settings.duration === 0) {
      this.durationInDays = null;
    } else {
      this.durationInDays = DEFAULT_DURATION_DAYS;
    }
    this.updateSetting.perform();
  }

  getDurationInDays() {
    if (this.args.settings.duration || this.args.settings.duration === 0) {
      return this.args.settings.duration / (3600 * 24);
    } else {
      return null;
    }
  }

  getDurationInSeconds() {
    if (this.durationInDays || this.durationInDays === 0) {
      return this.durationInDays * 3600 * 24;
    } else {
      return null;
    }
  }
}
