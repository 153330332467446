/* import __COLOCATED_TEMPLATE__ from './chart-action-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type StampColor } from 'embercom/components/reporting/custom/chart-action-options';

interface Signature {
  Element: HTMLElement;
  Args: Args;
}
interface Args {
  icon: InterfaceIconName;
  text: string;
  isDisabled?: boolean;
  paywall?: any;
  dropdown: any;
  onSelectItem: (chartId: string) => void;
  stamp?: {
    text: string;
    color: StampColor;
  };
}

export default class ReportingCustomReportLegacyComponentChartActionDropdownItem extends Component<Signature> {
  get paywallIsActive() {
    if (!this.args.paywall) {
      return false;
    }
    return this.args.paywall.isActive;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::LegacyComponent::ChartActionDropdownItem': typeof ReportingCustomReportLegacyComponentChartActionDropdownItem;
    'reporting/custom/report/legacy-component/dropdown-item': typeof ReportingCustomReportLegacyComponentChartActionDropdownItem;
  }
}
