/* import __COLOCATED_TEMPLATE__ from './signature.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import AttributeInfoResolver, {
  AUTHOR_NAME_ATTRIBUTE,
  AUTHOR_FULL_NAME_ATTRIBUTE,
  APP_NAME_ATTRIBUTE,
  AUTHOR_JOB_TITLE_ATTRIBUTE,
  AUTHOR_PHONE_NUMBER_ATTRIBUTE,
  AUTHOR_DEPARTMENT_ATTRIBUTE,
  AUTHOR_EMAIL_ATTRIBUTE,
} from 'embercom/lib/common/template-attribute-resolver';
import {
  BaseConfig,
  BlocksDocument,
  EMOJI_TYPEAHEAD,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_MARKDOWN_ANCHOR,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
} from '@intercom/embercom-prosemirror-composer';
import { tracked } from '@glimmer/tracking';
import { post } from 'embercom/lib/ajax';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { timeout } from 'ember-concurrency';
import imageSize from 'embercom/lib/image-size';
import { INLINE_CONTROL_ALIGNMENT } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';

class ComposerConfig extends BaseConfig {
  placeholder = '';
  autoFocus = false;
  customStylesConfig = {
    allowCustomStyles: true,
    formattingSidebarClass: 'email-composer-formatting-side-bar',
  };
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = true;
  hideFromBlockFormatter = [INLINE_CONTROL_ALIGNMENT];
  hideFromTextFormatter = [INLINE_CONTROL_ALIGNMENT];
  textDirection = 'ltr';
  allowedBlocks = [
    'paragraph',
    'orderedList',
    'unorderedList',
    'heading',
    'subheading',
    'codeBlock',
    'html',
  ];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_EMOJI,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];

  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'fallback-editor' },
    { name: 'media-inserter' },
    { name: 'html/edit-button' },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];

  constructor({ resolver }) {
    super();

    this.templating = {
      picker: 'common/attribute-picker',
      resolver,
    };
  }
}

const EMAIL_SIGNATURE_ATTRIBUTES = [
  AUTHOR_NAME_ATTRIBUTE,
  AUTHOR_FULL_NAME_ATTRIBUTE,
  AUTHOR_JOB_TITLE_ATTRIBUTE,
  AUTHOR_PHONE_NUMBER_ATTRIBUTE,
  AUTHOR_DEPARTMENT_ATTRIBUTE,
  AUTHOR_EMAIL_ATTRIBUTE,
  APP_NAME_ATTRIBUTE,
];

const DEBOUNCE_MS = 250;

const DEFAULT_WIDTH = 100;
export default class Signature extends Component {
  @service store;
  @service appService;
  @service attributeService;
  @service notificationsService;
  @service intercomEventService;
  @service intl;

  @tracked signature;
  @tracked blocksDoc;
  @tracked previewHtml;

  constructor() {
    super(...arguments);
    this.blocksDoc = new BlocksDocument([]);
    this.loadSignature.perform();
  }

  get composerConfig() {
    return new ComposerConfig({ resolver: this.resolver });
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: [],
      includeAppAttributes: true,
      manualAppAttributes: EMAIL_SIGNATURE_ATTRIBUTES,
      hideAppAllowedAttributes: true,
    });
  }

  get serializedBlocks() {
    return this.signature?.blocks || [];
  }

  get logoMaxSize() {
    return 9999;
  }

  get logoMinSize() {
    return 1;
  }

  async LogoDimensionRatio(dimensions) {
    return dimensions.width / dimensions.height;
  }

  async getLogoDimensions(src) {
    return await imageSize(src);
  }

  logoDefaultHeight(ratio) {
    return parseInt(DEFAULT_WIDTH / ratio, 10);
  }

  @task({ enqueue: true })
  *updateSignature() {
    try {
      yield this.signature.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.signature.saved'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.signature.save-error'),
      });
    }
  }

  @task({ drop: true })
  *loadSignature() {
    try {
      this.signature = yield this.store.findRecord('email-signature', this.appService.app.id);
      this.blocksDoc = new BlocksDocument(this.signature.blocks);
      this.updateSignaturePreview.perform();
      if (this.signature.logoUrl) {
        let dimensions = yield this.getLogoDimensions(this.signature.logoUrl);
        let ratio = yield this.LogoDimensionRatio(dimensions);
        this.signature.width = this.signature.width || DEFAULT_WIDTH;
        this.signature.height = this.signature.height || this.logoDefaultHeight(ratio);
      }
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.signature.load-error'),
      });
    }
  }

  @action
  updateBlocks(blocksDoc) {
    this.blocksDoc = blocksDoc;
    this.updateSignatureBlocks();
    this.updateSignaturePreview.perform({ blocks: this.blocksDoc.blocks });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'edited',
      object: 'text',
    });
  }

  @action
  async onImageChange(logoUrl) {
    if (logoUrl) {
      let dimensions = await this.getLogoDimensions(logoUrl);
      let ratio = await this.LogoDimensionRatio(dimensions);
      this.signature.width = DEFAULT_WIDTH;
      this.signature.height = this.logoDefaultHeight(ratio);
      if (!this.signature.logoPosition) {
        this.signature.logoPosition = 'left';
      }
    } else {
      this.signature.width = null;
      this.signature.height = null;
      this.signature.logoPosition = null;
    }
    this.signature.logoUrl = logoUrl;
    this.updateSignaturePreview.perform({ logo_url: logoUrl });

    this.intercomEventService.trackAnalyticsEvent({
      action: logoUrl ? 'updated_image' : 'removed_image',
      object: 'logo',
    });
  }

  @action
  onLogoPositionChange(position) {
    this.signature.logoPosition = position;
    this.updateSignaturePreview.perform({ position });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated_position',
      object: 'logo',
    });
  }

  @action
  async onLogoHeightChange(height) {
    this.signature.height = height;
    if (this.signature.height > this.logoMaxSize) {
      height = this.logoMaxSize;
    }
    if (this.signature.height < this.logoMinSize) {
      height = this.logoMinSize;
    }
    let dimensions = await this.getLogoDimensions(this.signature.logoUrl);
    let ratio = await this.LogoDimensionRatio(dimensions);
    let width = parseInt(height * ratio, 10);
    this.signature.width = width;
    if (height === this.logoMinSize) {
      this.signature.width = this.logoMinSize;
    }
    this.signature.height = height;
    this.updateSignaturePreview.perform({
      width: this.signature.width,
      height: this.signature.height,
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated_height',
      object: 'logo',
    });
  }

  @action
  async onLogoWidthChange(width) {
    this.signature.width = width;
    if (width > this.logoMaxSize) {
      width = this.logoMaxSize;
    }
    if (width < this.logoMinSize) {
      width = this.logoMinSize;
    }
    let dimensions = await this.getLogoDimensions(this.signature.logoUrl);
    let ratio = await this.LogoDimensionRatio(dimensions);
    let height = parseInt(width / ratio, 10);
    this.signature.width = width;
    this.signature.height = height;
    if (width === this.logoMinSize) {
      this.signature.height = this.logoMinSize;
    }
    this.updateSignaturePreview.perform({
      width: this.signature.width,
      height: this.signature.height,
    });

    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated_width',
      object: 'logo',
    });
  }

  @action
  saveSignature() {
    this.updateSignature.perform();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved_changes',
      object: 'signature',
    });
  }

  @action
  restoreSignature() {
    this.signature.rollbackAttributes();
    this.blocksDoc = new BlocksDocument(this.signature.blocks);
    this.updateSignaturePreview.perform();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'cancelled_changes',
      object: 'signature',
    });
  }

  updateSignatureBlocks() {
    this.signature.blocks = this.blocksDoc.blocks;
  }

  get signatureAttributes() {
    return {
      blocks: this.signature.blocks,
      logo_url: this.signature.logoUrl,
      logo_position: this.signature.logoPosition,
      width: this.signature.width,
      height: this.signature.height,
    };
  }

  @task({ restartable: true })
  *updateSignaturePreview(updatedAttributes = {}) {
    try {
      yield timeout(DEBOUNCE_MS);
      let response = yield post('/ember/email_signature_preview', {
        app_id: this.appService.app.id,
        ...this.signatureAttributes,
        ...updatedAttributes,
      });
      this.previewHtml = sanitizeHtml(response.html);
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.signature.update-preview-error'),
      });
    }
  }
}
