/* import __COLOCATED_TEMPLATE__ from './show-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';

export default class TopicShowButtonsComponent extends Component {
  @service notificationsService;
  @service router;
  @service intercomEventService;
  @service intl;

  @task
  *deleteTopic() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'deleted',
      object: 'topic',
      place: 'topics',
      topic_id: this.args.topic.id,
      topic_name: this.args.topic.name,
    });

    try {
      yield this.args.topic.destroyRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'reporting.conversational-insights.topic-show-buttons.notifications.delete-topic.confirmation',
        ),
      );
      this.router.transitionTo('apps.app.reports.conversational-insights.topics');
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t(
          'reporting.conversational-insights.topic-show-buttons.notifications.delete-topic.error',
        ),
      );
    }
  }
}
