/* import __COLOCATED_TEMPLATE__ from './email-verification.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { cached } from 'tracked-toolbox';
import resendSenderEmailVerificationLink from 'embercom/lib/settings/resend-sender-email-verification-link';

export default class EmailVerification extends Component {
  @service intercomEventService;
  @service notificationsService;
  @service appService;
  @service intl;

  @tracked selectedEmail;
  @tracked showAddCustomSenderModal = false;

  get app() {
    return this.appService.app;
  }

  get hasPermissionForCustomAddresses() {
    return this.app.currentAdmin.currentAppPermissions.can_send_from_custom_addresses;
  }

  @cached
  get entities() {
    return this.args.customSenderAddresses.map((address) => ({
      text: address.email,
      value: address.id,
    }));
  }

  @action
  initialize() {
    this.selectedEmail = this.entities[0].value;
  }

  @action
  onEmailChange(emailId) {
    this.selectedEmail = emailId;
  }

  @action
  toggleDisplayCustomSenderModal() {
    this.showAddCustomSenderModal = !this.showAddCustomSenderModal;
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_domains_page',
      });
    }
  }

  @action
  resendVerificationLink() {
    resendSenderEmailVerificationLink(this.selectedEmail);
  }
}
