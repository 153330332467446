/* import __COLOCATED_TEMPLATE__ from './fin-report.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { filterableProperties } from 'embercom/lib/reporting/custom/filter-helpers';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';

export const BANNER_DISMISSED_KEY = 'fin-custom-report-launch-banner-dismissed';

// @ts-ignore
import PALETTE from '@intercom/pulse/lib/palette';

export const DEFAULT_FIN_CHART_DEFINITION = {
  id: null,
  title: null,
  visualization_type: null,
  metric_property: null,
  metric_function: null,
  date_range: {
    property: 'first_user_conversation_part_created_at',
    selection: 'past_12_weeks',
    start: null,
    end: null,
  },
  stacked: false,
  view_by: null,
  view_by_time_interval: null,
  view_by_display_limit: 10000,
  view_by_display_other: false,
  width_type: null,
  height_type: null,
};

export default class FinReport extends Component {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: any;

  filterableProperties = filterableProperties().reject((p) => p === 'ticket_type_id');

  get reportDefinition() {
    return {
      id: `fin_report`,
      title: null,
      description: null,
      charts: this.charts,
      date_range: null,
      filters: {},
    };
  }

  chartDescription(descriptionKey: string) {
    let description = this.intl.t(descriptionKey);
    return this.intl.t('reporting.fin.metric-description-tooltip', {
      metricDescription: description,
    });
  }

  get charts(): any[] {
    let finCharts: any[] = [
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_involvement',
        title: this.intl.t('reporting.fin.involvement.title'),
        tooltip: this.chartDescription(
          'reporting.fin.metrics.involvement-rate-percentage.description',
        ),
        visualization_type: VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER,
        width_type: WIDTH_TYPES.FULL,
        height_type: HEIGHT_TYPES.X_TALL,
        chart_series: [
          {
            metric_id: 'fin.participation_rate',
          },
        ],
        view_by: 'fin.participated',
        footer_text: 'reporting.fin.involvement.cta-summary',
        footer_cta_component: 'reporting/reports/fin/involvement-cta-button',
        stacked: true,
        visualization_options: {
          show_relative_values: true,
        },
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'answer_rate',
        title: this.intl.t('reporting.fin.metrics.answer-rate-percentage.name'),
        tooltip: this.chartDescription('reporting.fin.metrics.answer-rate-percentage.description'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.X_SMALL,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        chart_series: [
          {
            metric_id: 'fin.answer_rate',
          },
        ],
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'deflection_rate',
        title: this.intl.t('reporting.fin.metrics.deflection-rate-percentage.name'),
        tooltip: this.chartDescription(
          'reporting.fin.metrics.deflection-rate-percentage.description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.X_SMALL,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        chart_series: [
          {
            metric_id: 'fin.deflection_rate',
          },
        ],
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'resolution_rate',
        title: this.intl.t('reporting.fin.metrics.resolution-rate-percentage.name'),
        tooltip: this.chartDescription(
          'reporting.fin.metrics.resolution-rate-percentage.description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.X_SMALL,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        chart_series: [
          {
            metric_id: 'fin.resolution_rate',
          },
        ],
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_impact_over_time',
        title: this.intl.t('reporting.fin.impact-over-time.title'),
        visualization_type: VISUALIZATION_TYPES.LINE,
        width_type: WIDTH_TYPES.FULL,
        chart_series: [
          {
            metric_id: 'fin.answer_rate',
            color: PALETTE['vis-slateblue-40'],
          },
          {
            metric_id: 'fin.deflection_rate',
            color: PALETTE['vis-salmon-40'],
          },
          {
            metric_id: 'fin.resolution_rate',
            color: PALETTE['vis-mint-40'],
          },
        ],
        view_by: 'time',
        footer_text: 'reporting.fin.impact-over-time.best-pratices-summary',
        footer_cta_component: 'reporting/reports/fin/impact-over-time-cta-link',
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'ai_answer_resolution_rate',
        title: this.intl.t('reporting.fin.metrics.resolution-rate-percentage.ai-answer.name'),
        tooltip: this.chartDescription(
          'reporting.fin.metrics.resolution-rate-percentage.ai-answer.description',
        ),
        visualization_type: VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER,
        width_type: WIDTH_TYPES.HALF,
        height_type: HEIGHT_TYPES.X_TALL,
        chart_series: [
          {
            metric_id: 'fin.ai_answer.resolution_rate',
          },
        ],
        view_by: 'fin.resolution_state',
        stacked: true,
        visualization_options: {
          show_relative_values: true,
        },
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'custom_answer_resolution_rate',
        title: this.intl.t('reporting.fin.metrics.resolution-rate-percentage.custom-answer.name'),
        tooltip: this.chartDescription(
          'reporting.fin.metrics.resolution-rate-percentage.custom-answer.description',
        ),
        visualization_type: VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER,
        width_type: WIDTH_TYPES.HALF,
        height_type: HEIGHT_TYPES.X_TALL,
        chart_series: [
          {
            metric_id: 'fin.custom_answer.resolution_rate',
          },
        ],
        view_by: 'fin.resolution_state',
        stacked: true,
        visualization_options: {
          show_relative_values: true,
        },
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'abandoned_conversations',
        title: this.intl.t('reporting.fin.metrics.abandoned-rate-percentage.name'),
        tooltip: this.chartDescription(
          'reporting.fin.metrics.abandoned-rate-percentage.description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.X_SMALL,
        width_type: WIDTH_TYPES.HALF,
        chart_series: [
          {
            metric_id: 'fin.abandonment_rate',
          },
        ],
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'routed_to_team_conversations',
        title: this.intl.t('reporting.fin.metrics.routed-to-team-rate-percentage.name'),
        tooltip: this.chartDescription(
          'reporting.fin.metrics.routed-to-team-rate-percentage.description',
        ),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.X_SMALL,
        width_type: WIDTH_TYPES.HALF,
        chart_series: [
          {
            metric_id: 'fin.routed_to_team_rate',
          },
        ],
      },

      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_csat',
        title: this.intl.t('reporting.fin.metrics.csat.name'),
        tooltip: this.intl.t('reporting.fin.metrics.csat.description', {
          learnMoreUrl: 'https://www.intercom.com/help/en/articles/8368157-fin-csat',
        }),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        height_type: HEIGHT_TYPES.FULL,
        width_type: WIDTH_TYPES.TWO_COLUMN,
        chart_series: [
          {
            metric_id: 'conversation_rating.ai_agent.csat',
          },
        ],
        footer_text: 'reporting.fin.metrics.csat.footer-description',
      },
      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin.rated_conversations',
        title: this.intl.t('reporting.fin.metrics.rated.name'),
        tooltip: this.intl.t('reporting.fin.metrics.rated.description'),
        visualization_type: VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER,
        height_type: HEIGHT_TYPES.FULL,
        width_type: WIDTH_TYPES.FOUR_COLUMN,
        chart_series: [
          {
            metric_id: 'conversation_rating.ai_agent.count',
          },
        ],
        view_by: 'rating_index',
        stacked: true,
        visualization_options: {
          show_relative_values: true,
        },
      },

      {
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_csat_remark',
        title: this.intl.t('reporting.fin.rating-remarks.title'),
        tooltip: this.intl.t('reporting.fin.rating-remarks.tooltip'),
        visualization_type: VISUALIZATION_TYPES.BESPOKE,
        component_name: 'fin-rating-remarks-table',
        width_type: WIDTH_TYPES.FULL,
        height_type: HEIGHT_TYPES.FULL,
        chart_series: [
          {
            metric_id: 'conversation_rating.ai_agent.count',
          },
        ],
        show_default_chart_title: false,
      },
    ];
    if (this.appService.app.canUseFeature('group-ps-fin-content-usage')) {
      finCharts.push({
        ...DEFAULT_FIN_CHART_DEFINITION,
        id: 'fin_agent_content_usage_table',
        title: this.intl.t('components.reporting.bespoke.fin-content-table.header'),
        tooltip: null,
        visualization_type: VISUALIZATION_TYPES.BESPOKE,
        component_name: 'fin-ai-agent-content-table',
        view_by: 'fin.content_references',
        width_type: WIDTH_TYPES.FULL,
        height_type: HEIGHT_TYPES.FULL,
        chart_series: [
          {
            metric_id: 'fin.conversations_participated.count',
          },
          {
            metric_id: 'fin.conversations_resolved.count',
          },
        ],
      });
    }
    return finCharts;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Reports::Fin::FinReport': typeof FinReport;
    'reporting/reports/fin/fin-report': typeof FinReport;
  }
}
