/* import __COLOCATED_TEMPLATE__ from './inbox-automation-items.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';

export default class InboxAutomationItems extends Component {
  @service declare appService: any;
  @service declare store: Store;

  @tracked inboxRules: any[] = [];

  constructor(owner: unknown, args: any) {
    super(owner, args);
    if (
      !this.appService.app.canUseWorkflowsNetNewExperience &&
      this.appService.app.canUseFeature('disable-inbox-rules')
    ) {
      taskFor(this.getInboxRules).perform();
    }
  }

  get shouldShowInboxRulesLink() {
    let hasInboxRules = this.inboxRules.length > 0;

    if (this.appService.app.canUseWorkflowsNetNewExperience) {
      return false;
    }

    if (this.appService.app.canUseFeature('disable-inbox-rules')) {
      return hasInboxRules;
    }

    return true;
  }

  @task({ drop: true })
  *getInboxRules() {
    this.inboxRules = yield this.store.findAll('workflow');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::AppSettingsSubmenu::InboxAutomationItems': typeof InboxAutomationItems;
    'settings/app-settings-submenu/inbox-automation-items': typeof InboxAutomationItems;
  }
}
