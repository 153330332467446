/* import __COLOCATED_TEMPLATE__ from './engagement-table-improved.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import Formatters, { units } from 'embercom/lib/reporting/flexible/formatters';
import percent, { toPercentString } from 'embercom/lib/percentage-formatter';
import { inject as service } from '@ember/service';
import {
  articleViewsDataConfig,
  conversationsCreatedFromArticlesDataConfig,
  negativeReactionsDataConfig,
  positiveReactionsDataConfig,
  neutralReactionsDataConfig,
} from 'embercom/lib/reporting/flexible/articles';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import PredicateGroup from 'predicates/models/predicate-group';
import { modifier } from 'ember-modifier';
import { next } from '@ember/runloop';

class CachedArticleStore {
  @tracked articles = {};

  constructor(store) {
    this.store = store;
  }

  get(id) {
    if (this.articles[id]) {
      return this.articles[id];
    }
    this.articles[id] = this.store.findRecord('article-multilingual', id);
    return this.articles[id];
  }
}

export default class ArticlesEngagementTableImproved extends Component {
  @service appService;
  @service store;
  @service helpCenterService;
  @service intl;
  @service intercomEventService;
  TABLE_NAME = 'articles-engagement-table-improved';
  viewCountAttribute = 'articles.reporting.view.count';
  positiveReactionsCountAttribute = 'articles.reporting.view.positive_reactions';
  negativeReactionsCountAttribute = 'articles.reporting.view.negative_reactions';
  neutralReactionsCountAttribute = 'articles.reporting.view.neutral_reactions';
  conversationsCountAttribute = 'articles.reporting.view.conversations';
  attributeToColumn = {
    [this.viewCountAttribute]: 'col-views',
    [this.positiveReactionsCountAttribute]: 'col-positive-reactions',
    [this.negativeReactionsCountAttribute]: 'col-negative-reactions',
    [this.neutralReactionsCountAttribute]: 'col-neutral-reactions',
    [this.conversationsCountAttribute]: 'col-conversations',
  };
  @tracked predicates = [];
  @tracked selectedMode = this.dropdownItems[0].value;
  @tracked selectedArticle;
  @tracked showEngagementOverlay = false;

  dropdownItems = [
    { text: this.intl.t('reporting.articles.engagement.total-number'), value: 'number' },
    { text: this.intl.t('reporting.articles.engagement.percentage'), value: 'percent' },
  ];

  constructor() {
    super(...arguments);
    this.timeFormatter = new Formatters[units.seconds]();
    this.articleCollection = new CachedArticleStore(this.store);
    this.helpCenterService.maybeFetchSite();
    this.initPredicates();
  }

  @action
  onFilterChange(filter, { action }) {
    this.predicates = filter;
    if (this.predicates.content.length > 0) {
      let nbPredicates = this.predicates.content.length;
      let { attribute: filter_identifier } = this.predicates.content[nbPredicates - 1];
      this.intercomEventService.trackAnalyticsEvent({
        action,
        object: 'filter',
        place: 'article_engagement_table',
        section: 'reports',
        filter_identifier,
        table_name: this.tableName,
        mode: this.selectedMode,
      });
    }
  }

  initPredicates() {
    this.predicates = PredicateGroup.createPredicatesArray(this.store, [
      {
        attribute: this.viewCountAttribute,
        comparison: 'gt',
        type: 'integer',
        value: 10,
      },
    ]);
  }

  articleTitle(article) {
    return this.relevantArticleContent(article).title;
  }

  relevantArticleContent(article) {
    return article.defaultLocalizedContent || article.articleContents.firstObject;
  }

  get columns() {
    let result = [
      {
        label: this.intl.t('reporting.articles.engagement.article'),
        isMain: true,
        valuePath: 'groupName',
        component: 'reporting/flexible/table-engagement-report-title-cell',
        componentData: {
          getCellValue: (article) => this.articleTitle(article),
          articleCollection: this.articleCollection,
          toggleEngagementDrawer: (article) => this.toggleEngagementDrawer(article),
        },
        isSortable: false,
      },
      {
        label: this.intl.t('reporting.articles.engagement.viewers'),
        valuePath: 'col-views',
        type: 'number',
        isSortable: true,
        tooltip: this.intl.t('reporting.articles.engagement.viewers-tooltip'),
      },
    ];
    if (this.selectedMode === this.dropdownItems[0].value) {
      //total number
      result.push(
        ...[
          {
            label: this.intl.t('reporting.articles.engagement.negative-reactions'),
            valuePath: 'col-negative-reactions',
            type: 'number',
            isSortable: true,
            tooltip: this.intl.t('reporting.articles.engagement.negative-reactions-tooltip-number'),
          },
          {
            label: this.intl.t('reporting.articles.engagement.neutral-reactions'),
            valuePath: 'col-neutral-reactions',
            type: 'number',
            isSortable: true,
            tooltip: this.intl.t('reporting.articles.engagement.neutral-reactions-tooltip-number'),
          },
          {
            label: this.intl.t('reporting.articles.engagement.positive-reactions'),
            valuePath: 'col-positive-reactions',
            type: 'number',
            isSortable: true,
            tooltip: this.intl.t('reporting.articles.engagement.positive-reactions-tooltip-number'),
          },
          {
            label: this.intl.t('reporting.articles.engagement.conversations'),
            valuePath: 'col-conversations',
            type: 'number',
            isSortable: true,
            tooltip: this.intl.t('reporting.articles.engagement.conversations-tooltip-number'),
          },
        ],
      );
    } else if (this.selectedMode === this.dropdownItems[1].value) {
      //percentage
      result.push(
        ...[
          {
            label: this.intl.t('reporting.articles.engagement.negative-reactions'),
            valuePath: 'col-negative-reactions',
            type: 'number',
            isSortable: true,
            tooltip: this.intl.t(
              'reporting.articles.engagement.negative-reactions-tooltip-percent',
            ),
            sortingHelper: (row) =>
              this.getPercentageForSorting(row['col-negative-reactions'], row['col-views']),
            getRowValue: (row) =>
              this.formatPercentage(row['col-negative-reactions'], row['col-views']),
          },
          {
            label: this.intl.t('reporting.articles.engagement.neutral-reactions'),
            valuePath: 'col-neutral-reactions',
            type: 'number',
            isSortable: true,
            tooltip: this.intl.t('reporting.articles.engagement.neutral-reactions-tooltip-percent'),
            sortingHelper: (row) =>
              this.getPercentageForSorting(row['col-neutral-reactions'], row['col-views']),
            getRowValue: (row) =>
              this.formatPercentage(row['col-neutral-reactions'], row['col-views']),
          },
          {
            label: this.intl.t('reporting.articles.engagement.positive-reactions'),
            valuePath: 'col-positive-reactions',
            type: 'number',
            isSortable: true,
            tooltip: this.intl.t(
              'reporting.articles.engagement.positive-reactions-tooltip-percent',
            ),
            sortingHelper: (row) =>
              this.getPercentageForSorting(row['col-positive-reactions'], row['col-views']),
            getRowValue: (row) =>
              this.formatPercentage(row['col-positive-reactions'], row['col-views']),
          },
          {
            label: this.intl.t('reporting.articles.engagement.conversations'),
            valuePath: 'col-conversations',
            type: 'number',
            isSortable: true,
            tooltip: this.intl.t('reporting.articles.engagement.conversations-tooltip-percent'),
            sortingHelper: (row) =>
              this.getPercentageForSorting(row['col-conversations'], row['col-views']),
            getRowValue: (row) => this.formatPercentage(row['col-conversations'], row['col-views']),
          },
        ],
      );
    }

    result.push({
      label: this.intl.t('reporting.articles.engagement.updated'),
      isMain: true,
      valuePath: 'updatedAt',
      type: 'number',
      component: 'reporting/flexible/table-engagement-report-last-updated-cell',
      componentData: {
        getCellValue: (article) => this.relevantArticleContent(article).updatedAt,
        articleCollection: this.articleCollection,
      },
      isSortable: false,
    });
    return result;
  }

  get dataConfig() {
    return {
      columns: [
        {
          name: 'col-views',
          aggregation: {
            type: 'cardinality',
            data: {
              property: 'user.id',
            },
          },
          ...articleViewsDataConfig(),
        },
        {
          name: 'col-conversations',
          aggregation: {
            type: 'cardinality',
            data: {
              property: 'user.id',
            },
          },
          ...conversationsCreatedFromArticlesDataConfig(),
        },
        {
          name: 'col-negative-reactions',
          aggregation: {
            type: 'cardinality',
            data: {
              property: 'user.id',
            },
          },
          ...negativeReactionsDataConfig(),
        },
        {
          name: 'col-positive-reactions',
          aggregation: {
            type: 'cardinality',
            data: {
              property: 'user.id',
            },
          },
          ...positiveReactionsDataConfig(),
        },
        {
          name: 'col-neutral-reactions',
          aggregation: {
            type: 'cardinality',
            data: {
              property: 'user.id',
            },
          },
          ...neutralReactionsDataConfig(),
        },
      ],
      rows: [
        {
          name: 'row-teammates',
          type: 'term',
          data: {
            term_size: 10000,
            property: 'article.id',
          },
        },
      ],
    };
  }

  get viewConfig() {
    return {
      rowsVisible: 10,
      sortState: { valuePath: 'col-views', direction: 'desc' },
      isVisibleRowAfterLoading: (row) => {
        let validPredicates = this.predicates.filter((predicate) => predicate.isValid);
        return validPredicates.every((predicate) => {
          let { attribute, value: predicateValue } = predicate;
          let column = this.attributeToColumn[attribute];
          let value = row[column] || 0;
          if (
            this.selectedMode === this.dropdownItems[1].value &&
            (attribute === this.negativeReactionsCountAttribute ||
              attribute === this.neutralReactionsCountAttribute ||
              attribute === this.positiveReactionsCountAttribute ||
              attribute === this.conversationsCountAttribute)
          ) {
            value = percent(row['col-views'], value);
          }
          predicateValue = parseInt(predicateValue, 10);
          if (predicate.comparison === 'eq') {
            return value === predicateValue;
          }
          if (predicate.comparison === 'lt') {
            return value < predicateValue;
          }
          if (predicate.comparison === 'gt') {
            return value > predicateValue;
          }
          return false;
        });
      },
    };
  }

  formatPercentage(value, total) {
    if (!total) {
      return '-';
    }

    let rawPercentage = percent(total, value || 0);
    let percentageValue = toPercentString(rawPercentage, 2);
    return percentageValue;
  }

  getPercentageForSorting(value, total) {
    if (!total) {
      return -1;
    }

    return percent(total, value || 0);
  }

  @action
  toggleEngagementDrawer(article = null) {
    if (article !== null) {
      this.showEngagementOverlay = true;
      this.selectedArticle = article;
    } else {
      this.showEngagementOverlay = false;
      this.selectedArticle = null;
    }
  }

  @action
  setSelectedMode(selectedValue) {
    if (this.selectedMode !== selectedValue) {
      this.selectedMode = selectedValue;
      this.initPredicates();

      this.intercomEventService.trackAnalyticsEvent({
        action: 'selected',
        object: 'switch_mode_dropdown',
        place: 'article_engagement_table',
        section: 'reports',
        selectedMode: selectedValue,
      });
    }
  }

  get attributes() {
    let attributes = new Set(this.predicates.map((p) => p.attribute));
    let availableAttributes = [];
    if (!attributes.has(this.viewCountAttribute)) {
      availableAttributes.push({
        name: this.intl.t('reporting.articles.engagement.viewers'),
        identifier: this.viewCountAttribute,
        type: 'integer',
        icon: 'visible',
      });
    }
    if (!attributes.has(this.negativeReactionsCountAttribute)) {
      availableAttributes.push({
        name: this.intl.t('reporting.articles.engagement.filters.negative-reactions.name'),
        identifier: this.negativeReactionsCountAttribute,
        type: 'integer',
        icon: 'lwr-sad',
      });
    }
    if (!attributes.has(this.neutralReactionsCountAttribute)) {
      availableAttributes.push({
        name: this.intl.t('reporting.articles.engagement.filters.neutral-reactions.name'),
        identifier: this.neutralReactionsCountAttribute,
        type: 'integer',
        icon: 'lwr-neutral',
      });
    }
    if (!attributes.has(this.positiveReactionsCountAttribute)) {
      availableAttributes.push({
        name: this.intl.t('reporting.articles.engagement.filters.positive-reactions.name'),
        identifier: this.positiveReactionsCountAttribute,
        type: 'integer',
        icon: 'lwr-happy',
      });
    }
    if (!attributes.has(this.conversationsCountAttribute)) {
      availableAttributes.push({
        name: this.intl.t('reporting.articles.engagement.filters.conversations.name'),
        identifier: this.conversationsCountAttribute,
        type: 'integer',
        icon: 'conversation',
      });
    }
    return availableAttributes;
  }

  get attributeGroupList() {
    return [
      {
        heading: this.intl.t('reporting.articles.engagement.filter-group-reports'),
        attributes: this.attributes,
      },
    ];
  }

  onSelection(contentId) {
    next(this, () => {
      let numericInputSelector = `[data-popover-content="${contentId}"] input[type="number"]`;
      let radioInputSelector = `[data-popover-content="${contentId}"] input[type="radio"]`;
      let options = document.querySelectorAll(radioInputSelector);
      options.forEach((option) => {
        option.addEventListener('click', () => {
          next(this, () => {
            let selectedInput = document.querySelector(numericInputSelector);
            selectedInput.placeholder = '%';
          });
        });
      });
      // by default one radio is selected and this input is displayed
      let input = document.querySelector(numericInputSelector);
      input.placeholder = '%';
    });
  }
  // it is not currently possible to setup the placeholder in the predicate numeric editor. Therefore this is a hack
  // to manually insert the placeholder
  attachListener = modifier((element, [predicate]) => {
    let { attribute } = predicate;
    if (
      this.selectedMode === this.dropdownItems[1].value &&
      (attribute === this.negativeReactionsCountAttribute ||
        attribute === this.neutralReactionsCountAttribute ||
        attribute === this.positiveReactionsCountAttribute ||
        attribute === this.conversationsCountAttribute)
    ) {
      let filter = element.querySelector('[data-popover-opener]');
      let contentId = filter.attributes.getNamedItem('data-popover-opener').value;
      filter.addEventListener('click', () => this.onSelection(contentId));
      this.onSelection(contentId);
    }
  });

  get tableName() {
    return `${this.TABLE_NAME}-${this.selectedMode}`;
  }
}
