/* import __COLOCATED_TEMPLATE__ from './signal-card.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';

export const MAX_CHART_LINES = 10;

export default Component.extend({
  reportingService: service(),
  zeroColor: '#f7fafc',
  summary: false,
  lineChartEmptyStateComponentPath: 'reporting/empty-state/tags',
  hiddenLineChartItemType: 'tag',
  hoveredLineChartIndex: undefined,
  range: null,

  isBarChart: equal('chartType', 'bar'),
  isHeatmapChart: equal('chartType', 'heatmap'),
  isLineChart: equal('chartType', 'line'),
  isStackedBarChart: equal('chartType', 'stacked-bar'),
  isHorizontalBarChart: equal('chartType', 'horizontal-bar'),

  width: ternary('summary', 400, 900),
  height: 250,
  showComparison: false,
  emptyStateActive: false,
  canHaveChildEmptyState: true,

  _uniqueConcat(firstArray, secondArray) {
    return [...new Set(firstArray.concat(secondArray))];
  },

  fetchComparisonTagSignal: task(function* (displayConversationTagIds) {
    let filters = Object.assign({}, this.filters);
    if (displayConversationTagIds.length === 0) {
      filters['conversation_tag_ids'] = [0];
    } else {
      filters['conversation_tag_ids'] = displayConversationTagIds
        .slice(0, MAX_CHART_LINES)
        .map((id) => parseInt(id, 10));
    }

    if (this.get('scopingTag.id')) {
      filters['conversation_tag_ids'] = this._uniqueConcat(
        filters['conversation_tag_ids'],
        parseInt(this.get('scopingTag.id'), 10),
      );
    }

    let signal = yield this.reportingService.fetchComparisonTagSignal(
      this.signalId,
      'conversation',
      this.range,
      filters,
    );
    this.set('signal', signal);
  }).restartable(),

  actions: {
    refetchSignalFromSignalId(displayConversationTagIds) {
      this.fetchComparisonTagSignal.perform(displayConversationTagIds);
    },
    setHoveredItem(itemIndex) {
      this.set('hoveredLineChartIndex', itemIndex);
    },
  },
});
