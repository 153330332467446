/* import __COLOCATED_TEMPLATE__ from './topic-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { task } from 'ember-concurrency-decorators';
import { sortBy } from 'underscore';

export default class TopicFilter extends Component {
  @service appService;
  @service intercomEventService;
  @service store;
  @service realTimeEventService;
  @service router;

  @tracked topics = [];
  @tracked label = this.currentLabel;
  @tracked isPropagating = this.hasPropagatingTopic;
  @tracked showPropagationFinishedBanner = false;

  // store the selection here so that the UI doesn't update until the drop down is closed
  selectedDropdownItems = this.args.selected;

  constructor() {
    super(...arguments);
    this.loadTopics.perform();
    this.realTimeEventService.subscribeTopics(['ConversationTopicPropagatedStatusChanged']);
    this.realTimeEventService.on('ConversationTopicPropagatedStatusChanged', this, (event) => {
      this.handleTopicPropagationStatusChange(event.topicId);
    });
  }

  handleTopicPropagationStatusChange(topicId) {
    let wasPropagating = this.hasPropagatingTopic;
    this.topics.forEach(async (topic) => {
      if (topic.id === topicId) {
        await topic.reload();
        if (wasPropagating === true && !this.hasPropagatingTopic) {
          this.showPropagationFinishedBanner = true;
        }
      }
    });
  }

  @action
  reloadPage() {
    window.location.reload();
  }

  get items() {
    return [
      {
        heading: 'Topic',
        items: this.allItems,
      },
    ];
  }

  @task
  *loadTopics() {
    let topics = yield this.store.findAll('conversational-insights/conversation-topic');
    this.topics = sortBy(topics.toArray(), (topic) => topic.name.toLowerCase());
  }

  get selectedTopics() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get hasPropagatingTopic() {
    return this.selectedTopics.some((selectedTopic) => {
      let topic = this.allItems.find((item) => item.value === selectedTopic);
      return topic.isStillPropagatingForTimeRange;
    });
  }

  get allItems() {
    return this.topics.map((topic) => ({
      text: topic.name,
      value: topic.id,
      // Check for range value in-case it is not passed
      isStillPropagatingForTimeRange: this.args.range?.comparisonStartMoment.isBefore(
        topic.fullyPropagatedFrom,
      ),
      isSelected: this.args.selected.includes(topic.id),
      component: 'reporting/flexible/filter-bar/topic-filter-item',
    }));
  }

  get currentLabel() {
    if (this.selectedTopics.length === 0) {
      return 'Any';
    }

    let topicNames = this.selectedTopics.map((topicId) => {
      return this.topics.find((topic) => topic.id === topicId).name;
    });
    return topicNames.join(', ');
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered',
      object: this.args.analyticsObject,
      place: 'reports',
      topic_ids: this.selectedDropdownItems,
      filter_name: 'topic',
    });
    this.intercomEventService.trackEvent('filtered-using-conversation-topic');

    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.currentLabel;
    this.isPropagating = this.hasPropagatingTopic;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.realTimeEventService.off('ConversationTopicPropagatedStatusChanged', this);
    this.realTimeEventService.unsubscribeTopics(['ConversationTopicPropagatedStatusChanged']);
    this.args.removeFilter();
  }
}
