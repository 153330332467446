/* import __COLOCATED_TEMPLATE__ from './mappings-table.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type { SourceAttributeType } from 'embercom/services/data/import-configuration-service';
import type AttributeMapping from 'embercom/models/crm/attribute-mapping';
import type { MappingVisibility } from 'embercom/models/crm/attribute-mapping';
import type IntlService from 'embercom/services/intl';
import type { default as CrmAttribute } from 'embercom/models/crm/attribute';

export interface Args {
  mappingVisibility?: MappingVisibility;
  integrationCode: string;
  sectionName: string;
  section: any;
  attributeType?: SourceAttributeType;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class MappingsTable extends Component<Signature> {
  @service declare intl: IntlService;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  get mappingVisibility() {
    return this.args.mappingVisibility || 'public';
  }

  get sectionData() {
    if (this.args.attributeType !== undefined) {
      return this.importConfigurationService.listMappings(
        this.args.sectionName,
        this.mappingVisibility,
        this.args.attributeType,
      );
    }
    return this.importConfigurationService.listMappings(
      this.args.sectionName,
      this.mappingVisibility,
    );
  }

  sourceAttributeLabel = (attribute: CrmAttribute | undefined, fallback: string) => {
    if (attribute === undefined) {
      return fallback;
    }
    return attribute.label || attribute.path;
  };

  sourceAttribute = (mapping: AttributeMapping) => {
    return this.importConfigurationService.matchingSourceAttribute(mapping);
  };

  destinationAttributeLabel = (mapping: AttributeMapping) => {
    let attribute = this.importConfigurationService.matchingDestinationAttribute(mapping);
    if (attribute === undefined) {
      return mapping.destinationAttributePath;
    }
    return attribute.name || attribute.identifier;
  };

  readOnlyMapping(mapping: AttributeMapping) {
    return mapping.status === 'non-mappable' || !mapping.editable;
  }

  isTicketStatusMapping(mapping: AttributeMapping) {
    return mapping.sourceObjectType === 'ticket' && mapping.sourceAttributePath === 'status';
  }

  translationKey = (path: string) => {
    return `settings.data-import.${this.args.integrationCode}.config-modal.accordion.page.${path}`;
  };

  translation = (path: string) => {
    return this.intl.t(this.translationKey(path));
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::MappingsTable': typeof MappingsTable;
    'settings/data-import/modal/mappings-table': typeof MappingsTable;
  }
}
