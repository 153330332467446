/* import __COLOCATED_TEMPLATE__ from './delete-app-modal.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import { computed, action } from '@ember/object';
import { alias, not, readOnly } from '@ember/object/computed';
import Evented from '@ember/object/evented';
import { inject as service } from '@ember/service';
import { equalToProperty, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';

export default Component.extend(Evented, {
  tagName: '',
  intercomEventService: service(),
  notificationsService: service(),
  windowService: service('browser/window'),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  admin: alias('app.currentAdmin'),
  title: computed('app.name', function () {
    return `Delete ${this.app.name} workspace`;
  }),
  confirmButtonText: 'Delete workspace',
  cancelButtonText: 'Cancel',
  center: false,
  invertButtonTypes: false,
  primaryButtonType: 'primary-destructive',
  secondaryButtonType: 'secondary',
  cancelButtonType: ternaryToProperty(
    'invertButtonTypes',
    'primaryButtonType',
    'secondaryButtonType',
  ),
  confirmButtonType: ternaryToProperty(
    'invertButtonTypes',
    'secondaryButtonType',
    'primaryButtonType',
  ),
  confirmationText: '',
  hasEnteredValidCheckText: equalToProperty('confirmationText', 'app.name'),
  cannotCreateToken: not('hasEnteredValidCheckText'),
  validationClass: computed('confirmationText', function () {
    return `f__text test__confirmation__text-input ${this.cannotCreateToken ? ' o__error' : ''}`;
  }),
  goToURL(url) {
    this.windowService.location.href = url;
  },
  closeModal: action(function () {
    this.modalService.closeModal();
  }),
  actions: {
    confirm() {
      let appModel = this.app;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'deleted',
        object: 'app',
        deleted_app_id: appModel.id,
        context: 'from_settings_modal',
      });

      let apps = this.get('admin.apps');
      let appInAdminRecord = apps.findBy('id', appModel.get('id'));
      appModel.destroyRecord().then(
        () => {
          apps.removeObject(appInAdminRecord);
          let url = '/apps/new';
          if (apps.length > 0) {
            url = `/apps/${apps.get('firstObject.id')}`;
          }
          this.goToURL(url);
        },
        (e) => {
          let response = e.jqXHR;
          switch (response.status) {
            case 422:
              this.notificationsService.notifyError(response.responseText);
              break;
            default:
              this.notificationsService.notifyError('You cannot delete this workspace');
          }
        },
      );
      this.closeModal();
    },
    cancel() {
      this.closeModal();
    },
  },
});
