/* import __COLOCATED_TEMPLATE__ from './auto-reply.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class AutoReply extends Component {
  @service store;
  @service appService;
  @service notificationsService;
  @service intl;

  @task({ enqueue: true })
  *updateSetting() {
    try {
      yield this.args.settings.updateSetting();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (error) {
      this.args.settings.toggleProperty('autoReplyEnabled');
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  toggleAutoReply() {
    this.args.settings.toggleProperty('autoReplyEnabled');
    this.updateSetting.perform();
  }
}
