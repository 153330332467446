/* import __COLOCATED_TEMPLATE__ from './email-stat-comparison.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { objectNames, objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class EmailStatComparison extends Component {
  get query() {
    return {
      name: 'default_query',
      debug_name: 'outbound_emails_opened',
      document_type: 'events',
      range_field: 'stat_receipt.created_at',
      aggregation_type: 'value_count',
      aggregation_field: 'event.type',
    };
  }

  get filters() {
    return {
      ...this.defaultFilters,
      ...this.failureFilters,
    };
  }

  get defaultFilters() {
    return {
      'event.type': `stats_${this.args.statistic}`,
      'stat_receipt.content_type': objectNames[objectTypes.email],
    };
  }

  get failureFilters() {
    return {
      'failure_category.id': this.args.failureType,
    };
  }
}
