/* import __COLOCATED_TEMPLATE__ from './standard-stats-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { makeStatsParametersForSeries } from 'embercom/helpers/stats-system/stats-parameters';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class StandardStatsList extends Component {
  @service seriesEditorService;
  @service appService;

  @tracked statsParameters;

  constructor() {
    super(...arguments);

    this.statsParameters = makeStatsParametersForSeries(
      this.series,
      this.range,
      this.appService.app.timezone,
    );
  }

  @action
  changeFilter(_, filter) {
    this.seriesEditorService.changeActiveFilter(filter);
  }

  get series() {
    return this.seriesEditorService.series;
  }

  get range() {
    return this.seriesEditorService.range;
  }

  @action
  updateDateRange(range) {
    this.seriesEditorService.updateRange(range);
    this.statsParameters = makeStatsParametersForSeries(
      this.series,
      this.range,
      this.appService.app.timezone,
    );
  }
}
