/* import __COLOCATED_TEMPLATE__ from './proactive-support-usage.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { buildProactiveMessagesQuery } from 'embercom/lib/reporting/csf-queries';
import { buildDataConfigWithComparisons } from '../../../lib/reporting/comparison-data-config';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';

const PROACTIVE_TOTAL = 'proactive-support-message-stats-receipts';
const PROACTIVE_CLICKED_REACTED = 'proactive-support-message-stats-clicks-and-reactions';
const PROACTIVE_OPENED = 'proactive-support-message-stats-opens';

const MAXIMUM_PROACTIVE_CONTENT_IDS = 10000;

export default class ProactiveSupportUsage extends Component {
  @service store;
  @service notificationsService;
  @service appService;
  @service outboundHomeService;
  @service intercomEventService;
  @service intl;

  @tracked showModal = false;
  @tracked filterHash = this._initializeFilterHash();

  get viewConfig() {
    return {
      formatUnit: { unit: units.value, commas: true },
      aggregations: { defaultOption: 'count' },
      counter: {
        [PROACTIVE_TOTAL]: {
          improvementDirection: improvementDirections.INCREASING,
        },
        [PROACTIVE_CLICKED_REACTED]: {
          improvementDirection: improvementDirections.INCREASING,
        },
        [PROACTIVE_OPENED]: {
          improvementDirection: improvementDirections.INCREASING,
        },
      },
    };
  }

  get receiptDataConfig() {
    return buildDataConfigWithComparisons(
      this._defaultDataConfig(PROACTIVE_TOTAL, ['stats_receipt']),
      this.args.range,
    );
  }

  get interactedDataConfig() {
    return buildDataConfigWithComparisons(
      this._defaultDataConfig(PROACTIVE_CLICKED_REACTED, ['stats_click', 'stats_reaction']),
      this.args.range,
    );
  }

  get openDataConfig() {
    return buildDataConfigWithComparisons(
      this._defaultDataConfig(PROACTIVE_OPENED, ['stats_open']),
      this.args.range,
    );
  }

  get filterCountText() {
    let filterCount = Object.keys(this.filterHash || {}).length;
    if (filterCount === 0) {
      return this.intl.t(
        'components.reporting.conversational-support-funnel.proactive-support-usage.return-on-all',
      );
    }

    return this.intl.t(
      'components.reporting.conversational-support-funnel.proactive-support-usage.return-on-count',
      { count: filterCount },
    );
  }

  _initializeFilterHash() {
    if (this.args.proactiveSupportMessageFilters.filterHash) {
      return JSON.parse(this.args.proactiveSupportMessageFilters.filterHash);
    }

    return {};
  }

  @action
  openModal() {
    this.showModal = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'button',
      context: 'manage_proactive_messages',
    });
  }

  @action
  closeModal() {
    this.filterHash = this._initializeFilterHash();
    this.showModal = false;
  }

  @action
  resetFilterHash() {
    this.filterHash = {};
  }

  @action
  updateFilterHash(key, value) {
    let filterHash = this.filterHash;
    if (value.length === 0) {
      delete filterHash[key];
    } else {
      filterHash[key] = value;
    }
    this.filterHash = filterHash;
  }

  @action
  async saveSettings() {
    let proactiveSupportMessageFilters = this.args.proactiveSupportMessageFilters;
    let filtersAreNew = proactiveSupportMessageFilters.isNew;
    let filtersAreIdentical =
      !filtersAreNew &&
      _.isEqual(JSON.parse(proactiveSupportMessageFilters.filterHash), this.filterHash);

    try {
      proactiveSupportMessageFilters.filterHash = JSON.stringify(this.filterHash);
      await proactiveSupportMessageFilters.save();

      if (!filtersAreNew && !filtersAreIdentical) {
        this.args.onChangeFilters.perform();
      }

      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'components.reporting.conversational-support-funnel.proactive-support-usage.filter-saved',
        ),
      );
      this.showModal = false;
    } catch {
      this.notificationsService.notifyError(
        this.intl.t(
          'components.reporting.conversational-support-funnel.proactive-support-usage.filters-not-saved',
        ),
      );
    }
  }

  _defaultDataConfig(name, stats) {
    let proactiveMessagesQuery = buildProactiveMessagesQuery(
      name,
      stats,
      this.args.contentIds,
      this.args.range,
    );
    return {
      series: [proactiveMessagesQuery],
    };
  }

  get contentIdsHaveBeenLimited() {
    return this.args.contentIds.length >= MAXIMUM_PROACTIVE_CONTENT_IDS;
  }
}
