/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  tagName: 'div',
  classNames: ['reporting__empty-state__table'],

  rowCount: 5,
  isLoading: false,

  ghostColumns: computed('columns', function () {
    return this.columns.map((col) =>
      Object.assign({}, col, {
        valueComponent: 'reporting/empty-state/ghost-field',
        component: null,
      }),
    );
  }),

  row: computed('columns', function () {
    let row = this.columns.reduce(
      (acc, current) =>
        Object.assign(acc, {
          [current.propertyName]: current.label.length * 9,
        }),
      {},
    );
    return row;
  }),

  rows: computed('rowCount', function () {
    return Array(this.rowCount).fill(this.row);
  }),
});
