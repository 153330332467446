/* import __COLOCATED_TEMPLATE__ from './fetch-report.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { isArray } from '@ember/array';
import DidChangeAttrs from 'ember-did-change-attrs';
import { buildNestedFieldFilters } from 'embercom/lib/reporting/nested-field-filters';

let filtersChanged = ({ current, previous }) => {
  let filtersUnchanged = Object.keys(current)
    .filter((key) => key !== 'list_filters' || key !== 'custom_fields')
    .every((key) => previous[key] === current[key]);

  let customAttributesFiltersUnchanged =
    Object.keys(current.custom_attributes || {}).length ===
      Object.keys(previous.custom_attributes || {}).length &&
    JSON.stringify(current.custom_attributes || {}) ===
      JSON.stringify(previous.custom_attributes || {});

  let listFiltersUnchanged =
    current.list_filters &&
    current.list_filters.length === previous.list_filters.length &&
    JSON.stringify(current.list_filters) === JSON.stringify(previous.list_filters);

  return !filtersUnchanged || !listFiltersUnchanged || !customAttributesFiltersUnchanged;
};

let groupingChanged = ({ current, previous }) => {
  return !(
    current &&
    current.every((grouping) => previous.includes(grouping)) &&
    current.length === previous.length
  );
};

export default Component.extend(DidChangeAttrs, {
  tagName: '',
  didChangeAttrsConfig: {
    attrs: ['query', 'unit', 'range', 'filters', 'groupings', 'name'],
  },
  reportingService: service(),
  signalType: 'comparison',
  groupOverTime: true,
  groupings: [],

  report: computed('updateChart.{isRunning,lastSuccessful.value}', function () {
    return this.updateChart.isRunning ? undefined : this.updateChart.lastSuccessful?.value;
  }),

  updateChart: task(function* () {
    if (!this.range || !this.query) {
      return null;
    }

    let queryOptions = {
      nested_keys_are_dates: false,
      ...this.query,
      keys_are_dates: this.groupOverTime,
      debug_name: this.debugName,
    };

    let { custom_attributes, ...normalFilters } = this.filters || {};

    let filters = {
      ...this.query.filters,
      ...normalFilters,
    };

    let nestedFilters = buildNestedFieldFilters(custom_attributes);

    if (nestedFilters) {
      filters.nested_filters = nestedFilters;
    }

    let groupings = this.groupOverTime
      ? [...this.range.baseAggregations, ...this.groupings]
      : this.groupings;

    let signal;
    if (this.signalType === 'conversation-rating') {
      signal = yield this.reportingService.fetchConversationRatingSignal(
        queryOptions,
        this.unit,
        this.range,
        filters,
        groupings,
        this.name,
      );
    } else if (this.signalType === 'comparison') {
      signal = yield this.reportingService.fetchComparisonSignal(
        queryOptions,
        this.unit,
        this.range,
        filters,
        groupings,
        this.name,
      );
    } else if (this.signalType === 'tabular') {
      signal = yield this.reportingService.fetchTabularSignal(queryOptions, this.range, filters);
    } else if (this.signalType === 'heatmap') {
      signal = yield this.reportingService.fetchHeatmapSignal(
        queryOptions,
        this.unit,
        this.range,
        filters,
      );
    } else {
      // basic
      signal = yield this.reportingService.fetchSignal(
        queryOptions,
        this.unit,
        this.range,
        filters,
        groupings,
        this.name,
      );
    }

    return isArray(this.transforms) ? signal.transform(...this.transforms) : signal;
  }).restartable(),

  didChangeAttrs({ range, query, unit, filters, groupings } = {}) {
    this._super(...arguments);

    if (
      range ||
      query ||
      unit ||
      (filters && filtersChanged(filters)) ||
      (groupings && groupingChanged(groupings))
    ) {
      this.updateChart.perform();
    }
  },

  didInsertElement() {
    this._super(...arguments);
    this.updateChart.perform();
  },
});
