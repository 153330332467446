/* import __COLOCATED_TEMPLATE__ from './filter-bar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class FilterBarComponent extends Component {
  @service appService;

  get filterHash() {
    let filterHash = {};
    let filterKeys = Object.keys(Object.getPrototypeOf(this.args.filterSet));

    filterKeys.forEach((key) => {
      filterHash[key] = this.args.filterSet[key];
    });

    if (!this.appService.app.hasConversationalInsightsBillingFeature) {
      delete filterHash.topics;
    }
    return filterHash;
  }
}
