/* import __COLOCATED_TEMPLATE__ from './standard-connector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import SeriesEdgeGenerator from 'embercom/components/series/edge/generator';
import { schedule } from '@ember/runloop';
import TruncateString from 'embercom/lib/truncate-string';
import { tracked } from '@glimmer/tracking';
import ENV from 'embercom/config/environment';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { seriesEdgeTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class StandardConnector extends Component {
  @service seriesEditorService;

  @tracked connectionPoint = undefined;
  @tracked hasCloseNextBlock = false;

  get labelText() {
    if (this.hasCloseNextBlock) {
      return TruncateString(this.args.connector.label, 6);
    } else {
      return this.args.connector.label;
    }
  }

  get shouldRenderSplitConnector() {
    return this.args.connector.edgeType === seriesEdgeTypes.split && this.args.index >= 1;
  }

  @action calculateBlockDistance() {
    this.debouncedCalculateBlockDistance.perform();
  }

  @task({ restartable: true })
  *debouncedCalculateBlockDistance() {
    yield timeout(ENV.APP._250MS);
    let hasCloseBlock = this.seriesEditorService.graph.nodes.some((node) => {
      if (this.hasCloseNextBlock) {
        // If we are already tuncated, would untruncating cover a block?
        return node.bounds.coversCoordinates({
          x: this.connectionPoint.coordinates.x + 100,
          y: this.connectionPoint.coordinates.y,
        });
      } else {
        return node.bounds.coversCoordinates(this.connectionPoint.coordinates);
      }
    });
    if (this.hasCloseNextBlock !== hasCloseBlock) {
      this.hasCloseNextBlock = hasCloseBlock;
    }
  }

  @action canStartDragging() {
    return this.args.connector.canConnect;
  }

  @action onEdgeDragStart(connector, edge) {
    this.seriesEditorService.edgeTypeForNewEdge = connector.edgeType;
    edge.dataObject = { edgeType: connector.edgeType, edgeSplit: connector.edgeSplit };
    edge.generator = new SeriesEdgeGenerator(edge);
  }

  @action addNewConnectionPoint({ edgeType, edgeSplit }, element) {
    schedule('afterRender', this, () => {
      let [x, y] = this._offsetsFromNodeTopRight(element);
      let connectionPoint = {
        x,
        y,
        alignment: 'right',
        direction: 'outwards',
        dataObject: { edgeTypes: [edgeType], edgeSplit },
      };
      this.connectionPoint = this.args.node.addConnectionPoint(connectionPoint);
      this.calculateBlockDistance();
    });
  }

  @action updateConnectionPoint(element) {
    schedule('afterRender', this, () => {
      let [x, y] = this._offsetsFromNodeTopRight(element);
      if (this.connectionPoint.x !== x) {
        this.connectionPoint.x = x;
      }
      if (this.connectionPoint.y !== y) {
        this.connectionPoint.y = y;
      }
    });
  }

  _offsetsFromNodeTopRight(element) {
    let scale = this.seriesEditorService.graph.state.scale;
    let elementRect = element.getBoundingClientRect();
    let parentRect = this.args.node.element.getBoundingClientRect();

    let nodeTopRight = {
      x: parentRect.right,
      y: parentRect.top,
    };

    let leftPad = 4;

    let connectorCenter = {
      x: elementRect.left + leftPad,
      y: elementRect.top + elementRect.height / 2,
    };

    let x = Math.round(Math.abs(connectorCenter.x - nodeTopRight.x) / scale);
    let y = Math.round(Math.abs(connectorCenter.y - nodeTopRight.y) / scale);

    return [x, y];
  }
}
