/* RESPONSIBLE TEAM: team-reporting */

import type IntlService from 'ember-intl/services/intl';
import {
  type Attribute,
  TICKETS_ATTRIBUTES,
} from 'embercom/objects/reporting/unified/datasets/types';
import { ATTRIBUTE_GROUP_ORDERING } from 'embercom/services/reporting-metrics';
import { chain } from 'underscore';

type AttributeGroup = (typeof ATTRIBUTE_GROUP_ORDERING)[number];

export interface DropDownItem {
  group: AttributeGroup;
  text: string;
  value: string; // attribute id
  tooltipText?: string;
  icon: string;
}

// An array of these can be passed as @groupList to IcDropdownOpener
interface GroupedDropDownItem {
  heading: string;
  items: DropDownItem[];
}

function groupTicketAttributes(
  items: DropDownItem[],
  availableDatasetAttributes: Attribute[],
): [string, DropDownItem[]][] {
  let ticketAttributesById = chain(availableDatasetAttributes)
    .filter((attribute) => attribute.group === TICKETS_ATTRIBUTES)
    .indexBy('id')
    .value();

  return chain(items)
    .filter((item) => ticketAttributesById[item.value].parentAttributeId !== null)
    .groupBy((item) => ticketAttributesById[item.value].parentAttributeId || '')
    .pairs()
    .map(([parentId, items]): [string, DropDownItem[]] => {
      return [ticketAttributesById[parentId]?.name || '', items];
    })
    .value();
}

export function groupDropDownItems(
  dropDownItems: DropDownItem[],
  availableDatasetAttributes: Attribute[],
  intl: IntlService,
): GroupedDropDownItem[] {
  let groupSortFunc = ([groupA]: [AttributeGroup, any], [groupB]: [AttributeGroup, any]) =>
    ATTRIBUTE_GROUP_ORDERING.indexOf(groupA) - ATTRIBUTE_GROUP_ORDERING.indexOf(groupB);

  return chain(dropDownItems)
    .groupBy('group')
    .pairs()
    .sort(groupSortFunc as any)
    .reduce((acc: [string, DropDownItem[]][], [group, items]) => {
      // ticket attributes need to be grouped under their own groups named by their parent attribute
      group === TICKETS_ATTRIBUTES
        ? acc.push(...groupTicketAttributes(items, availableDatasetAttributes))
        : acc.push([intl.t(`app.lib.reporting.custom.filter-headings.${group}-data`), items]);
      return acc;
    }, [])
    .map(([groupName, items]) => {
      return {
        heading: groupName,
        items: items.sortBy('text'),
      };
    })
    .value();
}
