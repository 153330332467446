/* import __COLOCATED_TEMPLATE__ from './search-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { useTask } from 'ember-resources';
import ajax from 'embercom/lib/ajax';

export default class SearchDrawer extends Component {
  articlesData = useTask(this, this.retrieveArticles, () => [
    this.args.selectedKeyword,
    this.args.showSearchOverlay,
  ]);
  @service store;
  @service notificationsService;
  @service intl;
  @service appService;

  @tracked metaData = null;
  @tracked articles = [];

  get app() {
    return this.appService.app;
  }

  @action
  onCancel() {
    this.metaData = null;
    this.articles = [];
    this.args.onClose();
  }

  get hasMorePages() {
    if (!this.metaData) {
      return false;
    }
    return this.metaData.page < this.metaData.total_pages;
  }

  getValueFromPercentage(percentage, total) {
    if (!total) {
      return '-';
    }
    return Math.round((percentage / 100) * total);
  }

  get columns() {
    let columnsToDisplay = [
      {
        label: this.intl.t('reporting.articles.search-results.articles-shown-in-search-results'),
        valuePath: 'title',
        minWidth: '50px',
        maxWith: '100px',
      },
      {
        label: this.intl.t('reporting.articles.search-results.views'),
        valuePath: 'viewCount',
        minWidth: '20px',
        width: '20px',
      },
      {
        label: this.intl.t('reporting.articles.search-results.bad'),
        valuePath: 'reaction2Percentage',
        minWidth: '20px',
        width: '20px',
      },
      {
        label: this.intl.t('reporting.articles.search-results.neutral'),
        valuePath: 'reaction1Percentage',
        minWidth: '20px',
        width: '20px',
      },
      {
        label: this.intl.t('reporting.articles.search-results.good'),
        valuePath: 'reaction0Percentage',
        minWidth: '20px',
        width: '20px',
      },
      {
        label: this.intl.t('reporting.articles.search-results.conversations'),
        valuePath: 'replyCount',
        minWidth: '35px',
        width: '35px',
      },
    ];
    return columnsToDisplay;
  }

  @action
  async onloadMore() {
    await this.retrieveArticles.perform();
    return this.articles;
  }

  // when store.query is called inside a task it goes into a loop which is a known bug in ember
  // https://github.com/NullVoxPopuli/ember-resources/issues/340
  // to resolve this issue yield Promise.resolve() is added at the top of the task
  @task
  *retrieveArticles() {
    yield Promise.resolve();
    try {
      let currentPage = this.metaData ? this.metaData.page : 0;
      let nextPage = this.metaData ? this.metaData.next_page : 1;
      if (nextPage > currentPage) {
        let response = yield ajax({
          url: `/ember/articles/reporting_search`,
          data: {
            app_id: this.app.id,
            phrase: this.args.selectedKeyword,
            page: nextPage,
            per_page: 10,
            ...(this.args.locale && { locale: this.args.locale }),
          },
        });

        this.store.pushPayload({ 'article-multilingual': response.article_multilinguals });

        this.metaData = response.meta;
        response.article_multilinguals.forEach((a) => {
          let article = this.store.peekRecord('article-multilingual', a.id);
          article.loadStats.perform();
          this.articles.addObject(article);
        });
      }
      return this.articles;
    } catch (exception) {
      this.notificationsService.notifyError(
        this.intl.t('reporting.articles.search-results.unable-to-load-search-results'),
      );
      this.args.onClose();
    }
  }
}
