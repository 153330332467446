/* import __COLOCATED_TEMPLATE__ from './line.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { select } from 'd3-selection';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { line } from 'd3-shape';
import { interpolatePath } from 'd3-interpolate-path';
import { easeCubicOut } from 'd3-ease';
import 'd3-transition';

let createLine = ({ xScale, yScale = () => 0, height }) =>
  line()
    .x((d) => xScale(new Date(d.key)) + 46 / 2)
    .y((d) => height - yScale(d.value));

let drawLines =
  ({ series, xScale, yScale, height, keyColorHash }) =>
  (chart) => {
    let initialLineFn = createLine({ xScale, height });
    let finalLineFn = createLine({ xScale, yScale, height });

    let points = chart.selectAll('.reporting__chart__series').data(series, (d) => d.key);

    points.exit().remove();

    let enterPoints = points
      .enter()
      .append('g')
      .attr('class', (d) => `reporting__chart__series _${d.key}`);

    enterPoints
      .append('path')
      .attr('class', 'reporting__chart__line')
      .attr('d', (d) => initialLineFn(d.value));

    return enterPoints
      .merge(points)
      .select('.reporting__chart__line')
      .attr('class', (d, i) => `reporting__chart__line o__${keyColorHash[d.key]}`)
      .transition()
      .ease(easeCubicOut)
      .duration(1000)
      .attrTween('d', function (d) {
        let previous = select(this).attr('d');
        let current = finalLineFn(d.value);
        return interpolatePath(previous, current);
      });
  };

export default Component.extend({
  didReceiveAttrs() {
    this._super(...arguments);
    this.drawLines.perform();
  },
  didInsertElement() {
    this._super(...arguments);
    this.drawLines.perform();
  },

  drawLines: task(function* () {
    let line = select(this.element).select('.line');
    yield drawLines({
      series: this.series,
      xScale: this.xScale,
      yScale: this.yScale,
      height: this.height,
      keyColorHash: this.keyColorHash,
    })(line);
  }).restartable(),
});
