/* import __COLOCATED_TEMPLATE__ from './reassign-messages.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
import { computed, action } from '@ember/object';
import { filterBy, map, alias, setDiff, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { rejectByProperty } from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';

export default Component.extend({
  tagName: '',
  appService: service(),
  intl: service(),
  notificationsService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  permissionsMutatorService: service(),
  app: alias('appService.app'),
  adminToReassign: readOnly('model'),
  teammates: alias('options.teammates'),
  isDeleting: readOnly('options.isDeleting'),
  hadInboxAccess: readOnly('options.hadInboxAccess'),
  permissionToEdit: alias('model.currentAppPermissions'),
  reassignments: { teams: {} },
  canSave: readOnly('isValid'),
  isValid: false,
  perProductPricingDeleteConfirmationMessage: alias('options.removedSeatPriceInfo'),
  sendersWithoutAdminToReassign: rejectByProperty('app.humanAdmins', 'id', 'adminToReassign.id'),
  assignableAdminsExcludingAdminToReassign: computed(
    'app.assignableAdmins.@each.{id,name}',
    'adminToReassign.id',
    function () {
      let adminToReassignId = this.get('adminToReassign.id');
      return this.get('app.assignableAdmins').reject((admin) => {
        return admin.get('id') === adminToReassignId || admin.get('name') === 'Unassigned';
      });
    },
  ),
  teamsWithOneAdmin: filterBy('assignableTeams', 'teamMemberCount', 1),
  teamsWithOnlyAdminToBeReassigned: computed(
    'teamsWithOneAdmin.[]',
    'teamsWithOneAdmin.@each.firstTeamMemberId',
    'adminToReassign.id',
    function () {
      let adminToReassignId = this.get('adminToReassign.id');
      return this.teamsWithOneAdmin.filter(
        (team) => team.get('firstTeamMemberId').toString() === adminToReassignId,
      );
    },
  ),
  assignableTeammates: filterBy('assignableAdminsExcludingAdminToReassign', 'isTeam', false),
  assignableTeams: setDiff('assignableAdminsExcludingAdminToReassign', 'assignableTeammates'),
  assignableTeamsExcludingTeamsWithOnlyAdminToBeReassigned: setDiff(
    'assignableTeams',
    'teamsWithOnlyAdminToBeReassigned',
  ),
  // Items for use in ic-select
  teamItems: map('assignableTeamsExcludingTeamsWithOnlyAdminToBeReassigned', function (team) {
    return this.buildItem(team);
  }),
  teammateItems: map('assignableTeammates', function (teammate) {
    return this.buildItem(teammate);
  }),
  teamsAndTeammatesItems: computed('teamItems', 'teammateItems', 'intl.locale', function () {
    return [
      {
        hasItemsMarkableAsSelected: true,
        heading: this.intl.t(
          'controllers.apps.app.settings.modals.reassign-messages-base.headings.teammates',
        ),
        items: this.teammateItems,
      },
      {
        hasItemsMarkableAsSelected: true,
        heading: this.intl.t(
          'controllers.apps.app.settings.modals.reassign-messages-base.headings.teams',
        ),
        items: this.teamItems,
      },
    ];
  }),
  sendersWithoutAdminToReassignItems: map('sendersWithoutAdminToReassign', function (sender) {
    return this.buildItem(sender);
  }),
  buildItem(teamOrTeammate) {
    return {
      text: teamOrTeammate.get('name'),
      value: teamOrTeammate,
      component: 'settings/teams/team-or-teammate-dropdown-item',
      componentAttrs: teamOrTeammate,
    };
  },
  save: task(function* () {
    try {
      yield this.permissionsMutatorService.savePermission(
        this.model,
        this.permissionToEdit,
        false,
        this.perProductPricingDeleteConfirmationMessage,
      );
      this.closeModal();
    } catch (e) {
      // noop
    }
  }).drop(),
  closeModal: action(function () {
    if (this.permissionToEdit !== undefined && !this.permissionToEdit.isSaving) {
      // Reset reassign properties if not saving
      this.permissionToEdit.setProperties({
        reassign_replies_admin_id: null,
        reassign_auto_messages_admin_id: null,
        reassign_replies_teams: [],
        reassign_articles_author_id: null,
      });
    }
    this.set('isValid', false);
    this.modalService.closeModal();
  }),

  actions: {
    updateSelection(isValid) {
      this.permissionToEdit.reassignments = this.reassignments;
      this.set('isValid', isValid);
    },
  },
});
