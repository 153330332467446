/* import __COLOCATED_TEMPLATE__ from './chat-message.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { EntityType } from 'embercom/models/data/entity-types';
import { type NormalizedFinSource } from 'embercom/lib/inbox2/copilot/types';
import { type InboxAssistantConversationPart } from 'embercom/objects/inbox/inbox-assistant-conversation';
import { CopilotSourceSection } from 'embercom/lib/inbox2/copilot/types';

interface Args {
  message: InboxAssistantConversationPart;
  sources?: NormalizedFinSource[];
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class ChatMessage extends Component<Signature> {
  @tracked previewingSource?: {
    source?: NormalizedFinSource;
    element?: HTMLElement;
  };

  readonly EntityType = EntityType;
  readonly CopilotSourceSection = CopilotSourceSection;

  @action onSourceClick(
    entityId: number,
    entityType: EntityType,
    _passageId: string,
    event: PointerEvent,
  ) {
    let source = this.args.sources?.find(
      (s) => s.entity_id === entityId && s.entity_type === entityType,
    );

    if (!source) {
      throw new Error('Source not found');
    }

    source.onClickLink({ isInlineSource: true }, event);
  }

  @action onSourceHover(
    entityId: number,
    entityType: EntityType,
    event: PointerEvent,
    element: HTMLElement,
  ): void {
    let source = this.args.sources?.find(
      (s) => s.entity_id === entityId && s.entity_type === entityType,
    );
    if (!source) {
      throw new Error('Source not found');
    }

    event.preventDefault();
    event.stopPropagation();
    this.previewingSource = { source, element };
  }

  @action onSourceDismissHover(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();
    //If we set the previewingSource to undefined it instantly removes the popover
    //Just removing the element will keep the source rendered as long as the user is hovering over it
    this.previewingSource = {
      source: this.previewingSource?.source,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::Copilot::ChatMessage': typeof ChatMessage;
    'reporting/custom/report/copilot/chat-message': typeof ChatMessage;
  }
}
