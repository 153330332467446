/* import __COLOCATED_TEMPLATE__ from './calls-report.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type ReportingSettings from 'embercom/models/reporting/settings';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';

const DEFAULT_CHART_DEFINITION = {
  id: null,
  title: null,
  visualization_type: null,
  metric_property: null,
  metric_function: null,
  stacked: false,
  view_by: null,
  view_by_time_interval: null,
  view_by_display_limit: 10000,
  view_by_display_other: false,
  width_type: null,
  height_type: null,
};

interface Args {
  settings: typeof ReportingSettings;
  conversationAttributeDescriptors: ConversationAttributeDescriptor[];
}

export default class CallsReport extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare reportingMetrics: ReportingMetrics;

  get reportDefinition() {
    return {
      id: `calls_report`,
      title: null,
      description: null,
      charts: this.charts,
      date_range: null,
      filters: {},
    };
  }

  get filterableProperties() {
    let callsMetric = this.reportingMetrics.getMetricById('calls.new.count');
    return callsMetric.supportedFilters.map(({ id }) => id) || [];
  }

  metricDescription(metricId: string) {
    let metric = this.reportingMetrics.getMetricById(metricId);
    return metric.description;
  }

  get charts() {
    return [
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'inbound_calls_count',
        title: this.intl.t('reporting.calls.inbound-calls-count-title'),
        tooltip: this.metricDescription('calls.inbound.count'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'calls.inbound.count',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'outbound_calls_count',
        title: this.intl.t('reporting.calls.outbound-calls-count-title'),
        tooltip: this.intl.t('reporting.calls.outbound-calls-count-tooltip'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'calls.new.count',
            filters: {
              type: 'and',
              filters: [
                {
                  type: 'category',
                  data: {
                    property: 'direction',
                    values: ['outbound'],
                  },
                },
              ],
            },
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'messenger_calls_count',
        title: this.intl.t('reporting.calls.messenger-calls-count-title'),
        tooltip: this.intl.t('reporting.calls.messenger-calls-count-tooltip'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'calls.new.count',
            filters: {
              type: 'and',
              filters: [
                {
                  type: 'category',
                  data: {
                    property: 'call_type',
                    values: ['messenger'],
                  },
                },
              ],
            },
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'median_call_duration',
        title: this.intl.t('reporting.calls.median-call-duration-title'),
        tooltip: this.metricDescription('calls.duration'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'calls.duration',
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'median_call_in_queue_time',
        title: this.intl.t('reporting.calls.median-call-in-queue-time-title'),
        tooltip: this.metricDescription('calls.inbound.queue_time'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'calls.inbound.queue_time',
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'median_call_talk_time',
        title: this.intl.t('reporting.calls.median-call-talk-time-title'),
        tooltip: this.metricDescription('calls.talk_time'),
        visualization_type: VISUALIZATION_TYPES.COUNTER,
        visualization_options: {
          show_time_comparison: true,
        },
        width_type: WIDTH_TYPES.TWO_COLUMN,
        height_type: HEIGHT_TYPES.X_SMALL,
        chart_series: [
          {
            metric_id: 'calls.talk_time',
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'calls_by_direction',
        title: this.intl.t('reporting.calls.calls-by-direction-title'),
        tooltip: this.metricDescription('calls.new.count'),
        visualization_type: VISUALIZATION_TYPES.BAR,
        stacked: true,
        view_by: 'time',
        segment_by: 'direction',
        width_type: WIDTH_TYPES.THREE_COLUMN,
        chart_series: [
          {
            metric_id: 'calls.new.count',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'inbound_calls_by_state',
        title: this.intl.t('reporting.calls.inbound-calls-by-state-title'),
        tooltip: this.metricDescription('calls.inbound.count'),
        visualization_type: VISUALIZATION_TYPES.BAR,
        stacked: true,
        view_by: 'time',
        segment_by: 'state',
        width_type: WIDTH_TYPES.THREE_COLUMN,
        chart_series: [
          {
            metric_id: 'calls.inbound.count',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'median_call_talk_time_by_time',
        title: this.intl.t('reporting.calls.median-call-talk-time-by-time-title'),
        tooltip: this.metricDescription('calls.talk_time'),
        visualization_type: VISUALIZATION_TYPES.BAR,
        width_type: WIDTH_TYPES.THREE_COLUMN,
        view_by: 'time',
        chart_series: [
          {
            metric_id: 'calls.talk_time',
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'median_call_in_queue_time_by_time',
        title: this.intl.t('reporting.calls.median-call-in-queue-time-title'),
        tooltip: this.metricDescription('calls.inbound.queue_time'),
        visualization_type: VISUALIZATION_TYPES.BAR,
        width_type: WIDTH_TYPES.THREE_COLUMN,
        view_by: 'time',
        chart_series: [
          {
            metric_id: 'calls.inbound.queue_time',
            aggregation: 'median',
          },
        ],
      },
      {
        ...DEFAULT_CHART_DEFINITION,
        id: 'teammate_performance',
        title: this.intl.t('reporting.calls.teammate-performance-title'),
        tooltip: null,
        visualization_type: VISUALIZATION_TYPES.TABLE,
        view_by: 'admin_id',
        width_type: WIDTH_TYPES.FULL,
        chart_series: [
          {
            metric_id: 'calls.new.count',
            filters: this.filtersForTeammateTable,
          },
          {
            metric_id: 'calls.inbound.count',
            filters: this.filtersForTeammateTable,
          },
          {
            metric_id: 'calls.outbound.count',
            filters: this.filtersForTeammateTable,
          },
          {
            metric_id: 'calls.teammate.talk_time',
            aggregation: 'median',
            filters: this.filtersForTeammateTable,
          },
        ],
      },
    ];
  }

  get filtersForTeammateTable() {
    return {
      type: 'and',
      filters: [
        {
          type: 'exists',
          data: {
            property: 'admin_id',
          },
        },
      ],
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Reports::Calls::CallsReport': typeof CallsReport;
  }
}
