/* import __COLOCATED_TEMPLATE__ from './stacked-bar-chart-legend.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { CONVERSATION_RATING_IDS } from 'predicates/models/data/conversation-ratings';
import PALETTE from '@intercom/pulse/lib/palette';

let ORDERED_HAPPINESS_RATING_INDEXES = [
  CONVERSATION_RATING_IDS.AMAZING,
  CONVERSATION_RATING_IDS.GREAT,
  CONVERSATION_RATING_IDS.OK,
  CONVERSATION_RATING_IDS.BAD,
  CONVERSATION_RATING_IDS.TERRIBLE,
];

let HAPPINESS_PALETTE = {
  [CONVERSATION_RATING_IDS.AMAZING]: PALETTE['vis-green-40'],
  [CONVERSATION_RATING_IDS.GREAT]: PALETTE['vis-green-60'],
  [CONVERSATION_RATING_IDS.OK]: PALETTE['vis-yellow-70'],
  [CONVERSATION_RATING_IDS.BAD]: PALETTE['vis-yellow-50'],
  [CONVERSATION_RATING_IDS.TERRIBLE]: PALETTE['vis-salmon-40'],
};

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  attributeBindings: ['data-test-reporting-stacked-bar-chart-legend'],
  'data-test-reporting-stacked-bar-chart-legend': true,
  unit: 'conversations',

  totalCount: computed('aggregate', 'signal', function () {
    return this.aggregate ? this.signal.total : this.signal.sumOfAllBuckets();
  }),

  data: computed('signal', function () {
    if (this.aggregate) {
      return ORDERED_HAPPINESS_RATING_INDEXES.map((index) => ({
        index,
        count: this.signal.totalsPerKey[index],
        rawPercentage: this.signal.percentagesByKey[index],
        color: HAPPINESS_PALETTE[index],
      }));
    }

    return ORDERED_HAPPINESS_RATING_INDEXES.map((index) => ({
      index,
      count: this.signal.sumForBucketIndex(index),
      rawPercentage: this.signal.percentForBucketIndex(index),
      color: HAPPINESS_PALETTE[index],
    }));
  }),
});
