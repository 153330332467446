/* import __COLOCATED_TEMPLATE__ from './list-all.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isEmpty, isPresent } from '@ember/utils';
import ENV from 'embercom/config/environment';

const DIRECTION_ASC = 'asc';
const DIRECTION_DESC = 'desc';
const UNTITLED_LABEL = 'untitled';

export default class ListAll extends Component {
  @service appService;
  @service router;
  @service outboundHomeService;
  @tracked tableSortState;
  @tracked pageFrom;
  @tracked tableData;
  @tracked lastPageHit;
  @tracked searchQuery;
  @tracked shouldShowEmptyState;

  constructor() {
    super(...arguments);
    this.tableData = this.args.model;
    this.pageFrom = this.args.pageFrom;
    this.lastPageHit = this.args.lastPageHit;
    this.pageSize = this.args.pageSize;
    this.shouldShowEmptyState = isEmpty(this.tableData);
  }

  @action
  async onSortUpdate(valuePath) {
    let currentDirection = this.tableSortState?.direction;
    let newDirection =
      isEmpty(currentDirection) || currentDirection === DIRECTION_DESC
        ? DIRECTION_ASC
        : DIRECTION_DESC;

    this.tableSortState = {
      valuePath,
      direction: newDirection,
    };
    this.sortTableByTitle(newDirection);
  }

  @action
  async onLoadMore() {
    this.pageFrom += 1;
    await this.executeSearch.perform(this.searchQuery);
  }

  @action
  onSearch({ target }) {
    this.pageFrom = 0;
    this.tableData = [];
    this.searchQuery = target.value;
    this.executeSearch.perform(target.value);
  }

  @action
  async transitionToSurveyCreation() {
    let newRoute = await this.router.transitionTo('apps.app.outbound.survey');
    newRoute.controller.set('showContentCreationModal', true);
  }

  @task({ restartable: true })
  *executeSearch(searchQuery) {
    this.lastPageHit = false;

    yield timeout(ENV.APP._150MS);
    let query = {
      object_types: [objectTypes.survey],
      app_id: this.appService.app.id,
      states: [states.live, states.paused],
      per_page: this.pageSize,
      page_from: this.pageFrom,
    };
    if (isPresent(searchQuery)) {
      query.title = searchQuery;
      query.wildcard_title_search = true;
    }
    let response = yield this.outboundHomeService.contentSearch(query);
    this.pageFrom = response.pageFrom;
    this.lastPageHit = response.lastPageHit;
    this.tableData = [...this.tableData, ...response.contentWrappers];
    this.sortTableByTitle(this.tableSortState?.direction);
  }

  sortTableByTitle(direction) {
    if (isEmpty(direction)) {
      return;
    }
    let sortedTable = this.tableData.sort((obj1, obj2) => {
      let a = isEmpty(obj1.title) ? UNTITLED_LABEL : obj1.title?.toLowerCase();
      let b = isEmpty(obj2.title) ? UNTITLED_LABEL : obj2.title?.toLowerCase();
      return this.sortByString(a, b, direction);
    });
    this.tableData = sortedTable;
  }

  sortByString(a, b, direction) {
    if (a === b) {
      return 0;
    }
    if (direction === DIRECTION_ASC) {
      return a < b ? -1 : 1;
    }
    return a > b ? -1 : 1;
  }
}
