/* RESPONSIBLE TEAM: team-proactive-support */
import PALETTE from '@intercom/pulse/lib/palette';

const SERIES_COLOR_PALETTE = {
  azure: PALETTE['vis-azure-50'],
  yellow: PALETTE['vis-yellow-50'],
  mint: PALETTE['vis-mint-50'],
  salmon: PALETTE['vis-salmon-50'],
  slateblue: PALETTE['vis-slateblue-50'],
  olive: PALETTE['vis-olive-50'],
  pink: PALETTE['vis-pink-50'],
  turquoise: PALETTE['vis-turquoise-50'],
  orange: PALETTE['vis-orange-50'],
  blue: PALETTE['vis-blue-50'],
};

export const seriesPerformanceStatsDetails = {
  started: {
    header: 'outbound.series.aggregated-stats.performance.started.header',
    metric: 'series.receipts',
    numerator_metric: 'series.receipts',
    color: SERIES_COLOR_PALETTE.azure,
  },
  finished: {
    header: 'outbound.series.aggregated-stats.performance.finished.header',
    metric: 'series.receipts.series_completion.percent',
    numerator_metric: 'series.receipts.series_completion',
    color: SERIES_COLOR_PALETTE.yellow,
  },
  disengaged: {
    header: 'outbound.series.aggregated-stats.performance.disengaged.header',
    metric: 'series.receipts.series_disengagement.percent',
    numerator_metric: 'series.receipts.series_disengagement',
    color: SERIES_COLOR_PALETTE.mint,
  },
  exited: {
    header: 'outbound.series.aggregated-stats.performance.exited.header',
    metric: 'series.receipts.series_exit.percent',
    numerator_metric: 'series.receipts.series_exit',
    condition: (seriesEditorService) => seriesEditorService.series.hasExitRules,
    color: SERIES_COLOR_PALETTE.salmon,
  },
  goal: {
    header: 'outbound.series.aggregated-stats.performance.goal.header',
    metric: 'series.receipts.goal_success.percent',
    numerator_metric: 'series.receipts.goal_success',
    condition: (seriesEditorService) => seriesEditorService.series.hasGoal,
    color: SERIES_COLOR_PALETTE.olive,
  },
};

export const seriesPerformanceAggregatedStats = [
  seriesPerformanceStatsDetails.started,
  seriesPerformanceStatsDetails.finished,
  seriesPerformanceStatsDetails.disengaged,
  seriesPerformanceStatsDetails.exited,
  seriesPerformanceStatsDetails.goal,
];

export const seriesPerformanceFilter = [
  {
    type: 'category',
    data: {
      property: 'series.id',
      values: [],
    },
  },
];
