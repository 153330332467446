/* import __COLOCATED_TEMPLATE__ from './viewer.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { isNone } from '@ember/utils';

export default class Viewer extends Component {
  get activeViewConfig() {
    let activeViewConfig = this.config.activeViewConfiguration[this.args.activeView];
    if (isNone(activeViewConfig)) {
      throw new Error(`No configuration found to render a view for ${this.args.activeView}`);
    }
    return activeViewConfig;
  }

  get config() {
    return this.args.selectionSet.object.selectionSetConfig();
  }

  // Returns an object of the format { statisticKeys.receipts => ContentStatistic, statisticKeys.opens => ContentStatistic, etc }
  get statistics() {
    return Object.fromEntries(
      new Map(this.args.selectionSet.objectStats.map((statistic) => [statistic.key, statistic])),
    );
  }
}
