/* import __COLOCATED_TEMPLATE__ from './stat-tab.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { statisticTypes } from 'embercom/models/data/outbound/constants';

export default class StatTab extends Component {
  get statisticTypes() {
    return statisticTypes;
  }
}
