/* import __COLOCATED_TEMPLATE__ from './events-table.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { A } from '@ember/array';
import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';
import { notEmpty, sort, union } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { findBy } from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';
import escapeRegExp from 'embercom/lib/escape-regular-expression';

export default Component.extend({
  intercomEventService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  store: service(),
  intl: service(),

  tagName: '',

  activeEvents: A(),
  archivedEvents: A(),
  eventTrackers: A(),
  eventActivities: A(),

  currentSortColumn: findBy('columns', 'isSortColumn', true),
  sortDefinition: computed('currentSortColumn', 'currentSortColumn.sortedAscending', function () {
    let sortColumn = this.currentSortColumn;
    return sortColumn.sortedAscending
      ? [sortColumn.propertyName]
      : [`${sortColumn.propertyName}:desc`];
  }),

  sortedActiveEvents: sort('activeEvents', 'sortDefinition'),
  allEvents: union('sortedActiveEvents', 'archivedEvents'),
  hasAnyEvents: notEmpty('allEvents'),
  init() {
    this._super(...arguments);
    this.set(
      'columns',
      A([
        EmberObject.create({
          label: this.intl.t('settings.events-table.event-name'),
          canExpandToFit: true,
          component: 'table-cells/events/name-and-description-cell',
          sortable: true,
          isSortColumn: false,
          sortedAscending: true,
          propertyName: 'name',
        }),
        EmberObject.create({
          label: this.intl.t('settings.events-table.recently-used'),
          component: 'table-cells/events/recently-used-cell',
          propertyName: 'recently_used',
        }),
        EmberObject.create({
          label: this.intl.t('settings.events-table.created'),
          component: 'table-cells/events/created-at-cell',
          sortable: true,
          isSortColumn: true,
          sortedAscending: false,
          propertyName: 'created_at',
        }),
      ]),
    );
  },

  columns: A(),

  eventSearchString: null,
  hasSearchString: notEmpty('eventSearchString'),

  rows: computed(
    'eventSearchString',
    'allEvents.[]',
    'eventTrackers.@each.id',
    'eventActivities.@each.id',
    function () {
      let filteredEvents = this._filterEventsBySearchString(this.allEvents);
      return filteredEvents.map((event) => this.createRow(event));
    },
  ),

  createRow(event) {
    return EmberObject.create({
      event,
      eventTracker: this.eventTrackers.findBy('name', event.get('name')),
      eventActivity: this.eventActivities.findBy('eventName', event.get('name')),
    });
  },

  _filterEventsBySearchString(events) {
    let eventSearchString = this.eventSearchString;
    if (isPresent(eventSearchString)) {
      let filterRegex = new RegExp(escapeRegExp(this.eventSearchString), 'i');
      return events.filter((event) =>
        filterRegex.test([event.get('humanFriendlyName'), event.get('description')].join(' ')),
      );
    } else {
      return events;
    }
  },

  onArchive(event) {
    event.set('archived', true);
    this.archivedEvents.pushObject(event);
  },

  onUnarchive(event) {
    event.set('archived', false);
    this.archivedEvents.removeObject(event);
  },

  onDeleteEventTracker(row) {
    row.set('eventTracker', null);
  },

  fetchEventActivities: task(function* (row) {
    let eventActivities = yield this.store.findAll('event-activity');
    this.set('eventActivities', eventActivities);
  }).keepLatest(),

  actions: {
    openDetailsView(row) {
      let event = row.get('event');
      let eventTracker = row.get('eventTracker');
      let customEventDetailsModal = 'settings/modals/custom-event-details';
      let model = {
        event,
        eventTracker,
        onArchive: () => this.onArchive(event),
        onUnarchive: () => this.onUnarchive(event),
        onDeleteEventTracker: () => this.onDeleteEventTracker(row),
      };
      this.modalService.openModal(customEventDetailsModal, model);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        object: 'event_details_modal',
        event_name: event.name,
      });
    },

    toggleSort(column) {
      let columns = this.columns;
      let currentSortColumn = this.currentSortColumn;

      if (currentSortColumn.get('propertyName') === column.propertyName) {
        currentSortColumn.toggleProperty('sortedAscending');
      } else {
        currentSortColumn.set('isSortColumn', false);

        let newSortColumn = columns.findBy('propertyName', column.propertyName);
        newSortColumn.set('isSortColumn', true);
      }
    },
  },
});
