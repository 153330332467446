/* import __COLOCATED_TEMPLATE__ from './user-type-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

export default class UserTypeFilter extends Component {
  @service intercomEventService;
  @service intl;
  @tracked label = this.currentLabel;
  // store the selection here so that the UI doesn't update until the drop down is closed
  selectedDropdownItems = this.args.selected.values || [];

  get userAndLeads() {
    return {
      user_role: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.user-type-filter.roles.user',
      ),
      contact_role: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.user-type-filter.roles.lead',
      ),
    };
  }

  get availableValues() {
    return {
      user_role: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.user-type-filter.roles.user',
      ),
      contact_role: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.user-type-filter.roles.lead',
      ),
      visitor_role: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.user-type-filter.roles.visitor',
      ),
    };
  }

  get items() {
    return this.groupList;
  }

  get selectedItems() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get allItems() {
    return Object.entries(this._availableValues).map(([value, text]) => ({
      text,
      value,
      isSelected: this.selectedDropdownItems.includes(value),
    }));
  }

  get groupList() {
    return [
      {
        items: this.allItems,
      },
    ];
  }

  get currentLabel() {
    if (this.selectedDropdownItems?.length && this.selectedDropdownItems[0] !== 'any') {
      return this.selectedDropdownItems.map((value) => this._availableValues[value]).join(', ');
    } else {
      return this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.user-type-filter.any',
      );
    }
  }

  get _availableValues() {
    return this.args.customFilterArgs?.showVisitorType ? this.availableValues : this.userAndLeads;
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_user_role',
      object: this.args.analyticsObject,
      continent: this.selectedContinents,
    });

    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }
}
