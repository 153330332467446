/* import __COLOCATED_TEMPLATE__ from './pick-conversation-sla.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-get-with-default */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { reads, readOnly, filterBy } from '@ember/object/computed';
import { formatSeconds } from 'embercom/lib/duration-formatter';
import { inject as service } from '@ember/service';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  conversationSlas: readOnly('actionEditorContext.conversationSlas'),
  officeHoursSchedules: readOnly('actionEditorContext.officeHoursSchedules'),
  liveConversationSlas: filterBy('conversationSlas', 'archived', false),
  showNewSlaWindow: false,

  slaOnDelete: computed('actionEditorContext.allowSlaOnDelete', 'onDelete', function () {
    if (this.actionEditorContext.allowSlaOnDelete && this.onDelete) {
      return this.onDelete;
    }
    return null;
  }),

  selectItems: computed('liveConversationSlas.@each.{name,id}', function () {
    return (this.liveConversationSlas ?? []).map((sla) => {
      return {
        text: sla.get('name'),
        value: sla.get('id'),
        description: `${slaDescription(this.app, sla, this.officeHoursSchedules)}`,
      };
    });
  }),

  selectedConversationSla: computed(
    'action.actionData.conversation_sla_id',
    'liveConversationSlas.@each.id',
    function () {
      let slaId = this.get('action.actionData.conversation_sla_id');
      if (slaId) {
        return (this.liveConversationSlas ?? []).findBy('id', slaId);
      }
    },
  ),
  selectedOfficeHoursId: reads('selectedConversationSla.officeHoursScheduleId'),
  officeHoursLabel: computed('selectedOfficeHoursId', function () {
    let officeHoursSchedule = this.officeHoursSchedules.findBy('id', this.selectedOfficeHoursId);
    return `${officeHoursSchedule.name} office hours`;
  }),

  filterBlockDetail: computed('selectedConversationSla', function () {
    let sla = this.selectedConversationSla;
    if (sla) {
      return `${sla.get('name')}`;
    }
  }),

  filterBlockDetailTooltip: computed('selectedConversationSla', function () {
    let sla = this.selectedConversationSla;
    if (sla) {
      return `${slaDescription(this.app, sla, this.officeHoursSchedules)}`;
    }
  }),

  actions: {
    updateConversationSla(slaId) {
      this.set('action.actionData', { conversation_sla_id: slaId });

      let onChange = this.onChange;
      if (onChange) {
        onChange();
      }
    },

    openNewSlaWindow() {
      this.set('showNewSlaWindow', true);
    },

    closeNewSlaWindow() {
      this.set('showNewSlaWindow', false);
    },

    onSlaCreated(sla) {
      this.send('updateConversationSla', sla.get('id'));
    },
  },
});

function slaDescription(app, sla, officeHoursSchedules) {
  if (officeHoursSchedules) {
    let officeHoursSchedule = officeHoursSchedules.findBy('id', sla.officeHoursScheduleId);
    if (officeHoursSchedule) {
      let officeHoursLabel = `${officeHoursSchedule.name}`;
      return `${formatConversationSlaResponseTimes(app, sla)}, office hours: ${officeHoursLabel}`;
    }
  }
  return `${formatConversationSlaResponseTimes(app, sla)}`;
}

function formatConversationSlaResponseTimes(app, sla) {
  return [
    ['FRT', sla.get('firstReplyTimeInSeconds')],
    ['NRT', sla.get('nextReplyTimeInSeconds')],
    ['TTC', sla.get('timeToCloseInSeconds')],
    ['TTR', sla.get('resolutionTimeInSeconds')],
  ]
    .filter(([label, seconds]) => {
      return !!seconds;
    })
    .map(([label, seconds]) => {
      return `${label}: ${formatSeconds(seconds)}`;
    })
    .join(', ');
}
