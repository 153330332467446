/* import __COLOCATED_TEMPLATE__ from './trigger-marketo-campaign-overlay.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import BaseFilterBlockPopover from 'predicates/components/filter-block/base-filter-block-popover';
import { action } from '@ember/object';

export default class TriggerMarketoCampaignOverlay extends BaseFilterBlockPopover {
  @action
  onDone() {
    this.componentAttrs.fetchAndSaveCampaignInfo();
    this.hideDropdownOverlay();
  }
}
