/* import __COLOCATED_TEMPLATE__ from './conversations-most-engaging-message-and-bots.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { hash, allSettled } from 'rsvp';
import {
  NEW_CONVERSATIONS_EVENT_TYPE,
  FILTER_AUTO_MESSAGES_CONVERSATION,
  FILTER_CUSTOM_BOT_CONVERSATION,
  FILTER_CUSTOM_BOT_AND_AUTO_MESSAGES_CONVERSATION,
  GROUP_BY_CONVERSATION_MESSAGE_TYPE,
  FILTER_STARTED_CONVERSATIONS,
} from 'embercom/lib/reporting/events';
import {
  topValuesSignalToContextSignal,
  concatAndSortSignalWith,
  prefixContextKey,
  reverse,
} from 'embercom/lib/reporting/signal-transformations';

const TOP_MESSAGES_PARAMS = {
  valueUnit: 'conversations',
  signalConfig: {
    name: 'default_query',
    document_type: 'events',
    range_field: 'event.created_at',
    aggregation_type: 'terms',
    aggregation_field: 'conversation.message_id',
    keys_are_dates: false,
  },
  aggregations: [],
  filters: {
    ...NEW_CONVERSATIONS_EVENT_TYPE,
    ...FILTER_AUTO_MESSAGES_CONVERSATION,
    ...FILTER_STARTED_CONVERSATIONS,
  },
};

const TOP_CUSTOM_BOTS_PARAMS = {
  valueUnit: 'conversations',
  signalConfig: {
    name: 'default_query',
    document_type: 'events',
    range_field: 'event.created_at',
    aggregation_type: 'terms',
    aggregation_field: 'current.conversation.custom_bot_id',
    keys_are_dates: false,
  },
  aggregations: [],
  filters: {
    ...NEW_CONVERSATIONS_EVENT_TYPE,
    ...FILTER_CUSTOM_BOT_CONVERSATION,
    ...FILTER_STARTED_CONVERSATIONS,
  },
};

const ALL_SIGNAL = {
  valueUnit: 'conversations',
  signalConfig: {
    name: 'default_query',
    document_type: 'events',
    range_field: 'event.created_at',
    keys_are_dates: false,
    aggregation_type: 'value_count',
    aggregation_field: 'conversation.id',
  },
  aggregations: [GROUP_BY_CONVERSATION_MESSAGE_TYPE],
  filters: {
    ...NEW_CONVERSATIONS_EVENT_TYPE,
    ...FILTER_CUSTOM_BOT_AND_AUTO_MESSAGES_CONVERSATION,
    ...FILTER_STARTED_CONVERSATIONS,
  },
};

const modelToRoute = (model) => {
  switch (model.constructor.modelName) {
    case 'operator/custom-bot':
      return 'apps.app.operator.custom-bots.custom-bot.show';
    case 'message':
      return 'apps.app.outbound.message';
  }
};

const keyToLabelFallback = (key) => {
  let [prefix, id] = key.split(':');
  switch (prefix) {
    case 'operator/custom-bot':
      return `Custom Bot ID ${id} (Deleted)`;
    case 'message':
      return `Message ID ${id} (Deleted)`;
  }
};

export default Component.extend({
  reportingService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  store: service(),

  didReceiveAttrs() {
    this._super(...arguments);
    this.fetchSignalsAndModels.perform();
  },

  topListCount: 5,

  fetchTopModels: task(function* (keys) {
    let models = yield allSettled(
      keys.map((key) => {
        let [modelName, id] = key.split(':');
        return this.store.findRecord(modelName, id, { backgroundReload: false });
      }),
    );

    return models.map((result) => (result.state === 'fulfilled' ? result.value : null));
  }),

  fetchSignals: task(function* () {
    let signals = yield hash({
      topMessages: this.reportingService.fetchStackedSignal(
        {
          debugName: 'top_messages_conversations',
          ...TOP_MESSAGES_PARAMS,
          range: this.range,
        },
        (signal) => signal.transform(topValuesSignalToContextSignal, prefixContextKey('message')),
      ),
      topCustomBots: this.reportingService.fetchStackedSignal(
        {
          debugName: 'top_custom_bot_conversations',
          ...TOP_CUSTOM_BOTS_PARAMS,
          range: this.range,
        },
        (signal) =>
          signal.transform(topValuesSignalToContextSignal, prefixContextKey('operator/custom-bot')),
      ),
      allSignal: this.reportingService.fetchStackedSignal({
        debugName: 'all_messages_and_bots_conversations',
        ...ALL_SIGNAL,
        range: this.range,
      }),
    });

    return {
      combinedTopSignal: signals.topMessages.transform(
        concatAndSortSignalWith(signals.topCustomBots),
        reverse,
      ),
      allSignal: signals.allSignal,
    };
  }),

  fetchSignalsAndModels: task(function* () {
    let { combinedTopSignal, allSignal } = yield this.fetchSignals.perform();

    let modelKeys = combinedTopSignal.context.slice(0, this.topListCount).map((c) => c.key);
    let models = yield this.fetchTopModels.perform(modelKeys);

    let topListContext = combinedTopSignal.context
      .slice(0, this.topListCount)
      .map((bucket, index) => ({
        ...bucket,
        id: models[index] ? models[index].id : null,
        label: models[index] ? models[index].title : keyToLabelFallback(bucket.key),
        linkRoute: models[index] ? modelToRoute(models[index]) : null,
      }));

    let otherRow = {
      key: 'others',
      label: 'All others',
      value: allSignal.originalValue - topListContext.reduce((sum, c) => sum + c.value, 0),
      previousValue:
        allSignal.previousValue -
        topListContext.reduce((sum, c) => sum + (c.previousValue || 0), 0),
    };

    topListContext = [...topListContext, ...(otherRow.value > 0 ? [otherRow] : [])];

    this.set('data', {
      topListContext,
    });
  }),
});
