/* import __COLOCATED_TEMPLATE__ from './chart-title.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
export default class ChartTitle extends Component {
  get chartTitle() {
    return this.args.chart.title ? this.args.chart.title : this.args.renderableChart.description;
  }

  get isStaticReport() {
    return this.args.renderableChart?.reportState?.isStatic;
  }
}
