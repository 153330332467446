/* import __COLOCATED_TEMPLATE__ from './custom-state-dropdown-option.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';
import { action } from '@ember/object';
import type TicketStateService from 'embercom/services/ticket-state-service';

interface CustomStateDropdownOptionArgs {
  item: ICustomStateDropdownOption;
}

interface ICustomStateDropdownOption {
  systemState: TicketSystemState;
  onSelectItem: () => void;
}

interface IconDetails {
  icon: InterfaceIconName;
  iconColor: string;
}

export default class CustomStateDropdownOption extends Component<CustomStateDropdownOptionArgs> {
  @service declare intl: IntlService;
  @service declare ticketStateService: TicketStateService;

  get iconDetails(): IconDetails {
    return this.ticketStateService.getIconForSystemState(this.args.item.systemState);
  }

  @action
  onClick() {
    this.args.item?.onSelectItem();
  }

  get getOptionTitle() {
    let base = 'settings.ticket-states.list-ticket-states.create';
    switch (this.args.item?.systemState) {
      case TicketSystemState.Submitted:
        return `${base}.submitted`;
      case TicketSystemState.InProgress:
        return `${base}.in-progress`;
      case TicketSystemState.WaitingOnCustomer:
        return `${base}.waiting`;
      case TicketSystemState.Resolved:
      default:
        return `${base}.resolved`;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketStates::CustomStateDropdownOption': typeof CustomStateDropdownOption;
    'settings/ticket-states/custom-state-dropdown-option': typeof CustomStateDropdownOption;
  }
}
