/* import __COLOCATED_TEMPLATE__ from './team-office-hours-variant.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class OfficeHoursBotInboxModifier extends Component {
  @service reportingMetrics;

  @action
  toggleOfficeHours() {
    this.args.selectMetric(this.metricIdToUse);
  }

  get metricIdToUse() {
    return this.args.selectedMetric.id === this.currentMetricDefaultId
      ? this.currentMetricVariantId
      : this.currentMetricDefaultId;
  }

  get currentMetricVariantId() {
    return this.reportingMetrics
      .getMetricsByFamilyId(this.args.selectedMetric.metricFamilyId)
      .find((metric) => !metric.metricFamilyDefault).id;
  }

  get currentMetricDefaultId() {
    return this.reportingMetrics
      .getMetricsByFamilyId(this.args.selectedMetric.metricFamilyId)
      .find((metric) => metric.metricFamilyDefault).id;
  }
}
