/* import __COLOCATED_TEMPLATE__ from './fin-rating-remarks-table.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  range?: any;
  renderableChart?: any;
  title?: string;
  titleTooltipText?: string;
}

const FinRatingRemarksTable = templateOnlyComponent<Args>();
export default FinRatingRemarksTable;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Bespoke::FinRatingRemarksTable': typeof FinRatingRemarksTable;
  }
}
