/* import __COLOCATED_TEMPLATE__ from './chart-stackable-bar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
import moment from 'moment-timezone';

const ITEM_WIDTH = 46; //corresponds to max-width set in reporting/_axes.scss
const BAR_WIDTH = 20; //corresponds to width set in reporting/_chart.scss

const valuesAreArrays = ({ context }) => context.every((item) => Array.isArray(item.value));

const convertSignalContextToStack = ({ context }) => {
  return valuesAreArrays({ context })
    ? context
    : context.map(({ key, count, value }) => ({
        key,
        count,
        value: [{ key: 'key', count, value }],
      }));
};

export default Component.extend({
  attributeBindings: ['data-test-reporting-stackable-bar-chart'],
  'data-test-reporting-stackable-bar-chart': true,
  offset: (ITEM_WIDTH - BAR_WIDTH) / 2,
  barScale: computed('width', 'signal.context.length', function () {
    return Math.min(this.width / (this.signal.context.length * (BAR_WIDTH + 10)), 1); //prevent bars from scaling larger than 100% their set width
  }),
  data: computed('signal.context', function () {
    return convertSignalContextToStack(this.signal);
  }),
  series: computed('data', 'signal.timezone', 'xScale', function () {
    if (!this.data || !this.xScale) {
      return;
    }

    return this.data.map((d) => ({
      ...d,
      x: this.xScale(moment.tz(d.key, this.signal.timezone)),
    }));
  }),
});
