/* import __COLOCATED_TEMPLATE__ from './company-priority.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class SeriesSummariesCompanyPriorityComponent extends Component {
  @service seriesEditorService;
  @service attributeService;

  get description() {
    let prioritizer = this.seriesEditorService.series.companyPrioritizer;
    if (prioritizer) {
      let names = prioritizer.prioritizations.map((prioritization) => {
        return this.attributeService.allCompanyAttributes.findBy(
          'identifier',
          prioritization.attribute,
        ).attribute.humanFriendlyName;
      });
      return `Send each message only to the highest priority matching company, prioritized by ${[
        ...names,
        'the oldest company',
      ].join(', and then by ')}`;
    } else {
      return `Send a copy of each message for every matching company`;
    }
  }
}
