/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  classNames: ['flex-auto', 'overflow-auto', 'u__padt__0'],
  reportName: notImplemented(),
  updateRange: notImplemented(),
  range: notImplemented(),
  timezone: notImplemented(),
  appService: service(),
  app: readOnly('appService.app'),
  canFilterByTag: false,
});
