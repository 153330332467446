/* import __COLOCATED_TEMPLATE__ from './implicit-waiting-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  nodeDelayBehaviors,
  defaultDelayTimes,
} from 'embercom/models/data/matching-system/matching-constants';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import { defaultDelayDescription } from 'embercom/lib/series/constants';
export default class SeriesNodeExplicitWaitingSettingsComponent extends Component {
  @service seriesEditorService;
  @service intl;

  get seriesNode() {
    return this.args.seriesNode;
  }

  get hasAlternativePath() {
    return this.args.graphNode.outwardEdges.some((edge) => edge.dataObject.isAlternative);
  }

  get descriptionPrefix() {
    return `Wait up to`;
  }

  get descriptionValue() {
    if (this.seriesNode.isDelayable) {
      return `${this.seriesNode.delayDescription}`;
    } else {
      return defaultDelayDescription;
    }
  }

  get descriptionSuffix() {
    if (this.hasAlternativePath) {
      return this.intl.t('outbound.series.canvas.node.implicit-wait.not-online', {
        descriptionValue: this.descriptionValue,
      });
    } else {
      return this.intl.t('outbound.series.canvas.node.implicit-wait.leave-path', {
        descriptionValue: this.descriptionValue,
      });
    }
  }

  get timeOrDefaultInMilliseconds() {
    if (this.seriesNode.delayBehavior === nodeDelayBehaviors.noDelay) {
      return defaultDelayTimes.implicit * 1000;
    } else {
      return this.seriesNode.maxEvaluationTime * 1000;
    }
  }

  @action disableDelay() {
    this.seriesNode.delayBehavior = nodeDelayBehaviors.noDelay;
    this.seriesNode.maxEvaluationTime = -1;
  }

  @action enableDelay() {
    this.seriesNode.maxEvaluationTime = moment.duration(1, 'week').asSeconds();
    this.seriesNode.delayBehavior = nodeDelayBehaviors.delayable;
  }

  @action updateNodeEvaluationTime(timeInMilliseconds) {
    this.seriesNode.delayBehavior = nodeDelayBehaviors.delayable;
    this.seriesNode.maxEvaluationTime = timeInMilliseconds / 1000;
  }
}
