/* import __COLOCATED_TEMPLATE__ from './save-ticket-type-error-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';

export default class extends Component {
  get dependentObjects() {
    return this.args.dependentObjects;
  }

  get hasDependentCustomBots() {
    return !!(this.dependentObjects?.customBots && this.dependentObjects.customBots.length > 0);
  }

  get hasDependentTicketLinks() {
    return !!(this.dependentObjects?.ticketLinks && this.dependentObjects.ticketLinks.length > 0);
  }

  get hasWebTicketLinks() {
    return !!this.dependentObjects?.ticketLinks?.firstObject?.web_ticket_links_exist;
  }

  get hasMobileTicketLinks() {
    return !!this.dependentObjects?.ticketLinks?.firstObject?.mobile_ticket_links_exist;
  }

  get webMessengerSettingsLink() {
    return 'apps.app.messenger.web';
  }

  get mobileMessengerSettingsLink() {
    return 'apps.app.messenger.mobile-sdk';
  }
}
