/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  name: string | null;
  emoji: string | null;
  class?: string;
}

interface Signature {
  Args: Args;
}

const TicketTypePill = templateOnlyComponent<Signature>();
export default TicketTypePill;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketStates::TicketTypePill': typeof TicketTypePill;
  }
}
