/* import __COLOCATED_TEMPLATE__ from './heatmap.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';

const days = ['Mondays', 'Tuesdays', 'Wednesdays', 'Thursdays', 'Fridays', 'Saturdays', 'Sundays'];

export default Component.extend({
  appService: service(),
  tagName: '',
  maxBucket: readOnly('signal.maxBucket'),
  maxDay: computed('maxBucket.outerKey', function () {
    return days[this.get('maxBucket.outerKey') - 1];
  }),
  _timezone: readOnly('appService.app.timezone', function () {
    return this.get('appService.app.timezone');
  }),
  maxHours: computed('maxBucket.key', '_timezone', function () {
    let date = moment(this.get('maxBucket.key')).tz(this._timezone);
    return date.format('ha');
  }),
});
