/* import __COLOCATED_TEMPLATE__ from './select-ticket-required-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */

import type AttributeMapping from 'embercom/models/crm/attribute-mapping';
import type { MappingVisibility } from 'embercom/models/crm/attribute-mapping';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type IntlService from 'embercom/services/intl';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import type { default as CrmAttribute } from 'embercom/models/crm/attribute';

export interface Args {
  sectionName: string;
  integrationCode: string;
  mapping: AttributeMapping;
  mappingVisibility: MappingVisibility;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SelectTicketRequiredAttribute extends Component<Signature> {
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;
  @service declare intl: IntlService;
  @service declare modalService: any;
  @service declare notificationsService: any;

  get mappingOptions() {
    let mappingAlreadyUsedProps = {
      isDisabled: true,
      tooltipText: this.intl.t(this.translationKey('attribute-already-in-use')),
    };

    let options = this.importConfigurationService.listMappingAlternativesForTicketRequiredAttribute(
      this.args.mapping,
    );

    return options.map((attr) => {
      let selectItem = {
        text: attr.label,
        value: attr,
        icon: 'small-text',
        component: 'settings/data-import/modal/select-ticket-required-attribute-dropdown-option',
        componentAttrs: { type: attr.type },
      };

      // filter assigned attributes
      let alreadyAssigned =
        attr.path !== this.args.mapping.sourceAttributePath && this.attributeAssigned(attr);

      if (alreadyAssigned) {
        return { ...selectItem, ...mappingAlreadyUsedProps };
      }

      return selectItem;
    });
  }

  @action onSelectItem(attr: CrmAttribute) {
    // find a non-required mapping matching the source attribute we're assigning and mark it "assigned-elsewhere"
    let mappingToAssign = this.findMatchingNonRequiredMapping(attr.path);
    if (mappingToAssign !== undefined) {
      mappingToAssign.status = 'assigned-elsewhere';
    }

    // find a non-required mapping matching the source attribute currently assigned and mark it "skip"
    if (isPresent(this.args.mapping.sourceAttributePath)) {
      let mappingPreviouslyAssigned = this.findMatchingNonRequiredMapping(
        this.args.mapping.sourceAttributePath,
      );
      if (mappingPreviouslyAssigned !== undefined) {
        mappingPreviouslyAssigned.status = 'skip';
      }
    }

    this.args.mapping.sourceAttributePath = attr.path;
    this.args.mapping.sourceType = attr.type;
    this.args.mapping.status = 'done';
  }

  get selectedAttribute(): CrmAttribute | undefined {
    return this.importConfigurationService.matchingSourceAttribute(this.args.mapping);
  }

  translate = (path: string) => {
    return this.intl.t(this.translationKey(path));
  };

  private findMatchingNonRequiredMapping(path: string) {
    return this.importConfigurationService
      .listMappings(this.args.sectionName, this.args.mappingVisibility)
      .find(
        (mapping) =>
          mapping.sourceAttributePath === path && mapping.sourceAttributeType !== 'required',
      );
  }

  private attributeAssigned(attr: CrmAttribute): boolean {
    return this.importConfigurationService
      .listMappings(this.args.sectionName, this.args.mappingVisibility)
      .some((mapping) => mapping.sourceAttributePath === attr.path && mapping.status === 'done');
  }

  private translationKey = (path: string) => {
    return `settings.data-import.${this.args.integrationCode}.config-modal.accordion.page.attr-selector.${path}`;
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::SelectTicketRequiredAttribute': typeof SelectTicketRequiredAttribute;
    'settings/data-import/modal/select-ticket-required-attribute': typeof SelectTicketRequiredAttribute;
  }
}
