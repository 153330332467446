/* import __COLOCATED_TEMPLATE__ from './single-stat.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import PALETTE from '@intercom/pulse/lib/palette';

export default Component.extend({
  attributeBindings: ['data-test-single-stat'],
  'data-test-single-stat': true,
  iconColor: computed('color', function () {
    return PALETTE[this.color];
  }),
});
