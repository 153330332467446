/* import __COLOCATED_TEMPLATE__ from './step1-seat-type.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';

export default class Step1SeatType extends Component {
  get inlineSvgPath() {
    return `upgrade/${this.args.seatType}-small.svg`;
  }

  get isSelected() {
    return this.args.selected === this.args.seatType;
  }

  get headingKey() {
    return `settings.seats.${this.args.seatType}-seat`;
  }

  get descriptionKey() {
    return `components.seatless-experience.step1-select-seat.${this.args.seatType}-description`;
  }

  get isDisabled() {
    return !this.args.availableSeatTypes.includes(this.args.seatType);
  }
}
