/* import __COLOCATED_TEMPLATE__ from './time-period-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { readOnly, equal, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { ternary } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  tagName: '',
  appService: service(),
  app: readOnly('appService.app'),

  defaultStartTime: 9 * 60,
  defaultEndTime: 17 * 60,

  computedAddFirstRowText: or('addFirstRowText', 'addRowText'),
  addRowText: 'Add hours',
  store: service(),
  hasSingleRow: equal('rows.length', 1),
  rowRemovalAction: ternary('hasSingleRow', 'removeLastRow', 'removeRow'),
  disabled: false,

  actions: {
    addRow() {
      if (this.disabled) {
        return;
      }

      let defaultTimes = this.store.createFragment('settings/time-period', {
        startTime: this.defaultStartTime,
        endTime: this.defaultEndTime,
      });
      this.rows.pushObject(defaultTimes);

      let onRowAdded = this.onRowAdded;
      if (onRowAdded) {
        onRowAdded();
      }
    },

    removeRow(index) {
      if (this.disabled) {
        return;
      }
      this.rows.removeAt(index);

      let onRowRemoved = this.onRowRemoved;
      if (onRowRemoved) {
        onRowRemoved(index);
      }
    },

    async removeLastRow() {
      if (this.disabled) {
        return;
      }
      let onBeforeRemoveLastRow = this.onBeforeRemoveLastRow;
      let success = !onBeforeRemoveLastRow || (await onBeforeRemoveLastRow());
      if (success) {
        this.send('removeRow', 0);
      }
    },
  },
});
