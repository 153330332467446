/* import __COLOCATED_TEMPLATE__ from './orderable-chart-layout.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { runDisposables, throttleTask } from 'ember-lifeline';
import { inject as service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import { isEqual, without } from 'underscore';
import { modifier } from 'ember-modifier';

export default class OrderableChartLayout extends Component {
  @service realTimeEventService;
  @service reportingChartService;
  @service appService;

  @tracked draggedChart = null;
  @tracked hoveredChartIds = [];
  chartOrderOnDragStart = [];
  @tracked showPropagationFinishedBanner = false;

  chartElements = [];

  constructor() {
    super(...arguments);
    this.realTimeEventService.subscribeTopics(['ConversationTopicPropagatedStatusChanged']);
    this.setupDropZone();

    registerDestructor(this, () => {
      runDisposables(this);
      let dropZone = document.querySelector(this.args.dropZoneSelector);
      if (dropZone) {
        dropZone.removeEventListener('dragover', this.stopPropagation);
        dropZone.removeEventListener('drop', this.onDrop);
      }
    });
  }

  setupDropZone() {
    let dropZone = document.querySelector(this.args.dropZoneSelector);
    if (dropZone) {
      dropZone.addEventListener('dragover', this.stopPropagation);
      dropZone.addEventListener('drop', this.onDrop);
    }
  }

  @action
  updateShowPropagationFinishedBanner() {
    this.showPropagationFinishedBanner = true;
  }

  @action
  reloadPage() {
    window.location.reload();
  }

  resetColors = modifier((_, [v]) => {
    this.reportingChartService.resetColors();
  });

  get hasCharts() {
    return this.args.charts?.length > 1;
  }

  get editMode() {
    if (this.args.editMode === undefined) {
      return true;
    }
    return this.args.editMode;
  }
  get draggable() {
    if (this.appService.app.canSeeR2Beta) {
      return this.hasCharts && this.editMode;
    }
    return this.hasCharts;
  }

  get isDragging() {
    return this.draggedChart !== null;
  }

  get dragSource() {
    return this.draggedChart ? this._indexOfChartWithId(this.draggedChart.id) : null;
  }

  get dragDestination() {
    // The nearest hovered chart is the destination
    let index = this._indexOfChartWithId(this.hoveredChartIds[0]);
    return index > -1 ? index : null;
  }

  get shouldSwitchPosition() {
    return (
      this.dragSource !== null &&
      this.dragDestination !== null &&
      this.dragSource !== this.dragDestination
    );
  }

  get hoveredChartIdsExludingDragged() {
    return without(this.hoveredChartIds, this.draggedChart?.id);
  }

  chartsAreColliding(box, event) {
    let innerBoxMultiplier = 0.25;
    let draggedElementPos = event.currentTarget.getBoundingClientRect();
    let handleWidthOffset = event.target.offsetWidth + 20; // width of handle button + box margin
    let heightOfCounterChart = 158;

    // left side = static chart pos, right side = current dragging position
    return (
      box.x + box.width * innerBoxMultiplier < event.clientX + handleWidthOffset &&
      box.x + box.width * (1 - innerBoxMultiplier) >
        event.clientX - draggedElementPos.width + handleWidthOffset &&
      box.y + heightOfCounterChart * innerBoxMultiplier <
        event.clientY + draggedElementPos.height - event.target.offsetTop &&
      box.y + heightOfCounterChart * (1 - innerBoxMultiplier) >
        event.clientY - event.target.offsetTop
    );
  }

  _orderedCollidingChartIds(dragEvent) {
    let chartElements = Array.from(document.querySelectorAll('[draggable-chart-card-id]').values());
    let collidingChartElements = chartElements.filter((element) => {
      return this.chartsAreColliding(element.getBoundingClientRect(), dragEvent);
    });

    let draggedChartElement = chartElements.find(
      (element) => element.getAttribute('draggable-chart-card-id') === this.draggedChart.id,
    );

    // Sort based on distance to the dragged chart, using centroid distance
    // This leaves the first result as the closest colliding chart
    collidingChartElements.sort((a, b) => {
      return (
        this._centroidDistance(draggedChartElement, a) -
        this._centroidDistance(draggedChartElement, b)
      );
    });

    return collidingChartElements.map((element) => element.getAttribute('draggable-chart-card-id'));
  }

  _centroidDistance(element1, element2) {
    let [x1, y1] = this._centroid(element1);
    let [x2, y2] = this._centroid(element2);
    return Math.hypot(x1 - x2, y1 - y2);
  }

  _centroid(element) {
    let { x, y, width, height } = element.getBoundingClientRect();
    return [x + width / 2, y + height / 2];
  }

  _indexOfChartWithId(chartId) {
    return this.args.charts.toArray().findIndex((chart) => chart.id === chartId);
  }

  updateHoveredCharts(event) {
    this.hoveredChartIds = this._orderedCollidingChartIds(event);

    if (this.shouldSwitchPosition) {
      this.args.reorderCharts(this.dragSource, this.dragDestination);
      // Remove all calulated hovers so chart highlighting gets reset,
      // and just moved charts don't bring their highlight with them
      this.hoveredChartIds = [];
    }
  }

  @action
  beforeDragStarts() {
    let chartContainer = document.querySelector('[data-chart-container]');
    chartContainer.style.minHeight = `${chartContainer.scrollHeight}px`;
    chartContainer.style.alignContent = 'flex-start';
  }

  @action
  onDragStart(chart, event) {
    this.draggedChart = chart;
    this.chartOrderOnDragStart = this.args.charts.map(({ id }) => id);
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'move';
      let currentTarget = event.currentTarget.children[0].children[0];
      event.dataTransfer.setDragImage(
        currentTarget,
        currentTarget.offsetWidth - event.target.offsetWidth,
        event.target.offsetHeight,
      );
    }
    event.stopPropagation();
  }

  @action
  onDragMove(event) {
    throttleTask(this, 'updateHoveredCharts', event, 300);
  }

  @action
  stopPropagation(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  @action
  onDrop() {
    let currentChartOrder = this.args.charts.map(({ id }) => id);
    if (!isEqual(currentChartOrder, this.chartOrderOnDragStart)) {
      this.args.saveReport.perform();
    }
  }

  @action
  onDragEnd() {
    document.querySelector('[data-chart-container]').style.removeProperty('min-height');
    document.querySelector('[data-chart-container]').style.removeProperty('align-content');
    this.hoveredChartIds = [];
    this.draggedChart = null;
  }
}
