/* import __COLOCATED_TEMPLATE__ from './suggested-topic-empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SuggestedTopicEmptyState extends Component {
  @service router;
  @service intercomEventService;

  @action
  onNewTopicClick() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'empty_state',
      object: 'new_topic',
      place: 'suggested_topics',
    });

    this.router.transitionTo('apps.app.reports.conversational-insights.topics.new');
  }
}
