/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import IcMultiselect from '@intercom/pulse/components/ic-multiselect';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

/*
    === ⚠️ Disclamer: do not copy/reuse this code pattern ⚠️ ===
    Subclassing components should not be the norm, this is a temporary workaround the limitations of the IcMultiselect base class.
    See this comment for more information: https://github.com/intercom/embercom/pull/43872/files#r423822788
*/
export default class ReportingMultiselect extends IcMultiselect {
  // Deactivate the selectAll when any other options changes
  onSelectionChange(values) {
    //Due to a strange behavior of ic-select, values does not have all selected items the first time the dropdown is open. Using selectedItems directly is more accurate
    let selectedValues = this.selectedItems.map((item) => item.value);

    if (this.isSelectAllActive !== this.shouldSelectAllBeActive) {
      this.groupTree.toggleSelectItemByValue(REPORTING_FILTER_SELECT_ALL);
    }
    if (this.shouldSelectAllBeActive) {
      this.executePropertyIfItIsAFunction('onSelectionChanged', [REPORTING_FILTER_SELECT_ALL]);
    } else {
      this.executePropertyIfItIsAFunction(
        'onSelectionChanged',
        selectedValues.filter((v) => v !== REPORTING_FILTER_SELECT_ALL),
      );
    }
  }

  get isSelectAllActive() {
    return !!this.selectedItems.find((item) => item.text === this.selectAllLabel);
  }

  get shouldSelectAllBeActive() {
    return (
      !this.selectedItems ||
      this.selectedItems?.length === 0 ||
      (this.selectedItems?.length === 1 && this.selectedItems[0].text === this.selectAllLabel)
    );
  }

  buildSelectAllItem(isSelected) {
    return {
      hasItemsMarkableAsSelected: true,
      items: [
        {
          value: REPORTING_FILTER_SELECT_ALL,
          text: this.selectAllLabel,
          onSelectItem: this.onSelectAllClick(),
          isSelected,
        },
      ],
    };
  }

  // Add the selectAll Group to the list of renderable items
  set displayItems(_items) {
    let items = _items || [];
    let hasItemsSelected = items[0]?.items?.some((item) => item.isSelected);
    let selectAllItem = this.buildSelectAllItem(!hasItemsSelected);
    if (this.selectAllLabel) {
      items = [selectAllItem].concat(items);
    }

    this.set('items', items);
  }

  // Ensure selectAll stays active onClick and reset any other options
  onSelectAllClick() {
    let ctx = this;
    return function () {
      this.set('isSelected', true);
      ctx.selectedItems
        ?.filter((item) => item.value !== REPORTING_FILTER_SELECT_ALL)
        ?.forEach((item) => ctx.groupTree.toggleSelectItemByValue(item.value));
      ctx.setSelectedItemByValue(REPORTING_FILTER_SELECT_ALL);
      ctx.executePropertyIfItIsAFunction('onSelectionChanged', [REPORTING_FILTER_SELECT_ALL]);
      event.stopPropagation();
    };
  }
}
