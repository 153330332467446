/* import __COLOCATED_TEMPLATE__ from './tag-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

export default class TagFilter extends Component {
  @service appService;
  @service intercomEventService;
  @tracked tags = this.appService.app.tags;
  @tracked label = this.currentLabel;

  // store the selection here so that the UI doesn't update until the drop down is closed
  selectedDropdownItems = this.args.selected;

  get items() {
    return [
      {
        heading: 'Tags',
        items: this.allItems,
      },
    ];
  }

  get selectedTags() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get allItems() {
    return this.tags.map((tag) => ({
      text: tag.name,
      value: tag.id,
      isSelected: this.args.selected.includes(tag.id),
    }));
  }

  get currentLabel() {
    if (this.selectedTags.length === 0) {
      return 'is Any';
    }

    let tagNames = this.selectedTags.map((tagId) => {
      return this.tags.find((tag) => tag.id === tagId).name;
    });
    return `is ${tagNames.join(', ')}`;
  }

  @action
  onClose() {
    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_tag',
      object: this.args.analyticsObject,
    });

    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }
}
