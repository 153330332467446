/* import __COLOCATED_TEMPLATE__ from './metric-selector.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { cached } from 'tracked-toolbox';

export default class MetricSelector extends Component {
  @cached
  get itemsWithComponentOverride() {
    return this.overrideItemComponents(this.args.items);
  }

  overrideItemComponents(items) {
    return items.map((item) => this.overrideItemComponent(item));
  }

  overrideItemComponent(item) {
    if (item.items) {
      return Object.assign(item, { items: this.overrideItemComponents(item.items) });
    }
    if (item.component) {
      return item;
    }
    return Object.assign(item, {
      component: 'reporting/custom/chart-builder/metric-picker/metric-item',
    });
  }
}
