/* import __COLOCATED_TEMPLATE__ from './default-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

const VIEW_BY_TRANSLATIONS = {
  time: 'reporting.view-by.time',
  conversation_tag_ids: 'reporting.view-by.conversation_tag_ids',
  team_assignee_id: 'reporting.view-by.team_assignee_id',
  topic: 'reporting.view-by.topic',
  reason: 'reporting.view-by.away-reason',
};

export default class DefaultChart extends Component {
  @service router;
  @service reportingMetrics;
  @service appService;
  @service intl;

  get chartConfig() {
    return {
      viewBy: this.args.viewBy,
      metricId: this.reportingMetrics.getMetricById(this.args.metricId).id, // allow for migration to new metric ids
    };
  }

  get metricName() {
    return this.reportingMetrics.getMetricById(this.args.metricId).name;
  }

  get viewBy() {
    if (!(this.args.viewBy in VIEW_BY_TRANSLATIONS)) {
      throw new Error(`Translation for viewBy ${this.args.viewBy} not found`);
    }

    return this.intl.t(VIEW_BY_TRANSLATIONS[this.args.viewBy]);
  }

  get route() {
    return `apps.app.reports.custom-reports.report.${this.args.isNew ? 'new' : 'show'}.chart.new`;
  }

  @action
  onClick() {
    this.args.trackAnalyticsEvent({
      action: 'clicked',
      object: 'add_chart',
      button_position: 'empty_state_template_chart',
    });
    this.router.transitionTo(this.route, {
      queryParams: {
        ...this.chartConfig,
      },
    });
  }
}
