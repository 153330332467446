/* import __COLOCATED_TEMPLATE__ from './string-filter-options.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { createDropdownGroupFromGroupList } from '@intercom/pulse/lib/dropdown-group';
import { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';

const SELECTED_ITEMS_GROUP_THRESHOLD = 50;
const DROPDOWN_ITEM_HEIGHT = 36;
const DROPDOWN_HEADING_HEIGHT = 51;

export default class StringFilterOptions extends Component {
  @service appService;
  @service intl;

  searchEnabled = this.args.enableSearch === undefined ? true : this.args.enableSearch;
  numItemsBeforeFiltering = null;
  minWidthOfOverlay = this.args.minWidthOfOverlay || 215;

  get groupTree() {
    return createDropdownGroupFromGroupList(this.internalItems);
  }

  get internalItems() {
    let groupList = [];

    if (
      this.args.selectedValues.length > 0 &&
      this.initialNumberOfItems >= SELECTED_ITEMS_GROUP_THRESHOLD
    ) {
      let items = this.args.selectedValues.map((i) => ({
        text: this.args.items.firstObject.items.find((item) => item.value === i)?.text || i,
        value: i,
        isSelected: true,
      }));

      let heightOfSelectedItems = items.length
        ? items.length * DROPDOWN_ITEM_HEIGHT + DROPDOWN_HEADING_HEIGHT
        : 0;

      groupList.push({
        heading: this.intl.t(
          'components.reporting.custom.chart-builder.filter-bar.string-filter-options.selected-items',
        ),
        isMain: true,
        isFilterable: false,
        items,
        hasItemsMarkableAsSelected: true,
        // eslint-disable-next-line @intercom/intercom/no-bare-strings
        style: `min-height: min(${heightOfSelectedItems}px, 25vh);`,
      });
    }

    groupList.push({
      isMain: true,
      isFilterable: false,
      items: this.args.items.firstObject.items,
      hasItemsMarkableAsSelected: true,
    });

    return {
      items: groupList,
      selectedCountLimit: 400,
      isFilterable: false,
    };
  }

  get initialNumberOfItems() {
    return this.numItemsBeforeFiltering || this.args.items.firstObject.items.length;
  }

  @action
  onOpen() {
    this.runFilterValues('');
  }

  @action
  selectItem({ value }) {
    this.args.onSelectionChange(this.toggleSelectedItem(value));
  }

  toggleSelectedItem(value) {
    return this.args.selectedValues.includes(value)
      ? this.args.selectedValues.filter((i) => i !== value)
      : [...this.args.selectedValues, value];
  }

  @action
  debouncedFilterValues(value) {
    debounce(this, this.runFilterValues, value, ENV.APP._500MS);
  }

  runFilterValues(filter) {
    if (this.numItemsBeforeFiltering === null) {
      this.numItemsBeforeFiltering === this.args.items.firstObject.items.length;
    }
    if (this.args.filterValues.perform) {
      // This is a task
      this.args.filterValues.perform(filter);
    } else {
      this.args.filterValues(filter);
    }
  }
}
