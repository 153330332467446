/* import __COLOCATED_TEMPLATE__ from './default-predicates-editor.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { action } from '@ember/object';

export default class DefaultPredicatesEditor extends Component {
  @service appService;
  @service attributeService;
  @service store;

  @tracked hidePredicateEditor = isEmpty(this.defaultPredicatesGroup?.predicates);

  get app() {
    return this.appService.app;
  }

  get attributeGroupList() {
    let groups = [];

    groups.push({
      heading: 'Person Data',
      attributes: this.attributeService.filterableUserAttributesForMessaging,
    });

    if (this.app.companiesActive) {
      groups.push({
        heading: 'Company Data',
        attributes: this.attributeService.filterableCompanyAttributesForMessaging,
      });
    }

    return groups;
  }

  get role() {
    if (isEmpty(this.args.permissionsObject.role_id)) {
      return null;
    }
    return this.store.peekRecord('role', this.args.permissionsObject.role_id);
  }

  get defaultPredicatesGroup() {
    return isEmpty(this.args.permissionsObject.defaultPredicatesGroup)
      ? this.role?.defaultPredicatesGroup
      : this.args.permissionsObject.defaultPredicatesGroup;
  }

  @action
  onClickHandler() {
    if (this.hidePredicateEditor) {
      this.hidePredicateEditor = false;
    } else {
      this.hidePredicateEditor = true;
      this.args.permissionsObject.defaultPredicatesGroup.predicates = [];
    }
  }
}
