/* import __COLOCATED_TEMPLATE__ from './accordion.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  isOpen: boolean;
  itemId: string | number;
  onToggle: (event: ToggleEvent & { target: HTMLElement }) => void;
}

interface Signature {
  Args: Args;
  Blocks: {
    header: [];
    actionButtons: [];
    description: [];
    body: [];
  };
}

const Accordion = templateOnlyComponent<Signature>();
export default Accordion;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Accordion': typeof Accordion;
  }
}
