/* import __COLOCATED_TEMPLATE__ from './edit.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class TopicEditComponent extends Component {
  @service conversationalInsights;
  @service intercomEventService;

  @tracked botInboxTime;
  @tracked removedConversationIds = [];
  @tracked showExcludedKeyphrases = this.hasExcludedKeyphrases;

  get hasExcludedKeyphrases() {
    return this.args.topic.excludedKeyphrases.length > 0;
  }

  get botInboxTimeExcluded() {
    return this.botInboxTime === 'excluded';
  }

  @action
  updateRange(range) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered',
      object: 'conversations',
      place: 'topics',
    });

    this.conversationalInsights.updateRange(range);
  }
}
