/* import __COLOCATED_TEMPLATE__ from './awareness-banner.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Args {
  hasDismissedBanner: boolean;
  awarenessBannerKey: string;
}

export default class AwarenessBanner extends Component<Args> {
  @tracked hasDismissedBanner = this.args.hasDismissedBanner;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::AwarenessBanner': typeof AwarenessBanner;
  }
}
