/* import __COLOCATED_TEMPLATE__ from './inbox-rules-disabled.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class InboxRulesDisabledModal extends Component {
  @service modalService;
  @service router;

  @action closeModal() {
    this.modalService.closeModal();
  }

  @action async closeModalAndTransitionToWorkflows() {
    await this.router.transitionTo('apps.app.automation.workflows-overview');
    this.closeModal();
  }
}
