/* import __COLOCATED_TEMPLATE__ from './segment-search-component.hbs'; */
/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { ternary, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  appService: service(),
  classNames: ['flex-none'],
  preventDropdownToggleFromWithin: true,
  isCompanySearch: readOnly('activeSegment.isCompany'),
  placeholderText: ternaryToProperty(
    'isCompanySearch',
    'companyPlaceholderText',
    'peopleOrUsersPlaceholderText',
  ),
  iconForStandardSearch: ternary('isCompanySearch', 'company', 'person'),
  standardSearchObject: ternary('isCompanySearch', 'Companies', 'People'),
  companyPlaceholderText: 'Search companies and tags…',
  peopleOrUsersPlaceholderText: 'Search people and tags…',

  actions: {
    resetSearch() {
      this.search.reset();
      this.set('searchController.searchTerm', '');
    },
  },
});
