/* import __COLOCATED_TEMPLATE__ from './highcharts-debugger.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type Options, type SeriesOptionsType } from 'highcharts';
import { type Signature } from './highcharts-wrapper';

function parseValueFrom(event: Event) {
  return JSON.parse((event.target as HTMLInputElement).value);
}

export default class HighchartsDebugger extends Component<Signature> {
  @tracked declare highchartsOptions: Options;
  @tracked declare highchartsSeries: SeriesOptionsType[];

  @tracked declare highchartsOptionsError: boolean;
  @tracked declare highchartsSeriesError: boolean;

  @tracked showModal = false;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.reset();
  }

  reset() {
    this.highchartsOptions = this.args.highchartsOptions;
    this.highchartsSeries = this.args.highchartsSeries;
    this.highchartsOptionsError = false;
    this.highchartsSeriesError = false;
  }

  @action
  highchartsOptionsChanged(event: Event) {
    this.highchartsOptionsError = false;
    try {
      this.highchartsOptions = parseValueFrom(event);
    } catch (error) {
      this.highchartsOptionsError = true;
      console.error(error);
    }
  }

  @action
  highchartsSeriesChanged(event: Event) {
    this.highchartsSeriesError = false;
    try {
      this.highchartsSeries = parseValueFrom(event);
    } catch (error) {
      this.highchartsSeriesError = true;
      console.error(error);
    }
  }

  @action
  closeModal() {
    this.showModal = false;
    this.reset();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Flexible::HighchartsDebugger': typeof HighchartsDebugger;
    'reporting/flexible/highcharts-debugger': typeof HighchartsDebugger;
  }
}
