/* import __COLOCATED_TEMPLATE__ from './schedule-modal.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { set } from '@ember/object';
import WeekDays from 'embercom/lib/reporting/week-days';
import formatTimezoneToUTC from 'embercom/lib/format-timezone-to-utc';
import { getOwner } from '@ember/application';

export default class ExportScheduleModalComponent extends Component {
  weekDays = new WeekDays(getOwner(this)).values;

  @service appService;
  @service notificationsService;
  @service store;
  @service intl;

  frequencies = this.frequencies;
  days = this.weekDays.map((name, i) => ({ text: name, value: i }));

  constructor() {
    super(...arguments);

    this._loadHours();
    this.monthlyDays = Array.from(Array(28), (d, i) => ({
      text: this.intl.t(
        'components.reporting.customer-support.export.schedule-modal.frequencies.monthly-days.on-a-given-day',
        {
          dayNumber: i + 1,
        },
      ),
      value: i + 1,
    }));
    this.monthlyDays.push({
      text: this.intl.t(
        'components.reporting.customer-support.export.schedule-modal.frequencies.monthly-days.on-a-last-day',
      ),
      value: 32,
    });
    // Settings the icSelect groups
    this.hoursGroup = [{ items: this.hours, isFilterable: false }];
    this.monthlyDaysGroup = [{ items: this.monthlyDays, isFilterable: false }];
    this.readOnlyDayGroup = [
      {
        text: this.intl.t(
          'components.reporting.customer-support.export.schedule-modal.frequencies.every-day',
        ),
        value: null,
      },
    ];

    this.readOnlyDay = null;
  }

  get frequencies() {
    return [
      {
        text: this.intl.t(
          'components.reporting.customer-support.export.schedule-modal.frequencies.daily',
        ),
        value: 'daily',
      },
      {
        text: this.intl.t(
          'components.reporting.customer-support.export.schedule-modal.frequencies.weekly',
        ),
        value: 'weekly',
      },
      {
        text: this.intl.t(
          'components.reporting.customer-support.export.schedule-modal.frequencies.monthly',
        ),
        value: 'monthly',
      },
    ];
  }

  get isMonthlyFrequency() {
    return Boolean(this.args.scheduleToEdit.dayOfMonth);
  }

  get isWeeklyFrequency() {
    return (
      this.args.scheduleToEdit.dayOfWeek !== null &&
      this.args.scheduleToEdit.dayOfWeek !== undefined
    );
  }

  get cadence() {
    if (this.isMonthlyFrequency) {
      return 'monthly';
    } else if (this.isWeeklyFrequency) {
      return 'weekly';
    } else {
      return 'daily';
    }
  }

  _loadHours() {
    let timezoneString = formatTimezoneToUTC(this.appService.app.timezone);
    this.hours = Array.from(Array(24), (_, i) => ({
      text: `${moment({ hour: i }).format('h:mmA')} ${timezoneString}`,
      value: i,
    }));
  }

  @action
  submitSchedule() {
    this.args.submitSchedule();
    this.args.closeScheduleModal();
  }

  @action
  updateScheduleFromCadence(cadence) {
    switch (cadence) {
      case 'monthly':
        if (!this.args.scheduleToEdit.dayOfMonth) {
          set(this, 'args.scheduleToEdit.dayOfMonth', this.monthlyDays[0].value);
        }
        set(this, 'args.scheduleToEdit.dayOfWeek', null);
        break;
      case 'weekly':
        if (!this.args.scheduleToEdit.dayOfWeek) {
          set(this, 'args.scheduleToEdit.dayOfWeek', 0);
        }
        set(this, 'args.scheduleToEdit.dayOfMonth', null);
        break;
      case 'daily':
        set(this, 'args.scheduleToEdit.dayOfWeek', null);
        set(this, 'args.scheduleToEdit.dayOfMonth', null);
        break;
    }
  }

  @action
  cancelSchedule() {
    this.args.closeScheduleModal();
    this.args.resetSchedule();
  }
}
