/* import __COLOCATED_TEMPLATE__ from './description.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';

export default class Description extends Component {
  get locale() {
    return this.args.translationSettings?.possibleLocales.findBy(
      'localeId',
      this.args.translation.locale,
    );
  }
}
