/* import __COLOCATED_TEMPLATE__ from './provisioning-form.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { SAML_PROVISIONING_METHODS } from 'embercom/controllers/apps/app/settings/security';

export default class ProvisioningForm extends Component {
  @service intl;
  @service appService;
  @service notificationsService;
  @service intercomConfirmService;

  @tracked scimEnabled = isPresent(this.args.scimSettings);
  @tracked showTeammateFilter = isPresent(this.args.scimSettings.immuneAdminIds);

  @action
  hideFilterIfNoAdminsSelected() {
    if (isEmpty(this.args.scimSettings.immuneAdminIds)) {
      this.showTeammateFilter = false;
    }
  }

  @action
  async confirmTokenRegeneration() {
    if (await this.confirmApplyChanges()) {
      this.regenerateToken();
    }
  }

  async regenerateToken() {
    try {
      await this.args.scimSettings.appPackageInstall.regenerateScimToken();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.security.provisioning-form.new-api-notify'),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('settings.security.provisioning-form.problem-regenerating-error'),
      );
    }
  }

  async confirmApplyChanges() {
    try {
      return await this.intercomConfirmService.confirm({
        title: this.intl.t('settings.security.provisioning-form.generate-new-api'),
        confirmButtonText: this.intl.t('settings.security.provisioning-form.generate-new-token'),
        body: this.intl.t('settings.security.provisioning-form.new-token-description'),
      });
    } catch (error) {
      this.notificationsService.notifyError(error.message);
    }
  }

  @action
  resetShowTeammateFilter() {
    this.showTeammateFilter = isPresent(this.args.scimSettings.immuneAdminIds);
  }

  get selectedProvisioningMethod() {
    if (this.scimEnabled) {
      return SAML_PROVISIONING_METHODS.scim;
    } else if (this.args.samlAccount.jitEnabled) {
      return SAML_PROVISIONING_METHODS.jit;
    } else {
      return SAML_PROVISIONING_METHODS.none;
    }
  }

  get humanAdmins() {
    return this.appService.app.humanAdmins;
  }

  get teammateFilterItems() {
    return this.humanAdmins.map((teammate) => ({
      assignee: teammate,
      text: teammate.display_as_assignee,
      value: teammate.id.toString(),
      component: 'admin/new-selector-item',
      isSelected: this._isImmuneAdmin(teammate),
    }));
  }

  get possibleAssignees() {
    return this.appService.app.assignableCreators;
  }

  get possibleAssigneesForConversations() {
    return this.appService.app.assignableAdmins.filter((t) => !t.isTeam);
  }

  get currentLabel() {
    if (this.args.scimSettings.immuneAdminIds.length === 0) {
      return this.intl.t('settings.security.provisioning-form.none');
    }

    let teammateNames = this.args.scimSettings.immuneAdminIds.map((teammateId) => {
      // eslint-disable-next-line eqeqeq
      let teammate = this.humanAdmins.find((teammate) => teammate.id == teammateId);
      return teammate
        ? teammate.display_as_assignee
        : this.intl.t('settings.security.provisioning-form.deleted-teammate');
    });
    return teammateNames.join(', ');
  }

  _isImmuneAdmin(teammate) {
    return this.args.scimSettings.immuneAdminIds.some(
      // eslint-disable-next-line eqeqeq
      (immuneAdminId) => teammate.id == immuneAdminId,
    );
  }
}
