/* import __COLOCATED_TEMPLATE__ from './ticket-type-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { task } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';
import { all } from 'ember-concurrency';
import { mapValueToLabel } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import intermoji from '@intercom/intermoji';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

const TICKET_PROPERTY_IDENTIFIER = 'ticket_type_id';

export default class TicketTypeFilter extends Component {
  @service appService;
  @service store;
  @service intercomEventService;
  @service intl;
  @tracked label = this.currentLabel;
  @tracked availableTicketTypes = [];
  // store the selection here so that the UI doesn't update until the drop down is closed
  selectedDropdownItems = this.startedSelectedValues;
  ticketTypes = [];

  get startedSelectedValues() {
    if (Array.isArray(this.args.selected)) {
      return this.args.selected;
    }
    return this.args.selected.values || [];
  }

  constructor() {
    super(...arguments);
    this.initializeValues.perform();
  }

  removeInvalidValuesFromSelection() {
    let before = this.selectedTicketTypes;
    let after = before.filter((string) => this.availableTicketTypes.includes(string));
    if (after.length === before.length) {
      return;
    }
    if (after.length === 0) {
      this.removeFilter();
      return;
    }
    this.args.setSelected(after);
  }

  findEmojiUriByTicketId(id) {
    let ticketType = this.ticketTypes.find((ticket) => ticket.id === id);
    if (ticketType) {
      return intermoji.twemojiSVGUri(ticketType.emoji);
    }
  }
  findCategoryByTicketId(id) {
    let ticketType = this.ticketTypes.find((ticket) => ticket.id === id);
    if (ticketType) {
      switch (ticketType.category) {
        case TicketCategory.Request:
          return this.intl.t('operator.workflows.visual-builder.send-ticket-menu.category.request');
        case TicketCategory.Task:
          return this.intl.t('operator.workflows.visual-builder.send-ticket-menu.category.task');
        case TicketCategory.Tracker:
          return this.intl.t('operator.workflows.visual-builder.send-ticket-menu.category.tracker');
      }
    }
  }

  @task({ restartable: true }) *initializeValues() {
    let knownValues = yield this.fetchKnownValues.perform();
    this.availableTicketTypes = Array.from(knownValues);
    this.removeInvalidValuesFromSelection();
  }

  @task({ restartable: true }) *fetchKnownValues() {
    let sources = this.args.loadKnownValuesSources || ['conversation'];
    let childTasks = sources.map((source) => this.fetchData.perform(source));
    let responses = yield all(childTasks);
    this.ticketTypes = yield this.fetchCompleteTicketTypes.perform();
    return new Set(responses.flat().map((ticketTypeId) => parseInt(ticketTypeId, 10)));
  }

  @task({ enqueue: true, maxConcurrency: 12 }) *fetchData(source) {
    return yield get('/ember/reporting/known_values', {
      app_id: this.appService.app.id,
      source,
      property: TICKET_PROPERTY_IDENTIFIER,
    });
  }

  @task({ restartable: true }) *fetchCompleteTicketTypes() {
    return yield get(`/ember/inbox/ticket_types?app_id=${this.appService.app.id}`);
  }

  get items() {
    return this.groupList;
  }

  get selectedTicketTypes() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get allItems() {
    if (this.availableTicketTypes === null) {
      return [];
    }

    return this.availableTicketTypes
      .map((ticketTypeId) => ({
        text: mapValueToLabel(TICKET_PROPERTY_IDENTIFIER, ticketTypeId?.toString()),
        value: ticketTypeId,
        isSelected: this.selectedDropdownItems.includes(ticketTypeId),
        category: this.findCategoryByTicketId(ticketTypeId),
        component: 'common/select-ticket-type-item',
        label: mapValueToLabel(TICKET_PROPERTY_IDENTIFIER, ticketTypeId?.toString()),
        emojiUri: this.findEmojiUriByTicketId(ticketTypeId),
      }))
      .sortBy('text');
  }

  get groupList() {
    return [
      {
        items: this.allItems,
      },
    ];
  }

  get currentLabel() {
    if (
      this.selectedDropdownItems?.length &&
      this.selectedDropdownItems[0] !== REPORTING_FILTER_SELECT_ALL
    ) {
      return this.selectedDropdownItems
        .map((value) => mapValueToLabel(TICKET_PROPERTY_IDENTIFIER, value?.toString()))
        .join(', ');
    } else {
      return this.intl.t('reporting.filter-bar.select-all-label');
    }
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'ticket_type_filter',
      object: this.args.analyticsObject,
      ticket_types: this.selectedTicketTypes,
    });

    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }
}
