/* import __COLOCATED_TEMPLATE__ from './country-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { get } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { lookupCountryName } from 'embercom/lib/countries';
import { all } from 'ember-concurrency';
import { copy } from 'ember-copy';

export default class CountryFilter extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;
  @tracked availableCountries = [];
  @tracked label = this.currentLabel;
  // store the selection here so that the UI doesn't update until the drop down is closed
  @tracked selectedDropdownItems = this.args.selected.values || [];
  @tracked operator = this.args.selected.operator || 'category';
  // We transform the values back and forth from a list of country to a list of names of country
  // We do this to link a label (Russia) to its multiple values (Russia, Russian Federation) in the backend

  cachedKnownCountryCodes = [];

  constructor() {
    super(...arguments);
    this.initializeValues.perform();
  }

  get groupList() {
    return [
      {
        heading: this.intl.t(
          'components.reporting.custom.chart-builder.filter-bar.country-filter.heading',
        ),
        items: this.allItems,
      },
    ];
  }

  get allItems() {
    return this.availableCountries.map((countryCode) => this.makeItem(countryCode));
  }

  makeItem(countryCode) {
    return {
      text: lookupCountryName(countryCode),
      value: countryCode,
      icon: 'globe',
      isSelected: this.selectedDropdownItems.includes(countryCode),
    };
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_country',
      object: this.args.analyticsObject,
      countries: this.selectedCountries,
      operator: this.operator,
    });
  }

  get selectedCountries() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get items() {
    return this.groupList;
  }

  get disabled() {
    return this.availableCountries && this.availableCountries.length === 0;
  }

  removeEmptyCountry(countries) {
    return countries.filter((row) => row.trim().length > 0);
  }

  removeInvalidValuesFromSelection() {
    let before = this.selectedCountries;
    let after = before.filter((country) => this.availableCountries.includes(country));
    if (after.length === before.length) {
      return;
    }
    if (after.length === 0) {
      this.removeFilter();
      return;
    }
    this.args.setSelected(after);
  }

  @task({ restartable: true }) *initializeValues() {
    let knownValues = yield this.fetchKnownValues.perform();
    this.availableCountries = Array.from(knownValues);
    this.cacheCountryCodes(this.availableCountries);
    this.removeInvalidValuesFromSelection();
  }

  cacheCountryCodes(codes) {
    this.cachedKnownCountryCodes = copy(codes);
  }

  @task({ restartable: true }) *fetchKnownValues() {
    let sources = this.args.loadKnownValuesSources || ['conversation'];
    let childTasks = sources.map((source) => this.fetchData.perform(source));
    let responses = yield all(childTasks);
    return new Set(responses.flat());
  }

  @task({ enqueue: true, maxConcurrency: 12 }) *fetchData(source) {
    return yield get('/ember/reporting/known_values', {
      app_id: this.appService.app.id,
      source,
      property: 'user.location.country_code',
    });
  }

  get currentLabel() {
    let numSelected = this.selectedCountries.length;
    if (numSelected === 0) {
      return this.intl.t('components.reporting.custom.chart-builder.filter-bar.country-filter.any');
    }

    return this.selectedCountries.map((c) => lookupCountryName(c)).join(', ');
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.trackAnalytics();
    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }

  @action
  onSearch(filterValue) {
    this.availableCountries = this.cachedKnownCountryCodes.filter((code) =>
      lookupCountryName(code).toLowerCase().includes(filterValue.toLowerCase()),
    );
  }
}
