/* import __COLOCATED_TEMPLATE__ from './archive-ticket-type-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';

export default class extends Component {
  @service appService;
  @service notificationsService;
  @service intl;
  @service router;
  @service ticketStateService;

  @tracked dependentObjects = null;
  @tracked hasDependentObjects = null;

  constructor() {
    super(...arguments);
    this.fetchDependentObjects.perform();
  }

  get inboxRulesLink() {
    return 'apps.app.settings.inbox-rules.edit';
  }

  get webMessengerSettingsLink() {
    return 'apps.app.messenger.web';
  }

  get mobileMessengerSettingsLink() {
    return 'apps.app.messenger.mobile-sdk';
  }

  get modalTitle() {
    return this.isChecking
      ? this.intl.t('settings.ticket-data.archive-ticket-type-modal.checking-dependent-objects')
      : this.intl.t('settings.ticket-data.archive-ticket-type-modal.archive-ticket-type-name', {
          ticketTypeName: this.args.ticketTypeToArchive.name,
        });
  }

  get isChecking() {
    return this.fetchDependentObjects.isRunning;
  }

  get canArchive() {
    return !this.isChecking && this.hasDependentObjects === false;
  }

  get hasDependentCustomBots() {
    return this.dependentObjects && this.dependentObjects.customBots.length > 0;
  }

  get hasDependentAnswers() {
    return this.dependentObjects && this.dependentObjects.answers.length > 0;
  }

  get hasDependentBehaviors() {
    return this.dependentObjects && this.dependentObjects.behaviors.length > 0;
  }

  get hasDependentWorkflows() {
    return this.dependentObjects && this.dependentObjects.workflows.length > 0;
  }

  get hasDependentTicketLinks() {
    return this.dependentObjects && this.dependentObjects.ticketLinks.length > 0;
  }

  get hasWebTicketLinks() {
    return (
      this.dependentObjects && this.dependentObjects.ticketLinks.firstObject?.web_ticket_links_exist
    );
  }

  get hasMobileTicketLinks() {
    return (
      this.dependentObjects &&
      this.dependentObjects.ticketLinks.firstObject?.mobile_ticket_links_exist
    );
  }

  @task({ drop: true })
  *fetchDependentObjects() {
    let ticketType = this.args.ticketTypeToArchive;

    try {
      let response = yield ajax({
        url: `/ember/inbox/ticket_types/${ticketType.id}/dependent_objects`,
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
        },
      });
      this.dependentObjects = {
        customBots: response.dependent_custom_bots,
        answers: response.dependent_answers,
        behaviors: response.dependent_behaviors,
        workflows: response.dependent_workflows,
        ticketLinks: response.dependent_ticket_links,
      };
      this.hasDependentObjects =
        response.dependent_custom_bots.length !== 0 ||
        response.dependent_workflows.length !== 0 ||
        response.dependent_answers.length !== 0 ||
        response.dependent_behaviors.length !== 0 ||
        response.dependent_ticket_links.length !== 0;
    } catch (error) {
      this.args.onModalClose();
      this.notificationsService.notifyError(
        this.intl.t('settings.ticket-data.archive-ticket-type-modal.error-on-archive', {
          ticketTypeName: this.args.ticketTypeToArchive.name,
        }),
      );
    }
  }

  @task({ drop: true })
  *archiveTicketType() {
    let ticketType = this.args.ticketTypeToArchive;

    try {
      ticketType.setProperties({
        archived: true,
      });

      yield ticketType.save();
      this.ticketStateService.updateTicketType(ticketType.id, { archived: true });
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.ticket-data.archive-ticket-type-modal.successful-archive'),
      );
      this.args.onModalClose();
      let routeName = 'apps.app.settings.helpdesk.tickets';
      this.router.transitionTo(routeName);
    } catch (error) {
      ticketType.rollbackAttributes();
      if (error.jqXHR?.responseJSON?.message) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-data.archive-ticket-type-modal.error-on-archive', {
            ticketTypeName: this.args.ticketTypeToArchive.name,
          }),
        );
      }
    }
  }
}
