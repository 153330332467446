/* import __COLOCATED_TEMPLATE__ from './status-filter.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { difference, isEqual, pairs, without } from 'underscore';
import {
  NO_FILTER,
  STATUS_FILTER_TYPE,
  STATUS_OBJECT,
} from 'embercom/lib/settings/filters/constants';

export default class StatusFilter extends Component {
  @service intl;

  @tracked selectedFilter = this.args.filterValues;
  @tracked label = this.currentLabel;

  get filterItems() {
    return [
      {
        items: [
          {
            text: this.intl.t('settings.filters.status-filter.any'),
            value: NO_FILTER,
            isSelected: this.selectedFilter.includes(NO_FILTER),
          },
        ],
      },
      {
        heading: this.intl.t('settings.filters.status-filter.status'),
        items: pairs(STATUS_OBJECT).map((statusObject) => {
          return {
            text: this.intl.t(`settings.filters.status-filter.${statusObject[1]}`),
            value: statusObject[0],
            isSelected: this.selectedFilter.includes(statusObject[0]),
          };
        }),
      },
    ];
  }

  get currentLabel() {
    if (isEqual(this.selectedFilter, [NO_FILTER])) {
      return this.intl.t('settings.filters.status-filter.any');
    } else {
      let selectedStatus = this.selectedFilter.map((filterValue) => {
        return this.intl.t(`settings.filters.status-filter.${STATUS_OBJECT[filterValue]}`);
      });
      return selectedStatus.join(', ');
    }
  }

  @action
  onChangeFilterValue(value) {
    let addedFilterValue = difference(value, this.selectedFilter);

    if (isEqual(addedFilterValue, [NO_FILTER]) || isEqual(value, [])) {
      this.selectedFilter = [NO_FILTER];
    } else {
      this.selectedFilter = without(value, NO_FILTER);
    }
    this.label = this.currentLabel;
  }

  @action
  clearFilters() {
    if (isEqual(this.args.filterValues, [NO_FILTER])) {
      this.selectedFilter = this.args.filterValues;
      this.label = this.currentLabel;
    }
  }

  @action
  updateFilter() {
    this.args.updateFilterValues(STATUS_FILTER_TYPE, this.selectedFilter);
  }
}
