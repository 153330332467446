/* RESPONSIBLE TEAM: team-proactive-support */

import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { statisticKeys, statisticTypes } from 'embercom/models/data/outbound/constants';
import { failureListCategories } from 'embercom/models/data/stats-system/stats-constants';

export const aggregatedStatsDetails = {
  sent: {
    header: 'outbound.series.aggregated-stats.sent.header',
    tooltip: 'outbound.series.aggregated-stats.sent.tooltip',
    metric: 'outbound.receipts',
    statisticKey: statisticKeys.receipts,
    statisticTypeKey: statisticTypes.integer,
  },
  opened: {
    header: 'outbound.series.aggregated-stats.opened.header',
    tooltip: 'outbound.series.aggregated-stats.opened.tooltip',
    metric: 'outbound.receipts.opened.percent',
    statisticKey: statisticKeys.opens,
    statisticTypeKey: statisticTypes.percentage,
  },
  opened_count: {
    header: 'outbound.series.aggregated-stats.opened-count.header',
    tooltip: 'outbound.series.aggregated-stats.opened.tooltip',
    metric: 'outbound.receipts.opened',
    statisticKey: statisticKeys.opens,
    statisticTypeKey: statisticTypes.integer,
  },
  clicked: {
    header: 'outbound.series.aggregated-stats.clicked.header',
    tooltip: 'outbound.series.aggregated-stats.clicked.tooltip',
    metric: 'outbound.receipts.click.cardinality.stat_receipt_id.percent',
    statisticKey: statisticKeys.clicks,
    statisticTypeKey: statisticTypes.percentage,
  },
  clicked_count: {
    header: 'outbound.series.aggregated-stats.clicked-count.header',
    tooltip: 'outbound.series.aggregated-stats.clicked.tooltip',
    metric: 'outbound.receipts.click.cardinality.stat_receipt_id',
    statisticKey: statisticKeys.clicks,
    statisticTypeKey: statisticTypes.integer,
  },
  replied: {
    header: 'outbound.series.aggregated-stats.replied.header',
    tooltip: 'outbound.series.aggregated-stats.replied.tooltip',
    metric: 'outbound.receipts.reply.percent',
    statisticKey: statisticKeys.replies,
    statisticTypeKey: statisticTypes.percentage,
  },
  replied_count: {
    header: 'outbound.series.aggregated-stats.replied-count.header',
    tooltip: 'outbound.series.aggregated-stats.replied.tooltip',
    metric: 'outbound.receipts.reply',
    statisticKey: statisticKeys.replies,
    statisticTypeKey: statisticTypes.integer,
  },
  engaged: {
    header: 'outbound.series.aggregated-stats.engaged.header',
    tooltip: 'outbound.series.aggregated-stats.engaged.tooltip',
    metric: 'outbound.receipts.reply.percent',
    statisticKey: statisticKeys.replies,
    statisticTypeKey: statisticTypes.percentage,
  },
  engaged_count: {
    header: 'outbound.series.aggregated-stats.engaged-count.header',
    tooltip: 'outbound.series.aggregated-stats.engaged.tooltip',
    metric: 'outbound.receipts.reply',
    statisticKey: statisticKeys.replies,
    statisticTypeKey: statisticTypes.integer,
  },
  reacted: {
    header: 'outbound.series.aggregated-stats.reacted.header',
    tooltip: 'outbound.series.aggregated-stats.reacted.tooltip',
    metric: 'outbound.receipts.reaction.percent',
    statisticKey: statisticKeys.reactions,
    statisticTypeKey: statisticTypes.percentage,
  },
  reacted_count: {
    header: 'outbound.series.aggregated-stats.reacted-count.header',
    tooltip: 'outbound.series.aggregated-stats.reacted.tooltip',
    metric: 'outbound.receipts.reaction',
    statisticKey: statisticKeys.reactions,
    statisticTypeKey: statisticTypes.integer,
  },
  goal: {
    header: 'outbound.series.aggregated-stats.goal-count.header',
    tooltip: 'outbound.series.aggregated-stats.goal.tooltip',
    metric: 'outbound.receipts.goal_success',
    statisticKey: statisticKeys.goals,
    statisticTypeKey: statisticTypes.integer,
  },
  bounced: {
    header: 'outbound.series.aggregated-stats.bounced.header',
    tooltip: 'outbound.series.aggregated-stats.bounced.tooltip',
    metric: 'outbound.receipts.email_failure.bounced.percent',
    filter: {
      type: 'category',
      data: {
        property: 'failure_category.id',
        values: ['hard_bounce'],
      },
    },
    statisticKey: statisticKeys.emailFailures,
    statisticTypeKey: statisticTypes.percentage,
    subaggregationKey: failureListCategories.hardBounce,
  },
  bounced_count: {
    header: 'outbound.series.aggregated-stats.bounced-count.header',
    tooltip: 'outbound.series.aggregated-stats.bounced.tooltip',
    metric: 'outbound.receipts.email_failure.bounced',
    filter: {
      type: 'category',
      data: {
        property: 'failure_category.id',
        values: ['hard_bounce'],
      },
    },
    statisticKey: statisticKeys.emailFailures,
    statisticTypeKey: statisticTypes.integer,
    subaggregationKey: failureListCategories.hardBounce,
  },
  unsubscribed: {
    header: 'outbound.series.aggregated-stats.unsubscribed.header',
    tooltip: 'outbound.series.aggregated-stats.unsubscribed.tooltip',
    metric: 'outbound.receipts.email_failure.unsubscribed.percent',
    filter: {
      type: 'category',
      data: {
        property: 'failure_category.id',
        values: ['unsubscribe'],
      },
    },
    statisticKey: statisticKeys.emailFailures,
    statisticTypeKey: statisticTypes.percentage,
    subaggregationKey: failureListCategories.unsubscribe,
  },
  unsubscribed_count: {
    header: 'outbound.series.aggregated-stats.unsubscribed-count.header',
    tooltip: 'outbound.series.aggregated-stats.unsubscribed.tooltip',
    metric: 'outbound.receipts.email_failure.unsubscribed',
    filter: {
      type: 'category',
      data: {
        property: 'failure_category.id',
        values: ['unsubscribe'],
      },
    },
    statisticKey: statisticKeys.emailFailures,
    statisticTypeKey: statisticTypes.integer,
    subaggregationKey: failureListCategories.unsubscribe,
  },
  soft_bounce: {
    header: 'outbound.series.aggregated-stats.soft_bounce.header',
    statisticKey: statisticKeys.emailFailures,
    statisticTypeKey: statisticTypes.percentage,
    subaggregationKey: failureListCategories.softBounce,
  },
  soft_bounce_count: {
    header: 'outbound.series.aggregated-stats.soft_bounce-count.header',
    statisticKey: statisticKeys.emailFailures,
    statisticTypeKey: statisticTypes.integer,
    subaggregationKey: failureListCategories.softBounce,
  },
  spam: {
    header: 'outbound.series.aggregated-stats.spam.header',
    statisticKey: statisticKeys.emailFailures,
    statisticTypeKey: statisticTypes.percentage,
    subaggregationKey: failureListCategories.spamComplaint,
  },
  spam_count: {
    header: 'outbound.series.aggregated-stats.spam-count.header',
    statisticKey: statisticKeys.emailFailures,
    statisticTypeKey: statisticTypes.integer,
    subaggregationKey: failureListCategories.spamComplaint,
  },
  collectedEmails: {
    header: 'outbound.series.aggregated-stats.collectedEmails.header',
    statisticKey: statisticKeys.collectedEmails,
    statisticTypeKey: statisticTypes.percentage,
  },
  collectedEmails_count: {
    header: 'outbound.series.aggregated-stats.collectedEmails-count.header',
    statisticKey: statisticKeys.collectedEmails,
    statisticTypeKey: statisticTypes.integer,
  },
  answers: {
    header: 'outbound.series.aggregated-stats.answers.header',
    statisticKey: statisticKeys.answers,
    statisticTypeKey: statisticTypes.percentage,
  },
  answers_count: {
    header: 'outbound.series.aggregated-stats.answers-count.header',
    statisticKey: statisticKeys.answers,
    statisticTypeKey: statisticTypes.integer,
  },
  pushFailure: {
    header: 'outbound.series.aggregated-stats.pushFailure.header',
    tooltip: 'outbound.series.aggregated-stats.pushFailure.tooltip',
    metric: 'outbound.receipts.email_failure.percent',
    filter: {
      type: 'category',
      data: {
        property: 'failure_category.id',
        values: ['push_failure'],
      },
    },
    statisticKey: statisticKeys.failures,
    statisticTypeKey: statisticTypes.percentage,
  },
  pushFailure_count: {
    header: 'outbound.series.aggregated-stats.pushFailure-count.header',
    tooltip: 'outbound.series.aggregated-stats.pushFailure.tooltip',
    metric: 'outbound.receipts.email_failure',
    filter: {
      type: 'category',
      data: {
        property: 'failure_category.id',
        values: ['push_failure'],
      },
    },
    statisticKey: statisticKeys.failures,
    statisticTypeKey: statisticTypes.integer,
  },

  tourFailure: {
    header: 'outbound.series.aggregated-stats.tourFailure.header',
    tooltip: 'outbound.series.aggregated-stats.tourFailure.tooltip',
    metric: 'outbound.receipts.tour_step_failure.percent',
    statisticKey: statisticKeys.tourStepFailures,
    statisticTypeKey: statisticTypes.percentage,
  },
  tourFailure_count: {
    header: 'outbound.series.aggregated-stats.tourFailure-count.header',
    tooltip: 'outbound.series.aggregated-stats.tourFailure.tooltip',
    metric: 'outbound.receipts.tour_step_failure',
    statisticKey: statisticKeys.tourStepFailures,
    statisticTypeKey: statisticTypes.integer,
  },
  smsFailure: {
    header: 'outbound.series.aggregated-stats.smsFailure.header',
    tooltip: 'outbound.series.aggregated-stats.smsFailure.tooltip',
    metric: 'outbound.receipts.email_failure.percent',
    filter: {
      type: 'category',
      data: {
        property: 'failure_category.id',
        values: ['sms_failure'],
      },
    },
    statisticKey: statisticKeys.failures,
    statisticTypeKey: statisticTypes.percentage,
    subaggregationKey: failureListCategories.smsFailure,
  },
  smsFailure_count: {
    header: 'outbound.series.aggregated-stats.smsFailure-count.header',
    tooltip: 'outbound.series.aggregated-stats.smsFailure.tooltip',
    metric: 'outbound.receipts.email_failure',
    filter: {
      type: 'category',
      data: {
        property: 'failure_category.id',
        values: ['sms_failure'],
      },
    },
    statisticKey: statisticKeys.failures,
    statisticTypeKey: statisticTypes.integer,
    subaggregationKey: failureListCategories.smsFailure,
  },
  keywordReplies: {
    header: 'outbound.series.aggregated-stats.keywordReplies.header',
    tooltip: 'outbound.series.aggregated-stats.keywordReplies.tooltip',
    metric: 'outbound.receipts.keyword_reply.percent',
    statisticKey: statisticKeys.keywordReplies,
    statisticTypeKey: statisticTypes.percentage,
  },
  keywordReplies_count: {
    header: 'outbound.series.aggregated-stats.keywordReplies-count.header',
    tooltip: 'outbound.series.aggregated-stats.keywordReplies.tooltip',
    metric: 'outbound.receipts.keyword_reply',
    statisticKey: statisticKeys.keywordReplies,
    statisticTypeKey: statisticTypes.integer,
  },
  dismissed: {
    header: 'outbound.series.aggregated-stats.dismissed.header',
    tooltip: 'outbound.series.aggregated-stats.dismissed.tooltip',
    metric: 'outbound.receipts.dismissal.percent',
    statisticKey: statisticKeys.dismissals,
    statisticTypeKey: statisticTypes.percentage,
  },
  dismissed_count: {
    header: 'outbound.series.aggregated-stats.dismissed-count.header',
    tooltip: 'outbound.series.aggregated-stats.dismissed.tooltip',
    metric: 'outbound.receipts.dismissal',
    statisticKey: statisticKeys.dismissals,
    statisticTypeKey: statisticTypes.integer,
  },
  completed: {
    header: 'outbound.series.aggregated-stats.completed.header',
    tooltip: 'outbound.series.aggregated-stats.completed.tooltip',
    metric: 'outbound.receipts.completion.percent',
    statisticKey: statisticKeys.completions,
    statisticTypeKey: statisticTypes.percentage,
  },
  completed_count: {
    header: 'outbound.series.aggregated-stats.completed-count.header',
    tooltip: 'outbound.series.aggregated-stats.completed.tooltip',
    metric: 'outbound.receipts.completion',
    statisticKey: statisticKeys.completions,
    statisticTypeKey: statisticTypes.integer,
  },
};

export const contentAggregatedStats = {
  all: [aggregatedStatsDetails.sent],
  [objectTypes.email]: [
    aggregatedStatsDetails.sent,
    aggregatedStatsDetails.opened,
    aggregatedStatsDetails.clicked,
    aggregatedStatsDetails.replied,
    aggregatedStatsDetails.unsubscribed,
    aggregatedStatsDetails.bounced,
  ],
  [objectTypes.post]: [
    aggregatedStatsDetails.sent,
    aggregatedStatsDetails.opened,
    aggregatedStatsDetails.clicked,
    aggregatedStatsDetails.replied,
    aggregatedStatsDetails.reacted,
  ],
  [objectTypes.banner]: [
    aggregatedStatsDetails.sent,
    aggregatedStatsDetails.clicked,
    aggregatedStatsDetails.reacted,
  ],
  [objectTypes.tour]: [
    aggregatedStatsDetails.sent,
    aggregatedStatsDetails.completed,
    aggregatedStatsDetails.tourFailure,
  ],
  [objectTypes.push]: [
    aggregatedStatsDetails.sent,
    aggregatedStatsDetails.opened,
    aggregatedStatsDetails.pushFailure,
  ],
  [objectTypes.sms]: [
    aggregatedStatsDetails.sent,
    aggregatedStatsDetails.keywordReplies,
    aggregatedStatsDetails.unsubscribed,
    aggregatedStatsDetails.smsFailure,
  ],
  [objectTypes.carousel]: [
    aggregatedStatsDetails.sent,
    aggregatedStatsDetails.completed,
    aggregatedStatsDetails.dismissed,
  ],
  [objectTypes.chat]: [
    aggregatedStatsDetails.sent,
    aggregatedStatsDetails.opened,
    aggregatedStatsDetails.clicked,
    aggregatedStatsDetails.replied,
  ],
  [objectTypes.customBot]: [
    aggregatedStatsDetails.sent,
    aggregatedStatsDetails.engaged,
    aggregatedStatsDetails.completed,
  ],
  [objectTypes.survey]: [
    aggregatedStatsDetails.sent,
    aggregatedStatsDetails.completed,
    aggregatedStatsDetails.dismissed,
  ],
  [objectTypes.tooltipGroup]: [aggregatedStatsDetails.sent, aggregatedStatsDetails.clicked],
  [objectTypes.checklist]: [
    aggregatedStatsDetails.sent,
    aggregatedStatsDetails.opened,
    aggregatedStatsDetails.completed,
  ],
  [objectTypes.whatsapp]: [aggregatedStatsDetails.sent],
};

const openedStats = [aggregatedStatsDetails.opened, aggregatedStatsDetails.opened_count];
const clickedStats = [aggregatedStatsDetails.clicked, aggregatedStatsDetails.clicked_count];
const repliedStats = [aggregatedStatsDetails.replied, aggregatedStatsDetails.replied_count];
const reactedStats = [aggregatedStatsDetails.reacted, aggregatedStatsDetails.reacted_count];
const collectedEmailsStats = [
  aggregatedStatsDetails.collectedEmails,
  aggregatedStatsDetails.collectedEmails_count,
];
const goalStats = [aggregatedStatsDetails.goal];
const completedStats = [aggregatedStatsDetails.completed, aggregatedStatsDetails.completed_count];
const dismissedStats = [aggregatedStatsDetails.dismissed, aggregatedStatsDetails.dismissed_count];
const keywordRepliesStats = [
  aggregatedStatsDetails.keywordReplies,
  aggregatedStatsDetails.keywordReplies_count,
];
const unsubscribedStats = [
  aggregatedStatsDetails.unsubscribed,
  aggregatedStatsDetails.unsubscribed_count,
];
const bouncedStats = [aggregatedStatsDetails.bounced, aggregatedStatsDetails.bounced_count];
const softBounceStats = [
  aggregatedStatsDetails.soft_bounce,
  aggregatedStatsDetails.soft_bounce_count,
];
const spamStats = [aggregatedStatsDetails.spam, aggregatedStatsDetails.spam_count];
const tourFailureStats = [
  aggregatedStatsDetails.tourFailure,
  aggregatedStatsDetails.tourFailure_count,
];
const pushFailureStats = [
  aggregatedStatsDetails.pushFailure,
  aggregatedStatsDetails.pushFailure_count,
];
const smsFailureStats = [
  aggregatedStatsDetails.smsFailure,
  aggregatedStatsDetails.smsFailure_count,
];
const engagedStats = [aggregatedStatsDetails.engaged, aggregatedStatsDetails.engaged_count];
const tourCompletedStats = [
  aggregatedStatsDetails.completed,
  aggregatedStatsDetails.completed_count,
];
const answersStats = [aggregatedStatsDetails.answers, aggregatedStatsDetails.answers_count];

const baseStats = [aggregatedStatsDetails.sent, ...openedStats, ...clickedStats, ...repliedStats];

export const contentTableStats = {
  all: [aggregatedStatsDetails.sent, ...goalStats],
  [objectTypes.email]: [
    ...baseStats,
    ...unsubscribedStats,
    ...bouncedStats,
    ...softBounceStats,
    ...spamStats,
    ...goalStats,
  ],
  [objectTypes.post]: [...baseStats, ...reactedStats, ...goalStats],
  [objectTypes.banner]: [
    aggregatedStatsDetails.sent,
    ...clickedStats,
    ...reactedStats,
    ...collectedEmailsStats,
    ...goalStats,
  ],
  [objectTypes.tour]: [
    aggregatedStatsDetails.sent,
    ...tourCompletedStats,
    ...tourFailureStats,
    ...goalStats,
  ],
  [objectTypes.push]: [
    aggregatedStatsDetails.sent,
    ...openedStats,
    ...pushFailureStats,
    ...goalStats,
  ],
  [objectTypes.sms]: [
    aggregatedStatsDetails.sent,
    ...keywordRepliesStats,
    ...unsubscribedStats,
    ...smsFailureStats,
    ...goalStats,
  ],
  [objectTypes.carousel]: [
    aggregatedStatsDetails.sent,
    ...completedStats,
    ...dismissedStats,
    ...goalStats,
  ],
  [objectTypes.chat]: [...baseStats, ...collectedEmailsStats, ...goalStats],
  [objectTypes.customBot]: [
    aggregatedStatsDetails.sent,
    ...engagedStats,
    ...completedStats,
    ...goalStats,
  ],
  [objectTypes.survey]: [
    aggregatedStatsDetails.sent,
    ...completedStats,
    ...dismissedStats,
    ...clickedStats,
    ...answersStats,
    ...goalStats,
  ],
  [objectTypes.tooltipGroup]: [aggregatedStatsDetails.sent, ...clickedStats, ...goalStats],
  [objectTypes.checklist]: [
    aggregatedStatsDetails.sent,
    ...openedStats,
    ...completedStats,
    ...goalStats,
  ],
  [objectTypes.newsItem]: [
    aggregatedStatsDetails.sent,
    ...openedStats,
    ...clickedStats,
    ...reactedStats,
    ...goalStats,
  ],
  [objectTypes.whatsapp]: [aggregatedStatsDetails.sent, ...goalStats],
};

export const aggregatedStatsFilter = [
  {
    type: 'category',
    data: {
      property: 'stat_receipt.ruleset_id',
      values: [],
    },
  },
];
