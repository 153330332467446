/* import __COLOCATED_TEMPLATE__ from './metric-picker.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { chain } from 'underscore';
import { isBlank, isPresent } from '@ember/utils';
import { DEFAULT_PERCENTILE_VALUE } from 'embercom/models/reporting/custom/chart-series';
import { MULTI_UNITS } from 'embercom/models/reporting/custom/visualization-options';
import { METRIC_UNIT_MAP } from 'embercom/objects/reporting/unified/metrics/types';
import { cached } from 'tracked-toolbox';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class MetricPicker extends Component {
  @service appService;
  @service intl;
  @service reportingMetrics;
  @service intercomEventService;

  @tracked newLabel = '';
  @tracked isEditingLabel = false;
  @tracked isHovered = false;

  percentileOptions = new Array(21)
    .fill(0)
    .map((_, index) => {
      let value = index * 5;
      return { text: value.toString(), value };
    })
    .reverse();

  // this defines the items that appear in the 1st metrics dropdown
  // the values for the metrics uniquely identify the metric. we use the metric's ID for these
  @cached
  get metrics() {
    let metrics = this.reportingMetrics
      .getCustomReportMetrics(this.args.isStandalone)
      .reject((metric) => {
        return metric.metricFamilyId && !metric.metricFamilyDefault;
      });

    let datasetOrder = [
      'conversation',
      'consolidated_conversation_part',
      'ticket',
      'teammate_handling_conversation',
      'admin_status_change',
      'conversation_sla_status_log',
      'call',
      'call_team_stats',
      'call_teammate_stats',
    ];
    let datasetSortFunc = ([datasetA], [datasetB]) =>
      datasetOrder.indexOf(datasetA) - datasetOrder.indexOf(datasetB);

    return chain(metrics)
      .groupBy('datasetId')
      .pairs()
      .sort(datasetSortFunc)
      .map(([datasetId, metrics]) => {
        return {
          heading: this.intl.t(`reporting.custom-reports.chart.datasets.${datasetId}`),
          items: metrics
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(this.buildMetricDropdownItem, this),
        };
      })
      .value();
  }

  buildMetricDropdownItem(metric) {
    let { isDisabled, disabledTooltipContent } = this.shouldDisableMetricItem(metric);

    return {
      text: metric.name,
      fullyQualifiedName: this.fullyQualifiedName(metric),
      value: metric.id,
      description: metric.description,
      releaseStatus: metric.releaseStatus,
      qualifiers: metric.qualifiers,
      icon: metric.icon,
      isDisabled,
      ...(isDisabled && { disabledTooltipContent }),
    };
  }

  shouldDisableMetricItem(metric) {
    if (this.args.isHeatmap && !metric.supportsHeatmaps) {
      return {
        isDisabled: true,
        disabledTooltipContent: this.intl.t('reporting.chart.heatmap-not-available'),
      };
    }
    if (
      this.args.chartSeriesIndex !== 0 &&
      isPresent(this.args.chart) &&
      !this.args.chart.supportsFeature(MULTI_UNITS)
    ) {
      let firstMetricUnit = METRIC_UNIT_MAP[this.args.chart.chartSeries.firstObject.metric.unit];
      return {
        isDisabled: METRIC_UNIT_MAP[metric.unit] !== firstMetricUnit,
        disabledTooltipContent: this.intl.t(
          'reporting.custom-reports.chart.multimetrics.multi-unit-tooltip-disable-dual-axis',
        ),
      };
    }
    return {
      isDisabled: false,
    };
  }

  fullyQualifiedName(metric) {
    if (!metric.qualifiers) {
      return metric.name;
    }

    let nameWithQualifiers = [metric.name, ...metric.qualifiers];
    return this.intl.formatList(nameWithQualifiers, { type: 'unit' });
  }

  get currentMetricVariantType() {
    return this.args.chartSeries.metric.metricVariantType;
  }

  get currentMetric() {
    return this.args.chartSeries.metric;
  }

  get selectableMetricForCurrentMetricId() {
    if (
      this.args.chartSeries.metric.metricFamilyId &&
      !this.args.chartSeries.metric.metricFamilyDefault
    ) {
      return this.currentMetricDefaultVariantId;
    }

    return this.args.chartSeries.metric.id;
  }

  get currentMetricDefaultVariantId() {
    return this.reportingMetrics
      .getMetricsByFamilyId(this.currentMetric.metricFamilyId)
      .find((metric) => metric.metricFamilyDefault).id;
  }

  get currentAggregation() {
    return this.args.chartSeries.aggregation;
  }

  get availableAggregations() {
    return this.currentMetric.supportedAggregations;
  }

  get aggregations() {
    return this.availableAggregations.map((aggregationId) => {
      return {
        text: this.intl.t(`reporting.aggregations.${aggregationId}`),
        value: aggregationId,
      };
    });
  }

  get shouldDisplayAggregationDropdown() {
    return this.availableAggregations?.length > 1;
  }

  get percentileValue() {
    let knownPercentileValues = this.percentileOptions.mapBy('value');
    return isPresent(this.args.chartSeries.percentileValue) &&
      knownPercentileValues.includes(this.args.chartSeries.percentileValue)
      ? this.args.chartSeries.percentileValue
      : DEFAULT_PERCENTILE_VALUE;
  }

  set percentileValue(value) {
    this.args.chartSeries.percentileValue = value;
  }

  get shouldLinkToHelpCenter() {
    // beta metrics do not have help center entries
    if (this.currentMetric.releaseStatus === 'beta') {
      return false;
    }

    return true;
  }

  @action
  trackAnalyticsEvent(data) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.sharedAnalyticsData,
      ...data,
    });
  }

  @action
  updateAggregation(value) {
    this.trackAnalyticsEvent({
      action: 'edited_metric_aggregation_function',
      aggregation_function: value,
    });
    this.args.chartSeries.aggregation = value;
    this.args.chartSeries.chart.deleteChartSeriesSnapshot();
    if (
      isBlank(this.args.chartSeries.percentileValue) &&
      this.args.chartSeries.aggregation === 'percentile'
    ) {
      this.args.chartSeries.percentileValue = this.percentileValue;
    }
    this.args.chartSeries.resetLabel();
  }

  @action
  selectMetric(value) {
    this.trackAnalyticsEvent({
      action: 'edited_metric',
      oldMetricId: this.args.chartSeries.metric.id,
      newMetricId: value,
    });
    this.args.chart.updateMetric(value, this.args.chartSeries);
    this.args.resetColumns(this.args.chartSeries);
  }

  get showRemoveChartSeries() {
    return this.args.chartSeries.chart.isMultimetric && this.args.renderForMultimetric;
  }

  @action
  removeChartSeries() {
    this.trackAnalyticsEvent({
      action: 'remove_metric',
      metricId: this.args.chartSeries.metric.id,
    });
    this.args.chart.removeChartSeries(this.args.chartSeries);
  }

  @action
  startEditingLabel() {
    this.newLabel = this.args.chartSeries.label;
    this.isEditingLabel = true;
  }

  @action
  finishEditingLabel() {
    if (!this.isEditingLabel) {
      return;
    }

    if (isPresent(this.newLabel)) {
      this.args.chartSeries.label = this.newLabel;
    } else {
      this.args.chartSeries.resetLabel();
    }

    this.newLabel = '';
    this.isEditingLabel = false;
  }

  @action
  updateNewLabel(value) {
    this.newLabel = sanitizeHtml(value)?.string;
  }
}
