/* import __COLOCATED_TEMPLATE__ from './pivot-table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';
import percent from 'embercom/lib/percentage-formatter';

export default Component.extend({
  tagName: '',
  totalsByLegendItem: computed('items', 'signal.context', function () {
    return this.items.reduce(
      (totalsByLegendItem, item) => ({
        ...totalsByLegendItem,
        [item.value]: this.signal.context.reduce((total, contextItem = { value: [] }) => {
          let { value } = contextItem.value.find((valueItem) => valueItem.key === item.value) || {
            value: 0,
          };
          return total + value;
        }, 0),
      }),
      {},
    );
  }),
  total: computed('totalsByLegendItem', function () {
    return Object.keys(this.totalsByLegendItem).reduce((total, key) => {
      return total + this.totalsByLegendItem[key] || 0;
    }, 0);
  }),
  percentsByLegendItem: computed('total', 'totalsByLegendItem', function () {
    return Object.keys(this.totalsByLegendItem).reduce(
      (percents, key) => ({
        ...percents,
        [key]: percent(this.total, this.totalsByLegendItem[key]) || 0,
      }),
      {},
    );
  }),
});
