/* import __COLOCATED_TEMPLATE__ from './survey-nps-counter-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ChartDataResource from 'embercom/lib/reporting/chart-data-resource';
import { useResource } from 'ember-resources';
import Formatters from 'embercom/lib/reporting/flexible/formatters';
import percent, { REPORTS_WHOLE_NUMBER_PRECISION } from 'embercom/lib/percentage-formatter';
import {
  NPS_DETRACTORS_RATING_THRESHOLD,
  NPS_PASSIVES_RATING_THRESHOLD,
  NPS_PROMOTERS_RATING_THRESHOLD,
} from 'embercom/lib/reporting/survey-helpers';

export default class SurveyNpsCounterChart extends Component {
  loadingIndicatorSize = 'large';
  size = '1';

  dataResource = useResource(this, ChartDataResource, () => ({
    renderableChart: this.args.renderableChart,
  }));

  get chartData() {
    let allSeries = [{ data: 0 }, { data: 0 }, { data: 0 }];

    let [detractors, passives, promoters] = allSeries;
    if (this.hasData) {
      this.dataResource.chartData[0].forEach((series) => {
        if (Number(series[0]) <= NPS_DETRACTORS_RATING_THRESHOLD) {
          detractors.data += series[1];
        } else if (Number(series[0]) <= NPS_PASSIVES_RATING_THRESHOLD) {
          passives.data += series[1];
        } else if (Number(series[0]) <= NPS_PROMOTERS_RATING_THRESHOLD) {
          promoters.data += series[1];
        }
      });

      let score = this._convertToPercentage(allSeries);
      return this._format(score);
    }
    return '-';
  }

  get hasData() {
    return this.dataResource.chartData[0].some((data) => {
      return data[1] > 0;
    });
  }

  _convertToPercentage(allSeries) {
    let [detractors, passives, promoters] = allSeries;
    let detractorScore = detractors.data || 0;
    let passiveScore = passives.data || 0;
    let promoterScore = promoters.data || 0;
    let yTotal = detractorScore + passiveScore + promoterScore;

    return percent(yTotal, promoterScore) - percent(yTotal, detractorScore);
  }

  _format(score) {
    let unit = 'value';
    let formatter = new Formatters[unit]();
    return formatter.formatCounter(score, REPORTS_WHOLE_NUMBER_PRECISION);
  }
}
