/* import __COLOCATED_TEMPLATE__ from './select-ticket-type-dropdown-option.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  item: { text: string; value: string };
  componentAttrs: { emojiUri: string };
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const SelectTicketTypeDropdownOption = templateOnlyComponent<Signature>();
export default SelectTicketTypeDropdownOption;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::SelectTicketTypeDropdownOption': typeof SelectTicketTypeDropdownOption;
    'settings/data-import/modal/select-ticket-type-dropdown-option': typeof SelectTicketTypeDropdownOption;
  }
}
