/* import __COLOCATED_TEMPLATE__ from './container-width.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { addEventListener, debounceTask } from 'ember-lifeline';
import ENV from 'embercom/config/environment';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  width: 0,
  didInsertElement() {
    this._super(...arguments);
    this.setComponentWidth();
    addEventListener(this, window, 'resize', () => this.setComponentWidthDebounced());
  },
  navbarCollapsed: notImplemented(
    'reporting/element/container-width: you must pass the `collapsed` property of the navbarCollapsing service',
  ),

  didReceiveAttrs() {
    this._super(...arguments);
    // the navbar collapse animation is set to run for 300ms,
    // we need to wait for that to complete before updating the width of the component
    setTimeout(() => this.setComponentWidthDebounced(), 300);
  },

  setComponentWidthDebounced() {
    debounceTask(this, 'setComponentWidth', ENV.APP._50MS);
  },

  setComponentWidth() {
    if (this.element) {
      this.set('width', this.element.clientWidth);
    }
  },
});
