/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import StandardEdgeGenerator from 'graph-editor/models/graph-editor/edge/generator';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default class SeriesEdgeGenerator extends StandardEdgeGenerator {
  get pathClass() {
    let predecessorInwardEdges = this.edge.predecessor.inwardEdges;
    if (
      isEmpty(predecessorInwardEdges) &&
      this.edge.dataObject.isAlternative &&
      this.edge.predecessor.dataObject.isCondition
    ) {
      return 'series__edge o__error';
    }
    return 'series__edge';
  }

  get hoverPathClass() {
    return 'series__edge__hover-path';
  }

  get arrowPathClass() {
    return 'series__edge__arrowhead';
  }

  // Replace this with @cached in Ember 3.19
  @computed('edge.predecessor.connectionPoints.@each.coordinates')
  get fromConnectionPoints() {
    let connectionPoints = this.edge.predecessor.connectionPoints.filter((connectionPoint) =>
      this.isUsableOutwardConnectionPoint(connectionPoint),
    );
    if (isEmpty(connectionPoints)) {
      return this.edge.predecessor.connectionPoints;
    }
    return connectionPoints;
  }

  isUsableOutwardConnectionPoint(connectionPoint) {
    if (
      connectionPoint.dataObject?.edgeSplit &&
      this.edge.dataObject?.edgeSplit &&
      connectionPoint.dataObject.edgeSplit.id !== this.edge.dataObject.edgeSplit.id
    ) {
      return false;
    }

    if (
      connectionPoint.dataObject?.edgeTypes &&
      !connectionPoint.dataObject.edgeTypes.includes(this.edge.dataObject?.edgeType)
    ) {
      return false;
    }

    if (connectionPoint.direction !== 'outwards' && connectionPoint.direction !== 'both') {
      return false;
    }

    return true;
  }

  isUsableInwardConnectionPoint(connectionPoint) {
    if (
      connectionPoint.dataObject?.edgeTypes &&
      !connectionPoint.dataObject.edgeTypes.includes(this.edge.dataObject?.edgeType)
    ) {
      return false;
    }

    if (connectionPoint.direction !== 'inwards' && connectionPoint.direction !== 'both') {
      return false;
    }
    return true;
  }

  // Replace this with @cached in Ember 3.19
  @computed('edge.successor.connectionPoints.@each.coordinates')
  get toConnectionPoints() {
    return (
      this.edge.successor?.connectionPoints.filter((connectionPoint) =>
        this.isUsableInwardConnectionPoint(connectionPoint),
      ) || []
    );
  }
}
