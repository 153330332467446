/* import __COLOCATED_TEMPLATE__ from './standard-stats-summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class SeriesNodeStandardStatsSummaryComponent extends Component {
  @service seriesEditorService;

  @action openStats() {
    this.seriesEditorService.registerActiveStatsNode(this.args.node);
  }

  get activeView() {
    return this.seriesEditorService.activeView;
  }

  get value() {
    switch (this.activeView) {
      case 'seriesCompletions':
        return this.args.node.completedCount;
      case 'seriesDisengagements':
        return this.args.node.disengagedCount;
      case 'seriesExits':
        return this.args.node.exitedCheckpointCount;
      default:
        return this.nonEmptyStats.firstObject?.value || this.args.node.completedCheckpointCount;
    }
  }

  get verb() {
    switch (this.activeView) {
      case 'receipts':
        return 'started';
      case 'seriesCompletions':
        return 'finished';
      case 'seriesDisengagements':
        return 'disengaged';
      case 'seriesExits':
        return 'exited';
      default:
        return this.nonEmptyStats.firstObject?.label || this.args.config.verb;
    }
  }

  get emptyStatsMessage() {
    return { label: this.verb, value: this.value };
  }

  get nonEmptyStats() {
    return this.args.node.graphConfiguration.checkpointStats.filter((stat) => stat.value > 0);
  }

  get showNodeStat() {
    if (this.seriesEditorService.isEditMode || this.seriesEditorService.series.isDraft) {
      return false;
    }

    switch (this.activeView) {
      case 'overview':
        return true;
      case 'receipts':
        return this.args.node.isStarting;
      case 'seriesCompletions':
        return this.args.node.isEnding;
      case 'seriesDisengagements':
        return this.args.node.isInternal;
      case 'seriesExits':
        return this.args.node.isInternal;
      default:
        return false;
    }
  }

  get statsBreakdown() {
    return this.args.node.graphConfiguration.checkpointStats;
  }

  get disablePopover() {
    return (
      this.args.node.graphConfiguration.checkpointStatsOverviewValue === 0 ||
      this.args.node.graphConfiguration.disableStatsPopover ||
      this.nonEmptyStats.length <= 1 ||
      this.activeView !== 'overview'
    );
  }
}
