/* import __COLOCATED_TEMPLATE__ from './identity-verification-installation-setup-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';
export default Component.extend({
  tagName: '',

  userSource: null,
  identityVerified: null,
  platformNames: {
    web: 'web',
    ios: 'iOS',
    android: 'Android',
  },

  identityVerificationSettingsRoutes: {
    web: 'apps.app.settings.identity-verification.web',
    ios: 'apps.app.settings.identity-verification.ios',
    android: 'apps.app.settings.identity-verification.android',
  },

  helpArticles: {
    web: 'https://www.intercom.com/help/configure-intercom-for-your-product-or-site/staying-secure/enable-identity-verification-on-your-web-product',
    ios: 'https://developers.intercom.com/installing-intercom/docs/ios-identity-verification',
    android: 'https://developers.intercom.com/installing-intercom/android/identity-verification/',
  },

  isWeb: equal('userSource', 'web'),

  platformName: computed('userSource', function () {
    return this.platformNames[this.userSource];
  }),

  identityVerificationSettingsRoute: computed('userSource', function () {
    return this.identityVerificationSettingsRoutes[this.userSource];
  }),

  helpArticle: computed('userSource', function () {
    return this.helpArticles[this.userSource];
  }),
});
