/* import __COLOCATED_TEMPLATE__ from './detect-enduser-forwarded.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class DetectEnduserForwardedSettings extends Component {
  @service store;
  @service notificationsService;
  @service intl;
  @service appService;
  @tracked settings;

  constructor() {
    super(...arguments);
    this.loadSettings.perform();
  }

  get app() {
    return this.appService.app;
  }

  @task({ drop: true })
  *loadSettings() {
    this.settings = yield this.store.findRecord('email-detect-forwarded-settings', this.app.id);
  }

  @task({ enqueue: true })
  *updateSetting() {
    try {
      yield this.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (error) {
      this.settings.toggleProperty('detectEnduserForwarded');
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  toggleDetectEnduserForwarded() {
    this.settings.toggleProperty('detectEnduserForwarded');
    this.updateSetting.perform();
  }
}
