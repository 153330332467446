/* import __COLOCATED_TEMPLATE__ from './series-comparison-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import countFormatter from 'embercom/lib/count-formatter';
import percent from 'embercom/lib/percentage-formatter';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { buildDataConfigWithComparisons } from 'embercom/lib/reporting/comparison-data-config';
import Formatters, { units } from 'embercom/lib/reporting/flexible/formatters';
import toSentence from 'embercom/lib/to-sentence';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { cached } from 'tracked-toolbox';

function getValueFromChartData(rawChartData) {
  return rawChartData.aggregations[0].values[0];
}

export default class SeriesComparisonCounter extends Component {
  @service seriesEditorService;

  constructor() {
    super(...arguments);
    this.formatter = new Formatters[this.args.viewConfig.formatUnit.unit](
      this.args.viewConfig.formatUnit.displayUnit,
    );
    this.args.viewConfig.formatUnit.commas = true;
  }

  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: buildDataConfigWithComparisons(
      this.args.dataConfig,
      this.args.renderableChart.dateRange,
    ),
    viewConfig: this.args.viewConfig,
  }));

  get size() {
    return this.args.size || '1';
  }

  get loadingIndicatorSize() {
    if (this.size === '3') {
      return 'small';
    }
    return 'large';
  }

  get currentCounter() {
    return this.formatter.formatData(this.values.current);
  }

  get previousCounter() {
    return this.formatter.formatData(this.values.previous);
  }

  get improvementDirection() {
    if (isPresent(this.args.renderableChart?.improvementDirection)) {
      return this.args.renderableChart.improvementDirection;
    }

    let improvementDirection = null;
    let names = this.dataResource.rawChartData.map((response) => response.name);

    names.forEach((name) => {
      if (
        this.args.viewConfig.counter &&
        this.args.viewConfig.counter[name]?.improvementDirection
      ) {
        improvementDirection = this.args.viewConfig.counter[name]?.improvementDirection;
        return;
      }
    });

    if (improvementDirection) {
      return improvementDirection;
    } else {
      throw new Error(`Improvement direction for any of ${toSentence(names)} is missing`);
    }
  }

  get timeRangeData() {
    return {
      inDays: this.args.range.inDays || 0,
      comparisonStart: this.args.range.comparisonStartMoment,
      comparisonEnd: this.args.range.comparisonEndMoment,
      timezone: this.args.range.timezone,
    };
  }

  get formattedLabel() {
    let formattedCounter;
    if (
      this.args.viewConfig.formatUnit.unit === units.value &&
      this.args.viewConfig.formatUnit.commas
    ) {
      formattedCounter = countFormatter(this.values.current);
    } else {
      formattedCounter = this.formatter.formatCounter(this.values.current);
    }
    return formattedCounter;
  }

  get statKey() {
    if (this.checkDataConfigForStat('series_completion')) {
      return statisticKeys.seriesCompletions;
    }

    if (this.checkDataConfigForStat('series_disengagement')) {
      return statisticKeys.seriesDisengagements;
    }

    if (this.checkDataConfigForStat('series_exit')) {
      return statisticKeys.seriesExits;
    }

    if (this.checkDataConfigForStat('series.receipts.goal_success')) {
      return statisticKeys.goals;
    }

    if (this.checkDataConfigForStat('series.receipts') && this.isSeriesStarted) {
      return statisticKeys.receipts;
    }
  }

  // all of our series stat data configs include `series.receipts`, but only
  // series_started is a count rather than a percent
  isSeriesStarted() {
    return this.isCountValue;
  }

  checkDataConfigForStat(statName) {
    return this.args.dataConfig.series.some((element) => element.name.includes(statName));
  }

  get showValueDescriptionLink() {
    return !isEmpty(this.statKey);
  }

  @cached
  get values() {
    return this.percentValues || this.absoluteValues;
  }

  get percentValues() {
    if (this.args.viewConfig.formatUnit.unit !== units.percent) {
      return;
    }

    // the order of the series in the data config is important :(
    let [previousNumerator, previousDenominator, currentNumerator, currentDenominator] =
      this.rawValues;
    let previous = percent(previousDenominator, previousNumerator);
    let current = percent(currentDenominator, currentNumerator);
    return { previous, current };
  }

  get isPercentValue() {
    return this.args.viewConfig.formatUnit.unit === units.percent;
  }

  get isCountValue() {
    return this.args.viewConfig.formatUnit.unit === units.count;
  }

  get currentNumerator() {
    return this.isPercentValue ? this.rawValues[2] : this.rawValues[1];
  }

  get absoluteValues() {
    // the order of the series in the data config is important :(
    let [previous, current] = this.rawValues;
    return { previous, current };
  }

  get rawValues() {
    return this.dataResource.rawChartData.map(getValueFromChartData);
  }

  @cached
  get chartData() {
    return this.formattedLabel;
  }

  get isError() {
    return this.dataResource.error || this.dataResource.rawChartData.length === 0;
  }

  get valueDescription() {
    return this.args.renderableChart.metric(0).valueDescription;
  }

  @action
  openSideDrawer() {
    if (this.showValueDescriptionLink) {
      this.seriesEditorService.activeSeriesOverviewStat = this.statKey;
    }
  }
}
