/* import __COLOCATED_TEMPLATE__ from './migrated-to-workflows.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import type Workflow from 'embercom/models/workflow';
import { taskFor } from 'ember-concurrency-ts';
import {
  type BotConfigTarget,
  CUSTOM_BOT_CONFIG,
} from 'embercom/objects/operator/configuration/configuration';
import type CustomBotConfig from 'embercom/objects/operator/configuration/custom-bot';

type TriggerWorkflows = {
  config: CustomBotConfig;
  workflows: {
    rulesetId: number;
    title: string;
  }[];
};
interface Signature {
  Args: {
    model: {
      workflow: Workflow;
    };
  };
  Element: HTMLDivElement;
}

class MigratedToWorkflows extends Component<Signature> {
  @service declare modalService: $TSFixMe;
  @service declare store: Store;
  @tracked rulesets: $TSFixMe[] = [];

  constructor(owner: unknown, args: ArgsOf<MigratedToWorkflows>) {
    super(owner, args);
    taskFor(this.loadRulesets).perform();
  }

  get isMigratedToMultipleWorkflows() {
    return this.args.model.workflow.migratedToRulesetIds.length > 1;
  }

  @task({ drop: true })
  *loadRulesets() {
    let migratedToRulesetIds = this.args.model.workflow.migratedToRulesetIds;

    let rulesetPromises = migratedToRulesetIds.map((rulesetId: number) => {
      return this.store.findRecord('matching-system/ruleset', rulesetId);
    });

    this.rulesets = yield Promise.all(rulesetPromises);
  }

  get triggersWithWorkflows(): TriggerWorkflows[] {
    return Object.values(
      this.rulesets.reduce(
        (dataForTarget, ruleset) => {
          let rulesetLink = ruleset.rulesetLinks.firstObject;
          let target: BotConfigTarget = rulesetLink.object.target;
          let { title } = rulesetLink.object;

          if (!(target in dataForTarget)) {
            dataForTarget[target] = { config: CUSTOM_BOT_CONFIG[target], workflows: [] };
          }

          dataForTarget[target].workflows.push({ rulesetId: ruleset.id, title });
          return dataForTarget;
        },
        {} as Record<BotConfigTarget, TriggerWorkflows>,
      ),
    );
  }

  get isRulesetsLoaded() {
    return taskFor(this.loadRulesets).isIdle;
  }

  @action closeModal() {
    this.modalService.closeModal();
  }
}

export default MigratedToWorkflows;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Modals::MigratedToWorkflows': typeof MigratedToWorkflows;
    'settings/modals/migrated-to-workflows': typeof MigratedToWorkflows;
  }
}
