/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { get, post } from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ajax from 'embercom/lib/ajax';
import { timeout } from 'ember-concurrency';

export default class Base extends Component {
  @service appService;
  @service intl;
  @service notificationsService;
  @service intercomConfirmService;
  @tracked runJobsData = [];
  @tracked isEditMode = false;
  @tracked exportDefinition = null;
  @tracked exportDefinitionPersisted = false;
  @tracked lastExportEventJob = null;
  @tracked previousS3BucketName = '';
  @tracked s3BucketName = '';
  @tracked dataPath = '';
  @tracked bulk = true;
  @tracked schedule = null;
  @tracked bucketVerified = false;
  @tracked showBucketVerificationBanner = false;
  currentPage = 1;
  perPage = 25;
  emailWaitTime = 30000;
  @tracked selectedTab = 'runs';
  @tracked canLoadMore = true;
  @tracked frequencies = [
    {
      text: this.intl.t(
        'settings.bulk-export.configure-data-export.schedule-card.step-two-frequency-hourly',
      ),
      value: 'hourly',
    },
    {
      text: this.intl.t(
        'settings.bulk-export.configure-data-export.schedule-card.step-two-frequency-daily',
      ),
      value: 'daily',
    },
  ];
  predefinedS3BucketPrefix = 'intercom-export-';
  maxS3BucketNameLength = 63 - this.predefinedS3BucketPrefix.length;
  dataFormats = [
    { text: 'JSON', value: 'JSON' },
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    { text: 'JSON Lines', value: 'JSONL' },
  ];
  @tracked selectedDataFormat = this.dataFormats[0].value;
  @tracked selectedSortingMethod = 'no_folders';
  @tracked skipEmptyFiles = false;
  @tracked includeAttachments = false;

  constructor() {
    super(...arguments);
    this.getExportJobDefinition.perform();
  }

  @task
  *getExportJobDefinition() {
    let response = yield get(`/ember/bulk_export/export_job_definitions`, {
      app_id: this.appService.app.id,
    });
    this.exportDefinition = response;
    if (this.exportDefinition.length !== 0) {
      this.exportDefinition = this.exportDefinition[0];
      this.exportDefinitionPersisted = true;
      this.loadJobDefinitionValues();

      if (this.exportDefinition.verified) {
        this.getRunJobs.perform(1);
      }
    }
    this.setActiveTab();
  }

  @action
  loadJobDefinitionValues() {
    this.bulk = this.exportDefinition.bulk;
    this.schedule = this.exportDefinition.schedule;
    this.dataPath = this.exportDefinition.data_path;
    this.s3BucketName = this.exportDefinition.s3_bucket_name;
    this.skipEmptyFiles = this.exportDefinition.skip_empty_files;
    this.selectedSortingMethod = this.calculateselectedSortingMethod(this.exportDefinition);
    this.selectedDataFormat = this.exportDefinition.format;
    this.includeAttachments = this.exportDefinition.include_attachments;
  }

  @action
  setActiveTab() {
    if (this.exportDefinition.length === 0) {
      this.selectedTab = 'configuration';
    }
  }

  @action
  onLoadMore() {
    this.getRunJobs.perform();
  }

  @task
  *getRunJobs(page) {
    this.currentPage = page || this.currentPage;
    this.newRunJobsData = yield get(`/ember/bulk_export/export_job_runs`, {
      app_id: this.appService.app.id,
      export_job_definition_id: this.exportDefinition.id,
      page: this.currentPage,
      per_page: this.perPage,
    });
    this.runJobsData = [...this.runJobsData, ...this.newRunJobsData];
    this.lastExportEventJob = this.runJobsData[0];
    this.currentPage += 1;
    if (this.newRunJobsData.length < this.perPage) {
      this.canLoadMore = false;
    }
  }

  @action
  async deactivateExportDefinition() {
    let confirmOptions = {
      title: this.intl.t('settings.bulk-export.run-jobs.delete-warning-card.title'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t('settings.bulk-export.run-jobs.delete-action'),
      cancelButtonText: this.intl.t('settings.bulk-export.cancel-action'),
      body: this.intl.t('settings.bulk-export.run-jobs.delete-warning-card.description'),
    };
    let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
    if (!isConfirmed) {
      return;
    }
    await ajax({
      url: `/ember/bulk_export/export_job_definitions/${this.exportDefinition.id}`,
      type: 'DELETE',
      data: JSON.stringify({
        app_id: this.appService.app.id,
      }),
      dataType: 'text',
    });
    this.exportDefinition = null;
    this.s3BucketName = '';
    this.dataPath = '';
    this.selectedDataFormat = this.dataFormats[0].value;
    this.runJobsData = [];
    this.skipEmptyFiles = false;
    this.selectedSortingMethod = 'no_folders';
    this.schedule = null;
    this.exportDefinitionPersisted = false;
    this.selectedTab = 'configuration';
  }

  @action
  toggleEditMode(isEditing) {
    this.isEditMode = isEditing;
    this.schedule = null;
  }
  @action
  toggleBulk() {
    this.bulk = !this.bulk;
  }
  @action
  changeFrequency(frequency) {
    this.schedule = frequency;
  }

  @task
  *resendVerificationEmail() {
    let response = yield get(
      `/ember/bulk_export/export_job_definitions/${this.exportDefinition.id}/resend_verification_email`,
      {
        app_id: this.appService.app.id,
      },
    );

    if (response.error) {
      this.notificationsService.notifyError(
        this.intl.t('settings.bulk-export.unverified-data-export.error'),
      );
    } else {
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.bulk-export.unverified-data-export.confirmation'),
      );
    }

    yield timeout(this.emailWaitTime);
  }

  @action
  manualResendEmail() {
    return this.resendVerificationEmail.perform();
  }

  @action
  async createJobDefinition() {
    try {
      let confirmOptions = {
        title: this.intl.t('settings.bulk-export.start-modal.title'),
        primaryButtonType: 'primary',
        confirmButtonText: this.intl.t('settings.bulk-export.start-modal.confirm-action'),
        hideCancelButton: true,
        body: this.intl.t('settings.bulk-export.start-modal.description'),
      };
      let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
      if (!isConfirmed) {
        return;
      }

      this.exportDefinition = await ajax({
        url: `/ember/bulk_export/export_job_definitions`,
        type: 'POST',
        data: JSON.stringify({
          app_id: this.appService.app.id,
          bulk: this.bulk,
          schedule: this.schedule,
          data_path: this.dataPath,
          s3_bucket_name: this.s3BucketName,
          data_format: this.selectedDataFormat,
          use_date_folders: this.useDateFolders(this.selectedSortingMethod),
          date_sorting_method: this.dateSortingMethod(this.selectedSortingMethod),
          skip_empty_files: this.skipEmptyFiles,
          include_attachments: this.includeAttachments,
        }),
      });
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('settings.bulk-export.run-jobs.fetch-error'),
      );
    }
    this.exportDefinitionPersisted = true;
    this.selectedTab = 'runs';
  }

  @action
  async verifyBucket() {
    let response = await post(`/ember/bulk_export/bucket_verification`, {
      app_id: this.appService.app.id,
      s3_bucket_name: this.s3BucketName,
    });
    if (!response.error) {
      this.bucketVerified = true;
      this.previousS3BucketName = this.s3BucketName;
    }
    this.showBucketVerificationBanner = true;
  }

  @action
  updateDataFormat(dataFormat) {
    this.selectedDataFormat = dataFormat;
  }

  @action
  onSortingMethodChange(sortingMethod) {
    switch (sortingMethod) {
      case 'no_folders':
        this.useDateFolders = false;
        this.dateSortingMethod = 'conversation_updated_date';
        break;
      case 'conversation_updated_date_folders':
        this.useDateFolders = true;
        this.dateSortingMethod = 'conversation_updated_date';
        break;
      case 'export_date_folders':
        this.useDateFolders = true;
        this.dateSortingMethod = 'export_date';
        break;
    }
  }

  @action
  toggleSkipEmptyFiles() {
    this.skipEmptyFiles = !this.skipEmptyFiles;
  }

  @action
  toggleIncludeAttachments() {
    this.includeAttachments = !this.includeAttachments;
  }

  calculateselectedSortingMethod(exportDefinition) {
    if (exportDefinition.use_date_folders) {
      if (exportDefinition.date_sorting_method === 'conversation_updated_date') {
        return 'conversation_updated_date_folders';
      } else {
        return 'export_date_folders';
      }
    } else {
      return 'no_folders';
    }
  }

  useDateFolders(selectedSortingMethod) {
    if (selectedSortingMethod === 'no_folders') {
      return false;
    } else {
      return true;
    }
  }

  dateSortingMethod(selectedSortingMethod) {
    if (selectedSortingMethod === 'export_date_folders') {
      return 'export_date';
    } else {
      return 'conversation_updated_date';
    }
  }
}
