/* import __COLOCATED_TEMPLATE__ from './comparison-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { SELF_SERVE_RESOLVED } from 'embercom/lib/reporting/csf-queries';
import { getTimeConfig } from 'embercom/lib/reporting/csf-query-helper';
import { buildDataConfigWithComparisons } from '../../../../lib/reporting/comparison-data-config';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';

const SELF_SERVE_RESOLUTIONS = 'self-serve-resolutions';

export default class ComparisonCounter extends Component {
  get viewConfig() {
    return {
      formatUnit: { unit: units.value, commas: true },
      counter: {
        [SELF_SERVE_RESOLUTIONS]: { improvementDirection: improvementDirections.INCREASING },
      },
    };
  }

  get dataConfig() {
    return buildDataConfigWithComparisons(
      {
        series: [
          {
            name: SELF_SERVE_RESOLUTIONS,
            ...SELF_SERVE_RESOLVED,
            time: getTimeConfig({ range: this.args.range }),
          },
        ],
      },
      this.args.range,
    );
  }
}
