/* import __COLOCATED_TEMPLATE__ from './table-local-filters.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

export default class TableLocalFilters extends Component {
  @service intercomEventService;

  @tracked teamFilterLabel = 'All teams';
  @tracked teammateFilterLabel = 'All teammates';

  @tracked selectedDropdownTeams = [];
  @tracked selectedDropdownTeammates = [];

  @tracked currentSelectedDropdownTeams = [];
  @tracked currentSelectedDropdownTeamsBuffer = [];

  @tracked currentSelectedDropdownTeammates = [];
  @tracked currentSelectedDropdownTeammatesBuffer = [];

  @service appService;

  // ------------------ team filter ------------------
  get teamItems() {
    return [
      {
        heading: 'Members of',
        items: this.allTeamItems,
      },
    ];
  }

  get selectedTeamIds() {
    return this.currentSelectedDropdownTeams.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get allTeamItems() {
    return this.appService.app.teams.map((team) => ({
      model: team,
      text: team.display_as_assignee,
      value: team.id.toString(),
      component: 'reporting/flexible/filter-bar/teammate-item',
      isSelected: this.currentSelectedDropdownTeamsBuffer.includes(team.id.toString()),
    }));
  }

  get currentTeamFilterLabel() {
    if (this.selectedTeamIds.length === 0) {
      return 'All teams';
    }

    let teamNames = this.selectedTeamIds.map((teammateId) => {
      return this.appService.app.teams.find((teammate) => teammate.id.toString() === teammateId)
        .display_as_assignee;
    });

    return `Members of ${teamNames.join(', ')}`;
  }

  @action
  onTeamFilterClose() {
    this.teamFilterLabel = this.currentTeamFilterLabel;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_teammate_table_by_team_filter',
      object: this.args.analyticsObject,
      team_count: this.selectedTeamIds.length,
    });
    this.currentSelectedDropdownTeamsBuffer = this.currentSelectedDropdownTeams;

    let isSelectionChanged =
      this.selectedDropdownTeams.length !== this.currentSelectedDropdownTeams.length ||
      this.selectedDropdownTeams.some((sdt, i) => sdt !== this.currentSelectedDropdownTeams[i]);

    if (isSelectionChanged) {
      this.selectedDropdownTeams = this.currentSelectedDropdownTeams;

      if (this.args.onTeamFilterSelection) {
        this.args.onTeamFilterSelection(this.selectedDropdownTeams);
      }
    }
  }

  // ----------------------------------------------------------------
  // ------------------ teammate filter ------------------
  get selectedTeammateIds() {
    return this.currentSelectedDropdownTeammates
      .filter((item) => item !== REPORTING_FILTER_SELECT_ALL)
      .map((id) => parseInt(id, 10));
  }

  get currentTeammateFilterLabel() {
    if (this.selectedTeammateIds.length === 0) {
      return 'All teammates';
    }

    let teammateNames = this.selectedTeammateIds.map((teammateId) => {
      return this.appService.app.humanAdmins.find((admin) => parseInt(admin.id, 10) === teammateId)
        .name;
    });

    return `${teammateNames.join(', ')}`;
  }

  get teammateItems() {
    return [
      {
        heading: 'Teammates',
        items: this.allTeammateItems,
      },
    ];
  }

  get allTeammateItems() {
    return this.appService.app.humanAdmins.map((teammate) => ({
      model: teammate,
      text: teammate.display_as_assignee,
      value: teammate.id.toString(),
      component: 'reporting/flexible/filter-bar/teammate-item',
      isSelected: this.currentSelectedDropdownTeammatesBuffer.includes(teammate.id.toString()),
    }));
  }

  @action
  onTeammateFilterClose() {
    let selectedTeammates = this.currentSelectedDropdownTeammates;
    this.currentSelectedDropdownTeammatesBuffer = this.currentSelectedDropdownTeammates;

    if (selectedTeammates && selectedTeammates.firstObject === 'any') {
      selectedTeammates = [];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_teammate_table_by_teammate_filter',
      object: this.args.analyticsObject,
      team_count: selectedTeammates.length,
    });

    this.teammateFilterLabel = this.currentTeammateFilterLabel;

    let isSelectionChanged =
      this.selectedDropdownTeammates.length !== selectedTeammates.length ||
      this.selectedDropdownTeammates.some((sdt, i) => sdt !== selectedTeammates[i]);

    if (isSelectionChanged) {
      this.selectedDropdownTeammates = selectedTeammates;

      if (this.args.onTeammateFilterSelection) {
        this.args.onTeammateFilterSelection(selectedTeammates.map((t) => parseInt(t, 10)));
      }
    }
  }
  // ----------------------------------------------------------------
}
