/* import __COLOCATED_TEMPLATE__ from './topic-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { task } from 'ember-concurrency-decorators';

export default class TopicFilter extends Component {
  @service appService;
  @service intercomEventService;
  @service store;
  @service realTimeEventService;
  @service router;
  @service intl;
  @service filterPatternService;

  @tracked topics = [];
  @tracked label = this.currentLabel;
  @tracked isPropagating = this.hasPropagatingTopic;
  @tracked showPropagationFinishedBanner = false;
  @tracked operator = this.startingOperator;

  // store the selection here so that the UI doesn't update until the drop down is closed
  @tracked selectedDropdownItems = this.selectedValues;
  @tracked visibleTopics = [];

  get startingOperator() {
    return this.args.selected.operator || 'category';
  }

  get selectedValues() {
    let values = this.args.selected.values || [];
    return values[0] === REPORTING_FILTER_SELECT_ALL ? [] : values;
  }

  constructor() {
    super(...arguments);
    this.loadTopics.perform();
    this.realTimeEventService.subscribeTopics(['ConversationTopicPropagatedStatusChanged']);
    this.realTimeEventService.on(
      'ConversationTopicPropagatedStatusChanged',
      this,
      'handleTopicPropagationStatusChange',
    );
  }

  handleTopicPropagationStatusChange({ topicId }) {
    let wasPropagating = this.hasPropagatingTopic;
    this.topics.forEach(async (topic) => {
      if (topic.id === topicId) {
        await topic.reload();
        if (wasPropagating === true && !this.hasPropagatingTopic) {
          this.showPropagationFinishedBanner = true;
        }
      }
    });
  }

  @action
  reloadPage() {
    window.location.reload();
  }

  get items() {
    return [
      {
        heading: 'Topic',
        items: this.allItems,
      },
    ];
  }

  @task({ restartable: true }) *loadTopics() {
    this.topics = yield this.store.findAll('conversational-insights/conversation-topic');
    this.visibleTopics = this.topics;
  }

  get selectedTopics() {
    return this.selectedDropdownItems.filter((item) => {
      return item !== REPORTING_FILTER_SELECT_ALL && this.topics.find((topic) => topic.id === item);
    });
  }

  get hasPropagatingTopic() {
    return this.selectedTopics.some((selectedTopic) => {
      let topic = this.allItems.find((item) => item.value === selectedTopic);
      return topic.isStillPropagatingForTimeRange;
    });
  }

  get allItems() {
    return this.visibleTopics.map((topic) => ({
      text: topic.name,
      value: topic.id,
      // Check for range value in-case it is not passed
      isStillPropagatingForTimeRange: this.args.range?.comparisonStartMoment.isBefore(
        topic.fullyPropagatedFrom,
      ),
      isSelected: this.selectedDropdownItems.includes(topic.id),
      component: 'reporting/flexible/filter-bar/topic-filter-item',
    }));
  }

  get currentLabel() {
    return this.filterPatternService.makeLabel(this.selectedTopicNames, this.operator);
  }

  get name() {
    return this.args.name || 'Topic';
  }

  get selectedTopicNames() {
    if (this.selectedTopics.length === 0) {
      return this.intl.t('components.reporting.custom.chart-builder.filter-bar.tag-filter.any');
    }

    let topicNames = this.selectedTopics.map((topicId) => {
      return this.topics.find((topic) => topic.id === topicId).name;
    });
    return topicNames.join(', ');
  }

  get valuesForFilter() {
    let valuesOrPlaceholder = this.selectedDropdownItems.length
      ? this.selectedDropdownItems
      : [REPORTING_FILTER_SELECT_ALL];
    return ['category', 'not_in_category'].includes(this.operator) ? valuesOrPlaceholder : [];
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered',
      object: this.args.analyticsObject,
      place: 'reports',
      topic_ids: this.selectedTopics,
      filter_name: 'topic',
      operator: this.operator,
    });

    this.args.setSelected(this.valuesForFilter, this.operator);
    this.label = this.currentLabel;
    this.isPropagating = this.hasPropagatingTopic;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.operator = null;
    this.realTimeEventService.off(
      'ConversationTopicPropagatedStatusChanged',
      this,
      'handleTopicPropagationStatusChange',
    );
    this.realTimeEventService.unsubscribeTopics(['ConversationTopicPropagatedStatusChanged']);
    this.args.removeFilter();
  }

  @action
  filterValues(filter) {
    this.visibleTopics = this.topics.filter((topic) =>
      topic.name.toLowerCase().includes(filter.toLowerCase()),
    );
  }
}
