/* import __COLOCATED_TEMPLATE__ from './predicates.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { seriesExitBehaviors } from 'embercom/models/data/matching-system/matching-constants';

export default class Predicates extends Component {
  @service appService;
  @service attributeService;
  @service seriesEditorService;

  get app() {
    return this.appService.app;
  }

  get attributeGroupList() {
    let groups = [];

    groups.push({
      heading: 'Person Data',
      attributes: this.attributeService.filterableUserAttributesForMessaging,
    });

    if (this.app.companiesActive) {
      groups.push({
        heading: 'Company Data',
        attributes: this.attributeService.filterableCompanyAttributesForMessaging,
      });
    }

    return groups;
  }

  get series() {
    return this.seriesEditorService.series;
  }

  @action
  onUpdate() {
    let isEmpty = this.series.exitPredicateGroup.editablePredicates.length === 0;
    this.series.exitBehavior = isEmpty ? seriesExitBehaviors.none : seriesExitBehaviors.exitRules;
  }
}
