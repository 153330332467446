/* import __COLOCATED_TEMPLATE__ from './articles-top-views.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { articleViewsDataConfig } from 'embercom/lib/reporting/flexible/articles';
import { tracked } from '@glimmer/tracking';

class CachedArticleStore {
  @tracked articles = {};

  constructor(store) {
    this.store = store;
  }

  get(id) {
    if (this.articles[id]) {
      return this.articles[id];
    }
    this.articles[id] = this.store.findRecord('article-multilingual', id);
    return this.articles[id];
  }
}

export default class ArticlesTopViews extends Component {
  @service appService;
  @service store;
  @service helpCenterService;
  @service intl;

  constructor() {
    super(...arguments);
    this.articleCollection = new CachedArticleStore(this.store);
    this.helpCenterService.maybeFetchSite();
  }

  articleTitle(article) {
    return this.relevantArticleContent(article).title;
  }

  relevantArticleContent(article) {
    return article.defaultLocalizedContent || article.articleContents.firstObject;
  }

  get columns() {
    return [
      {
        label: this.intl.t('articles.reports.top-viewed.columns.title'),
        valuePath: 'groupName',
        component: 'reporting/flexible/table-article-title-cell',
        componentData: {
          getCellValue: (article) => this.articleTitle(article),
          articleCollection: this.articleCollection,
        },
        isSortable: false,
        isMain: true,
      },
      {
        label: this.intl.t('articles.reports.top-viewed.columns.views'),
        valuePath: 'col-views',
        type: 'number',
      },
    ];
  }

  get dataConfig() {
    return {
      columns: [
        {
          name: 'col-views',
          aggregation: {
            type: 'cardinality',
            data: {
              property: 'user.id',
            },
          },
          ...articleViewsDataConfig(),
        },
      ],
      rows: [
        {
          name: 'row-articles',
          type: 'term',
          data: {
            term_size: 100,
            property: 'article.id',
            applyLimitOnServer: true,
            limit: 5,
          },
        },
      ],
    };
  }

  get viewConfig() {
    return {
      rowsVisible: 5,
      sortState: { valuePath: 'col-views', direction: 'desc' },
    };
  }
}
