/* import __COLOCATED_TEMPLATE__ from './topic-list-empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import TopicStats from 'embercom/lib/conversational-insights/topic-stats';
import { inject as service } from '@ember/service';
import { MAX_TOPIC_COUNT } from 'embercom/components/reporting/conversational-insights/visualization';

export default class TopicListEmptyState extends Component {
  @service router;
  @service intercomEventService;

  @tracked data;

  @action
  processData() {
    if (this.args.suggestionsLoading) {
      return;
    }

    let bubbleData = [];
    bubbleData = this.args.suggestions
      .filter((suggestion) => {
        if (this.args.suggestionStats) {
          return (
            this.args.suggestionStats[suggestion.id] &&
            this.args.suggestionStats[suggestion.id].current_count > 0
          );
        } else {
          return false;
        }
      })
      .map((suggestion) => {
        let stats = new TopicStats(this.args.suggestionStats[suggestion.id]);
        return {
          id: `suggestion-${suggestion.id}`,
          onClick: () => null,
          name: suggestion.name,
          currentCount: stats.currentCount,
          currentResponseTime: stats.currentResponseTime || 0,
          currentTimeToClose: stats.currentTimeToClose || 0,
          currentPositiveRate: stats.currentPositiveRate || 0,
          accepted: false,
        };
      });
    this.data = bubbleData
      .sort((a, b) => b.currentCount - a.currentCount)
      .slice(0, MAX_TOPIC_COUNT);
  }

  @action
  onNewTopicClick() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'empty_state',
      object: 'new_topic',
      place: 'topics',
    });

    this.router.transitionTo('apps.app.reports.conversational-insights.topics.new');
  }

  @action
  onShowSuggestionClick() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'empty_state',
      object: 'show_suggestions',
      place: 'topics',
    });

    this.router.transitionTo('apps.app.reports.conversational-insights.suggested-topics');
  }
}
