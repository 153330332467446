/* import __COLOCATED_TEMPLATE__ from './report-access-request-modal.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import { taskFor } from 'ember-concurrency-ts';
import type ReportAccessControlList from 'embercom/models/reporting/report-access-control-list';
import type ReportAccessService from 'embercom/services/report-access-service';
import { AccessType } from 'embercom/services/report-access-service';
import type Admin from 'embercom/models/admin';
import type IntlService from 'embercom/services/intl';
import { cached } from 'tracked-toolbox';

interface Signature {
  Args: {
    model: { reportId: string; onClose: () => void };
  };
}

class ReportAccessRequestModal extends Component<Signature> {
  @service declare modalService: $TSFixMe;
  @service declare store: Store;
  @service declare reportAccessService: ReportAccessService;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked adminsWithReportAccess: Admin[] = [];

  onClose = this.args.model.onClose;
  reportId = this.args.model.reportId;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    taskFor(this.setUpAccessControl).perform();
  }

  @task({ drop: true })
  *setUpAccessControl() {
    yield Promise.all([taskFor(this.loadReportAccess).perform()]);
  }

  @task({ drop: true })
  *loadReportAccess() {
    yield this.store.findAll('role'); //it's important we load the roles here as they might not have been preloaded
    let accessControlList: ReportAccessControlList[] =
      yield this.reportAccessService.fetchReportAccess.perform(this.args.model.reportId);

    // Note that we don't look at workpace level access controls here. This is because if there is a
    // workspace level edit acl then it's impossible to reach this code as everyone has permission.
    let adminsWithAccessIds = accessControlList
      .filter((access: ReportAccessControlList) => {
        return access.isAdminAccessControl && access.accessType === AccessType.EDIT;
      })
      .map((access: ReportAccessControlList) => access.adminId);

    let roles = accessControlList
      .filter((control) => {
        return control.isRoleAccessControl && control.accessType === AccessType.EDIT;
      })
      .map((control) => control.role)
      .compact();

    let adminsInRoleId = roles.flatMap((role) => role.admins.flatMap((admin: Admin) => admin.id));

    let report = this.store.peekRecord('reporting/custom/report', this.args.model.reportId);
    let reportOwnerId = report ? [report.createdById] : [];
    let adminIds = [...reportOwnerId, ...adminsWithAccessIds, ...adminsInRoleId].uniq();
    this.adminsWithReportAccess = this.appService.app.humanAdmins.filter((admin: any) => {
      return (
        adminIds.indexOf(admin.id) > -1 &&
        admin.currentAppPermissions.can_reporting__reports__update
      );
    });
  }

  get isLoading() {
    return taskFor(this.setUpAccessControl).isRunning;
  }

  get adminsWhoCanChangePermissions() {
    // It's possible for this list to be empty, in that case we fallback to admins that have
    // permission to edit permissions in settings
    if (this.adminsWithReportAccess.length === 0) {
      return this.adminsWithAppPermissions;
    } else {
      return this.adminsWithReportAccess;
    }
  }

  get adminsWithAppPermissions() {
    return this.appService.app.humanAdmins.filter(
      (admin: any) => admin.currentAppPermissions.can_manage_teammates,
    );
  }

  @action closeModal() {
    this.modalService.closeModal();
    this.onClose();
  }

  @cached
  get title() {
    let accessControl = this.reportAccessService.currentAdminReportAccess(this.reportId);

    if (accessControl) {
      return this.intl.t(
        `reporting.custom-reports.report.share-modal.restriction-modal.${accessControl.accessType}`,
      );
    } else {
      return this.intl.t('reporting.custom-reports.report.share-modal.restriction-modal.no-access');
    }
  }

  get description() {
    if (this.adminsWithReportAccess.length === 0) {
      return this.intl.t('reporting.custom-reports.report.ask-a-teammate-for-app-permission');
    } else {
      return this.intl.t('reporting.custom-reports.report.ask-a-teammate-for-report-access');
    }
  }
}

export default ReportAccessRequestModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ReportAccessRequestModal': typeof ReportAccessRequestModal;
    'reporting/custom/report-access-request-modal': typeof ReportAccessRequestModal;
  }
}
