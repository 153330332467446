/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import EmberObject, { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import currencyFormatter from 'embercom/lib/currency-formatter';

export default Component.extend({
  classNames: ['flex-auto', 'flex', 'flex-col', 'settings__teammate__teammate-list'],
  appService: service(),
  customerService: service(),
  app: readOnly('appService.app'),

  pricePerSeat: computed('customerService.pricePerInboxSeat', function () {
    return currencyFormatter(this.get('customerService.pricePerInboxSeat'));
  }),

  freeSeatCount: readOnly('customerService.customer.freeSeatCount'),

  createColumn(
    label,
    canExpandToFit,
    componentName,
    description,
    sortable = false,
    isSortColumn = false,
    sortedAscending = false,
    sortingPropertyKey,
    propertyName,
  ) {
    return EmberObject.create({
      label,
      canExpandToFit,
      component: componentName,
      sortable,
      isSortColumn,
      sortedAscending,
      description,
      sortingPropertyKey,
      propertyName,
    });
  },
});
