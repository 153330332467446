/* import __COLOCATED_TEMPLATE__ from './permission-toggle-row.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';

interface Args {
  permissionsObject?: any;
  permission?: any;
  updatePermission?: any;
  changed?: any;
  isDisabled?: boolean;
  isDisabledByRole?: boolean;
}

export default class PermissionToggleRow extends Component<Args> {
  @service appService: $TSFixMe;
  @service permissionsHierarchyService: $TSFixMe;

  get parentValue() {
    return this.args.permissionsObject[`can_${this.args.permission.parent_id}`];
  }

  @action
  togglePermission() {
    let frontEndKey = `can_${this.args.permission.key}`;
    if (this.args.permissionsObject[frontEndKey] && isPresent(this.args.permission.children_ids)) {
      this.args.permission.children_ids.forEach((child: string) => {
        if (this.permissionsHierarchyService.permissionsHierarchy[child]['type'] === 'crud') {
          this.permissionsHierarchyService.permissionsHierarchy[child]['actions'].forEach(
            (action: string) => {
              this.args.permissionsObject[`can_${action}`] = false;
            },
          );
        } else if (this.args.permissionsObject[`can_${child}`] !== undefined) {
          // Don't change children permissions that are set as undefined since it means a conflict on bulk permission edit
          this.args.permissionsObject[`can_${child}`] = false;
        }
      });
    }
    this.args.permissionsObject[frontEndKey] = !this.args.permissionsObject[frontEndKey];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::Permissions::PermissionsToggle': typeof PermissionToggleRow;
    'settings/teammates/permissions/permission-toggle-row': typeof PermissionToggleRow;
  }
}
