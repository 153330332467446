/* import __COLOCATED_TEMPLATE__ from './replies-sent-columnchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  COUNT,
  COMMENT_CREATED_AT,
  TEAMMATE_REPLIES_FILTERS,
} from 'embercom/lib/reporting/flexible/constants';
import PALETTE from '@intercom/pulse/lib/palette';
import { inject as service } from '@ember/service';

export default class RepliesSentColumnchart extends Component {
  @service intl;

  get modifiedFilters() {
    let newFilters = { ...this.args.filters };

    if (newFilters.admin_participant_ids) {
      newFilters.teammate_id = newFilters.admin_participant_ids;
      delete newFilters.admin_participant_ids;
    }

    return newFilters;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value, displayUnit: 'reply' };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[COMMENT_CREATED_AT],
        createTitleTooltipText: () =>
          this.intl.t('reporting.conversations-report.replies-sent.tooltip'),
      },
    };
    viewConfig.seriesColors = [PALETTE['vis-mantis-50']];

    return viewConfig;
  }

  get dataConfig() {
    return {
      xAxis: {
        type: 'temporal',
        data: {
          interval: FILTER_PROPERTY_PLACEHOLDER,
          property: COMMENT_CREATED_AT,
        },
      },
      series: [
        {
          name: 'replies_sent_columnchart',
          source: 'consolidated_conversation_part',
          type: COUNT,
          data: { property: COMMENT_CREATED_AT },
          time: {
            property: COMMENT_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'and',
            filters: TEAMMATE_REPLIES_FILTERS,
          },
        },
      ],
    };
  }
}
