/* import __COLOCATED_TEMPLATE__ from './category-card.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';
import { type TicketCategory } from 'embercom/objects/inbox/ticket';

export type CategoryCardConfig = {
  category: TicketCategory;
  title: string;
  description: string;
  icon: string;
  attributes: { icon: string; text: string }[];
  onSeeExample: (category: TicketCategory) => void;
};

interface Signature {
  Args: {
    config: CategoryCardConfig;
  };
}

const CategoryCard = templateOnlyComponent<Signature>();
export default CategoryCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketData::TicketsOnboarding::CategoryCard': typeof CategoryCard;
  }
}
