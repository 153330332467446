/* import __COLOCATED_TEMPLATE__ from './performance-linechart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import LinechartBuilder from 'embercom/lib/reporting/flexible/linechart-builder';
import { SORTING_LABEL_NAME } from 'embercom/lib/reporting/flexible/constants';
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { cached } from 'tracked-toolbox';

export default class PerformanceLinechart extends Component {
  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.args.dataConfig,
    viewConfig: this.args.viewConfig,
  }));

  @cached
  get chartData() {
    let seriesDefaultOptions = {
      marker: {
        symbol: 'circle',
      },
    };

    return this.buildVisualisationData(this.dataResource.rawChartData, seriesDefaultOptions);
  }

  buildVisualisationData(dataResponse, seriesDefaultOptions) {
    let group = dataResponse?.[0]?.groups[0];
    if (!group) {
      return [];
    }

    let allLineNames = [
      ...new Set(
        group.values.flatMap((groupItem) =>
          groupItem.groups.flatMap((innerGroup) => innerGroup.values),
        ),
      ),
    ];
    let allXValues = group.values.map((groupItem) => groupItem.value);

    //Set all lines to 0
    let resultLines = [];
    allLineNames.forEach((lineName) => {
      let data = allXValues.map((xValue) => [xValue, 0]);
      resultLines.push({
        name: this.mapLineLabel(lineName),
        data,
        ...seriesDefaultOptions,
      });
    });

    //Update the lines to the correct values
    group.values.forEach((groupItem) => {
      let x = groupItem.value;
      groupItem.groups.forEach((innerGroup) => {
        let aggregation = innerGroup.aggregations[0];

        innerGroup.values.forEach((innerGroupValue, index) => {
          let y = aggregation.values[index] !== 0 ? aggregation.values[index] : null;

          let lineName = this.mapLineLabel(innerGroupValue);
          let resultLine = resultLines.find((line) => line.name === lineName);
          let resultData = resultLine.data.find((data) => data[0] === x);
          resultData[1] = y;
        });
      });
    });

    //limit and sort results
    resultLines = this.sortAndLimitValuesIfNeeded(resultLines);

    return resultLines;
  }

  sortAndLimitValuesIfNeeded(resultLines) {
    if (this.args.dataConfig.yAxis?.data?.sorting === SORTING_LABEL_NAME) {
      resultLines.sort(function (lineA, lineB) {
        return lineA.name.localeCompare(lineB.name);
      });
    }

    let limit = this.args.dataConfig.yAxis?.data?.limit;
    if (limit) {
      resultLines = resultLines.slice(0, limit);
    }

    return resultLines;
  }

  mapLineLabel(label) {
    let labelMappings = this.args.dataConfig.yAxis.data.labelMappings;
    let defaultLabel = this.args.dataConfig.yAxis.data.defaultLabel;
    if (labelMappings) {
      return labelMappings[label] || defaultLabel || label;
    }
    return label;
  }

  get chartOptions() {
    let options = {
      range: this.args.range,
      chartData: this.chartData,
      width: this.args.width,
      viewConfig: this.args.viewConfig,
      xAxisType: this.args.dataConfig.xAxis.type,
    };

    let builder = new LinechartBuilder(options);

    return builder.buildTheme();
  }

  get hasData() {
    return this.chartData.some((singleSeries) => {
      return singleSeries.data.some((dataArray) => dataArray[1]);
    });
  }
}
