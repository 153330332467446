/* import __COLOCATED_TEMPLATE__ from './reassign-ownership-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import teamsThatWillBeEmpty from 'embercom/lib/teams/to-be-empty-teams';
import { tracked } from '@glimmer/tracking';

export default class ReassignOwnershipModal extends Component {
  @service appService;
  @tracked cannotConfirmReassignments = true;
  reassignments = this.args.reassignments;

  get isPricing5() {
    return this.appService.app.onPricing5;
  }

  get teammateIds() {
    let teammateIds = [];
    if (!this.args.isBulkEditing) {
      teammateIds.push(this.args.teammate.id);
    } else {
      this.args.teammates.every((teammate) => teammateIds.push(teammate.id));
    }

    return teammateIds;
  }

  get nameLabel() {
    if (this.args.teammates) {
      if (this.args.teammates.length > 1) {
        return 'their';
      } else {
        return `${this.args.teammates[0].name}'s`;
      }
    }

    return `${this.args.teammate.name}'s`;
  }

  get possibleAssigneesForConversations() {
    return this.appService.app.assignableAdmins // app.assignableAdmins includes teams
      .reject((a) => this.teammateIds.includes(a.id))
      .filter((t) => !this.teamsThatWillBeEmpty.findBy('id', t.id));
  }

  get possibleSendersForMessages() {
    return this.appService.app.assignableCreators.reject((admin) =>
      this.teammateIds.includes(admin.id),
    );
  }

  get teamsThatWillBeEmpty() {
    return teamsThatWillBeEmpty(this.args.app, this.args.teammates || [this.args.teammate]);
  }

  get selectionIsValid() {
    let reassignments = this.args.reassignments;
    return (
      isPresent(reassignments.conversations) &&
      isPresent(reassignments.messages) &&
      isPresent(reassignments.articles) &&
      isPresent(reassignments.ownedUsersLeadsAndAccounts) &&
      this.teamsThatWillBeEmpty.every((t) => isPresent(reassignments.teams[t.id]))
    );
  }

  @action
  updateSelection(key, assigneeId) {
    this.reassignments[key] = assigneeId;
    let validReassignmentSelection = this.selectionIsValid;
    this.args.updateSelection(validReassignmentSelection, this.reassignments);
    this.cannotConfirmReassignments = !validReassignmentSelection;
  }

  @action
  updateTeamSelection(teamId, teammateId) {
    this.reassignments.teams[teamId] = teammateId;
    let validReassignmentSelection = this.selectionIsValid;
    this.args.updateSelection(validReassignmentSelection, this.reassignments);
    this.cannotConfirmReassignments = !validReassignmentSelection;
  }
}
