/* import __COLOCATED_TEMPLATE__ from './chart-renderer.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { cached } from 'tracked-toolbox';
import TableDataConfigBuilder from 'embercom/lib/reporting/custom/table-data-config-builder';
import DataConfigBuilder from 'embercom/lib/reporting/custom/data-config-builder';
import ViewConfigBuilder from 'embercom/lib/reporting/custom/view-config-builder';
import TableColumnBuilder from 'embercom/lib/reporting/custom/table-column-builder';
import { buildDataConfigWithComparisons } from 'embercom/lib/reporting/comparison-data-config';
import { getOwner } from '@ember/application';
import { isPresent } from '@ember/utils';

export default class ChartRenderer extends Component {
  @cached
  get viewConfig() {
    return new ViewConfigBuilder(this.args.renderableChart, getOwner(this)).buildViewConfig();
  }

  @cached
  get dataConfig() {
    return new DataConfigBuilder(this.args.renderableChart).buildDataConfig();
  }

  get comparisonCounterDataConfig() {
    if (this.args.renderableChart.isComparisonCounter) {
      return buildDataConfigWithComparisons(this.dataConfig, this.args.renderableChart.dateRange);
    } else {
      return this.dataConfig;
    }
  }

  get timeComparisonDataConfig() {
    if (this.args.renderableChart.showTimeComparison) {
      return buildDataConfigWithComparisons(this.dataConfig, this.args.renderableChart.dateRange);
    }
    return this.dataConfig;
  }

  @cached
  get tableDataConfig() {
    return new TableDataConfigBuilder(this.args.renderableChart).buildDataConfig();
  }

  @cached
  get tableViewConfig() {
    return this.args.renderableChart.buildViewConfigForTable();
  }

  get columns() {
    return new TableColumnBuilder(this.args.renderableChart, getOwner(this)).buildColumns();
  }

  get componentName() {
    return `reporting/bespoke/${this.args.renderableChart.componentName}`;
  }

  get shouldRenderChrome() {
    return this.args.renderableChart.shouldRenderChrome;
  }

  get csvFileNamePrefix() {
    let title = this.args.chartTitle || '';
    return title.trim().replaceAll(' ', '_');
  }

  get includeOfficeHours() {
    return (
      isPresent(this.args.renderableChart.reportState) &&
      isPresent(this.args.renderableChart.reportState.settings) &&
      this.args.renderableChart.reportState.settings.excludeOooHours
    );
  }

  get isPaywalled() {
    if (isPresent(this.args.dashboard)) {
      // this is needed because we use the Dashboard component in Reports 1, which sends the isPaywalled prop when active
      // reports 2 use the paywall directly and the check is with the isActive prop
      return this.args.dashboard.isPaywalled || this.args.dashboard.isActive;
    }
    return false;
  }
}
