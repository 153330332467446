/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { stateOptions } from 'embercom/models/outbound-subscriptions/subscription-type';
import {
  objectTypes,
  humanReadableObjectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { readableConsentType } from 'embercom/models/outbound-subscriptions/subscription-type';

export default class Table extends Component {
  @service notificationsService;
  @service router;
  @service intercomEventService;

  readableConsentType = readableConsentType;
  humanReadableObjectNames = humanReadableObjectNames;
  objectTypes = objectTypes;

  get items() {
    return this.args.items
      .filter((item) => !item.isNew)
      .sort((a, b) => {
        if (a.translationForTeammate && b.translationForTeammate) {
          if (
            a.translationForTeammate.name.toUpperCase() <
            b.translationForTeammate.name.toUpperCase()
          ) {
            return -1;
          }
          if (
            a.translationForTeammate.name.toUpperCase() >
            b.translationForTeammate.name.toUpperCase()
          ) {
            return 1;
          }
        }
        return 0;
      });
  }

  @action
  activate(subscriptionType) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'put_live_action',
      previous_state: subscriptionType.stateLabel,
    });
    this.updateState(stateOptions.live, subscriptionType.id);
  }

  @action
  archive(subscriptionType) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'archive_action',
      previous_state: subscriptionType.stateLabel,
    });
    this.updateState(stateOptions.archived, subscriptionType.id);
  }

  async updateState(newState, subscriptionTypeId) {
    let subscriptionType = this.args.items.findBy('id', subscriptionTypeId);
    subscriptionType.state = newState;
    try {
      await subscriptionType.save();
    } catch (error) {
      subscriptionType.rollbackAttributes();
      this.notificationsService.notifyResponseError(error);
    }
  }
}
