/* import __COLOCATED_TEMPLATE__ from './self-serve-resolutions.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { SELF_SERVE_FAILED, SELF_SERVE_RESOLVED } from 'embercom/lib/reporting/csf-queries';
import { getTimeConfig } from 'embercom/lib/reporting/csf-query-helper';
import PALETTE from '@intercom/pulse/lib/palette';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import countFormatter from 'embercom/lib/count-formatter';
import { buildDataConfigWithComparisons } from 'embercom/lib/reporting/comparison-data-config';

const SELF_SERVE_RESOLVED_LABEL = 'Resolved by Self-serve';
const SERVED_BY_TEAMMATE_LABEL = 'Served by a teammate after self-serve';

const QUERY_LABEL_MAP = {
  'self-serve-resolved-bar': SELF_SERVE_RESOLVED_LABEL,
  'self-serve-failed-human-support-bar': SERVED_BY_TEAMMATE_LABEL,
};

const LABEL_TOOLTIP_MAP = {
  [SELF_SERVE_RESOLVED_LABEL]: 'resolved with Self-serve content',
  [SERVED_BY_TEAMMATE_LABEL]: 'served by a teammate after self-serve',
};

export const COLOR_LABEL_MAP = {
  [SELF_SERVE_RESOLVED_LABEL]: 'vis-pink-50',
  [SERVED_BY_TEAMMATE_LABEL]: 'vis-yellow-50',
};

export const QUERY_IMPROVEMENT_DIRECTION_MAP = {
  'self-serve-resolved-bar': improvementDirections.INCREASING,
  'self-serve-failed-human-support-bar': improvementDirections.DECREASING,
};

export default class SelfServeResolutions extends Component {
  @service appService;
  @service paywallService;

  get viewConfig() {
    return {
      seriesColorMap: this.barColors,
      showLegend: true,
      disabledLegends: this.barDisabledLegends,
      legendMappingFunction: this.mapQueryNameToLabel,
      tooltipTextMappingFunction: this.mapLabelToTooltipText,
      labelColorMap: COLOR_LABEL_MAP,
      improvementDirectionMappingFunction: this.mapQueryNameToImprovementDirection,
    };
  }

  get time() {
    return getTimeConfig({ range: this.args.range });
  }

  get dataConfig() {
    return buildDataConfigWithComparisons(
      {
        series: [
          {
            name: 'self-serve-failed-human-support-bar',
            ...SELF_SERVE_FAILED,
            time: this.time,
          },
          {
            name: 'self-serve-resolved-bar',
            ...SELF_SERVE_RESOLVED,
            time: this.time,
          },
        ],
      },
      this.args.range,
    );
  }

  get barDisabledLegends() {
    return {
      'Resolved by Self-serve': this.selfServeDisabled,
      'Served by a teammate after self-serve': this.selfServeDisabled,
    };
  }

  get barColors() {
    return {
      'Resolved by Self-serve': this.selfServeDisabled
        ? 'rgb(222, 120, 157, 0.2)'
        : PALETTE['vis-pink-50'],
      'Served by a teammate after self-serve': this.selfServeDisabled
        ? 'rgb(240, 202, 0, 0.2)'
        : PALETTE['vis-yellow-50'],
    };
  }

  get featureKey() {
    let key = 'help_center';
    if (this.restrictedBy('answer_workflows')) {
      key = 'answer_workflows';
    }
    return key;
  }

  get botDisabled() {
    return this.restrictedBy('answer_workflows');
  }

  get articleDisabled() {
    return this.restrictedBy('help_center');
  }

  get selfServeDisabled() {
    return this.articleDisabled && this.botDisabled;
  }

  get selfServeEnabled() {
    return !this.selfServeDisabled;
  }

  restrictedBy(featureName) {
    return this.paywallService.isPaywallActive({ featureName });
  }

  mapQueryNameToLabel(queryName) {
    return QUERY_LABEL_MAP[queryName];
  }

  mapLabelToTooltipText(label) {
    return LABEL_TOOLTIP_MAP[label];
  }

  mapQueryNameToImprovementDirection(series) {
    return QUERY_IMPROVEMENT_DIRECTION_MAP[series.requestName];
  }

  tooltipValueMappingFunction(value) {
    return countFormatter(value);
  }
}
