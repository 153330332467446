/* import __COLOCATED_TEMPLATE__ from './schedule-sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import WeekDays from 'embercom/lib/reporting/week-days';
import moment from 'moment-timezone';
import { getOwner } from '@ember/application';

export default class ScheduleSidebarComponent extends Component {
  weekDays = new WeekDays(getOwner(this)).values;

  @service store;
  @service intl;

  get schedules() {
    // Sort the array of schedule IDs by descending order of creation, so that last schedule is displayed on top
    return this.args.scheduleIds
      .sort()
      .reverse()
      .map((schId) => {
        let scheduleRecord = this.store.peekRecord('reporting/message-data-export-schedule', schId);
        return {
          id: schId,
          name: scheduleRecord.name,
          description: this._description(
            scheduleRecord.timeToExport,
            scheduleRecord.dayOfWeek,
            scheduleRecord.dayOfMonth,
          ),
        };
      });
  }

  _description(timeToExport, dayOfWeek, dayOfMonth) {
    let frequency;
    let time = moment({ hour: timeToExport }).format('h:mmA');

    if (dayOfMonth) {
      frequency = this.intl.t(
        'components.reporting.customer-support.export.schedule-sidebar.schedule-description.frequencies.monthly',
        {
          dayOfMonth,
          time,
        },
      );
    } else if (dayOfWeek !== undefined && dayOfWeek !== null) {
      frequency = this.intl.t(
        'components.reporting.customer-support.export.schedule-sidebar.schedule-description.frequencies.weekly',
        {
          dayOfWeek: this.weekDays[dayOfWeek],
          time,
        },
      );
    } else {
      frequency = this.intl.t(
        'components.reporting.customer-support.export.schedule-sidebar.schedule-description.frequencies.daily',
        {
          time,
        },
      );
    }

    return frequency;
  }
}
