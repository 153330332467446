/* import __COLOCATED_TEMPLATE__ from './teammate-assigned-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';

const UNASSIGNED_TEAMMATE_TEXT = 'None';
const UNASSIGNED_TEAMMATE_ID = '0';

export default class TeammateAssignedFilter extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;
  @tracked availableTeammates = this.teammatesWithUnassigned;
  @tracked label = this.currentLabel;
  selectedDropdownItems = this.args.selected;

  get items() {
    return [
      {
        heading: 'Teammates',
        items: this.allItems,
      },
    ];
  }

  get teammatesWithUnassigned() {
    return [this.appService.app.unassignedAdmin].concat(this.appService.app.humanAdmins);
  }

  teammateItemLabel(teammate) {
    if (teammate.id === UNASSIGNED_TEAMMATE_ID) {
      return UNASSIGNED_TEAMMATE_TEXT;
    } else {
      return teammate.display_as_assignee;
    }
  }

  get allItems() {
    return this.availableTeammates.map((teammate) => ({
      model: teammate,
      text: this.teammateItemLabel(teammate),
      value: teammate.id.toString(),
      component: 'reporting/flexible/filter-bar/teammate-item',
      isSelected: this.args.selected.includes(teammate.id.toString()),
    }));
  }

  get selectedTeammateIds() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get name() {
    return this.intl.t(
      'components.reporting.custom.chart-builder.filter-bar.teammate-assigned-filter.teammate-assigned',
    );
  }

  get selectAllLabel() {
    return this.intl.t(
      'components.reporting.custom.chart-builder.filter-bar.teammate-assigned-filter.any-teammate-currently-assigned',
    );
  }

  get currentLabel() {
    if (this.selectedTeammateIds.length === 0) {
      return 'Any';
    }

    let teammateNames = this.selectedTeammateIds.map((teammateId) => {
      let teammate = this.availableTeammates.find(
        (teammate) => teammate.id.toString() === teammateId,
      );
      return this.teammateItemLabel(teammate);
    });
    return teammateNames.join(', ');
  }

  @action
  onClose() {
    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_assigned_teammate',
      object: this.args.analyticsObject,
      teammate_count: this.selectedTeammateIds.length,
    });

    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }
}
