/* import __COLOCATED_TEMPLATE__ from './share.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type NativeArray from '@ember/array/-private/native-array';
import { A } from '@ember/array';
import { first, flatten, isEmpty, rest } from 'underscore';
import Admin from 'embercom/models/admin';
import { action } from '@ember/object';
import type Report from 'embercom/models/reporting/custom/report';
import type ReportAccessService from 'embercom/services/report-access-service';
import { AccessType } from 'embercom/services/report-access-service';
import { dropTask, task } from 'ember-concurrency-decorators';
import type ReportAccessControlList from 'embercom/models/reporting/report-access-control-list';
import { taskFor } from 'ember-concurrency-ts';
import { all } from 'ember-concurrency';
import { cached } from 'tracked-toolbox';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { isPresent } from '@ember/utils';
import Role from 'embercom/models/role';
import { trackedFunction } from 'ember-resources/util/function';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  model: { report: Report; newReportShareMode: boolean };
}

interface Signature {
  Args: Args;
}

export type Entity = Admin | Role;

export interface AccessOption {
  text: string;
  description: string;
  icon: InterfaceIconName;
  accessType: AccessType | null;
}

interface SelectedValue {
  text: string;
  description: string;
  icon: InterfaceIconName;
  accessType: AccessType | null;
}

enum AccessTableRowType {
  INTERCOM = 'intercom',
  ADMIN = 'admin',
  ROLE = 'role',
}

class AccessTableRow {
  public type: AccessTableRowType;
  public reportAccessControlList: ReportAccessControlList | null;

  constructor(type: AccessTableRowType, reportAccessControlList: ReportAccessControlList | null) {
    this.type = type;
    this.reportAccessControlList = reportAccessControlList;
  }
}

export default class ReportingCustomReportShare extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare reportAccessService: ReportAccessService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare modalService: $TSFixMe;
  @service declare intercomEventService: any;
  @service declare notificationsService: $TSFixMe;

  @tracked selectedEntities: NativeArray<Entity> = A([]);
  @tracked userInput = '';
  @tracked accessControlList: ReportAccessControlList[] = [];
  @tracked rolesList: Role[] = [];
  @tracked controlsLoaded = 0;
  @tracked declare lastAddedEntity?: Entity;

  @tracked declare selectedValue: string;
  @tracked focusActive = false;

  sharedAnalyticsData = {
    object: 'report_access',
    section: 'reports',
  };

  loadAccessControlTask = trackedFunction(this, async () => {
    try {
      return await taskFor(this.loadAccessControlList).perform();
    } catch (e) {
      if (e.name === 'TaskCancelation') {
        return;
      } else {
        throw e;
      }
    }
  });

  @task({ drop: true })
  *loadRoles() {
    let roles: Role[] = yield this.store.findAll('role');
    this.rolesList = roles;
  }

  get hasAddedRoles() {
    return this.accessControlList.filterBy('isNew').any((control) => control.isRoleAccessControl);
  }
  get report() {
    return this.args.model.report;
  }

  get app() {
    return this.appService.app;
  }

  get newReportShareMode() {
    return this.args.model.newReportShareMode;
  }

  @action
  openHelpArticle() {
    safeWindowOpen(
      'https://www.intercom.com/help/en/articles/9867813-internal-report-sharing-beta',
    );
  }

  @action
  isCreator(adminId: string) {
    return adminId === this.report.createdById;
  }

  @action
  isAdminAndCreator(accessControl: ReportAccessControlList) {
    return (
      accessControl.isAdminAccessControl &&
      accessControl.adminId &&
      this.isCreator(accessControl.adminId)
    );
  }

  @cached
  get tableData(): AccessTableRow[] {
    // We want the order [owner, role, admins]
    let adminAccess = this.accessControlList
      // We do control.admin here because admin could have been deleted (not a foreign key relationship in backend).
      // https://github.com/intercom/intercom/pull/360789/files#diff-cbf8b25d6d853acf58fa9057841f407d29ffe90649c75cf9e3f51b2d6e3aa1d3R7338
      .filter((control) => control.isAdminAccessControl && control.admin)
      .sort((a, b) => {
        // Sort the owner to be first in the list
        if (a.adminId === this.reportOwner?.id) {
          return -1;
        } else if (b.adminId === this.reportOwner?.id) {
          return 1;
        } else {
          return a.admin!.name.localeCompare(b.admin!.name);
        }
      });

    // We do control.role here because role could have been deleted (not a foreign key relationship in backend).
    // https://github.com/intercom/intercom/pull/360789/files#diff-cbf8b25d6d853acf58fa9057841f407d29ffe90649c75cf9e3f51b2d6e3aa1d3R7338
    let roleAccess = this.accessControlList
      .filter((control) => control.isRoleAccessControl && control.role)
      .sort((a, b) => a.role!.name.localeCompare(b.role!.name));

    let accessTableRows: AccessTableRow[] = [];

    if (this.report.isIntercomOwnedReport) {
      accessTableRows.push(new AccessTableRow(AccessTableRowType.INTERCOM, null));
    } else if (first(adminAccess) && this.isAdminAndCreator(first(adminAccess)!)) {
      accessTableRows.push(new AccessTableRow(AccessTableRowType.ADMIN, first(adminAccess)!));
      adminAccess = rest(adminAccess);
    }

    flatten([roleAccess, adminAccess])
      .compact()
      .forEach((control) => {
        if (control.isAdminAccessControl) {
          accessTableRows.push(new AccessTableRow(AccessTableRowType.ADMIN, control));
        } else if (control.isRoleAccessControl) {
          accessTableRows.push(new AccessTableRow(AccessTableRowType.ROLE, control));
        }
      });

    return accessTableRows;
  }

  get shareGroupList() {
    return [
      {
        items: [
          {
            text: this.intl.t('reporting.custom-reports.report.share-modal.full-access'),
            description: this.intl.t(
              'reporting.custom-reports.report.share-modal.full-access-description',
            ),
            icon: 'edit',
            value: AccessType.EDIT,
          },
          {
            text: this.intl.t('reporting.custom-reports.report.share-modal.explore-only'),
            description: this.intl.t(
              'reporting.custom-reports.report.share-modal.explore-only-description',
            ),
            icon: 'search',
            value: AccessType.VIEW,
          },
          {
            text: this.intl.t('reporting.custom-reports.report.share-modal.view-only'),
            description: this.intl.t(
              'reporting.custom-reports.report.share-modal.view-only-description',
            ),
            icon: 'eye',
            value: AccessType.RESTRICTED_VIEW,
          },
        ],
      },
    ];
  }

  @cached
  get currentAdmin() {
    return this.appService.app.currentAdmin;
  }

  @cached
  get humanAdmins() {
    return this.store.peekAll('admin').filter((admin) => admin.isHuman);
  }

  get availableTeammates() {
    let selectedTeammatesId = new Set(this.selectedTeammates.map((teammate) => teammate.id));
    let invitedAdminIds = new Set(this.accessControlList.map(({ adminId }) => adminId));
    return this.humanAdmins.reject(
      (admin) => selectedTeammatesId.has(admin.id) || admin.is_me || invitedAdminIds.has(admin.id),
    ); // we don't want to share with ourselve, admin selected for sharing or someone we already shared with :-)
  }

  get availableRoles() {
    let selectedRoleIds = new Set(this.selectedRoles.map((role) => role.id));
    let invitedRoleIds = new Set(
      this.accessControlList
        .filter((control) => control.isRoleAccessControl)
        .map((control) => control.roleId),
    );
    return this.rolesList.reject(
      (role) =>
        selectedRoleIds.has(role.id) || invitedRoleIds.has(role.id) || this.getSize(role) === 0,
    ); // we don't want to share with already selected role or role we've shared with :-)
  }

  get workspaceOptions() {
    return [
      {
        text: this.intl.t('reporting.custom-reports.report.share-modal.restricted'),
        onSelect: () => {
          this.unsetWorkspaceAccess();
        },
        icon: 'locked' as InterfaceIconName,
        isSelected: !isPresent(this.workspaceAccessControl),
        description: this.intl.t(
          'reporting.custom-reports.report.share-modal.restricted-description',
        ),
      },
      {
        text: this.appService.app.name,
        onSelect: () => {
          this.setWorkspaceAccess();
        },
        icon: 'company' as InterfaceIconName,
        isSelected: isPresent(this.workspaceAccessControl),
        description: this.intl.t('reporting.custom-reports.report.share-modal.shared-description'),
      },
    ];
  }

  @action
  unsetWorkspaceAccess() {
    if (this.workspaceAccessControl) {
      this.accessControlList.removeObject(this.workspaceAccessControl);
    }
  }

  @action
  setWorkspaceAccess(accessType = AccessType.RESTRICTED_VIEW) {
    if (!this.workspaceAccessControl) {
      let accessControl = this.store.createRecord('reporting/report-access-control-list', {
        reportId: this.report.id,
        accessType,
        adminId: null,
      });
      this.accessControlList.addObject(accessControl);
    } else {
      this.workspaceAccessControl.accessType = accessType;
      this.workspaceAccessControl.reportId = this.report.id;
    }
  }

  get suggestedTeammates(): Admin[] {
    if (isEmpty(this.userInput)) {
      return this.availableTeammates;
    }
    return this.availableTeammates.filter((admin: Admin) =>
      this.compareWithoutAccents(admin.name, this.userInput),
    );
  }

  get suggestedRoles(): Role[] {
    let roles: Role[] = isEmpty(this.userInput)
      ? this.availableRoles
      : this.availableRoles.filter((role: Role) =>
          this.compareWithoutAccents(role.name, this.userInput),
        );

    return roles.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
  }

  get suggestedEntities() {
    //Roles appears first in UI
    return flatten([this.suggestedRoles, this.suggestedTeammates]);
  }

  private removeAccents(name: string) {
    // normalize("NFD"): This breaks the accented characters into their base characters and the diacritical marks.
    // .replace(/[\u0300-\u036f]/g, ""): This removes the diacritical marks by targeting the Unicode range for diacritics.
    return name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  private compareWithoutAccents(value: string, target: string) {
    let cleanedValue = value.toLowerCase();
    let cleanedTarget = target.toLowerCase();
    return this.removeAccents(cleanedValue).includes(this.removeAccents(cleanedTarget));
  }

  @action
  selectEntity(entity: Entity) {
    // avoid adding empty entity
    if (entity) {
      this.selectedEntities.addObject(entity);
      this.lastAddedEntity = entity;
    }
  }

  get selectedTeammates() {
    return this.selectedEntities.filter((entity) => entity instanceof Admin);
  }

  get selectedRoles() {
    return this.selectedEntities.filter((entity) => entity instanceof Role);
  }

  get hasSuggestedTeammates() {
    return this.suggestedTeammates.length > 0;
  }

  get hasSuggestedRoles() {
    return this.suggestedRoles.length > 0;
  }

  @action
  clearUserInput() {
    this.userInput = '';
  }

  @action
  runSearch(e: InputEvent & { target: HTMLInputElement }) {
    this.userInput = e.target.value;
  }

  @action
  isTeammate(entity: Entity) {
    return entity instanceof Admin;
  }

  get columns() {
    return [
      {
        label: this.intl.t('reporting.custom-reports.report.share-modal.who-has-access'),
        valuePath: 'entity.name',
        type: 'avatar-with-text',
        isSortable: false,
      },
      {
        label: this.intl.t('reporting.custom-reports.report.share-modal.access'),
        valuePath: 'accessType',
        isSortable: false,
      },
    ];
  }

  get sharingHasChanged() {
    return (
      this.controlsLoaded !== this.accessControlList.length ||
      this.accessControlList.some((control) => control.get('hasDirtyAttributes'))
    );
  }

  get surveyDisabled() {
    return this.sharingHasChanged || this.newReportShareMode;
  }

  @dropTask
  *onModalClose(triggerSurvey: boolean) {
    if (this.sharingHasChanged) {
      let confirmed: boolean = yield this.intercomConfirmService.confirm({
        title: this.intl.t('reporting.custom-reports.report.share-modal.confirmation-modal.title'),
        body: this.intl.t('reporting.custom-reports.report.share-modal.confirmation-modal.body'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'reporting.custom-reports.report.share-modal.confirmation-modal.confirm-btn-text',
        ),
        distinguishCancelFromClose: false,
      });
      if (confirmed) {
        this.closeModalAndReset();
      }
    } else if (triggerSurvey) {
      this.triggerFeedbackSurvey();
      this.closeModalAndReset();
    } else {
      this.closeModalAndReset();
    }
  }

  closeModalAndReset() {
    this.reportAccessService.localAccessControls.forEach((control) => control.rollbackAttributes());
    this.clearUserInput();
    this.selectedEntities.clear();
    this.focusActive = false;
    this.modalService.closeModal();
  }

  get removingOwnEditAccess() {
    // user is removing own access if after selection they do not have edit access
    let currentAdminAccess = this.accessControlList.filter((control) =>
      this.reportAccessService.shouldConsiderControl(this.currentAdmin, control, this.report.id),
    );

    return (
      !currentAdminAccess.any((control) => control.accessType === AccessType.EDIT) &&
      !this.newReportShareMode
    );
  }

  @dropTask
  *saveChanges() {
    if (this.removingOwnEditAccess) {
      let confirmed: boolean = yield this.intercomConfirmService.confirm({
        title: this.intl.t(
          'reporting.custom-reports.report.share-modal.removal-warning-modal.title',
        ),
        body: this.intl.t('reporting.custom-reports.report.share-modal.removal-warning-modal.body'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'reporting.custom-reports.report.share-modal.removal-warning-modal.confirm-btn-text',
        ),
      });
      if (!confirmed) {
        return;
      }
    }

    yield taskFor(this.reportAccessService.updateReportAccess).perform(
      this.report,
      this.accessControlList,
    );

    this.notificationsService.notifyConfirmation(
      this.intl.t('reporting.custom-reports.report.share-modal.save-success'),
    );

    // Reload so records are no longer considered dirty
    yield taskFor(this.loadAccessControlList).perform();

    this.closeModalAndReset();
  }

  @action
  handleConfirmClick() {
    taskFor(this.saveChanges).perform();

    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      action: 'confirm',
      report_id: this.report.id,
    });
  }

  get placeHolder() {
    return isEmpty(this.selectedEntities)
      ? this.intl.t('reporting.custom-reports.report.share-modal.invite-teammates-or-roles')
      : undefined;
  }

  @action
  handleBackspace() {
    if (isEmpty(this.userInput)) {
      this.selectedEntities = this.selectedEntities.slice(0, this.selectedEntities.length - 1);
    }
  }

  @action
  handleInput(
    popover: { hide: () => void; show: () => void },
    e: InputEvent & { target: HTMLInputElement },
  ) {
    this.userInput = e.target.value;
    if (isEmpty(this.userInput)) {
      popover.hide();
    } else {
      popover.show();
    }
  }

  @action
  handleEnter(popover: { hide: () => void; show: () => void }) {
    let selectedEntity = first(this.suggestedEntities);
    if (selectedEntity && isPresent(this.userInput)) {
      this.selectEntity(selectedEntity);
      this.clearUserInput();
      popover.hide();
    }
  }

  @action
  onClickSuggestion(popover: { hide: () => void; show: () => void }, entity: Entity) {
    this.selectEntity(entity);
    this.clearUserInput();
    this.focusOnInput();
    popover.hide();
  }

  @action
  handleDelete(selectedEntity: Entity) {
    if (this.isTeammate(selectedEntity)) {
      this.selectedEntities = this.selectedEntities.reject(
        (entity) => entity.id === selectedEntity.id && entity instanceof Admin,
      );
    } else if (selectedEntity instanceof Role) {
      this.selectedEntities = this.selectedEntities.reject(
        (entity) => entity.id === selectedEntity.id && entity instanceof Role,
      );
    }
  }

  @action
  changeAccess(reportAccessControlList: ReportAccessControlList, selectedValue: SelectedValue) {
    reportAccessControlList.accessType = selectedValue.accessType!;
  }

  @action
  changeWorkspaceAccess(selectedValue: SelectedValue) {
    // TODO can this just be replaced by changeAccess above? When would accessType be null?
    if (selectedValue.accessType && this.workspaceAccessControl) {
      this.workspaceAccessControl.accessType = selectedValue.accessType;
    }
  }

  @action
  addEntitiesWithDefaultAccessType() {
    this.addEntitiesWithAccessType(this.reportAccessService.DEFAULT_NEW_WORKSPACE_ACCESS);
  }

  @action
  addEntitiesWithAccessType(selectedAccessType: AccessType) {
    let entitiesToAdd: NativeArray<ReportAccessControlList> = this.selectedEntities.map(
      (entity) => {
        if (entity instanceof Admin) {
          return this.store.createRecord('reporting/report-access-control-list', {
            reportId: this.report.id,
            accessType: selectedAccessType,
            adminId: entity.id,
            roleId: null,
          });
        } else {
          return this.store.createRecord('reporting/report-access-control-list', {
            reportId: this.report.id,
            accessType: selectedAccessType,
            roleId: entity.id,
            adminId: null,
          });
        }
      },
    );

    this.accessControlList.addObjects(entitiesToAdd);
    this.selectedEntities.clear();
  }

  private get reportOwner(): Admin | null {
    return this.report.isIntercomOwnedReport
      ? null
      : this.store.peekRecord('admin', this.report.createdById);
  }

  @task({ drop: true })
  *loadAccessControlList() {
    let results: any[] = yield all([
      taskFor(this.loadRoles).perform(),
      this.reportAccessService.fetchReportAccess.perform(this.report.id),
    ]);
    let result: ReportAccessControlList[] = results[1];

    // Track the number loaded to detect changes
    this.controlsLoaded = result.length;

    // Clone the array to make it mutable
    this.accessControlList = result.toArray();
    if (this.newReportShareMode) {
      this.setWorkspaceAccess(this.reportAccessService.DEFAULT_NEW_WORKSPACE_ACCESS);
    }
  }

  get isDisabled() {
    if (this.newReportShareMode) {
      return false;
    } else {
      return !this.sharingHasChanged;
    }
  }

  get disableSuggestion() {
    // we don't want to suggested entities when we are loading invited entities or when app has no teammates
    return (
      (!this.hasSuggestedTeammates && !this.hasSuggestedRoles) ||
      this.loadAccessControlTask.isLoading
    );
  }

  get workspaceAccessControl(): ReportAccessControlList | undefined {
    return this.accessControlList.find((control) => control.isWorkspaceAccessControl);
  }

  @action
  removeAccess(reportAccessControlList: ReportAccessControlList) {
    this.accessControlList.removeObject(reportAccessControlList);
    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      action: 'delete',
      report_id: this.report.id,
      is_workspace: reportAccessControlList.isWorkspaceAccessControl,
      access_type: reportAccessControlList.accessType,
    });
  }

  @action
  getAdminName(selectedAdmin: Admin) {
    if (this.isCreator(selectedAdmin.id) && this.currentAdmin.id === selectedAdmin.id) {
      // display "YOU" only when logged-in user is creator
      return this.intl.t('reporting.custom-reports.report.share-modal.you');
    } else {
      return selectedAdmin.name;
    }
  }

  get reportUrl() {
    return `${window.location.origin}/a/apps/${this.appService.app.id}/reports/custom-reports/report/${this.report.id}`;
  }

  @action
  revokeAccessForReport() {
    let ownerAccess = this.accessControlList.find(
      (control) => control.adminId === this.reportOwner?.id,
    );

    let currentAdminAccess = this.reportAccessService.currentAdminReportAccess(this.report.id);

    if (this.isCreator(this.currentAdmin.id)) {
      this.accessControlList = [ownerAccess].compact();
    } else if (currentAdminAccess?.isAdminAccessControl) {
      this.accessControlList = [ownerAccess, currentAdminAccess].compact();
    } else if (
      currentAdminAccess?.isRoleAccessControl ||
      currentAdminAccess?.isWorkspaceAccessControl
    ) {
      this.accessControlList = [
        ownerAccess,
        this.store.createRecord('reporting/report-access-control-list', {
          reportId: this.report.id,
          accessType: currentAdminAccess.accessType,
          adminId: this.app.currentAdmin.id,
          roleId: null,
        }),
      ].compact();
    }

    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      action: 'revoked',
      report_id: this.report.id,
    });
  }

  @action
  focusOnInput() {
    this.focusActive = true;
  }

  get reportAccessibleByNonOwnerAdmins() {
    let nonOwnerAccessTableRows = this.tableData.reject(
      (accessTableRow) =>
        accessTableRow.type === AccessTableRowType.INTERCOM ||
        this.isAdminAndCreator(accessTableRow.reportAccessControlList!),
    );

    return nonOwnerAccessTableRows.length > 0;
  }

  @cached
  get perRoleSize() {
    return Role.adminPerRole();
  }

  @action
  getSize(entity: Entity) {
    if (entity instanceof Role) {
      return this.perRoleSize[entity.id] ?? 0;
    }
    return 0;
  }

  @action
  triggerFeedbackSurvey() {
    window.Intercom('startSurvey', 42998475);
  }

  get enableTooltip() {
    // we want to display tooltip when button is not disabled and teammate has added role
    return !this.isDisabled && this.hasAddedRoles;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::Share': typeof ReportingCustomReportShare;
    'reporting/custom/report/share': typeof ReportingCustomReportShare;
  }
}
