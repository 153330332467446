/* import __COLOCATED_TEMPLATE__ from './date-filter-block.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Range from 'embercom/models/reporting/range';
import { action } from '@ember/object';

export default class DateFilterBlock extends Component {
  @service appService;
  range = this.args.selected?.values || Range.createFromPreset('past_4_weeks', this.timezone);

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }
    if (!this.args.selected?.values) {
      this.args.setSelected(this.range);
    }
  }

  get timezone() {
    return this.args.timezone || this.appService.app.timezone;
  }
}
