/* import __COLOCATED_TEMPLATE__ from './horizontal-bar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import HorizontalbarBuilder from 'embercom/lib/reporting/flexible/horizontalbar-builder';
import PALETTE from '@intercom/pulse/lib/palette';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { cached } from 'tracked-toolbox';

const EMPTY_STATE_COLOR = PALETTE['gray-light'];

export default class HorizontalBar extends Component {
  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.args.dataConfig,
    viewConfig: this.args.viewConfig,
  }));

  @cached
  get chartData() {
    let seriesData = this.args.dataConfig.xAxis
      ? this.buildSeriesFromGroupedResponses(this.dataResource.rawChartData)
      : this.buildSeriesFromDataResponses(this.dataResource.rawChartData);
    return this.updateSeriesForTooltips(seriesData);
  }

  buildSeriesFromDataResponses(dataResponses) {
    return dataResponses.map((dataResponse) => {
      let series = {};
      series.name = this.mapLabel(dataResponse.name);
      series.data = [dataResponse.aggregations[0].values[0]];
      series.requestName = dataResponse.name;
      return series;
    });
  }

  buildSeriesFromGroupedResponses(dataResponses) {
    let allSeries = dataResponses.map((response) => {
      let group = response.groups[0];
      if (!group) {
        return {};
      }
      let aggregation = group.aggregations[0];

      let responseSeries = group.values.map((gv, index) => {
        let series = {};
        series.name = gv;
        series.data = [aggregation.values[index]];
        series.requestName = `${gv}_${response.name}`;
        return series;
      });
      return this.sortSeries(responseSeries);
    });
    return allSeries.flat();
  }

  updateSeriesForTooltips(series) {
    let seriesByPeriod = this.splitSeriesByPeriod(series);

    return seriesByPeriod.current.map((currentSeries) => {
      let matchingPreviousSeries = seriesByPeriod.previous.find((prevSeries) => {
        return prevSeries.requestName === `${currentSeries.requestName}_previous`;
      });
      return this.setCustomValuesOnSeries(currentSeries, matchingPreviousSeries);
    });
  }

  setCustomValuesOnSeries(series, matchingPreviousSeries) {
    series.previousValue = matchingPreviousSeries?.data[0] || 0;
    if (this.args.viewConfig.improvementDirectionMappingFunction) {
      series.improvementDirection =
        this.args.viewConfig.improvementDirectionMappingFunction(series);
    }
    if (this.args.viewConfig.emojiMappingFunction) {
      series.emoji = this.args.viewConfig.emojiMappingFunction(series.name);
    }
    return series;
  }

  splitSeriesByPeriod(series) {
    let current = [];
    let previous = [];

    series.forEach((singleSeries) => {
      return (singleSeries.requestName?.endsWith('_previous') ? previous : current).push(
        singleSeries,
      );
    });

    return { current, previous };
  }

  sortSeries(series) {
    return series.sort((a, b) => a.name.localeCompare(b.name));
  }

  mapLabel(label) {
    let legendMappingFunction = this.args.viewConfig.legendMappingFunction;
    if (legendMappingFunction) {
      return legendMappingFunction(label);
    }
    return label;
  }

  get chartOptions() {
    let options = this.showData
      ? {
          chartData: this.chartData,
          width: this.args.width,
          viewConfig: this.args.viewConfig,
          dataConfig: this.args.dataConfig,
          range: this.args.range,
        }
      : this.getEmptyStateOptions();

    let builder = new HorizontalbarBuilder(options);
    return builder.buildTheme();
  }

  get chartContent() {
    if (this.showData) {
      return this.chartData;
    }
    return this.getEmptyStateData();
  }

  get getLegendLabels() {
    if (this.args.viewConfig.showLegend && this.chartData.length > 0) {
      let map = this.chartData.map((data) => {
        let label = {};
        label.name = data.name;
        label.color = this.args.viewConfig.labelColorMap[data.name];
        return label;
      });
      return map.reverse();
    }
    return [];
  }

  get showData() {
    return this.hasData && !this.args.disabled;
  }

  get hasData() {
    return this.chartData.some((singleSeries) => {
      return singleSeries.data.some((dataArray) => dataArray > 0);
    });
  }

  getEmptyStateData() {
    if (this.chartData.length > 0) {
      let data = this.chartData.map((e) => {
        let newElement = {};
        newElement.name = e.name;
        newElement.data = [0];
        return newElement;
      });
      data.push({ data: [1], name: 'EmptyState', showInLegend: false });
      return data;
    }
    return [{ data: [1], name: 'EmptyState', showInLegend: false }];
  }

  getEmptyStateOptions() {
    return {
      viewConfig: {
        seriesColorMap: { ...this.args.viewConfig.seriesColorMap, EmptyState: EMPTY_STATE_COLOR },
        showLegend: this.args.viewConfig.showLegend,
        disableTooltip: true,
        disabledLegends: this.args.viewConfig.disabledLegends,
        disableLegendInteraction: true,
        chartHeight: this.args.viewConfig.chartHeight,
      },
      chartData: this.getEmptyStateData(),
      range: this.args.range,
    };
  }
}
