/* import __COLOCATED_TEMPLATE__ from './most-assigned-team.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class MostAssignedTeamComponent extends Component {
  @service appService;
  get team() {
    if (this.args.currentMostAssignedTeam) {
      let teams = this.appService.app.teams;
      return teams.find(
        (team) => team.id.toString() === this.args.currentMostAssignedTeam.team_assignee_id,
      );
    }
  }

  get conversationCount() {
    return this.args.currentMostAssignedTeam?.count;
  }
}
