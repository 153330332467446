/* import __COLOCATED_TEMPLATE__ from './rule-editor-predicates-section.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { copy } from 'ember-copy';

export default Component.extend({
  attributeService: service(),

  init() {
    this._super(...arguments);

    this.set('groupList', [
      {
        hasItemsMarkableAsSelected: true,
        items: [
          {
            text: 'AND',
            value: 'and',
          },
          {
            text: 'OR',
            value: 'or',
          },
        ],
      },
    ]);
  },

  allowedRuleConditionalAttributes: undefined, //this can be overridden externally

  defaultAllowedRuleConditionalAttributes: computed(
    'attributeService.allUserAttributes.[]',
    'attributeService.allCompanyAttributes.[]',
    function () {
      let peopleAttributes = this.get('attributeService.allUserAttributes').mapBy('attribute');
      let companyAttributes = this.get('attributeService.allCompanyAttributes').mapBy('attribute');
      return [
        {
          heading: 'People data',
          attributes: peopleAttributes,
        },
        {
          heading: 'Company data',
          attributes: companyAttributes,
        },
      ];
    },
  ),

  attributeGroupList: computed(
    'allowedRuleConditionalAttributes.[]',
    'defaultAllowedRuleConditionalAttributes',
    function () {
      if (this.allowedRuleConditionalAttributes) {
        return this.allowedRuleConditionalAttributes;
      } else {
        return this.defaultAllowedRuleConditionalAttributes;
      }
    },
  ),

  withLogicalSwitcher: not('withoutLogicalSwitcher'),

  actions: {
    onPredicatesUpdate(rule, predicates) {
      rule.set('predicates', copy(predicates));

      if (this.onChange) {
        this.onChange();
      }
    },
  },
});
