/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import TopicStats from 'embercom/lib/conversational-insights/topic-stats';
import { mapObject } from 'underscore';

export default class TopicListComponent extends Component {
  @service store;
  @service appService;
  @service intercomEventService;
  @service router;
  @service topicStatsService;
  @service conversationalInsights;

  @tracked topicStats;
  @tracked suggestionStats;
  @tracked rows;
  @tracked botInboxTime;
  @tracked showSuggestionsInVisualization = false;
  @tracked suggestions = null;
  @tracked hasTopics = true;
  @tracked totalConversations;
  @tracked conversationsWithTopics;

  sortState = { valuePath: 'topic.name', direction: 'desc' };
  reportingSettings = this.store.queryRecord('reporting/settings', {});

  get botInboxTimeExcluded() {
    return this.botInboxTime === 'excluded';
  }

  @task({ restartable: true })
  *loadSuggestionStats() {
    let range = this.conversationalInsights.currentDateRange;
    let { excludeOooHours } = yield this.reportingSettings;
    this.suggestionStats = yield this.topicStatsService.loadSuggestionStatsInBatches.perform({
      rangeStart: range.start,
      rangeEnd: range.end,
      suggestedTopics: this.suggestions,
      excludeOooHours,
      excludeBotInboxTime: this.botInboxTimeExcluded,
      includePreviousRange: false,
    });
  }

  @task({ drop: true })
  *loadRows() {
    this.rows = undefined;
    this.topicStats = undefined;

    let loadedTopics = yield this.args.topics;
    if (!this.args.topics?.length) {
      this.hasTopics = false;
      return;
    }

    let { excludeOooHours } = yield this.reportingSettings;
    let excludeBotInboxTime = this.botInboxTimeExcluded;
    let range = this.conversationalInsights.currentDateRange;

    let topicIds = loadedTopics.map(({ id }) => id);
    let loadCurrentTask = this.topicStatsService.loadPropagatedTopicStatsInBatches.perform({
      rangeStart: range.start,
      rangeEnd: range.end,
      topicIds,
      excludeOooHours,
      excludeBotInboxTime,
    });

    // Load realtime stats for propagating topics
    let propagatingTopicIds = loadedTopics
      .filter((topic) => topic.isPropagatingInRange(range))
      .map(({ id }) => id);

    let propagatingStatsTask = this.topicStatsService.loadTopicStatsInBatches.perform({
      rangeStart: range.start,
      rangeEnd: range.end,
      topicIds: propagatingTopicIds,
      excludeOooHours,
      excludeBotInboxTime,
      includePreviousRange: true,
    });

    let loadPreviousTask = this.topicStatsService.loadPropagatedTopicStatsInBatches.perform({
      rangeStart: range.comparisonStart,
      rangeEnd: range.comparisonEnd,
      topicIds,
      excludeOooHours,
      excludeBotInboxTime,
    });

    // Load the stats for the current date range
    let allCurrentPropagatedStats = yield loadCurrentTask;
    let formattedCurrentStats = mapObject(allCurrentPropagatedStats, (currentStats) =>
      TopicStats.formatPropagatedStats({ currentStats }),
    );

    let propagatingStats = yield propagatingStatsTask;

    let combinedStats = { ...formattedCurrentStats, ...propagatingStats };
    this._createRowsFromTopicStats(combinedStats);

    // update the topicsStats
    this.topicStats = combinedStats;

    // Load the stats for the comparison date range
    let allPreviousStats = yield loadPreviousTask;
    let formattedPreviousStats = mapObject(allPreviousStats, (previousStats) =>
      TopicStats.formatPropagatedStats({ previousStats }),
    );
    this._updateRowsFromTopicStats({ ...formattedPreviousStats, ...propagatingStats });
  }

  @task({ drop: true })
  *loadTopicConversationCoverage() {
    let range = this.conversationalInsights.currentDateRange;
    let topicCoverage = yield this.topicStatsService.loadTopicConversationCoverage.perform({
      rangeStart: range.start,
      rangeEnd: range.end,
    });
    this.totalConversations = topicCoverage.total_conversations;
    this.conversationsWithTopics = topicCoverage.conversations_with_topics;
  }

  _createRowsFromTopicStats(topicStats) {
    this.rows = this.args.topics.map((topic) => {
      let rawStats = topicStats[topic.id];
      let stats = new TopicStats(rawStats);
      return { topic, stats };
    });
  }

  _updateRowsFromTopicStats(topicStats) {
    for (let topicId in topicStats) {
      if (topicStats.hasOwnProperty(topicId)) {
        let rawStats = topicStats[topicId];
        let rowToUpdate = this.rows.find((row) => row.topic.id === topicId);
        rowToUpdate.stats.update(rawStats);
      }
    }
  }

  @task({ drop: true })
  *loadSuggestions() {
    this.suggestions = yield this.store.findAll(
      'conversational-insights/suggested-conversation-topic',
    );
    yield this.loadSuggestionStats.perform();
  }

  @action
  updateRange(range) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered',
      context: 'topics_list',
      filter: 'date',
      object: 'kpis',
      place: 'topics',
    });

    this.conversationalInsights.updateRange(range);
  }

  @action
  updateBotInboxTime(value) {
    this.botInboxTime = value;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered',
      context: 'topics_list',
      filter: 'bot_time',
      is_on: this.botInboxTimeExcluded,
      place: 'topics',
    });
  }

  @action
  onNewTopicClick() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'new_topic',
      place: 'topics',
    });

    this.router.transitionTo('apps.app.reports.conversational-insights.topics.new');
  }
}
