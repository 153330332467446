/* import __COLOCATED_TEMPLATE__ from './chart-actions-drop-down-item.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */

import templateOnlyComponent from '@ember/component/template-only';
import type { ExploreOption } from 'embercom/components/reporting/custom/chart-action-options';
import { type StampColor } from 'embercom/components/reporting/custom/chart-action-options';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';

interface Args {
  exploreOption: ExploreOption;
  onClick: () => void;
  stamp: {
    text: string;
    color: StampColor;
  };
  actionParameter?: ChartSeries | string;
}

const ChartActionsDropDownItem = templateOnlyComponent<Args>();
export default ChartActionsDropDownItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartActionsDropDownItem': typeof ChartActionsDropDownItem;
    'reporting/custom/chart-actions-drop-down-item': typeof ChartActionsDropDownItem;
  }
}
