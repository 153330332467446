/* import __COLOCATED_TEMPLATE__ from './survey-rating-bar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResource from 'embercom/lib/reporting/chart-data-resource';
import { SORTING_LABEL_NUMERIC } from 'embercom/lib/reporting/flexible/constants';
import { inject as service } from '@ember/service';
import { range } from 'underscore';
import { populateEmptyRatingsWithZero } from 'embercom/lib/reporting/survey-helpers';

export default class SurveyRatingBar extends Component {
  @service store;

  constructor() {
    super(...arguments);

    let questionId = this.args.renderableChart.viewBy.split('#')[1];
    let { scaleStart, scaleEnd } = this.store.peekRecord('surveys/question', questionId).data;
    this._availableRatings = range(scaleStart, scaleEnd + 1).map((rating) => rating.toString());
  }

  dataResource = useResource(this, ChartDataResource, () => ({
    renderableChart: this.args.renderableChart,
  }));

  get dataConfig() {
    let config = this.dataResource.dataConfig;
    config.xAxis.data.sorting = SORTING_LABEL_NUMERIC;
    return config;
  }

  get viewConfig() {
    return {
      ...this.dataResource.viewConfig,
      grouping: {
        dataTransformation: (dataResponses) =>
          populateEmptyRatingsWithZero(this._availableRatings, dataResponses),
      },
    };
  }
}
