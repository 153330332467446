/* import __COLOCATED_TEMPLATE__ from './inbox-seat-allocation.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class InboxSeatAllocation extends Component {
  @service customerService;
  @service appService;
  @service store;

  originallyHadInboxSeat = this.args.permissionsObject.has_inbox_access;
  @tracked addingOrRemovingInboxSeat = false;
  reassignments;

  get app() {
    return this.appService.app;
  }

  get numberOfSeatsRequired() {
    return this.args.numberOfSeatsRequired || 1;
  }

  get isSalesforceContracted() {
    return this.app.isSalesforceContracted;
  }

  get isRemovingAnInboxSeat() {
    return (
      this.addingOrRemovingInboxSeat &&
      this.args.permissionsObject.has_inbox_access === false &&
      this.originallyHadInboxSeat &&
      this.args.permissionsObject.admin_id
    );
  }

  @action
  toggleInboxSeat() {
    let newValue = !this.args.permissionsObject.has_inbox_access;
    this.args.permissionsObject.has_inbox_access = newValue;
    this.addingOrRemovingInboxSeat = this.originallyHadInboxSeat !== newValue;
    if (newValue) {
      this.reassignments = undefined;
      this.changeReassignments(true);
    } else {
      this.reassignments = { teams: {} };
      this.changeReassignments(false);
    }
    if (this.args.onToggle) {
      this.args.onToggle();
    }
  }

  @action
  changeReassignments(isValid) {
    if (this.args.changeReassignments) {
      this.args.changeReassignments(isValid, this.reassignments);
    }
  }
}
