/* import __COLOCATED_TEMPLATE__ from './security-warning-banner.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class SecurityWarningBanner extends Component {
  @service appService;
  @service guideLibraryService;

  get app() {
    return this.appService.app;
  }

  get isMessengerInstalled() {
    return this.guideLibraryService.isMessengerInstalled;
  }
}
