/* import __COLOCATED_TEMPLATE__ from './filter-bank.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { isNone } from '@ember/utils';

let buildOptions = (options, selectedOption) =>
  options.map((option) => ({
    text: option.name,
    value: option.value,
    count: option.count,
    isSelected: selectedOption && option.value === selectedOption.value,
  }));

let buildDropdown = (blocks) =>
  blocks.map((block) => ({
    text: block.name,
    icon: block.icon,
    value: block.key,
    isSelected: false,
  }));

let buildTense = ({ block, suffix = '' }) => {
  let tense = 'was';

  if (block.tense === 'present') {
    tense = block.multiple ? 'includes' : 'is';
  }

  return `${tense}${suffix}`;
};

let buildBlockDetails = (block, selectedLabel) => {
  if (block.tense === 'none') {
    return selectedLabel;
  }

  if (selectedLabel) {
    return `${buildTense({ block })} ${selectedLabel}`;
  } else {
    return buildTense({ block, suffix: '...' });
  }
};

export default Component.extend({
  initiallyOpened: false,
  tagName: '',

  selectedFilterBlocks: computed('selectedFilters', 'availableFilters', function () {
    return this.selectedFilters.map((filter) => {
      let block = this.availableFilters[filter.key];
      let selectedOption = block.options.find((option) => option.value === filter.value);
      let selectedLabel = selectedOption && selectedOption.name;
      let blockDetails = buildBlockDetails(block, selectedLabel);

      return {
        ...block,
        deletable: isNone(filter.deletable) && true,
        dropdown: buildOptions(block.options, selectedOption),
        selectedLabel,
        blockDetails,
        ...filter,
      };
    });
  }),

  filtersDropdown: computed('availableFilters', function () {
    return buildDropdown(
      Object.keys(this.availableFilters).map((key) => this.availableFilters[key]),
    );
  }),

  actions: {
    onAddFilter(key) {
      event.stopPropagation();

      this.set('initiallyOpened', true);
      return this.addFilter(key);
    },
  },
});
