/* import __COLOCATED_TEMPLATE__ from './tag-dependencies.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class TagDependenciesComponent extends Component {
  @tracked dependencies;

  @service tagService;
  @service notificationsService;
  @service intl;

  constructor() {
    super(...arguments);
    this.checkForDependents.perform();
  }

  get hasDependencies() {
    return (
      this.tagService.hasDependencies(this.dependencies) || this.checkForDependents.last.isError
    );
  }

  @task
  *checkForDependents() {
    if (!this.args.tag?.id) {
      return;
    }

    try {
      this.dependencies = yield this.tagService.fetchTagDependencies.perform(this.args.tag);
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('components.settings.tag-dependencies.unexpected-error'),
      );
      throw e;
    }
  }
}
