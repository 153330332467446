/* import __COLOCATED_TEMPLATE__ from './copilot-usage-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
  Element: HTMLDivElement;
}

const CopilotUsageTooltip = templateOnlyComponent<Signature>();
export default CopilotUsageTooltip;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Copilot::CopilotUsageTooltip': typeof CopilotUsageTooltip;
  }
}
