/* import __COLOCATED_TEMPLATE__ from './simple-x-axis.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { scaleLinear } from 'd3-scale';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  showTicks: true,
  axisItems: computed('ticks', 'from', 'to', 'size', function () {
    let scale = scaleLinear().domain([this.from, this.to]);

    return scale.ticks(this.ticks || this.size === 'small' ? 1 : 5);
  }),
});
