/* import __COLOCATED_TEMPLATE__ from './legacy-chart-title.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
export default class ChartTitle extends Component {
  @service intl;
  get chartTitle() {
    return this.args.chartTitle ? this.args.chartTitle : this.args.renderableChart.description;
  }

  get officeHoursTooltipDescription() {
    let { excludeOooHours } = this.args.renderableChart.reportState?.settings;
    return this.intl.t(
      'components.reporting.flexible.chart-header-custom-tooltip.office-hours-tooltip.tooltip-description',
      { includeOfficeHours: excludeOooHours },
    );
  }

  get shouldShowOfficeHoursTooltip() {
    if (isEmpty(this.args.renderableChart?.chartSeries)) {
      return false;
    }
    let series = this.args.renderableChart.chartSeries.toArray();
    return (
      this.args.renderableChart.reportState?.isStatic &&
      this.args.renderableChart.shouldRenderChrome &&
      series.some((series) => series.aggregation !== undefined)
    );
  }

  get shouldShowMoreDetailLink() {
    return (
      isPresent(this.args.renderableChart) &&
      isPresent(this.args.renderableChart.reportState) &&
      (this.args.renderableChart.reportState.id === 'team_inbox_performance_report' ||
        this.args.renderableChart.reportState.id === 'teammate_performance_report')
    );
  }
}
