/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { allSettled } from 'rsvp';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import TopicStats from 'embercom/lib/conversational-insights/topic-stats';
import { mapObject } from 'underscore';

export default class SuggestedTopicListComponent extends Component {
  @service router;
  @service store;
  @service notificationsService;
  @service appService;
  @service intercomEventService;
  @service topicStatsService;
  @service conversationalInsights;
  @service intl;

  @tracked showTrackedTopicsInVisualization = false;
  @tracked trackedTopics = null;
  @tracked suggestionStats = {};
  @tracked topicStats = {};
  @tracked botInboxTime;
  @tracked selectedTopicIds = [];

  reportingSettings = this.store.queryRecord('reporting/settings', {});

  get excludeBotInboxTime() {
    return this.botInboxTime === 'excluded';
  }

  get rows() {
    return this.args.topics.map((topic) => {
      let rawStats = this.suggestionStats[topic.id];
      let stats = new TopicStats(rawStats);
      return { topic, stats };
    });
  }

  @task
  *loadStats() {
    yield this.args.topics;
    if (!this.args.topics?.length) {
      return;
    }

    let { excludeOooHours } = yield this.reportingSettings;
    let range = this.conversationalInsights.currentDateRange;

    this.suggestionStats = yield this.topicStatsService.loadSuggestionStatsInBatches.perform({
      rangeStart: range.start,
      rangeEnd: range.end,
      suggestedTopics: this.args.topics,
      excludeOooHours,
      excludeBotInboxTime: this.excludeBotInboxTime,
      includePreviousRange: true,
    });
  }

  @task
  *createTopics() {
    let topicsToCreate = this.args.topics.filter((topic) =>
      this.selectedTopicIds.includes(topic.id),
    );
    yield this.prepareTopics.perform(topicsToCreate);
    this.notificationsService.notifyConfirmation(
      this.intl.t(
        'reporting.conversational-insights.suggested-topics.list.notifications.create-topics.confirmation',
        {
          count: topicsToCreate.length,
        },
      ),
    );
    yield this.destroySuggestedTopics.perform();
  }

  @task
  *prepareTopics(topicsToCreate) {
    yield allSettled(
      topicsToCreate.map((topic) => {
        return this.saveTopic.perform(topic);
      }),
    );
  }

  @task
  *saveTopic(topic) {
    try {
      let newTopic = this.store.createRecord('conversational-insights/conversation-topic', {
        name: topic.name,
        keyphrases: topic.keyphrases,
      });
      yield newTopic.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'add_to_topics',
        place: 'topics',
        from_suggestion_id: topic.id,
        topic_id: newTopic.id,
        topic_name: newTopic.name,
      });
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t(
          'reporting.conversational-insights.suggested-topics.list.notifications.save-topic.error',
          {
            topicName: topic.name,
          },
        ),
      );
    }
  }

  @task
  *destroySuggestedTopics() {
    yield ajax({
      url: '/ember/conversational_insights/suggested_conversation_topics/destroy_by_ids',
      type: 'DELETE',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        topic_ids: this.selectedTopicIds,
      }),
    });

    this.args.topics.forEach((topic) => {
      if (this.selectedTopicIds.includes(topic.id)) {
        this.store.unloadRecord(topic);
      }
    });

    this.selectedTopicIds = [];
  }

  @task({ restartable: true })
  *loadTrackedTopicStats() {
    let { excludeOooHours } = yield this.reportingSettings;
    let topicIds = this.trackedTopics.map(({ id }) => id);
    let range = this.conversationalInsights.currentDateRange;
    let excludeBotInboxTime = this.excludeBotInboxTime;

    // Load the propagated stats
    let loadPropagatedTask = this.topicStatsService.loadPropagatedTopicStatsInBatches.perform({
      rangeStart: range.start,
      rangeEnd: range.end,
      topicIds,
      excludeOooHours,
      excludeBotInboxTime,
    });

    // Load realtime stats for propagating topics
    let propagatingTopicIds = this.trackedTopics
      .filter((topic) => topic.isPropagatingInRange(range))
      .map(({ id }) => id);

    // Only load propagating stats for the current date range
    let propagatingStatsTask = this.topicStatsService.loadTopicStatsInBatches.perform({
      rangeStart: range.start,
      rangeEnd: range.end,
      topicIds: propagatingTopicIds,
      excludeOooHours,
      excludeBotInboxTime,
      includePreviousRange: false,
    });

    // Wait for propagated and propagating stats to complete loading
    let propagatedStats = yield loadPropagatedTask;
    let formattedCurrentStats = mapObject(propagatedStats, (currentStats) =>
      TopicStats.formatPropagatedStats({ currentStats }),
    );

    let propagatingStats = yield propagatingStatsTask;

    let combinedStats = { ...formattedCurrentStats, ...propagatingStats };

    this.topicStats = combinedStats;
  }

  @task
  *loadTrackedTopics() {
    this.trackedTopics = yield this.store.findAll('conversational-insights/conversation-topic');
    yield this.loadTrackedTopicStats.perform();
  }

  @action
  mergeTopics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'merged',
      object: 'topic',
      place: 'topics',
      suggestion_ids: this.selectedTopicIds,
    });
    this.intercomEventService.trackEvent('merged-suggestions');
    return this.router.transitionTo(
      'apps.app.reports.conversational-insights.suggested-topics.topic',
      this.selectedTopicIds.join(','),
    );
  }

  @action
  updateRange(range) {
    this.conversationalInsights.updateRange(range);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered',
      context: 'suggestions_list',
      filter: 'date',
      object: 'kpis',
      place: 'topics',
    });
  }

  @action
  updateBotInboxTime(value) {
    this.botInboxTime = value;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered',
      context: 'suggestions_list',
      filter: 'bot_time',
      is_on: this.excludeBotInboxTime,
      place: 'topics',
    });
  }

  @action
  toggle(suggestionId) {
    if (this.selectedTopicIds.includes(suggestionId)) {
      this.selectedTopicIds = this.selectedTopicIds.filter((id) => id !== suggestionId);
    } else {
      this.selectedTopicIds = this.selectedTopicIds.concat([suggestionId]);
    }
  }
}
