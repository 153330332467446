/* import __COLOCATED_TEMPLATE__ from './folder.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import type ReportingFolderManagerService from 'embercom/services/reporting-folder-manager-service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type StickQueryParamsService from 'embercom/services/stick-query-params-service';
import { type ReportsFolder } from 'embercom/services/reporting-folder-manager-service';

interface ReportingNavSubmenuFolderArgs {
  folder: ReportsFolder;
  trackAnalytics: boolean;
  sections: any;
}

export default class ReportingNavSubmenuFolder extends Component<ReportingNavSubmenuFolderArgs> {
  @service declare router: Router;
  @service declare reportingFolderManagerService: ReportingFolderManagerService;
  @service declare intercomEventService: any;
  @service declare stickQueryParamsService: StickQueryParamsService;

  get folder() {
    return { id: this.args.folder.id, isSticky: this.args.folder.isSticky };
  }

  get isOpen() {
    return this.folderManager.isActive(this.folder);
  }

  get folderManager() {
    return this.reportingFolderManagerService;
  }

  get trustedListFilterQueryParams() {
    return this.stickQueryParamsService.safeCarryOverQueryParams;
  }

  @task({ drop: true })
  *trackAnalyticsEventFor(reportId: string) {
    let currentReport = this.args.folder.reports.find((report) => report.id === reportId);
    yield this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: currentReport?.route,
      place: this.args.folder.name,
      section: 'reports',
    });
  }

  @action
  async handleOnClickFor(reportId: string) {
    this.folderManager.setActiveFolderReport(this.args.folder.id, reportId);
    if (this.args.trackAnalytics) {
      taskFor(this.trackAnalyticsEventFor).perform(reportId);
    }
  }

  @action
  updateFolderState() {
    this.folderManager.updateFolderState(this.folder);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::NavSubmenuComponents::Folder': typeof ReportingNavSubmenuFolder;
  }
}
