/* import __COLOCATED_TEMPLATE__ from './step1-select-seat.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import Component from '@glimmer/component';
import {
  PRICING_5_X_BASE_SEAT_TYPES,
  VBP2_BASE_SEAT_TYPES,
} from 'embercom/lib/settings/seats/constants';
import { tracked } from '@glimmer/tracking';

export default class StepOne extends Component {
  @service customerService;
  @service appService;

  @tracked availableSeatTypes = [];

  constructor() {
    super(...arguments);
    this.loadAvailableSeatTypes.perform();
  }

  get showNonSpecificSeat() {
    return this.availableSeatTypes.length > 1;
  }

  get listOfSeatTypesForApp() {
    if (this.appService.app.onPricing5) {
      return PRICING_5_X_BASE_SEAT_TYPES;
    } else {
      return VBP2_BASE_SEAT_TYPES;
    }
  }

  @task *loadAvailableSeatTypes() {
    yield this.customerService.ensureDataIsLoaded.perform();
    let { availableSeatTypes: customerSeatTypes } = this.customerService.customer;
    this.availableSeatTypes = _.intersection(customerSeatTypes, this.listOfSeatTypesForApp);
  }
}
