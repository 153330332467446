/* import __COLOCATED_TEMPLATE__ from './strip-inbound-email-links-settings.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class StripInboundEmailLinksSettings extends Component {
  @service store;
  @service notificationsService;
  @service intl;
  @service appService;
  @service intercomEventService;

  constructor() {
    super(...arguments);
  }

  @task({ enqueue: true })
  *updateSetting() {
    try {
      yield this.args.settings.save();

      this.trackUpdateAnalytics();

      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (error) {
      this.args.settings.toggleProperty('stripInboundEmailLinks');
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  toggleStripInboundEmailLinks() {
    this.args.settings.toggleProperty('stripInboundEmailLinks');
    this.updateSetting.perform();
  }

  trackUpdateAnalytics() {
    let action = this.args.settings.stripInboundEmailLinks ? 'turned_on' : 'turned_off';
    this.trackAnalytics(action);
  }

  trackAnalytics(action) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'strip_inbound_email_links_settings',
    });
  }
}
