/* import __COLOCATED_TEMPLATE__ from './availability.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { DEFAULT_MACRO_TYPES } from 'embercom/lib/inbox/constants';
import { inject as service } from '@ember/service';

export default class Availability extends Component {
  @service intl;

  get openerLabel() {
    return this.intl.t('inbox.manage-macros.macro-types.filter-label', {
      hasSelectedTypes: this.args.selectedTypes.length !== 0,
      selectedTypes: this.args.selectedTypes
        .mapBy('intlKey')
        .map((typeKey) => this.intl.t(`inbox.manage-macros.macro-types.${typeKey}`))
        .join(', '),
    });
  }

  get typeValues() {
    return this.args.selectedTypes.mapBy('id');
  }

  get typeOptions() {
    // TODO: replace with fetching all types for this app from the backend
    return DEFAULT_MACRO_TYPES.map(({ id, intlKey }) => ({
      text: this.intl.t(`inbox.manage-macros.macro-types.${intlKey}`),
      value: id,
      isSelected: this.typeValues.includes(id),
    }));
  }
}
