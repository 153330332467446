/* import __COLOCATED_TEMPLATE__ from './boolean-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';

const VALUE_OPERATOR_MAPPING = {
  true: 'category',
  false: 'category',
  not_exists: 'not_exists',
  exists: 'exists',
};

export default class BooleanFilter extends Component {
  @service store;
  @service intercomEventService;
  @service intl;

  // store the selection here so that the UI doesn't update until the drop down is closed
  @tracked label = this.currentLabel;
  @tracked selected = this.startingSelectedValue;

  get valueLabelMapping() {
    return {
      true: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.is-true',
      ),
      false: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.is-false',
      ),
      exists: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.exists',
      ),
      not_exists: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.custom-attributes.boolean-values.not-exists',
      ),
    };
  }

  get startingSelectedValue() {
    let operator = this.args.selected.operator;
    let passedValue = this.args.selected.values?.[0];

    switch (operator) {
      case 'category':
        return isPresent(passedValue) ? passedValue : true;
      case 'exists':
      case 'not_exists':
        return operator;
      default:
        return true;
    }
  }

  get customField() {
    let identifier = this.args.type;
    return ConversationAttributeDescriptor.peekByIdentifier(identifier);
  }

  get currentLabel() {
    return this.valueLabelMapping[this.selected] || '';
  }

  get operator() {
    return VALUE_OPERATOR_MAPPING[this.selected];
  }

  get values() {
    return this.operator === 'category' ? [this.selected] : [];
  }

  @action
  onClose() {
    if (!this.values.length && !this.operator) {
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_custom_field',
      object: this.args.analyticsObject,
      custom_field_type: 'boolean',
    });

    this.args.setSelected(this.values, this.operator);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selected = null;
    this.args.removeFilter();
  }
}
