/* import __COLOCATED_TEMPLATE__ from './call-state-filter.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import type IntlService from 'ember-intl/services/intl';
import { type SelectedFilter } from 'embercom/services/filter-pattern-service';

interface Args {
  icon: string;
  name: string;
  initiallyOpened: boolean;
  removeFilter: () => void;
  setSelected: (filterValues: string[], operator: string) => void;
  selected: SelectedFilter;
  type: string;
  analyticsObject: any;
  isDisabled: boolean;
}

export default class CallStateFilter extends Component<Args> {
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @tracked selectedDropdownItems = this.args.selected.values || [];
  @tracked operator = this.args.selected.operator || 'category';

  get labelMap() {
    return [
      'answered',
      'ended_by_workflow',
      'voicemail_left',
      'abandoned_in_voicemail',
      'abandoned_on_hold',
      'abandoned_in_queue',
      'abandoned_routing',
      'no_answer',
    ].reduce((acc: Record<string, string>, key) => {
      acc[key] = this.intl.t(`reporting.calls.state.${key}`);
      return acc;
    }, {});
  }

  get selectedStates() {
    return this.selectedDropdownItems.filter(
      (item: string) => item !== REPORTING_FILTER_SELECT_ALL,
    );
  }

  get allItems() {
    return Object.entries(this.labelMap).map(([value, text]) => ({
      text,
      value,
      isSelected: this.selectedDropdownItems.includes(value),
    }));
  }

  get currentLabel() {
    if (
      this.selectedDropdownItems?.length &&
      this.selectedDropdownItems[0] !== REPORTING_FILTER_SELECT_ALL
    ) {
      return this.selectedDropdownItems.map((value: string) => this.labelMap[value]).join(', ');
    } else {
      return this.intl.t('reporting.custom-reports.any');
    }
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: `filtered_${this.args.type}`,
      object: this.args.analyticsObject,
      state: this.selectedStates,
      operator: this.operator,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::CallStateFilter': typeof CallStateFilter;
  }
}
