/* import __COLOCATED_TEMPLATE__ from './list-ticket-types.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import type IntlService from 'embercom/services/intl';
import type TicketTypeSummary from 'embercom/models/inbox/ticket-type-summary';
import TicketType from 'embercom/models/inbox/ticket-type';
import type Router from '@ember/routing/router-service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type TicketStateService from 'embercom/services/ticket-state-service';

interface Args {
  ticketTypes: TicketTypeSummary[];
}

type TabView = 'live' | 'archived';

export interface ColumnConfig {
  label: string;
  valuePath: 'name' | 'description' | 'createdAt' | 'edit' | 'internal';
  isVisible: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
}

const LOCALIZATION_KEY = 'settings.ticket-data.list-ticket-types';

export default class ListTicketTypes extends Component<Args> {
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare notificationsService: any;
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare ticketStateService: TicketStateService;

  @tracked showTicketTypeSideDrawer = false;
  @tracked currentTab: TabView = 'live';
  @tracked createTicketTypeCategory: TicketCategory | undefined;

  get ticketTypes() {
    return this.args.ticketTypes.sortBy('name');
  }

  get liveTicketTypes() {
    return this.ticketTypes.rejectBy('archived');
  }

  get archivedTicketTypes() {
    return this.ticketTypes.filterBy('archived');
  }

  get liveTicketTypesByCategory() {
    return TicketType.ticketTypesByCategory(this.liveTicketTypes) as Record<
      TicketCategory,
      TicketType[]
    >;
  }

  get archivedTicketTypesByCategory() {
    return TicketType.ticketTypesByCategory(this.archivedTicketTypes) as Record<
      TicketCategory,
      TicketType[]
    >;
  }

  get commonColumnsSettings(): ColumnConfig[] {
    return [
      {
        label: this.intl.t(`${LOCALIZATION_KEY}.table-columns.name`),
        valuePath: 'name',
        isVisible: true,
        width: '20%',
        minWidth: '200px',
      },
      {
        label: this.intl.t(`${LOCALIZATION_KEY}.table-columns.description`),
        valuePath: 'description',
        isVisible: true,
        width: '50%',
      },
      {
        label: this.intl.t(`${LOCALIZATION_KEY}.table-columns.created-at`),
        valuePath: 'createdAt',
        isVisible: true,
        width: '130px',
      },
      {
        label: '',
        valuePath: 'edit',
        isVisible: true,
        width: '160px',
      },
    ];
  }

  get taskColumnsSettings() {
    let createdAtColumnIndex = this.commonColumnsSettings.findIndex(
      (column) => column.valuePath === 'createdAt',
    );

    return this.commonColumnsSettings.insertAt(createdAtColumnIndex, {
      label: this.intl.t(`${LOCALIZATION_KEY}.table-columns.visibility`),
      valuePath: 'internal',
      isVisible: true,
      width: '146px',
    });
  }

  get categorySections() {
    return [
      {
        id: TicketCategory.Request,
        title: this.intl.t(`${LOCALIZATION_KEY}.ticket-type.request`),
        intro: this.intl.t(`${LOCALIZATION_KEY}.ticket-type.ticket-intervention-request-info`),
        icon: 'ticket',
        columns: this.commonColumnsSettings,
      },
      {
        id: TicketCategory.Tracker,
        title: this.intl.t(`${LOCALIZATION_KEY}.ticket-type.tracker`),
        intro: this.intl.t(`${LOCALIZATION_KEY}.ticket-type.ticket-intervention-tracker-info`),
        icon: 'tracker',
        columns: this.commonColumnsSettings,
      },
      {
        id: TicketCategory.Task,
        title: this.intl.t(`${LOCALIZATION_KEY}.ticket-type.task`),
        intro: this.intl.t(`${LOCALIZATION_KEY}.ticket-type.ticket-intervention-task-info`),
        icon: 'back-office',
        columns: this.taskColumnsSettings,
      },
    ];
  }

  get hasTicketTypes() {
    return this.ticketTypes.length > 0;
  }

  showTicketsArticle = (_triggerLocation = 'dropdown_item') => {
    window.Intercom('showArticle', 8428813); // https://www.intercom.com/help/en/articles/8428813-getting-started-with-tickets
    this.triggerAnalytics('how_to_set_up_ticket_types', _triggerLocation);
  };

  @action showCategoryIntroduction() {
    this.router.transitionTo('apps.app.settings.tickets-onboarding', this.appService.app.id);
  }

  @task({ drop: true })
  *unarchiveTicketType(ticketType: TicketTypeSummary) {
    try {
      ticketType.setProperties({
        archived: false,
      });

      yield ticketType.save();
      this.ticketStateService.updateTicketType(ticketType.id, { archived: false });
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.ticket-data.list-ticket-unarchive-success', {
          ticketTypeName: ticketType.name,
        }),
      );

      this.sortData();

      if (this.archivedTicketTypes.length === 0) {
        this.switchTab('live');
      }
    } catch (error) {
      ticketType.rollbackAttributes();
      if (error.jqXHR?.responseJSON?.message) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-data.list-ticket-unarchive-failure', {
            ticketTypeName: ticketType.name,
          }),
        );
      }
    }
  }

  @action private sortData() {
    this.ticketTypes.sortBy('name');
  }

  @action switchTab(tab: TabView) {
    this.currentTab = tab;
  }

  @action createNewTicketType() {
    this.showTicketTypeSideDrawer = true;
  }

  @action onCreateTicketType(ticketCategory: TicketCategory) {
    this.createTicketTypeCategory = ticketCategory;
    this.showTicketTypeSideDrawer = true;
  }

  @action onTicketTypeSideDrawerClose() {
    this.showTicketTypeSideDrawer = false;
    this.createTicketTypeCategory = undefined;
  }

  @action onTicketTypeCreated(ticketType: TicketType) {
    this.ticketTypes.push(ticketType);
  }

  @action onEditTicketType(ticketTypeId: number) {
    window?.scrollTo(0, 0);
    this.router.transitionTo(
      'apps.app.settings.helpdesk.tickets.ticket-types.detail',
      ticketTypeId,
    );
  }

  @action onRestoreTicketType(ticketType: TicketTypeSummary) {
    taskFor(this.unarchiveTicketType).perform(ticketType);
  }

  @action
  triggerAnalytics(object: string, section = 'dropdown_item') {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      context: `onboarding_guide_library.settings.ticket-data.banner.${section}`,
      place: 'ticket_data',
      section,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketData::ListTicketTypes': typeof ListTicketTypes;
  }
}
