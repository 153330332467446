/* import __COLOCATED_TEMPLATE__ from './attribute-row.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
export default Component.extend({
  classNames: [
    'flex-none',
    'flex',
    'flex-row',
    'settings__qualification__row',
    'u__padt__10',
    'u__padb__10',
  ],
  attributeBindings: ['data-test-qualification-attribute-row'],
  'data-test-qualification-attribute-row': true,
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  actions: {
    openDetailsModal(attribute) {
      this.modalService.openModal('settings/modals/attribute-details', {
        attribute,
        allowArchival: false,
      });
    },
    removeQualificationAttribute() {
      this.removeQualificationAttribute();
    },
  },
});
