/* import __COLOCATED_TEMPLATE__ from './permissions.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  confirmMultipleSeatOveragesIncrease,
  isAnySeatOverage,
} from 'embercom/lib/admins/multiple-seats-info';
import {
  TEAMMATE_USAGE as TEAMMATE,
  COLLABORATOR_SEAT_TYPE as COLLABORATOR,
} from 'embercom/lib/settings/seats/constants';
import { Promise as EmberPromise } from 'rsvp';
import { seatsByTeammates } from 'embercom/lib/teammates/calculate-common-seats';
import { difference, isEqual } from 'underscore';
import { isEmpty, isPresent } from '@ember/utils';

export default class Permissions extends Component {
  @service appService;
  @service notificationsService;
  @service intercomConfirmService;
  @tracked reassignmentsAreValid = true;
  @tracked showApplyChangesModal = false;
  @tracked showReassignmentModal = false;

  usageType = TEAMMATE;
  reassignments = { teams: {} };
  resolveAcceptedFunction;
  rejectAcceptedFunction;
  initialUsage = {};
  updatedUsage = {};
  initialSeatSelections = [...this.args.seatTypes];

  @action
  changeReassignments(isValid, reassignments) {
    this.reassignmentsAreValid = isValid;
    this.args.changeReassignments(reassignments);
  }

  async confirmReassignedSelections() {
    try {
      if (this.hasTeammateRequiringOwnershipReassignment) {
        this.showReassignmentModal = true;

        return this.modalConfirmationPromise;
      }
      return true;
    } catch (error) {
      this.notificationsService.notifyError(error.message);
    }
  }

  get modalConfirmationPromise() {
    return new EmberPromise((resolve, reject) => {
      // Nolaneo – this linter error seems genuine: why are we mutating state in this getter?
      // eslint-disable-next-line
      this.resolveAcceptedFunction = () => {
        resolve(true);
      };
      // eslint-disable-next-line
      this.rejectAcceptedFunction = () => {
        reject(false);
      };
    });
  }

  @action
  async saveChanges() {
    if (
      (await this.confirmMultipleSeatOveragesIncreaseIfHasSeats()) &&
      (await this.confirmReassignedSelections())
    ) {
      this.args.saveChanges(
        this.args.seatTypes,
        await isAnySeatOverage(this.args.seatTypes, this.initialUsage, this.updatedUsage),
      );
    }
  }

  @action
  async showConfirmModal(options) {
    return this.intercomConfirmService.confirm(options);
  }

  async confirmMultipleSeatOveragesIncreaseIfHasSeats() {
    if (this.appService.app.hasMultipleSeatTypes) {
      return await confirmMultipleSeatOveragesIncrease(
        this.appService.app,
        {
          seatTypes: this.args.seatTypes,
          initialUsage: this.initialUsage,
          updatedUsage: this.updatedUsage,
          teammateCount: this.args.teammates.length,
          isInvite: false,
          haveSeatsChanged: false,
        },
        this.showConfirmModal,
      );
    }
    return true;
  }

  @action
  onSeatsChanged() {
    this.args.seatsUpdated(this.hasUpdatedSeats);
  }

  @action
  onChangesAccepted() {
    this.resolveAcceptedFunction();
    this.showApplyChangesModal = false;
  }

  @action
  onChangesRejected() {
    this.rejectAcceptedFunction();
    this.showApplyChangesModal = false;
    this.showReassignmentModal = false;
  }

  @action
  setInitialUsage(usage) {
    this.initialUsage = usage;
  }

  @action
  setUpdatedUsage(usage) {
    this.updatedUsage = usage;
  }

  get hasUpdatedSeats() {
    return (
      difference(this.args.seatTypes, this.initialSeatSelections).length !== 0 ||
      difference(this.initialSeatSelections, this.args.seatTypes).length !== 0
    );
  }

  get hasRemovedAllSeats() {
    return !isEqual(this.initialSeatSelections, []) && isEqual(this.args.seatTypes, []);
  }

  get seatsTypesByTeammates() {
    return seatsByTeammates(this.args.teammates, this.args.seatTypes, this.args.seatsSelections);
  }

  get teammatesRequiringOwnershipReassignment() {
    return this.args.teammates.filter((teammate) => {
      return this._teammateRequiresOwnershipReassignment(teammate);
    });
  }

  get hasTeammateRequiringOwnershipReassignment() {
    return isPresent(this.teammatesRequiringOwnershipReassignment);
  }

  _teammateRequiresOwnershipReassignment(teammate) {
    return (
      this._hasSeatWithoutOwnership(teammate) && this._initiallyHadSeatsWithOwnership(teammate)
    );
  }

  _hasSeatWithoutOwnership(teammate) {
    let occupiedSeats = this.seatsTypesByTeammates[teammate.id];

    return isEmpty(occupiedSeats) || isEqual(occupiedSeats, [COLLABORATOR]);
  }

  _initiallyHadSeatsWithOwnership(teammate) {
    let initialTeammate = this.args.teammates.find((admin) => {
      return admin.id === teammate.id;
    });

    return isPresent(initialTeammate.seats) && !isEqual(initialTeammate.seats, [COLLABORATOR]);
  }
}
