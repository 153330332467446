/* import __COLOCATED_TEMPLATE__ from './ticket-state-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type IntlService from 'embercom/services/intl';

interface Args {
  componentAttrs: {
    state: TicketCustomState;
    showUserLabel: boolean;
    showRelatedTicketTypes: boolean;
    additionalItemClasses?: string;
  };
}

export default class TicketStateDropdownItem extends Component<Args> {
  @service declare intl: IntlService;

  get popoverModifiers() {
    return [{ name: 'offset', options: { offset: [0, 20] } }];
  }

  get popoverActive() {
    if (!this.args.componentAttrs.showRelatedTicketTypes) {
      return false;
    }

    return this.args.componentAttrs.state.ticketTypeIds?.length > 0;
  }

  get popoverInformation() {
    return this.intl.t('settings.ticket-data.dependent-ticket-types-popover.description', {
      count: this.args.componentAttrs.state.ticketTypeIds.length,
      htmlSafe: true,
    });
  }

  get userLabel() {
    let message = this.intl.t('settings.ticket-data.ticket-type-configuration.customer-sees');
    return `${message} "${this.args.componentAttrs.state.userLabel}"`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketStates::TicketStateDropdownItem': typeof TicketStateDropdownItem;
  }
}
