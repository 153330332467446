/* import __COLOCATED_TEMPLATE__ from './table-csv-info-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';

interface Args {
  exportFilename: string;
  tableId: string;
  trackDownloadCSV: Function;
  lastPageHit: boolean;
}

export default class MessageTable extends Component<Args> {
  get isMissingData() {
    return !this.args.lastPageHit;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Series::Editor::OverviewReport::TableCsvInfoButton': typeof MessageTable;
    'series/editor/overview-report/table-csv-info-button': typeof MessageTable;
  }
}
