/* import __COLOCATED_TEMPLATE__ from './actions.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import platform from 'embercom/lib/browser-platform';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
export default class SeriesNodeActionsComponent extends Component {
  @service seriesEditorService;
  @tracked isOpen = false;

  get modifierKey() {
    return platform.isMac ? '⌘' : 'ctrl';
  }

  @action onOpen() {
    this.isOpen = true;
  }

  @action onClose() {
    this.isOpen = false;
  }

  @action selectNode() {
    let state = this.seriesEditorService.graph.state;
    state.selectedNodes.clear();
    state.selectedNodes.pushObject(this.args.graphNode);
  }

  @action edit(dropdown) {
    this.seriesEditorService.registerActiveSeriesNode(this.args.seriesNode);
    dropdown.hideDropdownOverlay();
  }

  @action duplicate(dropdown) {
    this.seriesEditorService.duplicateNodes.perform([this.args.graphNode]);
    dropdown.hideDropdownOverlay();
  }

  @action delete(dropdown) {
    this.seriesEditorService.graph.trackActions(() => {
      this.seriesEditorService.graph.deleteNode(this.args.graphNode);
      dropdown.hideDropdownOverlay();
    });
  }
}
