/* import __COLOCATED_TEMPLATE__ from './replied-to-conversations-columnchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  COUNT,
  CARDINALITY,
  COMMENT_CREATED_AT,
  CONVERSATION_ID,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  TEAMMATE_REPLIES_FILTERS,
  TIME,
} from 'embercom/lib/reporting/flexible/constants';
import PALETTE from '@intercom/pulse/lib/palette';

import { buildFilters } from 'embercom/lib/reporting/flexible/filter-helpers';
import { inject as service } from '@ember/service';

export default class RepliedToConversationsColumnchart extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get modifiedFilters() {
    // remove admin_participant_ids from global filters as readding at series level with correct renaming
    let newFilters = { ...this.args.filters };

    if (newFilters.admin_participant_ids) {
      delete newFilters.admin_participant_ids;
    }

    return newFilters;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value, displayUnit: 'conversation' };
    viewConfig.aggregations = { defaultOption: CARDINALITY };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[TIME],
        createTitleTooltipText: () =>
          this.intl.t('reporting.conversations-report.new-vs-conversations-replied-to.tooltip'),
      },
    };
    viewConfig.legend = {
      new_conversations_columnchart: () => {
        return this.intl.t(
          'reporting.conversations-report.new-vs-conversations-replied-to.legend.new',
        );
      },
      replied_conversations_columnchart: () => {
        return this.intl.t(
          'reporting.conversations-report.new-vs-conversations-replied-to.legend.replied-to',
        );
      },
    };
    viewConfig.seriesColors = [PALETTE['vis-azure-50'], PALETTE['vis-mantis-50']];

    return viewConfig;
  }

  buildTeammateFilter(source) {
    // renaming of teammate property based on index used
    if (this.args.filters.admin_participant_ids) {
      let filterName =
        source === 'consolidated_conversation_part' ? 'teammate_id' : 'admin_participant_ids';
      let filters = buildFilters(filterName, this.args.filters.admin_participant_ids);

      if (filters.length > 1) {
        return { type: 'and', filters };
      } else {
        return filters[0];
      }
    }

    return null;
  }

  get dataConfig() {
    return {
      xAxis: {
        type: 'temporal',
        data: {
          interval: FILTER_PROPERTY_PLACEHOLDER,
          property: TIME,
        },
      },
      series: [
        {
          name: 'new_conversations_columnchart',
          source: this.app.canUseFeature('reporting-ember-conversation-index-merging')
            ? 'conversation'
            : 'conversation_with_message_thread',
          type: COUNT,
          data: { property: FIRST_USER_CONVERSATION_PART_CREATED_AT },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter:
            this.buildTeammateFilter(
              this.app.canUseFeature('reporting-ember-conversation-index-merging')
                ? 'conversation'
                : 'conversation_with_message_thread',
            ) || {},
        },
        {
          name: 'replied_conversations_columnchart',
          source: 'consolidated_conversation_part',
          type: CARDINALITY,
          data: { property: CONVERSATION_ID },
          time: {
            property: COMMENT_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'and',
            filters: [
              ...TEAMMATE_REPLIES_FILTERS,
              this.buildTeammateFilter('consolidated_conversation_part'),
            ].compact(),
          },
        },
      ],
    };
  }
}
