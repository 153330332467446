/* import __COLOCATED_TEMPLATE__ from './number-of-teammate-replies-to-close-columnchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  MEDIAN,
  MIN,
  MAX,
  MEAN,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  CLUSTERED_TEAMMATE_REPLIES_COUNT,
  CONVERSATION_STATE,
} from 'embercom/lib/reporting/flexible/constants';
import { inject as service } from '@ember/service';

export default class NumberOfTeammateRepliesToCloseColumnchart extends Component {
  @service intl;
  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.aggregations = { defaultOption: MEDIAN, rest: [MIN, MAX, MEAN] };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
        createTitleTooltipText: (aggregation) => {
          return this.intl.t(
            'components.reporting.reports.effectiveness.number-of-teammate-replies-to-close-columnchart.tooltip',
            { selectedAggregation: aggregation },
          );
        },
      },
    };
    viewConfig.formatUnit = {
      unit: units.value,
      displayUnit: 'reply',
    };

    return viewConfig;
  }
  get dataConfig() {
    return {
      xAxis: {
        type: 'temporal',
        data: {
          interval: FILTER_PROPERTY_PLACEHOLDER,
          property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
        },
      },
      series: [
        {
          name: 'number_of_teammate_replies_to_close_columnchart',
          source: 'conversation_with_message_thread',
          type: MEDIAN,
          data: {
            property: CLUSTERED_TEAMMATE_REPLIES_COUNT,
          },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'and',
            filters: [
              {
                type: 'category',
                data: {
                  property: CONVERSATION_STATE,
                  values: ['closed'],
                },
              },
              {
                type: 'range',
                data: {
                  operators: ['gte'],
                  property: CLUSTERED_TEAMMATE_REPLIES_COUNT,
                  values: [1],
                },
              },
            ],
          },
        },
      ],
    };
  }
}
