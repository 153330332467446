/* import __COLOCATED_TEMPLATE__ from './schedule-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class ExportScheduleButtonsComponent extends Component {
  @service intercomEventService;
  @service intl;
  @service permissionsService;
  @tracked showScheduleModal = false;
  @tracked showDeleteModal = false;

  get scheduleGroupList() {
    return [
      {
        items: [
          {
            text: this.intl.t(
              'components.reporting.customer-support.export.schedule-buttons.schedule-group-list.export',
            ),
            icon: 'schedule',
            onSelectItem: async () => this._openScheduleModal(),
          },
          {
            text: this.intl.t(
              'components.reporting.customer-support.export.schedule-buttons.schedule-group-list.manage',
            ),
            icon: 'edit',
            onSelectItem: async () => this._openSidebar(),
            isDisabled: this.args.isScheduleManagementDisabled,
          },
        ],
      },
    ];
  }

  async _checkPermission() {
    return this.permissionsService.checkPermission('can_export_csv');
  }

  async _openScheduleModal() {
    try {
      await this._checkPermission();
      this.showScheduleModal = true;
    } catch (_e) {
      return;
    }
  }

  async _openSidebar() {
    try {
      await this._checkPermission();
      this.args.openSidebar();
    } catch (_e) {
      return;
    }
  }

  @action
  deleteSchedule() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'message_data_export',
      action: 'schedule_deleted',
    });
    this.args.deleteSchedule();
    this.showDeleteModal = false;
  }
}
