/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  classNameBindings: [
    'flexes:flex-auto:flex-none',
    'flexes:overflow-auto',
    'hasPadding:u__padt__20',
    'hasPadding:u__padb__20',
    'hasPadding:u__padl__30',
    'hasPadding:u__padr__30',
    'jsBindingClass',
  ],
  hasPadding: true,
  flexes: true,
});
