/* import __COLOCATED_TEMPLATE__ from './fin-ai-copilot-content-table.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { FinAIType } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  renderableChart?: any;
}

export default class FinAICopilotContentTable extends Component<Args> {
  get aiType() {
    return FinAIType.COPILOT;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Bespoke::FinAiCopilotContentTable': typeof FinAICopilotContentTable;
    'reporting/bespoke/fin-ai-copilot-content-table': typeof FinAICopilotContentTable;
  }
}
