/* import __COLOCATED_TEMPLATE__ from './teammate-table.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import Formatters, { units } from 'embercom/lib/reporting/flexible/formatters';
import { isEmpty, pick } from 'underscore';
import percent, { toPercentString } from 'embercom/lib/percentage-formatter';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { bind } from '@ember/runloop';
import { action } from '@ember/object';
import { botInboxAndOfficeHoursField } from 'embercom/lib/reporting/office-hours-field';
import {
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  CONVERSATION_PART_REPLIES_FILTERS,
  TEAMMATE_REPLIES_FILTERS,
  CLOSES_WITH_USER_PART_FILTERS,
  EXCLUDE_BOT_ADMIN,
} from 'embercom/lib/reporting/flexible/constants';

const TEAM_TEAMMATE_DEFAULT_FILTER_NAME = 'admin_participant_ids';

export default class TeammateTable extends Component {
  @service appService;
  @service intl;
  @tracked filteredTeammates = A([]);
  filterSelectedTeamTeammates = [];
  filterSelectedTeammates = [];

  constructor() {
    super(...arguments);
    this.timeFormatter = new Formatters[units.seconds]();
    // to prevent rebuilding of a potentially expensive map
    this.precalculatedAllTeammatesMap = this.getTeammates();
  }

  createdAtFieldName() {
    return FIRST_USER_CONVERSATION_PART_CREATED_AT;
  }

  get botInboxTimeExcluded() {
    return this.args.botInboxTime === 'excluded';
  }

  get filters() {
    if (!this.filteredTeammates || this.filterSelectedTeammates.length === 0) {
      return {};
    }

    return { [TEAM_TEAMMATE_DEFAULT_FILTER_NAME]: this.filteredTeammates };
  }

  get columns() {
    return [
      {
        label: this.intl.t('components.reporting.reports.overview.teammate-table.name'),
        isMain: true,
        valuePath: 'groupName',
        component: 'reporting/flexible/table-teammate-cell',
        componentData: this.teammatesMap,
        // this is required to support sorting not on response values but on derive data instead
        // e.g. we can't simply sort by teammate names because we have only teammate ids returned
        // in the response. This function is used to map teammate ids (stored in `groupName`)
        // to teammate names
        getRowValue: (row) => this.teammateName(row.groupName),
        aliases: { 'row-summary': 'Summary of teammates' },
        isSortable: true,
        sortingHelper: (row) => this.teammateName(row.groupName),
      },
      {
        label: this.intl.t('components.reporting.reports.overview.teammate-table.participated'),
        valuePath: 'col-participated',
        type: 'number',
        isSortable: true,
        tooltip: this.intl.t(
          'components.reporting.reports.overview.teammate-table.participated-tooltip',
        ),
      },
      {
        label: this.intl.t(
          'components.reporting.reports.overview.teammate-table.closed-by-teammates',
        ),
        valuePath: 'col-closed',
        type: 'number',
        isSortable: true,
        tooltip: this.intl.t(
          'components.reporting.reports.overview.teammate-table.closed-by-teammates-tooltip',
        ),
      },
      {
        label: this.intl.t(
          'components.reporting.reports.overview.teammate-table.median-response-time',
        ),
        valuePath: 'col-mrt',
        isSortable: true,
        type: 'number',
        formatter: bind(this, this.formatTime),
        minWidth: '160px',
        tooltip: this.intl.t(
          'components.reporting.reports.overview.teammate-table.median-response-time-tooltip',
        ),
      },
      {
        label: this.intl.t('components.reporting.reports.overview.teammate-table.emoji-label'),
        csvLabel: this.intl.t('components.reporting.reports.overview.teammate-table.csv-label'),
        valuePath: 'col-group-csat',
        type: 'number',
        isSortable: true,
        component: 'reporting/flexible/table-csat-cell',
        sortingHelper: (row) => this.getPercentageForSorting(row['col-group-csat']),
        getRowValue: (row) => this.formatPercentage(row['col-group-csat']),
        minWidth: '100px',
        tooltip: this.intl.t(
          'components.reporting.reports.overview.teammate-table.csv-label-tooltip',
        ),
      },
    ];
  }

  get dataConfig() {
    return {
      columns: [
        {
          name: 'col-participated',
          filter: {
            type: 'and',
            filters: [
              {
                type: 'exists',
                data: {
                  property: 'teammate_id',
                },
              },
              ...TEAMMATE_REPLIES_FILTERS,
              ...this.excludeBotFilter,
            ],
          },
          filterAlias: {
            admin_participant_ids: 'teammate_id',
          },
          source: 'consolidated_conversation_part',
          aggregation: {
            type: 'cardinality',
            data: {
              property: 'conversation_id',
            },
          },
          time: {
            property: 'comment_created_at',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
        {
          name: 'col-closed',
          filter: {
            type: 'and',
            filters: [
              ...CLOSES_WITH_USER_PART_FILTERS,
              {
                type: 'exists',
                data: {
                  property: 'teammate_id',
                },
              },
              ...this.excludeBotFilter,
            ],
          },
          filterAlias: {
            admin_participant_ids: 'teammate_id',
          },
          source: 'consolidated_conversation_part',
          aggregation: {
            type: 'cardinality',
            data: {
              property: 'conversation_id',
            },
          },
          time: {
            property: 'comment_created_at',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
        {
          name: 'col-mrt',
          filter: {
            type: 'and',
            filters: [
              {
                type: 'exists',
                data: {
                  property: 'teammate_id',
                },
              },
              ...CONVERSATION_PART_REPLIES_FILTERS,
            ],
          },
          filterAlias: {
            admin_participant_ids: 'teammate_id',
          },
          source: 'consolidated_conversation_part',
          aggregation: {
            type: 'median',
            data: {
              property: botInboxAndOfficeHoursField(
                'response_time',
                this.args.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
                true,
              ),
            },
          },
          time: {
            property: this.createdAtFieldName(),
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
        {
          name: 'col-group-csat_[value]',
          filter: {
            type: 'and',
            filters: [
              {
                type: 'category',
                data: {
                  property: 'rating_index',
                  values: [4, 5],
                },
              },
              {
                type: 'exists',
                data: {
                  property: 'teammate_id',
                },
              },
            ],
          },
          filterAlias: {
            admin_participant_ids: 'teammate_id',
          },
          source: 'conversation_rating',
          aggregation: {
            type: 'count',
            data: {
              property: 'rating_updated_at',
            },
          },
          time: {
            property: 'rating_updated_at',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
        {
          name: 'col-group-csat_[total]',
          filter: {
            type: 'and',
            filters: [
              {
                type: 'exists',
                data: {
                  property: 'teammate_id',
                },
              },
              {
                type: 'exists',
                data: {
                  property: 'rating_index',
                },
              },
            ],
          },
          filterAlias: {
            admin_participant_ids: 'teammate_id',
          },
          source: 'conversation_rating',
          aggregation: {
            type: 'count',
            data: {
              property: 'rating_updated_at',
            },
          },
          time: {
            property: 'rating_updated_at',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
      ],
      rows: [
        {
          name: 'row-teammates',
          type: 'term',
          ignoreFilters: {
            // we're skipping participation filters for all teammate rows
            columns: [
              'col-participated',
              'col-closed',
              'col-mrt',
              'col-group-csat_[value]',
              'col-group-csat_[total]',
            ],
            filters: [TEAM_TEAMMATE_DEFAULT_FILTER_NAME],
          },
          data: {
            term_size: 10000,
            property: 'teammate_id',
          },
        },
        {
          name: 'row-summary',
          type: 'allRecords',
        },
      ],
    };
  }

  get viewConfig() {
    return {
      rowsVisible: 10,
      rowOrder: [{ position: 0, valuePath: 'groupName', value: 'row-summary' }],
      sortState: { valuePath: 'col-participated', direction: 'desc' },
      // allows controlling row visibility based on the data returned from the server
      isVisibleRow: (row) => {
        return !isEmpty(this.teammatesMap[row.groupName]) || row.groupName === 'row-summary';
      },
      // allows merging front-end defined rows with rows fetched from the server
      mergeableRows: {
        // property of a row based on which merging happens
        valuePath: 'groupName',
        // front-end defined rows as map to speed up merging
        getRowsMap: () =>
          Object.values(this.teammatesMap).reduce((acc, t) => {
            acc[t.id] = { groupName: t.id };
            return acc;
          }, {}),
      },
    };
  }

  sumRowValues(columnName) {
    return (rows) => rows.reduce((sum, r) => sum + (r[columnName] || 0), 0);
  }

  get teammatesMap() {
    if (isEmpty(this.filteredTeammates)) {
      return this.precalculatedAllTeammatesMap;
    }

    let result = pick(this.precalculatedAllTeammatesMap, ...this.filteredTeammates);
    return result;
  }

  teammateName(teammateId) {
    let teammate = this.teammatesMap[teammateId];
    return teammate ? teammate.name : 'unknown';
  }

  formatTime(value) {
    let result = value ? this.timeFormatter.formatCounter(value) : '-';
    return result;
  }

  formatPercentage(complexValue) {
    if (!complexValue || !complexValue.total) {
      return '-';
    }

    let rawPercentage = percent(complexValue.total, complexValue.value || 0);
    let percentageValue = toPercentString(rawPercentage, 2);
    return `${percentageValue} (${complexValue.total})`;
  }

  getPercentageForSorting(complexValue) {
    if (!complexValue || !complexValue.total) {
      return -1;
    }

    return percent(complexValue.total, complexValue.value || 0);
  }

  getTeammates() {
    let admins = this.appService.app.humanAdmins;
    return admins.reduce((acc, a) => {
      acc[a.id] = {
        id: a.id,
        name: a.name,
        avatar: a.avatar,
      };

      return acc;
    }, {});
  }

  get teamsData() {
    let admins = this.appService.app.assignableAdmins;
    return admins.filter((admin) => admin.isTeam);
  }

  @action
  onTeamFilterSelection(selectedFilterItems) {
    let selectedTeams = this.teamsData.filter((td) => selectedFilterItems.includes(td.id));

    if (!selectedTeams) {
      return;
    }

    this.filterSelectedTeamTeammates = selectedTeams.flatMap((st) => st.member_ids);
    this.filteredTeammates.setObjects(
      this.filterSelectedTeamTeammates.concat(this.filterSelectedTeammates),
    );
  }

  @action
  onTeammateFilterSelection(selectedFilterItems) {
    if (!selectedFilterItems) {
      return;
    }

    this.filterSelectedTeammates = selectedFilterItems;
    this.filteredTeammates.setObjects(
      this.filterSelectedTeammates.concat(this.filterSelectedTeamTeammates),
    );
  }

  get excludeBotFilter() {
    return [EXCLUDE_BOT_ADMIN];
  }
}
