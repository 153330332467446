/* import __COLOCATED_TEMPLATE__ from './section-page.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import {
  type Section,
  type SectionWidgetType,
} from 'embercom/components/settings/data-import/modal/dialog';
import { action } from '@ember/object';

export interface Args {
  integrationCode: string;
  sectionName: string;
  section: Section;
  translationPrefix: string;
  saveMappings: () => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SectionPage extends Component<Signature> {
  @service declare intl: IntlService;
  @tracked selectedTab = 0;

  get currentStep() {
    return this.args.section.steps[this.selectedTab];
  }

  get steps() {
    return this.args.section.steps;
  }

  @action nextStep() {
    if (this.selectedTab < this.args.section.steps.length) {
      this.selectedTab++;
    }
  }

  @action prevStep() {
    if (this.selectedTab >= 0) {
      this.selectedTab--;
    }
  }

  hasPrevStep = () => {
    return this.selectedTab > 0;
  };

  currentWidgetIs = (widget: SectionWidgetType) => {
    return this.currentStep.widget === widget;
  };

  sectionShowSteps = () => {
    return this.args.section.steps.length > 1;
  };

  stepId = (index: number) => {
    return (index + 1).toString();
  };

  get translationPrefix() {
    let base = this.args.translationPrefix;
    return `${base}.accordion.sections.${this.args.sectionName}`;
  }

  translationKey = (path: string) => {
    return `${this.translationPrefix}.${path}`;
  };

  translation = (path: string) => {
    return this.intl.t(this.translationKey(path));
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::SectionPage': typeof SectionPage;
    'settings/data-import/modal/section-page': typeof SectionPage;
  }
}
