/* import __COLOCATED_TEMPLATE__ from './select-teammate-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';

export default class SelectTeammateDropdown extends Component {
  get adminsWhoCanManageTeammates() {
    return this.args.model.app.adminsWhoCanManageTeammates.map((admin) => {
      return {
        text: admin.name,
        value: admin,
        model: admin,
        component: 'appstore/app-package/onboarding/invite-teammates-item',
      };
    });
  }
}
