/* import __COLOCATED_TEMPLATE__ from './team-or-admin-dropdown-option.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import templateOnlyComponent from '@ember/component/template-only';
import type Team from 'embercom/models/team';
import type Admin from 'embercom/models/admin';

export interface Args {
  item: { text: string; value: string };
  componentAttrs: { model: Team | Admin };
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const TeamOrAdminDropdownOption = templateOnlyComponent<Signature>();
export default TeamOrAdminDropdownOption;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::TeamOrAdminDropdownOption': typeof TeamOrAdminDropdownOption;
    'settings/data-import/modal/team-or-admin-dropdown-option': typeof TeamOrAdminDropdownOption;
  }
}
