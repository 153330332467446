/* import __COLOCATED_TEMPLATE__ from './self-serve-tried-conversation-rating.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  SELF_SERVE_FAILED_CONVERSATION_RATING_ALL,
  SELF_SERVE_FAILED_AMAZING_GREAT_CONVERSATION_RATING,
} from 'embercom/lib/reporting/csf-queries';
import { getTimeConfig } from 'embercom/lib/reporting/csf-query-helper';
import { buildDataConfigWithComparisons } from '../../../../lib/reporting/comparison-data-config';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';

const SELF_SERVE_FAILED_CONVERSATION_RATING_NUMERATOR =
  'self-serve-failed-conversation-rating-numerator';

export default class SelfServeTriedConversationRating extends Component {
  get viewConfig() {
    return {
      formatUnit: { unit: units.percent },
      counter: {
        [SELF_SERVE_FAILED_CONVERSATION_RATING_NUMERATOR]: {
          improvementDirection: improvementDirections.INCREASING,
        },
      },
    };
  }

  get time() {
    return getTimeConfig({ range: this.args.range });
  }

  get dataConfig() {
    return buildDataConfigWithComparisons(
      {
        series: [
          {
            name: SELF_SERVE_FAILED_CONVERSATION_RATING_NUMERATOR,
            ...SELF_SERVE_FAILED_AMAZING_GREAT_CONVERSATION_RATING,
            time: this.time,
          },
          {
            name: 'self-serve-failed-conversation-rating-denominator',
            ...SELF_SERVE_FAILED_CONVERSATION_RATING_ALL,
            time: this.time,
          },
        ],
      },
      this.args.range,
    );
  }
}
