/* import __COLOCATED_TEMPLATE__ from './started-by-user-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { INBOUND_TEXT, OUTBOUND_TEXT } from 'embercom/lib/reporting/flexible/constants';

export default class StartedByUserFilter extends Component {
  @service intercomEventService;

  selectionItems = [
    { text: INBOUND_TEXT, value: true }, // started by Customer
    { text: OUTBOUND_TEXT, value: false }, // replied to Bot/Message
  ];

  get selectedValue() {
    if (isEmpty(this.args.selected)) {
      return true; // started by Customer is default selection
    }
    return this._retrieveRealValue(this.args.selected);
  }

  get label() {
    return this.selectedValue ? INBOUND_TEXT : OUTBOUND_TEXT;
  }

  @action
  changeSelection(selectedValue) {
    if (this.args.setSelected) {
      this.args.setSelected([this._retrieveRealValue(selectedValue)]);
    }
  }

  @action
  onClose() {
    if (this.isDestroying) {
      // for some reason onClose gets triggered when you remove the filter
      // even if the dropdown isn't currently open
      return;
    }

    if (this.args.setSelected) {
      this.args.setSelected([this._retrieveRealValue(this.selectedValue)]);
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_started_by_user',
      object: this.args.analyticsObject,
      type: this.selectedValue,
    });
  }

  _retrieveRealValue(value) {
    return Array.isArray(value) ? value[0] === 'true' || value[0] === true : value;
  }
}
