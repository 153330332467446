/* import __COLOCATED_TEMPLATE__ from './step0-teammate-data-survey.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';

export default class SeatlessExperienceTeammateDataSurveyComponent extends Component {
  @service intercomEventService;
  @service modelDataCacheService;
  @service router;
  @service appService;

  @tracked selectedUseCase;
  @tracked selectedExperience;
  @tracked selectedDepartment;
  @tracked selectedRole;
  @tracked useCaseOther;
  @tracked departmentOther;
  @tracked roleOther;
  @tracked manageTeam = '';
  @tracked selectedTool;
  @tracked selectedToolOther;

  constructor() {
    super(...arguments);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'teammate_role_data_modal',
    });
  }

  get surveyCompleted() {
    if (this.appService.app.canSeeProfilingSurvey) {
      return this._departmentCompleted && this._manageTeamCompleted;
    }
    return this._departmentCompleted && this._manageTeamCompleted && this._toolsSelectionCompleted;
  }

  get _manageTeamCompleted() {
    return this.manageTeam && this.manageTeam.trim();
  }

  get _toolsSelectionCompleted() {
    return (
      (this.selectedTool && this.selectedTool !== 'tool_other') ||
      (this.selectedTool === 'tool_other' &&
        this.selectedToolOther &&
        this.selectedToolOther.trim())
    );
  }

  get _useCaseCompleted() {
    return (
      (this.selectedUseCase && this.selectedUseCase !== 'use_case_other') ||
      (this.selectedUseCase === 'use_case_other' && this.useCaseOther && this.useCaseOther.trim())
    );
  }

  get _experienceCompleted() {
    return this.selectedExperience && this.selectedExperience.trim();
  }

  get _departmentCompleted() {
    return (
      (this.selectedDepartment && this.selectedDepartment !== 'dept_other') ||
      (this.selectedDepartment === 'dept_other' &&
        this.departmentOther &&
        this.departmentOther.trim())
    );
  }

  get _roleCompleted() {
    return (
      this.selectedRole &&
      (!this._roleIsOther || (this._roleIsOther && this.roleOther && this.roleOther.trim()))
    );
  }

  get _roleIsOther() {
    return this.selectedRole && this.selectedRole.endsWith('_other');
  }

  @action setSelectedDepartment(selectedItem) {
    if (this.selectedDepartment !== selectedItem) {
      this.selectedDepartment = selectedItem;
      this.selectedRole = undefined;
    }
  }

  @task *submitTeammateRoleData() {
    try {
      yield this._saveTeammateRoleData();

      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: 'teammate_role_data_modal',
        role_data: {
          use_case: this.selectedUseCase,
          experience: this.selectedExperience,
          department: this.selectedDepartment,
          role: this.selectedRole,
        },
      });

      this.args.model.admin.teammate_role_data_completed = true;
      this.modelDataCacheService.clear();
    } catch {
      // noop
    } finally {
      this.router.transitionTo('apps.app.seatless-experience.select-seat');
    }
  }

  _saveTeammateRoleData() {
    let params = {
      app_id: this.args.model.app.id,
      admin_id: this.args.model.admin.id,
      use_case: this.selectedUseCase || 'use_case_other',
      experience: this.selectedExperience,
      department: this.selectedDepartment,
      role: this.selectedRole || 'other_other',
      use_case_other: this.useCaseOther,
      department_other: this.departmentOther,
      role_other: this.roleOther,
      manage_team: this.manageTeam || 'dont_ask_me_again',
      tool: this.selectedTool || 'tool_other',
      tool_other: this.selectedToolOther || 'dont_ask_me_again',
    };
    return post('/ember/teammate_role_data', params);
  }
}
