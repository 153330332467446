/* import __COLOCATED_TEMPLATE__ from './template-modal.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ReportingTemplates from 'embercom/services/reporting-templates';
import type Report from 'embercom/models/reporting/custom/report';
import type ReportingFolderManagerService from 'embercom/services/reporting-folder-manager-service';
import { isPresent } from '@ember/utils';
import type CustomReportsService from 'embercom/services/custom-reports-service';
import type Chart from 'embercom/models/reporting/custom/chart';

interface Args {
  closeModal: () => void;
}

export default class TemplateModal extends Component<Args> {
  @service declare router: $TSFixMe;
  @service declare store: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare reportingTemplates: ReportingTemplates;
  @service declare reportingFolderManagerService: ReportingFolderManagerService;
  @service declare customReportsService: CustomReportsService;

  @tracked report: Report;
  @tracked templateKey: string | null;
  @tracked width;
  @tracked searchTerm = '';
  @tracked isHovered = false;
  @tracked isBuildingTemplate = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.report = this.makeBaseTemplate();
    this.templateKey = null;
    this.width = this.widthForState;
  }

  get filteredTemplates() {
    return this.reportingTemplates
      .searchReports(this.searchTerm)
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  makeBaseTemplate() {
    return this.store.createRecord('reporting/custom/report', {
      id: this.customReportsService.generateID(),
      fromTemplate: true,
    });
  }

  get widthForState() {
    return this.templateKey ? 'vw95' : 'xl';
  }

  get templateTitle() {
    if (!isPresent(this.templateKey)) {
      return;
    }

    return this.reportingTemplates.reportTemplatesById[this.templateKey!].title;
  }

  @action
  close() {
    // unload and recreate the report so that it's not cached locally. we don't want to leave orphan reports lying around
    this.cleanUpStore();
    if (!this.templateKey) {
      // close the modal if we're not previewing a template
      this.args.closeModal();
      return;
    }

    // otherwise, reset the state & go back to the template list
    this.templateKey = null;
    this.width = this.widthForState;
    this.report = this.makeBaseTemplate();
  }

  cleanUpStore() {
    this.report.charts.toArray().forEach((chart: Chart) => {
      chart.deleteRecord();
      this.store.unloadRecord(chart);
    });
    this.report.deleteRecord();
    this.store.unloadRecord(this.report);
  }

  @action
  async saveReportTemplate() {
    this.isBuildingTemplate = true;
    this.trackAnalyticsEvent('template_report_saved');
    this.reportingFolderManagerService.resetActiveFolder();
    this.isBuildingTemplate = false;
    this.args.closeModal();
    this.router.transitionTo('apps.app.reports.custom-reports.report.show', this.report.id, {
      queryParams: {
        fromReportCreation: true,
      },
    });
  }

  @action
  useTemplateReport(templateKey: string) {
    this.templateKey = templateKey;
    this.width = this.widthForState;
    this.trackAnalyticsEvent('template_report_previewed');
  }

  trackAnalyticsEvent(action: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'custom_report',
      section: 'reports',
      template_key: this.templateKey,
      is_on_reports_1: false,
    });
  }

  get templateFeatureKey() {
    if (!isPresent(this.templateKey)) {
      return;
    }
    // @ts-ignore we check null state above
    return this.reportingTemplates.reportTemplatesById[this.templateKey].featureKey;
  }

  @action
  buildReportFromScratch() {
    this.trackReportCreationEvent();
    this.customReportsService.handleCreateReport();
    this.close();
  }

  @action
  trackReportCreationEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      place: 'new_report_button',
      object: 'custom_report',
      context: 'create_report_from_nav',
      section: 'reports',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::TemplateModal': typeof TemplateModal;
    'reporting/custom/report/template-modal': typeof TemplateModal;
  }
}
