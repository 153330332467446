/* import __COLOCATED_TEMPLATE__ from './conversation-card.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import Velocity from 'velocity';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

const VIEWPORT_SELECTOR = '[data-reports-scroll-container]';
const SCROLL_OFFSET = -50;

export default class TopicConversationsCardComponent extends Component {
  @service store;
  @service intercomEventService;
  @service appService;

  @tracked cardIsActive = false;

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.app.currentAdmin;
  }

  get otherMentionCount() {
    return this.args.conversation.matching_parts.length - 1;
  }

  get isExpanding() {
    return this.args.expandingConversationId === this.args.conversation.id;
  }

  get isExpanded() {
    return (
      this.args.expandingConversationId === this.args.conversation.id &&
      this.args.expandedConversation?.id === this.args.conversation.id.toString()
    );
  }

  @task
  *expandCard(element) {
    let contentExpanded = element.querySelector('[data-conversation-card-content-expanded]');
    if (contentExpanded) {
      yield Velocity(contentExpanded, 'stop');
      yield Velocity(contentExpanded, { maxHeight: '70vh' }, { duration: 500 });
    }
  }

  @task
  *scrollToCard(element) {
    let viewportElement = document.querySelector(VIEWPORT_SELECTOR);
    if (viewportElement) {
      yield Velocity(viewportElement, 'stop');
      yield Velocity(element, 'scroll', {
        duration: 500,
        offset: SCROLL_OFFSET,
        container: viewportElement,
      });
    }
  }

  @task
  *onCardExpand(element) {
    yield this.expandCard.perform(element);
    yield this.scrollToCard.perform(element);
  }

  @action
  clickRemoveConversation() {
    this.args.removeConversation(this.args.conversation.id);
  }

  @action
  onClick() {
    if (!this.args.conversation.restricted) {
      this.args.expandConversation(this.args.conversation.id);
      this.intercomEventService.trackEvent('viewed-conversation-in-topics');
    }
  }
}
