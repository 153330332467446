/* import __COLOCATED_TEMPLATE__ from './actions-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import {
  RULE_ACTION_MAP,
  createConversationDataAttributeItems,
} from 'embercom/lib/inbox/constants';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';

export interface ActionsEditorArgs {
  rule: $TSFixMe;
  slaPaywall?: $TSFixMe;
  allowedRuleActions: string[];
  onChange?: () => void;
  isDisabled?: boolean;
  onDelete?: () => void;
  actionEditorContext: $TSFixMe;
  hideIFTTT?: boolean;
  actionsType?: $TSFixMe;
  isReadOnly?: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: ActionsEditorArgs;
  Blocks: {
    default: [{ label: string | undefined }];
  };
}

type RuleActionName = keyof typeof RULE_ACTION_MAP;

export default class ActionsEditor extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare attributeService: $TSFixMe;
  @service declare ruleActionService: $TSFixMe;
  @service declare intl: IntlService;

  multiActionIdentifiers = [
    'add-tag-to-conversation',
    'workflow-connector-action',
    'remove-tag-from-conversation',
    'remove-tag-from-user',
  ];
  conversationAttributeIdentifier = 'set-conversation-data-attribute';
  actionsType = 'then';

  get usedActionIdentifiers(): RuleActionName[] {
    if (!this.args.rule || !this.args.rule.actions) {
      return [];
    }

    return this.args.rule.actions.map((ruleAction: $TSFixMe) => ruleAction.type);
  }

  get removedActionIdentifiers() {
    return this.usedActionIdentifiers.filter(
      (item: RuleActionName) => !this.multiActionIdentifiers.includes(item),
    );
  }

  get unusedActionIdentifiers() {
    return this.args.allowedRuleActions.filter(
      (item: RuleActionName) => !this.removedActionIdentifiers.includes(item),
    );
  }

  get conversationCustomAttributes() {
    return this.attributeService.nonArchivedNonSystemDefinedConversationCustomAttributes;
  }

  get conversationCustomAttributeItems() {
    return createConversationDataAttributeItems(this.conversationCustomAttributes);
  }

  get usedConversationCustomAttributeRuleActions() {
    return this.args.rule.actions.filter((ruleAction: $TSFixMe) => {
      return ruleAction.type === this.conversationAttributeIdentifier;
    });
  }

  get usedConversationCustomAttributeRuleActionIdentifiers() {
    return this.usedConversationCustomAttributeRuleActions.map(
      (ruleAction: $TSFixMe) => ruleAction.actionData.attribute_identifier,
    );
  }

  get availableConversationCustomAttributeItems() {
    return this.conversationCustomAttributeItems.filter((item: $TSFixMe) => {
      return !this.usedConversationCustomAttributeRuleActionIdentifiers.includes(
        item.attribute_identifier,
      );
    });
  }

  get availableActionIdentifiers() {
    let availableActionIdentifiers = this.unusedActionIdentifiers.slice();

    let hasCustomerFacingAction = this.usedActionIdentifiers.any(
      (action: RuleActionName) => (RULE_ACTION_MAP as $TSFixMe)[action].isCustomerFacing,
    );
    if (hasCustomerFacingAction) {
      availableActionIdentifiers = availableActionIdentifiers.reject(
        (action: RuleActionName) => (RULE_ACTION_MAP as $TSFixMe)[action].isCustomerFacing,
      );
    }
    if (this.hasUsedAnyAssignmentActionIdentifier) {
      availableActionIdentifiers = availableActionIdentifiers.reject(
        (action: RuleActionName) => (RULE_ACTION_MAP as $TSFixMe)[action].isAssignment,
      );
    }

    return availableActionIdentifiers;
  }

  get hasUsedAnyAssignmentActionIdentifier() {
    return this.usedActionIdentifiers.any(
      (action: RuleActionName) => (RULE_ACTION_MAP as $TSFixMe)[action].isAssignment,
    );
  }

  get availableActionConfigs() {
    return this.availableActionIdentifiers.map(
      (ruleAction: RuleActionName) => (RULE_ACTION_MAP as $TSFixMe)[ruleAction],
    );
  }

  get availableActionsGroupList() {
    let items = this.availableActionConfigs.sortBy('order');

    if (this.args.slaPaywall?.isActive) {
      let slaItem = items.find((item: $TSFixMe) => item.identifier === 'apply-conversation-sla');
      slaItem = Object.assign({}, slaItem, {
        component: 'rules/actions/actions-select-paywalled-item-component',
        componentShouldReplaceItem: true,
        isPaywalled: true,
        paywallOpenUpgradeModal: this.args.slaPaywall.openUpgradeModal,
        paywallAnalyticsEventData: this.args.slaPaywall.analyticsEventData,
      });
      items = items.reject((item) => item.identifier === 'apply-conversation-sla');
      items = items.concat(slaItem);
    }

    items = items.map((item) => ({
      ...item,
      text: this.intl.t(`settings.inbox-rules.rule-action-map.${item.intlKey}`),
    }));

    if (this.availableConversationCustomAttributeItems) {
      items = items.concat(
        this.availableConversationCustomAttributeItems.map((item: $TSFixMe) => ({
          ...item,
          text: this.intl.t(
            `settings.inbox-rules.rule-action-map.set-conversation-data-attribute`,
            { name: item.name },
          ),
        })),
      );
    }
    return [{ items }];
  }

  @action addAction(item: $TSFixMe, event: Event) {
    if (event && event.stopImmediatePropagation) {
      event.stopImmediatePropagation();
    }
    if (item.isPaywalled) {
      return;
    }

    this.ruleActionService.addActionToTarget(this.args.rule.actions, item);

    let onChange = this.args.onChange;
    if (onChange) {
      onChange();
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'added',
      object: 'action',
      identifier: item.identifier,
    });
  }

  @action removeAction(action: $TSFixMe) {
    let actions = this.args.rule.actions;
    actions.removeFragment(action);

    let onChange = this.args.onChange;
    if (onChange) {
      onChange();
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'removed',
      object: 'action',
      identifier: action.type,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Rules::Actions::ActionsEditor': typeof ActionsEditor;
    'rules/actions/actions-editor': typeof ActionsEditor;
  }
}
