/* import __COLOCATED_TEMPLATE__ from './category-cards.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import type IntlService from 'ember-intl/services/intl';
import { type CategoryCardConfig } from 'embercom/components/settings/ticket-data/tickets-onboarding/category-card';

interface Args {
  onSeeExample: (category: TicketCategory) => void;
}

export default class CategoryCards extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;

  private intlKeyPrefix = 'settings.ticket-data.tickets-onboarding.categories-introduction.cards';

  get categoryCardConfigs(): CategoryCardConfig[] {
    return [
      this.customerCategoryCardConfig,
      this.backOfficeCategoryCardConfig,
      this.trackerCategoryCardConfig,
    ];
  }

  get customerCategoryCardConfig(): CategoryCardConfig {
    return {
      category: TicketCategory.Request,
      title: this.intl.t(`${this.intlKeyPrefix}.customer-ticket.title`),
      description: this.intl.t(`${this.intlKeyPrefix}.customer-ticket.body`),
      icon: 'ticket',
      attributes: this.customerCategoryAttributes,
      onSeeExample: this.args.onSeeExample,
    };
  }

  get backOfficeCategoryCardConfig(): CategoryCardConfig {
    return {
      category: TicketCategory.Task,
      title: this.intl.t(`${this.intlKeyPrefix}.back-office-ticket.title`),
      description: this.intl.t(`${this.intlKeyPrefix}.back-office-ticket.body`),
      icon: 'back-office',
      attributes: this.backOfficeCategoryAttributes,
      onSeeExample: this.args.onSeeExample,
    };
  }

  get trackerCategoryCardConfig(): CategoryCardConfig {
    return {
      category: TicketCategory.Tracker,
      title: this.intl.t(`${this.intlKeyPrefix}.tracker-ticket.title`),
      description: this.intl.t(`${this.intlKeyPrefix}.tracker-ticket.body`),
      icon: 'tracker',
      attributes: this.trackerCategoryAttributes,
      onSeeExample: this.args.onSeeExample,
    };
  }

  get customerCategoryAttributes() {
    let key = `${this.intlKeyPrefix}.customer-ticket`;

    let attributes = [
      { icon: 'eye', text: this.intl.t(`${key}.sharing`) },
      { icon: 'chat-filled', text: this.intl.t(`${key}.replies`) },
    ];

    return attributes;
  }

  get backOfficeCategoryAttributes() {
    let key = `${this.intlKeyPrefix}.back-office-ticket`;

    let attributes = [
      { icon: 'hidden', text: this.intl.t(`${key}.sharing`) },
      { icon: 'chat-filled', text: this.intl.t(`${key}.replies`) },
    ];

    return attributes;
  }

  get trackerCategoryAttributes() {
    let key = `${this.intlKeyPrefix}.tracker-ticket`;
    let sharingKey = 'sharing-tickets-intervention';

    return [
      { icon: 'hidden', text: this.intl.t(`${key}.${sharingKey}`) },
      { icon: 'chat-filled', text: this.intl.t(`${key}.replies`) },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketData::TicketsOnboarding::CategoryCards': typeof CategoryCards;
    'settings/ticket-data/tickets-onboarding/category-cards': typeof CategoryCards;
  }
}
