/* import __COLOCATED_TEMPLATE__ from './status-filter.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class SwitchMonitorStatusFilter extends Component {
  @service intl;
  @tracked status = '';

  get title() {
    let prefix = this.intl.t('channels.switch.settings.monitor.filters.status');
    let text = this.statuses.find((i) => i.value === this.status).text;

    return `${prefix} ${text}`;
  }

  get statuses() {
    let any = {
      text: this.intl.t('channels.switch.settings.monitor.table.stamps.any'),
      value: '',
    };
    let options = ['conversation_started', 'switched', 'failed_sms'].map((k) => ({
      text: this.intl.t(`channels.switch.settings.monitor.table.stamps.${k}`),
      value: k,
    }));

    return [any, ...options];
  }

  @action
  onSelectItem(status) {
    this.status = status;

    if (this.args.onChange) {
      this.args.onChange(status);
    }
  }
}
