/* import __COLOCATED_TEMPLATE__ from './share-options.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { type AccessOption } from 'embercom/components/reporting/custom/report/share';
import { action } from '@ember/object';
import { indexBy } from 'underscore';
import { tracked } from '@glimmer/tracking';
import { AccessType } from 'embercom/services/report-access-service';
import type RouterService from '@ember/routing/router-service';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type ReportAccessControlList from 'embercom/models/reporting/report-access-control-list';
import type ModelWithPermissions from 'embercom/models/model-with-permissions';

interface Args {
  subtle: boolean;
  selectedValue: AccessType | null;
  onSelectItem: (value: AccessOption) => void;
  removeAccess?: any;
  accessControl: ReportAccessControlList | null;
  canRemoveAccess: boolean;
  readOnly: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

interface SelectedValue {
  text: string;
  description: string;
  icon: InterfaceIconName;
  accessType: AccessType;
}

export default class ReportingCustomReportShareOptions extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare router: RouterService;

  @tracked hoverIndex = 0;

  mappings = indexBy(this.accessOptions, 'accessType');

  get selectedValue() {
    if (this.args.selectedValue) {
      return this.args.selectedValue;
    }
    return AccessType.EDIT;
  }

  get baseOptions(): AccessOption[] {
    return [
      {
        text: this.intl.t('reporting.custom-reports.report.share-modal.full-access'),
        description: this.intl.t(
          'reporting.custom-reports.report.share-modal.full-access-description',
        ),
        icon: 'edit' as InterfaceIconName,
        accessType: AccessType.EDIT,
      },
      {
        text: this.intl.t('reporting.custom-reports.report.share-modal.explore-only'),
        description: this.intl.t(
          'reporting.custom-reports.report.share-modal.explore-only-description',
        ),
        icon: 'search' as InterfaceIconName,
        accessType: AccessType.VIEW,
      },
      {
        text: this.intl.t('reporting.custom-reports.report.share-modal.view-only'),
        description: this.intl.t(
          'reporting.custom-reports.report.share-modal.view-only-description',
        ),
        icon: 'eye' as InterfaceIconName,
        accessType: AccessType.RESTRICTED_VIEW,
      },
    ];
  }

  @action
  mapAccessTypeToText(accessType: AccessType | null) {
    if (accessType) {
      return this.mappings[accessType].text;
    }
    return this.intl.t('reporting.custom-reports.report.share-modal.no-access');
  }

  get accessOptions() {
    return this.teammateAccessOptions;
  }

  get teammateAccessOptions() {
    return this.baseOptions.map((option) => {
      return {
        ...option,
        isSelected: option.accessType === this.selectedValue,
      };
    });
  }

  @action
  onSelectItem(selectedValue: SelectedValue) {
    this.args.onSelectItem(selectedValue);
  }

  @action
  onShow() {
    this.hoverIndex = 0;
  }

  @action
  onHover(index: number) {
    this.hoverIndex = index;
  }

  @action
  conflictAction() {
    if (this.accessControl?.isAdminAccessControl && this.accessControl.adminId) {
      let url = this.router.urlFor(
        'apps.app.settings.workspace.teammates.teammate.permissions',
        this.accessControl.adminId,
      );
      safeWindowOpen(url, '_blank');
    } else if (this.accessControl?.isRoleAccessControl && this.accessControl.roleId) {
      let url = this.router.urlFor(
        'apps.app.settings.workspace.teammates.roles.edit',
        this.accessControl.roleId,
      );
      safeWindowOpen(url, '_blank');
    }
  }

  get accessControl() {
    return this.args.accessControl;
  }

  get hasConflict(): boolean {
    if (
      this.accessControl &&
      this.accessControl.isAdminAccessControl &&
      this.accessControl.admin?.currentAppPermissions
    ) {
      return this.checkConflict(this.accessControl, this.accessControl.admin.currentAppPermissions);
    } else if (
      this.accessControl &&
      this.accessControl.isRoleAccessControl &&
      this.accessControl.role
    ) {
      return this.checkConflict(this.accessControl, this.accessControl.role);
    }
    return false;
  }

  checkConflict(accessControl: ReportAccessControlList, permission: ModelWithPermissions) {
    return (
      this.hasViewConflict(accessControl, permission) ||
      this.hasEditConflict(accessControl, permission)
    );
  }

  hasViewConflict(accessControl: ReportAccessControlList, permission: ModelWithPermissions) {
    // user has view access type but no view permission
    return (
      [AccessType.VIEW, AccessType.RESTRICTED_VIEW].includes(accessControl.accessType) &&
      !permission.can_access_reporting
    );
  }

  hasEditConflict(accessControl: ReportAccessControlList, permission: ModelWithPermissions) {
    return (
      accessControl.accessType === AccessType.EDIT && !permission.can_reporting__reports__update
    );
  }

  get conflictText() {
    let EMPTY_STRING = '';
    if (
      this.accessControl &&
      this.accessControl.isAdminAccessControl &&
      this.accessControl.admin?.currentAppPermissions
    ) {
      return this.makeText(this.accessControl, this.accessControl?.admin?.currentAppPermissions);
    } else if (
      this.accessControl &&
      this.accessControl.isRoleAccessControl &&
      this.accessControl.role
    ) {
      return this.makeText(this.accessControl, this.accessControl.role);
    } else {
      return EMPTY_STRING;
    }
  }

  makeText(accessControl: ReportAccessControlList, permission: ModelWithPermissions) {
    if (this.hasViewConflict(accessControl, permission) && accessControl.isAdminAccessControl) {
      return this.intl.t('reporting.custom-reports.report.share-modal.restricted-view-permission', {
        htmlSafe: true,
      });
    } else if (
      this.hasViewConflict(accessControl, permission) &&
      accessControl.isRoleAccessControl
    ) {
      return this.intl.t(
        'reporting.custom-reports.report.share-modal.restricted-role-view-permission',
        {
          htmlSafe: true,
        },
      );
    } else if (
      this.hasEditConflict(accessControl, permission) &&
      accessControl.isAdminAccessControl
    ) {
      return this.intl.t('reporting.custom-reports.report.share-modal.restricted-edit-permission', {
        htmlSafe: true,
      });
    } else if (
      this.hasEditConflict(accessControl, permission) &&
      accessControl.isRoleAccessControl
    ) {
      return this.intl.t(
        'reporting.custom-reports.report.share-modal.restricted-role-edit-permission',
        {
          htmlSafe: true,
        },
      );
    }
    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ShareOptions': typeof ReportingCustomReportShareOptions;
    'reporting/custom/report/share-options': typeof ReportingCustomReportShareOptions;
  }
}
