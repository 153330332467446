/* import __COLOCATED_TEMPLATE__ from './additional-seats-cost-change-card.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AdditionalSeatsCostChangeCard extends Component {
  @service appService;
  @tracked permissionsObject = this.args.permissionsObject;

  get isInboxSeat() {
    return this.appService.app.canUsePerProductPricingFlow;
  }

  get hasAdditionalSeats() {
    if (!this.isInboxSeat) {
      return this.hadAdditionalSeatsBefore || this.hasAdditionalSeatsAfter;
    }
    return this.additionalSeatsWithoutCurrentChange || this.additionalSeatsAfterCurrentChange;
  }

  get additionalSeatsWithoutCurrentChange() {
    return !this.changeInInboxSeats && this.hadAdditionalSeatsBefore;
  }

  get additionalSeatsAfterCurrentChange() {
    return this.changeInInboxSeats && this.hasAdditionalSeatsAfter;
  }

  get hadAdditionalSeatsBefore() {
    return this.args.seatInfo.numberOfChargeableSeatsBefore > 0;
  }

  get hasAdditionalSeatsAfter() {
    return this.args.seatInfo.numberOfChargeableSeatsAfter > 0;
  }

  get changeInFreeSeats() {
    if (this.args.seatInfo.changeInNumberOfFreeSeats === 0) {
      return false;
    }
    return !this.isInboxSeat || this.changeInInboxSeats;
  }

  get changeInChargeableSeats() {
    if (this.args.seatInfo.changeInNumberOfChargeableSeats === 0) {
      return false;
    }
    return !this.isInboxSeat || this.changeInInboxSeats;
  }

  get changeInInboxSeats() {
    return this.args.permissionsObject.has_inbox_access !== this.args.originallyHadInboxSeat;
  }

  get showSeatAndPriceInfo() {
    if (!this.isInboxSeat) {
      return this.args.seatInfo.changeInNumberOfChargeableSeats > 0;
    }
    return this.args.permissionsObject.has_inbox_access && !this.args.originallyHadInboxSeat;
  }

  get hasPendingInvites() {
    return this.args.seatInfo.seatsInUseByInvites > 0;
  }
}
