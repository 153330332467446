/* import __COLOCATED_TEMPLATE__ from './reopened-conversations-comparison-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  CARDINALITY,
  STATE_START,
  CONVERSATION_ID,
  CONVERSATION_STATE,
  PREVIOUS_CONVERSATION_STATE,
} from 'embercom/lib/reporting/flexible/constants';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import { inject as service } from '@ember/service';

const seriesName = 'reopened_conversations_counter';

export default class ReopenedConversationsComparisonCounter extends Component {
  @service intl;

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value };
    viewConfig.aggregations = { defaultOption: CARDINALITY };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[STATE_START],
        isComparison: true,
        createTitleTooltipText: () =>
          this.intl.t('reporting.conversations-report.reopened-conversations.tooltip'),
      },
    };
    viewConfig.counter = {
      [seriesName]: {
        improvementDirection: improvementDirections.DECREASING,
      },
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      series: [
        {
          name: seriesName,
          source: 'conversation_state',
          type: CARDINALITY,
          data: { property: CONVERSATION_ID },
          time: {
            property: STATE_START,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'and',
            filters: [
              {
                type: 'category',
                data: {
                  property: CONVERSATION_STATE,
                  values: ['opened'],
                },
              },
              {
                type: 'category',
                data: {
                  property: PREVIOUS_CONVERSATION_STATE,
                  values: ['closed'],
                },
              },
            ],
          },
        },
      ],
    };
  }
}
