/* import __COLOCATED_TEMPLATE__ from './permission-request.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';

export default Component.extend({
  tagName: '',
  appService: service(),
  inboxVersion: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  permissionLabel: reads('model.permissionRequired'),

  modalHeaderText: reads('model.headerText'),

  // if multiple permissions are passed in
  permissionLabels: reads('model.permissionsRequired'),

  adminsWhoCanChangePermissions: computed(
    'appService.app.cannotAssignWiderAccess',
    'model.{adminsWhoCanChangePermissions,permissionRequired,permissionsRequired}',
    function () {
      if (this.appService.app.cannotAssignWiderAccess) {
        return this.get('model.adminsWhoCanChangePermissions').filter((admin) => {
          if (this.model.permissionsRequired) {
            this.adminHasPermissions(admin, this.model.permissionsRequired);
          } else if (this.model.permissionRequired) {
            return admin.currentAppPermissions[this.get('model.permissionRequired')];
          }
        });
      }
      return this.get('model.adminsWhoCanChangePermissions');
    },
  ),

  adminHasPermissions(admin, permissions = []) {
    return permissions.every((permission) => admin.currentAppPermissions[permission]);
  },

  closeModal: action(function () {
    this.modalService.closeModal();
    let action = this.complete;
    if (action) {
      action();
    }
    return true;
  }),
});
