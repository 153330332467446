/* import __COLOCATED_TEMPLATE__ from './ticket-type-settings.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type Router from '@ember/routing/router-service';
import type TicketType from 'embercom/models/inbox/ticket-type';
import type Session from 'embercom/services/session';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { AVAILABLE_EMOJI } from 'embercom/lib/available-emoji';
import shuffle from '@intercom/pulse/lib/shuffle';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
// @ts-ignore
import { trackedReset } from 'tracked-toolbox';
// @ts-ignore
import intermoji from '@intercom/intermoji';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';
import type Transition from '@ember/routing/transition';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import { type TicketCustomStateHash } from 'embercom/components/settings/ticket-types/ticket-type-states-picker';
import { isPresent } from '@ember/utils';

interface Args {
  ticketType: TicketType;
  convertInboundEmailsToTicketsSettings?: $TSFixMe;
  newSettings?: boolean;
}

export interface TicketConfigurationFields {
  name: string;
  description: string;
  emoji: any;
  internal: boolean;
  disableAiAssist: boolean;
  category: TicketCategory;
  ticketCustomStateIds: number[];
}

const DEFAULT_CATEGORY = TicketCategory.Request;
const DEFAULT_AI_ASSIST_DISABLED = false;
const DEFAULT_INTERNAL = false;

export default class TicketTypeSettings extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare router: Router;
  @service declare session: Session;
  @service declare ticketStateService: TicketStateService;
  @service declare intercomConfirmService: IntercomConfirmService;

  @trackedReset('args.ticketType') ticketType: TicketType | undefined = this.args.ticketType;
  @trackedReset('args.ticketType') configurations: TicketConfigurationFields = {
    name: this.args.ticketType.name,
    description: this.args.ticketType.description,
    emoji: this.args.ticketType?.emoji,
    internal:
      this.args.ticketType.internal !== undefined
        ? this.args.ticketType.internal
        : DEFAULT_INTERNAL,
    disableAiAssist:
      this.args.ticketType.disableAiAssist !== undefined
        ? this.args.ticketType.disableAiAssist
        : DEFAULT_AI_ASSIST_DISABLED,
    category: this.args.ticketType.category || DEFAULT_CATEGORY,
    ticketCustomStateIds: this.args.ticketType.ticketCustomStateIds || [],
  };
  @tracked isArchiveModalOpen = false;
  @tracked showDuplicateTicketTypeModal = false;
  @tracked dependentObjects: any = null;
  @tracked showUpdateTicketTypeModal = false;
  @tracked hasUnsavedChanges = false;
  @tracked openSectionId?: string = 'details';
  @tracked ticketSystemStates;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.router.on('routeWillChange', this.warnIfUnsavedChangesCallback);
    this.ticketSystemStates = this.ticketStateOptions;
  }

  willDestroy(): void {
    super.willDestroy();

    this.router.off('routeWillChange', this.warnIfUnsavedChangesCallback);
  }

  @action
  async warnIfUnsavedChangesCallback(transition: Transition) {
    if (transition.from?.name !== transition.to.name) {
      if (this.router.currentRouteName !== transition.to.name && this.hasUnsavedChanges) {
        transition.abort();
        let confirmed = await this.intercomConfirmService.confirm({
          title: this.intl.t(
            'settings.ticket-data.ticket-type-configuration.unsaved-changes-modal.label',
          ),
          body: this.intl.t(
            'settings.ticket-data.ticket-type-configuration.unsaved-changes-modal.description',
          ),
          confirmButtonText: this.intl.t(
            'settings.ticket-data.ticket-type-configuration.unsaved-changes-modal.continue',
          ),
          cancelButtonText: this.intl.t(
            'settings.ticket-data.ticket-type-configuration.unsaved-changes-modal.cancel',
          ),
        });
        if (confirmed) {
          this.hasUnsavedChanges = false;
          transition.retry();
        }
      }
    }
  }

  get breadcrumbsRoute() {
    return 'apps.app.settings.helpdesk.tickets';
  }

  get showUserLabelOnStates() {
    let isNotTracker = this.configurations.category !== TicketCategory.Tracker;
    let isShared = !this.configurations.internal;
    return isNotTracker && isShared;
  }

  get defaultCategory() {
    return DEFAULT_CATEGORY;
  }

  get defaultInternal() {
    if (this.configurations.category === TicketCategory.Tracker) {
      return true;
    }

    return DEFAULT_INTERNAL;
  }

  get saveButtonLabel() {
    return this.intl.t('settings.ticket-data.ticket-type-configuration.save-label');
  }

  get isNameEmpty() {
    return this.configurations.name.trim() === '';
  }

  get coveredSystemStates() {
    return this.configurations.ticketCustomStateIds.map((id) => {
      return this.ticketStateService.getTicketCustomStateById(id)?.systemState;
    });
  }

  get systemStatesNotCovered() {
    let missingStates: TicketSystemState[] = [];

    Object.values(TicketSystemState).filter((systemState: TicketSystemState) => {
      let included = this.coveredSystemStates.includes(systemState);
      if (!included) {
        missingStates.push(systemState);
      }
    });
    return missingStates;
  }

  get isCustomStateAssignedToEachSystemState() {
    return Object.values(TicketSystemState).every((systemState: TicketSystemState) => {
      return this.coveredSystemStates.includes(systemState);
    });
  }

  translatedSystemState(state: string) {
    if (state === 'submitted') {
      return this.intl.t(`inbox.ticket-state.${TicketSystemState.Submitted}`);
    } else if (state === 'in_progress') {
      return this.intl.t(`inbox.ticket-state.${TicketSystemState.InProgress}`);
    } else if (state === 'waiting_on_customer') {
      return this.intl.t(`inbox.ticket-state.${TicketSystemState.WaitingOnCustomer}`);
    } else {
      return this.intl.t(`inbox.ticket-state.${TicketSystemState.Resolved}`);
    }
  }

  get systemStatesNotCoveredError() {
    if (this.systemStatesNotCovered.length === 1) {
      let translatedState = this.translatedSystemState(this.systemStatesNotCovered[0]);
      return this.intl.t(
        'settings.ticket-data.ticket-type-configuration.states-errors.missing-one-state',
        { systemStateName: translatedState },
      );
    } else if (this.systemStatesNotCovered.length > 1) {
      return this.intl.t(
        'settings.ticket-data.ticket-type-configuration.states-errors.missing-multiple-states',
      );
    } else {
      return '';
    }
  }

  get isValidTicketType() {
    return !this.isNameEmpty && this.isCustomStateAssignedToEachSystemState;
  }

  get summaryDetails() {
    switch (this.configurations.category) {
      case TicketCategory.Request:
        return {
          categoryIcon: 'ticket',
          category: `${this.intl.t('settings.ticket-data.ticket-type-configuration.category.request')} ${this.intl.t('settings.ticket-data.ticket-type-configuration.category.suffix')}`,
          sharedIcon: 'eye',
          shared: this.intl.t(
            'settings.ticket-data.ticket-type-configuration.sharing-settings.shared',
          ),
          finIcon: 'fin',
          finSettings: this.configurations.disableAiAssist
            ? this.intl.t('settings.ticket-data.ticket-type-configuration.fin-ai.disabled')
            : this.intl.t('settings.ticket-data.ticket-type-configuration.fin-ai.enabled'),
        };
      case TicketCategory.Task:
        return {
          categoryIcon: 'back-office',
          category: `${this.intl.t('settings.ticket-data.ticket-type-configuration.category.task')} ${this.intl.t('settings.ticket-data.ticket-type-configuration.category.suffix')}`,
          sharedIcon: 'eye',
          shared: this.configurations.internal
            ? this.intl.t(
                'settings.ticket-data.ticket-type-configuration.sharing-settings.not-shared',
              )
            : this.intl.t('settings.ticket-data.ticket-type-configuration.sharing-settings.shared'),
          finIcon: 'fin',
          finSettings: this.configurations.disableAiAssist
            ? this.intl.t('settings.ticket-data.ticket-type-configuration.fin-ai.disabled')
            : this.intl.t('settings.ticket-data.ticket-type-configuration.fin-ai.enabled'),
        };
      case TicketCategory.Tracker:
        return {
          categoryIcon: 'tracker',
          category: `${this.intl.t('settings.ticket-data.ticket-type-configuration.category.tracker')} ${this.intl.t('settings.ticket-data.ticket-type-configuration.category.suffix')}`,
          sharedIcon: 'not-seen-path',
          shared: this.intl.t(
            'settings.ticket-data.ticket-type-configuration.sharing-settings.not-shared',
          ),
          finIcon: 'fin',
          finSettings: this.configurations.disableAiAssist
            ? this.intl.t('settings.ticket-data.ticket-type-configuration.fin-ai.disabled')
            : this.intl.t('settings.ticket-data.ticket-type-configuration.fin-ai.enabled'),
        };
      default:
        return { categoryIcon: 'ticket', category: 'undefined' };
    }
  }

  get ticketStateOptions() {
    return [
      {
        title: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.system-states.submitted',
        ),
        systemState: TicketSystemState.Submitted,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.Submitted),
      },
      {
        title: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.system-states.in_progress',
        ),
        systemState: TicketSystemState.InProgress,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.InProgress),
      },
      {
        title: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.system-states.waiting_on_customer',
        ),
        systemState: TicketSystemState.WaitingOnCustomer,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.WaitingOnCustomer),
      },
      {
        title: this.intl.t('settings.ticket-data.ticket-type-configuration.system-states.resolved'),
        systemState: TicketSystemState.Resolved,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.Resolved),
      },
    ];
  }

  get customStatesHash() {
    let ticketCustomStateIds = this.configurations.ticketCustomStateIds;
    let hash = {
      [TicketSystemState.Submitted]: [],
      [TicketSystemState.InProgress]: [],
      [TicketSystemState.WaitingOnCustomer]: [],
      [TicketSystemState.Resolved]: [],
    };
    if (ticketCustomStateIds) {
      return ticketCustomStateIds
        .map((elem) => this.ticketStateService.getTicketCustomStateById(elem))
        .reduce((acc, cur) => {
          if (!cur) {
            return acc;
          }
          acc[cur.systemState] = acc[cur.systemState] || [];
          acc[cur.systemState]?.push(cur);
          return acc;
        }, hash as TicketCustomStateHash);
    }

    return hash;
  }

  get attributes() {
    let liveConversationAttributeDescriptors = this.args.ticketType.descriptors
      .rejectBy('archived')
      .reject(this.hasReferenceToUndefinedOrSystemDefinedObjectType);

    return liveConversationAttributeDescriptors.sortBy('order');
  }

  hasReferenceToUndefinedOrSystemDefinedObjectType(
    attribute: { reference: unknown; referencedObjectType: { isSystemDefined: any } | undefined },
    _index: any,
    _array: any,
  ) {
    return (
      isPresent(attribute.reference) &&
      (attribute.referencedObjectType === undefined ||
        attribute.referencedObjectType.isSystemDefined)
    );
  }

  @action
  getSelectedCustomStatesForSystemState(
    systemState: TicketSystemState,
  ): TicketCustomState[] | undefined {
    return this.customStatesHash[systemState];
  }

  @action
  updateConfigurations(values: Partial<TicketConfigurationFields>) {
    this.hasUnsavedChanges = true;
    this.configurations = {
      ...this.configurations,
      ...values,
    };
  }

  @action selectEmoji(emojiIdentifier: string) {
    let unicodeEmoji = intermoji.unicodeFromIdentifier(emojiIdentifier);
    let twemojiCompatibleEmoji = intermoji.getSupportedTwemoji(unicodeEmoji);
    this.updateConfigurations({
      emoji: twemojiCompatibleEmoji,
    });
  }

  @action updateName(name: string) {
    this.updateConfigurations({
      name,
    });
  }

  @action
  onAddCustomState(customStateId: number) {
    let temp = this.configurations.ticketCustomStateIds;
    this.updateConfigurations({
      ticketCustomStateIds: [...temp, customStateId],
    });
  }

  @action
  onRemoveCustomState(customStateId: number) {
    let temp = this.configurations.ticketCustomStateIds;
    this.updateConfigurations({
      ticketCustomStateIds: temp.filter((id) => id !== customStateId),
    });
  }

  @action
  hideDependentObjects() {
    this.dependentObjects = null;
  }

  get confirmationChanges() {
    let ticketCustomStateIds = this.args.ticketType?.ticketCustomStateIds || [];

    let customStateRemoved = ticketCustomStateIds.some(
      (id: number) => !this.configurations.ticketCustomStateIds.includes(id),
    );

    return {
      ticketCustomStates: customStateRemoved,
      ticketCategory: this.args.ticketType?.category !== this.configurations.category,
    };
  }

  get shouldShowConfirmationModal() {
    return this.confirmationChanges.ticketCategory || this.confirmationChanges.ticketCustomStates;
  }

  get confirmationTitle(): string {
    let { ticketCustomStates, ticketCategory } = this.confirmationChanges;

    if (ticketCustomStates && ticketCategory) {
      return this.intl.t(
        'settings.ticket-data.ticket-type-configuration.confirmation-modal.title.default',
      );
    }

    if (ticketCustomStates) {
      return this.intl.t(
        'settings.ticket-data.ticket-type-configuration.confirmation-modal.title.remove-ticket-state',
      );
    }

    if (ticketCategory) {
      return this.intl.t(
        'settings.ticket-data.ticket-type-configuration.confirmation-modal.title.update-ticket-category',
      );
    }

    return this.intl.t(
      'settings.ticket-data.ticket-type-configuration.confirmation-modal.title.default',
    );
  }

  get confirmationMessage(): string {
    let { ticketCustomStates, ticketCategory } = this.confirmationChanges;

    if (ticketCustomStates && ticketCategory) {
      return this.intl.t(
        'settings.ticket-data.ticket-type-configuration.confirmation-modal.message.default',
      );
    }

    if (ticketCustomStates) {
      return this.intl.t(
        'settings.ticket-data.ticket-type-configuration.confirmation-modal.message.remove-ticket-state',
      );
    }

    if (ticketCategory) {
      return this.intl.t(
        'settings.ticket-data.ticket-type-configuration.confirmation-modal.message.update-ticket-category',
      );
    }

    return this.intl.t(
      'settings.ticket-data.ticket-type-configuration.confirmation-modal.message.default',
    );
  }

  @action
  confirmUpdateTicketType() {
    if (!this.shouldShowConfirmationModal) {
      return taskFor(this.updateTicketType).perform();
    }

    this.showUpdateTicketTypeModal = true;
    return;
  }

  @action onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @task({ drop: true })
  *updateTicketType() {
    if (!this.ticketType) {
      return;
    }

    try {
      this.showUpdateTicketTypeModal = false;

      this.ticketType.setProperties({
        ...this.configurations,
      });

      yield this.ticketType.save();
      this.ticketStateService.updateTicketType(this.ticketType.id, { ...this.configurations });
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.ticket-data.ticket-type-modal.successfully-updated'),
      );
      this.hasUnsavedChanges = false;
    } catch (error) {
      this.showUpdateTicketTypeModal = false;
      this.ticketType.rollbackAttributes();
      if (error.jqXHR.status === 409) {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-data.ticket-type-modal.ticket-type-name-duplicate'),
        );
      } else if (error.jqXHR.status === 412) {
        let failedTypes = error.jqXHR.responseJSON.failed_types;
        let dependentObjects = error.jqXHR.responseJSON.dependencies;

        this.dependentObjects = {
          customBots: failedTypes.includes('custom_bots')
            ? dependentObjects.dependent_custom_bots
            : [],
          ticketLinks: failedTypes.includes('ticket_links')
            ? dependentObjects.dependent_ticket_links
            : [],
        };
      } else if (error.jqXHR?.responseJSON?.message) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-data.ticket-type-modal.unknown-error'),
        );
      }
    }
  }

  _randomEmoji() {
    return shuffle(AVAILABLE_EMOJI)[0];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketTypes::TicketTypeSettings': typeof TicketTypeSettings;
    'settings/ticket-types/ticket-type-settings': typeof TicketTypeSettings;
  }
}
