/* import __COLOCATED_TEMPLATE__ from './migrate-from-zendesk.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { get, post } from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import { type SectionConfig } from './modal/dialog';

interface Args {
  isFromNewSettings?: boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export enum ZendeskObjectTypes {
  Organization = 'organization',
  Customer = 'customer',
  Ticket = 'ticket',
}

export default class MigrateFromZendesk extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare redirectService: any;
  @service declare intercomEventService: $TSFixMe;

  @tracked isAppPackageInstalled = false;
  @tracked isAccountCreated = false;
  @tracked isTokenValid = false;
  @tracked subdomain = '';
  @tracked showDisconnectFromZendesk = false;

  @tracked selectedView = 'overview';
  @tracked showMigrationConfigModal = false;

  integrationCode = 'migrate-from-zendesk';

  sectionConfigs: SectionConfig[] = [
    {
      objectType: 'company',
      icon: 'company',
      dependencies: [],
      active: false,
      frozen: false,
      disabled: false,
      comingSoon: false,
      steps: [
        {
          object: 'company',
          widget: 'map-attributes',
        },
      ],
    },
    {
      objectType: 'contact',
      icon: 'person',
      dependencies: ['company'],
      active: false,
      frozen: false,
      disabled: false,
      comingSoon: false,
      steps: [
        {
          object: 'contact',
          widget: 'map-attributes',
        },
      ],
    },
    {
      objectType: 'ticket',
      icon: 'ticket',
      dependencies: ['company', 'contact'],
      active: false,
      frozen: false,
      disabled: false,
      comingSoon: false,
      steps: [
        {
          object: 'team',
          widget: 'match-objects',
        },
        {
          object: 'admin',
          widget: 'match-objects',
        },
        {
          object: 'ticket',
          widget: 'map-ticket-attributes',
        },
        {
          object: 'public-ticket',
          widget: 'map-public-ticket-attributes',
        },
        {
          object: 'content',
          widget: 'content-settings',
        },
      ],
    },
  ];

  constructor(owner: unknown, args: any) {
    super(owner, args);

    taskFor(this.loadAppPackage).perform();
    taskFor(this.loadIntegrationStatus).perform();
  }

  get isLoading() {
    return taskFor(this.loadAppPackage).isRunning || taskFor(this.loadIntegrationStatus).isRunning;
  }

  get isInstalled() {
    return this.isAppPackageInstalled && this.isAccountCreated;
  }

  get isConnected() {
    return this.isInstalled && this.isTokenValid;
  }

  @action showFeedbackSurvey() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'show_feedback',
      migration_section: 'overview',
    });
    window.Intercom('startSurvey', 40871747);
  }

  @action changeView(view: string) {
    this.selectedView = view;
  }

  @action async connect() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'connect_account',
    });
    let url = await taskFor(this.generateRedirectUrl).perform();
    this.redirectService.redirect(url);
  }

  @action async disconnect() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'disconnect_account',
    });
    await taskFor(this.uninstallAppPackage).perform();
    this.showDisconnectFromZendesk = false;
  }

  @action onCloseMappingsConfigModal(actionTaken: boolean) {
    this.showMigrationConfigModal = false;
    if (actionTaken) {
      this.selectedView = 'overview';
    }
  }

  @dropTask *generateRedirectUrl(): TaskGenerator<string> {
    let requestData: any = {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
      app_package_code: 'migrate-from-zendesk',
      additional_params: { subdomain: this.subdomain },
    };
    let url;
    try {
      let response = yield post('/ember/appstore/redirect_url', requestData);
      url = response.url;
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
    return url;
  }

  @dropTask *loadAppPackage(): TaskGenerator<void> {
    let response = yield get(
      `/ember/appstore/app_packages/migrate-from-zendesk?app_id=${this.session.workspace.id}`,
    );
    this.isAppPackageInstalled = response.is_installed as boolean;
  }

  @dropTask *uninstallAppPackage(): TaskGenerator<void> {
    yield post('/ember/migrate_from_crm_integrations/uninstall', {
      app_id: this.appService.app.id,
      integration_code: 'migrate-from-zendesk',
    });
    this.isAccountCreated = false;
    this.isTokenValid = false;
    this.subdomain = '';
  }

  @dropTask *loadIntegrationStatus(): TaskGenerator<void> {
    try {
      let response = yield get(
        `/ember/migrate_from_zendesk_integrations/integration_status?app_id=${this.session.workspace.id}`,
      );
      this.isAccountCreated = true;
      this.isTokenValid = response.token_valid as boolean;
      this.subdomain = response.subdomain as string;
    } catch (e) {
      if (e.jqXHR.status === 404) {
        this.isAccountCreated = false;
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::MigrateFromZendesk': typeof MigrateFromZendesk;
  }
}
