/* import __COLOCATED_TEMPLATE__ from './consent-type.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { consentType } from 'embercom/models/outbound-subscriptions/subscription-type';

export default class Editor extends Component {
  get consentType() {
    return consentType;
  }

  @action
  updateConsentType(value) {
    this.args.subscriptionType.consentType = value;
  }
}
