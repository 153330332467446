/* import __COLOCATED_TEMPLATE__ from './conversations-top-urls.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { readOnly } from '@ember/object/computed';
import { hash } from 'rsvp';
import { topValuesSignalToContextSignal } from 'embercom/lib/reporting/signal-transformations';

const TOP_URLS_INBOUND = {
  valueUnit: 'conversations',
  signalConfig: {
    name: 'default_query',
    document_type: 'conversation',
    range_field: 'first_user_conversation_part_created_at',
    aggregation_type: 'terms',
    aggregation_field: 'message_url',
    keys_are_dates: false,
  },
  aggregations: [],
  filters: {
    conversation_started_by_user: [true],
  },
};

const ALL_INBOUND = {
  valueUnit: 'conversations',
  signalConfig: {
    name: 'default_query',
    document_type: 'conversation',
    range_field: 'first_user_conversation_part_created_at',
    keys_are_dates: false,
    aggregation_type: 'value_count',
    aggregation_field: 'conversation_id',
  },
  aggregations: [],
  filters: {
    conversation_started_by_user: [true],
  },
};

export default Component.extend({
  reportingService: service(),
  appService: service(),
  app: readOnly('appService.app'),

  topListCount: 5,

  didReceiveAttrs() {
    this._super(...arguments);
    this.fetchSignals.perform();
  },

  fetchSignals: task(function* () {
    let signals = yield hash({
      topUrls: this.reportingService.fetchStackedSignal(
        {
          debugName: 'top_urls_conversations',
          ...TOP_URLS_INBOUND,
          range: this.range,
        },
        (signal) => signal.transform(topValuesSignalToContextSignal),
      ),
      allInbound: this.reportingService.fetchStackedSignal({
        debugName: 'all_inbound_conversations_count',
        ...ALL_INBOUND,
        range: this.range,
      }),
    });

    let topUrlsContext = signals.topUrls.context
      .slice(0, this.topListCount)
      .map((context) => ({ ...context, label: context.key }));

    let otherRow = {
      key: 'others',
      label: 'All others',
      value: signals.allInbound.originalValue - topUrlsContext.reduce((sum, c) => sum + c.value, 0),
      previousValue:
        signals.allInbound.previousValue -
        topUrlsContext.reduce((sum, c) => sum + (c.previousValue || 0), 0),
    };

    topUrlsContext = [...topUrlsContext, ...(otherRow.value > 0 ? [otherRow] : [])];

    this.set('data', topUrlsContext);
  }).restartable(),
});
