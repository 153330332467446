/* import __COLOCATED_TEMPLATE__ from './view-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import ViewConfig from 'embercom/lib/reporting/flexible/view-config';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { COUNT } from 'embercom/lib/reporting/flexible/constants';
import { articleViewsDataConfig } from 'embercom/lib/reporting/flexible/articles';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import { inject as service } from '@ember/service';

const seriesName = 'help_center_views';

export default class ArticlesViewCounter extends Component {
  @service intl;

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        value: 'event.created_at',
        text: 'Time',
        type: 'temporal',
        isComparison: true,
        createTitleTooltipText: () => {
          return this.intl.t('articles.reports.total-view.tooltip');
        },
      },
    };
    viewConfig.counter = {
      [seriesName]: {
        improvementDirection: improvementDirections.INCREASING,
      },
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      series: [
        {
          name: seriesName,
          type: COUNT,
          data: { property: 'user.id' },
          ...articleViewsDataConfig(),
        },
      ],
    };
  }
}
