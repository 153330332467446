/* import __COLOCATED_TEMPLATE__ from './last-active-filter.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

export default class SettingsFiltersLastActiveFilterComponent extends Component {
  @service intercomEventService;
  @service intl;

  @tracked inactiveForDays;
  @tracked selectedDate;

  get filterText() {
    switch (this.args.filterType) {
      case 'for':
        return this.intl.t('settings.filters.last-active-filter.inactive-for', {
          emptyDays: isEmpty(this.inactiveForDays),
          days: this.inactiveForDays,
          htmlSafe: true,
        });
      case 'since':
        return this.intl.t('settings.filters.last-active-filter.inactive-since', {
          emptyDate: isEmpty(this.selectedDate),
          date: isEmpty(this.selectedDate)
            ? ''
            : this.intl.formatDate(this.selectedDate, {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              }),
          htmlSafe: true,
        });
    }
  }

  get _inactiveSinceByDays() {
    switch (this.args.filterType) {
      case 'for':
        return parseInt(this.inactiveForDays || 0, 10);
      case 'since':
        return moment.duration(moment().diff(this.selectedDate)).days();
    }
  }

  @action
  onClose() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered',
      object: 'settings',
      lastActiveFilter: true,
      lastActiveFilterType: this.args.filterType,
      inactiveSinceByDays: this._inactiveSinceByDays,
    });

    switch (this.args.filterType) {
      case 'for':
        if (this.inactiveForDays && parseInt(this.inactiveForDays, 10) > 0) {
          return this.args.onLastActiveFilterChange(
            moment().startOf('day').subtract(this.inactiveForDays, 'day').unix(),
          );
        } else {
          return this.args.onLastActiveFilterChange();
        }
      case 'since':
        return this.args.onLastActiveFilterChange(this.selectedDate.unix());
    }
  }
}
