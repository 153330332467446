/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class Header extends Component {
  @tracked showMore = false;
  @tracked hiddenEmailCount = 0;
  @tracked windowWidth = 0;

  get shouldShowMoreOption() {
    return this.hiddenEmailCount > 0 && !this.showMore;
  }

  isEmailHidden(emailElement, emailContainer) {
    return emailElement.offsetTop + emailElement.offsetHeight >= emailContainer.offsetHeight;
  }

  @action handleWindowResize(dimensions) {
    this.windowWidth = dimensions.width;
  }

  @action updateHiddenEmailCount(emailContainer) {
    let emailElements = Array.from(emailContainer.children);

    this.hiddenEmailCount = emailElements.reduce(
      (count, emailElement) =>
        this.isEmailHidden(emailElement, emailContainer) ? count + 1 : count,
      0,
    );
  }
}
