/* import __COLOCATED_TEMPLATE__ from './category-card-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    icon: string;
    text: string;
  };
}

const CategoryCardAttribute = templateOnlyComponent<Signature>();
export default CategoryCardAttribute;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketData::TicketsOnboarding::CategoryCardAttribute': typeof CategoryCardAttribute;
  }
}
