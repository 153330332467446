/* import __COLOCATED_TEMPLATE__ from './message-table.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';

import type IntlService from 'embercom/services/intl';

import {
  SeriesAnalyticActions,
  SeriesAnalyticObjects,
  SeriesAnalyticTracker,
} from 'embercom/lib/series/analytic-tracker';
import { contentTableStats } from 'embercom/components/series/editor/aggregated-stats/stat-tile-config/stats-per-content-type';
import { statisticTypes } from 'embercom/models/data/outbound/constants';

function statValuePath(stat: any) {
  let valuePathText = stat.statisticKey.toString();
  if (stat.subaggregationKey) {
    valuePathText = `${valuePathText}-${stat.subaggregationKey}`;
  }
  if (stat.statisticTypeKey === statisticTypes.integer) {
    valuePathText = `${valuePathText}-${stat.statisticTypeKey}`;
  }
  return valuePathText;
}

interface SortState {
  direction: string;
  valuePath: string;
}

interface Args {
  exportFilename: string;
  selectedObjectType: number; //app/models/data/matching-system/matching-constants.ts
  data: any[];
}

export default class MessageTable extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service intercomEventService: any;
  @service seriesEditorService: any; //app/services/series-editor-service.js

  DIRECTION_ASC = 'asc';
  DIRECTION_DESC = 'desc';

  id: string;
  seriesAnalyticTracker: SeriesAnalyticTracker;

  @tracked sortState: SortState | undefined;
  @tracked sortedData: any[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.id = guidFor(this);
    this.sortedData = this.args.data;
    this.seriesAnalyticTracker = new SeriesAnalyticTracker({
      intercomEventService: this.intercomEventService,
      seriesEditorService: this.seriesEditorService,
    });
  }

  get app() {
    return this.appService.app;
  }

  get selectedObjectType() {
    return this.args.selectedObjectType;
  }

  get data() {
    return this.sortedData;
  }

  set data(data: any[]) {
    this.sortedData = data;
  }

  @action trackAnalyticView() {
    this.seriesAnalyticTracker.track({
      action: SeriesAnalyticActions.viewed,
      object: SeriesAnalyticObjects.overviewReport,
    });
  }

  @action trackDownloadCSV() {
    this.seriesAnalyticTracker.track({
      action: SeriesAnalyticActions.download,
      object: SeriesAnalyticObjects.overviewReport,
    });
  }

  get tableObserver() {
    return {
      onIntersection: () => {
        this.trackAnalyticView();
      },
      options: {
        root: null, //null compares it to the whole window
        rootMargin: '50px', //how far in from the top before intersection is calculated
        threshold: 0.2, // how much of the table must be in view before intersection is true
      },
    };
  }

  get defaultColumns() {
    return [
      {
        valuePath: 'title',
        label: this.intl.t('outbound.series.message-stat-columns.default-columns.message'),
        component: 'series/editor/overview-report/title-cell',
      },
      {
        valuePath: 'version',
        minWidth: '130px',
      },
      {
        valuePath: 'sender',
        label: this.intl.t('outbound.series.message-stat-columns.default-columns.sender'),
      },
    ];
  }

  get columns() {
    return this.defaultColumns.concat(this.statColumnHeaders);
  }

  get statColumnHeaders() {
    return this.statisticColumns;
  }

  get statisticColumns() {
    return this.contentObjectStatistics.map((stat: any) => {
      let statColumn = {
        valuePath: statValuePath(stat),
        label: this.intl.t(stat.header),
        key: stat.statisticKey,
        isSortable: true,
        failureCategory: null,
        statisticTypeKey: stat.statisticTypeKey,
      };
      if (stat.subaggregationKey) {
        statColumn.failureCategory = stat.subaggregationKey;
      }
      return statColumn;
    });
  }

  get contentObjectStatistics() {
    return contentTableStats[this.selectedObjectType];
  }

  get useSingleValueStatisticCell() {
    return true;
  }

  @action
  onSortUpdate(valuePath: string) {
    let currentDirection = this.sortState?.direction;
    let currentValuePath = this.sortState?.valuePath;
    let newDirection =
      isEmpty(currentDirection) ||
      currentDirection === this.DIRECTION_ASC ||
      valuePath !== currentValuePath
        ? this.DIRECTION_DESC
        : this.DIRECTION_ASC;

    this.sortState = { direction: newDirection, valuePath };
    this.data = this._sortByValue(parseInt(valuePath, 10), this.sortState.direction);
    this.intercomEventService.trackAnalyticsEvent({
      name: 'overview_list_sort_update',
      object: 'series',
      sort_value: valuePath,
      sort_direction: newDirection,
      action: 'sort',
    });
  }

  _sortByValue(statistic: number, direction: string) {
    let sortedData = this.data.sort(function (wrapper_a, wrapper_b) {
      let stat_a;
      let stat_b;

      let max_stat = (contents_array: any[]) => {
        if (contents_array.length <= 1) {
          return contents_array.firstObject.stats.find((stat: any) => stat.key === statistic).value;
        }
        return contents_array.reduce((a, b) =>
          a.stats.find((stat: any) => stat.key === statistic).value >=
          b.stats.find((stat: any) => stat.key === statistic).value
            ? a.stats.find((stat: any) => stat.key === statistic).value
            : b.stats.find((stat: any) => stat.key === statistic).value,
        );
      };

      let min_stat = (contents_array: any[]) => {
        if (contents_array.length <= 1) {
          return contents_array.firstObject.stats.find((stat: any) => stat.key === statistic).value;
        }
        return contents_array.reduce((a, b) =>
          a.stats.find((stat: any) => stat.key === statistic).value <=
          b.stats.find((stat: any) => stat.key === statistic).value
            ? a.stats.find((stat: any) => stat.key === statistic).value
            : b.stats.find((stat: any) => stat.key === statistic).value,
        );
      };

      let contents_a = wrapper_a.serialize().contents;
      stat_a = direction === 'asc' ? min_stat(contents_a) : max_stat(contents_a);

      let contents_b = wrapper_b.serialize().contents;
      stat_b = direction === 'asc' ? min_stat(contents_b) : max_stat(contents_b);

      return direction === 'asc' ? stat_a - stat_b : stat_b - stat_a;
    });
    return sortedData;
  }

  @action clickContent(nodeId: number) {
    let node = this.seriesEditorService.series.getNodeForId(`${nodeId}`);
    this.seriesEditorService.registerActiveSeriesNode(node);
    this.seriesAnalyticTracker.track({
      action: SeriesAnalyticActions.clicked,
      object: SeriesAnalyticObjects.messageOpen,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Series::Editor::OverviewReport::MessageTable': typeof MessageTable;
    'series/editor/overview-report/message-table': typeof MessageTable;
  }
}
