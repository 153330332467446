/* import __COLOCATED_TEMPLATE__ from './fin-ai-copilot-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import type Session from 'embercom/services/session';
import type CopilotUsageService from 'embercom/services/copilot-usage-service';
import type Admin from 'embercom/models/admin';

interface FinAiCopilotBannerArgs {}

export default class FinAiCopilotBanner extends Component<FinAiCopilotBannerArgs> {
  @service declare appService: any;
  @service declare session: Session;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare copilotUsageService: CopilotUsageService;

  @action
  routeToInboxAiSettings() {
    if (this.session.workspace.isFeatureEnabled('team-activation-channels-new-ia-settings-hub')) {
      this.router.transitionTo(
        'apps.app.settings.ai-automation.helpdesk-ai',
        this.appService.app.id,
      );
    } else {
      this.router.transitionTo(
        'apps.app.settings.ai-automation.helpdesk-ai',
        this.appService.app.id,
      );
    }
  }

  @action
  routeToTeammateSettings() {
    if (this.session.workspace.isFeatureEnabled('team-activation-channels-new-ia-settings-hub')) {
      this.router.transitionTo(
        'apps.app.settings.workspace.teammates.index',
        this.appService.app.id,
      );
    } else {
      this.router.transitionTo('apps.app.settings.workspace.teammates', this.appService.app.id);
    }
  }

  get copilotEnabled() {
    return this.appService.app.hasCopilotEnabled;
  }

  get humanTeammates() {
    return this.appService.app.humanAdmins;
  }

  get numberOfHumanTeammates() {
    return this.humanTeammates.length;
  }

  get numberOfTeammatesHaveAccess() {
    return this.appService.app.adminsWithCopilotAccess.length;
  }

  get numberOfTeammatesReachedLimit() {
    return this.humanTeammates.filter((admin: Admin) => {
      return this.copilotUsageService.adminIsAtLimit(admin);
    }).length;
  }

  get seatAccessMessage() {
    // All teammates have access
    if (
      this.numberOfTeammatesHaveAccess > 0 &&
      this.numberOfTeammatesHaveAccess === this.numberOfHumanTeammates
    ) {
      return this.intl.t('reporting.copilot.banner.all-teammates-have-access');
      // X teammates have access
    } else if (this.numberOfTeammatesHaveAccess > 0) {
      let numberOfTeammatesHaveAccess = `${this.numberOfTeammatesHaveAccess}/${this.numberOfHumanTeammates}`;
      let message =
        this.numberOfTeammatesHaveAccess === 1
          ? 'reporting.copilot.banner.teammate-has-access'
          : 'reporting.copilot.banner.teammates-have-access';
      return this.intl.t(message, { numberOfTeammatesHaveAccess });
      // No teammates have access
    } else {
      return;
    }
  }

  get seatLimitMessage() {
    // All teammates have reached their limit
    if (
      this.numberOfTeammatesReachedLimit > 0 &&
      this.numberOfTeammatesReachedLimit === this.numberOfHumanTeammates
    ) {
      return this.intl.t('reporting.copilot.banner.teammates-reached-limit-all');
      // X teammates have reached their limit
    } else if (this.numberOfTeammatesReachedLimit > 0) {
      return this.intl.t('reporting.copilot.banner.teammates-reached-limit', {
        numberOfTeammatesReachedLimit: this.numberOfTeammatesReachedLimit,
      });
      // No teammates have reached their limit
    } else {
      return;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::FinAiCopilotBanner': typeof FinAiCopilotBanner;
    'reporting/fin-ai-copilot-banner': typeof FinAiCopilotBanner;
  }
}
