/* import __COLOCATED_TEMPLATE__ from './add-tag-to-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { readOnly, map } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import { captureException } from 'embercom/lib/sentry';

export default Component.extend({
  tagName: '',

  appService: service(),
  app: readOnly('appService.app'),

  selectableTags: computed('app.tags.[]', 'allRuleActions.@each.actionData', function () {
    let allOtherTagActions = this.allRuleActions.filter(
      (action) =>
        action !== this.action &&
        action.type === 'add-tag-to-conversation' &&
        isPresent(action.get('actionData.tag_id')),
    );
    let allUsedTagsInActions = allOtherTagActions.map((action) => action.actionData.tag_id);
    return this.app.tags.reject((tag) => {
      return (
        tag.id !== this.action.actionData.tag_id &&
        (tag.archived || allUsedTagsInActions.includes(tag.id))
      );
    });
  }),

  actionTagName: computed('action.actionData.tag_id', 'app.tags.[]', function () {
    let tagId = this.get('action.actionData.tag_id');

    if (!tagId) {
      return '';
    }

    let tag = this.app.tags.findBy('id', tagId);

    if (!tag) {
      captureException(new Error('Unable to find corresponding tag for tag conversation action'));
      return '';
    }
    return tag.name;
  }),

  tagItems: map('selectableTags.[]', function (tag) {
    return {
      text: tag.name,
      value: tag.id,
    };
  }),

  updateTag: action(function (tag) {
    this.set('action.actionData', { tag_id: tag });

    let onChange = this.onChange;
    if (onChange) {
      onChange();
    }
  }),

  init() {
    this._super(...arguments);
    this.set('noTags', [
      {
        value: null,
        isDisabled: true,
        component: 'rules/actions/add-tag-to-conversation-empty-item',
      },
    ]);
  },
});
