/* import __COLOCATED_TEMPLATE__ from './tag-user.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Tag from 'embercom/models/tag';
import { captureException } from 'embercom/lib/sentry';

interface Args {
  action: {
    actionData?: {
      tag_id?: string;
      tag?: string;
    };
    info?: {
      actionDescription?: string;
    };
    justAdded?: boolean;
    isInvalid?: boolean;
  };
  onChange?: () => void;
  onDelete?: () => void;
  isDisabled?: boolean;
  Blocks: {
    default: [{ opener: unknown; actionTagName: string }];
  };
}

export default class TagUser extends Component<Args> {
  @service declare appService: $TSFixMe;
  tags: Tag[] = [];

  constructor(owner: unknown, args: ArgsOf<TagUser>) {
    super(owner, args);

    this.tags = this.app.tags
      .filter((tag: Tag) => !tag.archived)
      .map((tag: Tag) => {
        return {
          name: tag.name,
          id: tag.id,
        };
      });
  }

  get app() {
    return this.appService.app;
  }
  get tagValueAccessor() {
    return this.shouldUseTagId ? 'id' : 'name';
  }

  get noTags() {
    return [
      {
        value: null,
        isDisabled: true,
        component: 'rules/actions/add-tag-to-conversation-empty-item',
      },
    ];
  }

  get shouldUseTagId() {
    let actionData = this.args.action?.actionData;
    if (!actionData) {
      return true;
    }

    let { tag, tag_id: tagId } = actionData;

    // if both tag id and tagName not present use tagId, if tagId exists use tagId, if tagName exists use tagName
    if (tagId) {
      return true;
    }

    return !tag;
  }

  get tagItems() {
    return this.tags.map((tag) => {
      return {
        text: tag.name,
        value: tag[this.tagValueAccessor],
      };
    });
  }

  get actionTagName() {
    let actionData = this.args.action?.actionData;

    if (!actionData) {
      return '';
    }

    let { tag: tagName, tag_id: tagId } = actionData;

    if (this.shouldUseTagId) {
      return this.getTagNameFromTagId(tagId);
    }

    //may be tag is renamed and archived
    if (tagName && !this.tags.findBy('name', tagName)) {
      captureException(
        new Error(`Unable to find corresponding tag for tag user action and tag is ${tagName}`),
      );
      return '';
    }

    return tagName || '';
  }

  private getTagNameFromTagId(tagId: string | undefined) {
    let tagObject = this.tags.findBy('id', tagId);

    if (!tagObject) {
      captureException(
        new Error(`Unable to find corresponding tag for tag user action and tagId is ${tagId}`),
      );
      return '';
    }

    return tagObject.name;
  }

  get selectedValue() {
    let actionData = this.args.action?.actionData;

    if (!actionData) {
      return undefined;
    }

    let { tag: tagName, tag_id: tagId } = actionData;

    if (this.shouldUseTagId) {
      let tag = this.tags.findBy('id', tagId);
      return tag && !tag.archived ? tag.id : undefined;
    }

    if (tagName) {
      let tag = this.tags.findBy('name', tagName);
      return tag && !tag.archived ? tag.name : undefined;
    }

    return;
  }

  @action
  updateTag(value: string) {
    this.args.onChange?.();
    let tagId: string | undefined = value;

    if (!this.shouldUseTagId) {
      tagId = this.tags.findBy('name', value)?.id;
      if (!tagId) {
        captureException(
          new Error(
            `Unable to find corresponding TagId for tag user action and tag name is ${value}`,
          ),
        );
      }
    }

    let tagName = this.getTagNameFromTagId(tagId);
    this.args.action.actionData = { tag_id: tagId, tag: tagName };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Rules::Actions::TagUser': typeof TagUser;
    'rules/actions/tag-user': typeof TagUser;
  }
}
