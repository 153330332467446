/* import __COLOCATED_TEMPLATE__ from './stacked-bar-chart-summary.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { CONVERSATION_RATING_IDS } from 'predicates/models/data/conversation-ratings';

function calculateAggregatePercentage({ signal, usePreviousContext = false }) {
  let amazing = signal.roundedPercentForBucketIndex(
    CONVERSATION_RATING_IDS.AMAZING,
    usePreviousContext,
  );
  let good = signal.roundedPercentForBucketIndex(CONVERSATION_RATING_IDS.GREAT, usePreviousContext);
  return amazing + good;
}

function calculateAggregateCount({ signal, usePreviousContext = false }) {
  let amazing = signal.sumForBucketIndex(CONVERSATION_RATING_IDS.AMAZING, usePreviousContext);
  let good = signal.sumForBucketIndex(CONVERSATION_RATING_IDS.GREAT, usePreviousContext);
  return amazing + good;
}

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  attributeBindings: ['data-test-reporting-stacked-bar-chart-summary'],
  'data-test-reporting-stacked-bar-chart-summary': true,

  totalCount: computed('signal', 'aggregate', function () {
    return this.aggregate ? this.signal.current.total : this.signal.sumOfAllBuckets(false);
  }),

  previousTotalCount: computed('signal', 'aggregate', function () {
    return this.aggregate ? this.signal.previous.total : this.signal.sumOfAllBuckets(true);
  }),

  amazingOrGoodCount: computed('signal', 'aggregate', function () {
    if (this.aggregate) {
      return (
        (this.signal.current.totalsPerKey[CONVERSATION_RATING_IDS.AMAZING] || 0) +
        (this.signal.current.totalsPerKey[CONVERSATION_RATING_IDS.GREAT] || 0)
      );
    }
    return calculateAggregateCount({
      signal: this.signal,
    });
  }),

  amazingOrGoodPercentage: computed('signal', 'aggregate', function () {
    if (this.aggregate) {
      return (
        (this.signal.current.percentagesByKey[CONVERSATION_RATING_IDS.AMAZING] || 0) +
        (this.signal.current.percentagesByKey[CONVERSATION_RATING_IDS.GREAT] || 0)
      );
    }

    return calculateAggregatePercentage({
      signal: this.signal,
    });
  }),

  previousAmazingOrGoodPercentage: computed('signal', 'aggregate', function () {
    if (this.aggregate) {
      return (
        (this.signal.previous.percentagesByKey[CONVERSATION_RATING_IDS.AMAZING] || 0) +
        (this.signal.previous.percentagesByKey[CONVERSATION_RATING_IDS.GREAT] || 0)
      );
    }

    return calculateAggregatePercentage({
      signal: this.signal,
      usePreviousContext: true,
    });
  }),
});
