/* import __COLOCATED_TEMPLATE__ from './total-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { DIRECT_TO_HUMAN, SELF_SERVE_FAILED } from 'embercom/lib/reporting/csf-queries';
import { getTimeConfig } from 'embercom/lib/reporting/csf-query-helper';
import { buildDataConfigWithComparisons } from '../../../../lib/reporting/comparison-data-config';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';

const SELF_SERVE_FAILED_HUMAN_SUPPORT = 'self-serve-failed-human-support';
const DIRECT_TO_HUMAN_SUPPORT = 'direct-to-human-support';

export default class TotalCounterComponent extends Component {
  get viewConfig() {
    return {
      formatUnit: { unit: units.value, commas: true },
      counter: {
        [SELF_SERVE_FAILED_HUMAN_SUPPORT]: {
          improvementDirection: improvementDirections.DECREASING,
        },
        [DIRECT_TO_HUMAN_SUPPORT]: {
          improvementDirection: improvementDirections.DECREASING,
        },
      },
    };
  }

  get dataConfig() {
    return buildDataConfigWithComparisons(
      {
        series: [
          {
            name: this.queryName,
            ...this.query,
            time: getTimeConfig({ range: this.args.range }),
          },
        ],
      },
      this.args.range,
    );
  }

  get queryName() {
    return this.args.isSelfServeFailed ? SELF_SERVE_FAILED_HUMAN_SUPPORT : DIRECT_TO_HUMAN_SUPPORT;
  }

  get query() {
    return this.args.isSelfServeFailed ? SELF_SERVE_FAILED : DIRECT_TO_HUMAN;
  }
}
