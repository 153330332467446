/* import __COLOCATED_TEMPLATE__ from './descriptor-details-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { CONVERSATION_OBJECT_TYPE_NAME } from 'embercom/models/custom-objects/constants/object-types';
import type TicketType from 'embercom/models/inbox/ticket-type';

// TODO: At somepoint we can pass this type to the AttributeDescriptor model to start to propagate types to it
interface AttributeInterface {
  id?: number;
  name: string;
  displayName: string;
  description: string;
  dataType: string;
  displayableDataType: string;
  allowMultipleValues: boolean;
  unArchivedListOptions: any[];
  multiline: boolean;
  visibleOnCreate: boolean;
  visibleToUsers: boolean;
  requiredToCreateForUsers: boolean;
  requiredToCreate: boolean;
  order: number;
  existingAndVisibleTeamIds: number[];
  isVisibilityLimited: string;
  required: boolean;
  reference: any;
  archived: boolean;
  rollbackAttributes: () => void;
  setProperties: (obj: Partial<AttributeInterface>) => void;
  listOptions: any[];
  cardinality: string;
  isBuiltIn: boolean;
  visibleToTeamIds: number[] | null;
  relatedObjectAttributeName: any;
  relatedObjectAttributeDescription: any;
  save: () => void;
}

interface Args {
  canEditConditions?: boolean;
  attributeToEdit?: AttributeInterface;
  ticketType?: TicketType;
  availableTypes?: any[];
  onModalClose: () => void;
  onDescriptorCreated?: (arg: any) => void;
  archiveAttribute?: () => void;
  allAttributes: any[];
  startWithTab?: string;
}

export default class DescriptorDetailsSideDrawer extends Component<Args> {
  @service declare intl: any;

  sourceObjectName = CONVERSATION_OBJECT_TYPE_NAME;

  @tracked editMode = isPresent(this.args.attributeToEdit);

  get modalTitle() {
    if (this.args.attributeToEdit) {
      return this.args.attributeToEdit.displayName;
    }

    return this.intl.t(
      `settings.ticket-data.ticket-type-configuration.ticket-attributes-modal-title-${
        this.editMode ? 'edit' : 'create'
      }`,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketTypes::DescriptorDetailsSideDrawer': typeof DescriptorDetailsSideDrawer;
    'settings/ticket-types/descriptor-details-side-drawer': typeof DescriptorDetailsSideDrawer;
  }
}
