/* import __COLOCATED_TEMPLATE__ from './closed-on-first-contact-columnchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  COUNT,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  IS_RESOLVED_FIRST_CONTACT,
} from 'embercom/lib/reporting/flexible/constants';
import { inject as service } from '@ember/service';

export default class ClosedOnFirstContactColumnchart extends Component {
  @service intl;
  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
        createTitleTooltipText: () => {
          return this.intl.t(
            'components.reporting.reports.effectiveness.closed-on-first-columnchart.tooltip',
          );
        },
      },
    };
    viewConfig.formatUnit = {
      unit: units.percent,
      displayUnit: 'of closed conversations',
    };

    return viewConfig;
  }
  get dataConfig() {
    return {
      xAxis: {
        type: 'temporal',
        data: {
          interval: FILTER_PROPERTY_PLACEHOLDER,
          property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
        },
      },
      series: [
        {
          name: 'closed_on_first_contact_filtered_columnchart',
          source: 'conversation_with_message_thread',
          type: COUNT,
          data: {
            property: IS_RESOLVED_FIRST_CONTACT,
          },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'category',
            data: {
              property: IS_RESOLVED_FIRST_CONTACT,
              values: [true],
            },
          },
        },
        {
          name: 'closed_on_first_contact_all_columnchart',
          source: 'conversation_with_message_thread',
          type: COUNT,
          data: {
            property: IS_RESOLVED_FIRST_CONTACT,
          },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
      ],
    };
  }
}
