/* import __COLOCATED_TEMPLATE__ from './median-time-to-close.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  DIRECT_TO_HUMAN_MEDIAN_TIME_TO_CLOSE,
  SELF_SERVE_FAILED_MEDIAN_TIME_TO_CLOSE,
} from 'embercom/lib/reporting/csf-queries';
import { getTimeConfig } from 'embercom/lib/reporting/csf-query-helper';
import { buildDataConfigWithComparisons } from '../../../../lib/reporting/comparison-data-config';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';

const SELF_SERVE_FAILED_MEDIAN_TIME_TO_CLOSE_NAME = 'self-serve-failed-median-time-to-close';
const DIRECT_TO_HUMAN_MEDIAN_TIME_TO_CLOSE_NAME = 'direct-to-human-support-median-time-to-close';

export default class MedianTimeToCloseComponent extends Component {
  get viewConfig() {
    return {
      formatUnit: { unit: units.minute },
      counter: {
        [SELF_SERVE_FAILED_MEDIAN_TIME_TO_CLOSE_NAME]: {
          improvementDirection: improvementDirections.DECREASING,
        },
        [DIRECT_TO_HUMAN_MEDIAN_TIME_TO_CLOSE_NAME]: {
          improvementDirection: improvementDirections.DECREASING,
        },
        [`${SELF_SERVE_FAILED_MEDIAN_TIME_TO_CLOSE_NAME}-in-office-hours`]: {
          improvementDirection: improvementDirections.DECREASING,
        },
        [`${DIRECT_TO_HUMAN_MEDIAN_TIME_TO_CLOSE_NAME}-in-office-hours`]: {
          improvementDirection: improvementDirections.DECREASING,
        },
      },
    };
  }

  get dataConfig() {
    return buildDataConfigWithComparisons(
      {
        series: [
          {
            name: this.queryName,
            ...this.query(this.args.settings?.excludeOooHours),
            time: getTimeConfig({ range: this.args.range }),
          },
        ],
      },
      this.args.range,
    );
  }

  nameInOfficeHours(name) {
    if (this.args.settings?.excludeOooHours) {
      name = `${name}-in-office-hours`;
    }
    return name;
  }

  get queryName() {
    return this.nameInOfficeHours(
      this.args.isSelfServeFailed
        ? SELF_SERVE_FAILED_MEDIAN_TIME_TO_CLOSE_NAME
        : DIRECT_TO_HUMAN_MEDIAN_TIME_TO_CLOSE_NAME,
    );
  }

  get query() {
    return this.args.isSelfServeFailed
      ? SELF_SERVE_FAILED_MEDIAN_TIME_TO_CLOSE
      : DIRECT_TO_HUMAN_MEDIAN_TIME_TO_CLOSE;
  }

  get reportingExcludeOooHours() {
    return this.args.settings?.excludeOooHours;
  }
}
