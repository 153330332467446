/* import __COLOCATED_TEMPLATE__ from './migration-welcome-banner.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Args {
  hasDismissedBanner: boolean;
  migrationBannerKey: string;
}

export default class MigrationWelcomeBanner extends Component<Args> {
  @tracked hasDismissedBanner = this.args.hasDismissedBanner;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ReportV2::MigrationWelcomeBanner': typeof MigrationWelcomeBanner;
  }
}
