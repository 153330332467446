/* import __COLOCATED_TEMPLATE__ from './x-scale.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { scaleTime } from 'd3-scale';
import moment from 'moment-timezone';

const X_AXIS_ITEM_WIDTH = 46; //corresponds to max-width set in reporting/_axes.scss

let createRangeTuple = (availiableWidth, itemsCount, itemWidth) => {
  let spacePerItem = availiableWidth / itemsCount;
  let spaceAroundItems = spacePerItem - itemWidth;
  let spaceBeforeItems = spaceAroundItems / 2;

  return [spaceBeforeItems, availiableWidth - spaceBeforeItems];
};

export default Component.extend({
  tagName: '',
  data: readOnly('signal.context'),
  timezone: readOnly('signal.timezone'),
  dates: computed('data', function () {
    // Use date from signal if it exists, otherwise use date range from date picker (signal might be empty)
    return this.datesFromSignal.length > 0 ? this.datesFromSignal : this.datesFromRangePicker;
  }),
  datesFromSignal: computed('data', 'timezone', function () {
    return this.data.map((d) => moment.tz(d.key, this.timezone));
  }),
  datesFromRangePicker: computed('data', function () {
    // If for some reason there is no datePickerRange default to last 7 days.
    let defaultStartDate = moment().tz(this.timezone).subtract(7, 'days').toString();

    let startDate = this.signal.get('datePickerRangeStart') || defaultStartDate;
    let endDate = this.signal.get('datePickerRangeEnd') || moment().toString();

    let days = Math.round(moment.duration(moment(endDate) - moment(startDate)).asDays());
    let range = [];
    let dayIndex;
    let hourIndex = 0;

    if (days > 1) {
      // Range of days from start to end
      for (dayIndex = 0; dayIndex < days; dayIndex++) {
        range.push(moment(startDate).add(dayIndex, 'days'));
      }
    } else {
      // Range of 24 hours increasing by 2 hours.
      for (hourIndex = 0; hourIndex < 24; hourIndex += 2) {
        range.push(moment(startDate).add(hourIndex, 'hours'));
      }
    }

    return range;
  }),
  fromTo: computed('dates', function () {
    return [
      moment.tz(this.dates[0], this.timezone),
      moment.tz(this.dates[this.dates.length - 1], this.timezone),
    ];
  }),
  from: readOnly('fromTo.firstObject'),
  to: readOnly('fromTo.lastObject'),
  stride: computed('width', 'dates.length', function () {
    let maxNumberOfTicks = this.width / X_AXIS_ITEM_WIDTH;
    return Math.ceil(this.get('dates.length') / maxNumberOfTicks);
  }),
  ticks: readOnly('dates'),
  xScale: computed('width', 'fromTo', function () {
    let range = createRangeTuple(
      this.width - X_AXIS_ITEM_WIDTH,
      this.dates.length,
      X_AXIS_ITEM_WIDTH,
    );

    return scaleTime().domain(this.fromTo).range(range);
  }),
});
