/* import __COLOCATED_TEMPLATE__ from './add-tags-to-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { readOnly, map } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  tagName: '',
  appService: service(),
  app: readOnly('appService.app'),

  tagItems: map('app.tags', function (tag) {
    return {
      text: tag.get('name'),
      value: tag.get('name'),
    };
  }),

  actions: {
    updateTag(tag) {
      this.set('action.actionData', {
        tags: [
          {
            name: tag,
            creator_id: parseInt(this.get('app.currentAdmin.id'), 10),
          },
        ],
      });

      let onChange = this.onChange;
      if (onChange) {
        onChange();
      }
    },
  },
});
