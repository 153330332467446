/* import __COLOCATED_TEMPLATE__ from './table-data-manager.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { isBlank } from '@ember/utils';

// replaces accents with ascii versions where possible
// and converts to lower-case
//
// String.prototype.normalize is supported everywhere apart from IE
// so just convert to lower-case for them
//
// Eg "Crème Brulée" -> "creme brulee"
function normalizeString(str) {
  if ('normalize' in String.prototype) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  } else {
    return str.toLowerCase();
  }
}

export default Component.extend({
  data: [],
  sortState: null,
  currentSortState: null,
  filterState: null,

  size: 5,
  numPagesShowing: 1,

  isLoadingMore: false,
  isLoading: false,

  didReceiveAttrs() {
    this._super(...arguments);
    this.set('numPagesShowing', 1);
  },

  init() {
    this._super(...arguments);
    this.set('currentSortState', this.sortState);
  },

  sortedData: computed('data', 'currentSortState', function () {
    let data = this.data || [];
    let { direction, valuePath } = this.currentSortState;

    let asc = [...data].sort((a, b) => {
      let valA = a[valuePath];
      let valB = b[valuePath];

      switch (typeof valA) {
        case 'string':
          return valA.localeCompare(valB);
        default:
          return valA - valB;
      }
    });

    if (direction === 'desc') {
      return asc.reverse();
    } else {
      return asc;
    }
  }),

  filteredData: computed('sortedData', 'filterState', function () {
    let { valuePath, filter } = this.filterState || {};

    if (isBlank(filter)) {
      return this.sortedData;
    }

    let normalizedFilter = normalizeString(filter);
    return this.sortedData.filter(
      (record) => normalizeString(record[valuePath]).indexOf(normalizedFilter) !== -1,
    );
  }),

  numRecordsShowing: computed('size', 'numPagesShowing', function () {
    return this.size * this.numPagesShowing;
  }),

  currentData: computed('filteredData', 'numRecordsShowing', function () {
    return this.filteredData.slice(0, this.numRecordsShowing);
  }),

  canLoadMore: computed('numRecordsShowing', 'filteredData', function () {
    return this.numRecordsShowing < this.filteredData.length;
  }),

  actions: {
    onSort(valuePath) {
      let direction = 'asc';

      if (
        this.currentSortState.valuePath === valuePath &&
        this.currentSortState.direction === 'asc'
      ) {
        direction = 'desc';
      }

      this.set('currentSortState', { valuePath, direction });
      this.set('numPagesShowing', 1);

      this.triggerAnalyticsEvent({
        action: 'conversation_tags_table_sort',
        columnName: this.sortAliases ? this.sortAliases[valuePath] : valuePath,
        direction,
      });
    },

    onLoadMore() {
      this.set('numPagesShowing', this.numPagesShowing + 1);

      this.triggerAnalyticsEvent({
        action: 'conversation_tags_table_load_more',
      });
    },
  },
});
