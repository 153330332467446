/* import __COLOCATED_TEMPLATE__ from './columnchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import SerieschartBuilder from 'embercom/lib/reporting/flexible/serieschart-builder';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import PALETTE from '@intercom/pulse/lib/palette';
import {
  SORTING_LABEL_NAME,
  SORTING_LABEL_NUMERIC,
} from 'embercom/lib/reporting/flexible/constants';
import { inject as service } from '@ember/service';
import { mapXAxisLabel } from 'embercom/lib/reporting/flexible/label-formatter';
import Component from '@glimmer/component';
import { useResource } from 'ember-resources';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { cached } from 'tracked-toolbox';
import {
  calculatePercentageDataResponse,
  shouldAllowZeroValues,
  getFirstSeriesBucketKeys,
} from 'embercom/lib/reporting/flexible/data-response-helpers';
import { chunk } from 'underscore';
import { isEmpty, isPresent } from '@ember/utils';
import {
  mapHumanReadableLabelsToRawKey,
  getChartSeriesName,
} from 'embercom/lib/reporting/custom/view-config-builder-helpers';

export default class Columnchart extends Component {
  @service appService;
  @service intl;
  @service intercomEventService;

  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.args.dataConfig,
    viewConfig: this.args.viewConfig,
    renderableChart: this.args.renderableChart,
  }));

  get app() {
    return this.appService.app;
  }

  get legend() {
    if (this.args.viewConfig.legend && !this.args.renderableChart?.showTimeComparison) {
      return this.args.viewConfig.legend;
    }

    return {
      comparison_columnchart: () => {
        return `${moment(this.args.range.startMoment).format(
          dateAndTimeFormats.dateWithoutYear,
        )} - ${moment(this.args.range.endMoment).format(dateAndTimeFormats.dateWithoutYear)}`;
      },
      comparison_columnchart_previous: () => {
        return this.intl.t('reporting.flexible-chart.previous-time-period-in-days', {
          dayCount: this.args.range.inDays,
        });
      },
    };
  }

  get isTimeComparison() {
    return (
      this.args.viewConfig.grouping?.isComparison || this.args.renderableChart?.showTimeComparison
    );
  }

  mapToSameAxis(dataResponses) {
    if (!dataResponses) {
      return dataResponses;
    }
    if (dataResponses.length === 1) {
      dataResponses[0].groups[0].names = [...dataResponses[0].groups[0].values];
      return dataResponses;
    }

    let currentPeriodResponses = this.isTimeComparison
      ? dataResponses.slice(dataResponses.length / 2)
      : dataResponses;
    let previousPeriodResponses = this.isTimeComparison
      ? dataResponses.slice(0, dataResponses.length / 2)
      : [];
    currentPeriodResponses.forEach((response) => {
      response.groups[0].names = [...response.groups[0].values];
    });

    if (
      this.args.viewConfig.formatUnit.unit === units.percent &&
      isEmpty(this.args.viewConfig.metrics)
    ) {
      return chunk(dataResponses, 2).map(([numeratorData, denominatorData]) => {
        return calculatePercentageDataResponse({
          numeratorData,
          denominatorData,
        });
      });
    }

    // we care only about a single nesting level of groups
    // we also rely on the presence of the group. Add validation in the future
    previousPeriodResponses.forEach((response, index) => {
      response.groups[0].names = response.groups[0].values;
      response.groups[0].values = [...currentPeriodResponses[index].groups[0].values];
    });

    return [...previousPeriodResponses, ...currentPeriodResponses];
  }

  @cached
  get chartData() {
    let groupingTransformation = this.args.viewConfig.grouping?.dataTransformation;
    // Nolaneo – this linter error seems genuine: why are we mutating state in this getter?
    // eslint-disable-next-line
    this.dataModified = {};
    try {
      let mappedResponse = this.mapToSameAxis(this.dataResource.rawChartData);
      let firstSeriesBucketKeys = getFirstSeriesBucketKeys(
        this.args.renderableChart,
        this.dataResource.rawChartData,
      );
      return mappedResponse.map((response, index) => {
        if (groupingTransformation) {
          response = groupingTransformation(response);
        }

        let data = this.buildVisualisationData(response, firstSeriesBucketKeys);
        data = this.sortAndLimitValuesIfNeeded(data);

        // For multi-metric charts with percentage or ratio metrics, we need to
        // use the main metric id, and not the metric id in the response
        let seriesName = this.args.viewConfig.metrics?.[index]?.id || response.name;

        let legendLabel;

        let seriesIsPreviousPeriod = false;

        if (this.args.renderableChart?.showTimeComparison) {
          seriesIsPreviousPeriod = response.name.includes('previous');

          let metricNameLabel = '';
          if (this.args.renderableChart?.isMultimetric) {
            let numberOfCurrentSeries = this.args.renderableChart.chartSeries.length;
            let seriesIndex = seriesIsPreviousPeriod ? index : index - numberOfCurrentSeries;
            metricNameLabel = getChartSeriesName(seriesIndex, this.args.renderableChart);
          }
          let previousLabel =
            this.legend['comparison_columnchart_previous'] &&
            this.legend['comparison_columnchart_previous'].call(seriesName);
          let comparisonLabel =
            this.legend['comparison_columnchart'] &&
            this.legend['comparison_columnchart'].call(seriesName);

          if (seriesIsPreviousPeriod) {
            legendLabel = metricNameLabel ? `${metricNameLabel} - ${previousLabel}` : previousLabel;
          } else {
            legendLabel = metricNameLabel
              ? `${metricNameLabel} - ${comparisonLabel}`
              : comparisonLabel;
          }
        } else if (this.args.renderableChart?.isMultimetric) {
          legendLabel = getChartSeriesName(index, this.args.renderableChart);
        } else {
          legendLabel = this.legend[seriesName] && this.legend[seriesName].call();
        }

        let options = {
          name: seriesName,
          data,
          legendLabel,
          tooltipDisplayUnit: this.args.viewConfig.columnChart?.series?.tooltips[seriesName],
          showInLegend: mappedResponse.length > 1,
          dataModified: this.dataModified,
          isComparison: this.isTimeComparison,
          rawToLabelMapping: mapHumanReadableLabelsToRawKey(
            response,
            this.args.dataConfig,
            this.args.viewConfig,
          ),
          isPreviousPeriod: seriesIsPreviousPeriod,
        };

        options = this.addHoverColor(options, seriesName);

        return options;
      });
    } catch (e) {
      console.error(e);
      this.dataResource.notifyError();
      return [];
    }
  }

  addHoverColor(options, responseName) {
    if (this.args.viewConfig.columnChart?.series?.hoverColor) {
      options['hoverColor'] = this.args.viewConfig.columnChart.series.hoverColor[responseName];
    }
    return options;
  }

  buildVisualisationData(dataResponse, keys = []) {
    // we assume there's only one group for a simple barchart
    let group = dataResponse.groups[0];
    let aggregation = group.aggregations[0];
    let xAxisType = this.args.dataConfig.xAxis.type;
    return group.values
      .map((gv, index) => {
        if (isPresent(keys) && !keys.includes(gv)) {
          return undefined;
        }
        let x = gv;
        let y =
          aggregation.values[index] !== 0 || this.shouldAllowZeroValues
            ? aggregation.values[index]
            : null;

        if (xAxisType === 'nominal') {
          let mappedX = mapXAxisLabel(this.args.dataConfig, this.args.viewConfig, x);
          return [mappedX.toString(), y, mappedX];
        } else {
          let name = group?.names?.get(index);
          return [x, y, name];
        }
      })
      .compact();
  }

  get aggregationFunction() {
    return this.args.dataConfig?.series?.firstObject?.type;
  }

  get shouldAllowZeroValues() {
    return shouldAllowZeroValues(this.args.viewConfig.metrics?.[0], this.aggregationFunction);
  }

  aggregateValuesForOtherColumn(visualisationData) {
    let values = visualisationData.map((point) => point[1]);

    if (this.aggregationFunction === 'min') {
      return Math.min(...values);
    }

    if (this.aggregationFunction === 'max') {
      return Math.max(...values);
    }

    if (this.aggregationFunction === 'median') {
      let midpoint = Math.floor(values.length / 2);
      let sortedValues = values.sort();
      if (sortedValues.length % 2 !== 0) {
        return sortedValues[midpoint];
      }
      return (sortedValues[midpoint - 1] + sortedValues[midpoint]) / 2;
    }

    let sum = values.reduce((acc, v) => acc + v);
    if (this.aggregationFunction === 'mean') {
      return sum / values.length;
    }
    return sum;
  }

  sortAndLimitValuesIfNeeded(visualisationData) {
    let limit = this.args.dataConfig.xAxis.data.limit;

    //sort by label name
    if (this.args.dataConfig.xAxis.data.sorting === SORTING_LABEL_NAME) {
      visualisationData.sort(function (pointA, pointB) {
        return pointA[2].localeCompare(pointB[2]);
      });
    } else if (this.args.dataConfig.xAxis.data.sorting === SORTING_LABEL_NUMERIC) {
      visualisationData.sort(function (pointA, pointB) {
        return Number(pointA[2]) - Number(pointB[2]);
      });
    } else if (typeof this.args.dataConfig.xAxis.data.sorting === 'function') {
      visualisationData.sort(this.args.dataConfig.xAxis.data.sorting);
    }

    //limit results and create 'Other' bucket
    if (limit && visualisationData.length > limit && this.isNotMetricPoweredChart) {
      //we don't want frontend limiting for metric powered charts
      this.dataModified.truncated = visualisationData.length - limit;
      let truncatedData = visualisationData.slice(0, limit);
      if (this.args.dataConfig.xAxis.data.showOtherColumn) {
        let otherData = visualisationData.slice(limit);
        let otherBucketValue = this.aggregateValuesForOtherColumn(otherData);
        truncatedData.push(['Other', otherBucketValue, 'Other']);
      }
      visualisationData = truncatedData;
    }

    return visualisationData;
  }

  get isNotMetricPoweredChart() {
    return isEmpty(this.args.viewConfig?.metrics);
  }

  get chartOptions() {
    let options = {
      range: this.args.range,
      chartData: this.chartData,
      width: this.args.width,
      viewConfig: this.args.viewConfig,
      dataConfig: this.args.dataConfig,
      app: this.app,
      isMultimetric: this.args.renderableChart?.isMultimetric || false,
      isTimeComparison: this.args.renderableChart?.showTimeComparison || false,
    };

    if (this.args.viewConfig.seriesColors) {
      options['seriesColors'] = this.args.viewConfig.seriesColors;
    } else if (this.chartData.length > 1 && !options.isMultimetric) {
      options['seriesColors'] = [PALETTE['vis-azure-80'], PALETTE['vis-azure-50']];
    }

    let builder = new SerieschartBuilder(options);
    return builder.buildTheme();
  }

  get hasData() {
    if (this.args.disabled) {
      return false;
    }

    if (this.shouldAllowZeroValues) {
      return this.chartData.some((singleSeries) => {
        return singleSeries.data.some(
          (dataArray) => dataArray[1] !== null && dataArray[1] !== undefined,
        );
      });
    } else {
      return this.chartData.some((singleSeries) => {
        return singleSeries.data.some((dataArray) => dataArray[1]);
      });
    }
  }

  get showEmptyState() {
    return this.args.dashboard?.isPaywalled || this.args.isPaywalled || !this.hasData;
  }
}
