/* import __COLOCATED_TEMPLATE__ from './pick-conversation-data-attribute-list.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class PickConversationDataAttributeList extends Component {
  @tracked selectedValue = this.args.selectedValue;
  @tracked onUpdate = this.args.onUpdate;

  get selectedAttributeName() {
    return this.args.conversationDataAttribute.options.find(
      (option) => option.identifier === this.selectedValue,
    )?.value;
  }

  get items() {
    return [
      {
        items: this.args.conversationDataAttribute.options
          .filter((option) => !option.archived || option.identifier === this.selectedValue)
          .map((option) => {
            return { value: option.identifier, text: option.value };
          }),
      },
    ];
  }

  @action
  selectListItem(value) {
    this.selectedValue = value;
    this.args.onUpdate(value);
  }
}
