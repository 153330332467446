/* import __COLOCATED_TEMPLATE__ from './teams-edit-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { A } from '@ember/array';
import { alias, notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import intermoji from '@intercom/intermoji';

export default Component.extend({
  selectedTeamEmoji: alias('team.avatar_emoji'),
  emptyTeamMembers: A(),
  selectedTeamMembersCount: alias('selectedTeamMembers.length'),
  selectedTeamMembers: ternaryToProperty('team', 'team.teamMembers', 'emptyTeamMembers'),
  selectedTeamHasTeamMembers: notEmpty('selectedTeamMembers'),

  actions: {
    selectAvatarEmoji(emojiIdentifier) {
      let unicodeEmoji = intermoji.unicodeFromIdentifier(emojiIdentifier);
      let twemojiCompatibleEmoji = intermoji.getSupportedTwemoji(unicodeEmoji);
      this.setProperties({
        selectedTeamEmoji: twemojiCompatibleEmoji,
        isEmojiPopoverOpen: false,
      });
    },
  },
});
