/* import __COLOCATED_TEMPLATE__ from './timezones-picker.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';

export default class TimezonesPicker extends Component {
  @service appService;
  @service customReportsService;
  @service intercomEventService;

  @tracked timezoneSelected = null;
  timezoneList = this.customReportsService.timezones();

  constructor() {
    super(...arguments);
    this.timezoneSelected = this.defaultTimezoneName;
  }

  @action
  updateTimezone(name) {
    let value = this.getTimezoneValue(name);
    this.args.report.applyTimeZone(value);
    if (this.args.isStaticReport) {
      localStorage.setItem(`${this.args.report.id}_timezone`, value);
    }
    this.timezoneSelected = name;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated',
      object: 'timezone',
      timezone: name,
    });
  }

  getTimezoneName(value) {
    return this.timezoneList.find((tz) => tz.value === value)?.description;
  }

  getTimezoneValue(name) {
    return this.timezoneList.find((tz) => tz.description === name)?.value;
  }

  get currentTimezoneValue() {
    return this.getTimezoneValue(this.timezoneSelected);
  }

  get timezones() {
    return this.timezoneList.map((tz) => {
      return {
        text: tz.description,
        value: tz.description,
      };
    });
  }

  get defaultTimezoneValue() {
    if (this.args.isStaticReport) {
      return (
        localStorage.getItem(`${this.args.report.id}_timezone`) ||
        this.args.report.timezone ||
        this.appService.app.timezone
      );
    }
    return this.args.report.timezone || this.appService.app.timezone;
  }

  get defaultTimezoneName() {
    return this.getTimezoneName(this.defaultTimezoneValue);
  }

  get shouldRenderTimezones() {
    let appTimezone = this.timezoneList.find((tz) => tz.value === this.appService.app.timezone);
    return isPresent(appTimezone);
  }
}
