/* import __COLOCATED_TEMPLATE__ from './report-template-card.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Args {
  template: any;
  onClick: () => void;
}

export default class ReportTemplateCard extends Component<Args> {
  @tracked isHovered = false;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ReportTemplateCard': typeof ReportTemplateCard;
    'reporting/custom/report/report-template-card': typeof ReportTemplateCard;
  }
}
