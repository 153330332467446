/* import __COLOCATED_TEMPLATE__ from './collapsible-ticket-state-list.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import platform from 'embercom/lib/browser-platform';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';

type TabView = 'live' | 'archived';

interface Args {
  systemState: TicketSystemState;
  currentTab: TabView;
  onRestoreTicketCustomState: (ticketCustomState: TicketCustomState) => void;
  newSettings?: boolean;
  accordion: any;
}

export default class CollapsibleTicketStateList extends Component<Args> {
  @service declare ticketStateService: TicketStateService;
  @service declare intl: IntlService;
  @service declare session: Session;
  @tracked openSectionId = `section-${this.args.systemState}`;

  tooltipShortcut = platform.isMac ? '⌥' : 'ALT';

  get backgroundColor() {
    return `bg-${this.iconDetails.backgroundColor}`;
  }

  get title() {
    switch (this.args.systemState) {
      case TicketSystemState.Submitted:
        return this.intl.t(`inbox.ticket-state.${TicketSystemState.Submitted}`);
      case TicketSystemState.InProgress:
        return this.intl.t(`inbox.ticket-state.${TicketSystemState.InProgress}`);
      case TicketSystemState.WaitingOnCustomer:
        return this.intl.t(`inbox.ticket-state.${TicketSystemState.WaitingOnCustomer}`);
      case TicketSystemState.Resolved:
        return this.intl.t(`inbox.ticket-state.${TicketSystemState.Resolved}`);
      default:
        throw new Error(`Unknown system state: ${this.args.systemState}`);
    }
  }

  get systemStateExplainer() {
    if (this.args.systemState === TicketSystemState.Submitted) {
      return this.intl.t(
        `settings.ticket-states.ticket-state-editor.description.multiple_submitted`,
      );
    }

    return this.intl.t(
      `settings.ticket-states.ticket-state-editor.description.${this.args.systemState}`,
    );
  }

  get iconDetails() {
    return this.ticketStateService.getIconForSystemState(this.args.systemState);
  }

  get iconColor() {
    if (this.args.currentTab === 'live') {
      return this.iconDetails.iconColor;
    } else {
      return 'gray';
    }
  }

  get canBeOpened() {
    return this.count > 0;
  }

  get ticketCustomStatesPerSystemState(): TicketCustomState[] {
    if (this.args.currentTab === 'live') {
      return this.ticketStateService.liveTicketCustomStates.filter(
        (state) => state.systemState === this.args.systemState,
      );
    } else {
      return this.ticketStateService.archivedTicketCustomStates.filter(
        (state) => state.systemState === this.args.systemState,
      );
    }
  }

  get count() {
    return this.ticketCustomStatesPerSystemState.length;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketStates::CollapsibleTicketStateList': typeof CollapsibleTicketStateList;
  }
}
