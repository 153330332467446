/* import __COLOCATED_TEMPLATE__ from './survey-report.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { responseTypes, scaleTypes } from 'embercom/models/data/survey/constants';
import {
  sentChartFilters,
  respondedChartFilters,
  tableChartFilters,
  defaultChartDefinition,
  ANSWER_TO_QUESTION_IDENTIFIER,
} from 'embercom/lib/reporting/survey-helpers';
import {
  filterableProperties,
  surveyPropertyIdentifiers,
  USER_TYPE_FILTER_IDENTIFIER,
} from 'embercom/lib/reporting/custom/filter-helpers';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';

const COMPONENT_NAME_LOOKUP = {
  [scaleTypes.emoji]: 'survey-rating-emoji-bar',
  [scaleTypes.stars]: 'survey-rating-star-bar',
};

export default class SurveyReport extends Component {
  @service store;
  @service intl;
  @service appService;

  get reportDefinition() {
    return {
      id: `static_report_${this.surveyId}`,
      title: null,
      description: null,
      charts: [this.sentChart(), this.respondedChart(), ...this.questionCharts()],
      date_range: null,
      filters: {},
    };
  }

  get answerToQuestionFilterId() {
    return ANSWER_TO_QUESTION_IDENTIFIER;
  }

  get surveyId() {
    return this.args.survey.id;
  }

  get questions() {
    return this.args.survey.questions;
  }

  get filterableProperties() {
    return filterableProperties()
      .filter(
        (property) =>
          property.startsWith('conversation.user.') ||
          property.startsWith('conversation.companies.') ||
          property === 'time',
      )
      .concat(ANSWER_TO_QUESTION_IDENTIFIER);
  }

  get filterableQuestionProperties() {
    return surveyPropertyIdentifiers(this.args.survey);
  }

  get customFilterArgs() {
    return {
      [USER_TYPE_FILTER_IDENTIFIER]: {
        showVisitorType: true,
      },
      [ANSWER_TO_QUESTION_IDENTIFIER]: {
        survey: this.args.survey,
      },
    };
  }

  sentChart() {
    return {
      ...defaultChartDefinition(),
      height_type: HEIGHT_TYPES.X_SMALL,
      title: this.intl.t('reporting.surveys.sent'),
      ignored_filters: this.filterableQuestionProperties,
      chart_series: [
        {
          metric_id: 'surveys.sent',
          filters: sentChartFilters(this.surveyId),
        },
      ],
    };
  }

  respondedChart() {
    return {
      ...defaultChartDefinition(),
      height_type: HEIGHT_TYPES.X_SMALL,
      title: this.intl.t('reporting.surveys.customer_responses'),
      ignored_filters: this.filterableQuestionProperties,
      chart_series: [
        {
          metric_id: 'surveys.responded',
          filters: respondedChartFilters(this.surveyId),
        },
      ],
    };
  }

  questionCharts() {
    return this.questions
      .map((question) => {
        switch (question.questionType) {
          case responseTypes.ratingScale:
            if (question.data.type === scaleTypes.nps) {
              return [
                this.npsCounterChart(question),
                ...this.npsLineChartWithLayoutAdjustment(question),
                this.npsColumnChart(question),
              ];
            } else {
              return this.ratingChart(question);
            }
          case responseTypes.input:
          case responseTypes.text:
            return this.tableChart(question);
          case responseTypes.multiSelect:
            return this.multiSelectChart(question);
          default:
            return this.questionChart(question);
        }
      })
      .flat();
  }

  titleFor(question) {
    return question.questionTitle || this.intl.t('reporting.surveys.empty-question-title');
  }

  tableChart(question) {
    return {
      ...defaultChartDefinition(),
      ...this.tableChartFields(question),
      chart_series: [
        {
          metric_id: 'surveys.responded',
          filters: tableChartFilters(this.surveyId, question.id),
        },
      ],
    };
  }

  ratingChart(question) {
    return {
      ...defaultChartDefinition(),
      ...this.ratingChartFields(question),
      chart_series: [
        {
          metric_id: 'surveys.responded',
          filters: sentChartFilters(this.surveyId),
        },
      ],
    };
  }

  questionChart(question) {
    return {
      ...defaultChartDefinition(),
      ...this.questionChartFields(question),
      chart_series: [
        {
          metric_id: 'surveys.responded',
          filters: sentChartFilters(this.surveyId),
        },
      ],
    };
  }

  multiSelectChart(question) {
    return {
      ...defaultChartDefinition(),
      ...this.multiSelectChartFields(question),
      chart_series: [
        {
          metric_id: 'surveys.responded',
          filters: sentChartFilters(this.surveyId),
        },
      ],
    };
  }

  npsLineChartWithLayoutAdjustment(question) {
    return [
      ...(this.isNpsScoreChartOnTheLeft(question.id) ? [this.invisibleChart(question)] : []),
      this.npsLineChart(question),
    ];
  }

  isNpsScoreChartOnTheLeft(npsQuestionId) {
    let npsQuestionIndex = this.questions.findIndex((question) => question.id === npsQuestionId);

    return this.questions.reduce((isNpsScoreChartOnTheLeft, question, questionIndex) => {
      if (questionIndex >= npsQuestionIndex) {
        // only the questions coming before the NPS question affect the NPS question location
        return isNpsScoreChartOnTheLeft;
      } else {
        let isNpsChart = question.isRatingScale && question.data.type === scaleTypes.nps;
        let isTableChart = question.questionType === responseTypes.text;

        // since nps and table charts are full width, accumulator should reset to true
        return isNpsChart || isTableChart || !isNpsScoreChartOnTheLeft;
      }
    }, true);
  }

  invisibleChart(question) {
    return {
      id: `invisible_chart_${this.surveyId}_${question.id}`,
      height_type: HEIGHT_TYPES.X_SMALL,
      visualization_type: VISUALIZATION_TYPES.INVISIBLE,
      chart_series: [
        {
          metric_id: 'surveys.responded',
        },
      ],
    };
  }

  npsLineChart(question) {
    return {
      ...defaultChartDefinition(),
      ...this.npsLineChartFields(question),
      chart_series: [
        {
          metric_id: 'surveys.responded',
          filters: sentChartFilters(this.surveyId),
        },
      ],
    };
  }

  npsColumnChart(question) {
    return {
      ...defaultChartDefinition(),
      ...this.npsColumnChartFields(question),
      chart_series: [
        {
          metric_id: 'surveys.responded',
          filters: sentChartFilters(this.surveyId),
        },
      ],
    };
  }

  npsCounterChart(question) {
    return {
      ...defaultChartDefinition(),
      ...this.npsCounterChartFields(question),
      chart_series: [
        {
          metric_id: 'surveys.responded',
          filters: sentChartFilters(this.surveyId),
        },
      ],
    };
  }

  tableChartFields(question) {
    return {
      id: `${this.surveyId}_${question.id}`,
      title: this.titleFor(question),
      visualization_type: VISUALIZATION_TYPES.BESPOKE,
      width_type: WIDTH_TYPES.FULL,
      height_type: HEIGHT_TYPES.X_TALL,
      component_name: 'survey-question-table',
    };
  }

  ratingChartFields(question) {
    let questionType = question.data.type;
    let component_name = COMPONENT_NAME_LOOKUP[questionType] || 'survey-rating-bar';
    return {
      id: `${this.surveyId}_${question.id}`,
      title: this.titleFor(question),
      view_by: `stats.question#${question.id}`,
      visualization_type: VISUALIZATION_TYPES.BESPOKE,
      view_by_display_limit: question.data.scaleEnd - question.data.scaleStart + 1,
      component_name,
    };
  }

  questionChartFields(question) {
    return {
      id: `${this.surveyId}_${question.id}`,
      title: this.titleFor(question),
      view_by: `stats.question#${question.id}`,
      visualization_type: VISUALIZATION_TYPES.BAR,
    };
  }

  multiSelectChartFields(question) {
    return {
      id: `${this.surveyId}_${question.id}`,
      title: this.titleFor(question),
      view_by: `stats.question#${question.id}`,
      visualization_type: VISUALIZATION_TYPES.BESPOKE,
      component_name: 'survey-multi-select',
    };
  }

  npsLineChartFields(question) {
    return {
      id: `${this.surveyId}_${question.id}_line`,
      title: this.titleFor(question),
      segment_by: `stats.question#${question.id}`,
      view_by: 'time',
      visualization_type: VISUALIZATION_TYPES.BESPOKE,
      stacked: true,
      segment_by_display_limit: 11,
      component_name: 'survey-nps-line-chart',
    };
  }

  npsColumnChartFields(question) {
    return {
      id: `${this.surveyId}_${question.id}`,
      title: this.titleFor(question),
      segment_by: `stats.question#${question.id}`,
      view_by: 'time',
      visualization_type: VISUALIZATION_TYPES.BESPOKE,
      stacked: true,
      segment_by_display_limit: 11,
      component_name: 'survey-nps-chart',
    };
  }

  npsCounterChartFields(question) {
    return {
      id: `${this.surveyId}_${question.id}_counter`,
      title: 'NPS Score',
      visualization_type: VISUALIZATION_TYPES.BESPOKE,
      height_type: HEIGHT_TYPES.X_SMALL,
      component_name: 'survey-nps-counter-chart',
      view_by_display_limit: 11,
      view_by: `stats.question#${question.id}`,
    };
  }
}
