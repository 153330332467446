/* import __COLOCATED_TEMPLATE__ from './conversational-support-funnel.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
export default class ConversationalSupportFunnel extends Component {
  @service appService;
  @service reportingService;
  @service outboundHomeService;
  @service intercomEventService;
  @service paywallService;

  @tracked proactiveSupportContentIds = [];

  constructor() {
    super(...arguments);
    this.fetchProactiveSupportContentIds.perform();
  }

  @task({ restartable: true })
  *fetchProactiveSupportContentIds() {
    let filterHash = JSON.parse(this.args.model.proactiveSupportMessageFilters.filterHash || '{}');

    this.proactiveSupportContentIds = yield ajax({
      url: '/ember/reporting/proactive_support_messages/content_ids',
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
        object_types: filterHash.contentTypeIds || this.outboundHomeService.rulesetBasedObjectTypes,
        tag_ids: filterHash.tagIds,
        sender_ids: filterHash.senderIds,
      },
    });
  }

  @action
  updateDateRange(value) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'edited',
      object: 'date_range',
      date_filter: value,
    });

    //Sending triggerEvent = false to avoid triggering REPORTING_RANGE_UPDATED event
    //as this is causing some reloads when changing the date range in the CSF report.
    //https://github.com/intercom/embercom/blob/3cc77560f81d3bd95e180d393047d2ac0410ed79/app/services/reporting-service.js#L78
    this.reportingService.updateRange(value, null, false, null);
  }

  get isProactiveSetupDone() {
    return !!this.args.model.proactiveSupportMessageFilters.filterHash;
  }

  get featureKey() {
    let key = '';
    if (
      this.paywallService.isPaywallActive({ featureName: 'inbox' }) &&
      this.paywallService.isPaywallActive({ featureName: 'answer_workflows' }) &&
      this.paywallService.isPaywallActive({ featureName: 'help_center' })
    ) {
      key = 'inbox';
    }
    return key;
  }
}
