/* import __COLOCATED_TEMPLATE__ from './ticket-types-table.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type TicketTypeSummary from 'embercom/models/inbox/ticket-type-summary';
import { type ColumnConfig } from '../ticket-data/list-ticket-types';

interface Args {
  columns: ColumnConfig[];
  data: TicketTypeSummary[];
  onEdit: (id: number) => void;
  onRestore?: (ticketType: TicketTypeSummary) => void;
}

export default class TicketTypesTable extends Component<Args> {
  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get columns() {
    return this.args.columns;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketTypes::TicketTypesTable': typeof TicketTypesTable;
  }
}
