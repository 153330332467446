/* import __COLOCATED_TEMPLATE__ from './target-channel-filter.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CHANNEL_METADATA_MAPPING } from 'embercom/lib/operator/custom-bots/constants';

export default class TargetChannelFilter extends Component {
  @service intl;
  @tracked selectedTargetChannel = this.args.targetChannels || [];

  @action
  filterByTargetChannel(channels) {
    this.selectedChannels = channels;
  }
  @action
  onOpen() {
    this.selectedChannels = this.args.targetChannels || [];
  }

  @action
  onClose() {
    this.args.filterByTargetChannel(this.selectedChannels);
  }

  get localizedList() {
    if (!this.args.targetChannels) {
      return [];
    }
    return this.args.targetChannels.map((channel) => {
      let metadata = CHANNEL_METADATA_MAPPING[channel];
      return this.intl.t('operator.workflows.channels.type', {
        channel: channel !== 'messenger' ? metadata.value : 'web',
      });
    });
  }

  get toolTipChannelList() {
    return this.intl.formatList(this.localizedList, {
      type: 'conjunction',
    });
  }

  get selectedChannelsLabel() {
    if (this.localizedList.length === 0) {
      return this.intl.t('reporting.custom-bots.target-channel-selector.any-channel');
    }
    if (this.localizedList.length === 1) {
      return this.intl.t('reporting.custom-bots.target-channel-selector.single-channel', {
        channel: this.localizedList[0],
      });
    } else if (this.localizedList.length === 2) {
      return this.intl.t('reporting.custom-bots.target-channel-selector.double-channel', {
        channelOne: this.localizedList[0],
        channelTwo: this.localizedList[1],
      });
    } else {
      return this.intl.t('reporting.custom-bots.target-channel-selector.multiple-channels', {
        channelOne: this.localizedList[0],
        remainingChannelCount: this.localizedList.length - 1,
      });
    }
  }
}
