/* import __COLOCATED_TEMPLATE__ from './team-or-teammate-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly, union } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  tagName: 'span',
  appService: service(),
  reportingService: service(),
  app: readOnly('appService.app'),
  teams: readOnly('app.teamsWithoutAppTeam'),
  teammatesAndTeams: union('rawTeammates', 'teams'),
});
