/* import __COLOCATED_TEMPLATE__ from './language.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Language extends Component {
  @service intl;

  get language() {
    let locale = this.args.translationSettings?.possibleLocales.findBy(
      'localeId',
      this.args.translation.locale,
    );
    return locale
      ? locale.name
      : this.intl.t('outbound.settings.subscription-types.language-fallback');
  }

  get isDefaultLocale() {
    return (
      this.args.translation.locale === this.args.translationSettings.defaultTranslationLocaleId
    );
  }
}
