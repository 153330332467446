/* import __COLOCATED_TEMPLATE__ from './sheet.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import { addEventListener, removeEventListener, runDisposables } from 'ember-lifeline';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class Editor extends Component {
  @tracked ruleset;
  @service seriesEditorService;
  @service contentEditorService;
  @tracked showCancelChangesConfirmation = false;
  @service appService;

  get sheetOptionClass() {
    let contentType = this.seriesEditorService.activeSeriesNode.objectTypes.firstObject;
    if (contentType === objectTypes.wait || contentType === objectTypes.tagger) {
      return 'o__small';
    }
    if (contentType === objectTypes.condition || contentType === objectTypes.splitter) {
      return 'o__medium';
    }
    if (contentType === objectTypes.tour) {
      return 'o__large';
    }
    if (contentType === objectTypes.email && this.appService.app.canUseLargerEmailPanelInSeries) {
      return 'o__extra-large';
    }
  }

  @action
  onRulesetChanged() {
    if (this.contentEditorService.ruleset.hasUnsavedChanges) {
      this.showCancelChangesConfirmation = true;
    } else {
      this.loadRuleset.perform();
    }
  }

  @action
  addClickListener() {
    next(this, () => {
      addEventListener(this, document, 'click', this.handleDocumentClick);
    });
  }

  @action
  removeClickListener() {
    removeEventListener(this, document, 'click', this.handleDocumentClick);
    runDisposables(this);
  }

  @action
  closeEditor() {
    if (this.contentEditorService.ruleset?.hasUnsavedChanges) {
      this.showCancelChangesConfirmation = true;
    } else {
      this.seriesEditorService.closeRulesetEditor();
    }
  }

  @action
  async cancelChanges() {
    await this.contentEditorService.cancelRulesetChanges.perform();
    this.seriesEditorService.closeRulesetEditor();
    this.showCancelChangesConfirmation = false;
  }

  @task
  *loadRuleset() {
    yield timeout(50);
    this.ruleset = yield this.seriesEditorService.activeSeriesNode.fetchRuleset();
    yield this.ruleset.loadDependentSeries();
  }

  handleDocumentClick(event) {
    if (this.seriesEditorService.isViewMode) {
      let blanket = document.querySelector('.side-drawer__blanket');
      if (blanket?.contains(event.target)) {
        this.closeEditor();
      }
    } else if (this.seriesEditorService.graph.element === event.target) {
      this.closeEditor();
    }
  }
}
