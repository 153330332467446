/* import __COLOCATED_TEMPLATE__ from './overview-stats-sheet.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import Component from '@glimmer/component';

export default class OverviewStatsSheet extends Component {
  @service seriesEditorService;

  get activeSeriesOverviewStat() {
    return this.seriesEditorService.activeSeriesOverviewStat;
  }

  @action
  closeSheet() {
    this.seriesEditorService.activeSeriesOverviewStat = undefined;
  }

  get activeConfig() {
    let config = this.args.activeSeriesOverviewStatConfig;
    if (isNone(config)) {
      throw new Error(
        `No configuration found to render a view for ${this.seriesEditorService.activeSeriesOverviewStat}`,
      );
    }
    return config;
  }
}
