/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { difference } from 'underscore';

export default class EditHeader extends Component {
  initialSeatTypes = [...this.args.invite.seatTypes];
  get hasUpdatedSeats() {
    return (
      difference(this.initialSeatTypes, this.args.invite.seatTypes).length !== 0 ||
      difference(this.args.invite.seatTypes, this.initialSeatTypes).length !== 0
    );
  }
}
