/* import __COLOCATED_TEMPLATE__ from './standard-header.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import type Model from '@ember-data/model';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import RouteRegexes from 'embercom/lib/route-regexes';
import { type ComponentLike } from '@glint/template';

interface Stamp {
  text: string;
  color:
    | 'gray'
    | 'green'
    | 'yellow-light'
    | 'sky'
    | 'blue'
    | 'red'
    | 'transparent'
    | 'yellow'
    | undefined;
}

export interface Breadcrumb {
  route: string;
  title: string;
  model?: Model;
  customComponent?: $TSFixMe;
}

interface Signature {
  Element: HTMLDivElement;
  Args: {
    titleToken?: string;
    shouldHideAppName?: boolean;
    newSettingHeader?: boolean;
    hideMenuToggle?: boolean;
    headingStamp?: Stamp;
    breadcrumbs?: Breadcrumb[];
    hideBottomBorder?: boolean;
    customHeaderTitle?: ComponentLike;
  };
  Blocks: {
    default: [];
  };
}

export default class StandardHeader extends Component<Signature> {
  @service declare router: RouterService;
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  get breadcrumbs() {
    return this.args.breadcrumbs ?? [];
  }

  get hideAppName() {
    let shouldHideAppNameOnHeader = this.args.shouldHideAppName ?? false;
    let isOnYourAccountSettingsRoute = RouteRegexes.accountSettings.root.test(
      this.router.currentRouteName,
    );

    return isOnYourAccountSettingsRoute || shouldHideAppNameOnHeader;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::StandardHeader': typeof StandardHeader;
    'settings/standard-header': typeof StandardHeader;
  }
}
