/* import __COLOCATED_TEMPLATE__ from './set-ticket-state.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export enum TicketState {
  submitted = 0,
  in_progress = 32,
  waiting_on_customer = 64,
  resolved = 96,
}

export const TICKET_STATES = [
  { textKey: 'inbox.ticket-state.submitted', value: TicketState.submitted },
  { textKey: 'inbox.ticket-state.in_progress', value: TicketState.in_progress },
  {
    textKey: 'inbox.ticket-state.waiting_on_customer',
    value: TicketState.waiting_on_customer,
  },
  { textKey: 'inbox.ticket-state.resolved', value: TicketState.resolved },
];

interface Signature {
  Element: HTMLElement;
  Args: {
    action: any;
    onChange?: () => void;
    isDisabled?: boolean;
    onDelete?: () => void;
  };
  Blocks: {
    default: [args: { opener: unknown; ticketState: string }];
  };
}

export default class SetTicketState extends Component<Signature> {
  @service declare intl: IntlService;

  get ticketStates() {
    return TICKET_STATES.map((ticketState) => ({
      text: this.intl.t(ticketState.textKey),
      value: ticketState.value,
    }));
  }

  get ticketStateId() {
    return this.args.action.actionData?.ticket_state_id;
  }

  get ticketState() {
    if (this.ticketStateId === undefined) {
      return '';
    }

    let matchedTicketState = this.ticketStates.find(
      (ticketState) => ticketState.value === this.ticketStateId,
    );
    return matchedTicketState?.text || '';
  }

  @action
  setTicketState(ticketState: TicketState) {
    this.args.action.actionData = { ticket_state_id: ticketState };

    if (this.args.onChange) {
      this.args.onChange();
    }
  }

  @action
  close() {
    this.args.action.justAdded = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Rules::Actions::SetTicketState': typeof SetTicketState;
  }
}
