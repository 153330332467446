/* import __COLOCATED_TEMPLATE__ from './column-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig from 'embercom/lib/reporting/flexible/view-config';
import { COUNT } from 'embercom/lib/reporting/flexible/constants';
import PALETTE from '@intercom/pulse/lib/palette';
import { SELF_SERVE_RESOLVED, SERVED_BY_HUMAN } from 'embercom/lib/reporting/csf-queries';
import { getTimeConfig } from 'embercom/lib/reporting/csf-query-helper';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { inject as service } from '@ember/service';
import countFormatter from 'embercom/lib/count-formatter';
import IntervalFormatter from 'embercom/lib/reporting/flexible/interval-formatter';
import Highcharts from 'highcharts';
import ConversationSupportFunnelTooltipTemplate from 'embercom/templates-raw/reporting/conversational-support-funnel/tooltip';

export default class PerformanceOverviewColumnChart extends Component {
  @service paywallService;
  @service intl;

  constructor() {
    super(...arguments);

    this._allowHighchartsAttributes();
  }

  _allowHighchartsAttributes() {
    // From Highcharts 9.x you need to specifically opt in any custom data- attributes
    Highcharts.AST.allowedAttributes.push('data-test-csf-tooltip');
    Highcharts.AST.allowedAttributes.push('data-test-csf-comparison-tooltip');
    Highcharts.AST.allowedAttributes.push('data-test-csf-comparison-tooltip-arrow');
  }

  get featureKey() {
    let key;
    if (
      this.paywallService.isPaywallActive({ featureName: 'help_center' }) &&
      this.paywallService.isPaywallActive({ featureName: 'answer_workflows' })
    ) {
      key = 'answer_workflows';
    }
    return key;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.valueSingle };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.legend = {
      self_serve_resolved_performance_overview: () => {
        return this.intl.t('reporting.column-chart.resolved');
      },
      human_support_performance_overview: () => {
        return this.intl.t('reporting.column-chart.supported-sessions');
      },
    };
    viewConfig.seriesColors = [PALETTE['vis-pink-50'], PALETTE['vis-yellow-50']];
    viewConfig.shouldEnableHoverState = true;
    viewConfig.columnChart = {
      series: {
        hoverColor: {
          self_serve_resolved_performance_overview: '#DC7097',
          human_support_performance_overview: '#E6C100',
        },
        tooltips: {
          self_serve_resolved_performance_overview: this.intl.t(
            'reporting.column-chart.resolved-tooltip',
          ),
          human_support_performance_overview: this.intl.t('reporting.column-chart.served-tooltip'),
        },
      },
    };

    viewConfig.tooltipFormatter = this.buildTooltipFormatter();

    return viewConfig;
  }

  get time() {
    return getTimeConfig({ range: this.args.range });
  }

  get dataConfig() {
    return {
      xAxis: {
        type: 'temporal',
        data: {
          interval: this.args.range.interval,
          property: 'interaction_created_at',
        },
      },
      series: [
        {
          name: 'self_serve_resolved_performance_overview',
          ...SELF_SERVE_RESOLVED,
          time: this.time,
        },
        {
          name: 'human_support_performance_overview',
          ...SERVED_BY_HUMAN,
          time: this.time,
        },
      ],
    };
  }

  get intervalFormatter() {
    return new IntervalFormatter(this.args.range.interval);
  }

  buildTooltipFormatter() {
    let config = this;
    return function () {
      let timezone = this.series.chart.options.time.timezone;
      let rangeText = config.intervalFormatter.datePart(this.point.name, timezone);

      if (this.series?.userOptions?.tooltipDisplayUnit) {
        let context = {
          value: countFormatter(this.point.y),
          text: this.series.userOptions.tooltipDisplayUnit,
          rangeText,
        };
        return ConversationSupportFunnelTooltipTemplate(context);
      }

      // This is necessary to override the old tooltip formatter
      return false;
    };
  }
}
