/* import __COLOCATED_TEMPLATE__ from './container.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Container extends Component {
  @service appService;

  get paywallActiveOverride() {
    return !this.appService.app?.canUseFeature('switch');
  }
}
