/* import __COLOCATED_TEMPLATE__ from './survey-nps-line-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import SerieschartBuilder from 'embercom/lib/reporting/flexible/serieschart-builder';
import ChartDataResource from 'embercom/lib/reporting/chart-data-resource';
import { useResource } from 'ember-resources';
import NpsLineChartTooltipTemplate from 'embercom/templates-raw/reporting/survey-reports/nps-line-chart-tooltip';
import IntervalFormatter from 'embercom/lib/reporting/flexible/interval-formatter';
import { inject as service } from '@ember/service';
import {
  NPS_DETRACTORS_RATING_THRESHOLD,
  NPS_PASSIVES_RATING_THRESHOLD,
  NPS_PROMOTERS_RATING_THRESHOLD,
} from 'embercom/lib/reporting/survey-helpers';

import { units } from 'embercom/lib/reporting/flexible/formatters';

export default class SurveyNpsLineChart extends Component {
  @service intl;

  dataResource = useResource(this, ChartDataResource, () => ({
    renderableChart: this.args.renderableChart,
  }));

  get chartOptions() {
    let options = {
      range: this.args.range,
      chartData: this.chartData,
      width: this.args.width,
      viewConfig: this.dataResource.viewConfig,
      dataConfig: this.dataResource.dataConfig,
      chartType: 'line',
    };

    options.viewConfig.disableLegend = true;
    options.viewConfig.tooltipFormatter = this.buildTooltipFormatter();
    options.viewConfig.yAxis = { min: -100, max: 100, tickInterval: 50 };
    options.viewConfig.formatUnit = { unit: units.value };

    let builder = new SerieschartBuilder(options);

    return builder.buildTheme();
  }

  buildTooltipFormatter() {
    let component = this;

    return function () {
      let timezone = this.series.chart.options.time.timezone;
      let rangeText = component._intervalFormatter.datePart(this.point.x, timezone);

      return NpsLineChartTooltipTemplate({
        npsScore: component.intl.t('reporting.surveys.nps-line-chart.tooltip.nps-score', {
          npsScore: Math.round(this.point.y),
        }),
        rangeText,
      });
    };
  }

  get hasData() {
    return this.dataResource.chartData.some(({ data }) => {
      return data.some(([_x, y]) => y > 0);
    });
  }

  /*
    dataResource.chartData: [
      { "name": "0", "legendLabel": "4", "data": [ [ 1648450800000, 1, 1648450800000 ] ] },
      { "name": "4", "legendLabel": "4", "data": [ [ 1648450800000, 1, 1648450800000 ] ] },
      { "name": "7", "legendLabel": "7", "data": [ [ 1648450800000, 1, 1648450800000 ] ] },
      { "name": "9", "legendLabel": "9", "data": [ [ 1648450800000, 1, 1648450800000 ] ] }
    ]

    chartData: [
      { "data": [ [ 1648450800000, -25 ] ] },
    ]
  */
  get chartData() {
    return this._convertSeriesToNpsScorePercentages();
  }

  _convertSeriesToNpsScorePercentages() {
    let npsRatingsByDate = this._groupNpsRatingsByDate();

    let data = Object.entries(npsRatingsByDate).map(([timestamp, ratingCountsByGroup]) => {
      return [Number(timestamp), this._npsScoreBy(ratingCountsByGroup)];
    });

    return [{ data }];
  }

  /*
    {
      1648450800000: {
        detractorsCount: 2,
        passivesCount: 1,
        promotersCount: 1,
      },
    }
  */
  _groupNpsRatingsByDate() {
    return this.dataResource.chartData.reduce((npsRatingsByDate, series) => {
      let subdivisionType = this._subdivisionType(Number(series.name));

      series.data.forEach((ratingData) => {
        let [timestamp, count] = ratingData;

        if (npsRatingsByDate[timestamp]) {
          let currentCount = npsRatingsByDate[timestamp][subdivisionType] || null;
          let newCount = null;
          if (currentCount || count) {
            newCount = currentCount + count;
          }
          npsRatingsByDate[timestamp][subdivisionType] = newCount;
        } else {
          npsRatingsByDate[timestamp] = { [subdivisionType]: count };
        }
      });

      return npsRatingsByDate;
    }, {});
  }

  _subdivisionType(rating) {
    if (rating <= NPS_DETRACTORS_RATING_THRESHOLD) {
      return 'detractorsCount';
    } else if (rating <= NPS_PASSIVES_RATING_THRESHOLD) {
      return 'passivesCount';
    } else if (rating <= NPS_PROMOTERS_RATING_THRESHOLD) {
      return 'promotersCount';
    }
  }

  _npsScoreBy(ratingCountsByGroup) {
    if (
      !ratingCountsByGroup.detractorsCount &&
      !ratingCountsByGroup.passivesCount &&
      !ratingCountsByGroup.promotersCount
    ) {
      return null;
    }
    let detractorsCount = ratingCountsByGroup.detractorsCount || 0;
    let passivesCount = ratingCountsByGroup.passivesCount || 0;
    let promotersCount = ratingCountsByGroup.promotersCount || 0;
    let totalNumberOfAnswers = detractorsCount + passivesCount + promotersCount;

    return ((promotersCount - detractorsCount) * 100) / totalNumberOfAnswers || 0;
  }

  get _intervalFormatter() {
    return new IntervalFormatter(this.args.range.interval);
  }
}
