/* import __COLOCATED_TEMPLATE__ from './filter-bar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { AVAILABLE_FILTER_CONFIGS } from 'embercom/lib/reporting/flexible/constants';
import { schedule } from '@ember/runloop';

const FILTERABLE_CUSTOM_ATTRIBUTE_TYPES = ['list', 'boolean'];

export default class FilterBar extends Component {
  @service appService;
  @service intl;

  @tracked addedFilterTypes = this.initialFiltersAdded;
  @tracked paywall;
  filterTypeAdded = null;
  paywallFeatureKey = this.args.paywallFeatureKey || 'reporting_drilldowns';

  get app() {
    return this.appService.app;
  }

  get customAttributeFiltersConfig() {
    if (!this.app.canUseFeature('conversation_attributes')) {
      return [];
    }

    let descriptors = this.nonTicketCvDAs || [];

    let filters = {};

    descriptors.forEach((descriptor) => {
      filters[descriptor.id] = {
        text: descriptor.name,
        selectorValue: descriptor.id,
        component: `reporting/flexible/filter-bar/custom-attributes/${descriptor.dataType}-filter`,
        isArchived: descriptor.archived,
        icon: descriptor.icon || 'transfer',
        category: descriptor.category,
      };
    });

    return filters;
  }

  get nonTicketCvDAs() {
    return this.args.conversationAttributeDescriptors
      ?.rejectBy('isTicketDescriptor')
      ?.rejectBy('hideFromReporting');
  }

  get supportedFiltersConfig() {
    let filters = { ...AVAILABLE_FILTER_CONFIGS, ...this.customAttributeFiltersConfig };

    if (!this.app.canAssignToTeamAndTeammate) {
      delete filters.teammatesAssigned;
      delete filters.teamsAssigned;
    }

    if (!this.app.canUseFeature('team_inboxes')) {
      delete filters.teamsAssigned;
      delete filters.teamsParticipated;
    }

    if (!this.appService.app.canUseTopicFilter) {
      delete filters.topics;
    }

    return filters;
  }

  // filters available for selection when user clicks on "+ Add filter" button
  get supportedFilters() {
    //ignore any non-supported filter type
    let filterTypes = this.filterTypesNotAdded.filter((filterType) =>
      Object.keys(this.supportedFiltersConfig).includes(filterType),
    );

    let configuredFilters = filterTypes
      .map((filterType) => this.supportedFiltersConfig[filterType])
      .sort((configA, configB) => {
        if (configA.isArchived && !configB.isArchived) {
          return 1;
        } else if (!configA.isArchived && configB.isArchived) {
          return -1;
        } else {
          return this.getFilterLabel(configA).localeCompare(this.getFilterLabel(configB));
        }
      });

    let filters = configuredFilters.map((config) => ({
      text: this.getFilterLabel(config),
      value: config.selectorValue,
      icon: config.icon,
      count: config.isArchived
        ? this.intl.t('reporting.flexible-filters.cvda-archived-indicator')
        : '',
      isPaywalled: config.isPaywalled,
    }));

    if (this.paywall?.isActive) {
      return this.filtersWithPaywall(filters);
    } else {
      return filters;
    }
  }

  getFilterLabel(config) {
    return config.selectorLabelIntlKey ? this.intl.t(config.selectorLabelIntlKey) : config.text;
  }

  filtersWithPaywall(filters) {
    let nonPaywalledFilters = [];
    let paywalledFilters = [];

    filters.forEach((filter) => {
      if (filter.isPaywalled) {
        paywalledFilters.push(filter);
      } else {
        nonPaywalledFilters.push(filter);
      }
    });

    return [
      ...nonPaywalledFilters,
      {
        component: 'paywalls/select-group-block',
        componentShouldReplaceItem: true,
        paywallFeatureKey: this.paywallFeatureKey,
        openUpgradeModal: this.paywall.openUpgradeModal,
        analyticsEventData: this.paywall.analyticsEventData,
        noFreeItems: !nonPaywalledFilters.length,
        paywalledItems: paywalledFilters.map((filter) => ({ isDisabled: true, ...filter })),
        value: 'paywalled',
      },
    ];
  }

  // filters added to the page
  get enabledFilters() {
    let filters = [];

    this.addedFilterTypes.forEach((filterType) => {
      let filterConfig = this.supportedFiltersConfig[filterType];
      filters.pushObject({
        component: filterConfig.component,
        type: filterType,
        isOpen: filterType === this.filterTypeAdded,
        loadKnownValuesSources: filterConfig.loadKnownValuesSources,
      });
    });
    return filters;
  }

  get customAttributeFilters() {
    return (
      this.nonTicketCvDAs?.reduce((filters, descriptor) => {
        if (FILTERABLE_CUSTOM_ATTRIBUTE_TYPES.includes(descriptor.dataType)) {
          filters[descriptor.id] =
            this.args.conversationCustomAttributes?.getAttributes(descriptor.id) || {};
        }
        return filters;
      }, {}) || []
    );
  }

  // all filters including custom attribute filters
  get allFilters() {
    return Object.assign({}, this.args.barFilters, this.customAttributeFilters);
  }

  get filterTypesNotAdded() {
    return Object.keys(this.allFilters).filter(
      (filterType) => !this.addedFilterTypes.includes(filterType),
    );
  }

  get initialFiltersAdded() {
    return Object.entries(this.allFilters)
      .filter(([_, filterValues]) => Object.keys(filterValues).length)
      .filter(([filterType, _]) => this.supportedFiltersConfig[filterType])
      .map(([filterType, _]) => filterType);
  }

  @action
  refreshAddedFilterTypes() {
    if (this.args.initialFiltersAddedDirty) {
      this.addedFilterTypes = this.initialFiltersAdded || [];
      schedule('afterRender', () => {
        this.args.initialFiltersAddedCleaned();
      });
    }
  }

  @action
  addFilter(filterType) {
    this.filterTypeAdded = filterType;
    this.addedFilterTypes.pushObject(filterType);
  }

  @action
  clearFilters() {
    this.filterTypeAdded = null;
    this.addedFilterTypes.map((filterTypeAdded) => {
      this.args.onFiltersChanged(filterTypeAdded, [], null);
    });
    this.addedFilterTypes = [];
    if (this.args.onFiltersCleared) {
      this.args.onFiltersCleared();
    }
  }

  @action
  removeFilter(index) {
    let newFilterTypes = [];
    let filterTypeToRemove;

    this.addedFilterTypes.forEach((type, i) => {
      if (index === i) {
        filterTypeToRemove = type;
      } else {
        newFilterTypes.push(type);
      }
    });

    this.filterTypeAdded = null;
    this.addedFilterTypes = newFilterTypes;
    this.args.onFiltersChanged(filterTypeToRemove, [], null);
  }

  @action
  onFiltersChanged(filterType, filterValues, operator) {
    this.filterTypeAdded = null;
    this.args.onFiltersChanged(filterType, filterValues, operator);
  }

  @action
  updatePaywall(paywall) {
    let resetFilters = false;

    this.paywall = paywall;

    // In case someone tries to apply a value to the url params for
    // a filter that is paywalled. Very unlikely but we will reset all filter
    // selection if that were to happen
    resetFilters = this.addedFilterTypes.find((filterType) => {
      let filterConfig = this.supportedFiltersConfig[filterType];

      return filterConfig.isPaywalled && this.paywall?.isActive;
    });

    if (resetFilters) {
      this.clearFilters();
    }
  }
}
