/* import __COLOCATED_TEMPLATE__ from './color-gradient.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type Gradient from 'embercom/models/customization-options/gradient';
import type GradientStep from 'embercom/models/customization-options/gradient-step';

export interface Args {
  gradient: Gradient;
  onChange?: (gradient: Gradient) => void;
}

export default class ColorGradient extends Component<Args> {
  @action
  addStep() {
    this.args.gradient.addStep();
    this.emitChange();
  }

  @action
  removeStep(index: number) {
    this.args.gradient.removeStep(index);
    this.emitChange();
  }

  @action
  setStepColor(step: GradientStep, color: string) {
    step.color = color;
    this.emitChange();
  }

  @action
  emitChange() {
    this.args.onChange?.(this.args.gradient);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Appearance::ColorGradient': typeof ColorGradient;
  }
}
