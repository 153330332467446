/* import __COLOCATED_TEMPLATE__ from './table-with-chrome.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import {
  formatFilterDisplayName,
  formatFilterDisplayValues,
  formatCustomAttributeDisplayName,
  formatCustomAttributeDisplayValues,
} from 'embercom/lib/reporting/flexible/filter-display-formatter';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ExportHelper from 'embercom/lib/reporting/export-helper';
import { DATE_WITHOUT_TIME, CSV_EMPTY_LINE } from 'embercom/lib/reporting/export-helper';
import { getOwner } from '@ember/application';
import { isPresent } from '@ember/utils';

export default class TableWithChrome extends Component {
  @service appService;
  @service csv;
  @service intl;

  @tracked tableData = [];
  @tracked isHovered = false;
  @tracked showAggregationEditor = false;

  exportHelper = new ExportHelper(getOwner(this));

  get shouldCsvButtonBeDisabled() {
    return this.tableData.length === 0;
  }

  get showStickyButtons() {
    return !this.args.renderableChart?.shouldRenderChrome;
  }
  get shouldHideButtons() {
    return this.args.renderableChart?.shouldRenderChrome && !this.isHovered;
  }

  //  -------- csv export --------
  @action
  downloadCSV() {
    if (this.tableData.length === 0) {
      return;
    }
    return this.exportCSVTask.perform();
  }

  @task
  *exportCSVTask() {
    let filename = `${this.args.csvFilenamePrefix}_${this.exportHelper.dateFormatter(
      this.args.range.startMoment,
      DATE_WITHOUT_TIME,
    )}_${this.exportHelper.dateFormatter(this.args.range.endMoment, DATE_WITHOUT_TIME)}.csv`;

    let title = [[this.args.headingText]];
    let data = title
      .concat(CSV_EMPTY_LINE)
      .concat(this.exportHelper.csvHeaders(this.args.range))
      .concat(CSV_EMPTY_LINE)
      .concat(this.csvFilters())
      .concat(CSV_EMPTY_LINE)
      .concat(this.csvTableData());
    return yield this.csv.export(data, {
      fileName: filename,
      withSeparator: false,
    });
  }

  csvFilters() {
    if (!this.args.rawFilters) {
      return [];
    }
    let title = ['Filters'];
    let formattedFilters = Object.entries(this.args.rawFilters).map(
      ([filterName, filterValues]) => {
        if (filterName === 'customAttributes') {
          return this.csvFormatCustomAttributeFilters(filterValues);
        }

        let displayFilterName = formatFilterDisplayName(filterName);
        let displayFilterValues = formatFilterDisplayValues(
          filterName,
          filterValues,
          this.appService.app,
          this.intl,
        );

        return `${displayFilterName}:${displayFilterValues.join('|')}`;
      },
    );
    return [title, formattedFilters.flat()];
  }

  csvFormatCustomAttributeFilters(filterValues) {
    let displayFilterName;
    let displayFilterValues;
    let formattedCustomAttibutes = [];
    for (let [customAttribute, customAttributeValues] of Object.entries(filterValues)) {
      let conversationAttributeDescriptor = this.args.conversationAttributeDescriptors.find(
        (descriptor) => descriptor.id === customAttribute,
      );
      displayFilterName = formatCustomAttributeDisplayName(
        customAttribute,
        conversationAttributeDescriptor,
      );
      displayFilterValues = formatCustomAttributeDisplayValues(
        customAttributeValues,
        conversationAttributeDescriptor,
      );
      formattedCustomAttibutes.push(`${displayFilterName}:${displayFilterValues.join('|')}`);
    }
    return formattedCustomAttibutes;
  }

  csvTableData() {
    let columnLabels = this.args.columns.map((column) => column.csvLabel || column.label);
    let tableData = this.tableData.map((row) => {
      let rowData = [];
      for (let column of this.args.columns) {
        let value = this.getCellValue(column, row);
        rowData.push(value);
      }
      return rowData;
    });

    return [columnLabels].concat(tableData);
  }

  getCellValue(column, row) {
    let value;
    let numerator;
    let denominator;
    if (column.aliases?.[row.groupName]) {
      value = column.aliases[row.groupName];
    } else if (column.getRowValue) {
      value = column.getRowValue(row);
      if (isPresent(column.metricId)) {
        let rowPath = row[column.valuePath];
        numerator = rowPath[`col-metric-${column.metricId}_numerator`];
        denominator = rowPath[`col-metric-${column.metricId}_denominator`];
      } else {
        numerator = row[`${column.valuePath}_numerator`];
        denominator = row[`${column.valuePath}_denominator`];
      }
    } else if (column.formatter) {
      value = column.formatter(row[column.valuePath]);
      numerator = row[`${column.valuePath}_numerator`];
      denominator = row[`${column.valuePath}_denominator`];
    } else {
      value = row[column.valuePath];
    }
    if (
      this.args.viewConfig.showRawValues &&
      isPresent(numerator) &&
      isPresent(denominator) &&
      denominator > 0
    ) {
      let formattedNumerator = numerator;
      let formattedDenominator = denominator;
      if (column.numeratorFormatter) {
        formattedNumerator = column.numeratorFormatter(numerator);
      }
      if (column.denominatorFormatter) {
        formattedDenominator = column.denominatorFormatter(denominator);
      }
      value = `${value} (${formattedNumerator}/${formattedDenominator})`;
    }
    return value || '-';
  }
  // ------------------------------

  /********************************
   * Aggregation Modal
   ********************************/

  get enableConfigurationModal() {
    let metrics = this.args.renderableChart?.chartSeries?.map((series) => series.metric) || [];
    return (
      this.args.enableConfigurationModal &&
      metrics.some((metric) => metric.supportedAggregations?.length > 1)
    );
  }

  @action
  openAggregationEditor() {
    this.showAggregationEditor = true;
  }

  @action
  closeAggregationEditor() {
    this.showAggregationEditor = false;
  }
}
