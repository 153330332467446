/* import __COLOCATED_TEMPLATE__ from './email-stats.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { objectNames, objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { createPercentBucketsForNestedKeys } from 'embercom/lib/reporting/signal-transformations';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { inject as service } from '@ember/service';
export default class EmailStats extends Component {
  @service reportingService;

  constructor() {
    super(...arguments);
  }

  get query() {
    return {
      name: 'default_query',
      debug_name: 'outbound_emails_opened',
      document_type: 'events',
      range_field: 'stat_receipt.created_at',
      aggregation_type: 'value_count',
      aggregation_field: 'event.type',
    };
  }

  get defaultFilters() {
    return {
      'event.type': this.queryKeys,
      'stat_receipt.content_type': objectNames[objectTypes.email],
    };
  }

  get failureFilters() {
    return {
      'failure_category.id': this.args.failureType,
    };
  }

  get filters() {
    return {
      ...this.defaultFilters,
      ...this.failureFilters,
    };
  }

  get groupings() {
    return [{ grouping: 'event.type', interval: 1 }];
  }

  get keys() {
    return [this.statName];
  }

  get keyColorHash() {
    return {
      stats_open: 'vis-blue-40',
      stats_email_failure: this.failureCategoryColor[this.args.failureType],
    };
  }

  get failureCategoryColor() {
    return {
      unsubscribe: 'vis-yellow-50',
      hard_bounce: 'vis-pink-50',
      spam_complaint: 'vis-slateblue-50',
    };
  }

  get transforms() {
    return [createPercentBucketsForNestedKeys(this.statName, this.queryKeys)];
  }

  get queryKeys() {
    return [`stats_${stats.receipt}`, this.statName];
  }

  get statName() {
    return `stats_${this.args.statistic}`;
  }
}
