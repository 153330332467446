/* import __COLOCATED_TEMPLATE__ from './sso-domain-whitelist.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { isEmpty } from '@ember/utils';
import { copy } from 'ember-copy';
import { isPresent } from '@ember/utils';
import { validator, buildValidations } from 'ember-cp-validations';
import { and, notEmpty } from '@ember/object/computed';

const Validations = buildValidations({
  domain: validator('domain'),
});

export default Component.extend(Validations, {
  classNames: ['f__group', 'u__padt__5'],
  domain: '',
  domainHasValue: notEmpty('domain'),
  inputNotFocused: false,
  showError: and('domainHasValue', 'validations.attrs.domain.isInvalid', 'inputNotFocused'),

  actions: {
    addDomain() {
      let domain = this.domain;
      let whitelist = copy(this.whitelist);
      if (isPresent(domain) && !whitelist.includes(domain)) {
        whitelist.push(domain);
        this.set('whitelist', whitelist);
      }
      this.set('domain', '');
    },
    deleteDomain(domain) {
      let whitelist = copy(this.whitelist);
      whitelist.removeObject(domain);
      this.set('whitelist', whitelist);
    },
    showError() {
      let domain = this.domain;
      if (isEmpty(domain)) {
        this.set('showError', false);
      } else {
        this.set('showError', this.get('validations.attrs.domain.isInvalid'));
      }
    },
  },
});
