/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { CONNECTION_POINTS } from 'embercom/services/series-editor-service';
import { NODE_CATEGORIES } from 'embercom/objects/series/node-configuration';
import { action } from '@ember/object';

export default class SeriesNodeInserterList extends Component {
  @service seriesEditorService;
  connectionPoints = CONNECTION_POINTS;

  get isOpen() {
    if (this.args.isOpen === undefined) {
      return true;
    } else {
      return this.args.isOpen;
    }
  }

  get conditionInserterConfigs() {
    return this.seriesEditorService.nodeConfigurations
      .filter((config) => config.category === NODE_CATEGORIES.condition)
      .map((config) => config.inserterConfiguration);
  }

  get contentInserterConfigs() {
    return this.seriesEditorService.nodeConfigurations
      .filter((config) => config.category === NODE_CATEGORIES.content)
      .map((config) => config.inserterConfiguration);
  }

  get actionsInserterConfigs() {
    return this.seriesEditorService.nodeConfigurations
      .filter((config) => config.category === NODE_CATEGORIES.action)
      .map((config) => config.inserterConfiguration);
  }

  @action onInsertionStart() {
    if (this.args.onInsertionStart) {
      this.args.onInsertionStart();
    }
  }

  @action onInsertionEnd() {
    if (this.args.onInsertionEnd) {
      this.args.onInsertionEnd();
    }
  }
}
