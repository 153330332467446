/* import __COLOCATED_TEMPLATE__ from './bar-group.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { fmtStyle } from '@intercom/pulse/lib/computed-properties';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import { stack } from 'd3-shape';
import { createScaledStackItem } from 'embercom/lib/reporting/visualization-helpers';

let convertArrayToKeyedHash = (list) =>
  list.reduce(
    (agg, item) => ({
      ...agg,
      [item.key]: item,
    }),
    {},
  );

let createRelativeStackItem = (start, end, maxValue) => ({
  start,
  scale: (end - start) / maxValue,
});

export default Component.extend({
  tagName: '',
  isVertical: equal('orientation', 'vertical'),
  style: fmtStyle('transform: translate3d(%@px, 0, 0) scale3d(%@, 1, 1);', 'x', 'scaleX'),
  keyedGroups: computed('group', function () {
    return this.group && this.group.length >= 0 ? convertArrayToKeyedHash(this.group) : this.group;
  }),
  layers: computed('keys', 'keyedGroups', function () {
    return stack()
      .keys(this.keys)
      .value((d, key) => (d[key] ? d[key].value || 0 : 0))([this.keyedGroups]);
  }),
  stack: computed('layers', 'keyedGroups', 'scale', function () {
    return this.layers.map((layer) => {
      let [start, end] = layer[0];

      return {
        ...this.keyedGroups[layer.key],
        key: layer.key,
        ...(this.scale
          ? createScaledStackItem(start, end, this.scale)
          : createRelativeStackItem(start, end, this.maxValue)),
      };
    });
  }),
});
