/* import __COLOCATED_TEMPLATE__ from './seat-requirement-tag.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { getRequiredSeatTypesForPermission } from 'embercom/lib/seats-permissions-map';
import {
  VBP2_SEAT_LABELS,
  PRICING_5_X_SEAT_LABELS,
  ALL_SEAT_LABELS,
} from 'embercom/lib/settings/seats/constants';
import { inject as service } from '@ember/service';

export default class SeatRequirementTag extends Component {
  @service permissionsHierarchyService;
  @service appService;

  get requiredSeatLabels() {
    let requiredSeatTypes = getRequiredSeatTypesForPermission(
      this.args.key,
      this.permissionsHierarchyService.seatPermissionMap,
    );

    if (this.appService.app.onPricing5) {
      requiredSeatTypes = requiredSeatTypes.filter((seatType) => PRICING_5_X_SEAT_LABELS[seatType]);
    } else {
      requiredSeatTypes = requiredSeatTypes.filter((seatType) => VBP2_SEAT_LABELS[seatType]);
    }

    return requiredSeatTypes.map((seatType) => ALL_SEAT_LABELS[seatType]);
  }
}
