/* import __COLOCATED_TEMPLATE__ from './busiest-period-heatmap.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  COUNT,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  FIRST_USER_CONVERSATION_PART_CREATED_AT_DAY_OF_WEEK,
} from 'embercom/lib/reporting/flexible/constants';
import { inject as service } from '@ember/service';

export default class BusiestPeriodHeatmap extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT_DAY_OF_WEEK],
        createTitleTooltipText: () =>
          this.intl.t(
            'reporting.conversations-report.busiest-period-for-new-conversations.tooltip',
          ),
      },
    };
    viewConfig.formatUnit = {
      unit: units.value,
      displayUnit: 'new conversation',
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      yAxis: {
        type: 'temporal',
        data: {
          interval: 'hour_of_day',
          property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
        },
      },
      xAxis: {
        name: 'conversation_created_at_day',
        type: 'temporal',
        data: {
          interval: 'day_of_week',
          property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
        },
      },
      series: [
        {
          name: 'conversation_count_heatmap',
          source: this.app.canUseFeature('reporting-ember-conversation-index-merging')
            ? 'conversation'
            : 'conversation_with_message_thread',
          type: COUNT,
          data: { property: FIRST_USER_CONVERSATION_PART_CREATED_AT },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
      ],
    };
  }
}
