/* import __COLOCATED_TEMPLATE__ from './inbox-seat-info.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { getInboxSeatInfo } from 'embercom/lib/admins/inbox-seat-info';
import { inject as service } from '@ember/service';

export default class InboxSeatInfo extends Component {
  @service appService;

  get numberOfSeatsRequired() {
    return this.args.numberOfSeatsRequired || 1;
  }

  get customerUsesInboxSeats() {
    return this.appService.app.activePricingMetrics.includes('inbox_seats');
  }

  @task *loadData() {
    return yield getInboxSeatInfo(this.numberOfSeatsRequired);
  }
}
