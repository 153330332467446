/* import __COLOCATED_TEMPLATE__ from './sla-archive-modal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { OUTBOUND_LIST_ROUTES } from 'embercom/models/outbound/content-wrapper';

export default class SlaArchiveModal extends Component {
  @service appService;
  @service store;
  @tracked dependentRules = [];
  @tracked dependentWorkflows = [];
  @tracked dependentCustomBots = [];
  @tracked dependentAnswers = [];
  @tracked dependentBehaviors = [];

  constructor() {
    super(...arguments);
    this.checkForDependents.perform(this.args.sla);
  }

  get app() {
    return this.appService.app;
  }

  get inboxRulesLink() {
    return 'apps.app.settings.inbox-rules.edit';
  }

  get customBotsLink() {
    return OUTBOUND_LIST_ROUTES['custom_bot'];
  }

  get dependentObjects() {
    return [
      ...this.dependentRules,
      ...this.dependentWorkflows,
      ...this.dependentCustomBots,
      ...this.dependentAnswers,
      ...this.dependentBehaviors,
    ];
  }

  get hasDependentObjects() {
    return this.dependentObjects.length > 0;
  }

  get title() {
    if (this.checkForDependents.isRunning) {
      return 'Checking dependencies for this SLA...';
    }

    if (this.hasDependentObjects) {
      return `Can't archive "${this.args.sla?.name}"`;
    }

    return `Archive "${this.args.sla?.name}"`;
  }

  @task({ drop: true })
  *checkForDependents(sla) {
    let response = yield ajax({
      url: `/ember/inbox/conversation_slas/${sla.id}/dependent_objects`,
      type: 'GET',
      data: {
        app_id: this.app.id,
      },
    });

    let {
      dependent_sla_rules,
      dependent_workflows,
      dependent_custom_bots,
      dependent_answers,
      dependent_behaviors,
    } = response;
    this.dependentRules = dependent_sla_rules === undefined ? [] : dependent_sla_rules;
    this.dependentWorkflows = dependent_workflows === undefined ? [] : dependent_workflows;
    this.dependentCustomBots = dependent_custom_bots === undefined ? [] : dependent_custom_bots;
    this.dependentAnswers = dependent_answers === undefined ? [] : dependent_answers;
    this.dependentBehaviors = dependent_behaviors === undefined ? [] : dependent_behaviors;
  }
}
