/* import __COLOCATED_TEMPLATE__ from './trigger-marketo-campaign.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { get } from 'embercom/lib/ajax';

export default class TriggerMarketoCampaign extends Component {
  @service appService;
  @service notificationsService;
  @service intercomEventService;

  @tracked activeCampaigns = [];
  @tracked campaignUrl = this.args.action.actionData?.marketo_campaign_url;

  @task({ drop: true })
  *fetchingCampaignInfo() {
    try {
      if (!this.campaignUrl) {
        this.resetCampaign();
        return;
      }

      let { marketo_campaign_url } = this.args.action.actionData || {};
      if (this.campaignUrl === marketo_campaign_url) {
        return;
      }

      let { app } = this.appService;
      let params = {
        app_id: app.id,
        campaign_url: this.campaignUrl,
      };
      return yield get('/ember/marketo_integrations/campaign', params);
    } catch (e) {
      this.resetCampaign();
      this.notificationsService.notifyError('There was a problem fetching your Marketo campaigns.');
      this.trackEvent('failed');
    }
  }

  @action
  resetCampaign() {
    this.args.action.set('actionData', {
      marketo_campaign_id: null,
      marketo_campaign_name: null,
      marketo_campaign_url: null,
    });
  }

  @action
  setCampaignUrl(e) {
    this.campaignUrl = e.currentTarget.value;
  }

  @action
  async fetchAndSaveCampaignInfo() {
    let campaign = await this.fetchingCampaignInfo.perform();
    if (!campaign) {
      return;
    }

    if (!campaign.is_active || !campaign.is_requestable) {
      this.notificationsService.notifyError(
        'The Marketo campaign is not active or not requestable via the Marketo API',
      );
      this.trackEvent('invalid');
      return;
    }

    this.trackEvent('added');

    this.args.action.set('actionData', {
      marketo_campaign_id: campaign.id,
      marketo_campaign_name: campaign.name,
      marketo_campaign_url: this.campaignUrl,
    });
  }

  @action
  onClose() {
    this.args.action.set('justAdded', false);
  }

  trackEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'marketo_campaign',
      context: 'follow_up_action',
    });
  }
}
