/* import __COLOCATED_TEMPLATE__ from './pick-conversation-data-attribute-value-overlay.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import BaseFilterBlockPopover from 'predicates/components/filter-block/base-filter-block-popover';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class PickConversationDataAttributeValueOverlay extends BaseFilterBlockPopover {
  @tracked selectedValue = this.componentAttrs.selectedValue;
  @tracked conversationDataAttribute = this.componentAttrs.conversationDataAttribute;
  @tracked onUpdate = this.componentAttrs.onUpdate;

  get type() {
    return this.conversationDataAttribute.type;
  }

  @action
  updateAndClose() {
    this.onUpdate(this.selectedValue);
    this.hideDropdownOverlay();
  }
}
