/* import __COLOCATED_TEMPLATE__ from './permissions-section-panel.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    permissionsObject: any;
    changed: () => void;
    isDisabled: boolean;
    isDisabledByRole: boolean;
    updatePermission: (key: string, value?: boolean) => void;
    unallowedPermissionList: any;
    seatDependentPermissionList: any;
    withSeats: boolean;
    onOpenSection: (sectionKey: string) => void;
  };
  Blocks: {
    default: [{ section: any }];
  };
  Element: HTMLDivElement;
}

const PermissionsSectionPanel = templateOnlyComponent<Signature>();
export default PermissionsSectionPanel;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::Permissions::PermissionsSectionPanel': typeof PermissionsSectionPanel;
    'settings/teammates/permissions/permissions-section-panel': typeof PermissionsSectionPanel;
  }
}
