/* RESPONSIBLE TEAM: team-help-desk-experience */
import { VISIBILITY_FILTER_TYPE } from './filter-types';
import { AnyVisibilityFilter } from './any-visibility-filter';
import { TeamVisibilityFilter } from './team-visibility-filter';
import { SavedReplyTypeVisibilityFilter } from './saved-reply-type-visibility-filter';

// eslint-disable-next-line @intercom/intercom/no-component-inheritance
export class VisibilityFilters {
  #visibilityFiltersMap;

  constructor(appService, owner) {
    let { ANY, SAVED_REPLY_TYPE, TEAM } = VISIBILITY_FILTER_TYPE;
    this.#visibilityFiltersMap = new Map([
      [ANY, new AnyVisibilityFilter(owner)],
      [SAVED_REPLY_TYPE, new SavedReplyTypeVisibilityFilter(appService, owner)],
      [TEAM, new TeamVisibilityFilter(appService, owner)],
    ]);
  }

  get initialVisibility() {
    let anyVisibilityFilter = this.#visibilityFiltersMap.get(VISIBILITY_FILTER_TYPE.ANY);
    return anyVisibilityFilter.anyVisibilityFilterValue;
  }

  filterSavedReplies(selectedVisibility, savedReplies) {
    let { type, value } = selectedVisibility;
    return this.#visibilityFiltersMap.get(type).filterSavedReplies(savedReplies, value);
  }

  filterLabel(selectedVisibility) {
    let { type, value } = selectedVisibility;
    return this.#visibilityFiltersMap.get(type).filterLabel(value);
  }

  get items() {
    let visibilityFilters = Array.from(this.#visibilityFiltersMap.values());
    return visibilityFilters.flatMap((visibilityFilter) => visibilityFilter.items());
  }
}
