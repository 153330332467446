/* import __COLOCATED_TEMPLATE__ from './panel.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  title: string;
  subtitle: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
}

const Panel = templateOnlyComponent<Signature>();
export default Panel;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::Panel': typeof Panel;
    'settings/data-import/modal/panel': typeof Panel;
  }
}
