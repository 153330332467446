/* import __COLOCATED_TEMPLATE__ from './chart-definition-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import type Chart from 'embercom/models/reporting/custom/chart';
import type ChartSeries from 'embercom/models/reporting/custom/chart-series';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type LogicalFilter } from 'embercom/components/reporting/custom/filters';
import type Range from 'embercom/models/reporting/range';

interface Args {
  chart: Chart;
  dateRangePrefix: string;
  range: Range;
  humanizedDynamicFilter?: string;
  humanizedReportFilters?: string;
  hasChartFilters: boolean;
  showTeamOrTeammateFilter?: boolean;
  hasReportFiltersExceptTeammateOrTeam?: boolean;
  isTableColumnHeader?: boolean;
  chartSeries: ChartSeries[];
  reportFilters: LogicalFilter;
  isBeingRenderedOnAReport: boolean;
}

export default class ChartDefinitionTooltip extends Component<Args> {
  @service declare intl: IntlService;

  get singleChartSeriesHumanizedFilters() {
    return this.firstChartSeries?.humanizedFilterText;
  }

  get firstChartSeries() {
    return this.args.chartSeries.firstObject;
  }

  hasAtLeastOneFilter(filters: LogicalFilter | null) {
    return filters?.filters && filters.filters.length > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartDefinitionTooltip': typeof ChartDefinitionTooltip;
    'reporting/custom/chart-definition-tooltip': typeof ChartDefinitionTooltip;
  }
}
