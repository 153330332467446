/* import __COLOCATED_TEMPLATE__ from './survey-nps-chart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import SerieschartBuilder from 'embercom/lib/reporting/flexible/serieschart-builder';
import ChartDataResource from 'embercom/lib/reporting/chart-data-resource';
import { useResource } from 'ember-resources';
import PALETTE from '@intercom/pulse/lib/palette';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  NPS_DETRACTORS_RATING_THRESHOLD,
  NPS_PASSIVES_RATING_THRESHOLD,
  NPS_PROMOTERS_RATING_THRESHOLD,
} from 'embercom/lib/reporting/survey-helpers';

export default class SurveyNpsChart extends Component {
  @service intl;

  dataResource = useResource(this, ChartDataResource, () => ({
    renderableChart: this.args.renderableChart,
  }));

  zipAndSum(arr1, arr2) {
    if (arr1.length === 0) {
      return arr2;
    }
    return arr1.map((elem, i) => [elem[0], elem[1] + arr2[i][1], elem[2]]);
  }

  /*
    let allSeries = [
      { name: 'Detractors', legendLabel: 'Detractors', data: [[x1, y1], [x2, y2]] },
      { name: 'Passives', legendLabel: 'Passives', data: [...] },
      { name: 'Promoters', legendLabel: 'Promoters', data: [...] },
    ];
  */
  convertSeriesToPercentages(allSeries) {
    let [detractors, passives, promoters] = allSeries;

    return allSeries.map((series) => {
      return {
        ...series,
        data: series.data.map(([x, y], index) => {
          let detractorScore = detractors.data[index]?.[1] || 0;
          let passiveScore = passives.data[index]?.[1] || 0;
          let promoterScore = promoters.data[index]?.[1] || 0;
          let yTotal = detractorScore + passiveScore + promoterScore;
          return {
            x,
            y: (y * 100) / yTotal || null, // To not show the 0 percentages on the chart
            name: x,
            value: y,
          };
        }),
      };
    });
  }

  /*
    chartData: [
      { "name": "4", "legendLabel": "4", "data": [ [ 1648450800000, 1, 1648450800000 ] ] },
      { "name": "7", "legendLabel": "7", "data": [ [ 1648450800000, 1, 1648450800000 ] ] },
      { "name": "9", "legendLabel": "9", "data": [ [ 1648450800000, 1, 1648450800000 ] ] }
    ]

    allSeries: [
      { "name": "Detractors", "legendLabel": "Detractors", "data": [ [ 1648450800000, 1, 1648450800000 ] ] },
      { "name": "Passives", "legendLabel": "Passives", "data": [ [ 1648450800000, 1, 1648450800000 ] ] },
      { "name": "Promoters", "legendLabel": "Promoters", "data": [ [ 1648450800000, 1, 1648450800000 ] ] }
    ]

    seriesInPercentages: [
      { "name": "Detractors", "legendLabel": "Detractors", "data": [ { "x": 1648450800000, "y": 33.333333333333336, "name": 1648450800000, "value": 1 } ] },
      { "name": "Passives", "legendLabel": "Passives", "data": [ { "x": 1648450800000, "y": 33.333333333333336, "name": 1648450800000, "value": 1 } ] },
      { "name": "Promoters", "legendLabel": "Promoters", "data": [ { "x": 1648450800000, "y": 33.333333333333336, "name": 1648450800000, "value": 1 } ] }
    ]
  */
  get seriesFromChartData() {
    let detractorsName = this.intl.t('reporting.surveys.detractors');
    let passivesName = this.intl.t('reporting.surveys.passives');
    let promotersName = this.intl.t('reporting.surveys.promoters');

    let allSeries = [
      { name: detractorsName, legendLabel: detractorsName, data: [] },
      { name: passivesName, legendLabel: passivesName, data: [] },
      { name: promotersName, legendLabel: promotersName, data: [] },
    ];

    let [detractors, passives, promoters] = allSeries;

    this.dataResource.chartData.forEach((series) => {
      if (Number(series.name) <= NPS_DETRACTORS_RATING_THRESHOLD) {
        detractors.data = this.zipAndSum(detractors.data, series.data);
      } else if (Number(series.name) <= NPS_PASSIVES_RATING_THRESHOLD) {
        passives.data = this.zipAndSum(passives.data, series.data);
      } else if (Number(series.name) <= NPS_PROMOTERS_RATING_THRESHOLD) {
        promoters.data = this.zipAndSum(promoters.data, series.data);
      }
    });

    return this.convertSeriesToPercentages(allSeries);
  }

  get chartOptions() {
    let options = {
      range: this.args.range,
      chartData: this.dataResource.chartData,
      width: this.args.width,
      viewConfig: this.dataResource.viewConfig,
      dataConfig: this.dataResource.dataConfig,
      seriesColors: ['vis-salmon-50', 'vis-yellow-50', 'vis-mint-50'].map(
        (color) => PALETTE[color],
      ),
    };

    options.viewConfig.colorByPoint = false;
    options.viewConfig.yAxis = { min: 0, max: 100, tickInterval: 50 };
    options.viewConfig.formatUnit = { unit: units.percent };
    options.viewConfig.showPercentages = false;
    options.viewConfig.showValues = true;

    let builder = new SerieschartBuilder(options);
    return builder.buildTheme();
  }

  get hasData() {
    return this.dataResource.chartData.some(({ data }) => {
      return data.some(([x, y]) => y > 0);
    });
  }
}
