/* import __COLOCATED_TEMPLATE__ from './sender-role-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import pluralize from 'embercom/lib/inflector';

export default class SenderRoleFilter extends Component {
  @tracked selectedSenderRoleIds = this.args.filterHash.senderRoleIds || [];
  @service appService;

  get hasSenderRoles() {
    return this.args.senderRoles.length > 0;
  }

  get senderRoles() {
    if (!this.hasSenderRoles) {
      return [
        {
          text: 'No sender roles available',
          value: 'name',
          component: 'outbound/filters/empty-tags-component',
          isDisabled: true,
        },
      ];
    }

    return this.args.senderRoles
      .map((senderRole) => {
        return {
          text: senderRole.name,
          value: senderRole.id,
          isSelected: this.selectedSenderRoleIds.includes(senderRole.id),
        };
      })
      .sortBy('text')
      .reverse()
      .sortBy('isSelected')
      .reverse();
  }

  get filterName() {
    if (this.selectedSenderRoleIds.length === 0) {
      return 'Any sender role';
    }

    let selectedSenderRoleNames = this.args.senderRoles
      .filter((senderRole) => {
        return this.selectedSenderRoleIds.includes(senderRole.id);
      })
      .map((senderRole) => {
        return senderRole.name;
      });

    if (selectedSenderRoleNames.length === 1) {
      return selectedSenderRoleNames.firstObject;
    } else {
      return `${selectedSenderRoleNames.firstObject} or ${
        selectedSenderRoleNames.length - 1
      } ${pluralize('other', selectedSenderRoleNames.length - 1, 'others')}`;
    }
  }

  @action
  updateMessagesFilter(selectedValues) {
    this.selectedSenderRoleIds = selectedValues;
    this.args.updateFilterHash('senderRoleIds', selectedValues);
  }
}
