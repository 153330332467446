/* import __COLOCATED_TEMPLATE__ from './tag-archive-modal.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class TagArchiveModal extends Component {
  @service intercomEventService;
  @service notificationsService;
  @service appService;
  @service modelDataCacheService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  @task({ drop: true })
  *archiveTag() {
    try {
      let tag = this.args.tag;
      tag.archived = true;
      yield tag.save();
      this.app.updateLocalCache(this.modelDataCacheService);
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.tag-archive-modal.archived', { name: tag.name }),
      );
      this.args.onClose({ success: true, action: 'archived' });

      this.intercomEventService.trackAnalyticsEvent({
        action: 'archived',
        object: tag,
      });
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyError(
        this.intl.t('settings.tag-archive-modal.archive-request-failed'),
      );
      this.args.onClose({ success: false });
    }
  }

  @task({ drop: true })
  *deleteTag() {
    try {
      let tag = this.args.tag;
      yield tag.destroyRecord();
      this.app.tags.removeObject(tag);
      this.app.updateLocalCache(this.modelDataCacheService);
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.tag-archive-modal.deleted', { name: tag.name }),
      );
      this.args.onClose({ success: true, action: 'deleted' });

      this.intercomEventService.trackAnalyticsEvent({
        action: 'deleted',
        object: tag,
      });
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyError(
        this.intl.t('settings.tag-archive-modal.delete-request-failed'),
      );
      this.args.onClose({ success: false });
    }
  }

  @action
  focusPrimaryButton(element) {
    let buttonElement = element.querySelector('button[class*="o__primary"]');
    if (buttonElement) {
      buttonElement.focus();
    }
  }
}
