/* import __COLOCATED_TEMPLATE__ from './notify-slack-channel.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';

export default class NotifySlackChannel extends Component {
  @service appService;
  @tracked botChannels = [];

  constructor() {
    super(...arguments);
    this.fetchingSlackChannels.perform();
  }

  get slackChannels() {
    return this.botChannels.map((channel) => ({
      text: `#${channel.name}`,
      value: channel.id,
    }));
  }

  @task({ drop: true })
  *fetchingSlackChannels() {
    let { app } = this.appService;
    let params = {
      app_id: app.id,
    };
    let channels = yield get('/ember/slack_notifications_settings', params);
    this.botChannels = channels.bot_channels;
  }

  get slackChannelId() {
    return this.args.action.actionData?.slack_channel_id;
  }

  get slackChannel() {
    return this.slackChannelName(this.slackChannelId);
  }

  slackChannelName(slack_channel_id) {
    if (slack_channel_id === undefined) {
      return '';
    }

    let matchedSlackChannel = this.slackChannels.find(
      (slackChannel) => slackChannel.value === slack_channel_id,
    );
    return matchedSlackChannel?.text || '';
  }

  @action setSlackChannelId(slackChannel) {
    this.args.action.actionData = {
      slack_channel_id: slackChannel,
      slack_channel_name: this.slackChannelName(slackChannel),
    };

    if (this.args.onChange) {
      this.args.onChange();
    }
  }

  @action updateValues(slack_channel_id) {
    this.args.action.actionData = { slack_channel_id };
  }

  @action close() {
    this.args.action.justAdded = false;
  }
}
