/* import __COLOCATED_TEMPLATE__ from './engagement-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { makeStatsParametersForArticle } from 'embercom/helpers/stats-system/stats-parameters';
import Range from 'embercom/models/reporting/range';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import moment from 'moment-timezone';

export default class EngagementDrawer extends Component {
  articleData = trackedTask(this, this.retrieveStatsFromArticle, () => [
    this.args.selectedArticle,
    this.args.showEngagementOverlay,
  ]);

  @tracked tabToDisplay = 'views';
  @tracked reaction;
  @tracked range = Range.createFromParams(
    moment(this.args.range.startMoment).format(),
    moment(this.args.range.endMoment).format(),
    this.app.timezone,
  );

  @service notificationsService;
  @service intl;
  @service appService;

  @action
  onCancel() {
    this.selectedArticle = null;
    this.args.onClose();
  }

  @action
  changeView(tab) {
    this.tabToDisplay = tab;
  }

  @action changeReactionFilter(_, value) {
    this.reaction = value;
  }

  @action onUpdateDateRange(range) {
    this.range = range;
  }

  get app() {
    return this.appService.app;
  }

  get statsParameters() {
    return makeStatsParametersForArticle(this.args.selectedArticle, this.range, this.app.timezone);
  }

  get reactionFilter() {
    return this.reaction;
  }

  @task
  *retrieveStatsFromArticle() {
    try {
      return yield this.args.selectedArticle.loadStats.perform();
    } catch (exception) {
      this.notificationsService.notifyError(
        this.intl.t('reporting.articles.engagement.drawer.error'),
      );
      this.args.onClose();
    }
  }
}
