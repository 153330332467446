/* import __COLOCATED_TEMPLATE__ from './human-support-section.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class HumanSupportSection extends Component {
  @service paywallService;

  get featureKey() {
    let key = 'inbox';
    if (this.args.isSelfServeFailed) {
      if (this.restrictedBy('answer_workflows')) {
        key = 'answer_workflows';
      } else if (this.restrictedBy('help_center')) {
        key = 'help_center';
      }
    }
    return key;
  }

  get isDisabled() {
    let restricted = this.restrictedBy('inbox');
    if (!restricted && this.args.isSelfServeFailed) {
      if (this.restrictedBy('answer_workflows') && this.restrictedBy('help_center')) {
        restricted = true;
      }
    }
    return restricted;
  }

  restrictedBy(featureName) {
    return this.paywallService.isPaywallActive({ featureName });
  }
}
