/* import __COLOCATED_TEMPLATE__ from './roles.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';

export default class Roles extends Component {
  @service router;
  @service permissionsService;
  @service appService;

  @task({ keepLatest: true })
  *transitionToTeamsIfPermitted(value) {
    try {
      yield this.permissionsService.checkPermission('can_manage_teams');
      this.router.transitionTo('apps.app.settings.helpdesk.teams', {
        queryParams: {
          tab: value,
        },
      });
      //eslint-disable-next-line no-empty
    } catch (err) {}
  }
}
