/* import __COLOCATED_TEMPLATE__ from './sla-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';

export default class SlaFilter extends Component {
  @service appService;
  @service intercomEventService;
  @service store;
  @tracked slas = [];
  @tracked selectedSlaId = this.args.selected[0];

  constructor() {
    super(...arguments);
    this.loadKnownValues.perform();
  }

  @task
  *loadKnownValues() {
    this.slas = yield this.store.findAll('inbox/conversation-sla');
    // This is necessary to ensure the default item is selected correctly
    this.args.setSelected(this.selectedSla ? [this.selectedSla] : []);
  }

  get selectedSla() {
    let selectedExistingSlaId = this._findSla(this.args.selected[0])?.id;
    return selectedExistingSlaId || this.slas.firstObject?.id;
  }

  _findSla(id) {
    return this.slas.find((sla) => sla.id === this.args.selected[0]);
  }

  get items() {
    return this.slas.map((sla) => ({
      text: sla.name,
      value: sla.id,
      isSelected: this.args.selected.includes(sla.id),
      count: sla.archived ? '(Archived)' : '',
    }));
  }

  get currentLabel() {
    let currentSla = this._findSla(this.selectedSla);

    if (currentSla) {
      return currentSla.archived ? `${currentSla.name} (Archived)` : `${currentSla.name}`;
    } else {
      return '';
    }
  }

  @action
  onClose() {
    if (!this.selectedSlaId) {
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_sla',
      object: this.args.analyticsObject,
    });

    this.args.setSelected(this.selectedSlaId ? [this.selectedSlaId] : []);
  }

  @action
  removeFilter() {
    this.selectedSlaId = null;
    this.args.removeFilter();
  }
}
