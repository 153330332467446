/* import __COLOCATED_TEMPLATE__ from './most-sent-rulesets.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import {
  REPORTING_RANGE_UPDATED,
  CONTENT_TYPE_IDS_UPDATED,
} from 'embercom/services/reporting-service';
import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';
import { isEmpty } from '@ember/utils';

const PER_PAGE = 10;

export default class MostSentRulesets extends Component {
  @service appService;
  @service outboundHomeService;
  @service reportingService;

  @tracked data = [];
  @tracked numberOfVisibleRows = PER_PAGE;

  statisticKeys = statisticKeys;

  constructor() {
    super(...arguments);

    let fetchData = () => this.getSortedData.perform();
    this.reportingService.on(REPORTING_RANGE_UPDATED, fetchData);
    this.reportingService.on(CONTENT_TYPE_IDS_UPDATED, fetchData);

    registerDestructor(this, () => {
      this.getSortedData.cancelAll();
      this.reportingService.off(REPORTING_RANGE_UPDATED, fetchData);
      this.reportingService.on(CONTENT_TYPE_IDS_UPDATED, fetchData);
    });
  }

  get app() {
    return this.appService.app;
  }

  get visibleRows() {
    return this.data.slice(0, this.numberOfVisibleRows);
  }

  @action
  showMore() {
    this.numberOfVisibleRows += PER_PAGE;
  }

  @task
  *getSortedData() {
    let rulesets = yield this.outboundHomeService.getMostSentRulesets({
      app_id: this.app.id,
      range_start: this.args.range.start,
      range_end: this.args.range.end,
      object_types: isEmpty(this.args.selectedContentTypeIds)
        ? this.outboundHomeService.allObjectTypes
        : this.args.selectedContentTypeIds,
    });

    this.data = rulesets
      .map((ruleset) => {
        let count = 0;
        ruleset.contents.forEach((content) => {
          let receipt_count = content.stats.find(
            (stat) => stat.key === this.statisticKeys.receipts,
          )?.value;
          count += receipt_count;
        });

        return { count, ruleset };
      })
      .sortBy('count')
      .reverse()
      .map((object) => {
        return object.ruleset;
      });
  }
}
