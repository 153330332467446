/* import __COLOCATED_TEMPLATE__ from './team-delete-modal.hbs'; */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-actions-hash */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import { computed } from '@ember/object';
import { alias, filter, filterBy, map, notEmpty, setDiff } from '@ember/object/computed';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { rejectByProperty } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  tagName: '',
  appService: service(),
  intl: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  app: alias('appService.app'),
  adminToReassign: null,
  notificationsService: service(),
  modelDataCacheService: service(),
  selectedAssignee: null,
  selectedTeam: alias('model'),
  assigneeHasBeenSelected: notEmpty('selectedAssignee'),
  _teamItems: filter('assignableTeamsExcludingTeamsWithOnlyAdminToBeReassigned', function (team) {
    return team.get('id') !== this.get('selectedTeam.id');
  }),
  teamItems: map('_teamItems', function (team) {
    return this.buildItem(team);
  }),
  sendersWithoutAdminToReassign: rejectByProperty('app.humanAdmins', 'id', 'adminToReassign.id'),
  assignableAdminsExcludingAdminToReassign: computed(
    'app.assignableAdmins.@each.{id,name}',
    'adminToReassign.id',
    function () {
      let adminToReassignId = this.get('adminToReassign.id');
      return this.get('app.assignableAdmins').reject((admin) => {
        return admin.get('id') === adminToReassignId || admin.get('name') === 'Unassigned';
      });
    },
  ),
  teamsWithOneAdmin: filterBy('assignableTeams', 'teamMemberCount', 1),
  teamsWithOnlyAdminToBeReassigned: computed(
    'teamsWithOneAdmin.[]',
    'teamsWithOneAdmin.@each.firstTeamMemberId',
    'adminToReassign.id',
    function () {
      let adminToReassignId = this.get('adminToReassign.id');
      return this.teamsWithOneAdmin.filter(
        (team) => team.get('firstTeamMemberId').toString() === adminToReassignId,
      );
    },
  ),
  assignableTeammates: filterBy('assignableAdminsExcludingAdminToReassign', 'isTeam', false),
  assignableTeams: setDiff('assignableAdminsExcludingAdminToReassign', 'assignableTeammates'),
  assignableTeamsExcludingTeamsWithOnlyAdminToBeReassigned: setDiff(
    'assignableTeams',
    'teamsWithOnlyAdminToBeReassigned',
  ),
  teammateItems: map('assignableTeammates', function (teammate) {
    return this.buildItem(teammate);
  }),
  teamsAndTeammatesItems: computed('teamItems', 'teammateItems', 'intl.locale', function () {
    return [
      {
        hasItemsMarkableAsSelected: true,
        heading: this.intl.t(
          'controllers.apps.app.settings.modals.reassign-messages-base.headings.teammates',
        ),
        items: this.teammateItems,
      },
      {
        hasItemsMarkableAsSelected: true,
        heading: this.intl.t(
          'controllers.apps.app.settings.modals.reassign-messages-base.headings.teams',
        ),
        items: this.teamItems,
      },
    ];
  }),
  sendersWithoutAdminToReassignItems: map('sendersWithoutAdminToReassign', function (sender) {
    return this.buildItem(sender);
  }),
  buildItem(teamOrTeammate) {
    return {
      text: teamOrTeammate.get('name'),
      value: teamOrTeammate,
      component: 'settings/teams/team-or-teammate-dropdown-item',
      componentAttrs: teamOrTeammate,
    };
  },
  actions: {
    closeTeamDeleteModal() {
      this.modalService.closeModal();
    },
    deleteTeam() {
      let team = this.model;
      team
        .deleteAndReassign(this.get('selectedAssignee.id'))
        .then(() => {
          this.app.removeTeam(team);
          this.app.updateLocalCache(this.modelDataCacheService);
          this.notificationsService.notifyConfirmation(`${team.get('name')} was deleted`);
          this.modalService.closeModal();
        })
        .catch((error) => {
          if (error.jqXHR.status === 403) {
            this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
          } else {
            this.notificationsService.notifyError('Something went wrong. Please try again');
          }
        });
    },
    selectAssignee(teammateOrTeam) {
      this.set('selectedAssignee', teammateOrTeam);
    },
  },
});
