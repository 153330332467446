/* import __COLOCATED_TEMPLATE__ from './monitor.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { get } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { getDefaultRange } from './monitor/filters/date-range-filter';

export default class SwitchMonitor extends Component {
  @service store;
  @service appService;
  @service intercomEventService;
  @service intl;

  // Table
  @tracked hasInitiallyLoaded = false;
  @tracked logs = [];
  @tracked page = 1;
  @tracked totalPages = 1;
  @tracked count;

  // Sorting
  @tracked sortState = {
    valuePath: 'created_at',
    direction: 'desc',
    sortKey: 'created_at',
  };

  // Filters
  @tracked filterDateRange;
  @tracked filterStatus;

  // Conversation View
  @tracked showSideDrawer = false;
  @tracked expandedRecord;

  constructor() {
    super(...arguments);
    this.filterDateRange = getDefaultRange();
    this.loader.perform();
  }

  @task({ drop: true })
  *loader() {
    let sortedParams = {
      sort_by: this.sortState.valuePath,
      order: this.sortState.direction,
    };
    let dateRangeFilterParams = this.filterDateRange
      ? {
          range_start: this.filterDateRange.start,
          range_end: this.filterDateRange.end,
        }
      : {};
    let statusFilterParams = this.filterStatus ? { status: this.filterStatus } : {};

    let data = yield get(`/ember/switch/logs/${this.app.id}`, {
      app_id: this.app.id,
      page: this.page,
      per_page: 10,
      ...sortedParams,
      ...dateRangeFilterParams,
      ...statusFilterParams,
    });

    if (!this.hasInitiallyLoaded && data.logs.length > 0) {
      this.hasInitiallyLoaded = true;
    }

    this.logs = this.logs.concat(data.logs);
    this.totalPages = data.meta.logs.page_count;
    this.count = data.meta.logs.count;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'open_table',
      context: 'switch_monitor_page',
      page: this.page,
      total_pages: this.totalPages,
      count: this.count,
      ...sortedParams,
      ...dateRangeFilterParams,
      ...statusFilterParams,
    });
  }

  // MARK: Helper Utils

  get isLoading() {
    return this.loader.isRunning && this.data.length === 0;
  }

  get shouldShowNoResults() {
    return !this.loader.isRunning && !this.hasInitiallyLoaded;
  }

  get shouldShowTable() {
    return this.loader.isRunning || this.hasInitiallyLoaded;
  }

  get app() {
    return this.appService.app;
  }

  @action
  resetAndReloadTable() {
    this.page = 1;
    this.logs = [];
    this.loader.perform();
  }

  // MARK: Table

  get columns() {
    return [
      { label: this.intl.t('components.settings.switch.caller-label'), valuePath: 'user.name' },
      {
        label: this.intl.t('components.settings.switch.call-date'),
        valuePath: 'created_at',
        isSortable: true,
      },
      { label: this.intl.t('components.settings.switch.status'), valuePath: 'status' },
    ];
  }

  get data() {
    return this.logs;
  }

  // MARK: Sorting

  @action
  onSort(_valuePath) {
    let { valuePath, direction } = this.sortState;
    let _direction = valuePath === _valuePath && direction === 'asc' ? 'desc' : 'asc';

    this.sortState = {
      valuePath: _valuePath,
      direction: _direction,
    };
    this.resetAndReloadTable();
  }

  @action
  resetSorting() {
    this.sortState = {
      valuePath: 'created_at',
      direction: 'desc',
      sortKey: 'created_at',
    };
  }

  // MARK: Filtering

  @action
  onDateRangeFilterChange(dateRange) {
    this.filterDateRange = dateRange;
    this.resetSorting();
    this.resetAndReloadTable();
  }

  @action
  onStatusFilterChange(status) {
    this.filterStatus = status.length > 0 ? status : null;
    this.resetSorting();
    this.resetAndReloadTable();
  }

  // MARK: Pagination Logic

  get canLoadMore() {
    return this.page < this.totalPages;
  }

  @action
  onLoadMore() {
    this.page += 1;
    this.loader.perform();
  }

  // MARK: Conversation view

  get conversationId() {
    return this.expandedRecord && this.expandedRecord.conversation_id;
  }

  @task({ drop: true })
  *fetchConversationIds() {
    return yield [this.conversationId];
  }

  @action
  openConversation(record) {
    this.showSideDrawer = true;
    this.expandedRecord = record;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'opened_expanded_conversation',
      context: 'switch_monitor_page',
      conversation_id: this.conversationId,
    });
  }

  @action
  closeConversation() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'closed_expanded_conversation',
      context: 'switch_monitor_page',
      conversation_id: this.conversationId,
    });

    this.showSideDrawer = false;
    this.expandedRecord = null;
  }
}
