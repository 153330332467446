/* import __COLOCATED_TEMPLATE__ from './ticket-custom-state-filter.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, restartableTask } from 'ember-concurrency-decorators';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import type IntlService from 'ember-intl/services/intl';
import type { SelectedFilter } from 'embercom/services/filter-pattern-service';
import type TicketStateService from 'embercom/services/ticket-state-service';
import { taskFor } from 'ember-concurrency-ts';
import { get } from 'embercom/lib/ajax';
import { all, type TaskGenerator } from 'ember-concurrency';

interface Args {
  icon: string;
  name: string;
  initiallyOpened: boolean;
  removeFilter: () => void;
  setSelected: (filterValues: string[], operator: string) => void;
  selected: SelectedFilter;
  type: string;
  analyticsObject: any;
  isDisabled: boolean;
  loadKnownValuesSources: string[];
}

export default class TicketCustomStateFilter extends Component<Args> {
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare ticketStateService: TicketStateService;

  @tracked availableCustomStates: string[] = [];
  @tracked selectedDropdownItems = this.args.selected.values || [];
  @tracked operator = this.args.selected.operator || 'category';

  @action
  ticketStateLabel(value: string) {
    return this.ticketStateService.getTicketCustomStateById(parseInt(value, 10)).adminLabel;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadKnownCustomStates).perform();
  }

  getTicketSystemState(ticketCustomStateId: string) {
    return this.ticketStateService.getTicketCustomStateById(parseInt(ticketCustomStateId, 10))
      .systemState;
  }

  groupCustomStatesBySystemStates(ticketCustomStates: any) {
    let customStatesGroupedBySystemState: { [key: string]: Array<string> } =
      ticketCustomStates.reduce((groups: { [key: string]: Array<string> }, item: string) => {
        let state: string = this.getTicketSystemState(item);
        if (!groups[state]) {
          groups[state] = [];
        }
        groups[state].push(item);
        return groups;
      }, {});

    return customStatesGroupedBySystemState;
  }

  get isLoadingCustomStates() {
    return taskFor(this.loadKnownCustomStates).isRunning;
  }

  get selectedStates() {
    return this.selectedDropdownItems.filter(
      (item: string) => item !== REPORTING_FILTER_SELECT_ALL,
    );
  }
  _customStateEntry(value: string, systemState: string) {
    return {
      text: this.ticketStateLabel(value),
      value,
      isSelected: this.selectedStates.includes(value),
      component: 'reporting/flexible/filter-bar/ticket-custom-state-item',
      systemState,
      'data-test-item': value,
    };
  }

  get allItems() {
    let groupedCustomStates = this.groupCustomStatesBySystemStates(this.availableCustomStates);

    let items: any[] = [];

    Object.entries(groupedCustomStates).forEach(([systemState, customStates]) => {
      items.push({
        heading: this.intl.t(`inbox.ticket-state.${systemState}`),
        'data-test-item': `heading-${systemState}`,
        items: customStates.map((value: string) => this._customStateEntry(value, systemState)),
      });
    });

    return items;
  }

  get currentLabel() {
    if (
      this.selectedDropdownItems?.length &&
      this.selectedDropdownItems[0] !== REPORTING_FILTER_SELECT_ALL
    ) {
      return this.selectedDropdownItems
        .map((customStateId: string) => this.ticketStateLabel(customStateId))
        .join(', ');
    } else {
      return this.intl.t('reporting.custom-reports.any');
    }
  }

  @restartableTask
  *loadKnownCustomStates() {
    // Load known ticket custom states values for both ticket_custom_state_id and current_ticket_custom_state_id
    // Values from both fields should be available in the dropdown
    let sources = this.args.loadKnownValuesSources || ['ticket_time_in_state'];

    let ticketCustomStateIds = sources.map((source) =>
      taskFor(this.fetchData).perform(source, 'ticket_custom_state_id'),
    );

    let currentTicketCustomStateIds = sources.map((source) =>
      taskFor(this.fetchData).perform(source, 'current_ticket_custom_state_id'),
    );

    let responses: string[][] = yield all([
      ...ticketCustomStateIds,
      ...currentTicketCustomStateIds,
    ]);

    this.availableCustomStates = responses.flat().uniq();
  }

  @task({ enqueue: true })
  *fetchData(source: string, property: string): TaskGenerator<string[]> {
    return yield get('/ember/reporting/known_values', {
      app_id: this.appService.app.id,
      source,
      property,
    });
  }

  @action
  trackAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: `filtered_${this.args.type}`,
      object: this.args.analyticsObject,
      state: this.selectedStates,
      operator: this.operator,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ChartBuilder::FilterBar::TicketCustomStateFilter': typeof TicketCustomStateFilter;
  }
}
