/* import __COLOCATED_TEMPLATE__ from './sales-performance-charts.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  store: service(),
  hasMeetingBooked: true,

  init() {
    this._super(...arguments);
    this.setHasMeetingBooked.perform();
  },

  setHasMeetingBooked: task(function* () {
    let settings = yield this.store.findRecord('appstore/app-package', 'google-calendar-meetings');
    this.set('hasMeetingBooked', settings.get('isInstalled'));
  }).drop(),
});
