/* import __COLOCATED_TEMPLATE__ from './table-sla-miss-cell.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import percent, { toPercentString } from 'embercom/lib/percentage-formatter';
import { isPresent } from '@ember/utils';

export default class SLAMissCellComponent extends Component {
  total;
  value;

  constructor() {
    super(...arguments);
    ({ total: this.total, value: this.value } = this.args.row['col-group-sla'] || {});
  }

  get hasValue() {
    return isPresent(this.total) && this.total > 0;
  }

  get percentage() {
    let rawPercentage = percent(this.total, this.valueWithFallback);
    return toPercentString(rawPercentage, 2);
  }

  get valueWithFallback() {
    return this.value || 0;
  }
}
