/* import __COLOCATED_TEMPLATE__ from './company-email-address-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { or } from '@ember/object/computed';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';

export default Component.extend({
  notificationsService: service(),
  intl: service(),
  intercomConfirmService: service(),
  intercomEventService: service(),

  get addressSourceTooltip() {
    return {
      forwarding_email_processor: this.intl.t(
        'settings.senders.comapny-email-address-component.email-detected',
      ),
    };
  },

  tagName: 'tr',
  isDeletingOrNew: or('destroyEmailAddressTask.isRunning', 'companyEmailAddress.isNew'),
  tooltipText: computed('addressSourceTooltip', 'companyEmailAddress.source', function () {
    return this.addressSourceTooltip[this.get('companyEmailAddress.source')];
  }),
  destroyEmailAddressTask: task(function* () {
    try {
      yield this.companyEmailAddress.destroyRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.senders.comapny-email-address-component.deleted-address', {
          address: this.get('companyEmailAddress.email_address'),
        }),
      );
      this.trackAnalyticsEvent('success');
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('settings.senders.comapny-email-address-component.email-not-deleted'),
      );
      this.trackAnalyticsEvent('failed');
    }
  }),
  _openConfirm(options) {
    return this.intercomConfirmService.confirm(options);
  },
  trackAnalyticsEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'delete_company_email_address',
      context: 'company_address_to_ignore_in_group_conversation',
      place: 'settings_channels_email',
    });
  },
  actions: {
    async deleteCompanyEmailAddress() {
      let companyEmailAddress = this.companyEmailAddress;
      let isConfirmed = await this._openConfirm({
        title: this.intl.t('settings.senders.comapny-email-address-component.delete-address'),
        body: this.intl.t('settings.senders.comapny-email-address-component.confirmation', {
          address: companyEmailAddress.get('email_address'),
        }),
        confirmButtonText: this.intl.t(
          'settings.senders.comapny-email-address-component.confirmation-button',
        ),
        primaryButtonType: 'primary-destructive',
      });
      if (!isConfirmed) {
        this.trackAnalyticsEvent('canceled');
        return;
      }
      this.trackAnalyticsEvent('confirmed');
      this.destroyEmailAddressTask.perform();
    },
  },
});
