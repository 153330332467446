/* import __COLOCATED_TEMPLATE__ from './conversations-preview.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import User from 'embercom/models/user';
import ajax from 'embercom/lib/ajax';
import Admin from 'embercom/models/admin';
import Velocity from 'velocity';

const PAGE_SIZE = 20;

export default class TopicConversationsPreviewComponent extends Component {
  @service userCacheService;
  @service appService;
  @service store;
  @service intercomEventService;
  @service intl;

  @tracked conversations = [];
  @tracked users = {};
  @tracked admins = {};
  @tracked canLoadMore = false;
  @tracked expandedConversation;
  @tracked expandingConversationId;
  @tracked chosenSortValue = 'newest';

  get sortItems() {
    let sortItems = [
      {
        text: this.intl.t(
          'reporting.conversational-insights.conversation-preview.sort-items.oldest',
        ),
        value: 'oldest',
      },
      {
        text: this.intl.t(
          'reporting.conversational-insights.conversation-preview.sort-items.newest',
        ),
        value: 'newest',
      },
      {
        text: this.intl.t(
          'reporting.conversational-insights.conversation-preview.sort-items.manually-added-first',
        ),
        value: 'overrides_then_newest',
      },
    ];

    return sortItems;
  }

  get currentSortLabel() {
    return this.sortItems.find((item) => item.value === this.chosenSortValue).text;
  }

  @action
  changeSortSelection(sortValue) {
    this.chosenSortValue = sortValue;
  }

  @task
  *loadConversationsFromStart() {
    this.conversations = [];
    this.lastLoadedPage = -1;
    this.canLoadMore = true;
    yield this.loadMoreConversations.linked().perform();
  }

  @task
  *loadMoreConversations() {
    if (!this.args.topic.keyphrases?.length) {
      this.canLoadMore = false;
      return;
    }

    if (!this.canLoadMore) {
      return;
    }

    let page = this.lastLoadedPage + 1;
    let loadedConversations = yield this.loadPage.linked().perform(page);
    this.lastLoadedPage = page;

    if (loadedConversations.length < PAGE_SIZE) {
      this.canLoadMore = false;
    }
    this.conversations = this.conversations.concat(loadedConversations);
  }

  @task
  *loadPage(page) {
    let { conversations } = yield this.loadConversationPreview.perform(page);
    let userIds = conversations.flatMap((c) => c.user_ids);
    yield this.loadMissingUsers.perform(userIds);
    yield this.loadMissingAdmins.perform(conversations);
    return this.prepareConversations(conversations);
  }

  @task({ keepLatest: true, maxConcurrency: 1 })
  *loadConversationPreview(page) {
    let range = this.args.range;
    let response = yield ajax({
      url: '/ember/conversational_insights/conversation_topics/conversation_preview',
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        range_start: range?.start,
        range_end: range?.end,
        keyphrases: this.args.topic.keyphrases,
        excluded_keyphrases: this.args.topic.excludedKeyphrases,
        conversation_custom_data: this.args.topic.conversationCustomData,
        author_types: this.args.topic.authorTypes,
        sort_mode: this.chosenSortValue,
        topic_id: this.args.topic.id,
        page_size: PAGE_SIZE,
        page,
      }),
    });
    return response.conversation_preview;
  }

  @task({ enqueue: true })
  *loadMissingUsers(userIds) {
    let missingUserIds = userIds.filter((id) => !this.users[id]);
    let predicates = [{ attribute: '_id', type: 'id', comparison: 'in', value: missingUserIds }];
    let usersResponse = yield User.search(this.appService.app, predicates, {});
    usersResponse.users.forEach((u) => (this.users[u.id] = u));
  }

  @task({ enqueue: true })
  *loadMissingAdmins(conversations) {
    let adminIdsArray = conversations
      .map((conversation) => conversation.matching_parts?.firstObject?.admin_id)
      .filter((adminId) => adminId);
    let adminIds = new Set(adminIdsArray);
    let admins = [...adminIds].reduce(
      (acc, adminId) =>
        Object.assign(acc, { [adminId]: Admin.peekAndMaybeLoad(this.store, adminId) }),
      {},
    );
    this.admins = yield hash(admins);
  }

  prepareConversations(conversations) {
    return conversations.map((conversation) => {
      let partToShow = conversation.matching_parts.firstObject || conversation.first_part;
      let partUser = partToShow.user_id ? this.users[partToShow.user_id] : null;
      let partAdmin = partToShow.admin_id ? this.admins[partToShow.admin_id] : null;
      let user = conversation.user_ids?.length
        ? this.users[conversation.user_ids.firstObject]
        : null;
      return {
        ...conversation,
        partToShow,
        partAuthor: partUser || partAdmin,
        user,
      };
    });
  }

  get conversationCount() {
    return this.conversations?.length || 0;
  }

  highlightFirstMatchingPart(conversationId) {
    let conversation = this.conversations.find((c) => c.id === conversationId);
    if (!conversation.matching_parts.length) {
      return;
    }
    // ConversationCard has a weird interface that goes through the URL instead of
    // component params.
    window.location.hash = `#part_id=${conversation.matching_parts[0].part_id}`;
  }

  @task({ drop: true })
  *expandConversation(conversationId) {
    this.highlightFirstMatchingPart(conversationId);
    yield this.collapseConversation.perform();
    this.expandingConversationId = conversationId;
    if (this.expandedConversation?.id !== conversationId.toString()) {
      this.expandedConversation = yield this.store.findRecord('conversation', conversationId);
    }
    this.trackConversationExpanded(conversationId);
  }

  trackConversationExpanded(conversationId) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      place: 'topics',
      object: 'conversation',
      conversation_id: conversationId,
      app_id: this.appService.app.id,
      topic_id: this.args.topic.id,
      admin_id: this.appService.app.currentAdmin.id,
    });
  }

  @task
  *collapseConversation() {
    let contentExpanded = document.querySelector('[data-conversation-card-content-expanded]');
    if (contentExpanded) {
      yield Velocity(contentExpanded, 'stop');
      yield Velocity(contentExpanded, { maxHeight: 64, opacity: 0 }, { duration: 300 });
    }
    this.expandingConversationId = undefined;
  }

  @action
  removeConversation(conversationId) {
    this.args.removedConversationIds.push(conversationId);
    this.conversations = this.conversations.filter(
      (conversation) => conversation.id !== conversationId,
    );
  }
}
