/* import __COLOCATED_TEMPLATE__ from './has-calls-filter.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';

export default class HasCallsFilter extends Component {
  @service store;
  @service intercomEventService;
  @service intl;

  @tracked label = this.currentLabel;
  @tracked selected = this.startingSelectedValue;

  get valueLabelMapping() {
    return {
      true: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.has-calls-filter.with-calls',
      ),
      false: this.intl.t(
        'components.reporting.custom.chart-builder.filter-bar.has-calls-filter.without-calls',
      ),
    };
  }

  get startingSelectedValue() {
    let passedValue = this.args.selected?.values?.[0];

    if (isPresent(passedValue)) {
      return passedValue;
    }

    return true;
  }

  get currentLabel() {
    return this.valueLabelMapping[this.selected] || '';
  }

  get values() {
    return [this.selected];
  }

  @action
  onClose() {
    this.args.setSelected(this.values);
    this.label = this.currentLabel;
  }

  @action
  removeFilter() {
    this.selected = null;
    this.args.removeFilter();
  }
}
