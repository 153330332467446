/* import __COLOCATED_TEMPLATE__ from './talk-to-team.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';

interface Args {
  customBotTarget: string;
  text: string;
  ctaText: string;
  leftAligned: boolean;
  allCentered: boolean;
  withAvatars: boolean;
  withMessengerIcon: boolean;
  triggerAnalytics: () => void;
}

interface Signature {
  Args: Args;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class TalkToTeam extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sales::TalkToTeam': typeof TalkToTeam;
  }
}
