/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-new-mixins */
import { lt, lte } from '@ember/object/computed';
import { isArray } from '@ember/array';
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear, scaleBand } from 'd3-scale';
import moment from 'moment-timezone';
import { formatMinutes } from 'embercom/lib/duration-formatter';
import { makeDateFormatter } from 'embercom/lib/reporting/date-formatter';
import { unitFormatter } from 'embercom/helpers/unit-formatter';

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;

export default Mixin.create({
  intl: service(),
  drawAxes() {
    let chart = this.chart();
    chart
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0, ${this.chartHeight})`);

    chart.append('g').attr('class', 'y axis');
  },
  redrawAxes() {
    this._redrawXAxis();
    this._redrawYAxisAndGridlines();
  },
  dates: computed('data', function () {
    // Use date from signal if it exists, otherwise use date range from date picker (signal might be empty)
    return this.datesFromSignal.length > 0 ? this.datesFromSignal : this.datesFromRangePicker;
  }),
  datesFromSignal: computed('data', function () {
    // TODO We're assuming here that data.firstObject has a full set of dates, but this will not always be the case - LC
    let data = isArray(this.get('data.firstObject.value'))
      ? this.get('data.firstObject.value')
      : this.data;
    return data.map((d) => new Date(d.key));
  }),
  datesFromRangePicker: computed('data', function () {
    // If for some reason there is no datePickerRange default to last 7 days.
    let defaultStartDate = moment().tz(this.timezone).subtract(7, 'days').toString();

    let startDate = this.signal.get('datePickerRangeStart') || defaultStartDate;
    let endDate = this.signal.get('datePickerRangeEnd') || moment().toString();

    let days = Math.round(moment.duration(moment(endDate) - moment(startDate)).asDays());
    let range = [];
    let dayIndex;
    let hourIndex = 0;
    if (days > 1) {
      // Range of days from start to end
      for (dayIndex = 0; dayIndex < days; dayIndex++) {
        range.push(moment(startDate).add(dayIndex, 'days'));
      }
    } else {
      // Range of 24 hours increasing by 2 hours.
      for (hourIndex = 0; hourIndex < 24; hourIndex += 2) {
        range.push(moment(startDate).add(hourIndex, 'hours'));
      }
    }
    return range;
  }),
  _xAxisStride: computed('chartWidth', 'dates.length', function () {
    // This property is used to determine what fraction of the x-axis labels will be shown. If the number
    // returned is 1 then all labels will be shown, if it's 4 then only a quarter of them will be shown.
    let widthOfAxisLabel = 35;
    let maxNumberOfTicks = this.chartWidth / widthOfAxisLabel;
    return Math.ceil(this.get('dates.length') / maxNumberOfTicks);
  }),

  _redrawXAxis() {
    let xAxisStride = this._xAxisStride;
    let isRangeHours = this._isRangeHours;
    let isRangeDays = this._isRangeDays;

    let dateFormat = makeDateFormatter({
      isRangeDays,
      isRangeHours,
      stride: xAxisStride,
    });

    let xAxis = axisBottom(this.xScale).tickFormat((item, i) => {
      let format = dateFormat(item, i);
      return isEmpty(format) ? '' : this.intl.formatDate(item, { format, timeZone: this.timezone });
    });

    let axis = this.chart().select('g.x.axis');

    axis.transition().duration(this.transitionDuration).call(xAxis);

    axis.selectAll('.tick text').classed('reporting__chart__axis-label', true);
  },
  _redrawYAxisAndGridlines() {
    let yAxis = axisLeft(this.axisYScale).ticks(5); // This is a hint, ultimately the number of ticks we get is defined here: https://github.com/d3/d3-array/#ticks

    if (this.get('signal.valueUnitIsMinute')) {
      let yAxisGranularity = this.yAxisGranularity;
      let minutesInGranularity = 1;
      if (yAxisGranularity === 'days') {
        minutesInGranularity = MINUTES_IN_DAY;
      } else if (yAxisGranularity === 'hours') {
        minutesInGranularity = MINUTES_IN_HOUR;
      }
      yAxis = yAxis.tickFormat((scaleValue) => formatMinutes(scaleValue * minutesInGranularity));
    }

    let formatYAxis = this.formatYAxis;
    if (formatYAxis) {
      yAxis.tickFormat((value) => unitFormatter({ value, unit: formatYAxis }));
    }

    let axis = this.chart().select('g.y.axis');

    axis.transition().duration(this.transitionDuration).call(yAxis);

    axis.selectAll('.tick text').classed('reporting__chart__axis-label', true);

    axis.selectAll('.reporting__chart__grid-line').remove();
    axis
      .selectAll('.tick')
      .append('line')
      .classed('reporting__chart__grid-line', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', this.chartWidth)
      .attr('y2', 0);
  },
  xScale: computed('chartWidth', 'dates', function () {
    return scaleBand().rangeRound([0, this.chartWidth]).domain(this.dates);
  }),
  maxChartValue: computed('data', 'isStacked', function () {
    if (!this.renderComparisonData) {
      return this.data.reduce((prev, current) => {
        if (isArray(current.value)) {
          if (this.isStacked) {
            return Math.max(
              prev,
              current.value.reduce((sum, c) => sum + (c.value || 0), 0),
            );
          } else {
            return Math.max(
              prev,
              current.value.reduce((p, c) => Math.max(p, c.value || 0), 0),
            );
          }
        } else {
          return Math.max(prev, current.value || 0);
        }
      }, 0);
    }
    return this.data.reduce((prev, current) => {
      if (isArray(current.value)) {
        return Math.max(
          prev,
          current.value.reduce(
            (p, c) => Math.max(p, Math.max(c.value || 0, c.previousValue || 0)),
            0,
          ),
        );
      } else {
        return Math.max(prev, Math.max(current.value || 0, current.previousValue || 0));
      }
    }, 0);
  }),

  maxChartValueWithDefault: computed('maxChartValue', function () {
    return this.maxChartValue || 100;
  }),
  minChartValue: 0,
  maxChartValueDuration: computed('maxChartValueWithDefault', function () {
    return moment.duration(this.maxChartValueWithDefault, 'minutes');
  }),
  yAxisGranularity: computed('maxChartValueDuration', 'signal.valueUnitIsMinute', function () {
    if (!this.get('signal.valueUnitIsMinute')) {
      return 'units';
    }
    let duration = this.maxChartValueDuration;
    if (duration.asMinutes() > MINUTES_IN_DAY) {
      return 'days';
    } else if (duration.asMinutes() > MINUTES_IN_HOUR) {
      return 'hours';
    } else {
      return 'minutes';
    }
  }),
  yAxisTopInLargestUnit: computed(
    'maxChartValueWithDefault',
    'signal.valueUnitIsMinute',
    'yAxisGranularity',
    function () {
      let maxValue = this.maxChartValueWithDefault;
      if (!this.get('signal.valueUnitIsMinute')) {
        return maxValue;
      }
      let duration = this.maxChartValueDuration;
      let yAxisGranularity = this.yAxisGranularity;
      let durationAsLargestUnit;
      if (yAxisGranularity === 'days') {
        durationAsLargestUnit = duration.asDays();
      } else if (yAxisGranularity === 'hours') {
        durationAsLargestUnit = duration.asHours();
      } else {
        durationAsLargestUnit = maxValue;
      }
      return Math.ceil(durationAsLargestUnit);
    },
  ),
  yAxisTopInUnits: computed('yAxisTopInLargestUnit', 'yAxisGranularity', function () {
    if (this.yAxisGranularity === 'units') {
      return this.yAxisTopInLargestUnit;
    }
    return moment.duration(this.yAxisTopInLargestUnit, this.yAxisGranularity).asMinutes();
  }),
  yScale: computed('chartHeight', 'minChartValue', 'yAxisTopInUnits', function () {
    return scaleLinear()
      .range([this.chartHeight, 0])
      .domain([this.minChartValue, this.yAxisTopInUnits]);
  }),
  axisYScale: computed('chartHeight', 'minChartValue', 'yAxisTopInLargestUnit', function () {
    return scaleLinear()
      .range([this.chartHeight, 0])
      .domain([this.minChartValue, this.yAxisTopInLargestUnit]);
  }),
  _isRangeHours: lt('_rangeInDays', 1),
  _isRangeDays: lte('_rangeInDays', 178),
  _rangeInDays: computed(
    'datesFromRangePicker.firstObject',
    'datesFromRangePicker.lastObject',
    function () {
      let rangeStart = moment(this.get('datesFromRangePicker.firstObject'));
      let rangeEnd = moment(this.get('datesFromRangePicker.lastObject'));
      let duration = moment.duration(rangeEnd.diff(rangeStart)).asDays();
      if (duration < 1) {
        return duration;
      } else {
        return Math.round(duration);
      }
    },
  ),
});
