/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';

export default class Form extends Component {
  get locale() {
    return this.args.translationSettings?.possibleLocales.findBy(
      'localeId',
      this.args.translation.locale,
    );
  }

  get isDefaultLocale() {
    return (
      this.args.translation.locale === this.args.translationSettings.defaultTranslationLocaleId
    );
  }
}
