/* import __COLOCATED_TEMPLATE__ from './set-office-hours.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { TrackedArray, tracked } from 'tracked-built-ins';

interface Schedule {
  id: string;
  name: string;
  labelWithTimezone: string;
  isNew: boolean;
  isDefault: boolean;
}

interface TeamSettings {
  officeHoursScheduleId: string | null;
}

interface Args {
  schedules: Schedule[];
  teamSettings: TeamSettings;
  isDisabled: boolean;
  disableToolTip: boolean;
}

export default class SetOfficeHoursComponent extends Component<Args> {
  @service appService: any;
  @service store: any;

  @tracked showModal = false;
  @tracked newSchedule: Schedule | null = null;
  @tracked dropdownSchedules = new TrackedArray<{
    value: string;
    text: string;
    description: string;
  }>(this.savedSchedules.map((schedule: any) => this.getDropdownItemFromSchedule(schedule)));

  get app() {
    return this.appService.app;
  }

  get savedSchedules() {
    return this.args.schedules.filter((schedule) => !schedule.isNew);
  }

  get defaultSchedule() {
    return this.args.schedules.find((schedule) => schedule.isDefault);
  }

  get selectedScheduleId() {
    return this.args.teamSettings.officeHoursScheduleId || this.defaultSchedule?.id;
  }

  get selectedSchedule() {
    return this.args.schedules.find((schedule) => schedule.id === this.selectedScheduleId);
  }

  getDropdownItemFromSchedule(schedule: Schedule) {
    return {
      value: schedule.id,
      text: schedule.name,
      description: schedule.labelWithTimezone,
    };
  }

  @action
  onSelectItem(scheduleId: string) {
    this.args.teamSettings.officeHoursScheduleId = scheduleId;
  }

  @action
  onModalSave(schedule: Schedule) {
    if (
      !this.dropdownSchedules.find((dropdownSchedule) => dropdownSchedule.value === schedule.id)
    ) {
      this.dropdownSchedules.push(this.getDropdownItemFromSchedule(schedule));
    }
    this.args.teamSettings.officeHoursScheduleId = schedule.id;
  }

  @action
  openNewScheduleModal() {
    this.newSchedule = this.store.createRecord('office-hours-schedule');
    this.showModal = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teams::SetOfficeHours': typeof SetOfficeHoursComponent;
  }
}
