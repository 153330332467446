/* import __COLOCATED_TEMPLATE__ from './image-uploader.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import { ref } from 'ember-ref-bucket';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import { trackedReset } from 'tracked-toolbox';

export default class ImageUploader extends Component {
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @service intl;
  @tracked isDragging = false;
  fileExtension = 'png,gif,jpeg,jpg';
  file;
  @ref('inputElement') inputElement;
  @trackedReset('args.imageUrl') imageUrl = this.args.imageUrl;

  constructor() {
    super(...arguments);
  }

  get hasImage() {
    return !!this.imageUrl;
  }

  get imageName() {
    if (this.hasImage) {
      return this.imageUrl.split('/').pop().split('?').shift();
    }
  }

  @dropTask *uploadImage(file) {
    let uploader = new EmbercomFileUploader(file, {
      policyUrl: `/apps/${this.appService.app.id}/uploads`,
    });
    let uploadResponse = yield uploader.upload();

    if (uploadResponse.type !== 'success') {
      this.handleUploadError();
    } else {
      this.handleUploadSuccess(uploadResponse);
      this.trackImageUpload(uploadResponse.uploadUrl);
    }
  }

  handleUploadError() {
    this.clearFileInput();
    this.notificationsService.notifyError(this.intl.t('channels.email.settings.signature.error'));
  }

  handleUploadSuccess(response) {
    this.imageUrl = response.uploadUrl;
    if (this.args.onImageChange) {
      this.args.onImageChange(this.imageUrl);
    }
  }

  trackImageUpload(imageUrl) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'uploaded',
      object: 'signature_logo',
      image_url: imageUrl,
    });
  }

  pickFile() {
    if (typeof this.args.pickFile === 'function') {
      // this is here for testing 🤮
      return this.args.pickFile.call(this);
    }
    this.clearFileInput();
    this.inputElement.click();
  }

  setFile(file) {
    this.file = file;
    this.uploadImage.perform(file);
  }

  clearFileInput() {
    this.inputElement.value = '';
  }

  @action
  dragOver(event) {
    event.preventDefault();
    this.isDragging = true;
  }

  @action
  dragLeave(event) {
    event.preventDefault();
    this.isDragging = false;
  }

  @action
  drop(event) {
    event.preventDefault();
    this.isDragging = false;
    this.setFile(event.dataTransfer?.files[0]);
  }

  @action
  change(event) {
    if (event.target.files?.length) {
      this.setFile(event.target.files[0]);
    }
  }

  @action
  removeImage() {
    this.clearFileInput();
    this.file = null;
    this.imageUrl = null;
    if (this.args.onImageChange) {
      this.args.onImageChange(null);
    }
  }

  @action
  showFilePicker() {
    if (this.uploadImage.isRunning || this.args.disabled) {
      return;
    }
    this.pickFile();
  }
}
