/* import __COLOCATED_TEMPLATE__ from './permissions-hierarchy-section.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    permissionSection: { children_ids: string[] };
    permissionsObject: any;
    canAssignDefaultPredicates: boolean;
    withSeats: boolean;
    section: any;
    showDefaultPredicatesEditor: boolean;
  };
}

const PermissionsHierarchySection = templateOnlyComponent<Signature>();
export default PermissionsHierarchySection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Teammates::Permissions::PermissionsHierarchySection': typeof PermissionsHierarchySection;
    'settings/teammates/permissions/permissions-hierarchy-section': typeof PermissionsHierarchySection;
  }
}
