/* import __COLOCATED_TEMPLATE__ from './funnel-step.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  active: true,
  tagName: '',
  showPercentage: computed('totalValue', function () {
    let total = this.totalValue;
    if (total && total > 0) {
      return true;
    }
    return false;
  }),
});
