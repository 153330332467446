/* import __COLOCATED_TEMPLATE__ from './create-folder-modal.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type NavigationFolder from 'embercom/models/reporting/navigation-folder';
import type ReportingFolderManagerService from 'embercom/services/reporting-folder-manager-service';
import { isPresent } from '@ember/utils';

interface Arguments {
  isOpen: boolean;
  onClose: () => void;
  editingFolder: NavigationFolder | undefined;
}
interface Signature {
  Args: Arguments;
}

export default class CreateFolderModal extends Component<Signature> {
  @service declare store: any;
  @service declare intl: any;
  @service declare reportingFolderManagerService: ReportingFolderManagerService;
  @service declare intercomEventService: $TSFixMe;
  @tracked declare folder: NavigationFolder;
  @tracked declare name: string;

  get defaultFolderName() {
    if (this.isEditingFolder) {
      return this.args.editingFolder?.name || '';
    }
    return '';
  }

  get isEditingFolder() {
    return isPresent(this.args.editingFolder);
  }

  get isValidFolderName() {
    let name = this.name.trim();
    let folderNameAlreadyExists = this.reportingFolderManagerService.allFlexibleFolders.find(
      (folder: NavigationFolder) => {
        return folder.name === name;
      },
    );

    if (folderNameAlreadyExists) {
      return false;
    }
    return name.length > 0 && name.length <= 50;
  }

  get confirmLabel() {
    return this.isEditingFolder
      ? this.intl.t('reporting.submenu-component.modal-folder.rename')
      : this.intl.t('reporting.submenu-component.modal-folder.create');
  }

  get headerTitle() {
    this.setDefaultFolderName();
    return this.isEditingFolder
      ? this.intl.t('reporting.submenu-component.modal-folder.rename-folder')
      : this.intl.t('reporting.submenu-component.modal-folder.new-folder');
  }

  @action
  async createFolder() {
    let folder = this.store.createRecord('reporting/navigation-folder', { name: this.name.trim() });
    await folder.save();
    this.trackAnalytics(folder, 'created');
  }

  @action
  async renameFolder() {
    if (this.args.editingFolder) {
      this.args.editingFolder.name = this.name.trim();
      await this.args.editingFolder.save();
      this.trackAnalytics(this.args.editingFolder, 'renamed');
    }
  }

  @action
  async confirm() {
    if (this.isEditingFolder) {
      await this.renameFolder();
    } else {
      await this.createFolder();
    }
    this.reportingFolderManagerService.loadPinnedFlexibleFolders();
    this.clearForm();
  }

  trackAnalytics(folder: NavigationFolder, action: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'reporting_navigation_folder',
      folder_id: folder.id,
    });
  }

  @action cancel() {
    this.clearForm();
  }

  clearForm() {
    this.args.onClose();
    this.name = this.defaultFolderName;
  }

  setDefaultFolderName() {
    this.name = this.defaultFolderName;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::NavSubmenuComponents::CreateFolderModal': typeof CreateFolderModal;
    'reporting/nav-submenu-components/create-folder-modal': typeof CreateFolderModal;
  }
}
