/* import __COLOCATED_TEMPLATE__ from './report-list-search.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';

interface Args {
  searchFunction: (event: Event) => void;
  searchTerm: string | undefined;
  resetSearch: Function;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class ReportListSearch extends Component<Signature> {
  willDestroy() {
    super.willDestroy();
    this.args.resetSearch();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Views::ReportListSearch': typeof ReportListSearch;
  }
}
