/* import __COLOCATED_TEMPLATE__ from './dedicated-ips.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class DedicatedIps extends Component {
  @service store;
  @service notificationsService;
  @service intl;

  @tracked model;
  @tracked value;

  constructor() {
    super(...arguments);
    this.model = this.args.dedicatedIp;
    this.value = this.args.dedicatedIp ? 'dedicated' : 'shared';
  }

  get isSaveDisabled() {
    if (this.model) {
      return this.value === 'dedicated';
    } else {
      return this.value === 'shared';
    }
  }

  @action
  async save() {
    try {
      this.model = await this.store.createRecord('settings/dedicated-ip').save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.email.dedicated-ips.saved'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('settings.email.dedicated-ips.error'),
      });
    }
  }
}
