/* import __COLOCATED_TEMPLATE__ from './summary-comparison.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  equalToProperty,
  greaterThanProperty,
  subtractProperties,
} from '@intercom/pulse/lib/computed-properties';

const formattedRangeDate = (intl, date, timezone) => {
  return date && timezone && intl.formatDate(date, { format: 'dateWithMonth', timeZone: timezone });
};

const DEFAULT_IMPROVEMENT_DIRECTION = 'increasing';
const directions = {
  INCREASING: DEFAULT_IMPROVEMENT_DIRECTION,
  DECREASING: 'decreasing',
};
export const improvementDirections = directions;
export default Component.extend({
  intl: service(),
  attributeBindings: ['data-test-summary-comparison'],
  'data-test-summary-comparison': true,
  tagName: 'span',
  improvementDirection: DEFAULT_IMPROVEMENT_DIRECTION,
  showNotChangedText: true,
  showDeltaText: true,
  showRangeText: true,
  previousRangeDelta: subtractProperties('currentValue', 'previousValue'),

  signalHasIncreased: greaterThanProperty('currentValue', 'previousValue'),
  signalHasNotChanged: equalToProperty('currentValue', 'previousValue'),
  signalHasImproved: computed('signalHasIncreased', 'improvementDirection', function () {
    let improvementDirection = this.improvementDirection || DEFAULT_IMPROVEMENT_DIRECTION;
    let hasIncreasedAndImproved =
      this.signalHasIncreased && improvementDirection === directions.INCREASING;
    let hasDecreasedAndImproved =
      !this.signalHasIncreased && improvementDirection === directions.DECREASING;
    return hasIncreasedAndImproved || hasDecreasedAndImproved;
  }),

  arrowIcon: computed('signalHasIncreased', function () {
    let signalHasIncreased = this.signalHasIncreased;
    let arrow = signalHasIncreased ? 'up-arrow' : 'down-arrow';
    return `small-${arrow}`;
  }),
  formattedComparisonStart: computed(
    'intl.locale',
    'range.{comparisonStart,timezone}',
    function () {
      return formattedRangeDate(this.intl, this.range.comparisonStart, this.range.timezone);
    },
  ),
  formattedComparisonEnd: computed('intl.locale', 'range.{comparisonEnd,timezone}', function () {
    return formattedRangeDate(this.intl, this.range.comparisonEnd, this.range.timezone);
  }),
  tooltipText: computed(
    'intl.locale',
    'range',
    'formattedComparisonStart',
    'formattedComparisonEnd',
    function () {
      if (!this.range) {
        return;
      }

      if (this.formattedComparisonStart !== this.formattedComparisonEnd) {
        return this.intl.t('reporting.element.summary-comparison.compared-to.start-end', {
          start: this.formattedComparisonStart,
          end: this.formattedComparisonEnd,
        });
      }

      return this.intl.t('reporting.element.summary-comparison.compared-to.start', {
        start: this.formattedComparisonStart,
      });
    },
  ),
});
