/* import __COLOCATED_TEMPLATE__ from './segment-limit-selector.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

const DEFAULT_CUSTOM_LIMIT_MAX = 200;

export default class SegmentLimitSelector extends Component {
  @tracked isValid = true;

  get segmentLimits() {
    return [
      { text: '5', value: 5 },
      { text: '7', value: 7 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
    ];
  }

  get isCustomLimit() {
    return this.segmentLimits.every((limit) => limit.value !== this.args.displayLimit);
  }

  get customLimit() {
    return this.args.customSegmentLimit || DEFAULT_CUSTOM_LIMIT_MAX;
  }

  isValidCustomLimit(limit) {
    return limit > 0 && limit <= this.customLimit;
  }

  @action
  setCustomLimit(event) {
    let limit = Math.floor(Number(event.currentTarget.value));
    if (this.isValidCustomLimit(limit)) {
      this.args.updateDisplayLimit(limit);
    }
  }

  @action
  validateCustomLimit(event) {
    let value = event.currentTarget.value;
    if (value.length) {
      let limit = Math.floor(Number(value));
      this.isValid = this.isValidCustomLimit(limit);
    } else {
      this.isValid = true;
    }
  }

  @action
  onClickPopoverContent(event) {
    event.stopPropagation();
  }

  @action
  toggleDisplayOther() {
    this.args.updateDisplayOther(!this.args.displayOther);
  }
}
