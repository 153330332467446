/* import __COLOCATED_TEMPLATE__ from './overview-report.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import {
  seriesPerformanceAggregatedStats,
  seriesPerformanceFilter,
  seriesPerformanceMultilineStats,
} from 'embercom/components/series/editor/aggregated-stats/stat-tile-config/performance-stats';
import {
  aggregatedStatsFilter,
  contentAggregatedStats,
  contentTableStats,
} from 'embercom/components/series/editor/aggregated-stats/stat-tile-config/stats-per-content-type';
import { pluralHumanReadableObjectName } from 'embercom/helpers/matching-system/plural-human-readable-object-name';
import {
  createExtraRangeOption,
  formattedStartDate,
} from 'embercom/helpers/stats-system/stats-parameters';
import {
  SeriesAnalyticActions,
  SeriesAnalyticObjects,
  SeriesAnalyticTracker,
} from 'embercom/lib/series/analytic-tracker';
import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';
import Range from 'embercom/models/reporting/range';
export default class OverviewReport extends Component {
  @service seriesEditorService;
  @service outboundHomeService;
  @service intercomEventService;
  @service store;
  @service appService;
  @service intl;

  @tracked pageFrom = 0;
  @tracked data = [];
  @tracked lastPageHit = true;
  @tracked selectedObjectType = this.contentObjectTypesInSeries?.firstObject || 'all';
  @tracked rulesetIds = [];

  rulesetIdsByContentType = {};
  seriesAnalyticTracker = new SeriesAnalyticTracker({
    intercomEventService: this.intercomEventService,
    seriesEditorService: this.seriesEditorService,
  });

  constructor() {
    super(...arguments);
    this.seriesEditorService.updateRange(
      Range.createFromParamsWithAddedRanges(
        formattedStartDate(this.series?.wentLiveAt),
        null,
        this.timezone(),
        createExtraRangeOption(this.series?.wentLiveAt, this.timezone()),
      ),
    );
    if (!this.series.isDraft) {
      this._fetchAllRulesetIds.perform();
    }
  }

  get app() {
    return this.appService.app;
  }

  get aggregatedStatsFilter() {
    return aggregatedStatsFilter;
  }

  get seriesPerformanceFilter() {
    return seriesPerformanceFilter;
  }

  get seriesPerformanceAggregatedStats() {
    return seriesPerformanceAggregatedStats;
  }

  get seriesPerformanceMultilineStats() {
    return seriesPerformanceMultilineStats;
  }

  get contentAggregatedStats() {
    return contentAggregatedStats;
  }

  get amountOfStatTilesForContentType() {
    return contentAggregatedStats[this.selectedObjectType].length;
  }

  get isTabBeingChosen() {
    return this._fetchAllRulesetIds.isRunning;
  }

  get isLoading() {
    return (this.data.length === 0 && this._fetch.isRunning) || this.isTabBeingChosen;
  }

  get series() {
    return this.seriesEditorService.series;
  }

  get contentObjectTypesInSeries() {
    return Object.keys(contentTableStats).filter((key) =>
      this.series.objectTypesUsed.includes(parseInt(key, 10)),
    );
  }

  get humanReadableObjectNames() {
    return humanReadableObjectNames;
  }

  get contentObjectStatistics() {
    return contentTableStats[this.selectedObjectType];
  }

  get statisticKeysForContentType() {
    return this.contentObjectStatistics.map((stat) => stat.statisticKey);
  }

  get headingStatType() {
    return this.selectedObjectType === 'all'
      ? this.intl.t('outbound.series.stat-text-for-all-tab')
      : pluralHumanReadableObjectName(this.selectedObjectType, this.intl);
  }

  @action
  _updateContentStats(objectType) {
    this.selectedObjectType = objectType;

    this.pageFrom = 0;
    this.data = [];
    this.lastPageHit = true;
    this.rulesetIds = [];

    this._fetch.perform();
  }

  @action
  changeObjectType(objectType) {
    this._updateContentStats(objectType);
    this.seriesAnalyticTracker.track({
      action: SeriesAnalyticActions.changeTab,
      object: SeriesAnalyticObjects.overviewReport,
      additionalAttributes: {
        new_tab: objectType,
      },
    });
  }

  @task({ drop: true })
  *_fetch() {
    yield this.outboundHomeService
      .contentSearchWithFilters({
        app_id: this.outboundHomeService.app.id,
        object_types:
          this.selectedObjectType === 'all'
            ? this.outboundHomeService.allObjectTypes
            : [this.selectedObjectType],
        page_from: this.pageFrom,
        per_page: 100,
        series_id: this.series.id,
        statistics: this.statisticKeysForContentType,
      })
      .then((result) => {
        this.data.pushObjects(result.contentWrappers);
        this.lastPageHit = result.lastPageHit;
        this.pageFrom = result.pageFrom;
        this.setRulesetIds();
      });
  }

  setRulesetIds() {
    if (this.selectedObjectType === 'all') {
      this.rulesetIds = Object.values(this.rulesetIdsByContentType)
        .map((key) => key.ruleset_ids)
        .flat();
    } else {
      this.rulesetIds = this.rulesetIdsByContentType[this.selectedObjectType]?.ruleset_ids;
    }
  }

  @task({ drop: true })
  *_fetchAllRulesetIds() {
    yield this.outboundHomeService
      .getAllRulesetIds({
        app_id: this.outboundHomeService.app.id,
        object_types: this.outboundHomeService.allObjectTypes,
        series_id: this.series.id,
      })
      .then((result) => {
        this.rulesetIdsByContentType = result;
        this.selectTabWithMostContent(result);
      });
  }

  selectTabWithMostContent(result) {
    let maxKey = _.max(Object.keys(result), (o) => result[o].total_count);
    this._updateContentStats(maxKey);
  }

  @action
  async loadMore() {
    this.pageFrom += 1;
    await this._fetch.perform();
  }

  @action
  updateDateRange(range) {
    this.seriesEditorService.updateRange(range);
    this.pageFrom = 0;
    this.data = [];
    this.lastPageHit = true;

    this._fetch.perform();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'update_date_range',
      object: 'series_overview_list',
      place: 'outbound_series',
      owner: 'engage',
      new_range: range,
    });
  }

  timezone() {
    return this.app.timezone;
  }

  get tableExportFilename() {
    return `series_${this.series.id}_${this.headingStatType}`;
  }
}
