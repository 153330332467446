/* import __COLOCATED_TEMPLATE__ from './seat-filter.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { difference, isEqual, pairs, without } from 'underscore';
import { isBlank } from '@ember/utils';

import {
  PRICING_5_X_SEAT_LABELS,
  VBP2_SEAT_LABELS,
  COPILOT_SEAT_TYPE as COPILOT,
} from 'embercom/lib/settings/seats/constants';
import {
  NO_FILTER,
  WITHOUT_FILTER_VALUE,
  SEAT_FILTER_TYPE,
} from 'embercom/lib/settings/filters/constants';

export default class SeatFilter extends Component {
  @service intl;
  @service appService;
  @service customerService;

  @tracked availableSeatTypes = this.customerService.customer.availableSeatTypes;
  @tracked selectedFilter = this.args.filterValues;
  @tracked label = this.currentLabel;

  get filterItems() {
    let convertToFilterItem = ([value, text]) => {
      let isSelected = this.selectedFilter.includes(value);

      return { text, value, isSelected };
    };

    return [
      {
        items: [
          {
            text: this.intl.t('settings.filters.seat-filter.any'),
            value: NO_FILTER,
            isSelected: this.selectedFilter.includes(NO_FILTER),
          },
          ...(!this.appService.app.onPricing5
            ? [
                {
                  text: this.intl.t('settings.filters.seat-filter.no-seat'),
                  value: WITHOUT_FILTER_VALUE,
                  isSelected: this.selectedFilter.includes(WITHOUT_FILTER_VALUE),
                },
              ]
            : []),
        ],
      },
      {
        heading: this.intl.t('settings.filters.seat-filter.seats'),
        items: this.availableSeatTypePairs.map(convertToFilterItem),
      },
    ];
  }

  get availableSeatTypePairs() {
    let { [COPILOT]: _, ...seatTypesWithoutCopilot } = this.appService.app.onPricing5
      ? PRICING_5_X_SEAT_LABELS
      : VBP2_SEAT_LABELS;

    let bySeatTypeAvailability = ([value]) => {
      return isBlank(this.availableSeatTypes) || this.availableSeatTypes.includes(value);
    };

    return pairs(seatTypesWithoutCopilot).filter(bySeatTypeAvailability);
  }

  get shouldShowFilter() {
    return this.availableSeatTypePairs.length > 1;
  }

  get currentLabel() {
    if (isEqual(this.selectedFilter, [NO_FILTER])) {
      return this.intl.t('settings.filters.seat-filter.any');
    } else if (isEqual(this.selectedFilter, [WITHOUT_FILTER_VALUE])) {
      return this.intl.t('settings.filters.seat-filter.no-seat');
    } else {
      let selectedSeatNames = this.selectedFilter.map((filterValue) => {
        return this.appService.app.onPricing5
          ? PRICING_5_X_SEAT_LABELS[filterValue]
          : VBP2_SEAT_LABELS[filterValue];
      });
      return selectedSeatNames.join(` ${this.intl.t('settings.filters.seat-filter.and')} `);
    }
  }

  @action
  onChangeFilterValue(value) {
    let addedFilterValue = difference(value, this.selectedFilter);

    if (isEqual(addedFilterValue, [NO_FILTER]) || isEqual(value, [])) {
      this.selectedFilter = [NO_FILTER];
    } else if (this.appService.app.onPricing5) {
      this.selectedFilter = addedFilterValue;
    } else if (isEqual(addedFilterValue, [WITHOUT_FILTER_VALUE])) {
      this.selectedFilter = [WITHOUT_FILTER_VALUE];
    } else {
      this.selectedFilter = without(value, NO_FILTER, WITHOUT_FILTER_VALUE);
    }

    this.label = this.currentLabel;
  }

  @action
  clearFilters() {
    if (isEqual(this.args.filterValues, [NO_FILTER])) {
      this.selectedFilter = this.args.filterValues;
      this.label = this.currentLabel;
    }
  }

  @action
  updateFilter() {
    this.args.updateFilterValues(SEAT_FILTER_TYPE, this.selectedFilter);
  }
}
