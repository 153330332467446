/* import __COLOCATED_TEMPLATE__ from './comparison-bar-group.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { fmtStyle } from '@intercom/pulse/lib/computed-properties';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { createScaledStackItem } from 'embercom/lib/reporting/visualization-helpers';

export default Component.extend({
  tagName: '',
  style: fmtStyle('transform: translate3d(%@px,0,0) scale3d(%@,1,1);', 'x', 'scaleX'),
  currentBar: computed('current', function () {
    return {
      ...this.current,
      ...createScaledStackItem(0, this.current.value, this.scale),
    };
  }),
  hasPreviousBar: readOnly('previous'),
  previousBar: computed('previous', function () {
    return {
      ...this.previous,
      ...createScaledStackItem(0, this.previous.value, this.scale),
    };
  }),
});
