/* import __COLOCATED_TEMPLATE__ from './confirmed.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class Confirmation extends Component {
  @service router;

  @action
  restartSeatRequest() {
    this.args.model.set('seat', null);
    this.args.model.set('requestTo', null);
    this.router.transitionTo('apps.app.seatless-experience.select-seat');
  }
}
