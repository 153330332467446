/* import __COLOCATED_TEMPLATE__ from './apply-changes-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
import Component from '@glimmer/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { defineProperty, computed } from '@ember/object';
import ModelWithPermissions from 'embercom/models/model-with-permissions';
import { PERMISSIONS_EXCLUDED_FROM_TOGGLED_PERMISSIONS } from 'embercom/models/model-with-permissions';
import Role from 'embercom/models/role';
import { inject as service } from '@ember/service';

export default class ApplyChangesModal extends Component {
  @service appService;

  permissionNames = ModelWithPermissions.editablePermissionKeys;

  constructor() {
    super(...arguments);
    // Create a property that is dependent on any change in permissions
    defineProperty(
      this,
      'changedPermissions',
      computed(`args.permissionsObject.{${this.permissionNames.join(',')}}`, function () {
        return this.args.permissionsObject.changedAttributes();
      }),
    );
  }

  get app() {
    return this.appService.app;
  }

  get isRoleChanging() {
    return this.args.permissionsObject instanceof Role;
  }

  get isConversationAccessChanging() {
    return this.args.permissionsObject.conversationAccessHasChanged;
  }

  get isCopilotAccessChanging() {
    return this.args.permissionsObject.copilotUsageAccessHasChanged;
  }

  get changes() {
    let changedAttrs = this.changedPermissions;

    PERMISSIONS_EXCLUDED_FROM_TOGGLED_PERMISSIONS.forEach((permission) => {
      delete changedAttrs[permission];
    });

    return this.permissionNames
      .map((permissionName) => {
        let permissionChange = changedAttrs[permissionName];

        return permissionChange ? { permissionName, added: permissionChange[1] } : null;
      })
      .filter((p) => p)
      .sort(this.sortChanges);
  }

  get numToReview() {
    return this.changes.length + this.isConversationAccessChanging + this.isCopilotAccessChanging;
  }

  sortChanges(a, b) {
    if (a.added === b.added) {
      return 0;
    } else {
      return a.added ? -1 : 1;
    }
  }
}
