/* import __COLOCATED_TEMPLATE__ from './reassign-conversations.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import teamsThatWillBeEmpty from 'embercom/lib/teams/to-be-empty-teams';
import { inject as service } from '@ember/service';

const UNASSIGNED_ASSIGNEE_ID = '0';
export default class extends Component {
  @service appService;
  @service intl;

  get teammates() {
    return this.args.teammates || [this.args.teammate];
  }

  get thingsToReassign() {
    return (
      this.args.thingsToReassign ||
      this.intl.t(
        'settings.teammates.remove-teammate.reassign-conversations.things-to-reassign-question',
      )
    );
  }

  get teamsThatWillBeEmpty() {
    return teamsThatWillBeEmpty(this.args.app, this.teammates);
  }

  get possibleAssigneesForConversations() {
    let teammateIds = this.teammates.map((teammate) => teammate.id);
    return this.args.app.assignableAdmins // app.assignableAdmins includes teams!
      .reject((admin) => teammateIds.includes(admin.id))
      .filter((admin) => admin.id !== UNASSIGNED_ASSIGNEE_ID)
      .reject(
        (team) =>
          this.teamsThatWillBeEmpty.findBy('id', team.id) || this._emptyTeams.findBy('id', team.id),
      );
  }

  get selectionIsValid() {
    let reassignments = this.args.reassignments;
    return (
      isPresent(reassignments.conversations) &&
      (this.args.hideEmptyToBeTeams ||
        this.teamsThatWillBeEmpty.every((t) => isPresent(reassignments.teams[t.id])))
    );
  }

  @action
  updateSelection(key, teammateId) {
    this.args.reassignments[key] = teammateId;
    this.args.updateSelection(this.selectionIsValid);
  }

  @action
  updateSelectionForTeam(teamId, assigneeId) {
    this.args.reassignments.teams[teamId] = assigneeId;
    this.args.updateSelection(this.selectionIsValid);
  }

  get scimEnabled() {
    return this.appService.app.scim_enabled;
  }

  get _emptyTeams() {
    return this.args.app.teams.filter((team) => team.teamMemberCount === 0);
  }
}
