/* import __COLOCATED_TEMPLATE__ from './message-volume-by-user.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import {
  REPORTING_RANGE_UPDATED,
  CONTENT_TYPE_IDS_UPDATED,
} from 'embercom/services/reporting-service';
import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';
import { isEmpty } from '@ember/utils';

const PER_PAGE = 10;

export default class MessageVolumeByUser extends Component {
  @service appService;
  @service store;
  @service reportingService;
  @service outboundHomeService;

  @tracked numberOfVisibleRows = PER_PAGE;
  @tracked userData = [];

  constructor() {
    super(...arguments);

    let fetchData = () => this.fetchData.perform();
    this.reportingService.on(REPORTING_RANGE_UPDATED, fetchData);
    this.reportingService.on(CONTENT_TYPE_IDS_UPDATED, fetchData);

    registerDestructor(this, () => {
      this.fetchData.cancelAll();
      this.reportingService.off(REPORTING_RANGE_UPDATED, fetchData);
      this.reportingService.off(CONTENT_TYPE_IDS_UPDATED, fetchData);
    });
  }

  get app() {
    return this.appService.app;
  }

  get visibleRows() {
    return this.userData.slice(0, this.numberOfVisibleRows);
  }

  @action
  showMore() {
    this.numberOfVisibleRows += PER_PAGE;
  }

  @task({ restartable: true })
  *fetchData() {
    let statCountsByUser = yield ajax({
      url: '/ember/reporting/customer_engagement_report/stat_count_by_user',
      type: 'GET',
      data: {
        app_id: this.app.id,
        range_start: this.args.range.start,
        range_end: this.args.range.end,
        object_types: isEmpty(this.args.selectedContentTypeIds)
          ? this.outboundHomeService.allObjectTypes
          : this.args.selectedContentTypeIds,
      },
    });

    this.userData = statCountsByUser.map((statCountByUser) => {
      let user = this.store.push(this.store.normalize('messages/recipient', statCountByUser.user));
      return {
        app_id: statCountByUser.app_id,
        count: statCountByUser.count,
        user,
      };
    });
  }
}
