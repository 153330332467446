/* import __COLOCATED_TEMPLATE__ from './chart-heatmap.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { scaleQuantize } from 'd3-scale';
import { inject as service } from '@ember/service';

const DEFAULT_HEATMAP_COLORS = [
  'vis-blue-80',
  'vis-blue-70',
  'vis-blue-60',
  'vis-blue-40',
  'vis-blue-30',
];

export default Component.extend({
  intl: service(),
  tagName: '',
  get days() {
    return [
      this.intl.t('reporting.chart-heatmap.days.mon'),
      this.intl.t('reporting.chart-heatmap.days.tue'),
      this.intl.t('reporting.chart-heatmap.days.wed'),
      this.intl.t('reporting.chart-heatmap.days.thu'),
      this.intl.t('reporting.chart-heatmap.days.fri'),
      this.intl.t('reporting.chart-heatmap.days.sat'),
      this.intl.t('reporting.chart-heatmap.days.sun'),
    ];
  },
  get hours() {
    return [
      this.intl.t('reporting.chart-heatmap.hours.12am'),
      this.intl.t('reporting.chart-heatmap.hours.3am'),
      this.intl.t('reporting.chart-heatmap.hours.6am'),
      this.intl.t('reporting.chart-heatmap.hours.9am'),
      this.intl.t('reporting.chart-heatmap.hours.12pm'),
      this.intl.t('reporting.chart-heatmap.hours.3pm'),
      this.intl.t('reporting.chart-heatmap.hours.6pm'),
      this.intl.t('reporting.chart-heatmap.hours.9pm'),
    ];
  },
  colorScale: computed('maxValue', 'colors', function () {
    return scaleQuantize()
      .domain([0, this.maxValue])
      .range(this.colors || DEFAULT_HEATMAP_COLORS);
  }),
  maxValue: computed('signal.context', function () {
    let bucketMax = (bucket) => Math.max(...bucket.map((item) => item.value));
    return Math.max(...this.signal.context.map(({ value }) => bucketMax(value)));
  }),
});
