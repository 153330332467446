/* import __COLOCATED_TEMPLATE__ from './standard-tab.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  SeriesAnalyticTracker,
  SeriesAnalyticActions,
  SeriesAnalyticObjects,
} from 'embercom/lib/series/analytic-tracker';

export default class StandardTab extends Component {
  @service seriesEditorService;
  @service intercomEventService;

  get isActive() {
    return this.args.config.view === this.seriesEditorService.activeView;
  }

  @action changeView() {
    if (!this.isActive) {
      new SeriesAnalyticTracker({
        intercomEventService: this.intercomEventService,
        seriesEditorService: this.seriesEditorService,
      }).track({
        action: SeriesAnalyticActions.clicked,
        object: SeriesAnalyticObjects.tab(this.args.config.view),
      });
      this.seriesEditorService.changeActiveView(this.args.config.view);
    }
  }
}
