/* import __COLOCATED_TEMPLATE__ from './bot-inbox-time-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { action } from '@ember/object';

export default class BotInboxTimeFilterComponent extends Component {
  get selected() {
    if (isEmpty(this.args.selected)) {
      return 'included';
    } else {
      return this.args.selected[0];
    }
  }

  @action
  changeSelection(selectedValue) {
    this.args.setSelected([selectedValue]);
  }

  selectItems = [
    { text: 'Bot inbox time included', value: 'included' },
    { text: 'Bot inbox time excluded', value: 'excluded' },
  ];
}
