/* import __COLOCATED_TEMPLATE__ from './bot-inbox-time-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class BotInboxTimeFilterComponent extends Component {
  @service intl;
  @tracked selected;

  constructor() {
    super(...arguments);
    this.selected = this.args.selected || 'included';
  }

  get selectItems() {
    return [
      {
        text: this.intl.t('components.reporting.bot-inbox-time-filter.bot-inbox-time-included'),
        value: 'included',
      },
      {
        text: this.intl.t('components.reporting.bot-inbox-time-filter.bot-inbox-time-excluded'),
        value: 'excluded',
      },
    ];
  }
}
