/* import __COLOCATED_TEMPLATE__ from './sparkline.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  tagName: '',
  series: computed('signal.context', 'keys', function () {
    return this.keys.map((key) => ({
      key,
      value: this.signal.context.map((item) => {
        let count;
        let value;

        if (key) {
          ({ count = 0, value = 0 } = item.value.find((val) => val.key === key) || {});
        } else {
          ({ count = 0, value = 0 } = item);
        }

        return {
          key: item.key,
          count,
          value,
        };
      }),
    }));
  }),
});
