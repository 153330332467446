/* import __COLOCATED_TEMPLATE__ from './closed-on-first-contact-counter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  COUNT,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  IS_RESOLVED_FIRST_CONTACT,
} from 'embercom/lib/reporting/flexible/constants';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import { inject as service } from '@ember/service';

export default class ClosedOnFirstContactCounter extends Component {
  @service intl;

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.percent };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
        isComparison: true,
        createTitleTooltipText: () => {
          return this.intl.t(
            'components.reporting.reports.effectiveness.closed-on-first-contact-counter.tooltip',
          );
        },
      },
    };
    viewConfig.counter = {
      closed_on_first_contact_filtered: {
        improvementDirection: improvementDirections.INCREASING,
      },
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      series: [
        {
          name: 'closed_on_first_contact_filtered',
          source: 'conversation_with_message_thread',
          type: COUNT,
          data: {
            property: IS_RESOLVED_FIRST_CONTACT,
          },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'category',
            data: {
              property: IS_RESOLVED_FIRST_CONTACT,
              values: [true],
            },
          },
        },
        {
          name: 'closed_on_first_contact_all',
          source: 'conversation_with_message_thread',
          type: COUNT,
          data: {
            property: IS_RESOLVED_FIRST_CONTACT,
          },
          time: {
            property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
        },
      ],
    };
  }
}
