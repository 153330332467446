/* import __COLOCATED_TEMPLATE__ from './line-chart-legend.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly, sort, setDiff } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { rejectByProperty, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import { MAX_CHART_LINES } from 'embercom/components/reporting/signal-card';

export default Component.extend({
  attributeBindings: ['data-test-line-chart-legend'],
  'data-test-line-chart-legend': true,
  appService: service(),
  app: readOnly('appService.app'),

  showContextMultiselectDropdown: false,
  displayedItemCount: MAX_CHART_LINES,
  sortKey: ['value:desc'],
  scopingTagId: null,
  classNames: ['reporting__tag-line-chart__legend'],

  sortedSignalComparisons: sort('signal.comparisons', 'sortKey'),
  signalComparisonsLimitedByDisplayedItemCount: computed(
    'sortedSignalComparisons.[]',
    'displayedItemCount',
    function () {
      return this.sortedSignalComparisons.slice(0, this.displayedItemCount);
    },
  ),

  signalComparisonsWithoutScopingTagComparison: rejectByProperty(
    'sortedSignalComparisons',
    'key',
    'scopingTagId',
  ),

  scopingTagComparison: setDiff(
    'sortedSignalComparisons',
    'signalComparisonsWithoutScopingTagComparison',
  ),
  displayedSignalComparisons: ternaryToProperty(
    'showContextMultiselectDropdown',
    'signalComparisonsWithoutScopingTagComparison',
    'signalComparisonsLimitedByDisplayedItemCount',
  ),

  hiddenItemCount: computed('signal.context', 'displayedItemCount', function () {
    let count = 0;
    this.get('signal.context').forEach((context, i) => {
      if (i >= this.displayedItemCount) {
        count += context.count;
      }
    });
    return count;
  }),
});
