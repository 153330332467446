/* import __COLOCATED_TEMPLATE__ from './conversation-rating-bar.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import {
  ALL_DIRECT_TO_HUMAN_CONVERSATION_RATINGS,
  ALL_SELF_SERVE_FAILED_CONVERSATION_RATINGS,
} from 'embercom/lib/reporting/csf-queries';
import { getTimeConfig } from 'embercom/lib/reporting/csf-query-helper';
import { CONVERSATION_RATINGS } from 'predicates/models/data/conversation-ratings';
import PALETTE from '@intercom/pulse/lib/palette';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import { inject as service } from '@ember/service';
import { buildDataConfigWithComparisons } from 'embercom/lib/reporting/comparison-data-config';

const RATING_COLOR_MAP = {
  5: PALETTE['vis-green-40'],
  4: PALETTE['vis-green-60'],
  3: PALETTE['vis-yellow-70'],
  2: PALETTE['vis-yellow-50'],
  1: PALETTE['vis-salmon-40'],
};

export const IMPROVEMENT_DIRECTION_MAP = {
  5: improvementDirections.INCREASING,
  4: improvementDirections.INCREASING,
  3: improvementDirections.DECREASING,
  2: improvementDirections.DECREASING,
  1: improvementDirections.DECREASING,
};

export default class ConversationRatingBar extends Component {
  @service appService;

  get viewConfig() {
    return {
      showLegend: false,
      seriesColorMap: RATING_COLOR_MAP,
      chartHeight: '50px',
      improvementDirectionMappingFunction: this.mapQueryNameToImprovementDirection,
      tooltipTextMappingFunction: this.formatTooltip,
      emojiMappingFunction: this.mapRatingIdToEmoji,
    };
  }

  get query() {
    return this.args.isSelfServeFailed
      ? ALL_SELF_SERVE_FAILED_CONVERSATION_RATINGS
      : ALL_DIRECT_TO_HUMAN_CONVERSATION_RATINGS;
  }

  get queryName() {
    return this.args.isSelfServeFailed
      ? 'self-serve-failed-all-conversation-ratings'
      : 'direct-to-human-support-all-conversation-ratings';
  }

  get dataConfig() {
    return buildDataConfigWithComparisons(
      {
        series: [
          {
            name: this.queryName,
            ...this.query,
            time: getTimeConfig({ range: this.args.range }),
          },
        ],
        xAxis: {
          type: 'nominal',
          data: {
            property: 'conversation_rating.rating_index',
            exclude_missing_group: true,
          },
        },
      },
      this.args.range,
    );
  }

  mapRatingIdToEmoji(id) {
    return CONVERSATION_RATINGS.findBy('value', parseInt(id, 10)).emoji;
  }

  mapQueryNameToImprovementDirection(series) {
    return IMPROVEMENT_DIRECTION_MAP[series.name];
  }

  formatTooltip(queryName) {
    return 'conversations';
  }
}
