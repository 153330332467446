/* import __COLOCATED_TEMPLATE__ from './explicit-waiting-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { nodeDelayBehaviors } from 'embercom/models/data/matching-system/matching-constants';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
export default class SeriesNodeExplicitWaitingSettingsComponent extends Component {
  @service seriesEditorService;
  @service intl;

  get evaluationTimeInMilliseconds() {
    return this.args.seriesNode.maxEvaluationTime * 1000;
  }

  get hasFalsePath() {
    return this.args.graphNode.outwardEdges.some((edge) => edge.dataObject.isAlternative);
  }

  get descriptionPrefix() {
    return this.args.seriesNode.isDelayable ? `Try to match for` : `Try to match`;
  }

  get descriptionValue() {
    if (this.args.seriesNode.isDelayable) {
      return `${this.args.seriesNode.delayDescription}`;
    } else {
      return `once`;
    }
  }

  get descriptionSuffix() {
    if (this.args.seriesNode.isDelayable) {
      if (this.hasFalsePath) {
        return this.intl.t('outbound.series.canvas.node.explicit-wait.delayable.not-matched', {
          descriptionValue: this.descriptionValue,
        });
      } else {
        return this.intl.t('outbound.series.canvas.node.explicit-wait.delayable.leave-path', {
          descriptionValue: this.descriptionValue,
        });
      }
    } else if (this.hasFalsePath) {
      return this.intl.t('outbound.series.canvas.node.explicit-wait.not-delayable.leave-path');
    } else {
      return this.intl.t('outbound.series.canvas.node.explicit-wait.not-delayable.not-matched');
    }
  }

  @action disableDelay() {
    this.args.seriesNode.delayBehavior = nodeDelayBehaviors.noDelay;
    this.args.seriesNode.maxEvaluationTime = -1;
  }

  @action enableDelay() {
    this.args.seriesNode.maxEvaluationTime = moment.duration(1, 'week').asSeconds();
    this.args.seriesNode.delayBehavior = nodeDelayBehaviors.delayable;
  }

  @action updateNodeEvaluationTime(timeInMilliseconds) {
    this.args.seriesNode.maxEvaluationTime = timeInMilliseconds / 1000;
  }
}
