/* import __COLOCATED_TEMPLATE__ from './new-conversations-timeline.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';

export default Component.extend({
  query: {
    name: 'default_query',
    debug_name: 'new-conversations-timeline',
    document_type: 'conversation_with_message_thread',
    range_field: 'first_user_conversation_part_created_at',
    aggregation_type: 'value_count',
    aggregation_field: 'first_user_conversation_part_created_at',
    nested_keys_are_dates: false,
  },
  groupings: [],
});
