/* import __COLOCATED_TEMPLATE__ from './open-snoozed-columnchart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  COUNT,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
} from 'embercom/lib/reporting/flexible/constants';
import PALETTE from '@intercom/pulse/lib/palette';
import { inject as service } from '@ember/service';

export default class OpenSnoozedColumnchart extends Component {
  @service intl;

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[FIRST_USER_CONVERSATION_PART_CREATED_AT],
        createTitleTooltipText: () =>
          this.intl.t('reporting.conversations-report.open-and-snoozed-conversations.tooltip'),
      },
    };
    viewConfig.legend = {
      open_conversations_columnchart: () =>
        this.intl.t('reporting.conversations-report.open-and-snoozed-conversations.legend.open'),
      snoozed_conversations_columnchart: () =>
        this.intl.t('reporting.conversations-report.open-and-snoozed-conversations.legend.snoozed'),
    };
    viewConfig.seriesColors = [PALETTE['vis-pink-50'], PALETTE['vis-turquoise-50']];
    viewConfig.columnChart = {
      stacking: 'normal',
      series: {
        tooltips: {
          open_conversations_columnchart: 'open conversation',
          snoozed_conversations_columnchart: 'snoozed conversation',
        },
      },
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      xAxis: {
        type: 'temporal',
        data: {
          interval: FILTER_PROPERTY_PLACEHOLDER,
          property: FIRST_USER_CONVERSATION_PART_CREATED_AT,
        },
      },
      series: [
        {
          name: 'open_conversations_columnchart',
          source: 'conversation_state',
          type: COUNT,
          data: { property: FIRST_USER_CONVERSATION_PART_CREATED_AT },
          time: {
            property: 'state',
            data_model: 'interval',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'category',
            data: {
              property: 'conversation_state',
              values: ['opened'],
            },
          },
        },
        {
          name: 'snoozed_conversations_columnchart',
          source: 'conversation_state',
          type: COUNT,
          data: { property: FIRST_USER_CONVERSATION_PART_CREATED_AT },
          time: {
            property: 'state',
            data_model: 'interval',
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
          },
          filter: {
            type: 'category',
            data: {
              property: 'conversation_state',
              values: ['snoozed'],
            },
          },
        },
      ],
    };
  }
}
