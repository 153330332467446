/* import __COLOCATED_TEMPLATE__ from './first-response-by-team-linechart.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import {
  LAST_TEAM_ASSIGNED_BEFORE_REPLY,
  MEDIAN,
  MIN,
  MAX,
  MEAN,
  TEAMMATE_RESPONSE_TIME,
  FIRST_TEAMMATE_RESPONSE_TIME,
  COMMENT_CREATED_AT,
  FIRST_ADMIN_REPLY_AT,
  TEAM_GROUPING_LIMIT,
  TEAM_GROUPING_TERM_SIZE,
  SORTING_LABEL_NAME,
} from 'embercom/lib/reporting/flexible/constants';
import { botInboxAndOfficeHoursField } from 'embercom/lib/reporting/office-hours-field';

import { inject as service } from '@ember/service';

export default class FirstResponseByTeamLinechart extends Component {
  @service appService;
  @service intl;
  teams = this.appService.app.teams;

  get modifiedFilters() {
    let newFilters = { ...this.args.filters };

    if (newFilters.admin_participant_ids) {
      newFilters.teammate_id = newFilters.admin_participant_ids;
      delete newFilters.admin_participant_ids;
    }

    return newFilters;
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.seconds };
    viewConfig.aggregations = { defaultOption: MEDIAN, rest: [MIN, MAX, MEAN] };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[COMMENT_CREATED_AT],
        isComparison: false,
        createTitleTooltipText: () => {
          let defaultTooltip = this.intl.t(
            'apps.app.reports.customer-support.team-performance.first-response-by-team-linechart.tooltip',
          );
          let officeHoursTooltip = this.intl.t(
            'components.reporting.flexible.chart-header-custom-tooltip.office-hours-tooltip.tooltip-description',
            { includeOfficeHours: this.args.settings.reportingExcludeOooHours },
          );
          return this.intl.t(
            'components.reporting.reports.effectiveness.office-hours.tooltip-with-office-hours',
            { defaultTooltip, officeHoursTooltip, htmlSafe: true },
          );
        },
      },
      rest: [],
    };

    return viewConfig;
  }

  get labelMappings() {
    let teamMapping = {};
    this.teams.forEach((team) => (teamMapping[team.id] = team.display_as_assignee));
    return teamMapping;
  }

  get dataConfig() {
    if (this.appService.app.canQueryPowerfulIndices) {
      return {
        //hack the yAxis to do a line per team
        yAxis: {
          type: 'nominal',
          data: {
            sorting: SORTING_LABEL_NAME,
            limit: TEAM_GROUPING_LIMIT,
            term_size: TEAM_GROUPING_TERM_SIZE,
            exclude_missing_group: true,
            labelMappings: this.labelMappings,
            defaultLabel: this.intl.t(
              'apps.app.reports.customer-support.team-performance.first-response-by-team-linechart.default-label',
            ),
            property: LAST_TEAM_ASSIGNED_BEFORE_REPLY,
          },
        },
        xAxis: {
          name: 'first_admin_reply_by_team',
          type: 'temporal',
          data: {
            interval: FILTER_PROPERTY_PLACEHOLDER,
            property: FIRST_ADMIN_REPLY_AT,
          },
        },
        series: [
          {
            name: 'mfrt-line-chart',
            source: 'conversation',
            type: MEDIAN,
            data: {
              property: botInboxAndOfficeHoursField(
                FIRST_TEAMMATE_RESPONSE_TIME,
                this.args.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
                this.appService.app.canQueryPowerfulIndices,
              ),
            },
            time: {
              property: FIRST_ADMIN_REPLY_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
            filter: {
              type: 'range',
              data: {
                property: LAST_TEAM_ASSIGNED_BEFORE_REPLY,
                operators: ['gt'],
                values: ['0'], //filter out unassigned teams
              },
            },
          },
        ],
      };
    } else {
      return {
        //hack the yAxis to do a line per team
        yAxis: {
          type: 'nominal',
          data: {
            sorting: SORTING_LABEL_NAME,
            limit: TEAM_GROUPING_LIMIT,
            term_size: TEAM_GROUPING_TERM_SIZE,
            exclude_missing_group: true,
            labelMappings: this.labelMappings,
            defaultLabel: this.intl.t(
              'apps.app.reports.customer-support.team-performance.first-response-by-team-linechart.default-label',
            ),
            property: LAST_TEAM_ASSIGNED_BEFORE_REPLY,
          },
        },
        xAxis: {
          name: 'first_admin_reply_by_team',
          type: 'temporal',
          data: {
            interval: FILTER_PROPERTY_PLACEHOLDER,
            property: COMMENT_CREATED_AT,
          },
        },
        series: [
          {
            name: 'mfrt-line-chart',
            source: 'initial_admin_reply',
            type: MEDIAN,
            data: {
              property: botInboxAndOfficeHoursField(
                TEAMMATE_RESPONSE_TIME,
                this.args.settings.reportingExcludeOooHours,
                this.botInboxTimeExcluded,
              ),
            },
            time: {
              property: COMMENT_CREATED_AT,
              start: FILTER_PROPERTY_PLACEHOLDER,
              end: FILTER_PROPERTY_PLACEHOLDER,
            },
            filter: {
              type: 'range',
              data: {
                property: LAST_TEAM_ASSIGNED_BEFORE_REPLY,
                operators: ['gt'],
                values: ['0'], //filter out unassigned teams
              },
            },
          },
        ],
      };
    }
  }

  get botInboxTimeExcluded() {
    return this.args.botInboxTime?.[0] === 'excluded';
  }
}
