/* import __COLOCATED_TEMPLATE__ from './seats-editor-card.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import {
  COLLABORATOR_SEAT_TYPE,
  CONVERT_SEAT_TYPE,
  ENGAGE_SEAT_TYPE,
  SUPPORT_SEAT_TYPE,
  PRICING_5_X_FULL_SEAT_TYPE,
  PRICING_5_X_LITE_SEAT_TYPE,
  PRICING_5_X_SEAT_LABEL_KEYS,
} from 'embercom/lib/settings/seats/constants';
import { inject as service } from '@ember/service';
import currencyFormatter from 'embercom/lib/currency-formatter';
import { PLAN_DATA } from 'embercom/lib/billing';

export default class SeatsEditorCard extends Component {
  @service intl;
  @service appService;
  @service customerService;

  get isOnPricing5() {
    return this.appService.app.onPricing5;
  }

  get disableChatWithUsTooltip() {
    if (this.args.seatType === COLLABORATOR_SEAT_TYPE) {
      return !(
        this.isToggleDisabled ||
        (!this.args.seats.includes(this.args.seatType) && this.args.wouldGoIntoOverages)
      );
    } else {
      return !(this.args.showTooltip && this.args.seats.includes(COLLABORATOR_SEAT_TYPE));
    }
  }

  get isToggleDisabled() {
    let { seats } = this.args;
    let map = {
      [COLLABORATOR_SEAT_TYPE]:
        seats.includes(SUPPORT_SEAT_TYPE) ||
        seats.includes(ENGAGE_SEAT_TYPE) ||
        seats.includes(CONVERT_SEAT_TYPE),

      [ENGAGE_SEAT_TYPE]: seats.includes(COLLABORATOR_SEAT_TYPE),

      [CONVERT_SEAT_TYPE]: seats.includes(COLLABORATOR_SEAT_TYPE),

      [PRICING_5_X_FULL_SEAT_TYPE]: false,

      [PRICING_5_X_LITE_SEAT_TYPE]: false,
    };

    return map[this.args.seatType] || false;
  }

  get isPricing5Seat() {
    return (
      this.args.seatType === PRICING_5_X_FULL_SEAT_TYPE ||
      this.args.seatType === PRICING_5_X_LITE_SEAT_TYPE
    );
  }

  get seatCardDescription() {
    return this.intl.t(
      `components.settings.teammates.seats-editor.${this.args.seatType}-seat-description`,
    );
  }

  get pricing5SeatCardLabel() {
    return this.intl.t(`${PRICING_5_X_SEAT_LABEL_KEYS[this.args.seatType]}`);
  }

  get hasCompoundSeatPrice() {
    return Object.keys(this._chargeableSeatPriceByPlan(this.args.seatType)).length > 1;
  }

  get seatPriceBreakdownTooltip() {
    let seatType = this.args.seatType;
    let seatPriceByPlan = this._chargeableSeatPriceByPlan(seatType);
    let sorted = [];
    Object.keys(seatPriceByPlan).forEach((planId) => {
      let rawPrice = seatPriceByPlan[planId];
      let precision = rawPrice % 100 === 0 ? 0 : 2;
      let price = currencyFormatter(rawPrice, precision);
      if (PLAN_DATA[planId].addon) {
        sorted.push(`${price} ${this.intl.t(PLAN_DATA[planId].nameWithProductTranslationKey)}`);
      } else {
        sorted.unshift(`${price} ${this.intl.t(PLAN_DATA[planId].nameWithProductTranslationKey)}`);
      }
    });

    return sorted.join(' + ');
  }

  _chargeableSeatPriceByPlan(seatType) {
    let priceByPlan = this.customerService.customer.seatTypePriceByPlan[seatType] || {};

    return Object.keys(priceByPlan)
      .filter((key) => priceByPlan[key] !== 0)
      .reduce((newObj, key) => {
        newObj[key] = priceByPlan[key];
        return newObj;
      }, {});
  }
}
