/* import __COLOCATED_TEMPLATE__ from './card-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import $ from 'jquery';
import { computed } from '@ember/object';
import { sort, or } from '@ember/object/computed';
import { schedule } from '@ember/runloop';
import Component from '@ember/component';
import { findByProperty } from '@intercom/pulse/lib/computed-properties';
import Admin from 'embercom/models/admin';
import { post } from 'embercom/lib/ajax';

export default Component.extend({
  appService: service(),
  store: service(),
  intl: service(),
  permissionsService: service(),
  intercomEventService: service(),

  classNames: ['settings__teams__card-component'],

  defaultSectionName: 'new-team',
  sectionName: or('selectedTeam.id', 'defaultSectionName'),
  isCardOpen: computed('accordion.publicAPI.openSectionName', 'sectionName', function () {
    return this.accordion.publicAPI.openSectionName === this.sectionName;
  }),
  teamSettings: null,
  shouldDisableWorkloadManagement: computed(function () {
    return !this.permissionsService.currentAdminCan('can_manage_workload_management');
  }),
  attributeBindings: ['data-test-teams-card-component'],
  'data-test-teams-card-component': true,

  responseDelayOptionsWithAutomatic: computed('intl.locale', function () {
    return [
      {
        text: this.intl.t(
          'components.settings.teams.card-component.share-team-reply-time.custom-response-delay.as-soon-as-possible',
        ),
        id: 'expected-response-delay-asap',
        value: 3,
      },
      {
        text: this.intl.t('messenger.office-hours.few-minutes'),
        id: 'expected-response-delay-minutes',
        value: 0,
      },
      {
        text: this.intl.t('messenger.office-hours.few-hours'),
        id: 'expected-response-delay-hours',
        value: 1,
      },
      {
        text: this.intl.t('messenger.office-hours.in-a-day'),
        id: 'expected-response-delay-day',
        value: 2,
      },
      {
        text: this.intl.t('messenger.office-hours.custom-time-option'),
        id: 'expected-response-delay-custom',
        value: 4,
      },
    ];
  }),

  init() {
    this._super(...arguments);
    let teamLinkedToInUrlId = window.location.hash.slice(1);
    let linkedToTeam = teamLinkedToInUrlId === this.get('selectedTeam.id');
    if (linkedToTeam) {
      schedule('afterRender', this, this.setInitialOpenSection, teamLinkedToInUrlId);
      schedule('afterRender', this, this._scrollParent);
    }
    this.resetTeammateState();
  },

  didReceiveAttrs() {
    this._super(...arguments);
    if (!this.teamSettings) {
      this.set(
        'teamSettings',
        this.selectedTeamSettings ||
          this.store.createRecord('team-setting', { teamId: this.selectedTeam.id }),
      );
    }
  },

  didInsertElement() {
    this._super(...arguments);
    if (this.isSectionOpen) {
      this.accordion.publicAPI.openSection(this.sectionName);
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    this.accordion.publicAPI.removeSectionByName(this.sectionName);
  },

  teammateUpdates: { addedToPrimary: [], addedToSecondary: [], removed: [] },
  teamUpdated: computed('teammateUpdates', function () {
    return (
      this.teammateUpdates.addedToPrimary.length +
        this.teammateUpdates.addedToSecondary.length +
        this.teammateUpdates.removed.length >
      0
    );
  }),
  teamMembershipsValid: computed('teammateUpdates', function () {
    if (
      this.teammateUpdates.addedToPrimary.length > 0 ||
      this.teammateUpdates.addedToSecondary.length > 0
    ) {
      return true;
    }
    if (this.selectedTeam.teamMembers.length === 0) {
      return false;
    }
    return this.teammateUpdates.removed.length < this.selectedTeam.teamMembers.length;
  }),
  teamMembersSortKeys: ['name'],
  selectedTeamSortedTeamMembers: sort('selectedTeamMembers', 'teamMembersSortKeys'),
  selectedTeamSortedSecondaryTeamMembers: sort('secondaryMembers', 'teamMembersSortKeys'),
  selectedTeamMembers: computed(
    'isCardOpen',
    'selectedTeam',
    'teammateUpdates',
    'secondaryTeammatesAvailable',
    function () {
      let members = [];
      if (this.clearChangedData) {
        this.resetTeammateState();
      }
      if (this.secondaryTeammatesAvailable) {
        members = this.selectedTeam.primaryMembers
          .concat(this.teammateUpdates.addedToPrimary)
          .reject(
            (teammateId) =>
              this.teammateUpdates.addedToSecondary.includes(teammateId) ||
              this.teammateUpdates.removed.includes(teammateId),
          );
      } else {
        members = this.selectedTeam.member_ids
          .concat(this.addedMembers)
          .reject((teammateId) => this.teammateUpdates.removed.includes(teammateId));
      }
      return members.map((id) => {
        return Admin.peekAndMaybeLoad(this.store, String(id));
      });
    },
  ),
  secondaryMembers: computed('isCardOpen', 'selectedTeam', 'teammateUpdates', function () {
    let members = this.selectedTeam.secondaryMembers
      .concat(this.teammateUpdates.addedToSecondary)
      .reject(
        (teammateId) =>
          this.teammateUpdates.addedToPrimary.includes(teammateId) ||
          this.teammateUpdates.removed.includes(teammateId),
      );
    return members.map((id) => {
      return Admin.peekAndMaybeLoad(this.store, String(id));
    });
  }),
  addedMembers: computed('selectedTeam', 'teammateUpdates', function () {
    return this.teammateUpdates.addedToPrimary
      .concat(this.teammateUpdates.addedToSecondary)
      .reject((adminId) => this.selectedTeam.member_ids.includes(adminId));
  }),
  teamMembersCount: computed('selectedTeamMembers', 'selectedTeam', function () {
    if (this.secondaryTeammatesAvailable) {
      return this.selectedTeam.primaryMembers.length + this.selectedTeam.secondaryMembers.length;
    }
    return this.selectedTeamMembers.length;
  }),
  teamSubHeading: computed(
    'teamSettings.distributionMethod',
    'teamMembersCount',
    'intl.locale',
    function () {
      let subheading = this.intl.t('components.settings.teams.card-component.members-count', {
        teamMembersCount: this.teamMembersCount,
      });
      if (this.teamSettings.distributionMethod === 'round_robin') {
        subheading += this.intl.t(
          'components.settings.teams.card-component.distribution_methods.round-robin',
        );
      } else if (this.teamSettings.distributionMethod === 'load_balanced') {
        subheading += this.intl.t(
          'components.settings.teams.card-component.distribution_methods.load-balanced',
        );
      }
      if (this.selectedSchedule && !this.selectedSchedule.isDefault) {
        subheading += ` · ${this.selectedSchedule.name}`;
      }
      return subheading;
    },
  ),
  secondaryTeammatesAvailable: computed('teamSettings.distributionMethod', function () {
    return (
      this.appService.app.canSeeTeammateWorkloadPage &&
      this.teamSettings.distributionMethod === 'load_balanced'
    );
  }),

  selectedSchedule: findByProperty('schedules', 'id', 'teamSettings.officeHoursScheduleId'),

  scrollParentSelector: '.main__content .overflow-auto',

  _scrollParent() {
    let yOffset = $(this.element).offset().top;
    let scrollParent = $(this.element).closest(this.scrollParentSelector);
    scrollParent.scrollTop(yOffset);
  },

  async updateTeammates() {
    let teamId = parseInt(this.selectedTeam.id, 10);

    let primaryUpdates = [];
    if (this.secondaryTeammatesAvailable) {
      primaryUpdates = this.teammateUpdates.addedToPrimary;
    } else {
      primaryUpdates = this.addedMembers;
    }

    let admin_id_updates = { added: [], removed: [] };
    primaryUpdates.forEach((id) => {
      admin_id_updates['added'].push({
        id,
        wlm_inbox_priority_level: 1,
      });
    });
    if (this.secondaryTeammatesAvailable && this.teammateUpdates.addedToSecondary.length) {
      this.teammateUpdates.addedToSecondary.forEach((id) => {
        admin_id_updates['added'].push({
          id,
          wlm_inbox_priority_level: 2,
        });
      });
    }
    if (this.teammateUpdates.removed.length) {
      this.teammateUpdates.removed.forEach((id) => {
        admin_id_updates['removed'].push({
          id,
        });
      });
    }

    if (!admin_id_updates['added'].length && !admin_id_updates['removed'].length) {
      return;
    }
    let params = {
      app_id: this.appService.app.id,
      team_id: teamId,
      admin_ids: admin_id_updates,
    };
    await post('/ember/teams/update_team_memberships', params);

    if (primaryUpdates.length) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'teammates',
        place: 'settings',
        teammate_ids: primaryUpdates,
        selected_teammate_count: primaryUpdates.length,
        added_team_inbox_ids: [teamId],
        wlm_inbox_priority_level: 1,
      });
    }
    if (this.secondaryTeammatesAvailable && this.teammateUpdates.addedToSecondary.length) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'teammates',
        place: 'settings',
        teammate_ids: this.teammateUpdates.addedToSecondary,
        selected_teammate_count: this.teammateUpdates.addedToSecondary.length,
        added_team_inbox_ids: [teamId],
        wlm_inbox_priority_level: 2,
      });
    }
    if (this.teammateUpdates.removed.length) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'teammates',
        place: 'settings',
        teammate_ids: this.teammateUpdates.removed,
        selected_teammate_count: this.teammateUpdates.removed.length,
        removed_team_inbox_ids: [teamId],
      });
    }
  },

  resetTeammateState() {
    this.set('teammateUpdates', { addedToPrimary: [], addedToSecondary: [], removed: [] });
    this.setTeammateUpdateStatus(false);
  },

  actions: {
    addMemberToPrimary(admin) {
      let adminId = parseInt(admin.id, 10);
      let updates = Object.assign({}, this.teammateUpdates);
      updates.addedToSecondary = this.teammateUpdates.addedToSecondary.filter(
        (addedAdminId) => addedAdminId !== adminId,
      );
      updates.removed = this.teammateUpdates.removed.filter(
        (addedAdminId) => addedAdminId !== adminId,
      );
      if (!this.selectedTeam.primaryMembers.includes(adminId)) {
        updates.addedToPrimary.push(adminId);
      }
      this.set('teammateUpdates', updates);
      this.setTeammateUpdateStatus(this.teamUpdated);
    },
    addMemberToSecondary(admin) {
      let adminId = parseInt(admin.id, 10);
      let updates = Object.assign({}, this.teammateUpdates);
      updates.addedToPrimary = this.teammateUpdates.addedToPrimary.filter(
        (addedAdminId) => addedAdminId !== adminId,
      );
      updates.removed = this.teammateUpdates.removed.filter(
        (addedAdminId) => addedAdminId !== adminId,
      );
      if (!this.selectedTeam.secondaryMembers.includes(adminId)) {
        updates.addedToSecondary.push(adminId);
      }
      this.set('teammateUpdates', updates);
      this.setTeammateUpdateStatus(this.teamUpdated);
    },
    removeMember(admin) {
      let adminId = parseInt(admin.id, 10);
      let updates = Object.assign({}, this.teammateUpdates);
      updates.addedToPrimary = this.teammateUpdates.addedToPrimary.filter(
        (addedAdminId) => addedAdminId !== adminId,
      );
      updates.addedToSecondary = this.teammateUpdates.addedToSecondary.filter(
        (addedAdminId) => addedAdminId !== adminId,
      );
      if (
        this.selectedTeam.primaryMembers.includes(adminId) ||
        this.selectedTeam.secondaryMembers.includes(adminId)
      ) {
        updates.removed.push(adminId);
      }
      this.set('teammateUpdates', updates);
      this.setTeammateUpdateStatus(this.teamUpdated);
    },
    async updateTeamMemberships() {
      await this.updateTeammates();
      await this.selectedTeam.reload();
      this.resetTeammateState();
    },
    clearMemberSelections() {
      this.resetTeammateState();
    },
  },
});
