/* import __COLOCATED_TEMPLATE__ from './topic-coverage.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import percent from 'embercom/lib/percentage-formatter';
import { inject as service } from '@ember/service';

export default class TopicsCoverage extends Component {
  @service appService;

  get coverage() {
    return this.args.totalConversations > 0
      ? percent(this.args.totalConversations, this.args.conversationsWithTopics)
      : 0;
  }
}
