/* import __COLOCATED_TEMPLATE__ from './stat-tiles.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { aggregatedStatsDetails } from 'embercom/components/series/editor/aggregated-stats/stat-tile-config/stats-per-content-type';
import generateUUID from 'embercom/lib/uuid-generator';
import { objectTypes, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import { pluralHumanReadableObjectName } from 'embercom/helpers/matching-system/plural-human-readable-object-name';

export default class StatTiles extends Component {
  @service seriesEditorService;
  uniqueId = generateUUID();
  chartDefinitions = [];
  @service store;
  @service intl;

  constructor() {
    super(...arguments);
    this.chartDefinitions = this.createChartDefinitions();
  }

  get charts() {
    return this.chartDefinitions;
  }

  get reportState() {
    return {
      settings: {},
      dateRange: this.seriesEditorService.range,
      filters: {},
      isStatic: true,
      colorChartsByPoint: true,
    };
  }

  createChartDefinition(chartId, metric, chartTitle, tip, additionalfilter) {
    let allFilters = this.args.filter.slice();
    allFilters[0].data.values = Array.isArray(this.args.filterValues)
      ? this.args.filterValues
      : [this.args.filterValues];

    if (additionalfilter) {
      allFilters.push(additionalfilter);
    }

    let chartDefinition = {
      id: chartId,
      title: chartTitle,
      tooltip: tip,
      visualization_type: VISUALIZATION_TYPES.BESPOKE,
      component_name: 'series-comparison-counter',
      width_type: WIDTH_TYPES.TWO_COLUMN,
      height_type: HEIGHT_TYPES.SMALL,
      chart_series: [
        {
          metric_id: metric,
          filters: {
            type: 'and',
            filters: allFilters,
          },
        },
      ],
      date_range: {
        selection: 'custom',
        start: this.seriesEditorService.range.start,
        end: this.seriesEditorService.range.end,
      },
      stacked: false,
      view_by: null,
      view_by_time_interval: null,
      view_by_display_limit: 10000,
      view_by_display_other: false,
    };
    return chartDefinition;
  }

  createChartDefinitions() {
    let charts = this.args.statInfo.reduce((filteredCharts, statDetails) => {
      if (statDetails.condition === undefined || statDetails.condition(this.seriesEditorService)) {
        let chartId = `chart-${this.uniqueId}-${generateUUID()}`;
        let chartTitle = this.intl.t(statDetails.header);
        let tooltip = this.args.showTooltip ? this.getTooltipText(statDetails) : null;
        let additionalfilter = statDetails.hasOwnProperty('filter') ? statDetails.filter : null;

        let normalized = this.store.normalize(
          'reporting/custom/chart',
          this.createChartDefinition(
            chartId,
            statDetails.metric,
            chartTitle,
            tooltip,
            additionalfilter,
          ),
        );
        let chart = this.store.createRecord('reporting/custom/chart', normalized.data.attributes);
        filteredCharts.push(chart);
      }
      return filteredCharts;
    }, []);
    return charts;
  }

  getTooltipText(statDetails) {
    switch (statDetails) {
      case aggregatedStatsDetails.sent:
        return this.getSentTooltips(statDetails);

      case aggregatedStatsDetails.completed:
        return this.getCompletedTooltips(statDetails);

      default:
        return this.getTranslatedTooltip(statDetails.tooltip);
    }
  }

  getSentTooltips(statDetails) {
    let withoutDisplayText = [
      objectTypes.push,
      objectTypes.sms,
      objectTypes.whatsapp,
      objectTypes.email,
    ];

    return withoutDisplayText.includes(parseInt(this.args.selectedObjectType, 10))
      ? this.getTranslatedTooltip(`${statDetails.tooltip}.without-display-text`)
      : this.getTranslatedTooltip(`${statDetails.tooltip}.with-display-text`);
  }

  getCompletedTooltips(statDetails) {
    return this.getTranslatedTooltip(
      `${statDetails.tooltip}.${objectNames[this.args.selectedObjectType]}`,
    );
  }

  getTranslatedTooltip(translationReference) {
    return this.intl.t(translationReference, {
      stat:
        this.args.selectedObjectType === 'all'
          ? 'messages'
          : pluralHumanReadableObjectName(this.args.selectedObjectType),
    });
  }
}
