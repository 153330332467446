/* import __COLOCATED_TEMPLATE__ from './data-type.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP } from 'embercom/models/custom-objects/constants/attribute-descriptor-types';
import { RESERVED_OBJECT_TYPE_IDENTIFIERS } from 'embercom/models/custom-objects/constants/object-types';

export default class DataType extends Component {
  @service customObjectsService;
  @service intl;
  @service appService;

  get app() {
    return this.appService.app;
  }

  get typeDropdownItems() {
    return this.args.typeDropdownItems.map((typeDropdownItem) => {
      if (typeDropdownItem.value === ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP) {
        let text;

        text = isPresent(this.args.relatedObjectTypeIdentifier)
          ? this.intl.t('settings.attribute-details.data-type.reference-object', {
              referencedObjectName: this.relatedObjectType.name,
            })
          : this.intl.t('settings.attribute-details.data-type.reference');

        typeDropdownItem = Object.assign(typeDropdownItem, {
          text,
          component: 'settings/attribute-details/attribute-descriptor-format-link-to',
          componentShouldReplaceItem: true,
          isDisabled: this.objectTypeDropdownItems.length === 0,
          componentAttrs: {
            onSelectItem: this.args.onSelectRelatedObjectType,
            objectTypeDropdownItems: this.objectTypeDropdownItems,
            selectedObjectTypeIdentifier: this.args.relatedObjectTypeIdentifier,
          },
        });
      }
      return typeDropdownItem;
    });
  }

  get objectType() {
    return this.customObjectsService.objectTypes.findBy(
      'identifier',
      this.args.objectTypeIdentifier,
    );
  }

  get relatedObjectType() {
    return this.customObjectsService.objectTypes.findBy(
      'identifier',
      this.args.relatedObjectTypeIdentifier,
    );
  }

  get objectTypeDropdownItems() {
    return this.customObjectsService.objectTypeDropdownItems(this.excludedObjectTypeIdentifiers);
  }

  get excludedObjectTypeIdentifiers() {
    if (RESERVED_OBJECT_TYPE_IDENTIFIERS.includes(this.args.objectTypeIdentifier)) {
      return RESERVED_OBJECT_TYPE_IDENTIFIERS;
    }
    return [this.args.objectTypeIdentifier];
  }
}
