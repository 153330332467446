/* import __COLOCATED_TEMPLATE__ from './report-replacement-banner.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type CustomReportsService from 'embercom/services/custom-reports-service';
import { tracked } from '@glimmer/tracking';

export default class ReportReplacementBanner extends Component {
  @service declare customReportsService: CustomReportsService;
  @tracked showTemplateModal = false;

  @action
  openModal() {
    if (!this.customReportsService.canChangeCustomReports) {
      this.customReportsService.loadChangePermissionModal();
    } else {
      this.showTemplateModal = true;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::ReportV2::ReportReplacementBanner': typeof ReportReplacementBanner;
    'reporting/custom/report-v2/report-replacement-banner': typeof ReportReplacementBanner;
  }
}
