/* import __COLOCATED_TEMPLATE__ from './set-live-button.hbs'; */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

export default class SetLiveButton extends Component {
  @service seriesEditorService;
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @service store;
  @tracked showSetLiveModal = false;
  @tracked setLiveConfirmationData;
  @tracked messengerSettings = [];

  constructor() {
    super(...arguments);
    this.fetchMessengerSettings.perform();
  }

  get series() {
    return this.seriesEditorService.series;
  }

  get seriesHasChecklist() {
    return this.series.nodes.any((node) => node.hasRulesetWithChecklist);
  }

  get lacksTasksSpaceOnlyForUsers() {
    if (!this.seriesHasChecklist) {
      return false;
    }

    return (
      this.series.nodes.any((node) => node.rulesetTargetsUsers) &&
      !this.messengerSettings?.spaces?.spaceEnabledForUsers('isTasks')
    );
  }

  get lacksTasksSpaceOnlyForVisitorsOrLeads() {
    if (!this.seriesHasChecklist) {
      return false;
    }

    return (
      this.series.nodes.any((node) => node.rulesetTargetsVisitorsOrLeads) &&
      !this.messengerSettings?.spaces?.spaceEnabledForVisitors('isTasks')
    );
  }

  @task
  *openSetLiveConfirmation() {
    let audiencePreview;
    try {
      audiencePreview = yield this.seriesEditorService.series.fetchAudiencePreview();
    } catch (e) {
      let error =
        e.jqXHR?.status === 422
          ? 'The entry rules of this Series are too complex to process. Try reducing the number of company filters, segments, or levels of nesting'
          : `Failed to fetch the audience. Please check your starting condition blocks`;
      this.notificationsService.notifyError(error);
      this.seriesEditorService.showErrorsOnAllNodes();
      return;
    }

    this.setLiveConfirmationData = audiencePreview;
    this.setLiveConfirmationData.nodes = Object.keys(
      this.setLiveConfirmationData.startingNodes,
    ).reduce((nodes, nodeId) => {
      let audienceCount = this.setLiveConfirmationData.startingNodes[nodeId];
      nodes.pushObject({
        title: this.series.nodes.findBy('id', nodeId).title,
        audienceCount,
      });
      return nodes;
    }, []);
    this.showSetLiveModal = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'set_series_live',
      place: 'series_editor',
      section: 'series',
    });
  }

  @task({ drop: true })
  *fetchMessengerSettings() {
    let messengerSettings = yield this.store.findRecord(
      'messenger-settings/all',
      this.appService.app.id,
    );
    this.messengerSettings = messengerSettings;
  }

  @action focusNode(node) {
    let graphNode = this.seriesEditorService.graph.nodeForDataObject(node);
    this.seriesEditorService.graph.focusNode(graphNode);
  }

  @action
  confirmSetLive() {
    this.seriesEditorService.saveAndSetLive.perform().catch((err) =>
      this.notificationsService.notifyResponseError(err, {
        default: 'Error setting the Series Live',
      }),
    );
    this.showSetLiveModal = !this.showSetLiveModal;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'set_live',
      place: 'series_editor',
      section: 'series',
    });
  }
}
