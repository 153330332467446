/* import __COLOCATED_TEMPLATE__ from './collapsible-ticket-type-list.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import platform from 'embercom/lib/browser-platform';
import type TicketTypeSummary from 'embercom/models/inbox/ticket-type-summary';
import { type ColumnConfig } from './list-ticket-types';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  currentView: 'live' | 'archived';
  icon: InterfaceIconName;
  title: string;
  intro: string;
  introTooltip: string;
  loading?: boolean;
  ticketTypes?: TicketTypeSummary[];
  columns: ColumnConfig[];
  onCreateTicketType: () => void;
  onEditTicketType: (ticketTypeId: number) => void;
  onRestoreTicketType: (ticketType: TicketTypeSummary) => void;
  ticketCategoryId: string;
  accordion: any;
}

export default class CollapsibleTicketTypeList extends Component<Args> {
  @service declare intl: IntlService;

  tooltipShortcut = platform.isMac ? '⌥' : 'ALT';

  @tracked showTicketTypeModal = false;
  @tracked showTicketTypeSideDrawer = false;

  get isLiveView() {
    return this.args.currentView === 'live';
  }

  get emptyArchive() {
    return this.args.currentView === 'archived' && this.count === 0;
  }

  get canBeOpened() {
    return this.count > 0 || this.args.currentView === 'live';
  }

  get noTicketTypesInfoLabel() {
    let categoryName = this.intl.t(
      `settings.ticket-data.list-ticket-types.categories.${this.args.ticketCategoryId}`,
    );

    return this.intl.t('settings.ticket-data.list-ticket-types.no-ticket-types-info', {
      categoryName,
    });
  }

  get count() {
    return this.args.ticketTypes?.length || 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketData::CollapsibleTicketTypeList': typeof CollapsibleTicketTypeList;
  }
}
